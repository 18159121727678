import React from 'react';
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';

const LayoutOptions = (props) => {
  const { step, showLayoutTypes } = props;
  const {
    setPosition, setDisplay, showWidget, layout,
  } = props;

  const setLayoutPosition = (e, pos) => {
    e.preventDefault();

    if (pos === 0) {
      stats.push(['trackEvent', 'Flowter Creator - Layout', 'Position Right selected']);
    } else {
      stats.push(['trackEvent', 'Flowter Creator - Layout', 'Position Left selected']);
    }

    setPosition(pos);
    showWidget();
  };

  const setLayoutDisplay = (e, display) => {
    e.preventDefault();

    if (display === 1) {
      stats.push(['trackEvent', 'Flowter Creator - Layout', 'Button selected']);
    } else {
      stats.push(['trackEvent', 'Flowter Creator - Layout', 'Popup selected']);
    }

    setDisplay(display);
    showWidget();
  };

  const isPositionRight = layout.pos === 1;
  const isButton = layout.display === 1;

  return (
    <div id="layoutOptionWrapper" className="has-text-black">
      <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">{step}</p>
      <h2 className="has-text-weight-bold is-size-6 has-text-centered is-inline">Bestimme wie dein Flowter angezeigt werden soll</h2>
      <div className="mb10 mt20 has-info has-text-centered">
        <p className="has-text-weight-semibold has-text-grey is-inline">Position auf der Website</p>
        <Popup
          trigger={(
            <div className="cleanButton is-inline ml5">
              <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
            </div>
          )}
          position="top center"
          on={['hover']}
          keepTooltipInside="#root"
          repositionOnResize
          className="mt10"
        >
          {
            () => (
              <div className="columns is-marginless">
                <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey">
                  <p className="is-size-7">Du kannst die Position deines Flowters auf deiner Website angeben.</p>
                </div>
              </div>
            )
          }
        </Popup>
      </div>
      <div className="columns is-marginless is-centered has-text-centered mb30">
        <div className="column is-narrow has-text-right-desktop has-text-centered-touch pr0 pb0">
          <button
            className={`layoutBox button br10 ${isPositionRight ? 'has-border-semibold has-text-grey has-text-weight-semibold has-hover' : 'has-border-primary is-primary has-text-weight-bold'}`}
            onClick={(e) => setLayoutPosition(e, 0)}
            type="button"
          >
            Unten Links
          </button>
        </div>
        <div className="column is-narrow has-text-left-desktop has-text-centered-touch pr0 pb0">
          <button
            className={`layoutBox button br10 ${isPositionRight ? 'has-border-primary is-primary has-text-weight-bold' : 'has-border-semibold has-text-grey has-text-weight-semibold has-hover'}`}
            onClick={(e) => setLayoutPosition(e, 1)}
            type="button"
          >
            Unten Rechts
          </button>
        </div>
      </div>
      {
        showLayoutTypes
        && (
        <>
          <div className="mb10 mt20 has-info has-text-centered">
            <p className="has-text-weight-semibold has-text-grey is-inline">Wie soll dein Flowter auf der Website erscheinen?</p>
            <Popup
              trigger={(
                <div className="cleanButton is-inline ml5">
                  <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                </div>
              )}
              position="top center"
              on={['hover']}
              keepTooltipInside="#root"
              repositionOnResize
              className="mt10"
            >
              {
                () => (
                  <div className="columns is-marginless">
                    <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey">
                      <p className="is-size-7">Wie soll dein Flowter angezeigt werden? Animation: Der Flowter wird von der Seite hereingeschoben. - Button: Es wird ein Button angezeigt, auf dem geklickt werden muss.</p>
                    </div>
                  </div>
                )
              }
            </Popup>
          </div>
          <div className="columns is-marginless is-centered has-text-centered mb30">
            <div className="column is-narrow has-text-right-desktop has-text-centered-touch pr0 pb0">
              <button
                className={`layoutBox button br10 ${isButton ? 'has-border-semibold has-text-grey has-text-weight-semibold has-hover' : 'has-border-primary is-primary has-text-weight-bold'}`}
                onClick={(e) => setLayoutDisplay(e, 0)}
                type="button"
              >
                per Animation
              </button>
            </div>
            <div className="column is-narrow has-text-left-desktop has-text-centered-touch pr0 pb0">
              <button
                className={`layoutBox button br10 ${!isButton ? 'has-border-semibold has-text-grey has-text-weight-semibold has-hover' : 'has-border-primary is-primary has-text-weight-bold'}`}
                onClick={(e) => setLayoutDisplay(e, 1)}
                type="button"
              >
                per Button-Klick
              </button>
            </div>
          </div>
        </>
        )
      }
    </div>
  );
};

LayoutOptions.propTypes = {
  step: PropTypes.number.isRequired,
  showLayoutTypes: PropTypes.bool.isRequired,

};

const mapStateToProps = (state) => ({
  layout: state.creatorState.layout,
});

const mapDispatchToProps = (dispatch) => ({
  setPosition: (pos) => dispatch({ type: 'SET_LAYOUT_POSITION', pos }),
  setDisplay: (display) => dispatch({ type: 'SET_LAYOUT_DISPLAY', display }),
  showWidget: () => dispatch({ type: 'SHOW_WIDGET' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutOptions);
