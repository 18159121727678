/**
 * Reducer for the creator used for all widgets
 */
const INITIAL_STATE = {
  name: '',
  nameError: '',

  layout: {
    pos: 1,
    display: 1,

  },
  trigger: {
    type: 0,
    amount: 0,
  },

  color: '#273443',

  showWidget: true,
  editIndex: -1,
  isDemo: false,

  settings: {
    // a week
    expire: 604800,
    /**
     * screens is an array of numbers that shows which screen is allowed
     * Order is like this: Desktop, Tablet, Mobile
     * 0 = not allowed, 1 = allowed
     * e.g: [1, 0, 1] means Desktop and Mobile is allowed, Tablet is not
     * default is [1, 1, 1], so all devices are allowed
     */
    screens: [1, 1, 1],
  },

};

const clearWidgetState = () => INITIAL_STATE;

const setWidget = (state, action) => {
  const { widget } = action;

  const name = widget.name || widget.meta.name || '';

  return {
    ...state,
    name,
    layout: widget.layout || INITIAL_STATE.layout,
    trigger: widget.trigger || INITIAL_STATE.trigger,
    settings: widget.settings || INITIAL_STATE.settings,
    showWidget: widget.showWidget || true,
    editIndex: widget.editIndex === undefined ? -1 : widget.editIndex,
    color: widget.color || '#273443',
  };
};

const setTriggerType = (state, action) => ({
  ...state,
  trigger: {
    ...state.trigger,
    type: action.triggerType,
  },
});

const setTriggerAmount = (state, action) => ({
  ...state,
  trigger: {
    ...state.trigger,
    amount: action.amount,
  },
});

const setName = (state, action) => ({
  ...state,
  name: action.name || '',
});

const setNameError = (state, action) => ({
  ...state,
  nameError: action.error || '',
});

const setPosition = (state, action) => ({
  ...state,
  layout: {
    ...state.layout,
    pos: action.pos,
  },
});

const showWidget = (state, action) => ({
  ...state,
  showWidget: true,
});

const setDisplay = (state, action) => ({
  ...state,
  layout: {
    ...state.layout,
    display: action.display,
  },
});

const setShowWidget = (state, action) => ({
  ...state,
  showWidget: action.show,
});

const toggleShow = (state, action) => ({
  ...state,
  showWidget: !state.showWidget,
});

const setExpire = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    expire: action.expire,
  },
});

const setEditIndex = (state, action) => ({
  ...state,
  editIndex: action.editIndex,
});

const setScreens = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    screens: action.screens,
  },
});

const setColor = (state, action) => ({
  ...state,
  color: action.color,
});

function widgetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CLEAR_STATE':
    case 'CLEAR_CREATOR':
    {
      return clearWidgetState(state, action);
    }

    case 'SET_WIDGET':
    {
      return setWidget(state, action);
    }

    case 'SET_TRIGGER_TYPE':
    {
      return setTriggerType(state, action);
    }

    case 'SET_TRIGGER_AMOUNT':
    {
      return setTriggerAmount(state, action);
    }
    case 'SET_NAME':
    {
      return setName(state, action);
    }
    case 'SET_NAME_ERROR':
    {
      return setNameError(state, action);
    }
    case 'SET_LAYOUT_POSITION':
    {
      return setPosition(state, action);
    }
    case 'SET_LAYOUT_DISPLAY':
    {
      return setDisplay(state, action);
    }
    case 'SHOW_WIDGET':
    {
      return showWidget(state);
    }

    case 'SET_SHOW_WIDGET':
    {
      return setShowWidget(state, action);
    }
    case 'TOGGLE_SHOW_WIDGET':
    {
      return toggleShow(state);
    }

    case 'SET_EXPIRE':
    {
      return setExpire(state, action);
    }

    case 'SET_EDIT_INDEX':
    {
      return setEditIndex(state, action);
    }

    case 'SET_SCREENS':
    {
      return setScreens(state, action);
    }

    case 'SET_WIDGET_COLOR':
    {
      return setColor(state, action);
    }

    default:
      return state;
  }
}

export default widgetReducer;
