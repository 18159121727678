import React, { useState } from 'react';

// Libraries
import withAuthentication from 'components/hoc/withAuthentication';
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';

// Actions
import { addHub } from 'reducer/hubs';

// Components
import UrlCreator from 'components/hub/HubProfileEditor/UrlCreator';

// Constants
import { HUB_PROFILE, HUB_CONTENT } from 'constants/routes';
import TooManyHubsInfo from './TooManyHubsInfo';

function HubCreator() {
  const { creator, hubs } = useSelector((state) => state.hubs);
  const { maxAmountHubs } = useSelector((state) => state.sessionState.user.planDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { url } = creator;

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const { post } = useAPI();

  const canCreateHub = hubs.length < maxAmountHubs;

  const createHub = async () => {
    try {
      if (url.length < 3) {
        setError('Deine URL für deinen Hub ist zu kurz! Benutze bitte mindestens 3 Zeichen.');
        return;
      }
      setDisabled(true);

      const hub = await post('/hubs', { url });
      dispatch(addHub(hub));

      if (creator.hasTemplateData) {
        navigate(`${HUB_CONTENT}/${hubs.length}`);
      } else {
        navigate(`${HUB_PROFILE}/${hubs.length}`);
      }
    } catch (e) {
      console.log(e);

      if (e.response && e.response.status === 409) {
        setError('Die URL für diesen Hub existiert bereits. Bitte wähle eine andere URL.');
        setDisabled(false);
        return;
      }

      setError('Ein Fehler ist aufgetreten. Konnte Hub nicht erstellen.');
      setDisabled(false);
    }
  };

  if (!canCreateHub) {
    return (
      <>
        <Helmet>
          <title>Flowdust - Zu viele Hubs!</title>
        </Helmet>
        <TooManyHubsInfo />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Flowdust - Hub erstellen</title>
      </Helmet>
      <div className="is-relative py-6">
        <h1 className="has-text-centered is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold mt-4">
          Hub erstellen
        </h1>
        <div className="columns is-marginless is-centered is-mobile mb-6">
          <div className="column is-7-desktop is-9-tablet is-11-mobile">
            <div className="box boxshadow">
              <UrlCreator showRedirectLink={false} />
              <p className="has-text-grey is-size-6 my-1 mx-1">Damit man deinem Hub ganz einfach aufrufen kann, musst du zu allererst eine URL hinzufügen.</p>
              <div className="has-text-centered">
                <button
                  type="button"
                  onClick={createHub}
                  className={`button is-rounded has-text-weight-bold is-primary mt-3 ${disabled ? 'is-loading' : ''}`}
                  disabled={url.length === 0 || disabled}
                >
                  Hub erstellen
                </button>
                <p
                  className="has-text-danger has-text-weight-bold mt-2"
                >
                  { error }
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthentication(HubCreator);
