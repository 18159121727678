import React, { useState, useEffect, useLayoutEffect } from 'react';

// Libraries
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import useSubscription from 'components/hooks/useSubscription';

// Actions
import { setCustomerData } from 'actions/customer';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// constants
import * as status from 'constants/status';
import { CHECKOUT } from 'constants/routes';

// Components
import CustomerInfoBox from 'components/settings/CustomerInfoBox';
import LoadingLayout from 'components/utils/LoadingLayout';
import SubscriptionInfoBox from './subscription/SubscriptionInfoBox';
import PaymentInfo from './PaymentInfo';
import PaymentHistory from './PaymentHistory';
import UserStatisticBox from './UserStatisticBox';

// Styles
import './styles/styles.scss';

function Billing() {
  const [signal] = useState(axios.CancelToken.source());
  const [error, setError] = useState('');

  const user = useSelector((state) => state.sessionState.user);

  const { customer, isNewCustomer } = useSubscription();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  useLayoutEffect(() => {
    if (isNewCustomer) {
      navigate(CHECKOUT);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        // Get customer data
        const data = await axios.get(`${process.env.REACT_APP_API}/subscription/customer`, config);

        dispatch(setCustomerData(data.data));
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setError('Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');
        console.log(err);
      }
    };

    if (!isNewCustomer && (!customer || !user.paymentMethods || user.paymentMethods.length === 0)) {
      getCustomerData();
    }
  }, []);

  if (isNewCustomer || !customer) {
    return (<LoadingLayout error={error} />);
  }

  const showNextPayment = () => {
    if (user.status !== status.ACTIVE || !user.subscription) {
      return null;
    }

    const { nextBillAmount, nextBillingDate } = user.subscription;

    if (!nextBillAmount || !nextBillingDate) {
      return null;
    }

    const date = new Date(nextBillingDate).toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' });

    const nextAmountToPay = nextBillAmount.replace('.', ',');

    return (
      <div className="box boxshadow br10 has-text-black">
        <p className="has-text-weight-semibold">Nächste Zahlung:</p>
        <p className="has-text-weight-bold is-size-3">
          {`${nextAmountToPay} €`}
        </p>
        <p className="is-size-7 has-text-grey">(inkl. USt)</p>
        <p className="has-text-weight-semibold is-size-5">
          {`am ${date}`}
        </p>
        <p className="is-size-7 has-text-grey">(Abonnement wird jeden Monat automatisch verlängert)</p>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Abonnement</title>
      </Helmet>
      <div id="billing" className="has-background-white has-fullheight">
        <div className="columns is-marginless is-centered has-fullheight">
          <div className="column pl20 pr20 py-6">
            <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-black has-text-weight-bold mb-2">
              Zahlungsdetails
            </p>
            <div className="columns is-marginless is-mobile is-centered is-vcentered mb-5">
              <div className="column is-5-desktop is-5-tablet is-11-mobile infoBox">
                <SubscriptionInfoBox />
              </div>
              <div className="column is-5-desktop is-5-tablet is-11-mobile is-offset-1-desktop infoBox">
                { showNextPayment() }
              </div>
            </div>
            <PaymentHistory />
          </div>
          <div className="column is-3-desktop is-11-tablet has-border-left-grey-light has-background-white pb20" id="sideInfo">
            <PaymentInfo />
            <CustomerInfoBox />
            <UserStatisticBox />
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthentication(Billing);
