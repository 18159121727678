import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import notification from 'utils/notification';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import loader from 'assets/images/loading.svg';
import { removeWidgetFromWebsites } from 'actions/websites';
import { decrementWidgetCount } from 'actions/widget';

function DeleteButton(props) {
  const { websites } = useSelector((state) => state.websiteState);
  const dispatch = useDispatch();

  const { api, id, deleteWidget } = props;

  const [disabled, setDisabled] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const signal = axios.CancelToken.source();

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  const removeWidget = async (close) => {
    setDisabled(true);

    // get websites with the widget that gets deleted
    const websiteIds = [];
    for (let i = 0; i < websites.length; i++) {
      if (websites[i].widgets && websites[i].widgets.id === id) {
        websiteIds.push(i);
      }
    }

    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      await axios.delete(`${process.env.REACT_APP_API}/${api}/${id}`, config);

      if (websiteIds.length !== 0) {
        dispatch(removeWidgetFromWebsites(websiteIds));
      }

      setDisabled(false);
      close();

      deleteWidget(id);

      dispatch(decrementWidgetCount());

      notification.send('Erledigt!', 'Dein Flowter wurde gelöscht.', 'success');
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err);
      console.log(err.response);

      setDisabled(false);

      if (!err.response) {
        notification.send('Fehler!', 'Es ist etwas schief gelaufen. Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      if (err.response.status === 500) {
        notification.send('Server Fehler!', 'Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      notification.send('Fehler!', err.response.data, 'danger');
    }
  };

  return (
    <Popup
      trigger={(
        <button
          className="button is-inline-block p0 icon-circle has-background-grey-light"
          type="button"
        >
          <i className="is-size-7 fas fa-trash has-text-grey" />
        </button>
  )}
      position="top center"
      on="click"
      keepTooltipInside="#root"
      repositionOnResize
    >
      { (close) => (
        <div className="columns is-marginless">
          <div className="column is-marginless is-paddingless pb10 pl5 pr5 has-background-white br22">
            <div className="br22 has-text-centered box">
              <div className="has-text-right">
                <button
                  className="cleanButton"
                  onClick={close}
                  type="button"
                >
                  <i className="fas fa-times has-text-grey-lighter is-size-7" />
                </button>
              </div>
              <h2 className="has-text-centered has-text-black is-size-7">
                Bist Du sicher, dass Du diesen Flowter löschen willst?
              </h2>
              {
               disabled
                 ? <img className="buttonLoader" src={loader} alt="" />
                 : (
                   <button
                     className="pt5 pb5 pl10 pr10 cleanButton mt5"
                     onClick={() => removeWidget(close)}
                     disabled={disabled}
                     type="button"
                   >
                     <i className="fas fa-trash-alt has-text-danger is-size-7 is-inline mr5" />
                     <p className="is-inline has-text-danger has-text-weight-semibold is-size-7">Löschen</p>
                   </button>
                 )
               }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

DeleteButton.propTypes = {
  deleteWidget: PropTypes.func.isRequired,
  api: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DeleteButton;
