import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';

const Button = (props) => {
  const {
    text, url, onClick, styles, children,
  } = props;

  const sanitizedUrl = useMemo(() => {
    let _url = url;

    // adds https if it does not start with any protocol
    if (_url.search(/^http[s]?:\/\//) === -1) {
      _url = `https://${_url}`;
    }

    return sanitizeUrl(_url);
  }, [url]);

  return (
    <div className="my-2 is-in-center has-max-width-250 has-fullwidth">
      <a
        href={sanitizedUrl}
        onClick={onClick}
        target="_blank"
        rel="noreferrer"
        className="button break-word br6 has-text-white has-fullwidth has-fullheight has-white-space-normal"
        style={styles}
      >
        { children }
        <p
          className="ml-2 has-text-weight-bold is-inline-block"
        >
          {text}
        </p>
      </a>
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}).isRequired,
};

export default Button;
