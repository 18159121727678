const INITIAL_STATE = {
  topText: '',
  bottomText: '',
  iconURL: '',
  buttonURL: '',
  buttonText: '',
};

function setButtonURL(state, action) {
  return {
    ...state,
    buttonURL: action.url,
  };
}

function setIconURL(state, action) {
  return {
    ...state,
    iconURL: action.url,
  };
}

function setButtonText(state, action) {
  return {
    ...state,
    buttonText: action.text,
  };
}

function setTopText(state, action) {
  return {
    ...state,
    topText: action.text,
  };
}

function setBottomText(state, action) {
  return {
    ...state,
    bottomText: action.text,
  };
}

function setStatusWidget(state, action) {
  const widget = action.template || action.widget;

  const defaultLayout = { position: 1, display: 1 };
  const defaultTrigger = { type: 0, amount: 0 };

  return {
    ...state,
    topText: widget.topText || '',
    bottomText: widget.bottomText || '',
    name: widget.name || '',
    buttonText: widget.buttonText || '',
    buttonURL: widget.buttonURL || '',
    iconURL: widget.iconURL || '',
    layout: widget.layout || defaultLayout,
    trigger: widget.trigger || defaultTrigger,
  };
}

function clearStatusCreator(state) {
  return INITIAL_STATE;
}

// IMPORTANT: the data is in an object, so call action.object and then in the function action.data to get the data
function formReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_STATUS_TOPTEXT':
    {
      return setTopText(state, action);
    }
    case 'SET_STATUS_BOTTOMTEXT':
    {
      return setBottomText(state, action);
    }
    case 'SET_STATUS_BUTTONTEXT':
    {
      return setButtonText(state, action);
    }
    case 'SET_STATUS_ICONURL':
    {
      return setIconURL(state, action);
    }
    case 'SET_STATUS_BUTTONURL':
    {
      return setButtonURL(state, action);
    }
    case 'SET_STATUS_WIDGET':
    {
      return setStatusWidget(state, action);
    }
    case 'CLEAR_STATE':
    case 'CLEAR_STATUS_CREATOR':
    {
      return clearStatusCreator(state, action);
    }

    default:
      return state;
  }
}

export default formReducer;
