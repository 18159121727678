import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { HUB_RESPONSE_TEXT_MAX_LENGTH } from 'constants/hub';

const TextInput = (props) => {
  const {
    text, setText, goNext, color, saveOnEnter, showSubmitButton, icon, placeholder,
  } = props;

  const { t } = useTranslation(['hub']);

  const placeholderToShow = placeholder || t('hub:your_answer');

  const _setText = (_text) => {
    if (_text.length > HUB_RESPONSE_TEXT_MAX_LENGTH) return;

    setText(_text);
  };

  const _saveText = () => {
    if (text.length === 0) return;

    goNext();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && saveOnEnter) {
      _saveText();
    }
  };

  return (
    <div className="mt-4 has-background-white has-border-grey-light-thin br6 has-max-width-600 has-margin-auto">
      <div className="columns m-0 p-2">
        <div className="column p-0">
          <input
            type="text"
            value={text}
            onChange={(e) => _setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={placeholderToShow}
            className="input has-no-shadow has-no-border is-inline-block"
          />
        </div>
        {
          showSubmitButton && (
            <div className="column is-narrow p-0">
              <button
                type="button"
                className="button has-fullwidth is-inline-block has-text-white px-3 py-2"
                onClick={_saveText}
                style={{ backgroundColor: color }}
              >
                <i className={`fas fa-${icon}`} />
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
};

TextInput.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  showSubmitButton: PropTypes.bool,
  goNext: PropTypes.func,
  saveOnEnter: PropTypes.bool,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  saveOnEnter: true,
  showSubmitButton: true,
  goNext: () => {},
  icon: 'paper-plane',
  placeholder: '',
};

export default TextInput;
