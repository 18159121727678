const INITIAL_STATE = {
  hasData: false,
  statusWidgets: [],
};

const clearStatusWidgets = () => INITIAL_STATE;

function addStatusWidget(state, action) {
  return {
    ...state,
    hasData: true,
    statusWidgets: [...state.statusWidgets, action.widget],
  };
}

function updateStatusWidget(state, action) {
  return {
    ...state,
    statusWidgets: state.statusWidgets.map(
      (widget) => (widget._id === action.widget._id ? action.widget : widget),
    ),
  };
}

function deleteStatusWidget(state, action) {
  return {
    ...state,
    statusWidgets: state.statusWidgets.filter((widget) => widget._id !== action.id),
  };
}

const setStatusWidgets = (state, action) => ({
  ...state,
  hasData: true,
  statusWidgets: action.widgets,
});

function statusWidgetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_STATUS_WIDGETS':
    {
      return setStatusWidgets(state, action);
    }

    case 'ADD_STATUS_WIDGET':
    {
      return addStatusWidget(state, action);
    }

    case 'DELETE_STATUS_WIDGET':
    {
      return deleteStatusWidget(state, action);
    }

    case 'UPDATE_STATUS_WIDGET':
    {
      return updateStatusWidget(state, action);
    }

    case 'CLEAR_STATE':
    case 'STATUSWIDGETS_CLEAR':
    {
      return clearStatusWidgets(state);
    }

    default:
      return state;
  }
}

export default statusWidgetReducer;
