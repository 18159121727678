import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/scss/notification.scss';

const notification = {};

notification.send = function (title, message, type) {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
    waitForAnimation: true,
    showIcon: true,
  });
};

export default notification;
