import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import MediaDisplay from 'components/hub/MediaDisplay';
import LinkDisplay from './LinkDisplay';
import ProgressIndicator from '../ProgressIndicator';

const EndingDisplay = (props) => {
  const { index } = props;

  const { content, id } = useSelector((state) => state.hubs.creator);
  const { questions, endings } = content;

  const ending = endings[index];

  if (!ending) return null;

  const { text, media, links } = ending;

  const showLinks = () => links.map((link, _index) => (
    <LinkDisplay
      key={_index}
      endingIndex={index}
      linkIndex={_index}
    />
  ));

  return (
    <div className="box br8 boxshadow p-5 has-min-height-400 is-flex flex-direction-column">
      <ProgressIndicator activeQuestion={questions.length} />
      <div className="columns is-flex is-vcentered is-centered has-flex-1 is-mobile is-multiline">
        <div className="column has-min-width-300 has-border-right is-flex has-content-centered flex-direction-column is-12-mobile">
          <p className="p-2 mb-2 is-size-5 is-size-6-mobile has-text-weight-medium has-text-black show-linebreaks">
            {text}
          </p>
          <div className="mt-3">
            <MediaDisplay file={media} hubID={id} isCreator />
          </div>
        </div>
        {
          links.length !== 0
          && (
          <div className="column is-flex has-content-centered is-12-mobile">
            <div className="has-fullwidth">
              { showLinks() }
            </div>
          </div>
          )
        }
      </div>
    </div>

  );
};

EndingDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export default EndingDisplay;
