import React, { useEffect } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer
import { setWidget } from 'actions/creator';
import { setFlowter } from 'reducer/flowterCreator';

// Constants
import { FLOWTER_LIST, HOME } from 'constants/routes';

// Components
import FlowterCreator from './FlowterCreator';

function FlowterCreatorContainer() {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { hasData, flowters } = useSelector((state) => state.flowterState);
  const { editIndex } = useSelector((state) => state.creatorState);

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    const flowterIndex = parseInt(id, 10);

    if (!hasData || Number.isNaN(flowterIndex)) {
      navigate(HOME);
      return;
    }

    const flowterToEdit = flowters[flowterIndex];

    if (!flowterToEdit) {
      return;
    }

    // pos is the name for position in database
    // the creator only knows position, so we change it
    flowterToEdit.editIndex = flowterIndex;

    // set trigger / layout / name
    dispatch(setWidget(flowterToEdit));

    // set flowter specific stuff
    dispatch(setFlowter(flowterToEdit));
    // eslint-disable-next-line
	}, [])

  const type = editIndex === -1 ? 'hinzufügen' : 'bearbeiten';

  return (
    <>
      <Helmet>
        <title>
          {`Flowdust - Flowter ${type}`}
        </title>
      </Helmet>
      <FlowterCreator finishCreation={() => navigate(FLOWTER_LIST)} />
    </>
  );
}

export default FlowterCreatorContainer;
