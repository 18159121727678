import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const SliderResponse = (props) => {
  const { value, text, color } = props;

  return (
    <>
      <div>
        <i
          className="fas fa-square is-size-7 is-inline mr-1"
          style={{ color }}
        />
        <p className="is-inline">
          {`${value}%`}
        </p>
      </div>
      {
        text
        && (
        <div>
          <i
            className="fas fa-square is-size-7 is-inline mr-1"
            style={{ color }}
          />
          <p className="is-inline break-word">
            {`${text}`}
          </p>
        </div>
        )
      }
    </>

  );
};

SliderResponse.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,

  text: PropTypes.string,
};

SliderResponse.defaultProps = {
  text: '',
};

export default SliderResponse;
