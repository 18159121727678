import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

const TrustpilotButton = (props) => {
  const { text, url, onClick } = props;

  return (
    <Button
      url={url}
      text={text}
      onClick={onClick}
      styles={{ backgroundColor: '#00b67A' }}
    >
      <p className="has-text-weight-bold">T</p>
    </Button>
  );
};

TrustpilotButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TrustpilotButton;
