import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setInfo, setInfoMedia } from 'reducer/hubs';

// Constants
import { HUB_MAX_INFO_LENGTH } from 'constants/hub';

// Components
import ReactQuill from 'react-quill';
import FileUploader from '../FileUploader';

function InfoEditor() {
  const dispatch = useDispatch();

  const { id: hubID, content } = useSelector((state) => state.hubs.creator);
  const { text, media } = content.info;

  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState('');

  const _setInfo = (_info) => {
    if (_info.length > HUB_MAX_INFO_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    setError('');
    dispatch(setInfo(_info));
  };

  const setMedia = (fileUrl, fileType) => {
    dispatch(setInfoMedia({ source: fileUrl, type: fileType }));
  };

  return (
    <div>
      <h2 className="has-text-weight-bold is-size-6 has-text-black">
        Füge einen Infotext hinzu (optional)
      </h2>

      <div className="columns is-marginless mt-3 is-mobile">
        <div className="column is-paddingless">
          <ReactQuill
            theme="snow"
            value={text}
            onChange={(_content) => _setInfo(_content)}
            placeholder=""
            className="py-2"
          />
        </div>
      </div>

      <div className="has-text-centered mt-3">
        <p className="has-text-black has-text-weight-semibold">
          {error}
        </p>
      </div>

      <FileUploader hub={hubID} file={media} setFile={setMedia} />
    </div>
  );
}

/*

          <textarea
            onChange={(event) => _setInfo(event.target.value)}
            type="text"
            value={text}
            rows={4}
            placeholder="Danke, dass du dir die Zeit nimmst, um unsere Fragen zu beantworten!"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}

            className="textarea br10 no-shadow has-border p-3 has-fixed-size has-text-black is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
          />

*/

export default InfoEditor;
