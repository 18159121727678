import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setQuestionActive, setEndingActive, setInfoActive } from 'reducer/hubs';

// Components
import Question from './Question';
import InfoDisplay from './InfoDisplay';
import EndingDisplay from './EndingDisplay';

import { SwitchTransition, CSSTransition } from 'react-transition-group';

function BackButton(props) {
  return (
    <div className="has-text-left">
      <button
        aria-label="Zurück"
        type="button"
        onClick={props.goBack}
        className="button br8 boxshadow mb-4 has-text-midnightblue"
        style={{ backgroundColor: props.colors[0] }}
      >
        <i className="has-text-white fas fa-arrow-left" />
      </button>
    </div>
  );
}

function HubPreview(props) {
  // const bottomRef = useRef();

  const { content, colors } = useSelector((state) => state.hubs.creator);
  const dispatch = useDispatch();
  /*
  type: 'INFO',
    index: -1,
  */

  const ref = props.wrapper ? props.wrapper : useRef();

  const {
    info, questions, endings, logic, activeContent,
  } = content;

  const [isInit, setIsInit] = useState(true);
  const [answers, setAnswers] = useState([]);

  const scroll = () => {
    if (!ref || !ref.current) return;

    const div = ref.current;
    div.scrollTo({ top: 0, behavior: 'smooth' });

    if (!isInit) return;

    if (window.innerWidth >= 768) return;

    window.scrollTo({ top: window.document.body.scrollHeight, behavior: 'smooth' });
  };

  const goBack = () => {
    setIsInit(true);

    if (activeContent.index === 0) {
      dispatch(setInfoActive());
    } else {
      dispatch(setQuestionActive(activeContent.index - 1));
    }

    scroll();
  };

  // goes to the next question / ending
  const goToNextSection = () => {
    // if (activeQuestion + 1 >= questions.length) {
    //   setActiveEnding(0);
    //   return;
    // }

    if (activeContent.index + 1 >= questions.length) {
      dispatch(setEndingActive(0));
      return;
    }

    dispatch(setQuestionActive(activeContent.index + 1));

    // setActiveQuestion(activeQuestion + 1);
  };

  const triggerAction = (action) => {
    const { type: actionType, value } = action;

    // we only have goto atm
    if (actionType !== 'GOTO') {
      goToNextSection();
      return;
    }

    const { type, index } = value;

    if (type === 'QUESTION' && !!questions[index]) {
      dispatch(setQuestionActive(index));
    } else if (type === 'ENDING' && !!endings[index]) {
      dispatch(setEndingActive(index));
    } else {
      goToNextSection();
    }
  };

  const evaluateLogic = () => {
    // if there is no logic, just go to the next question / ending there is
    if (logic.length === 0) {
      goToNextSection();
      return;
    }

    for (let i = 0; i < logic.length; i++) {
      const { trigger, conditions, action } = logic[i];

      // search for a logic that should be applied after this question
      if (trigger !== activeContent.index) {
        continue;
      }

      let matchingConditions = 0;

      for (let j = 0; j < conditions.length; j++) {
        const {
          question, type, value, sign,
        } = conditions[j];

        const answer = answers[question];

        // if there is no answer for this question (for whatever reason), just go to the next condition
        if (answer === undefined) {
          continue;
        }

        const questionType = questions[question].type;

        // if type is answer it doesnt matter what the user picked, ANSWER on SLIDER just means the question has been answered
        // so the value does not matter
        if (questionType === 'SLIDER' && type === 'ANSWER') {
          matchingConditions++;
          // otherwise, check the actual value of the answer and compare it to the one we want
        } else if (type === 'ANSWER' && answer.value.toString() === value) {
          matchingConditions++;
        }
      }

      // if all conditions are met, trigger the action
      if (matchingConditions === conditions.length) {
        triggerAction(action);
        return;
      }
    }

    // otherwise, just go to the next section
    goToNextSection();
  };

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    if (!ref || !ref.current) return;

    // only does something for mobile: scroll to top after activeContent has changed
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeContent.type, activeContent.index]);

  useEffect(() => {
    if (answers.length === 0) return;

    evaluateLogic();
  }, [answers]);

  const saveAnswer = (_values, _input = '') => {
    setIsInit(true);

    const { index } = activeContent;
    // if answer to this question does not yet exist, add it
    if (index >= answers.length) {
      setAnswers([...answers, { values: _values, input: _input }]);
    } else {
      // otherwise, update it
      const _answers = answers.map((answer, _index) => {
        if (_index !== index) return answer;

        return { values: _values, input: _input };
      });

      setAnswers(_answers);
    }

    scroll();
  };

  const startQuestions = () => {
    // setActiveQuestion(0);
    dispatch(setQuestionActive(0));
  };

  const showContent = () => {
    const { type, index } = activeContent;

    // TODO: show logic map?
    if (type === 'LOGIC') return <div />;

    if (type === 'ENDING') {
      return (
        <div className="columns is-flex is-vcentered is-centered m-0 has-flex-1 has-content-centered is-mobile">
          <div className="column is-10-desktop is-11-tablet is-12-mobile">
            <EndingDisplay index={index} />
          </div>
        </div>
      );
    }

    if (type === 'INFO') {
      return (
        <div className="columns is-flex is-vcentered is-centered m-0 has-flex-1 has-content-centered is-mobile">
          <div className="column is-10-desktop is-11-tablet is-12-mobile">
            <InfoDisplay startQuestions={startQuestions} />
          </div>
        </div>
      );
    }

    return (
      <div className="columns is-flex is-vcentered is-centered m-0 has-flex-1 has-content-centered is-mobile">
        <div className="column is-10-desktop is-11-tablet is-12-mobile">
          {
              index > 0 && <BackButton goBack={goBack} colors={colors} />
            }
          <Question
            index={index}
            saveAnswer={saveAnswer}
            key={index}
          />
        </div>
      </div>
    );
  };

  // dont animate if type is switched to LOGIC
  const key = useMemo(() => {
    const { type, index } = activeContent;
    let i = index;

    if (type === 'INFO') i++;

    if (type === 'QUESTION') i += 10;

    if (type === 'ENDING') i += 100;

    return i;
  }, [activeContent]);

  return (
    <div ref={ref} className={`is-flex flex-direction-column has-flex-1 has-text-centered has-background-grey-lighter py-6 has-overflow-y-auto is-relative ${props.wrapper ? '' : 'has-fullheight'}`}>
      <div className="columns is-multiline is-absolute top-0 has-fullwidth">
        <div className="column p-1 is-hidden-mobile">
          { activeContent.type !== 'LOGIC' && (
            <p className="has-text-left has-text-weight-bold is-size-7 ml-4 mt-5">
              Interaktive Vorschau - Probiere es aus!
            </p>
          )}
        </div>
      </div>

      <SwitchTransition>
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={key}
          onEnter={scroll}
        >
          { showContent() }
        </CSSTransition>
      </SwitchTransition>
    </div>

  );
}

HubPreview.propTypes = {
  wrapper: PropTypes.node,
};

HubPreview.defaultProps = {
  wrapper: null,
};

export default HubPreview;
