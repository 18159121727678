import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import InfoPopup from 'components/utils/InfoPopup';

const IsOptionalEditor = (props) => {
  const { isOptional, setOptional, id } = props;

  return (
    <div className="field dont-select-text">
      <input
        type="checkbox"
        checked={isOptional}
        id={`isOptional_${id}`}
        className="is-checkradio"
        onChange={() => setOptional(!isOptional)}
      />

      <label className="has-text-centered" htmlFor={`isOptional_${id}`}>
        Frage ist optional
      </label>
      <InfoPopup
        text="Frage muss nicht zwingend beantwortet werden und kann übersprungen werden."
      />
    </div>
  );
};

IsOptionalEditor.propTypes = {
  isOptional: PropTypes.bool.isRequired,
  setOptional: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default IsOptionalEditor;
