import React from 'react';
import { useSelector } from 'react-redux';
import * as routes from 'constants/routes';
import './css/websiteContainer.scss';
import {
  Link,
} from 'react-router-dom';
import Website from './Website';

/*
 * Container that holds all the websites
 */
const WebsiteContainer = () => {
  const { hasData, websites } = useSelector((state) => state.websiteState);
  const { user } = useSelector((state) => state.sessionState);

  const showWebsites = () => {
    if (!hasData) {
      return null;
    }

    // const websitesInOrder = websites.reverse();

    return websites.map((website, index) => (
      <Website
        id={index}
        key={website._id}
        isOnboarding={false}
      />
    ));
  };

  const amountWebsites = user.websiteCount || Object.keys(websites).length;

  // const showMore = websitesToShow.length < websites.length;
  // const showLess = websitesToShow.length > WEBSITES_TO_SHOW;

  return (
    <div className="">
      <h2 className="has-text-weight-bold has-text-yellow is-size-3-desktop is-size-4-tablet is-size-5-mobile">
        Deine Webseiten
      </h2>
      <div className="columns is-multiline is-centered is-paddingless m0">
        <div className="column m0 p0">
          <div>
            <p className="has-text-weight-bold has-text-black is-size-5">{`Anzahl: ${amountWebsites}`}</p>
            <div
              className="columns is-multiline is-centered has-max-height-500 has-overflow-y-auto box p-5 m-0 mt-3 is-mobile"
              id=""
            >
              <div className="box has-background-white column boxshadow is-2 mb20 website has-hover">
                <Link
                  className="columns cleanButton is-vcentered has-fullheight has-fullwidth is-marginless is-paddingless"
                  to={routes.ADD_WEBSITE}
                >
                  <div className="column has-text-centered">
                    <i className="mt20 fas fa-plus-circle has-text-yellow is-size-2" />
                    <p className="has-text-black is-size-5 mt10 has-text-weight-bold">Website hinzufügen</p>
                  </div>
                </Link>
              </div>
              {showWebsites()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteContainer;
