import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

// Components
import MediaDisplay from 'components/hub/MediaDisplay';
import NoAuthFilePopup from './NoAuthFilePopup';
import formatBytes from 'utils/formatBytes';

/**
 * Drag & Drop Zone to upload images
 */
function FileUploader(props) {
  const { isAuthenticated } = useAuth0();

  const { fileStorageUsedBytes, planDetails } = useSelector((state) => state.sessionState.user);
  const { maxfileSizeBytes, fileStorageSizeBytes } = planDetails;

  const {
    hub, file, setFile,
  } = props;

  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const removeFile = () => {
    URL.revokeObjectURL(file.source);
    setFile('', '');
  };

  /* useEffect(() => {
     // if there is an old blob url in the state, remove it
    if (file.source.includes('blob:')) {
      setFile('', '');
}
  }, []);

  useEffect(() => () => {
    if (file.source.includes('blob:')) {
      // URL.revokeObjectURL(file.source);
    }
  }, [file]); */

  /*
  const getFileSource = () => {
    // if it's a local blob file, just return the blob url
    if (file.source.includes('blob:')) {
      return sanitizeUrl(file.source);
    }

    // if its already saved on the cdn, load it from there
    return sanitizeUrl(`${process.env.REACT_APP_HUB_FILES}/hubs/${hub}/${file.source}`);
  };

  const fileDisplay = useMemo(() => {
    if (!hub || !file || !file.source || !file.type) return null;

    if (file.type.includes('image')) {
      return (
        <div className="has-border-white-bold has-width-128 has-height-128 has-overflow-hidden p-0 has-background-white my-3">
          <img
            src={getFileSource()}
            onError={removeFile}
            className="has-fullheight has-fullwidth"
            alt=""
            onLoad={() => { setReady(true); }}
          />
        </div>
      );
    }

    return (
      <div className="br10 has-overflow-hidden">
        <ReactPlayer
          url={getFileSource()}
          width="100%"
          height="100%"
          controls
          className="has-min-height-200"
        />
      </div>

    );
  }, [file]); */

  const loadFile = (_file) => {
    setDisabled(true);

    // if file has been rejected, data will be empty
    if (_file.length === 0) {
      setDisabled(false);
      return;
    }

    setError('');

    URL.revokeObjectURL(file.source);

    const url = URL.createObjectURL(_file[0]);

    setFile(url, _file[0].type);

    setDisabled(false);
  };

  // check if there is enough file space for the user
  const fileStorageValidator = (_file) => {
    if (_file.size + fileStorageUsedBytes > fileStorageSizeBytes) {
      return {
        code: 'not-enough-storage',
        message: 'Dein Speicher ist voll! Lösche ein paar andere Dateien oder ändere dein Abonnement!',
      };
    }

    return null;
  };

  const fileValidator = (_file) => {
    const allowedFiles = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'audio/mpeg', 'video/mpeg', 'video/mp4'];

    if (!_file.type || !allowedFiles.includes(_file.type)) {
      return {
        code: 'file-invalid-type',
        message: '',
      };
    }

    return null;
  };

  const onDropRejected = (files) => {
    const { errors } = files[0];

    if (errors[0].code === 'too-many-files') {
      setError('Bitte wähle nur eine Datei!');
      return;
    }

    if (errors[0].code === 'file-too-large') {
      setError('Die ausgewählte Datei ist zu groß! (maximal 2 MB)');
      return;
    }

    if (errors[0].code === 'file-invalid-type') {
      setError('Bitte wähle eine gültige Datei! (JPG, PNG, GIF, MP3 oder MP4)');
      return;
    }

    if (errors[0].code === 'not-enough-storage') {
      setError(errors[0].message);
      return;
    }

    setError('Bitte lade eine gültige Datei hoch! (jpg/png/gif, audio oder video und maximal 2 MB groß)');
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    /*     accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'audio/mpeg': ['.mp3'],
      'video/mpeg': ['.mpeg'],
      'video/mp4': ['.mp4'],
    }, */
    noDrag: true,
    onDrop: loadFile,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
    disabled,
    validator: fileValidator,
  });

  // A file cannot be uploaded is user is not logged in.
  if (!isAuthenticated) return <NoAuthFilePopup />;

  // Füge ein Video, ein Bild oder eine Audiodatei hinzu (optional, maximal 2 MB)
  return (
    <div
      className="control is-flex flex-direction-column has-content-centered mt-4"
    >
      <MediaDisplay file={file} hubID={hub} />
      {
          file.source
          && (
          <div className="has-text-right mb-4 mt-2">
            <button
              type="button"
              className="button has-text-danger has-text-weight-bold has-text-underlined is-size-7"
              onClick={removeFile}
            >
              Entfernen
            </button>
          </div>
          )
        }

      <div className="has-fullwidth">
        <div {...getRootProps({
          className: 'm-0 has-height-40 is-inline-block',
        })}
        >
          <input {...getInputProps()} />
          <div className="has-text-left">
            <div className="">
              <button
                type="button"
                className="has-border button br10 py-1 px-3 has-fullheight has-background-white has-cursor-pointer has-hover"
              >
                {
                file.source ? (
                  <>
                    <i className="fas fa-plus has-text-pastel is-size-7 mr-2" />
                    <p className="has-text-grey is-size-7 has-text-weight-semibold is-inline">
                      Datei ersetzen
                    </p>
                  </>
                )
                  : (
                    <>
                      <i className="fas fa-plus has-text-pastel is-size-7 mr-2" />
                      <p className="has-text-grey is-size-7 has-text-weight-semibold is-inline">
                        Datei hinzufügen
                      </p>
                    </>
                  )
              }

              </button>
            </div>
          </div>
        </div>
      </div>

      <p className="has-text-black has-text-weight-bold is-size-7 has-text-centered mt-3">
        { error }
      </p>
    </div>
  );
}

FileUploader.propTypes = {
  file: PropTypes.shape({
    source: PropTypes.string,
    type: PropTypes.string,
  }),
  setFile: PropTypes.func.isRequired,
  hub: PropTypes.string,
};

FileUploader.defaultProps = {
  file: {
    source: '',
    type: '',
  },
  hub: '',
};

export default FileUploader;
