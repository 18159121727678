import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useNavigate } from 'react-router-dom';

// Constants
import { HUB_TEMPLATE } from 'constants/routes';

// Components
import Template from 'components/templates/Template';

function HubTemplates(props) {
  const { templates } = props;

  const navigate = useNavigate();

  const goToTemplatePage = (template) => {
    navigate({
      pathname: `${HUB_TEMPLATE}/${template.id}`,
    });
  };

  const renderedTemplates = useMemo(() => templates.map((template, index) => (
    <div className="column is-multiline is-narrow-desktop is-narrow-tablet is-12-mobile is-flex has-content-centered" key={index}>
      <Template
        templateData={template}
        onSelect={() => goToTemplatePage(template)}
      />
    </div>
  )), []);

  return (
    <div className="columns is-centered is-mobile">
      { renderedTemplates }
    </div>
  );
}

HubTemplates.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HubTemplates;
