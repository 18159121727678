import React from 'react';
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setColor } from 'actions/creator/color';
import { SketchPicker } from 'react-color';

const ColorSelector = (props) => {
  const { step, isVisible } = props;

  const { color } = useSelector((state) => state.creatorState);

  const dispatch = useDispatch();

  const sendAnalytics = () => {
    stats.push(['trackEvent', 'Widget Creator - Color', 'Color selected']);
  };

  const setWidgetColor = (newColor) => {
    dispatch(setColor(newColor.hex));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="has-text-black mt-3 mb-5">
      <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">
        {step}
      </p>
      <h2 className="has-text-weight-bold is-size-6 has-text-centered is-inline">
        Wähle die Farbe für deinen Flowter
      </h2>
      <div className="mb10 mt20 has-info has-text-centered">
        <SketchPicker
          color={color}
          onChange={setWidgetColor}
          onChangeComplete={sendAnalytics}
          className="is-in-center"
        />
      </div>
    </div>
  );
};

ColorSelector.propTypes = {
  step: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
};

ColorSelector.defaultProps = {
  isVisible: true,
};

export default ColorSelector;
