import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';

const ScriptWordpress = () => (
  <>
    <div className="has-text-centered">
      <i className="fab fa-wordpress has-text-info is-size-1" />
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-left has-text-weight-bold has-text-black">Installation für WordPress / WooCommerce</p>
    <div className="mt20">
      <p className="is-size-6">Wir haben ein WordPress Plugin entwickelt, mit dem die Installation ganz einfach ist.</p>
      <p className="mt30 has-text-weight-bold">So fügst Du deiner Seite Flowdust hinzu:</p>
    </div>
    <div className="mt20 ">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Logge dich in deinen WordPress Account ein</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf Plugins auf der linken Seite</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Installieren"</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Gib oben rechts als Suchbegriff "Flowdust" ein</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'5. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke beim Flowdust Plugin auf "Jetzt installieren" und dann auf "Aktivieren"</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'6. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf der linken Seite auf "Flowdust"</p>
    </div>
    <div className="mt20 mb30">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'7. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere diesen HTML-Code und füge ihn in das "Dein Flowdust Script"-Eingabefeld ein:</p>
    </div>
    <ScriptTagCopy />
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'8. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf Änderungen speichern</p>
    </div>
    <div className="mt-2 mb-5 has-background-pastel br10 has-text-white p20">
      <div className="mb10">
        <i className="fas fa-question-circle mr10 is-inline" />
        <p className="is-size-6 has-text-weight-bold mb20 is-inline">Eine Sache noch...</p>
      </div>
      <p className="has-text-weight-semibold">1. Warte ein paar Minuten nachdem Du das Skript installiert hast. WordPress Seiten sind für gewöhnlich gecached und es dauert eine Weile bis deine Website die Änderungen anzeigt.</p>
      <p className="mt20 has-text-weight-semibold">2. Wenn Du ein Cache-Plugin auf deiner Seite benutzt, musst Du diesen Cache erst leeren nachdem Du Flowdust installiert hast.</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);

export default ScriptWordpress;
