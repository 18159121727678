import 'react-app-polyfill/ie11';
import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import App from './App';
// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    normalizeDepth: 11,

    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,

  });
}

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// serviceWorker.unregister();
