import React, { useState, useEffect } from 'react';

// Libraries

import { useNavigate } from 'react-router-dom';
import notification from 'utils/notification';
import axios from 'axios';
import stats from 'analytics/analytics';
import { Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Components
import WebsiteForm from './WebsiteForm';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

// HOCs
import withAuthentication from '../hoc/withAuthentication';

// Used by the routes.ADD_WEBSITE route
function AddWebsite() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [signal, setSignal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddWebsite = (website) => dispatch({ type: 'ADD_WEBSITE', website });
  const incrementWebsiteCount = () => dispatch({ type: 'INC_WEBSITE_COUNT' });

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  const saveNewWebsite = async (websiteData) => {
    // TODO: enter should save
    setDisabled(true);
    setError('');

    try {
      const token = await getAccessTokenSilently();

      const newSignal = axios.CancelToken.source();
      setSignal(newSignal);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: newSignal.token,
      };

      const site = await axios.post(`${process.env.REACT_APP_API}/websites`, websiteData, config);

      // Save in redux
      onAddWebsite(site.data);
      incrementWebsiteCount();

      // Matomo
      stats.push(['trackEvent', 'Website Creator', 'Website created']);

      notification.send('Fertig!', 'Deine Website wurde hinzugefügt.', 'success');
      navigate(routes.FLOWTER_LIST);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err);
      console.log(err.response);

      setDisabled(false);

      if (!err.response) {
        setError('Es ist etwas schief gelaufen. Bitte versuche es später noch einmal.');
        return;
      }

      if (err.response.status === 500) {
        setError('Server Fehler - Bitte versuche es später noch einmal.');
        return;
      }

      setError(err.response.data);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Website hinzufügen
        </title>
      </Helmet>
      <div className="columns is-centered is-vcentered has-fullheight is-marginless">
        <div className="column is-12-mobile is-7-desktop is-8-tablet mt20 mb20 pl20 pr20">
          <p className="mt10 has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile">Füge deine Website hinzu</p>
          <p className="mb30 mt10">Um deinen Flowter mit deiner Website verknüpfen zu können, füge bitte hier deine Website hinzu.</p>
          <WebsiteForm
            saveButtonText="Website hinzufügen"
            setError={setError}
            saveForm={saveNewWebsite}
            disabled={disabled}
          />
          {error && <p className="mb20 has-text-weight-bold has-text-black">{error}</p>}
        </div>
      </div>
    </>
  );
}

export default withAuthentication(AddWebsite);
