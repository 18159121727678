import React, { useState, useLayoutEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import SelectableElement from './SelectableElement';

const ElementCategory = (props) => {
  const [isActive, setIsActive] = useState(false);

  const {
    selectElement,
    values,
    name,
    selectedValues,
  } = props;

  useLayoutEffect(() => {
    if (selectedValues.length !== 0) {
      setIsActive(true);
    }
  }, [selectedValues]);

  /**
   * Called function after a nested element has been clicked
   * @param {Object} element: The clicked element, containing a label and a value
   */
  const clickElement = (element) => {
    selectElement(name, element);
  };

  /*
   * Toggle the isActive state to determine if the nested elements should be shown
   */
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  /**
    * Render function for each individual selectable nested element
    */
  const showElements = () => values.map((_value, index) => {
    const {
      label: elementLabel, value: elementValue,
    } = _value;

    const isSelected = selectedValues.find((item) => item.value === elementValue);

    return (
      <SelectableElement
        label={elementLabel}
        value={elementValue}
        category={name}
        clickElement={clickElement}
        isSelected={!!isSelected}
        key={index}
      />
    );
  });

  return (
    <div className="my-2 py-2 dont-select-text">
      <div className="field">
        <div className="columns is-mobile px-3 m-0">
          <div className="column is-narrow py-3 px-0">
            <input
              type="checkbox"
              id={name}
              checked={selectedValues.length !== 0}
              className="is-checkradio"
              onChange={() => selectElement(name)}
            />

            <label
              className="has-text-centered has-text-weight-bold has-text-black"
              htmlFor={name}
            >
              {name}
            </label>
          </div>
          <div className="column py-3 px-0">
            <button
              type="button"
              onClick={toggleActive}
              className="cleanButton has-fullwidth has-fullheight has-text-right px-4"
            >
              <i className={`fas fa-${isActive ? 'minus' : 'plus'}`} />
            </button>
          </div>
        </div>
      </div>
      {
        isActive
        && (
          <div className="mx-3">
            {showElements()}
          </div>
        )
      }
    </div>
  );
};

ElementCategory.propTypes = {
  /*
   * All the nested values for that category
   */
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  /*
   * All Values that are currently selected for this category
   */
  selectedValues: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  /*
   * The name of the category
   */
  name: PropTypes.string.isRequired,
  /*
   * Function that gets called after an element has been clicked
   */
  selectElement: PropTypes.func.isRequired,

};

export default ElementCategory;
