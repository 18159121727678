import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setQuestion,
} from 'reducer/hubs';

// Constants
import {
  HUB_MAX_QUESTION_LENGTH,
} from 'constants/hub';

const QuestionEditor = (props) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState('');

  const { id } = props;

  const question = questions[id];

  const { text } = question;

  const _setQuestion = (_text) => {
    if (_text.length > HUB_MAX_QUESTION_LENGTH) {
      stats.push(['trackEvent', 'Hub Content Editor', 'Question text max length reached']);

      setError('Dein Text ist leider etwas zu lang.');
      return;
    }

    setError('');

    dispatch(setQuestion({ id, text: _text }));
  };

  /*
</div>
{
 isFocus
 && (
   <div className="column is-narrow is-paddingless has-text-right is-relative">
     <p className="has-text-grey-light dont-select-text is-inline">
        {`${text.length} / ${HUB_MAX_QUESTION_LENGTH}`}
     </p>
   </div>
 )
}
</div>
  */

  return (
    <div>
      <p className="is-size-7 has-text-grey">Frage</p>

      <div className="columns is-marginless mt-1 is-mobile">
        <div className="column is-paddingless has-overflow-hidden">
          <textarea
            onChange={(event) => _setQuestion(event.target.value)}
            type="text"
            value={text}
            rows={4}
            questionindex={id}
            placeholder="Deine Frage"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className="textarea br10 no-shadow has-border p-3 has-fixed-size has-text-black is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
          />
        </div>
      </div>

      <div className="has-text-centered mt-3">
        <p className="has-text-black has-text-weight-semibold">
          {error}
        </p>
      </div>

    </div>
  );
};

QuestionEditor.propTypes = {
  id: PropTypes.number.isRequired,
};

export default QuestionEditor;
