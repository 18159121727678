import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import ReactQuill from 'react-quill';
import MediaDisplay from 'components/hub/MediaDisplay';
import DurationDisplay from './DurationDisplay';

/*
<p className="mb-2 is-size-5-desktop is-size-6-touch has-text-weight-medium has-text-black pb-3 show-linebreaks">
                  {text}
                </p>
*/

function InfoDisplay(props) {
  const { startQuestions } = props;

  const { t } = useTranslation(['hub']);

  const { colors, content, id } = useSelector((state) => state.hubs.creator);
  const { info } = content;

  if (!info) return null;

  const { text, media } = info;

  return (
    <div className="box boxshadow br8 p-5 has-min-height-400 is-flex flex-direction-column">
      <div className="columns is-flex is-vcentered is-centered has-flex-1 is-mobile is-multiline">
        <div className="column has-min-width-300 has-border-right is-flex has-content-centered flex-direction-column is-12-mobile">
          {
            text && (
              <div className="nmt-2">
                <p className="has-text-grey has-text-medium is-size-7-mobile">
                  {t('hub:info')}
                </p>
                <ReactQuill
                  value={text}
                  readOnly
                  theme="snow"
                  className="quill-hide-toolbar"
                />

              </div>
            )
          }
          <button
            type="button"
            onClick={startQuestions}
            className="br6 button has-text-white is-size-6 grow"
            style={{ backgroundColor: colors[0] }}
          >
            <i className="is-size-7 mt-1
               has-text-white fas fa-chevron-right"
            />
            <p className="ml-2 has-text-weight-semibold">
              {t('hub:start')}
            </p>
          </button>
          {
            !media.source
            && (
            <div className="has-text-centered mt-4">
              <DurationDisplay />
            </div>
            )
          }
        </div>
        {
             media.source
             && (
             <div className="column has-min-width-300 is-flex has-content-centered">
               <div className="has-fullwidth p-3">
                 <div className="mb-5">
                   <MediaDisplay file={media} hubID={id} isCreator />
                 </div>
                 <div className="has-text-centered">
                   <DurationDisplay />
                 </div>
               </div>
             </div>
             )
        }
      </div>
    </div>
  );
}

InfoDisplay.propTypes = {
  startQuestions: PropTypes.func.isRequired,
};

export default InfoDisplay;
