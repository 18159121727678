import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';
import { Link } from 'react-router-dom';

// Components
import StatusBar from './StatusBar';
import WelcomeMessage from './WelcomeMessage';
import UserStatisticBox from 'components/billing/UserStatisticBox';
import WebsiteContainer from 'components/websites/WebsiteContainer';
import WidgetContainer from 'components/widgets/WidgetContainer';
import HubList from 'components/hub/HubList';

// Styles
import './css/home.scss';
import { FLOWTER_LIST, HUB_LIST } from 'constants/routes';

/**
 <div className="columns is-multiline">
              <div className="column">
                <WidgetContainer />
              </div>
              <div className="column">
                <WebsiteContainer />
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <HubList />
              </div>
            </div>
 */

/*
<div className="column has-text-right mt5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.flowdust.com/erste-schritte"
                  className="has-text-black has-text-weight-bold is-size-5"
                >
                  <p className="is-inline mr5">
                    Brauchst Du Hilfe? Hier gehts zur Kurzanleitung
                  </p>
                  <i className="is-inline fas fa-external-link-alt is-size-7" />
                </a>
              </div>
*/

/**
 * The Dashboard.
 * Here, the user controls all his widgets and websites.
 */
function Home() {
  return (
    <>
      <Helmet>
        <title>Flowdust - Dein Dashboard</title>
      </Helmet>

      <div id="homeWrapper">
        <div className="is-bold has-background-dash">
          <div className="columns is-marginless">
            <div className="column p20">
              <StatusBar />
              <div className="columns is-marginless box boxshadow mb-4">
                <div className="column is-narrow">
                  <p className="is-size-4 has-text-black has-text-weight-bold">Dashboard</p>
                </div>

              </div>
              <div className="pb20">
                <WelcomeMessage />
                <p className="has-text-weight-light has-text-grey is-size-5 mb-4 ">Schön, dass Du hier bist.</p>
                <div className="columns is-marginless">
                  <div className="column is-4">
                    <UserStatisticBox />
                  </div>
                </div>
                <Link
                  to={HUB_LIST}
                  className="box boxshadow has-hover my-5 is-inline-flex has-content-centered mr-3"
                >
                  <i className="fas fa-calendar-plus is-size-5 has-text-info mr-3" />
                  <p className="has-text-black is-size-5-desktop is-size-6-touch has-text-weight-bold">
                    Zu den Hubs
                  </p>
                </Link>
                <Link
                  to={FLOWTER_LIST}
                  className="box boxshadow has-hover my-5 is-inline-flex has-content-centered"
                >
                  <i className="fas fa-plus-circle is-size-5 has-text-pastel mr-3" />
                  <p className="has-text-black is-size-5-desktop is-size-6-touch has-text-weight-bold">
                    Zu den Flowtern
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthentication(Home);
