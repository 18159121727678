import React from 'react';

const ScreenshotPage = () => {
  const downloadIcon = (name, href) => {
    const link = document.createElement('a');
    link.href = href;
    link.download = `Flowdust_Screenshot_${name}.png`;
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <div>
        <p
          className="has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold"
        >
          Screenshots
        </p>
        <p
          className="mt-4"
        >
          Einige Beispiele der Flowdust-Oberfläche, die verwendet werden dürfen.
        </p>
        <div className="mt-5">
          <p>Screenshots</p>
        </div>
      </div>

    </div>
  );
};

export default ScreenshotPage;
