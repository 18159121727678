import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setDescription } from 'reducer/hubs';

// Constants
import { HUB_MAX_DESC_LENGTH } from 'constants/hub';

// Components
import InfoPopup from 'components/utils/InfoPopup';

const DescCreator = () => {
  const { creator } = useSelector((state) => state.hubs);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const { description } = creator;

  /*
   * Validates and sets the description to state
   */
  const _setDescription = (_description) => {
    setError('');

    // check max length
    if (_description.length >= HUB_MAX_DESC_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    dispatch(setDescription(_description));
  };

  return (
    <>
      <div className="field">
        <label className="label is-inline">
          Beschreibung
        </label>
        <label className="mx-2 has-text-grey label is-inline has-text-weight-medium">
          (Optional)
        </label>
        <InfoPopup
          text="Erzähle etwas über dich und dein Unternehmen, indem du eine Beschreibung hinzufügst. Diese wird im Header deines Hubs angezeigt."
        />
        <div className="control">
          <input
            onChange={(event) => _setDescription(event.target.value)}
            type="text"
            value={description}
            placeholder="Beschreibung"
            className="input br5 is-size-6 has-text-black has-background-white"
          />
          <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
            { error }
          </p>
        </div>
      </div>
    </>
  );
};

export default DescCreator;
