import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const IndexDisplay = (props) => {
  const { index, max } = props;

  // don't show IndexDisplay if there's only one question
  if (max === 1) return null;

  const isDone = index === -2;
  const indexToDisplay = (index < 0 ? max : index + 1);

  return (
    <div id="indexDisplay">
      <p>
        {
          isDone
            ? (
              'Abgeschlossen!'
            )
            : (
              `Frage ${indexToDisplay} / ${max}`
            )
        }
      </p>
    </div>

  );
};

IndexDisplay.propTypes = {
  index: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default IndexDisplay;
