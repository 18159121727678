import React, { useState, useEffect } from 'react';

// Libraries
import * as Sentry from '@sentry/react';
import stats from 'analytics/analytics';
import { v4 as uuidv4 } from 'uuid';

// Actions
import { setFlowters } from 'actions/flowter';
import { setWebsites } from 'actions/websites';
// import { setStatusWidgets } from 'actions/statusWidgets';
import { setUserObj } from 'actions/user';
import { setStatCookies } from 'actions/cookies';
import { setComingFromDemo as setComingFromHubDemo, setHubs } from 'reducer/hubs';
import { setComingFromDemo as setComingFromFlowterDemo } from 'reducer/flowterCreator';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// Constants
import * as routes from 'constants/routes';
import * as status from 'constants/status';

// Assets
import loader from 'assets/images/loading.svg';

/**
 * After auth0 authentication is done, the user is directed to /login
 * where the user gets loaded from the database.
 * Afterwards, the user gets redirected to /home
 */
function Login() {
  const {
    isAuthenticated, user: auth0User, logout,
  } = useAuth0();

  const { get, post } = useAPI();

  const { statCookies } = useSelector((state) => state.cookieState);
  const { hubs, creator } = useSelector((state) => state.hubs);
  const { content, isComingFromDemo: isComingFromHubDemo } = creator;

  const { isComingFromDemo: isComingFromFlowterDemo } = useSelector((state) => state.flowterCreator);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [error, setError] = useState('');

  // if a user created a hub in the demo, create a new hub and save the current content
  // then, go to the editor to show it to the user
  const finishUpHubDemo = async () => {
    try {
      // TODO: check if user can create hub
      // generate random id to create a unique url
      const id = uuidv4().replace(/-/gi, '').slice(16);
      const hub = await post('/hubs', { url: id });

      // change its contents to match the current creator state
      hub.content = {
        ...hub.content,
        ...content,
        // make sure every question has a text, even a generic one (will be shown in statistic)
        questions: content.questions.map((_question) => {
          const { text } = _question;

          if (!text) {
            return {
              ..._question,
              text: 'Wie gefällt dir unser Produkt?',
            };
          }

          return _question;
        }),
        // make sure every ending has a text, even a generic one (will be shown in statistic)
        endings: content.endings.map((_ending) => {
          const { text } = _ending;

          if (!text) {
            return {
              ..._ending,
              text: 'Danke für deine Hilfe!',
            };
          }

          return _ending;
        }),
      };

      // we dont ADD, but SET, as we need to have the hasData variable to be set to true (so the editor does not kick us out)
      // When we go back to HubList, we will load all the other hubs
      dispatch(setHubs([hub]));
      // clear both comingFromDemos
      dispatch(setComingFromFlowterDemo(false));
      dispatch(setComingFromHubDemo(false));

      // and finally, go to the hub editor
      navigate(`${routes.HUB_CONTENT}/${hubs.length}`);
    } catch (e) {
      // clear both comingFromDemos
      dispatch(setComingFromFlowterDemo(false));
      dispatch(setComingFromHubDemo(false));
      console.log(e);
      navigate(routes.HOME);
    }
  };

  const finishUpFlowterDemo = () => {
    // clear both comingFromDemos
    dispatch(setComingFromFlowterDemo(false));
    dispatch(setComingFromHubDemo(false));
    navigate(routes.FLOWTER_CREATOR);
  };

  useEffect(() => {
    const getData = async () => {
      // clear any data, then load new one
      // dispatch(clearCompleteState());
      const user = await loadUserData();

      // exception has been thrown if user is null
      if (!user) return;

      await loadWebsites();
      await loadWidgets();

      if (user.status === status.NEW_USER) {
        navigate(routes.NEW_USER);
        return;
      }

      if (isComingFromHubDemo) {
        await finishUpHubDemo();
        return;
      }

      if (isComingFromFlowterDemo) {
        finishUpFlowterDemo();
        return;
      }

      navigate(routes.HOME);
    };

    // If the user is not authenticated via auth0, go back to landing
    if (!isAuthenticated) {
      navigate(routes.LANDING);
      return;
    }

    getData();
    // eslint-disable-next-line
	}, []);

  const loadWebsites = async () => {
    try {
      const items = await get('/websites');
      dispatch(setWebsites(items));
    } catch (e) {
      setWebsites([]);

      console.log(e);
    }
  };

  const loadWidgets = async () => {
    try {
      const items = await get('/users/widgets');
      // const { stautsWidgets } = items;
      const { flowters } = items;

      dispatch(setFlowters(flowters || []));
      // dispatch(setStatusWidgets(statusWidgets || []));
    } catch (e) {
      console.log(e);
    }
  };

  const loadUserData = async () => {
    try {
      const { email, nickname } = auth0User;

      const name = nickname.charAt(0).toUpperCase() + nickname.slice(1);
      const data = {
        statCookies,
        name,
        email,
      };

      // Signs up user or just returns user values
      const response = await post('/users', data);

      const userData = response.user;

      if (!userData) {
        logout({ returnTo: window.location.origin });
      }

      if (userData.name === undefined) {
        userData.name = nickname;
        userData.email = email;
      }

      const userCookies = userData.statCookies;
      delete userData.statCookies;

      dispatch(setUserObj(userData));

      if (userCookies === true) {
        stats.push(['rememberConsentGiven']);
        dispatch(setStatCookies(true));
      }

      stats.setUserId(userData.email);
      stats.push(['trackEvent', 'Account', 'Sign In']);
      stats.push(['trackPageView']);

      Sentry.setUser({ email: userData.email });

      // TODO: refactor this
      if (statCookies === true && userCookies === false) {
        await post('/users/cookies', { statCookies: true }).catch((err) => console.log(err));
      }

      return userData;
    } catch (err) {
      if (err.response) {
        setError(err.response.data);
        return null;
      }

      if (!err.response) {
        setError('Etwas hat nicht funktioniert. Bitte logge dich erneut ein. Wenn das Problem bestehen bleibt, wende dich an support@flowdust.com');
        return null;
      }

      if (err.response.status === 500) {
        setError('Server Fehler. Bitte versuche es später noch einmal. Wenn das Problem bestehen bleibt, wende dich an support@flowdust.com');
        return null;
      }
      setError(err.response.data);
      return null;
    }
  };

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {
               error.length === 0
                 ? (
                   <>
                     <img className="has-text-centered" src={loader} alt="" />
                     <p className="is-size-7">Laden...</p>
                   </>
                 )
                 : <p className="has-text-centered ml3 mr30 has-text-danger has-text-weight-bold is-size-4">{error}</p>
             }
        </div>
      </div>
    </div>
  );
}

export default Login;
