import React, { useEffect, useState, useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import MediaDisplay from 'components/hub/MediaDisplay';
import Chart from '../Chart';
import LinkStatistic from './LinkStatistic';

const EndingStatistic = () => {
  const { hubs, statistic: statisticData } = useSelector((state) => state.hubs);
  const { _id, hubIndex, resultsPerEnding } = statisticData;

  const { endings } = hubs[hubIndex].content;

  const [statistic, setStatistic] = useState([]);

  useEffect(() => {
    // if (resultsPerEnding.length === 0) return;

    const data = [];

    for (let i = 0; i < resultsPerEnding.length; i++) {
      const { linksClicked, amount } = resultsPerEnding[i];

      const { text, media, link } = endings[i];

      data.push({
        x: `Ende Nr. ${i + 1}: ${text}`, y: amount, index: i, value: i,
      });
    }

    setStatistic(data);
  }, [resultsPerEnding]);

  const renderedResultsPerEnding = useMemo(() => endings.map((ending, index) => {
    const result = resultsPerEnding.find((_result) => _result.ending === index);

    const hasData = result !== undefined;

    let renderedStatistic;

    if (hasData) {
      const { linksClicked, amount } = result;
      const hasLinkData = linksClicked && linksClicked.link;

      renderedStatistic = (
        <div className="mt-5 mb-4">
          <p className="has-text-weight-semibold has-text-black is-size-5">
            {`${amount}`}
          </p>
          <p className="has-text-weight-semibold has-text-black">
            {
              amount === 1 ? 'hat dieses Ende erreicht' : 'haben dieses Ende erreicht'
            }
          </p>
          {
            hasLinkData && <LinkStatistic data={linksClicked} />
          }
        </div>
      );
    } else {
      renderedStatistic = (
        <p className="has-text-weight-semibold is-6 has-text-black has-text-centered mt-4">
          Bisher hat noch niemand dieses Ende erreicht
        </p>
      );
    }

    const { text, media, link } = endings[index];

    return (
      <div className="my-4" key={index}>
        {
          endings.length > 1
          && (
          <p className="mb-4 has-text-weight-semibold has-text-pastel has-text-left">
            {`Ende Nr.${index + 1}`}
          </p>
          )
        }
        <h2 className="title br10 pt15 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey mb-4 px-3">
          {text}
        </h2>
        {
          media.source
          && (
          <div className="my-5">
            <MediaDisplay file={media} hubID={_id} />
          </div>
          )
        }
        { renderedStatistic }
      </div>
    );
  }), [resultsPerEnding]);

  return (
    <div className="box mb-6">
      <h2 className="has-text-weight-semibold has-text-pastel has-text-left">
        {
          endings.length === 1 ? 'Statistik Ende' : 'Statistik pro Ende'
        }
      </h2>
      {
          statistic.length > 1
          && (
            <div className="mb-6">
              <Chart statistic={statistic} />
            </div>
          )
       }
      <div className="">
        { renderedResultsPerEnding }
      </div>
    </div>

  );
};

export default EndingStatistic;
