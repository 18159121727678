import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setSocial } from 'reducer/hubs';

const TwitterEditor = () => {
  const dispatch = useDispatch();
  const { creator } = useSelector((state) => state.hubs);
  const { twitter } = creator.socials;

  const [error, setError] = useState('');

  const setValue = (value) => {
    dispatch(setSocial({ type: 'twitter', value }));
  };

  return (
    <div className="field">
      <label className="label">
        Twitter Nutzername
      </label>
      <div className="control">
        <div className="is-flex has-content-centered">
          <div
            className="has-border-bottom-grey has-background-grey-light has-fullheight p-2 br5 has-no-br-right"
          >
            <p
              className="has-text-weight-bold"
            >
              @
            </p>
          </div>
          <input
            onChange={(event) => setValue(event.target.value)}
            type="text"
            value={twitter}
            placeholder="deinname"
            className="input br5 has-no-br-left is-size-6 has-text-black has-background-white"
          />
        </div>
        <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
          { error }
        </p>
      </div>
    </div>
  );
};

export default TwitterEditor;
