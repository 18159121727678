import React from 'react';
import { useSelector } from 'react-redux';
import plans from 'constants/plans';
import loading from 'assets/images/loading.svg';

const UserStatisticBox = () => {
  const user = useSelector((state) => state.sessionState.user);

  const {
    widgetCount, websiteCount, amount, planDetails,
  } = user;
  const { views } = planDetails;

  const showImpressions = () => {
    const amountPercentage = (amount / views) * 100;
    // Format numbers so that they have a space after a thousand
    return (
      <div className="mt-2 has-text-grey pl10 pr10">
        <p className="has-text-centered mb5 has-text-black is-size-7">
          {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {' / '}
          {views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {' '}
          Impressionen
        </p>
        <progress
          className="progress is-primary mb0 has-border has-background-white"
          value={amountPercentage}
          max="100"
        />
        <p>
          {
                amountPercentage >= 100
                && 'Du hast die maximale Anzahl an Impressionen erreicht! Deine Flowter werden nicht mehr angezeigt. Wechsle auf einen höheren Plan, um deine Flowter wieder auszuliefern!'
           }
        </p>
      </div>
    );
  };

  return (
    <div className="box br20 boxshadow has-background-pastel-light has-border br10 px-5 py-5 has-text-black">
      <p className="has-text-black has-text-weight-bold is-size-5">Alles auf einen Blick</p>
      <div className="columns is-centered is-marginless mt-3">
        <div className="column is-narrow has-text-centered">
          <p className="has-background-white px-4 py-4 has-text-black has-text-weight-bold br10 is-inline-block">
            {widgetCount}
          </p>
          <p className="mt-1 has-text-weight-bold">Flowter</p>
        </div>
        <div className="column is-narrow has-text-centered">
          <p className="has-background-white px-4 py-4 has-text-black has-text-weight-bold br10 is-inline-block">
            {websiteCount}
          </p>
          <p className="mt-1 has-text-weight-bold">Websites</p>
        </div>
      </div>
      { showImpressions() }
    </div>

  );
};

export default UserStatisticBox;
