import React from 'react';
import Collapsible from 'react-collapsible';
import Switch from 'react-switch';
import { useSelector, useDispatch } from 'react-redux';
import stats from 'analytics/analytics';

import './css/cookieSettings.scss';

function CookieSettings() {
  const dispatch = useDispatch();
  const setStatCookies = (setting) => dispatch({ type: 'SET_STATISTIC_COOKIES', setting });
  const { statCookies } = useSelector((state) => state.cookieState);

  // Allow / Disallow Statistic Cookies
  const toggleStatisticCookie = () => {
    if (!statCookies === false) {
      stats.push(['forgetConsentGiven']);
    } else {
      stats.push(['rememberConsentGiven']);
    }

    setStatCookies(!statCookies);
  };

  return (
    <div className="mt-3 pb-6">
      <div className="pl10 pr10">
        <Collapsible
          trigger={(
            <div className="columns is-mobile cookieSetting is-marginless mt20">
              <div className="column is-paddingless has-text-left">
                <p>Erforderliche Cookies</p>
              </div>
              <div className="column is-paddingless px-2 align-self-center">
                <p className="has-text-info is-size-7 has-text-right">Akzeptiert</p>
              </div>
            </div>
          )}
          transitionTime={200}
        >
          <p className="has-text-black has-text-weight-normal is-size-7-mobile">Diese Cookies sind für die Hauptfunktionen der Website notwendig. Ohne diese Cookies funktioniert die Website nicht einwandfrei. Die Cookies werden unter anderem für das Registrieren oder Einloggen benutzt. Automatisch akzeptiert.</p>
        </Collapsible>
        <Collapsible
          trigger={(
            <div className="columns is-mobile cookieSetting is-marginless mt20">
              <div className="column is-paddingless has-text-left">
                <p>Statistiken</p>
              </div>
              <div className="column is-paddingless px-2 align-self-center">
                {
                  statCookies
                    ? <p className="is-size-7 has-text-info has-text-right">Akzeptiert</p>
                    : <p className="is-size-7 has-text-pastel has-text-right">Abgelehnt</p>
                }
              </div>
            </div>
           )}
          transitionTime={200}
        >
          <div className="columns is-mobile cookieSetting is-marginless">
            <div className="column pb-6">
              <p className="has-text-black has-text-weight-normal is-size-7-mobile">
                Diese Cookies erlauben uns die Nutzer zu identifizieren, Website-Traffic zu messen und analysieren und ermöglichen andere erweiternde Features. Automatisch abgelehnt.
              </p>
              <div className="columns mt5 is-centered">
                <div className="column has-text-right has-text-centered-mobile pr0 pb0 pl0">
                  <span className="is-size-6">Abgelehnt</span>
                </div>
                <div className="column pl0 pr0 pb0 ml5 mr5 is-3 has-text-centered">
                  <Switch
                    onChange={toggleStatisticCookie}
                    checked={statCookies}
                    onColor="#209CEE"
                    onHandleColor="#ffffff"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    offColor="#ddd"
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    className="switch"
                  />
                </div>
                <div className="column pl0 pb0 pl5 pr0">
                  <span className="is-size-6 has-text-centered-mobile">Akzeptiert</span>
                </div>
              </div>
            </div>
          </div>
        </Collapsible>
      </div>
    </div>
  );
}

export default CookieSettings;
