import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// Components
import PrivacyAgreementDownload from './PrivacyAgreementDownload';
import PrivacyURL from './PrivacyURL';

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Flowdust - Datenschutz</title>
      </Helmet>
      <div className="columns is-paddingless is-marginless mt60 mb50">
        <div className="column">
          <h2 className="mb30 has-text-black has-text-weight-bold has-text-centered is-size-3-desktop is-size-4-tablet is-size-5-mobile">Datenschutz</h2>
          <PrivacyURL />
          <PrivacyAgreementDownload />
        </div>
      </div>
    </>
  );
}

export default withAuthentication(Privacy);
