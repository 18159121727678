import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreens } from 'actions/creator';
import stats from 'analytics/analytics';

const ScreenOptions = () => {
  const { screens } = useSelector((state) => state.creatorState.settings);

  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const toggleScreens = (index) => {
    const newScreens = [...screens];

    newScreens[index] = newScreens[index] ? 0 : 1;
    dispatch(setScreens(newScreens));

    let device = 'Desktop';

    if (index === 1) {
      device = 'Tablet';
    }

    if (index === 2) {
      device = 'Mobile';
    }

    stats.push(['trackEvent', 'Widget Creator - Advanced Options - Screens', `Set ${device} ${!!newScreens[index]}`]);

    if (!newScreens.includes(1)) {
      setError('Bitte wähle mindestens ein Gerät aus!');
    } else {
      setError('');
    }
  };

  return (
    <div className="mt-5 ">
      <p className="has-text-grey">Auf welchen Geräten soll der Flowter angezeigt werden?</p>
      <div className="mt-3">
        <div className="mb-1">
          <input
            type="checkbox"
            id="screensDesktop"
            name="screensOption"
            checked={screens[0]}
            onChange={() => toggleScreens(0)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered has-text-grey dont-allow-selection"
            htmlFor="screensDesktop"
          >
            <i className="mr10 fas fa-desktop" />
            Desktop
          </label>
        </div>
        <div className="mb-1">
          <input
            type="checkbox"
            id="screensTablet"
            name="screensOption"
            checked={screens[1]}
            onChange={() => toggleScreens(1)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered has-text-grey dont-allow-selection"
            htmlFor="screensTablet"
          >
            <i className="mr10 fas fa-tablet-alt" />
            Tablet
          </label>
        </div>
        <div className="">
          <input
            type="checkbox"
            id="screensMobile"
            name="screensOption"
            checked={screens[2]}
            onChange={() => toggleScreens(2)}
            className="is-checkradio is-pastel "
          />
          <label
            className="has-text-centered has-text-grey dont-allow-selection"
            htmlFor="screensMobile"
          >
            <i className="mr10 fas fa-mobile-alt" />
            Smartphone
          </label>
        </div>
      </div>
      <p className="has-text-weight-bold has-text-danger mt-2">
        {error}
      </p>
    </div>
  );
};

export default ScreenOptions;
