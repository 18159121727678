import { createSlice } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

const ONE_CONDITION = {
  question: 0,
  type: 'ANSWER',
  sign: 'EQ',
  // value needs to be set when we know the question type and properties
  value: null,
};

// Simple and advanced logic are the same (at least, at the start)
const ONE_LOGIC = {
  // after which question the logic should be applied
  trigger: 0,

  // the conditions - if all are met, fire the action
  conditions: [ONE_CONDITION],

  // the actual action to do - in this case: go to the first ending
  action: {
    type: 'GOTO',
    value: {
      type: 'ENDING',
      index: 0,
    },
  },
};

const ONE_ENDING_LINK = {
  type: 'GOOGLE',
  url: '',
  text: '',
};

const ONE_ENDING = {
  text: 'Danke für deine Hilfe!',
  media: {
    source: '',
    type: '',
  },
  links: [],
};

const ONE_OPTION = {
  text: '',
  media: {
    source: '',
    type: '',
  },
};

const ONE_QUESTION = {
  text: '',
  options: [],
  allowText: true,
  isOptional: false,
  maxAmountChecks: 1,
  media: {
    source: '',
    type: '',
  },
  // possible types: ['POLL', 'EMAIL', 'SLIDER']
  type: 'POLL',
};

const INITIAL_CONTENT = {
  info: {
    text: '',
    media: {
      source: '',
      type: '',
    },
  },
  questions: [ONE_QUESTION],
  endings: [ONE_ENDING],
  logic: [],
  settings: {
    hasAdvancedLogic: false,
  },
  /**
   * the content that is active and can be edited
   * valid types:
   * INFO, QUESTION, ENDING, SETTINGS, lOGIC
   * index points to a specific question / ending, otherwise its'
   * value is -1
   */
  activeContent: {
    type: 'QUESTION',
    index: 0,
  },
};

const INITIAL_CREATOR = {
  name: '',
  url: '',
  id: '',
  description: '',
  status: 'active',
  colors: ['#FF914D', '#FF914D'],
  profilePic: '',
  language: 'de',
  privacyUrl: '',
  socials: {
    email: '',
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
    pinterest: '',
    website: '',
  },
  content: INITIAL_CONTENT,

  editIndex: -1,
  isComingFromDemo: false,
  hasTemplateData: false,
};

const INITIAL_STATISTIC = {
  hasStatisticData: false,
  hubIndex: -1,
  _id: '',
  resultsPerQuestion: [],
  resultsPerEnding: [],
  analytics: {},
  submissions: [],
  filter: {
    browser: [],
    countries: [],
    cpus: [],
    devices: [],
    languages: [],
    os: [],
    screens: [],
    darkmode: [],
    questions: [],
  },
};

const initialState = {
  hasData: false,
  hubs: [],
  creator: INITIAL_CREATOR,
  statistic: INITIAL_STATISTIC,
};

/**
  * Checks what condition values are available and sets the correct one to the given logic object
  * IMPORTANT: condition value can still be null!
  * */
const setAvailableConditionValue = (question, conditionID, _logic) => {
  const { allowText, type, options } = question;

  // copy logic so its not read-only
  const logic = JSON.parse(JSON.stringify(_logic));

  // if text is allowed, set default logic condition to user input,
  // so we dont have to check if there are any options to select
  if (allowText) {
    logic.conditions[conditionID].value = '-1';
  }

  if (type === 'POLL' && options.length !== 0) {
    logic.conditions[conditionID].value = '0';
  }

  // for slider, a type of ANSER and a value of 0 means just that the question has been answered
  if (type === 'SLIDER') {
    logic.conditions[conditionID].value = '0';
  }

  return logic;
};

/**
  * Checks if a given questionIndex has been used
  * in a logic statement. If it was, reset the logic.
  * Otherwise, just keep the logic array.
  * */
const resetLogic = (questionIndex, logic) => logic.filter((_logic) => {
  const { trigger, conditions, action: logicAction } = _logic;

  if (trigger === questionIndex) {
    return false;
  }

  const { value } = logicAction;
  const { type: actionType, index } = value;

  // atm, there is no QUESTION action type, but maybe later
  // so we just check it anyway
  // reset logic if question has been used as action
  if (actionType === 'QUESTION' && index === questionIndex) {
    return false;
  }

  for (let j = 0; j < conditions.length; j++) {
    const { question: conditionQuestion } = conditions[j];

    if (conditionQuestion === questionIndex) {
      return false;
    }
  }

  return true;
});

/**
 * Checks if a given endingIndex has been used in a logic action
 * If it was, delete that logic object from the array
 * if it wasn't keep, the old one
 */
const resetEndingLogic = (endingIndex, logic) => logic.filter((_logic) => {
  const { action: logicAction } = _logic;

  const { value } = logicAction;
  const { type: actionType, index } = value;

  if (actionType === 'ENDING' && index === endingIndex) {
    return false;
  }

  return true;
});

export const hubSlice = createSlice({
  name: 'hub',
  initialState,
  extraReducers: (builder) => {
    // clear creator on CLEAR_STATE
    builder.addCase('CLEAR_STATE', (state) => {
      state = initialState;
      return state;
    });
    // dont rehydrate hub state
    builder.addCase(REHYDRATE, (state) => {
      state.creator = INITIAL_CREATOR;
      return state;
    });
  },
  reducers: {
    setHubs: (state, action) => {
      state.hubs = action.payload;
      state.hasData = true;
    },
    addHub: (state, action) => {
      state.hubs.push(action.payload);
    },
    updateHub: (state, action) => {
      state.hubs[action.payload.id] = {
        ...state.hubs[action.payload.id],
        ...action.payload.hub,
      };
    },
    setHubStatus: (state, action) => {
      const { id, status } = action.payload;

      // id is the _id of the hub
      // so first, we need to find the index of the hub
      const index = state.hubs.findIndex((hub) => hub._id === id);

      if (index === -1) {
        return;
      }

      state.hubs[index].status = status;
    },
    setHubContent: (state, action) => {
      const { id, content } = action.payload;
      state.hubs[id].content = content;
    },
    deleteHub: (state, action) => {
      state.hubs.splice(action.payload, 1);
    },
    // HUB CREATOR
    setCreator: (state, action) => {
      const {
        _id, socials, content,
      } = action.payload;

      // if we have template data, dont set the content coming from action.payload, but rather keep the current data
      if (state.creator.hasTemplateData) {
        state.creator = {
          ...INITIAL_CREATOR,
          ...state.creator,
          ...action.payload,
          id: _id || INITIAL_CREATOR.id,
          content: {
            ...INITIAL_CONTENT,
            ...state.creator.content,
          },
        };

        return;
      }

      state.creator = {
        ...INITIAL_CREATOR,
        ...action.payload,
        id: _id || INITIAL_CREATOR.id,
        content: {
          ...INITIAL_CONTENT, ...content,
        },
      };

      // remove _id as we have saved it as just id
      delete state.creator._id;

      /* state.creator.id = _id || INITIAL_CREATOR.id;
      state.creator.url = url || INITIAL_CREATOR.url;
      state.creator.name = name || INITIAL_CREATOR.name;
      state.creator.description = description || INITIAL_CREATOR.description;
      state.creator.colors = colors || INITIAL_CREATOR.colors;
      state.creator.privacyUrl = privacyUrl || INITIAL_CREATOR.privacyUrl;
      state.creator.profilePic = profilePic || '';
      state.creator.editIndex = editIndex;
      state.creator.content = { ...INITIAL_CONTENT, ...content }; */

      // if user has not entered any socials, socials will be undefined
      if (socials) {
        state.creator.socials = {
          email: socials.email || '',
          facebook: socials.facebook ? `https://facebook.com/${socials.facebook}` : '',
          twitter: socials.twitter || '',
          instagram: socials.intagram || '',
          linkedin: socials.linkedin ? `https://linkedin.com/${socials.linkedin}` : '',
          pinterest: socials.pinterest ? `https://pinterest.com/${socials.pinterest}` : '',
          website: socials.website ? `https://${socials.website}` : '',
        };
      }

      const { info } = content;

      // if we have a info slide, show it
      if (info.text || info.media.source) {
        state.creator.content.activeContent = {
          type: 'INFO',
          index: -1,
        };
      }
    },
    setCreatorContent: (state, action) => {
      state.creator.content = { ...state.creator.content, ...action.payload };
    },
    setHasTemplateData: (state, action) => {
      state.creator.hasTemplateData = action.payload;

      if (state.creator.content.info.text || state.creator.content.info.media.source) {
        state.creator.content.activeContent = {
          type: 'INFO',
          index: 0,
        };
      } else {
        state.creator.content.activeContent = {
          type: 'QUESTION',
          index: 0,
        };
      }
    },
    setUrl: (state, action) => {
      state.creator.url = action.payload;
    },
    setName: (state, action) => {
      state.creator.name = action.payload;
    },
    setStatus: (state, action) => {
      state.creator.status = action.payload;
    },
    setDescription: (state, action) => {
      state.creator.description = action.payload;
    },
    setPrivacyUrl: (state, action) => {
      state.creator.privacyUrl = action.payload;
    },
    setColors: (state, action) => {
      state.creator.colors = action.payload;
    },
    setLanguage: (state, action) => {
      state.creator.language = action.payload;
    },
    setProfilePic: (state, action) => {
      state.creator.profilePic = action.payload;
    },
    setSocial: (state, action) => {
      state.creator.socials[action.payload.type] = action.payload.value;
    },
    clearCreator: (state) => {
      const { info, questions, endings } = state.creator.content;

      // revoke all object urls on clearing the creator
      if (info.media.source.includes('blob:')) {
        URL.revokeObjectURL(info.media.source);
      }

      questions.forEach((question) => {
        if (question.media.source.includes('blob:')) {
          URL.revokeObjectURL(question.media.source);
        }

        question.options.forEach((option) => {
          if (option.media.source.includes('blob:')) {
            URL.revokeObjectURL(option.media.source);
          }
        });
      });

      endings.forEach((ending) => {
        if (ending.media.source.includes('blob:')) {
          URL.revokeObjectURL(ending.media.source);
        }
      });

      state.creator = INITIAL_CREATOR;
    },
    // HUB CONTENT
    setInfoActive: (state) => {
      state.creator.content.activeContent = {
        type: 'INFO',
        index: -1,
      };
    },
    setInfo: (state, action) => {
      state.creator.content.info.text = action.payload;

      // go to info editor
      state.creator.content.activeContent = {
        type: 'INFO',
        index: -1,
      };
    },
    setInfoMedia: (state, action) => {
      const { source, type } = action.payload;
      state.creator.content.info.media = {
        source,
        type,
      };

      // go to info editor
      state.creator.content.activeContent = {
        type: 'INFO',
        index: -1,
      };
    },
    // Questions
    setQuestionActive: (state, action) => {
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: action.payload,
      };
    },
    swapQuestions: (state, action) => {
      const { start, end } = action.payload;
      const temp = state.creator.content.questions[start];

      const newState = state.creator.content.questions.filter((contact, index) => index !== action.payload.start);

      state.creator.content.questions = [
        ...newState.slice(0, end),
        temp,
        ...newState.slice(end),
      ];

      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: end,
      };
    },
    addQuestion: (state) => {
      state.creator.content.questions.push(ONE_QUESTION);

      // switch to the new question
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: state.creator.content.questions.length - 1,
      };
    },
    setQuestion: (state, action) => {
      const { id, text } = action.payload;
      state.creator.content.questions[id].text = text;

      // go to active question
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };
    },
    setQuestionMedia: (state, action) => {
      const { id, source, type } = action.payload;

      state.creator.content.questions[id].media = {
        source,
        type,
      };

      // go to that question
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };
    },
    setQuestionType: (state, action) => {
      const { id, type } = action.payload;

      const question = state.creator.content.questions[id];

      if (type === question.type) return;

      // reset question, remove all files
      /* if (question.media.source.includes('blob:')) {
        URL.revokeObjectURL(question.media.source);
   } */

      // remove all files for all options
      question.options.forEach((option) => {
        if (option.media.source.includes('blob:')) {
          URL.revokeObjectURL(option.media.source);
        }
      });

      state.creator.content.questions[id] = {
        ...ONE_QUESTION,
        text: question.text,
        media: question.media,
        type,
      };

      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };

      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(id, logic);
    },
    setAllowText: (state, action) => {
      const { id, allowText } = action.payload;
      state.creator.content.questions[id].allowText = allowText;

      // reset logic
      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(id, logic);

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };
    },

    setMaxAmountChecks: (state, action) => {
      const { id, maxAmountChecks } = action.payload;
      state.creator.content.questions[id].maxAmountChecks = maxAmountChecks;

      // reset logic
      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(id, logic);

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };
    },

    setOptional: (state, action) => {
      const { id, isOptional } = action.payload;
      state.creator.content.questions[id].isOptional = isOptional;

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: id,
      };

      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(id, logic);
    },
    deleteQuestion: (state, action) => {
      state.creator.content.questions.splice(action.payload, 1);

      // reset logic
      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(action.payload, logic);

      // go to previous question if a question has been active
      if (state.creator.content.activeContent.type === 'QUESTION') {
        const { index } = state.creator.content.activeContent;

        state.creator.content.activeContent = {
          type: 'QUESTION',
          index: index === 0 ? 0 : index - 1,
        };
      }
    },
    // Options
    addOption: (state, action) => {
      const question = state.creator.content.questions[action.payload];
      question.options.push(ONE_OPTION);

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: action.payload,
      };
    },
    setOption: (state, action) => {
      const { question, option, text } = action.payload;
      const _question = state.creator.content.questions[question];

      _question.options[option].text = text;

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: question,
      };
    },
    setOptionMedia: (state, action) => {
      const {
        question, option, source, type,
      } = action.payload;

      const _question = state.creator.content.questions[question];

      _question.options[option].media = {
        source,
        type,
      };

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: question,
      };
    },
    deleteOption: (state, action) => {
      const { question, option } = action.payload;
      const _question = state.creator.content.questions[question];
      _question.options.splice(option, 1);

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'QUESTION',
        index: question,
      };

      // reset logic
      const { logic } = state.creator.content;
      state.creator.content.logic = resetLogic(question, logic);
    },
    // ENDINGS
    setEndingActive: (state, action) => {
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: action.payload,
      };
    },
    addEnding: (state) => {
      state.creator.content.endings.push(ONE_ENDING);

      state.creator.content.activeContent = {
        type: 'ENDING',
        index: state.creator.content.endings.length - 1,
      };
    },
    setEnding: (state, action) => {
      const { id, text } = action.payload;
      state.creator.content.endings[id].text = text;

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: id,
      };
    },
    setEndingMedia: (state, action) => {
      const { id, source, type } = action.payload;

      state.creator.content.endings[id].media = {
        source,
        type,
      };

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: id,
      };
    },
    deleteEnding: (state, action) => {
      state.creator.content.endings.splice(action.payload, 1);

      // reset logic
      const { logic } = state.creator.content;
      state.creator.content.logic = resetEndingLogic(action.payload, logic);

      // go to previous ending if a ending has been active
      if (state.creator.content.activeContent.type === 'ENDING') {
        const { index } = state.creator.content.activeContent;

        state.creator.content.activeContent = {
          type: 'ENDING',
          index: index === 0 ? 0 : index - 1,
        };
      }
    },
    addEndingLink: (state, action) => {
      const newLink = {
        ...ONE_ENDING_LINK,
        type: 'LINK',
      };

      state.creator.content.endings[action.payload].links.push(newLink);

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: action.payload,
      };
    },
    setEndingLink: (state, action) => {
      const {
        endingIndex, linkIndex, url, type, text,
      } = action.payload;
      const link = state.creator.content.endings[endingIndex].links[linkIndex];

      if (url !== undefined) link.url = url;
      if (type !== undefined) link.type = type;
      if (text !== undefined) link.text = text;

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: endingIndex,
      };
    },
    setEndingLinks: (state, action) => {
      const { id, links } = action.payload;

      state.creator.content.endings[id].links = links;

      // show as active (also for preview)
      state.creator.content.activeContent = {
        type: 'ENDING',
        index: id,
      };
    },
    deleteEndingLink: (state, action) => {
      const { endingIndex, linkIndex } = action.payload;
      state.creator.content.endings[endingIndex].links.splice(linkIndex, 1);
    },
    // LOGIC
    addLogic: (state) => {
      // TODO: logic für slider - not sure what is meant by that

      const question = state.creator.content.questions[0];
      const logic = setAvailableConditionValue(question, 0, ONE_LOGIC);

      state.creator.content.logic.push(logic);
    },
    deleteLogic: (state, action) => {
      state.creator.content.logic.splice(action.payload, 1);
    },
    setLogicTrigger: (state, action) => {
      const { id, value } = action.payload;

      // dont set value if question does not exist
      if (!state.creator.content.questions[id]) return;

      // set the trigger to the new value
      state.creator.content.logic[id].trigger = value;
    },

    addLogicCondition: (state, action) => {
      const id = action.payload;

      // get the current logic object
      const logic = state.creator.content.logic[id];
      // get the question from the logic trigger
      const question = state.creator.content.questions[logic.trigger];

      // push a new condition
      state.creator.content.logic[id].conditions.push(ONE_CONDITION);

      // get the index of this condition
      const conditionId = logic.conditions.length - 1;

      // finally, set its value depending on the question it should be applied to
      state.creator.content.logic[id] = setAvailableConditionValue(question, conditionId, logic);
    },
    deleteLogicCondition: (state, action) => {
      const { logicIndex, conditionIndex } = action.payload;

      state.creator.content.logic[logicIndex].conditions.splice(conditionIndex, 1);
    },
    setLogicCondition: (state, action) => {
      const {
        logicIndex, conditionIndex, condition,
      } = action.payload;

      // TODO: check if it can be set
      state.creator.content.logic[logicIndex].conditions[conditionIndex] = condition;
    },
    setLogicAction: (state, action) => {
      const { id, index, type } = action.payload;
      state.creator.content.logic[id].action.value = {
        index,
        type,
      };
    },
    // show the logic tab as active content
    setLogicActive: (state) => {
      state.creator.content.activeContent = {
        type: 'LOGIC',
        index: -1,
      };
    },
    setHasAdvancedLogic: (state, action) => {
      state.creator.content.settings.hasAdvancedLogic = action.payload;

      // reset logic and set correct condition value
      const question = state.creator.content.questions[0];
      const logic = setAvailableConditionValue(question, 0, ONE_LOGIC);

      state.creator.content.logic = [logic];
    },
    /* Statistic Methods */
    setStatistic: (state, action) => {
      const { statistic, hubIndex } = action.payload;

      state.statistic = {
        ...INITIAL_STATISTIC,
        ...state.statistic,
        ...statistic,
        _id: state.hubs[hubIndex]._id,
        hubIndex,
        hasStatisticData: true,
      };
    },
    addSubmissions: (state, action) => {
      state.statistic.submissions = [
        ...state.statistic.submissions,
        ...action.payload,
      ];
    },
    deleteSubmission: (state, action) => {
      state.statistic.submissions = state.statistic.submissions.filter((item) => item._id !== action.payload);
      state.statistic.amountSubmissions -= 1;
    },
    setFilter: (state, action) => {
      state.statistic.filter = {
        ...state.statistic.filter,
        ...action.payload,
      };
    },
    resetFilter: (state) => {
      state.statistic.filter = INITIAL_STATISTIC.filter;
    },

    setComingFromDemo: (state, action) => {
      state.creator.isComingFromDemo = action.payload;
    },
  },
});

export const {
  setHubs,
  addHub,
  setHubContent,
  setHubStatus,
  updateHub,
  deleteHub,

  // HUB CREATOR
  setCreator,
  setCreatorContent,
  setUrl,
  setPrivacyUrl,
  setName,
  setDescription,
  setColors,
  setLanguage,
  setProfilePic,
  setSocial,
  setStatus,

  clearCreator,
  setComingFromDemo,
  setHasTemplateData,

  // HUB CONTENT

  // Info
  setInfo,
  setInfoMedia,
  setInfoActive,

  // Questions
  addQuestion,
  setQuestion,
  setQuestionMedia,
  setQuestionType,
  deleteQuestion,
  swapQuestions,

  setAllowText,
  setMaxAmountChecks,
  setOptional,
  setQuestionActive,

  // Options
  addOption,
  setOption,
  setOptionMedia,
  deleteOption,

  // Endings
  addEnding,
  setEnding,
  setEndingMedia,
  deleteEnding,
  setEndingActive,

  addEndingLink,
  setEndingLink,
  setEndingLinks,
  deleteEndingLink,

  // Logic
  addLogic,
  deleteLogic,
  // Logic Trigger
  setLogicTrigger,
  // Logic Conditions
  addLogicCondition,
  deleteLogicCondition,
  setLogicCondition,
  // Logic Actions
  setLogicAction,
  setHasAdvancedLogic,
  setLogicActive,

  // Statistic
  setStatistic,
  addSubmissions,
  deleteSubmission,
  setFilter,
  resetFilter,

} = hubSlice.actions;

export default hubSlice.reducer;
