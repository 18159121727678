import React from 'react';
import './styles/waveAnimation.scss';

const InfoText = () => (
  <div className="pb50">
    <div className="oceanWrapper">
      <div className="ocean">
        <div className="wave wave-flipped" />
      </div>
    </div>
    <div className="columns is-centered mt-6 pt-6 ml10 mr10">
      <div className="column is-8 has-text-centered mt-6">
        <h2 className="has-text-black has-text-weight-bold is-size-2-desktop is-size-2-tablet is-size-4-mobile mb20">
          Umfragen und Formulare, an denen man gerne teilnimmt.
        </h2>
      </div>
    </div>
  </div>
);

export default InfoText;
