import React, {
  useState,
} from 'react';

// Libraries
import { jsPDF } from 'jspdf';
import { CSVLink, CSVDownload } from 'react-csv';
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

const StatisticExporter = () => {
  const { statistic, hubs } = useSelector((state) => state.hubs);
  const { impressions, amountSubmissions } = statistic;

  const [csvHeaders, setCsvHeaders] = useState();
  const [csvData, setCsvData] = useState();

  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const hub = hubs[statistic.hubIndex];
  const { name, url, _id } = hub;

  const exportResponses = async () => {
    try {
      setDisabled(true);

      const headers = [
        { label: 'Erstellt am', key: 'createdAt' },
      ];

      const { questions, endings } = hub.content;

      // prepare question headers
      for (let i = 0; i < questions.length; i++) {
        const { text } = questions[i];
        headers.push({ label: text, key: `question_${i + 1}` });
      }

      headers.push({ label: 'Ende', key: 'ending' });

      // prepare ending headers
      /* for (let j = 0; j < endings.length; j++) {
        const { text } = endings[j];
        headers.push({ label: text, key: `ending_${j + 1}` });
      } */

      const responses = await post('/hubs/responses/export', { hubID: _id });

      const data = [];

      // prepare data for all the responses
      for (let i = 0; i < responses.length; i++) {
        const { ending, responses: givenResponses, createdAt } = responses[i];

        const response = { createdAt };

        // get the response for each answered question
        for (let j = 0; j < questions.length; j++) {
          const { type, options } = questions[j];

          if (!givenResponses[j]) continue;

          // get the response for this question
          const { values, input } = givenResponses[j];

          // question has not been answered
          if (values.length === 0) continue;

          // save correct type of answer
          // depending on the question type and the user input
          if (values[0] === -2) {
            // -2 means skipped
            response[`question_${j + 1}`] = 'Übersprungen';
          } else if (values[0] === -1) {
            // -1 means user input, so save that
            response[`question_${j + 1}`] = input;
          } else if (type === 'SLIDER') {
            // get the value if its a slider question
            // eslint-disable-next-line
            response[`question_${j + 1}`] = values[0];
          } else if (type === 'POLL') {
            // if its a poll question, get the selected option
            const option = options[values[0]];

            // option dont need a text
            if (!option.text) {
              response[`question_${j + 1}`] = `Option ${values[0] + 1}`;
            } else {
              response[`question_${j + 1}`] = option.text;
            }
          }
        }

        const { index } = ending;

        // if user has not reached an ending, just stop here
        if (index === -1) {
          data.push(response);
          continue;
        }

        // otherwise, save the data for the ending
        const { text } = endings[index];
        response.ending = text;
        data.push(response);
      }

      setCsvHeaders(headers);
      setCsvData(data);

      stats.push(['trackEvent', 'Hub Statistic', 'Responses exported']);

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
    }
  };

  const generatePDF = async () => {
    try {
      setDisabled(true);
      const doc = new jsPDF('p', 'pt', 'a4', true);

      doc.setFontSize(15);

      doc.addPage();
      doc.setPage(1);
      doc.text(`Flowdust Statistik für ${name}(/${url})`, 10, 15);
      doc.text(`Der Hub wurde ${impressions} mal aufgerufen.`, 10, 30);
      doc.text(`${amountSubmissions} haben mindestens eine Frage beantwortet.`, 10, 45);

      // doc.addImage(browserStatistic, 'png', (width / 2 - 210), 10, 410, height - 20, '', 'FAST');

      doc.save('flowdust_statistik.pdf');
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className={`button has-text-white has-background-midnightblue grow br10 py-0 ${disabled ? 'is-loading' : ''}`}
        onClick={exportResponses}
        disabled={disabled}
      >
        <i className="fas fa-download mr-2" />
        Alle Antworten exportieren (.csv)
      </button>

      {
        csvData && !disabled
        && <CSVDownload data={csvData} headers={csvHeaders} target="_blank" />
      }
    </>

  );
};

export default StatisticExporter;
