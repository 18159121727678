import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';
import { setCustomerData } from 'actions/customer';
import LoadingLayout from 'components/utils/LoadingLayout';

import './styles/CustomerInfoCollector.scss';

function CustomerInfoCollector(props) {
  const { user } = useSelector((state) => state.sessionState);
  const dispatch = useDispatch();

  const { disabled } = props;

  const [errorPerson, setErrorPerson] = useState('');
  const [errorCompany, setErrorCompany] = useState('');
  const [error, setError] = useState('');

  const [firstName, setFirstName] = useState(user.name || '');
  const [firstNameValid, setFirstNameValid] = useState(true);

  const [lastName, setLastName] = useState('');
  const [lastNameValid, setLastNameValid] = useState(false);

  const [street, setStreet] = useState('');
  const [streetValid, setStreetValid] = useState(false);

  const [postalCode, setPostalCode] = useState('');
  const [postalCodeValid, setPostalCodeValid] = useState(false);

  const [city, setCity] = useState('');
  const [cityValid, setCityValid] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [companyNameValid, setCompanyNameValid] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const signal = axios.CancelToken.source();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const initState = (customer) => {
      const { addressData } = customer;

      setFirstName(customer.firstName || '');
      setFirstNameValid(true);

      setLastName(customer.lastName || '');
      setLastNameValid(true);

      setPostalCode(addressData.postalCode || '');
      setPostalCodeValid(true);

      setCompanyName(addressData.company || '');
      setCompanyNameValid(true);

      setCity(addressData.locality || '');
      setCityValid(true);

      setStreet(addressData.streetAddress || '');
      setStreetValid(true);
    };

    const loadCustomerData = async () => {
      setLoading(true);

      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        // Get customer info from braintree
        let data = await axios.get(`${process.env.REACT_APP_API}/subscription/customer`, config);

        data = data.data;
        dispatch(setCustomerData(data));

        if (data === false) {
          setLoading(false);
          return;
        }

        initState(data.customer);

        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setError('Leider ist etwas schief gelaufen. Bitte versuche es später noch einmal.');
        console.log(err);
      }
    };

    if (user.customer === undefined) {
      loadCustomerData();
    } else {
      initState(user.customer);
    }

    return (() => {
      signal.cancel();
    });

    // eslint-disable-next-line
	}, [])

  const setFirstNameAndValidate = (event) => {
    const name = event.target.value;

    if (name.length > 30) {
      stats.push(['trackEvent', 'Customer Info Collector', 'First name max length reached']);
      setErrorPerson('Maximale Länge des Namens erreicht!');
      return;
    }

    if (name.includes(' ')) {
      setErrorPerson('Du brauchst nur deinen Vornamen anzugeben. Bitte benutze keine Leerzeichen!');
      setFirstName(name);
      setFirstNameValid(false);
      return;
    }

    // Allow only letters, umlaut and -, no numbers, no special chars
    const reg = /^[\u00C0-\u017Fa-z-]+$/i;
    // Only show error message when there is a name
    if (name.length > 0 && !reg.test(name)) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Input invalid special chars on first name ']);
      setErrorPerson('Bitte benutze keine Sonderzeichen oder Zahlen für deinen Vornamen!');
      setFirstName(name);
      setFirstNameValid(false);
      return;
    }

    setFirstName(name);
    setErrorPerson('');
    setFirstNameValid(true);
  };

  const setLastNameAndValidate = (event) => {
    const name = event.target.value;

    if (name.length > 255) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Last name max length reached']);
      setErrorPerson('Maximale Länge des Namens erreicht!');
      return;
    }
    if (name.includes(' ')) {
      setErrorPerson('Bitte benutze keine Leerzeichen für deinen Nachnamen!');
      setLastName(name);
      setLastNameValid(false);
      return;
    }

    // Allow only letters, umlaut and -, no numbers, no special chars
    const reg = /^[\u00C0-\u017Fa-z-]+$/i;
    // Only show error message when there is a name
    if (name.length > 0 && !reg.test(name)) {
      stats.push(['trackEvent', 'CustomerInfoCollector', 'Input invalid special chars on last name']);
      setErrorPerson('Bitte benutze keine Sonderzeichen oder Zahlen für deinen Nachnamen!');
      setLastName(name);
      setLastNameValid(false);
      return;
    }

    setLastName(name);
    setErrorPerson('');
    setLastNameValid(true);
  };

  const setCompanyNameAndValidate = (event) => {
    const name = event.target.value;

    if (name.length > 255) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Company name max length reached']);
      setErrorCompany('Maximale Länge des Firmennamens erreicht!');
      return;
    }

    // Allow only letters, umlaut and -, no numbers, no special chars
    // const reg = /^[\u00C0-\u017Fa-z-()1-9 ]+$/i;
    const reg = /^[\u00C0-\u017Fa-zA-Z1-9]+(( |-)[a-zA-Z\u00C0-\u017F()1-9]+)*(\s?|-?)$/i;
    // const reg = /^[\u00C0-\u017Fa-z1-9() -]*$/i;
    // const reg = /^[\u00C0-\u017Fa-zA-Z-]+( [a-zA-Z\u00C0-\u017F-]+)*\s?$/i;
    // Only show error message when there is a name
    if (name.length > 0 && !reg.test(name)) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Input invalid special chars on company name']);
      setErrorCompany('Bitte benutze keine Sonderzeichen für den Firmennamen!');
      setCompanyName(name);
      setCompanyNameValid(false);
      return;
    }

    setCompanyName(name);
    setErrorCompany('');
    setCompanyNameValid(true);
  };

  const setStreetAndValidate = (event) => {
    const streetValue = event.target.value;

    if (streetValue.length > 255) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Street max length reached']);
      setErrorCompany('Maximale Länge des Straßennamens erreicht!');
      return;
    }

    // Allow only letters, umlaut and -, no numbers, no special chars
    // const reg = /^[\u00C0-\u017Fa-zA-Z]+[\u00C0-\u017Fa-z- ]+$/i;
    const reg = /^[\u00C0-\u017Fa-zA-Z]+.?(( |-)[a-zA-Z\u00C0-\u017F0-9]+)*\s?$/i;
    // Only show error message when there is a name
    if (streetValue.length > 0 && !reg.test(streetValue)) {
      setErrorCompany('Bitte gib einen korrekten Straßennamen an!');
      setStreet(streetValue);
      setStreetValid(false);
      return;
    }

    if (streetValue.length > 4 && !/\d/.test(streetValue)) {
      setStreet(streetValue);
      setStreetValid(false);
      return;
    }

    setStreet(streetValue);
    setStreetValid(true);
    setErrorCompany('');
  };

  const setCityAndValidate = (event) => {
    const cityValue = event.target.value;

    if (cityValue.length > 255) {
      stats.push(['trackEvent', 'Customer Info Collector', 'City max length reached']);
      setErrorCompany('Bitte gib einen kürzeren Städtenamen an!');
      return;
    }

    if (/\d/.test(cityValue)) {
      setErrorCompany('Bitte gib die Postleitzahl erst im nächsten Feld an!');
      setCity(cityValue);
      setCityValid(false);
      return;
    }

    const reg = /^[\u00C0-\u017Fa-zA-Z]+( [a-zA-Z\u00C0-\u017F]+)*\s?$/i;

    if (cityValue.length > 0 && !reg.test(cityValue)) {
      setErrorCompany('Bitte gib einen korrekten Städtenamen an!');
      setCity(cityValue);
      setCityValid(false);
      return;
    }

    setCity(cityValue);
    setCityValid(true);
    setErrorCompany('');
  };

  const setPostalCodeAndValidate = (event) => {
    const code = event.target.value;

    if (code.length > 10) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Postal code max length reached']);
      setErrorCompany('Bitte gib eine kürzere Postleitzahl an!');
      return;
    }

    const reg = /^[0-9]*$/i;

    if (code.length > 0 && !reg.test(code)) {
      setErrorCompany('Bitte gib eine korrekte Postleitzahl an!');
      setPostalCode(code);
      setPostalCodeValid(false);
      return;
    }

    setPostalCode(code);
    setPostalCodeValid(true);
    setErrorCompany('');
  };

  const saveCustomerData = () => {
    if (!firstNameValid || firstName.length <= 1) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid text input on first name']);

      setError('Bitte gib einen gültigen Vornamen an!');
      return;
    }

    if (!lastNameValid || lastName.length <= 1) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid text input on last name']);

      setError('Bitte gib einen gültigen Nachnamen an!');
      return;
    }

    if (!companyNameValid || companyName.length <= 2) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid text input on company name']);

      setError('Bitte gib einen gültigen Firmennamen an!');
      return;
    }

    if (!streetValid || street.length <= 4) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid text input on street']);
      setError('Bitte gib eine gültige Straße mit Hausnummer an!');
      return;
    }

    if (!cityValid || city.length <= 1) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid text input on city']);

      setError('Bitte gib einen gültigen Städtenamen an!');
      return;
    }

    if (!postalCodeValid || postalCode.length <= 3) {
      stats.push(['trackEvent', 'Customer Info Collector', 'Invalid postal code']);

      setError('Bitte gib eine gültige Postleitzahl an!');
      return;
    }

    stats.push(['trackEvent', 'Customer Info Collector', 'Info collection completed']);

    // map customer object to braintree customer object
    // and remove trailing space if there is one
    const customer = {
      firstName,
      lastName,
      addressData: {
        company: companyName.replace(/\s+$/, ''),
        streetAddress: street.replace(/\s+$/, ''),
        locality: city.replace(/\s+$/, ''),
        postalCode,
      },
    };

    props.next(customer);
  };

  if (isLoading) {
    return <LoadingLayout error={error} screenHeight={false} />;
  }

  return (
    <div
      className="columns is-centered is-marginless is-centered"
      id="CustomerInfoCollectorWrapper"
    >
      <div className="column is-10">
        <h2 className="has-text-weight-bold is-size-4 has-text-black pt50">
          Persönliche Daten
        </h2>
        <div className="columns is-centered is-marginless">
          <div className="column is-6">
            <div className="field">
              <label className="label">
                Vorname
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={firstName}
                  placeholder="Vorname"
                  onChange={(e) => setFirstNameAndValidate(e)}
                />
                {firstName.length > 0 && (
                <span className="icon is-small is-right">
                  {
                    firstNameValid && firstName.length > 1
                      ? <i className="fas fa-check has-text-success" />
                      : <i className="fas fa-times has-text-danger" />
                  }
                </span>
                )}
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">
                Nachname
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={lastName}
                  placeholder="Nachname"
                  onChange={(e) => setLastNameAndValidate(e)}
                />
                { lastName.length > 0 && (
                <span className="icon is-small is-right">
                  { lastNameValid && lastName.length > 1
                    ? <i className="fas fa-check has-text-success" />
                    : <i className="fas fa-times has-text-danger" />}
                </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="has-text-danger has-text-weight-semibold">
          {errorPerson}
        </p>
        <h2 className="has-text-weight-bold is-size-4 has-text-black pt50 mb20">
          Firmendaten
        </h2>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                Firmenname
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={companyName}
                  placeholder="Firmenname"
                  onChange={(e) => setCompanyNameAndValidate(e)}
                />
                {companyName.length > 0 && (
                <span className="icon is-small is-right">
                  {
                    companyNameValid && companyName.length > 2
                      ? <i className="fas fa-check has-text-success" />
                      : <i className="fas fa-times has-text-danger" />
                  }
                </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                Straße und Hausnummer
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={street}
                  placeholder="Straße und Hausnummer"
                  onChange={(e) => setStreetAndValidate(e)}
                />
                { street.length > 0 && (
                <span className="icon is-small is-right">
                  {streetValid && street.length > 4
                    ? <i className="fas fa-check has-text-success" />
                    : <i className="fas fa-times has-text-danger" />}
                </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                Ort
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={city}
                  placeholder="Ort"
                  onChange={(e) => setCityAndValidate(e)}
                />
                {city.length > 0 && (
                <span className="icon is-small is-right">
                  {cityValid && city.length > 1
                    ? <i className="fas fa-check has-text-success" />
                    : <i className="fas fa-times has-text-danger" />}
                </span>
                )}
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">
                Postleitzahl
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  value={postalCode}
                  placeholder="Postleitzahl"
                  onChange={(e) => setPostalCodeAndValidate(e)}
                />
                { postalCode.length > 0 && (
                <span className="icon is-small is-right">
                  { postalCodeValid && postalCode.length > 3
                    ? <i className="fas fa-check has-text-success" />
                    : <i className="fas fa-times has-text-danger" />}
                </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="has-text-danger has-text-weight-bold">{errorCompany}</p>
        <div className="has-text-centered mt30">
          <button
            className={`button is-pastel is-rounded has-text-weight-bold grow ${disabled && 'is-loading'}`}
            disabled={disabled}
            onClick={() => saveCustomerData()}
            type="button"
          >
            Bestätigen
          </button>
          <p className="has-text-danger has-text-weight-bold mt-3">{error}</p>
        </div>
      </div>
    </div>
  );
}

CustomerInfoCollector.propTypes = {
  next: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CustomerInfoCollector.defaultProps = {
  disabled: false,
};

export default CustomerInfoCollector;
