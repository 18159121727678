import React, { useEffect, useState } from 'react';

// Libraries
import { sanitizeUrl } from '@braintree/sanitize-url';
import PropTypes from 'prop-types';

// Components
import { CopyToClipboard } from 'react-copy-to-clipboard';

const EmailResponse = ({ email, color }) => {
  const [sanitizedMail, setSanitizedMail] = useState('');

  const [hasBeenCopied, setHasBeenCopied] = useState(false);

  useEffect(() => {
    const _sanitizedMail = sanitizeUrl(email);

    setSanitizedMail(_sanitizedMail);
  }, [email]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasBeenCopied(false);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasBeenCopied]);

  /**
  <a
    href={`mailto:${sanitizedMail}`}
    className="cleanButton is-inline mr-1"
  >
    <i
      className="fas fa-pen-square has-text-primary is-size-6 is-inline mr-1"
    />
  </a>
  */

  return (
    <div>
      <div>
        <i
          className="fas fa-envelope-square br5 is-size-6 is-inline mr-1"
          style={{ color }}
        />
        <p className="is-inline break-word">
          {email}
        </p>
      </div>
      <div>
        <CopyToClipboard
          text={sanitizedMail}
          onCopy={() => setHasBeenCopied(true)}
        >
          <button
            type="button"
            className="px-1 py-0 has-no-border br5 has-background-pastel is-inline mr-1 has-cursor-pointer"
          >
            <p
              className="is-size-7 has-text-white"
            >
              {
              hasBeenCopied
                ? 'Kopiert!'
                : 'Kopieren'
            }
            </p>
          </button>
        </CopyToClipboard>

      </div>
    </div>

  );
};

EmailResponse.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailResponse;
