import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import TypeSelector from '../TypeSelector';
import SignSelector from '../SignSelector';
import ValueSelector from '../ValueSelector';

/**
  * Component to set the condition for a given email question
  *
* */
const EmailConditionSelector = (props) => {
  const { condition, setCondition } = props;

  const { questions } = useSelector((state) => state.hubs.creator.content);
  const {
    question, type, sign, value,
  } = condition;

  const { isOptional } = questions[question];

  const availableTypes = useMemo(() => {
    // Prepare all types to be displayed
    const types = [
      {
        value: 'ANSWER',
        label: 'Gewählte Option',
      },
    ];

    return types;
  }, []);

  const availableValues = useMemo(() => {
    const values = [];

    values.push({
      value: '-1',
      label: 'E-Mail abgegeben',
    });

    if (isOptional) {
      values.push({
        value: '-2',
        label: 'Übersprungen',
      });
    }

    return values;
  }, [type]);

  const setConditionType = (_type) => {
    // set an empty string if the value is a text field
    let newValue = '';

    // but if you can select options, select an index
    if (_type === 'ANSWER') {
      newValue = `${availableValues[0].value}`;
    }

    setCondition({
      ...condition,
      type: _type,
      sign: 'eq',
      value: newValue,
    });
  };

  const setConditionSign = (_sign) => {
    setCondition({
      ...condition,
      sign: _sign,
    });
  };

  const setConditionValue = (_value) => {
    if (_value.length > 128) return;

    setCondition({
      ...condition,
      value: _value,
    });
  };
  /*
<div className="column is-6">
        <TypeSelector
          type={type}
          types={availableTypes}
          setType={setConditionType}
        />
      </div>
      <div className="column is-2">
        <SignSelector
          onlyEqualSign={false}
          sign={sign}
          setSign={setConditionSign}
        />
      </div>
*/
  return (
    <div className=" mt-3">
      <p>abgestimmt wurde mit </p>
      <div className="">
        <ValueSelector
          onlyNumbers={false}
          isTextfield={false}
          values={availableValues}
          value={value}
          setValue={setConditionValue}
        />
      </div>
    </div>
  );
};

EmailConditionSelector.propTypes = {
  /** One condition object
        * @example: Condition: For Question at index 1, answer should be User Input
        * question would be 1, type would be "answer", sign "eq" and value -1
        */
  condition: PropTypes.shape({
    /* Index of the question the condition should be applied to */
    question: PropTypes.number,
    /* Type of the condition. Values: ['duration', 'answer', 'value'] */
    type: PropTypes.string,
    /*
         * Sign of the type for the condition. Values: ['gt', 'lt', 'eq', 'gte', 'lte']
         * Used to determine the range of the value
         */
    sign: PropTypes.string,
    /* Value of the condition */
    value: PropTypes.string,
  }).isRequired,
  setCondition: PropTypes.func.isRequired,
};

export default EmailConditionSelector;
