import React from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  addOption, setQuestionMedia, setAllowText,
} from 'reducer/hubs';

// Constants
import {
  HUB_MAX_OPTIONS,
} from 'constants/hub';

// Components
import FileUploader from 'components/hub/HubContentEditor/FileUploader';
import QuestionEditor from '../QuestionEditor';
import AllowTextEditor from '../AllowTextEditor';
import Option from './Option';

const PollQuestion = (props) => {
  const dispatch = useDispatch();
  const { id: hubID, content } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const { id } = props;

  const question = questions[id];

  const {
    options, media, allowText,
  } = question;

  const setMedia = (fileUrl, fileType) => {
    stats.push(['trackEvent', 'Hub Slider Question Editor', 'Question Media set']);
    dispatch(setQuestionMedia({ id, source: fileUrl, type: fileType }));
  };

  const _addOption = () => {
    dispatch(addOption(id));

    stats.push(['trackEvent', 'Hub Poll Question Editor', 'Option added']);
  };

  const _setAllowText = (_allowText) => {
    stats.push(['trackEvent', 'Hub Poll Question Editor', 'Allow text set']);
    dispatch(setAllowText({ id, allowText: _allowText }));
  };

  const showOptions = () => (
    options.map((option, index) => (
      <Option
        question={id}
        id={index}
        key={index}
      />
    ))
  );

  return (
    <div>
      <p className="mb-5 is-size-7">Nutzer können aus verschiedenen Antwortmöglichkeiten wählen. </p>
      <QuestionEditor id={id} />
      <div className="has-border-bottom pb-4">
        <FileUploader hub={hubID} file={media} setFile={setMedia} />
      </div>
      { showOptions() }
      {
        options.length < HUB_MAX_OPTIONS
        && (
        <div className="">
          <h2 className="my-5 has-text-weight-bold is-size-6  has-text-black">
            Füge Antwortmöglichkeiten hinzu
          </h2>
          <button
            className="mb-5 is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
            onClick={_addOption}
            type="button"
          >
            <i className="fa fa-circle has-text-pastel is-size-7 mr-2" />
            <p className="is-size-7 has-text-weight-bold is-inline">
              Option hinzufügen
            </p>
          </button>
        </div>
        )
      }
      <div className="mt-4">
        <AllowTextEditor isAllowed={allowText} setAllowed={_setAllowText} id={id} />
      </div>
    </div>
  );
};

PollQuestion.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PollQuestion;
