import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';

const ScriptManually = () => (
  <>
    <div className="has-text-centered">
      <i className="fas fa-file-code has-text-primary is-size-1 mb20"> </i>
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold">Flowdust manuell einbinden</p>
    <div className="mt20 mb20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere den untenstehenden HTML Code</p>
    </div>
    <ScriptTagCopy />
    <div className="mt-3 mb-5">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">{'Füge ihn vor dem schließenden </body>-tag in deine HTML Datei ein'}</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);
export default ScriptManually;
