import React from 'react';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';

// Components
import Popup from 'reactjs-popup';

function NoAuthFilePopup() {
  const {
    loginWithRedirect,
  } = useAuth0();

  const login = async (close) => {
    try {
      close();
      await loginWithRedirect();
    } catch (err) {
      // TODO: show error message
      console.log(err);
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="has-border button br10 py-1 px-3 has-fullheight has-background-white has-cursor-pointer has-hover"
        >
          <i className="fas fa-plus has-text-pastel is-size-7 mr-2" />
          <p className="has-text-grey is-size-7 has-text-weight-semibold is-inline">
            Datei hinzufügen
          </p>
        </button>
      )}
      position="top center"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
      modal
      className="no-inset"
    >
      { (close) => (
        <div className="br22 has-text-centered box pt-2 p-5">
          <div className="has-text-right">
            <button
              className="cleanButton"
              onClick={close}
              type="button"
            >
              <i className="fas fa-times has-text-grey is-size-6" />
            </button>
          </div>
          <h2 className="has-text-centered has-text-weight-semibold has-text-black is-size-6mt-2">
            Um Daten hochladen zu können benötigst du einen (kostenlosen) Flowdust-Account.
          </h2>
          <p className="has-text-centered has-text-weight-semibold has-text-black is-size-6 mb-4">
            Möchtest du dich jetzt anmelden?
          </p>
          <button
            type="button"
            className="button grow br10 is-primary has-text-weight-bold is-size-6 has-text-white has-hover-primary has-fullheight p-3 has-content-centered has-line-height-full"
            onClick={() => login(close)}
          >
            Ja, zum Login
            <i className="fas fa-arrow-up-right-from-square ml-3" />
          </button>
        </div>
      )}
    </Popup>
  );
}

export default NoAuthFilePopup;
