import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';

// Components
import AnalyticsChart from '../AnalyticsChart';

const BrowserStatistic = () => {
  const { analytics } = useSelector((state) => state.hubs.statistic);
  const { browser } = analytics;

  const [statisticPerBrowser, setStatisticPerBrowser] = useState([]);
  // compares each browser with each other
  const [generalStatistic, setGeneralStatistic] = useState([]);

  useEffect(() => {
    if (browser.length === 0) return;

    const dataPerBrowser = [];
    const generalData = [];

    for (let i = 0; i < Math.min(browser.length, 5); i++) {
      const { name, versions, totalAmount } = browser[i];

      const browserData = [];

      for (let j = 0; j < Math.min(versions.length, 5); j++) {
        const { version, amount } = versions[j];

        browserData.push(
          {
            x: `${name} ${version}`, y: amount, index: j, label: `Version ${version}`,
          },
        );
      }

      generalData.push({
        x: name, y: totalAmount, label: name, index: i,
      });
      dataPerBrowser.push(browserData);
    }

    setGeneralStatistic(generalData);
    setStatisticPerBrowser(dataPerBrowser);
  }, [browser]);

  const showGeneralStatistic = () => {
    if (generalStatistic.length === 0) return null;

    return (
      <AnalyticsChart
        statistic={generalStatistic}
      />
    );
  };

  const showStatisticPerBrowser = () => {
    const hasData = statisticPerBrowser.length !== 0;
    if (!hasData) return null;

    return statisticPerBrowser.map((_statistic, index) => {
      const { name, totalAmount } = browser[index];

      return (
        <AnalyticsChart
          statistic={_statistic}
          key={index}
          name={name}
          amount={totalAmount}
          prefix="Version "
        />
      );
    });
  };

  return (
    <div className="my-4">
      <h2 className="has-text-centered has-text-black has-text-weight-semibold">
        Aufrufe pro Browser
      </h2>
      <div>
        { showGeneralStatistic() }
      </div>
      <h2 className="has-text-centered mt-6 has-text-black has-text-weight-semibold">
        Aufrufe pro Browserversion
      </h2>
      <div>
        { showStatisticPerBrowser() }
      </div>
    </div>
  );
};

export default BrowserStatistic;
