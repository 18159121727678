import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Assets
import pictures from './pictures';

/*
 * Component to get a random profile pic to display
 */
const ProfilePic = (props) => {
  const { index } = props;

  const pic = useMemo(() => pictures[index % pictures.length], []);

  return <img alt="" className="has-width-64" src={pic} />;
};

ProfilePic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ProfilePic;
