import React, { useState, useEffect } from 'react';

// Libraries
import axios from 'axios';
import stats from 'analytics/analytics';
import notification from 'utils/notification';
import PropTypes from 'prop-types';

// Constants
import { FLOWTER_MAX_QUESTIONS } from 'constants/flowter.js';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Styles
import './css/flowterCreator.scss';

// Actions
import { addFlowter, updateFlowter } from 'actions/flowter';
import { updateWebsitesWithWidget } from 'actions/websites';
import { incrementWidgetCount } from 'actions/user';

// Reducer
import {
  setEndTxt,
  addQuestion,
  resetPreview,
  clearFlowterCreator,
} from 'reducer/flowterCreator';

// Components
import Creator from 'components/widgets/creator/Creator';
import isBasicWidgetValid from 'components/widgets/creator/isBasicWidgetValid';
import Question from './Question';
import Flowter from './widget/Flowter';

function FlowterCreator(props) {
  const signal = axios.CancelToken.source();

  const dispatch = useDispatch();

  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);

  // Flowter Creator
  const {
    questions, endTxt, showResetButton,
  } = useSelector((state) => state.flowterCreator);

  // Basic Widget Creator
  const {
    layout, name, trigger, settings, editIndex, color,
  } = useSelector((state) => state.creatorState);

  // All Flowter
  const { flowters } = useSelector((state) => state.flowterState);

  const { getAccessTokenSilently } = useAuth0();

  const allQuestions = questions;

  const isEdit = editIndex !== -1;

  useEffect(
    () => () => {
      signal.cancel();
      dispatch(clearFlowterCreator());
    }
    // eslint-disable-next-line
	, [])

  const resetCreatorPreview = () => {
    dispatch(resetPreview());
  };

  const loadQuestions = () => allQuestions.map((question, index) => (
    <div key={index}>
      <div className="box is-paddingless mt30 mb30">
        <Question
          key={index}
          id={index}
          isPreview={false}
        />
      </div>
    </div>

  ));

  // Set the text that displays when the user successfully submitted the form
  const setEndText = (txt) => {
    if (endTxt.length >= 100) {
      stats.push(['trackEvent', 'Flowter Creator', 'End text max length reached']);

      setError('Kurz und knapp ist unser Motto. Wähle doch bitte einen kürzeren Text.');
    } else {
      setError('');
      dispatch(setEndTxt(txt));
    }
  };

  const isInputValid = () => {
    if (allQuestions.length === 0) {
      setError('Hoppla, Du hast vergessen eine Frage hinzuzufügen!');
      return false;
    }

    if (name.length === 0) {
      setError('Bitte gib deinem Flowter einen Namen!');
      return false;
    }

    for (let i = 0; i < flowters.length; i++) {
      if (flowters[i].meta.name === name) {
        // if it's an edit and a different flowter than the current one has the same name
        if (editIndex !== i) {
          setError('Es gibt schon einen Flowter mit diesem Namen. Wähle bitte einen anderen.');
          return false;
        }
      }
    }

    if (endTxt.length === 0) {
      setError('Sag deinen Nutzern Danke. Wähle dafür bitte einen Text, der nach der Abstimmung erscheinen soll.');
      return false;
    }

    for (let i = 0; i < allQuestions.length; i++) {
      if (allQuestions[i].question.length === 0) {
        setError('Bitte achte darauf, dass jede Frage ausgefüllt ist.');
        return false;
      }
    }

    /* for (let j = 0; j < allQuestions.length; j++) {
      if (
        allQuestions[j].options.length < 2
        && allQuestions[j].allowText === false
      ) {
        setError('Bitte achte darauf, dass jede Frage mindestens 2 Antwortmöglichkeiten hat.');
        return false;
      }

      for (let k = 0; k < allQuestions[j].options.length; k++) {
        if (allQuestions[j].options[k].length === 0) {
          setError('Bitte achte darauf, dass alle Antwortmöglichkeiten ausgefüllt sind.');
          return false;
        }
      }
    } */

    if (!isBasicWidgetValid(setError, trigger, layout, settings)) {
      return false;
    }

    setError('');
    return true;
  };

  const handleError = (err) => {
    if (axios.isCancel(err)) {
      return;
    }

    console.log(err);

    setDisabled(false);

    if (!err.response || !err.response.data) {
      setError('Es ist etwas schief gelaufen. Bitte versuche es später noch einmal.');
      return;
    }

    if (err.response.status === 500) {
      setError('Server Fehler. Bitte versuche es später noch einmal.');
      return;
    }

    console.log(err.response);
    console.log(err.response.data);

    setError(err.response.data);
  };

  const createFlowter = async (data, config) => {
    const flowter = await axios.post(`${process.env.REACT_APP_API}/flowters`, data, config);

    dispatch(addFlowter(flowter.data));
    dispatch(incrementWidgetCount());

    stats.push(['trackEvent', 'Flowter Creator', 'Flowter created']);

    notification.send('Erledigt!', 'Ein neuer Flowter wurde erstellt.', 'success');
  };

  const updateKnownFlowter = async (data, config) => {
    const flowter = await axios.put(`${process.env.REACT_APP_API}/flowters`, data, config);

    dispatch(updateFlowter(flowter.data));
    dispatch(updateWebsitesWithWidget(flowter.data));

    stats.push(['trackEvent', 'Flowter Creator', 'Flowter updated']);

    notification.send('Erledigt!', 'Dein Flowter wurde bearbeitet.', 'success');
  };

  const saveFlowter = async () => {
    if (!isInputValid()) {
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }

    setError('');
    setDisabled(true);

    // Dont save checks in database
    const flowterQuestions = [];
    for (let i = 0; i < allQuestions.length; i++) {
      const obj = allQuestions[i];
      flowterQuestions.push({
        question: obj.question,
        maxNumberOfChecks: obj.maxNumberOfChecks,
        options: obj.options,
        allowText: obj.allowText,
      });
    }

    const data = {
      questions: flowterQuestions,
      endTxt,
      name,
      layout,
      trigger,
      settings,
      color,
    };

    const token = await getAccessTokenSilently();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: signal.token,
    };

    try {
      if (isEdit) {
        // add widget id to the data for the server
        data.id = flowters[editIndex]._id;
        await updateKnownFlowter(data, config);
      } else {
        await createFlowter(data, config);
      }

      props.finishCreation();
    } catch (e) {
      handleError(e);
    }
  };

  const addNewQuestion = (e) => {
    e.preventDefault();

    if (allQuestions.length >= FLOWTER_MAX_QUESTIONS) {
      stats.push(['trackEvent', 'Flowter Creator', 'Too many questions']);

      return setError('Da wir die Flowter so kurz wie möglich halten wollen, kannst Du leider keine weiteren Fragen hinzufügen.');
    }

    stats.push(['trackEvent', 'Flowter Creator', 'Question added']);

    dispatch(addQuestion());

    return null;
  };

  const amountQuestions = allQuestions.length;

  return (

    <Creator
      showResetButton={showResetButton}
      resetWidget={resetCreatorPreview}
      saveWidget={saveFlowter}
      widgetPreview={<Flowter />}
      error={error}
      disabled={disabled}
    >
      <div className="flowterCreatorWrapper">
        <div>
          <div className="has-text-black mb20">
            <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">2</p>
            <h2 className="has-text-weight-bold is-size-6 has-text-centered pt10 is-inline">Erstelle deine Fragen</h2>
          </div>
          { loadQuestions() }
          {
          amountQuestions < FLOWTER_MAX_QUESTIONS
          && (
          <div className="has-text-centered box">
            <button
              className="cleanButton has-text-pastel has-hover-text-primary"
              onClick={(event) => addNewQuestion(event)}
              type="button"
            >
              <div
                className="pt5 pb5 pl50 pr50 is-size-7  is-outlined has-background-pastel has-no-border has-text-white br10"
              >
                <i className="fas fa-plus" />
              </div>
              <p className="mt10 has-text-weight-bold has-text-black">
                Frage hinzufügen
              </p>
            </button>
          </div>
          )
        }
        </div>
        <div id="endTxtWrapper" className="mt50 mb50">
          <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">3</p>
          <h2 className="has-text-weight-bold is-size-6 has-text-centered pt10 is-inline has-text-black">Gib an, welcher Text am Ende angezeigt werden soll</h2>
          <input
            onChange={(event) => setEndText(event.target.value)}
            type="text"
            value={endTxt}
            placeholder="Danke für's Mitmachen!"
            className="creatorInput input is-size-6 has-text-black has-text-centered has-background-white is-medium has-text-weight-bold mt20"
          />
        </div>
      </div>
    </Creator>
  );
}

FlowterCreator.propTypes = {
  finishCreation: PropTypes.func,
};

FlowterCreator.defaultProps = {
  finishCreation: () => {},
};

export default FlowterCreator;
