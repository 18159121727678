import feedbackThumbnail from 'assets/images/templates/audio.png';
import onboardingThumbnail from 'assets/images/templates/onboarding.png';
import localThumbnail from 'assets/images/templates/local.png';
import sliderThumbnail from 'assets/images/templates/slider.png';
import emailThumbnail from 'assets/images/templates/email.png';
import usabilityThumbnail from 'assets/images/templates/usability.png';
import eventThumbnail from 'assets/images/templates/event.png';
import reviewThumbnail from 'assets/images/templates/reviews.png';
import supportThumbnail from 'assets/images/templates/kundenservice.png';
import websiteThumbnail from 'assets/images/templates/websitefeedback.png';
import productThumbnail from 'assets/images/templates/produkt.png';
import courseThumbnail from 'assets/images/templates/course.png';
import newsletterThumbnail from 'assets/images/templates/newsletter.png';
import restaurantThumbnail from 'assets/images/templates/restaurant.png';
import mitarbeiterThumbnail from 'assets/images/templates/mitarbeiter.png';
import blogThumbnail from 'assets/images/templates/blog.png';

export const templates = [
  {
    id: 'd1eb1845-3c0b-41a9-8e71-5083ebf71dce',
    name: 'Blog Formular',
    descriptionShort: 'Optimiere Deinen Blog und frage nach, welche Themen Deine Leser interessieren.',
    descriptionLong: 'Ziehe mehr und mehr Leser auf Deinen Blog, indem du über das schreibst, was die Besucher Deines Blogs lesen möchten. Frage sie zudem, wie oft ein Artikel erscheinen soll und ob Aufbau und Gestaltung ansprechend sind.',
    tags: ['landing'],
    thumbnail: blogThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie gefallen Dir meine Blogbeiträge?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie oft besuchst Du den Blog?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Täglich',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Gelegentlich',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Immer, wenn ein neuer Blogbeitrag erscheint',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Selten',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Findest Du die Navigation auf dem Blog einfach?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Über welche Themen soll ich schreiben?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Toll, danke für Deine Meinung und Deine Vorschläge!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },

  {
    id: 'd2ec1845-3f0b-41a9-8e71-5083ebf82dce',
    name: 'Mitarbeiter Befragung',
    descriptionShort: 'Führe eine Mitarbeiter-Befragung durch und steigere ihre Motivation.',
    descriptionLong: 'Gute Arbeitsmoral Deiner Mitarbeiter ist der Schlüssel eines effizieten Unternehmens. Finde heraus, wie Du ihre Motivation erhöhen kannst, um noch mehr Gewinn zu erzielen.',
    tags: ['landing'],
    thumbnail: mitarbeiterThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie gefällt Ihnen die Arbeit hier?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Gefällt Ihnen die Atmosphäre Ihres Arbeitsplatzes? Wenn Nein, warum nicht?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Finden Sie Ihren Job herausfordernd und aufregend?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Finden Sie, dass Ihre Arbeit geschätzt wird?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie könnte das Unternehmen Ihre Motivation verbessern?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für Ihre Meinung. Wir tun weiterhin das Bestmöglichste, dass Sie sich bei uns wohlfühlen.',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },

  {
    id: 'd1eb1945-3f1b-41a9-8e71-5083ebf82dce',
    name: 'Restaurant Feedback',
    descriptionShort: 'Werde das beste Restaurant der Stadt, indem Du die Wünsche Deiner Kunden erfüllst.',
    descriptionLong: 'Gefällt Deinen Kunden das Ambiente? Und was sagen sie eigentlich zur Qualität Deiner Speisen? Finde es heraus!',
    tags: ['landing'],
    thumbnail: restaurantThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie lange musstest Du auf Deine Bestellung warten?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: '< 20 Minuten',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'ca. 30 Minuten',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'zwischen 45 Minuten und einer Stunde',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehr als eine Stunde',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie bist Du mit dem Service zufrieden?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Gefällt Dir das Ambiente? Wenn Nein, warum nicht?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie sehr hat Dir Deine Bestellung geschmeckt?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Bist Du mit dem Preis-Leistungs Verhältnis zufrieden?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },

      ],
      endings: [
        {
          text: 'Danke für Dein Feedback! Wir hoffen, dass Du uns bald wieder besuchen wirst.',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'd2fb1925-3f0b-41a9-8e71-5083ebf82fcb',
    name: 'Newsletter Anmeldung',
    descriptionShort: 'Halte Deine User immer auf dem neusten Stand und informiere sie über wichtige Informationen.',
    descriptionLong: 'Mit der Vorlage zur Newsletter Registrierung können sich Deine Nutzer mit nur wenigen Klicks eintragen, um Nichts mehr zu verpassen.',
    tags: ['landing'],
    thumbnail: newsletterThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Welche Themen interessieren Dich?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'News zum Unternehmen',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Online Marketing',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Webdevelopment',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Events & mehr',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie oft wünschst Du dir, News zu erhalten?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Jede Woche',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Alle 2 Wochen',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Einmal pro Monat',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Einmal alle 3 Monate',
              media: {
                source: '',
                type: '',
              },
            },

          ],
        },

        {
          text: 'Hinterlasse uns Deine E-Mail, um Dich für den Newsletter zu registrieren.',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'EMAIL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Super, wir freuen uns über Deine Anmeldung!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'd2eb1945-3f0b-41a9-8e71-5083ebf82dce',
    name: 'Kurs Evaluation',
    descriptionShort: 'Hole die Meinung der Teilnehmer ein und verbessere Deine Kurse stetig.',
    descriptionLong: 'Erfahre, wie die Teilnehmer den Kurs fanden, was sie mitgenommen haben und frage sie nach Verbesserungsvorschlägen.',
    tags: ['landing'],
    thumbnail: courseThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie zufrieden warst Du mit dem Kurs?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie hast Du von dem Kurs erfahren?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Website',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Freunde / Kollegen / Familie',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Zeitschrift',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Social Media',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Werbeanzeige',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Konnte der Kursleiter das Thema gut rüberbringen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Hat der Kurs Deinen Erwartungen entsprochen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie können wir den Kurs verbessern?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Danke für Deine Zeit und Dein Feedback!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'd2eb1945-3f0b-41a9-8e71-5183ebf82dce',
    name: 'Produkt Bewertung',
    descriptionShort: 'Lass Deine Nutzer Dein Produkt bewerten und optimiere es, basierend auf ihrer Meinung.',
    descriptionLong: 'Bitte Deine Kunden um Feedback und Verbesserungsvorschläge zu Deinem Produkt. Passe es nach ihren Wünschen an, um noch mehr Verkäufe zu erzielen.  ',
    tags: ['landing'],
    thumbnail: productThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Was gefällt Dir an unserem Produkt am Besten?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Aussehen',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Funktionalität',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Design',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Einzigartigkeit',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Was gefällt dir nicht?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [
            {
              text: 'Preis',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Design',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Verpackung',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Würdest Du das Produkt weiterempfehlen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wenn Du etwas an unserem Produkt verbessern könntest, was wäre es?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für Deine Meinung!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },

  {
    id: 'd2eb1925-3f0b-41a9-8e71-5083ebf82dce',
    name: 'Website-Zufriedenheit messen',
    descriptionShort: 'Hier können Nutzer ihre Meinung und Wünsche zu Deiner Website äußern.',
    descriptionLong: 'Eine übersichtliche Navigation, ansprechende Designs und optimale Usability machen eine gute Website aus. Überprüfe mithilfe der Vorlage, ob Deine Nutzer mit Deiner Website zufrieden sind.',
    tags: ['landing'],
    thumbnail: websiteThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie hast Du von uns erfahren?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Werbeanzeige',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Social Media',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Freunde / Familie',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Zeitung',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Was hat Dich dazu motiviert, unsere Website zu besuchen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Angebote',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Markenname',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Funktionalität',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ansprechendes Design',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie findest Du die Navigation auf der Website?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Sehr einfach',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Einfach',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Okay',
              media: {
                source: '',
                type: '',
              },
            },

            {
              text: 'Schwierig',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Sehr schwierig',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Was gefällt Dir an der Website überhaupt nicht?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
        {
          text: 'Wie zufrieden bist Du insgesamt mit unserer Website?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für Deine Meinung!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'd2eb1925-3f0b-41a9-8e71-5083ebf82fce',
    name: 'Kundenservice Feedback',
    descriptionShort: 'Feedback Hub zum Thema Kundensupport.',
    descriptionLong: 'Die Zufriedenheit der Nutzer ist das A und O. Finde mit der Vorlage heraus, wie Deine Nutzer den Kundenservice bewerten und was verbessert werden kann.',
    tags: ['landing'],
    thumbnail: supportThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie oft hast Du unseren Kundenservice bereits kontaktiert?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Noch kein mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '1-3 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '3-6 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehr als 10 mal',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie lange musstest Du auf eine Antwort warten?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Wenige Stunden',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ca. einen Tag',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehr als einen Tag',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehr als eine Woche',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Welche Kontaktmöglichkeit hast Du benutzt?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Live Chat',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'E-Mail',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Telefon',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Würdest Du den Kundensupport wieder in Anspruch nehmen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie können wir unseren Service verbessern?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für Deine Meinung!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'd2eb1925-3f0b-41a9-8e71-5083ebf82fcb',
    name: 'Kontaktanfrage',
    descriptionShort: 'Wie ein Hub als intelligentes Kontaktformular funktionieren kann, auch ohne eigene Website.',
    descriptionLong: 'Mit dem Flowdust Hub ist es möglich ganz einfach ein Kontaktformular erstellen - auch ohne eigener Website und ohne technische Kenntnisse! Zuerst wird sich nach der Art der Anfrage erkundigt, anschließend wird nach den Kontaktinformationen gefragt.',
    tags: ['landing'],
    thumbnail: emailThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Welche Art der Anfrage möchten Sie an uns stellen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ich benötige weitere Informationen',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich brauche Beratung oder Support',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich habe Feedback zum Produkt',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Etwas anderes',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie können wir Ihnen helfen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie lautet Ihr Name?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie lautet Ihre E-Mail Adresse unter der wir Sie kontaktieren können?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'EMAIL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank, wir werden uns in Kürze bei Ihnen melden!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Unsere Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },

  {
    id: '02504a66-0d71-45a0-9827-166f22de683a',
    name: 'Positive Bewertungen erhalten',
    descriptionShort: 'Diese Vorlage findet die Zufriedenheit der Nutzer heraus und fragt nur bei positivem Feedback nach einer Bewertung auf verschiedenen Plattformen.',
    descriptionLong: 'Teilnehmer werden in unterschiedliche Richtungen gelenkt, je nachdem wie sie abgestimmt haben. Kritik wird abgefangen und bei positivem Feedback wird nach einer Bewertung gefragt.',
    tags: ['landing'],
    thumbnail: reviewThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie bist Du auf uns aufmerksam geworden?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Suchmaschine',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Social Media',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Werbeanzeige',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Familie / Freunde',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie sehr bist Du mit unserem Service zufrieden?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ich bin sehr zufrieden',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich bin zufrieden',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ist in Ordnung',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Naja...',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich bin sehr unzufrieden',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Möchtest Du uns noch etwas mitteilen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Danke für dein Feedback! Lass uns gerne eine Bewertung da!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'GOOGLE',
              url: 'https://google.com',
              text: 'Zur Google Bewertung',
            },
            {
              type: 'LINK',
              url: 'https://tripadvisor.com',
              text: 'Zu Tripadvisor',
            },
          ],
        },
        {
          text: 'Danke für deine Meinung! Wir werden daran arbeiten uns zu verbessern! Du kannst diese Seite nun schließen',
          media: {
            source: '',
            type: '',
          },
          links: [],
        },
      ],
      logic: [
        {
          trigger: 2,
          conditions: [
            {
              question: 1,
              type: 'ANSWER',
              sign: 'EQ',
              value: '2',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              index: 1,
              type: 'ENDING',
            },
          },
        },
        {
          trigger: 2,
          conditions: [
            {
              question: 1,
              type: 'ANSWER',
              sign: 'EQ',
              value: '3',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              index: 1,
              type: 'ENDING',
            },
          },
        },
        {
          trigger: 2,
          conditions: [
            {
              question: 1,
              type: 'ANSWER',
              sign: 'EQ',
              value: '4',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              index: 1,
              type: 'ENDING',
            },
          },
        },
      ],
      settings: {
        hasAdvancedLogic: true,
      },
    },
  },
  {
    id: '96c72a50-c764-45d2-acdb-2d2b34445b51',
    name: 'Event Evaluation',
    descriptionShort: 'Schnell und einfach Feedback nach einem Event oder einer Veranstaltung sammeln.',
    descriptionLong: 'Die Vorlage hilft, schnell und einfach nach einer Veranstaltung Feedback zu sammeln. Erst wird das Event bewertet und Verbesserungsmöglichkeiten werden eingeholt, dann wird für zukünftige Events nachgefragt.',
    tags: ['landing'],
    thumbnail: eventThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie hat Dir unsere Veranstaltung gefallen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Welches Panel hat Dir am Besten gefallen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
        {
          text: 'Was können wir für das nächste Event verbessern?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
        {
          text: 'Welche Themen würden Dich interessieren?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
        {
          text: 'Lass uns Deine E-Mail da, wenn Du für die nächsten Events benachrichtigt werden möchtest.',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'EMAIL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für dein Feedback! Wir freuen uns schon auf die nächste Veranstaltung mit Dir!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Besuche unsere Website!',
            },
            {
              type: 'FB',
              url: 'https://facebook.com',
              text: 'Zu unserer Facebook-Seite',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: '975ae157-5259-4925-a854-7dfd93622291',
    name: 'eCommerce Post-Purchase Feedback',
    descriptionShort: 'Wertvolles Feedback sammeln, nachdem ein Kunde einen Kauf abgeschlossen hat.',
    descriptionLong: 'Nach einem abgeschlossenen Kaufprozess ist es ideal, den Kunden nach Feedback zu fragen und herauszufinden, ob es Probleme gab und welche Verbesserungsmöglichkeiten es gibt.',
    tags: ['landing'],
    thumbnail: sliderThumbnail,
    content: {
      info: {
        text: 'Bitte beantworten Sie uns kurz einige Fragen zu Ihrem Kauf. (Geht ganz schnell!) Als Dank erhalten Sie nach dem Kauf einen Gutschein für 10% auf Ihren nächsten Einkauf bei uns.',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Hat beim Kauf alles reibungslos funktioniert? (Bei Nein bitte Probleme angeben)',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja, alles war wunderbar',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Welche Produkte würden Sie sich noch von uns wünschen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
        {
          text: 'Wie haben Sie von uns erfahren?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Durch Freunde oder Familie',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Durch Social Media',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Durch Außenwerbung',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Durch einen TV-Spot',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },

        {
          text: 'Würden Sie uns weiterempfehlen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ja, absolut',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Nein, ich war nicht zufrieden',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich bin mir nicht sicher',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
      ],
      endings: [
        {
          text: 'Danke fürs Mitmachen! Folgen Sie uns gerne auf unseren Social Media Kanälen, um Nichts mehr zu verpassen.',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://flowdust.com',
              text: 'Hier gehts zum Gutschein',
            },
          ],
        },
        {
          text: 'Danke für Ihre Meinung. Wir nehmen uns Ihr Feedback zu Herzen und arbeiten daran, den bestmöglichen Service für Sie bieten zu können.',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'flowdust.com',
              text: 'Hier gehts zum Gutschein',
            },
          ],
        },
      ],
      logic: [
        {
          trigger: 3,
          conditions: [
            {
              question: 0,
              type: 'ANSWER',
              sign: 'EQ',
              value: '-1',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              type: 'ENDING',
              index: 1,
            },
          },
        },
        {
          trigger: 3,
          conditions: [
            {
              question: 3,
              type: 'ANSWER',
              sign: 'EQ',
              value: '1',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              type: 'ENDING',
              index: 1,
            },
          },
        },
        {
          trigger: 3,
          conditions: [
            {
              question: 3,
              type: 'ANSWER',
              sign: 'EQ',
              value: '2',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              type: 'ENDING',
              index: 1,
            },
          },
        },
      ],
      settings: {
        hasAdvancedLogic: true,
      },
    },
  },
  {
    id: '7113f858-2119-4432-8d98-1d9a984432a7',
    name: 'Usability herausfinden',
    descriptionShort: 'Die Benutzerfreundlichkeit einer Website mit gezielten Fragen herausfinden.',
    descriptionLong: 'Finden sich die Besucher auf der Website zurecht? Und wie empfinden die Nutzer die Kaufabwicklung? Mit dieser Vorlage findet man die Benutzerfreundlichkeit einer Website heraus.',
    tags: ['landing'],
    thumbnail: usabilityThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie oft besuchst Du unsere Seite?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Jeden Tag',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehrmals pro Woche',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ab und zu',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Selten',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie einfach war es für Dich, die gesuchten Seiten zu finden? (0% = Sehr schwer, 100% = sehr leicht)',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie einfach ist für Dich die Zahlungsabwicklung gestaltet?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Was können wir verbessern?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Navigation auf der Website',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Design',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Mehr Funktionen',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Bessere Übersicht',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Möchtest Du uns noch etwas mitteilen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für dein Feedback!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Zu unserer Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: '903102ca-2934-490f-9af1-2984c1d80ef7',
    name: 'Feedback Formular',
    descriptionShort: 'Ein einfaches Feedback Formular, um schnell auf einer zentralen Seite Feedback sammeln zu können',
    descriptionLong: 'Diese Vorlage hilft, einen zentralen Anlaufspunkt für Feedback zu haben. So kann man schnell und einfach Impulse von Nutzern sammeln.',
    tags: ['general'],
    thumbnail: feedbackThumbnail,
    content: {
      info: {
        text: 'Haben Sie Feedback oder Vorschläge? Wir würden uns sehr über ihren Input freuen!',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Welche Art von Feedback möchten Sie abgeben?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Vorschlag oder Anmerkung',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Fehlermeldung',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Frage',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Etwas anderes',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Was möchten Sie uns mitteilen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
        {
          text: 'Falls Sie von uns kontaktiert werden möchten, geben Sie bitte Ihren Namen an.',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
        {
          text: 'Wie lautet Ihre E-Mail Adresse?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'EMAIL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Vielen Dank für Ihr Feedback! Sie können diese Seite nun schließen',
          media: {
            source: '',
            type: '',
          },
          links: [],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: 'baea5f10-5f39-46b3-9529-6373f7d499db',
    name: 'User Onboarding',
    descriptionShort: 'Onboarding eines neuen Kunden am Beispiel eines Formulars für Fitness Coaches',
    descriptionLong: 'Wie ein Hub hilft, das User Onboarding zu vereinfachen, hier am Beispiel eines Formulars für Fitness Coaches.',
    tags: ['general'],
    thumbnail: onboardingThumbnail,
    content: {
      info: {
        text: '',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie fit würdest du Dich momentan einschätzen?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Was ist Dein Ziel?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Ich will Gewicht verlieren',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich will mich gesünder ernähren',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Ich will Muskeln aufbauen',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie oft möchtest Du pro Woche trainieren?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: '1 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '2 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '3 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '4 mal',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: '5 mal',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie lautet Dein Name?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [],
        },
        {
          text: 'Wie lautet Deine E-Mail Adresse unter der Ich Dich kontaktieren kann?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'EMAIL',
          isOptional: false,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Super! Ich melde mich bald bei Dir! In der Zwischenzeit, schau doch mal auf meiner Website vorbei!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Meine Website',
            },
          ],
        },
      ],
      logic: [],
      settings: {
        hasAdvancedLogic: false,
      },
    },
  },
  {
    id: '9213e55e-e3df-4355-8da8-178a0684ce35',
    name: 'Lokal Feedback sammeln',
    descriptionShort: 'Lokal im Geschäft Feedback von Kunden sammeln und positive Bewertungen erhalten, hier am Beispiel eines Cafés.',
    descriptionLong: 'Wie ein Hub hilft Feedback zu sammeln und positive Bewerungen zu bekommen, hier am Beispiel eines Formulars für Cafés. Idealerweise wird lokal im Geschäft ein QR-Code platziert, mit dem Besucher zur Umfrage geleitet werden.',
    tags: ['general'],
    thumbnail: localThumbnail,
    content: {
      info: {
        text: 'Hey! Wir wollen uns weiter verbessern und braucher daher Dein Feedback! Hast du kurz Zeit?',
        media: {
          source: '',
          type: '',
        },
      },
      questions: [
        {
          text: 'Wie gefällt Dir unser neues Café?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: false,
          options: [
            {
              text: 'Super gut!',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Schon recht gut',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Es ist in Ordnung',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Naja...',
              media: {
                source: '',
                type: '',
              },
            },
            {
              text: 'Gefällt mir überhaupt nicht',
              media: {
                source: '',
                type: '',
              },
            },
          ],
        },
        {
          text: 'Wie zufrieden bist Du mit unserem Service?',
          media: {
            source: '',
            type: '',
          },
          allowText: false,
          type: 'SLIDER',
          isOptional: false,
          options: [],
        },
        {
          text: 'Danke für deine Hilfe! Möchtest Du uns sonst noch etwas mitteilen?',
          media: {
            source: '',
            type: '',
          },
          allowText: true,
          type: 'POLL',
          isOptional: true,
          options: [],
        },
      ],
      endings: [
        {
          text: 'Super, dass es Dir gefällt! Wir würden uns mega über eine Bewertung von Dir freuen!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Hier gehts zur Bewertung',
            },
          ],
        },
        {
          text: 'Vielen Dank für Deine Meinung. Wir arbeiten hart daran, uns zu verbessern!',
          media: {
            source: '',
            type: '',
          },
          links: [
            {
              type: 'LINK',
              url: 'https://google.com',
              text: 'Hier gehts zu unserer Website',
            },
          ],
        },
      ],
      logic: [
        {
          trigger: 2,
          conditions: [
            {
              question: 0,
              type: 'ANSWER',
              sign: 'EQ',
              value: '4',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              index: 1,
              type: 'ENDING',
            },
          },
        },
        {
          trigger: 2,
          conditions: [
            {
              question: 0,
              type: 'ANSWER',
              sign: 'EQ',
              value: '3',
            },
          ],
          action: {
            type: 'GOTO',
            value: {
              index: 1,
              type: 'ENDING',
            },
          },
        },
      ],
      settings: {
        hasAdvancedLogic: true,
      },
    },
  },
];
