import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setLogicTrigger, deleteLogic } from 'reducer/hubs';

// Components
import QuestionSelector from './QuestionSelector';
import ConditionEditor from './ConditionEditor';
import ActionEditor from './ActionEditor';

const Logic = (props) => {
  const { logic, questions } = useSelector((state) => state.hubs.creator.content);
  const dispatch = useDispatch();

  const { index } = props;

  const { trigger, action } = logic[index];

  const _setTrigger = (value) => {
    dispatch(setLogicTrigger({ id: index, value }));
  };

  const _deleteLogic = () => {
    dispatch(deleteLogic(index));
  };

  return (
    <div className="mt-4 has-text-black has-border-bottom pb-4">
      <div>
        <p className="has-text-black mb-3 has-text-weight-bold is-inline">
          {`Verzweigung Nr. ${index + 1}`}
        </p>
        <button
          type="button"
          onClick={_deleteLogic}
          className="cleanButton has-text-underlined has-text-danger is-inline mx-1"
        >
          Löschen
        </button>
      </div>
      <p className="pr-2 has-text-weight-bold is-size-7">
        Nachdem
      </p>
      <QuestionSelector
        index={trigger}
        setQuestion={_setTrigger}
      />
      <p className="has-text-weight-bold is-size-7 my-2">
        beantwortet wurde, müssen folgende Bedingungen eintreten:
      </p>

      <ConditionEditor logicIndex={index} />
      <ActionEditor logicIndex={index} />
    </div>
  );
};

Logic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default Logic;
