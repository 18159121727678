import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootReducer from '../reducer';

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
// import cookieMiddleware from 'middleware/cookies';

const middleware = [thunk];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// const middleware = [thunk, cookieMiddleware, createStateSyncMiddleware({})];
// activate redux dev tools only in dev mode
const extension = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;

const composeEnhancers = extension || compose;

// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  // whitelist: ['cookieState', 'creatorState', 'flowterCreator', 'statusCreatorState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer));

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

// initStateWithPrevTab(store);

export const persistor = persistStore(store);
