import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Question from './Question';

const QuestionEditor = () => {
  const { questions, activeContent } = useSelector((state) => state.hubs.creator.content);

  const { index } = activeContent;

  if (!questions || index === -1) return null;

  return (
    <div className="">
      <h2 className="has-text-weight-bold is-size-6 pt-5 has-text-black">
        Erstelle deine Fragen
      </h2>
      <div key={index}>
        <div className="box is-paddingless my-4">
          <Question
            id={index}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionEditor;
