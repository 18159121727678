import React, { useState } from 'react';

// Libraries
import notification from 'utils/notification';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Popup from 'reactjs-popup';

// Actions
import { deleteHub } from 'reducer/hubs';

// Constants
import { HUB_LIST } from 'constants/routes';

// Assets
import loader from 'assets/images/loading.svg';

function DeleteButton() {
  const dispatch = useDispatch();
  const { del } = useAPI();
  const navigate = useNavigate();

  const { hubs, creator } = useSelector((state) => state.hubs);
  const { editIndex: index } = creator;

  const [disabled, setDisabled] = useState(false);

  const removeHub = async (close) => {
    setDisabled(true);

    try {
      const _index = parseInt(index, 10);

      if (Number.isNaN(_index)) return;

      const id = hubs[index]._id;

      await del(`/hubs/${id}`);

      setDisabled(false);

      close();

      navigate(HUB_LIST);

      notification.send('Erledigt!', 'Dein Hub wurde gelöscht.', 'success');

      dispatch(deleteHub(id));
    } catch (err) {
      setDisabled(false);

      if (!err.response.data) {
        notification.send('Etwas hat nicht funktioniert.', 'Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      notification.send('Fehler!', err.response.data, 'danger');
    }
  };

  return (
    <Popup
      trigger={(
        <button
          className="is-flex has-content-centered button has-background-danger has-text-black is-inline-block br10 has-line-height-full"
          type="button"
          disabled={disabled}
        >
          <p
            className="has-text-weight-semibold is-size-7"
          >
            Hub löschen
          </p>
        </button>
      )}
      position="top center"
      on="click"
      keepTooltipInside="#root"
      repositionOnResize
    >
      { (close) => (
        <div className="columns is-marginless">
          <div className="column is-marginless is-paddingless pb10 pl5 pr5 has-background-white br22">
            <div className="has-text-centered">
              <div className="has-text-right">
                <button
                  className="cleanButton"
                  onClick={close}
                  type="button"
                >
                  <i className="fas fa-times has-text-grey-lighter is-size-7" />
                </button>
              </div>
              <h2 className="has-text-centered has-text-black is-size-7">
                Bist Du sicher, dass Du diesen Hub löschen willst? Alle Daten gehen für immer verloren.
              </h2>
              {
               disabled
                 ? (
                   <img
                     className="has-max-height-30 mt-2"
                     src={loader}
                     alt=""
                   />
                 )
                 : (
                   <button
                     className="pt5 pb5 pl10 pr10 cleanButton mt5"
                     onClick={() => removeHub(close)}
                     disabled={disabled}
                     type="button"
                   >
                     <i className="fas fa-trash-alt has-text-danger is-size-7 is-inline mr5" />
                     <p className="is-inline has-text-danger has-text-weight-semibold is-size-7">Löschen</p>
                   </button>
                 )
               }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default DeleteButton;
