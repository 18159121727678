import React from 'react';
import Footer from 'components/layout/Footer';
import register from 'assets/images/register.png';
import share from 'assets/images/share.png';
import earn from 'assets/images/earn.png';
import styles from './styles.module.scss';

function Affiliate() {
  return (
    <div>
      <div className="columns pt-6 has-background-midnightblue m0" id="affiliate">
        <div className="column">
          <div className="columns is-centered">
            <div className="column is-4-desktop is-5-tablet">
              <p className="has-text-weight-bold has-text-pastel is-size-3 is-size-5-mobile">
                Tritt unserem Flowdust Affiliate Programm bei und erhalte 30%
              </p>
              <p className="mt-4 has-text-weight-light has-text-white is-size-6">
                Affiliates sind unsere Partner und wir wollen, dass auch sie erfolgreich sind. Deswegen bieten wir unseren Affiliates jeden Monat eine 30% Kommission aller geworbenen Kunden. Und das ganze 6 Monate lang!
              </p>
              <div className="has-text-centered-mobile mt-5">
                <a
                  href="https://affiliate.flowdust.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-size-6 grow has-text-weight-bold is-rounded button has-background-white has-text-pastel"
                >
                  Affiliate werden
                </a>
              </div>
            </div>
            <div className="column is-offset-2-desktop is-offset-1-tablet is-4">
              <div className="box">
                <p className="mt20 mb10 has-text-weight-bold has-text-pastel is-size-4 is-size-5-mobile">
                  DEINE VORTEILE
                </p>
                <p className="has-text-weight-bold has-text-midnightblue is-size-5 is-size-6-mobile">
                  - Einmalig Kunden werben, monatlich verdienen
                </p>
                <p className="has-text-weight-bold has-text-midnightblue is-size-5 is-size-6-mobile">
                  - 30 % Kommission jeden Monat für ein halbes Jahr
                </p>
                <p className="has-text-weight-bold has-text-midnightblue is-size-5 is-size-6-mobile">
                  - Übersicht über alle Daten und Statistiken
                </p>
                <p className="pb20 has-text-weight-bold has-text-midnightblue is-size-5 is-size-6-mobile">
                  - Auszahlung bereits ab 20 €
                </p>
              </div>
            </div>
          </div>
          <div className="br10 pb-6 m-5 boxshadow has-background-white">
            <div className="mt50 columns is-centered ">
              <div className="column is-4">
                <p className="mt-6 has-border-bottom-pastel-bold has-text-centered has-text-weight-bold has-text-black is-size-3 is-size-5-mobile">
                  Wie funktioniert's?
                </p>
              </div>
            </div>
            <div className="mt-6 columns is-marginless">
              <div className="column has-text-centered is-4">
                <p className="has-text-centered mt20 has-background-dash mb-6 has-text-weight-bold has-text-black is-size-4 is-size-5-mobile">
                  REGISTRIEREN
                </p>
                <div className={`${styles.imageWrapper} boxshadow`}>
                  <img src={register} alt="" />
                </div>
                <p className="has-text-centered px-2 mt40 mb10 has-text-weight-light has-text-grey">
                  Melde Dich in nur wenigen Minuten an, um Flowdust Affiliate zu werden.
                </p>
              </div>
              <div className="column is-4">
                <p className="has-background-dash has-text-centered mt20 mb40 has-text-weight-bold has-text-black is-size-4 is-size-5-mobile">TEILEN</p>
                <div className={`${styles.imageWrapper} boxshadow`}>
                  <img src={share} alt="" />
                </div>
                <p className="has-text-centered mt40 mb10 has-text-weight-light has-text-grey is-size-6 px-2">
                  Sobald wir Dich freigeschalten haben, erscheint in Deinem Account Dein Affiliate Code. Nutze diesen, um Flowdust mit der Welt zu teilen.
                </p>
              </div>
              <div className="column is-4">
                <p className="has-background-dash mt20 mb40 has-text-centered has-text-weight-bold has-text-black is-size-4 is-size-5-mobile">
                  VERDIENEN
                </p>
                <div className={`${styles.imageWrapper} boxshadow`}>
                  <img src={earn} alt="" />
                </div>
                <p className="has-text-centered mt40 mb10 has-text-weight-light has-text-grey is-size-6 px-2">
                  Erhalte 30% für jede Person, die durch Deinen Affiliate Link ein Abonnement abschließt. Und das nicht nur einmalig! Deine Kommission verdienst du jeden Monat für ein halbes Jahr.
                </p>
              </div>
            </div>
            <div className="has-text-centered">
              <a
                href="https://affiliate.flowdust.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="is-size-6 grow boxshadow ml20 mt20 has-text-weight-bold is-rounded button has-background-pastel has-text-white"
              >
                Jetzt Affiliate werden
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Affiliate;
