import React from 'react';
import Popup from 'reactjs-popup';
import stats from 'analytics/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FLOWTER_INFO, HUB_INFO } from 'constants/routes';
import { Link } from 'react-router-dom';

function PrimaryPlan(props) {
  const { loginWithRedirect } = useAuth0();
  const { plan, discountFrom } = props;

  const selectPlan = async () => {
    stats.push(['trackEvent', 'Landing - Pricing Page', 'Primary pricing plan selected']);
    await loginWithRedirect();
  };

  const impressions = plan.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return (
    <div className="column is-3-desktop is-12-tablet p0 mt30 mx-2" id="primaryPlan">
      <div
        className="box has-border-bold-pastel boxshadow is-relative has-text-centered has-text-black is-relative pt-0"
      >
        <p className="has-text-weight-bold has-text-centered has-text-white br22 has-background-pastel" id="primaryPlanLabel">
          Am Beliebtesten
        </p>
        <div className=" has-border-bottom has-text-black has-text-weight-bold">
          <h3 className="mb10 is-size-4">{plan.name}</h3>
        </div>
        {
          discountFrom && (
            <div>
              <p className="has-text-crossed has-text-grey mt-5 is-size-4">
                {`${discountFrom} €`}
              </p>
            </div>
          )
        }
        <p className="has-text-weight-bold is-size-1-desktop is-size-2-tablet is-size-4-mobile ">
          {`${plan.cost} €`}
        </p>
        <p className="has-text-weight-light is-size-6">pro Monat</p>
        <p className="has-text-grey is-size-7">
          {`(${plan.costWithVAT}€ inkl. USt.)`}
        </p>
        <div className="columns is-centered is-marginless mt-5">
          <div className="column has-text-weight-semibold is-paddingless">
            <div className="is-flex has-content-centered mt-2">
              <p className="is-size-6 mr-1">{plan.maxAmountHubs}</p>
              <Link to={HUB_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-1">
                {plan.maxAmountHubs === 1 ? 'Hub' : 'Hubs'}
              </Link>
            </div>
            <div>
              <p className="has-text-weight-bold is-size-6 is-inline">
                {`${impressions} Flowter-Impressionen`}
              </p>
              <Popup
                trigger={(
                  <div className="cleanButton is-inline ml5">
                    <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                  </div>
                  )}
                position="top center"
                on={['hover']}
                keepTooltipInside="#root"
                repositionOnResize
                className="mt10"
              >
                {
                    () => (
                      <div className="columns is-marginless">
                        <div className="has-text-weight-light column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-midnightblue">
                          <p className="is-size-7">Impressionen bezeichnen die Anzahl, wie oft deine Flowter auf deiner Website geladen und angezeigt werden.</p>
                        </div>
                      </div>
                    )
                  }
              </Popup>
            </div>
            <p className="has-text-weight-bold is-size-6 mb10">
              pro Monat
            </p>
            <i className="has-text-pastel fas fa-infinity" />
            <div className="mt-2">
              <p className="is-size-6">Unbegrenzte Anzahl an </p>
              <Link to={FLOWTER_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-1">
                Flowtern
              </Link>
            </div>

            <p className="is-size-6 mt-2">Unbegrenzte Anzahl an Abstimmungen</p>
            <p className="is-size-6 has-text-weight-light mt30 mb10">Jederzeit kündbar</p>
            <p className="is-size-6  has-text-weight-light">Keine Mindestlaufzeit</p>
            <div className="has-text-centered mt30 mb40">
              <button
                className="button has-text-white has-background-pastel has-text-weight-bold br10 py-3 has-fullheight grow"
                type="button"
                onClick={() => selectPlan()}
              >
                Jetzt kaufen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PrimaryPlan.propTypes = {
  plan: PropTypes.shape({
    views: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    costWithVAT: PropTypes.string.isRequired,
    plan: PropTypes.number.isRequired,
    maxAmountHubs: PropTypes.number.isRequired,
  }).isRequired,
};

export default PrimaryPlan;
