import React, { useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import InfoPopup from 'components/utils/InfoPopup';
import AnalyticsStatistic from './AnalyticsStatistic';
import { Link } from 'react-router-dom';

// COnstants
import { CHECKOUT } from 'constants/routes';

const InfoBox = () => {
  const { amountSubmissions, impressions } = useSelector((state) => state.hubs.statistic);
  const { maxAmountHubs } = useSelector((state) => state.sessionState.user.planDetails);

  // TODO: change if multilangual
  const renderedImpressions = useMemo(() => new Intl.NumberFormat('de-DE').format(impressions), [impressions]);

  const renderedSubmissions = useMemo(() => new Intl.NumberFormat('de-DE').format(amountSubmissions), [amountSubmissions]);

  return (
    <div className="box has-text-left has-text-black">
      <p className="has-text-weight-bold has-text-black is-size-4-desktop is-size-5-touch">
        Info
      </p>

      <div className="my-4 has-border-bottom pb-5">

        <div>
          <i className="fas fa-eye mr-2" />
          <p
            className="is-inline"
          >
            { `${renderedImpressions} Impressionen`}
          </p>
        </div>

        <div className="mt-3">
          <i className="fas fa-user-check" />
          <p
            className="is-inline ml-2 mr-1"
          >
            { `${renderedSubmissions} Abstimmungen`}
          </p>
          <InfoPopup text="Anzahl der Nutzer, die mindestens eine Frage beantwortet haben" />
        </div>

        {
      maxAmountHubs === 1
      && (
        <div className="mt-3 is-size-7">
          <p className="is-inline">
            { `Du hast ${renderedSubmissions} / 10 Abstimmungen. Für unendlich viele Abstimmungen`}
          </p>
          <Link
            to={CHECKOUT}
            className="is-inline mx-1 has-text-pastel has-text-weight-bold"
          >
            starte einen Premium Plan!
          </Link>
        </div>
      )
    }

      </div>

      <div className="mt-4">
        {
          amountSubmissions > 0
          && <AnalyticsStatistic />
        }
      </div>

    </div>
  );
};

export default InfoBox;
