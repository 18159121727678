const templates = [{
		questions: [{
				question: "Wie gefällt dir die Idee?",
				options: ["Sehr gut", "Gut", "In Ordnung", "Eher nicht", "Gar nicht"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Würdest Du das Konzept gerne testen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du die Umsetzung gelungen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

		],
		endTxt: "Danke für's Abstimmen!",
		name: "Idee + Umsetzung",
	},

	{
		questions: [{
				question: "Was können wir verbessern?",
				options: ["Support", "Design", "Konzept", "Umsetzung"],
				maxNumberOfChecks: 4,
			},

			{
				question: "Fehlt dir etwas auf unserer Website?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Weiterentwicklung",
	},

	{
		questions: [{
				question: "Gefiel dir das Design vor dem Update besser?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wie findest Du unsere neuen Features?",
				options: ["Super", "Gut", "Unnötig", "Schlecht", "Ausreichend"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du dich nun besser auf der Website zurecht?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Update",
	},




] // Ende Template Array

export default templates;