const templates = [{
		questions: [{
				question: "Würdest Du selbst gerne einem kompetitiven Team beitreten?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wie oft schaust Du Livestreams auf Twitch?",
				options: ["Täglich", "Gelegentlich", "Selten", "Nie"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Welche Austauschplattform bevorzugst du?",
				options: ["Discord", "Facebook", "Teamspeak", "Andere"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Welchen Content wünschst Du dir von uns?",
				options: ["Training Streams", "Tipps & Tricks", "Community Streams", "News"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Hilfe!",
		name: "E-Sports",
	},
	{
		questions: [{
				question: "Für wen tätigst Du bei uns deinen Einkauf?",
				options: ["Für mich selbst", "Für Freunde oder Familie", "Geschäftlich", "Geschenk"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wie oft benutzt Du unsere Produkte?",
				options: ["Täglich", "Gelegentlich", "Selten", "Nie"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du die Artikelbeschreibung verständlich?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du die Produktvorschau ansprechend?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Hilfe!",
		name: "Online-Shops",
	},
	{
		questions: [{
				question: "Findest Du die Auswahl an Größen ausreichend?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Würdest Du dir andere Farben wünschen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Was beeinflusst deine Kaufentscheidung?",
				options: ["Preis", "Kundenbewertungen", "Auswahl", "Qualität", "Zahlungsmöglichkeiten"],
				maxNumberOfChecks: 5,
			},
			{
				question: "Wie würdest Du die gesamte Einkaufserfahrung in unserem Shop bewerten?",
				options: ["Sehr gut", "Gut", "In Ordnung", "Schlecht", "Sehr schlecht"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Hilfe!",
		name: "Kleidung",
	},

] // Ende Template Array

export default templates;