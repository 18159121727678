import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as routes from 'constants/routes';
import './css/navigation.scss';
import { useAuth0 } from '@auth0/auth0-react';

// TODO: Wenn man auf zurueck klickt, kommt man irgendwann wieder zu der Start-Einlogg-Seite

function Navigation() {
  const { isAuthenticated } = useAuth0();
  const { hasData, user } = useSelector((state) => state.sessionState);
  const location = useLocation();

  const hideNavbar = useMemo(() => {
    let path = location.pathname;

    if (path !== '/') {
      // remove trailing slash if there is one
      path = path.replace(/\/$/, '');
    }

    const {
      NEW_USER, LANDING, FLOWTER_TEMPLATES, PARTNER, ABOUT_US, PRIVACY_POLICY, TERMS_OF_SERVICE, IMPRESSUM, HUB_CONTENT, HUB_PROFILE, HUB_STATISTIC,
    } = routes;

    const mustNotContain = ['creator', '/ref/', '/login', '/affiliate', '/widget/', HUB_CONTENT, HUB_PROFILE, HUB_STATISTIC];

    for (let i = 0; i < mustNotContain.length; i++) {
      if (path.includes(mustNotContain[i])) {
        return true;
      }
    }

    const mustNotBe = [NEW_USER, LANDING, FLOWTER_TEMPLATES, PARTNER, ABOUT_US, PRIVACY_POLICY, TERMS_OF_SERVICE, IMPRESSUM];

    if (mustNotBe.includes(path)) return true;

    return false;
  }, [location.pathname]);

  if (!isAuthenticated || !hasData || hideNavbar) return null;

  const showUserStatus = () => {
    const planName = user.planDetails.name;

    return (
      <div className="columns mt10 mb20 is-hidden-mobile">
        <div className="column has-text-centered">
          <p className="has-text-white has-text-weight-normal">
            Plan:
          </p>
          <p className="has-text-white has-text-weight-bold is-rounded">
            {planName}
          </p>
          <i className="fas fa-award has-text-white is-size-2 mt20 is-block" />
          <Link
            className="button br10 is-white mt20"
            to={routes.BILLING}
          >
            Upgrade
          </Link>
        </div>
      </div>
    );
  };

  /*
  <li>
    <Link
      className="is-size-7 has-text-centered  has-text-white"
      to={routes.SETTINGS}
    >
      Einstellungen
    </Link>
 </li>
  */
  return (
    <div className="column is-2-desktop navigationWrapper is-3-tablet box mb0">
      <aside className="menu mt20">
        <ul className="menu-list">
          <li>
            <Link className="is-size-6 has-text-white has-text-weight-bold" to={routes.FLOWTER_LIST}>
              <i className="fas fa-plus-circle mr10" />
              Flowter
              <p
                className="is-size-7 has-text-grey-light has-text-weight-semibold mt-1"
              >
                Mini-Umfragen direkt auf der Website
              </p>
            </Link>
          </li>
          <li>
            <Link className="is-size-6 has-text-white has-text-weight-bold" to={routes.HUB_LIST}>
              <i className="fas fa-calendar-plus mr10" />
              Hubs
              <p
                className="is-size-7 has-text-grey-light has-text-weight-semibold mt-1"
              >
                Vorkonfigurierte Seite für Formulare und Umfragen
              </p>
            </Link>
          </li>
        </ul>
        <div className="lineh my-5" />
        <ul className="menu-list">
          <li>
            <Link
              className="is-size-6 has-text-white"
              to={routes.SCRIPT_INSERT_CONTAINER}
            >
              <i className="fas fa-code mr10" />
              Flowter Installation
            </Link>
          </li>
        </ul>
        <ul className="menu-list">
          <li>
            <Link className="is-size-6 has-text-white" to={routes.BILLING}>
              <i className="fas fa-credit-card mr10" />
              Abonnement
            </Link>
          </li>
        </ul>
        { showUserStatus() }
        <div className="lineh mt10 mb10" />
        <ul className="menu-list">
          <li>
            <Link className="has-text-white has-text-centered" to={routes.PRIVACY_SETTINGS}>
              <p className=" is-size-7">Datenschutz</p>
            </Link>
          </li>
          <li>
            <Link className="has-text-centered has-text-white" to={routes.SUPPORT}>
              <p className="is-size-7">Support</p>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  );
}

/*
{
  props.plan === 1
  ?
  <i className="fas fa-dice-one is-size-2 mb10 has-text-white"></i>
  :
  props.plan === 2
  ?
  <i className="fas fa-dice-two is-size-2 mb10 has-text-white"></i>
  :
  <i className="fas fa-dice-three is-size-2 mb10 has-text-white"></i>
}

{
 props.plan !== 3
 ?
 <Link className="button is-rounded is-white mt20" to={routes.BILLING}>{"Upgrade"}</Link>
 :
 null
}
*/

export default Navigation;
