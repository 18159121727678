import React, { useState, useMemo, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';
import { getColor } from 'components/hub/HubStatistic/Statistic/palette';

// Components
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import {
  VictoryPie, VictoryChart, VictoryBar, VictoryAxis,
} from 'victory';

// Styles
import styles from './styles.module.scss';

const Chart = (props) => {
  const { statistic } = props;

  const [chartType, setChartType] = useState(0);
  const [fixedIndex, setFixedIndex] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(-1);

  const [mutationFromStatClick, setMutationFromStatClick] = useState([]);

  useEffect(() => {
    setFixedIndex(-1);
    setActiveIndex(-1);
  }, [chartType]);

  const setChartRadiant = () => {
    stats.push(['trackEvent', 'Hub Statistic', 'Chart Radiant selected']);

    setChartType(0);
  };

  const setChartBar = () => {
    stats.push(['trackEvent', 'Hub Statistic', 'Chart Bar selected']);

    setChartType(1);
  };

  const onMouseOver = () => ([
    {
      eventKey: 'all',
      target: 'data',
      mutation: () => ({}),
    },
    {
      target: 'data',
      mutation: ({ datum }) => {
        const statIndex = datum.index;
        const statValue = datum.value;

        // save index of the active value
        setActiveIndex(statIndex);

        // get color from its value
        return ({ style: { fillOpacity: 0.6, fill: getColor(statValue) } });
      },

    },
  ]);

  const onMouseOut = () => ([
    // color fixed value again
    {
      eventKey: fixedIndex,
      target: 'data',
      mutation: () => ({ style: { fillOpacity: 0.6, fill: getColor(fixedIndex) } }),
    },
    {
      target: 'data',
      mutation: ({ datum }) => {
        const statIndex = datum.index;
        const statValue = datum.value;

        // save index of the active value
        setActiveIndex(statIndex);

        if (fixedIndex === statIndex) {
          // get color from its value
          return ({ style: { fillOpacity: 0.6, fill: getColor(statValue) } });
        }

        setActiveIndex(fixedIndex);
        return {};
      },
    },
  ]);

  const onClick = () => ([
    {
      target: 'data',
      mutation: ({ datum }) => {
        const statIndex = datum.index;
        const statValue = datum.value;

        if (statIndex === fixedIndex) {
          setFixedIndex(-1);
        } else {
          setFixedIndex(statIndex);
          setActiveIndex(statIndex);
        }

        return ({ style: { fillOpacity: 0.6, fill: getColor(statValue) } });
      },
    }]);

  const resetMutationFromClick = () => {
    setMutationFromStatClick([]);
  };

  const setFixed = (statIndex, statValue) => {
    // if the active value is clicked again, reset everything
    if (fixedIndex === statIndex) {
      setFixedIndex(-1);
      setActiveIndex(-1);
      setMutationFromStatClick([
        {
          eventKey: 'all',
          target: 'data',
          mutation: () => ({ style: undefined }),
          callback: resetMutationFromClick,
        },
      ]);
      return;
    }

    setFixedIndex(statIndex);
    setActiveIndex(statIndex);

    setMutationFromStatClick([
      {
        eventKey: 'all',
        target: 'data',
        mutation: ({ datum }) => {
          const _statIndex = datum.index;

          if (_statIndex !== statIndex) return { style: undefined };

          return ({ style: { fillOpacity: 0.6, fill: getColor(statValue) } });
        },
        callback: resetMutationFromClick,
      },
    ]);
  };

  const renderedActiveIndex = useMemo(() => {
    if (activeIndex === -1 || statistic.length < 2) return null;

    const value = statistic[activeIndex].y;
    const statName = statistic[activeIndex].x;

    let totalAmount = 0;

    statistic.forEach((stat) => {
      totalAmount += stat.y;
    });

    const percent = ((value / totalAmount) * 100).toFixed(2);

    return (
      <div
        id={(chartType === 0 ? styles.tooltipPie : styles.tooltip)}
        className={chartType === 0 ? 'has-fullheight' : ''}
      >
        {
          chartType === 1
          && (
          <p className="has-text-black">
            {`${statName}:`}
          </p>
          )
        }
        <p className="has-text-black">
          {`${value} (${percent}%)`}
        </p>
      </div>
    );
  }, [activeIndex]);

  const showGraph = () => {
    if (statistic.length === 1) {
      return null;
    }

    if (chartType === 0) {
      return (
        <div className="has-max-width-300 is-center is-relative">
          { renderedActiveIndex }
          <VictoryPie
            innerRadius={90}
            padAngle={2}
            data={statistic}
            externalEventMutations={mutationFromStatClick}
            labels={() => null}
            style={{
              pointerEvents: 'auto',
              userSelect: 'auto',
              touchAction: 'auto',
              data: {
                fill: ({ datum }) => getColor(datum.value),
              },
            }}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver,
                onMouseOut,
                onClick,
              },
            }]}
          />
        </div>
      );
    }

    return (
      <div className="has-max-width-600 is-center is-relative">
        { renderedActiveIndex }
        <VictoryChart
          domainPadding={30}
          style={{
            pointerEvents: 'auto',
            userSelect: 'auto',
            touchAction: 'auto',
          }}
        >
          <VictoryBar
            data={statistic}
            barWidth={45}
            externalEventMutations={mutationFromStatClick}
            style={{
              data: {
                fill: ({ datum }) => getColor(datum.value),
              },
            }}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver,
                onMouseOut,
                onClick,
              },
            }]}
          />
          <VictoryAxis tickFormat={() => ''} />
          <VictoryAxis
            dependentAxis
          />
        </VictoryChart>
      </div>
    );
  };

  const showStatistic = () => statistic.map((stat, statIndex) => {
    const color = getColor(stat.value);
    const name = stat.x;
    const amount = stat.y;

    return (
      <div key={statIndex} className="has-text-left">
        <button
          type="button"
          onClick={() => setFixed(statIndex, stat.value)}
          className="p-3 button has-fullheight"
        >
          <div className="columns is-mobile is-tablet is-centered">
            <div className="column is-narrow has-text-right">
              <i className="fas fa-square is-size-4" style={{ color }} />
            </div>
            <div className="column has-text-left customParagraph">
              <p
                className={`is-inline has-text-black break-word ${activeIndex === statIndex ? 'has-text-weight-bold' : ''}`}
              >
                {`${name}: `}
              </p>
              <p
                className="is-inline has-text-weight-bold ml-1"
                style={{ color }}
              >
                {amount}
              </p>
            </div>
          </div>
        </button>
      </div>
    );
  });

  // only show a statistic if we have more than one data point
  if (statistic.length < 2) return null;

  return (
    <div>
      <div className="columns is-marginless mt-2">
        <div className="column has-text-right">
          <button
            className="button mx-1"
            onClick={setChartRadiant}
            type="button"
          >
            { chartType === 0
              ? (
                <div className="has-text-pastel has-border-pastel-light br10 p10">
                  <i className="fas fa-chart-pie" />
                </div>
              )
              : (
                <div className="has-text-grey br10 p10 has-border">
                  <i className="fas fa-chart-pie" />
                </div>
              )}
          </button>
          <button
            className="button mx-1"
            onClick={setChartBar}
            type="button"
          >
            { chartType === 0
              ? (
                <div className="has-text-grey br10 p10 has-border">
                  <i className="fas fa-chart-bar" />
                </div>
              )
              : (
                <div className="has-text-pastel has-border-pastel-light br10 p10">
                  <i className="fas fa-chart-bar" />
                </div>
              )}
          </button>
        </div>
      </div>
      <SwitchTransition>
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={chartType}
        >
          { showGraph() }
        </CSSTransition>
      </SwitchTransition>
      { showStatistic() }
    </div>
  );
};

Chart.propTypes = {
  statistic: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.number,
    index: PropTypes.number,
    value: PropTypes.number,
  })).isRequired,
};

export default Chart;
