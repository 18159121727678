import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Slider from 'rc-slider';

// Styles
import styles from './styles.module.scss';

function SliderDisplay(props) {
  const { value } = props;

  const getColor = (_value) => {
    // value from 0 to 1
    const hue = (((_value / 100)) * 120).toString(10);
    return ['hsl(', hue, ',100%,70%)'].join('');
  };

  return (
    <div className="has-max-width-300 is-in-center">
      <Slider
        className="my-5"
        value={value}
        trackStyle={[{ height: '15px', backgroundColor: getColor(value) }]}
        railStyle={{ height: '15px' }}
        handle={(handleProps) => (
          <div {...handleProps} dragging={undefined} className={styles.handle} />
        )}
      />
    </div>
  );
}

SliderDisplay.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SliderDisplay;
