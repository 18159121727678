import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import newtag from 'assets/images/gtm1.png';
import configtag from 'assets/images/gtm2.png';
import site from 'assets/images/gtm3.png';
import trigger from 'assets/images/gtm4.png';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';
import Image from './Image';

const ScriptGoogleTagManager = () => (
  <>
    <div className="has-text-centered mt30">
      <i className="fas fa-tags has-text-danger is-size-3" />
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-weight-bold">Installation für den Google Tag Manager</p>
    <div className="mt40">
      <p className="mt30  is-size-5 has-text-weight-bold">So fügst Du Flowdust dem Google Tag Manager hinzu:</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Logge dich in den Google Tag Manager ein</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Gehe links auf Tags und wähle "Neu"</p>
      <div className="columns">
        <Image src={newtag} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf Tag Konfigurationen</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Wähle "Benutzerdefiniertes HTML"</p>
      <div className="columns">
        <Image src={configtag} />
      </div>
    </div>
    <div className="mt20 mb30">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'5. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere diesen HTML-Code und füge ihn in das "HTML"-Eingabefeld ein:</p>
    </div>
    <ScriptTagCopy />
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'6. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Erweiterte Einstellungen"</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'7. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Wähle bei Optionen für Tag-Auslösung "Einmal pro Seite" aus</p>
      <div className="columns">
        <Image src={site} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'8. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Trigger"</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'9. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "All Pages"</p>
      <div className="columns">
        <Image src={trigger} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'10. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">
        Klicke oben rechts auf "Speichern"
      </p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'11. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">
        Klicke oben rechts auf "Senden"
      </p>
    </div>
    <div className="mt-2 mb-5">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'12. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">
        Sieh nach, ob unten links bei "In Umgebung veröffentlichen" "Live" ausgewählt ist und klicke danach auf "Veröffentlichen"
      </p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);
export default ScriptGoogleTagManager;
