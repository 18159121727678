import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

const ValueSelector = (props) => {
  const {
    isTextfield, onlyNumbers, values, value, setValue,
  } = props;

  const _setValue = (e) => {
    const { value: _value } = e;

    // dont set value if only numbers is allowed and input is not a value currently
    // (but skip empty inputs, they are allowed)
    if (_value.length !== 0 && onlyNumbers) {
      if (!/^\d+$/.test(_value)) return;
    }

    setValue(_value);
  };

  // how each question should be rendered
  const renderLabel = ({ label }) => (
    <div>
      <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-info" />
      <p className="is-inline has-text-weight-semibold has-text-black is-size-7 break-word">
        {label}
      </p>
    </div>
  );

  const selectedValue = useMemo(() => {
    if (isTextfield) return value;

    const item = values.find((label) => label.value === value);

    return item;
  }, [value, isTextfield, values]);

  return (
    <div className="">

      {
        isTextfield
          ? (
            <input
              className="input"
              type="text"
              value={selectedValue}
              placeholder="Wert"
              onChange={(e) => _setValue(e.target)}
            />
          )
          : (
            <Dropdown
              value={selectedValue}
              renderLabel={renderLabel}
              labels={values}
              onChange={_setValue}
              noOptionsMessage={() => 'Das waren alle!'}
            />
          )
      }
    </div>
  );
};

ValueSelector.propTypes = {
  isTextfield: PropTypes.bool.isRequired,
  onlyNumbers: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ValueSelector;
