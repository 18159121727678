import React, { useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as routes from 'constants/routes';
import * as status from 'constants/status';
import { useSelector } from 'react-redux';

import { withAuthenticationRequired } from '@auth0/auth0-react';

/**
 * This HOC checks if the user is authenticated and has data.
 * If he is not, the user will be redirected to the auth0 sign in
 * Then, load data from the db
 */
const withAuthentication = (Component, settings) => {
  function WithAuthentication(props) {
    // const { isAuthenticated } = useAuth0();

    const { hasData: hasUserData, user } = useSelector((state) => state.sessionState);
    const { flowters } = useSelector((state) => state.flowterState);
    const { statusWidgets } = useSelector((state) => state.statusWidgetState);

    const navigate = useNavigate();
    const location = useLocation();

    const hasData = hasUserData && flowters && statusWidgets;

    useLayoutEffect(() => {
      /* if (!isAuthenticated) {
        return navigate(routes.LANDING);
      } */

      if (!hasData) {
        // Login is the route where we load the user from our db
        return navigate(routes.LOGIN);
      }

      const isNewUser = user.status === status.NEW_USER;
      const isNotInOnboarding = location.pathname !== routes.NEW_USER;

      if (isNewUser && isNotInOnboarding) {
        navigate(routes.NEW_USER);
      }
    }, [location]);

    return user ? <Component {...props} /> : null;
  }

  return withAuthenticationRequired(WithAuthentication, { ...settings });
};

export default withAuthentication;
