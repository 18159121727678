import React from 'react';

// Components
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FLOWTER_DEMO, HUB_EDITOR_SELECTOR } from 'constants/routes';

// HOCs
import withoutAuthentication from 'components/hoc/withoutAuthentication';

// Assets
import hubicon from 'assets/images/hubicon2.png';
import flowtericon from 'assets/images/flowtericon.png';

const Demo = () => (
  <>
    <Helmet>
      <title>Flowdust Editor</title>
    </Helmet>
    <div className="columns is-centered is-vcentered is-fullheight">
      <div className="column is-8-desktop is-10-tablet">
        <div className="mt20 mb40">
          <div className="columns mt0 mb0 ml10 mr10 is-mobile">
            <div className="column p0">
              <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile my-5 has-text-centered-mobile">
                Was möchtest Du erstellen?
              </h2>
            </div>
          </div>

          <div className="columns m0 is-mobile is-centered-mobile">

            <div className="column is-3-desktop is-4-tablet is-6-mobile creatorButton m10 p0">
              <Link
                className="button columns is-mobile has-no-border p-5 br22 has-fullheight has-fullwidth is-marginless is-paddingless has-hover has-background-white"
                to={FLOWTER_DEMO}
              >
                <div className="column has-text-centered has-fullheight">
                  <div className="has-height-two-third creatorButtonImageWrapper">
                    <img className="" src={flowtericon} alt="" />
                  </div>

                  <div className="has-height-one-third">
                    <p className="has-text-black is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-bold">
                      Flowter
                    </p>
                    <p className="is-size-6 mt10 has-text-grey mb20">
                      Eine Mini-Umfrage, die direkt auf deiner Website platziert wird.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="column is-3-desktop is-4-tablet is-6-mobile creatorButton m10 p0">
              <Link
                className="button columns has-no-border p-5 br22 has-fullheight has-fullwidth is-marginless is-paddingless has-hover has-background-white"
                to={HUB_EDITOR_SELECTOR}
              >
                <div className="column has-text-centered has-fullheight">
                  <div className="has-height-two-third creatorButtonImageWrapper">
                    <img className="" src={hubicon} alt="" />
                  </div>
                  <div className="has-height-one-third">
                    <p className="has-text-black is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-bold">
                      Hub
                    </p>
                    <p className="is-size-6 mt10 has-text-grey mb20">
                      Eine größere Umfrage oder ein Formular - auf einer zentralen Seite.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </>
);

export default withoutAuthentication()(Demo);
