import React, { useRef } from 'react';

// Components
import Frame from 'components/widgets/creator/widget/Frame';

// Libraries
import PropTypes from 'prop-types';

// Styles
/* eslint import/no-webpack-loader-syntax: off */
import innerStyles from '!!raw-loader!./styles/innerStyles.scss';
import styles from '!!raw-loader!./styles/styles.scss';

/**
 * Flowter Button Component - controlled by a widget
 *
 * @type {Function}
 * @param  props
 * @prop {Number} pos - 0 = left, 1 = right
 * @prop {Number} isActive - true if widget is open, false if its not
 * @prop {String} color - background color of the button
 * @prop {func} click - the function to call after button click
 *
 * @return {JSX} widget button
 */
const Button = (props) => {
  const buttonRef = useRef();

  const {
    pos, click, isActive, color,
  } = props;

  const buttonPosition = pos ? 'flowdustButtonRight' : 'flowdustButtonLeft';

  const head = `<style type="text/css">${innerStyles}</style>`;

  return (
    <>
      <style>
        { styles }
      </style>
      <div
        id="flowdustButton"
        className={buttonPosition}
        ref={buttonRef}
      >
        <Frame head={head} frameContainerRef={buttonRef}>
          <button
            id="button"
            className={isActive ? 'open' : ''}
            onClick={click}
            type="button"
            style={{ backgroundColor: color }}
          >
            <div id="icon" />
          </button>
        </Frame>
      </div>
    </>
  );
};

Button.propTypes = {
  /**
   * Position of the button - 0 = left, 1 = right
   */
  pos: PropTypes.number.isRequired,
  /**
   * Hex code color of the button background
   */
  color: PropTypes.string.isRequired,
  /**
   * Function to call after button has been clicked
   */
  click: PropTypes.func.isRequired,
  /**
   * Flag to see if the widget that controls the button is visible
   */
  isActive: PropTypes.bool.isRequired,
};

export default Button;
