import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';
import CopyUrlButton from './CopyUrlButton';
import { Link } from 'react-router-dom';

// Constants
import { HUB_CONTENT } from 'constants/routes';
import ToggleStatusButton from './ToggleStatusButton';

const ShareOptions = (props) => {
  const {
    url, id, index, status,
  } = props;

  return (
    <Popup
      trigger={(
        <button
          className="cleanButton has-text-black is-inline-block br10 has-line-height-full is-size-5 growBig"
          type="button"
        >
          <i className="fas fa-cog" />
        </button>
      )}
      position="top center"
      on="click"
      keepTooltipInside="#root"
      repositionOnResize
    >
      { () => (
        <div className="box has-text-centered">

          <Link
            to={`${HUB_CONTENT}/${index}`}
            type="button"
            className="cleanButton has-hover is-flex align-items-center py-2 px-3 br10 mx-0 is-size-5 has-text-black has-fullwidth"
          >
            <i className="mr-3 is-inline fas fa-pen" />
            <p className="is-size-7 is-inline-block has-text-black has-text-weight-semibold">Bearbeiten</p>
          </Link>

          <CopyUrlButton url={url} />

          <div>
            <ToggleStatusButton id={id} status={status} />
          </div>

        </div>
      )}
    </Popup>
  );
};

ShareOptions.propTypes = {
  url: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ShareOptions;
