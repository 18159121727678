import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

// constants
import {
  FLOWTER_MAX_QUESTIONS, FLOWTER_MAX_OPTIONS,
} from 'constants/flowter';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// Components
import InfoPopup from 'components/utils/InfoPopup';

// reducer
import {
  // options
  setOption,
  addOption,
  deleteOption,
  // questions
  setQuestion,
  deleteQuestion,
  // checks
  setCheck,
  clearChecks,
  setMaxNumberOfChecks,
  // settings,
  setAllowText,
} from 'reducer/flowterCreator';

// styles
import './css/question.scss';

const MAX_OPTION_LENGTH = 140;
const MAX_QUESTION_LENGTH = 140;

/**
 * Question Component for the Flowter creator.
 *
 * If there is a isPreview prop, it will
 * show the question just like the PreviewQuestion Component,
 * but it is linked to the checks state, so the checks can get resetted
 * by the Question Component without the isPreview-prop.
 * One Question component is always linked to a Question Component
 * with an isPreview-prop
 */
function Question(props) {
  const { questions } = useSelector((state) => state.flowterCreator);

  const { id, isPreview } = props;

  const questionObj = questions[id];
  const {
    options, question, checks, maxNumberOfChecks, allowText,
  } = questionObj;

  const dispatch = useDispatch();

  const [focusIndex, setFocusIndex] = useState(-1);

  const [error, setError] = useState('');

  const toggleAllowText = () => {
    dispatch(setAllowText({ id, type: !allowText }));
  };

  const addNewOption = (e) => {
    e.preventDefault();

    if (options.length >= 5) {
      stats.push(['trackEvent', 'Flowter Creator', 'Too many options added']);

      setError('Da wir die Flowter so kurz wie möglich halten wollen, kannst Du leider keine weiteren Auswahlmöglichkeiten hinzufügen.');

      return;
    }

    stats.push(['trackEvent', 'Flowter Creator', 'Option added']);

    setError('');

    // If all options got deleted and we add one again
    if (options.length === 0) {
      // maxNumberOfChecks is now 0, add one back
      dispatch(setMaxNumberOfChecks({ id, amount: 1 }));
    }

    dispatch(addOption(id));
  };

  const setOptionText = (index, text) => {
    // TODO: various byte length for char fix?
    if (text.length > MAX_OPTION_LENGTH) {
      stats.push(['trackEvent', 'Flowter Creator', 'Option text max length reached']);

      setError('Dein Text ist leider zu lang. Wie wäre es, wenn Du deine Auswahlmöglichkeit etwas kürzer gestaltest?');
      return;
    }
    setError('');

    dispatch(setOption({
      id,
      option: index,
      text,
    }));
  };

  /**
   * Tests if checking is allowed and checks the option if it is
   */
  const checkOption = (index) => {
    if (checks[index] === 0) {
      // see if check is allowed
      let amount = 0;
      for (let i = 0; i < checks.length; i++) {
        amount += checks[i];
      }

      if (amount < maxNumberOfChecks) {
        setCheck({
          id,
          option: index,
          type: 1,
        });
      }
      return;
    }

    setCheck({
      id,
      option: index,
      type: 0,
    });
  };

  /**
   * Removes an option
   */
  const removeOption = (e, optionIndex) => {
    e.preventDefault();

    // if after deleting one option, maxNumberOfChecks would be greater than the amount of options, reset maxNumberOfChecks
    if (maxNumberOfChecks > options.length - 1) {
      dispatch(setMaxNumberOfChecks({ id, amount: 1 }));
    }

    dispatch(deleteOption({ id, option: optionIndex }));

    setError('');

    stats.push(['trackEvent', 'Flowter Creator', 'Option removed']);
  };

  /*
   * In-/Decrement maxNumberOfChecks
   *
   * @param {object} event - The event
   * @param {number} type - The type to either in- or decrease, 0 is increase, 1 is decrease
   */
  const setMaxChecks = (event, type) => {
    event.preventDefault();

    const optionAmount = options.length;

    // MaxChecks++
    if (type === 0) {
      const maxAmount = allowText ? optionAmount + 1 : optionAmount;

      if (maxNumberOfChecks < maxAmount) {
        dispatch(setMaxNumberOfChecks({ id, amount: maxNumberOfChecks + 1 }));
      }
      // MaxChecks--;
    } else if (maxNumberOfChecks > 1) {
      dispatch(setMaxNumberOfChecks({ id, amount: maxNumberOfChecks - 1 }));

      /* Only clear the checks if the amount of checks at the moment
       * is bigger than the permitted amount of checks
       */
      let amount = 0;
      for (let i = 0; i < checks.length; i++) {
        amount += checks[i];
      }
      if (amount > maxNumberOfChecks || maxNumberOfChecks === 1) {
        dispatch(clearChecks());
      }
    }

    setError('');
  };

  const removeQuestion = (e) => {
    e.preventDefault();

    dispatch(deleteQuestion(id));
    stats.push(['trackEvent', 'Flowter Creator', 'Question removed']);
  };

  const setQuestionText = (text) => {
    if (text.length > MAX_QUESTION_LENGTH) {
      stats.push(['trackEvent', 'Flowter Creator', 'Question text max length reached']);

      setError('Dein Text ist leider zu lang. Wie wäre es, wenn Du deine Frage etwas kürzer gestaltest?');
      return;
    }

    setError('');

    dispatch(setQuestion({ id, text }));
  };

  const showPreviewOptions = () => {
    // TODO: improve.
    const name = Math.random();
    return options.map((option, index) => {
      const num = Math.random();

      const checkbox = (
        <div className="columns is-centered">
          <div className="column has-text-centered mb20">
            <input
              type="checkbox"
              name={name}
              id={num}
              checked={checks[index]}
              onChange={() => checkOption(index)}
              className="is-checkradio"
            />

            <label className="has-text-centered" htmlFor={num}>
              {option}
            </label>
          </div>
        </div>
      );

      const button = (
        <button
          name={name}
          id={num}
          arrayindex={index}
          className="is-checkradio customButton"
          type="button"
        >
          <p>{option}</p>
        </button>
      );

      return option.length > 0
      && (
        // eslint-disable-next-line
        <div key={num}
          className="mb30 previewButton"
        >
          {maxNumberOfChecks > 1 ? checkbox : button}
        </div>
      );
    });
  };

  const showQuestion = () => (
    <div className="columns mb30 mt10 ml3 mr3 is-mobile creatorInput pr10 pl10 questionContainer">
      <div className="column is-paddingless mr5">
        <input
          onChange={(event) => setQuestionText(event.target.value)}
          type="text"
          questionindex={id}
          value={question}
          placeholder="Deine Frage"
          onFocus={() => setFocusIndex(-1)}
          className="input has-text-black is-size-6 is-rounded is-paddingless is-inline has-no-border-bottom has-fullheight has-fullwidth"
        />
      </div>
      {
        focusIndex === -1
        && (
          <div className="column is-narrow is-paddingless has-text-right is-relative">
            <p className="has-text-grey-light letterLimitationDisplay dont-select-text is-inline mr10">
              {`${question.length} / ${MAX_OPTION_LENGTH}`}
            </p>
          </div>
        )
      }
    </div>
  );

  const showOptions = () => options.map((option, index) => (
    // eslint-disable-next-line
    <div key={index}
      className="mb20 mt10 ml10 mr10 columns is-mobile creatorInput pl10 pr10"
    >
      <div className="column is-paddingless mr5">
        <input
          type="text"
          name="option"
          placeholder="Option"
          value={option}
          optionindex={index}
          onChange={(event) => setOptionText(index, event.target.value)}
          onFocus={() => setFocusIndex(index)}
          className="input has-text-black is-size-6 is-rounded is-paddingless is-inline has-no-border-bottom has-fullheight has-fullwidth"
        />
      </div>
      <div className="column is-narrow is-paddingless has-text-right is-relative">
        {
          focusIndex === index
            && (
              <p className="has-text-grey-light letterLimitationDisplay dont-select-text is-inline mr10">
                {`${option.length} / ${MAX_OPTION_LENGTH}`}
              </p>
            )
        }
        <button
          className="button is-size-5 p0"
          onClick={(e) => removeOption(e, index)}
          type="button"
        >
          <i className="fas fa-times is-size-5 has-text-danger" />
        </button>
      </div>
    </div>
  ));

  const amountOptions = options.length;
  const isNotLastQuestion = questions.length > 1;

  if (isPreview) {
    return (
      <QuestionPreviewLayout
        loadPreviewOptions={showPreviewOptions}
        question={question}
      />
    );
  }

  return (
    <div className="questionWrapper has-border-bottom pb30">
      <div className="columns">
        <div className="column is-narrow">
          <p className="has-text-grey pl5 has-text-weight-semibold has-text-centered-mobile is-size-7">
            {`Frage ${id + 1} / ${FLOWTER_MAX_QUESTIONS}`}
          </p>
          {
            isNotLastQuestion
              && (
                <div className="has-text-left">
                  <button
                    className="cleanButton is-inline has-text-weight-bold has-text-red is-size-7 mt10 is-underlined"
                    onClick={(e) => removeQuestion(e)}
                    type="button"
                  >
                    Frage entfernen
                  </button>
                </div>
              )
            }
        </div>
        <div className="column">
          <p className="has-text-grey has-text-right-tablet has-text-left has-text-weight-semibold has-text-centered-mobile is-size-7">
            {
              `Auswahlmöglichkeiten ${amountOptions} / ${FLOWTER_MAX_OPTIONS}`
            }
          </p>
        </div>
      </div>
      { showQuestion() }
      <div className="optionWrapper">
        { showOptions() }
      </div>
      {
        amountOptions < FLOWTER_MAX_OPTIONS
        && (
        <div className=" mt40 mb40">
          <h2 className="my-5 has-text-weight-bold is-size-6  has-text-black">
            Füge Antwortmöglichkeiten hinzu
          </h2>
          <button
            className="mb-5  is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
            onClick={(event) => addNewOption(event)}
            type="button"
          >
            <i className="fa fa-circle has-text-pastel is-size-7 mr-2" />
            <p className="is-size-7 has-text-weight-bold is-inline">
              Optionen hinzufügen
            </p>
          </button>
        </div>
        )
      }
      {
           amountOptions >= 2
           && (
           <div className="columns is-gapless mt60 mb30">
             <div className="column customDiv has-info has-text-centered">
               <div className="has-text-grey">
                 <p className="has-text-centered is-inline is-size-7">
                   {'Auswählen bis zu '}
                 </p>
                 <div className="incrementBtnWrapper">
                   <button
                     className="incrementBtn"
                     onClick={(event) => setMaxChecks(event, 0)}
                     type="button"
                   >
                     <i className="fas fa-chevron-up" />
                   </button>
                   <p className="incrementValue">
                     {maxNumberOfChecks}
                   </p>
                   <button
                     className="incrementBtn"
                     onClick={(event) => setMaxChecks(event, 1)}
                     type="button"
                   >
                     <i className="fas fa-chevron-down" />
                   </button>
                 </div>
                 <p className="has-text-centered is-inline is-size-7">
                   {' Möglichkeit'}
                   { maxNumberOfChecks !== 1 && 'en'}
                 </p>
                 <div className="has-info checkInfoWrapper">
                   <Popup
                     trigger={(
                       <div className="cleanButton ml10 pr20">
                         <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                       </div>
                     )}
                     position="top center"
                     on={['hover']}
                     keepTooltipInside="#root"
                     repositionOnResize
                   >
                     {
                       () => (
                         <div className="columns is-marginless">
                           <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey has-background-white">
                             <p className="is-size-7">
                               Gib an, wie viele Antworten die Nutzer bei deiner Frage auswählen können.
                             </p>
                           </div>
                         </div>
                       )
                     }
                   </Popup>
                 </div>
               </div>
             </div>
           </div>
           )
      }
      <div>
        <input
          type="checkbox"
          id={`allow_text_checkbox_${id}`}
          checked={allowText}
          className="is-checkradio"
          onChange={toggleAllowText}
        />

        <label className="has-text-centered" htmlFor={`allow_text_checkbox_${id}`}>
          Textfeld hinzufügen
        </label>
        <InfoPopup
          text="Hier können Nutzer per Texteingabe ihre Meinung ausdrücken."
        />
      </div>
      {
        error
        && (
          <p className="has-text-danger">
            {error}
          </p>
        )
      }
    </div>
  );
}

Question.propTypes = {
  id: PropTypes.number.isRequired,
  isPreview: PropTypes.bool,
};

Question.defaultProps = {
  isPreview: false,
};

function QuestionPreviewLayout(props) {
  const { question, showOptions } = props;
  return (
    <div className="">
      <div className="questionCircleTop" />
      <div className="box questionPreviewWrapper">
        <h2 className="box pt10 pb10 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped  previewQuestion">
          {question}
        </h2>
        <div className="optionPreviewWrapper">
          { showOptions() }
        </div>
      </div>
      <div className="questionCircleBottom" />
      <div className="lineToQuestion" />
    </div>
  );
}

QuestionPreviewLayout.propTypes = {
  question: PropTypes.string.isRequired,
  showOptions: PropTypes.func.isRequired,
};

export default Question;
