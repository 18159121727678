import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Assets
import gif from 'assets/images/giphy.gif';

const NotFound = () => (
  <>
    <Helmet>
      <title>Flowdust - Seite nicht gefunden</title>
    </Helmet>

    <div className="has-text-centered has-text-weight-bold mt70">
      <h2 className="title">404</h2>
      <h2 className="title">Seite wurde nicht gefunden.</h2>
      <img
        src={gif}
        width="332"
        height="480"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
        alt=""
      />
      <p>
        <a href="https://giphy.com/gifs/89a-eightninea-VwoJkTfZAUBSU">
          via GIPHY
        </a>
      </p>
    </div>
  </>
);

export default NotFound;
