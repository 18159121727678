import React from 'react';
import Footer from './Footer';
import ecommerce from 'assets/images/ecommerce.png';
import startup from 'assets/images/startup.png';
import analytics from 'assets/images/analytics.png';

const UseCases = (props) => (
  <div>
    <section className="">
      <div className="columns is-centered">
        <div className="mt50 column box is-11 pt50 pb50">
          <p className="mb20 is-size-1 has-text-midnightblue has-text-centered has-text-weight-bold">{'Anwendungsfälle'}</p>
          <div className="columns is-mobile is-centered m0 p0">
            <div className="column p30 is-3-desktop is-4-tablet is-10-mobile has-text-centered mb30">
              <img src={ecommerce} alt="" />
              <p className="mt10 has-text-weight-bold has-text-midnightblue is-size-3-desktop is-size-4-tablet">{'E-Commerce'}</p>
              <p className="mt10 is-size-6 is-size-6-mobile has-text-black has-text-weight-light">{'Erhalte Feedback zu Produkten, Kundensupport und zum Einkaufserlebnis.'}</p>
              <p className="mt30 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Ist das Preis-Leistungs-Verhältnis angemessen?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Finden die Nutzer die Produktbeschreibungen verständlich?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Welche Zahlungsart bevorzugen sie?'}</p>
            </div>
            <div className="column p30 is-offset-1-desktop is-offset-1-tablet is-3-desktop is-4-tablet is-10-mobile has-text-centered mb30">
              <img src={startup} alt="" />
              <p className="mt10 has-text-weight-bold is-size-3-desktop is-size-4-tablet has-text-midnightblue br10">{'Start-Ups'}</p>
              <p className="mt10 is-size-6 is-size-6-mobile has-text-black has-text-weight-light">{'Erfahre stetig, wie gut deine Idee und Umsetzung ankommt.'}</p>
              <p className="mt30 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Wie gefallen den Nutzern die neuen Funktionen?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Finden sie das Design ansprechend?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Gefällt ihnen das Konzept der Website?'}</p>
            </div>
            <div className="column p30 is-offset-1-desktop is-3-desktop is-4-tablet is-10-mobile has-text-centered mb30">
              <img src={analytics} alt="" />
              <p className="mt10 has-text-weight-bold has-text-midnightblue is-size-3-desktop is-size-4-tablet">{'Marketing'}</p>
              <p className="mt10 is-size-6 is-size-6-mobile has-text-black has-text-weight-light">{'Stelle genau die Fragen, die aus Analytics nicht herauslesbar sind.'}</p>
              <p className="mt30 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Sind die Nutzer mit dem Kundensupport zufrieden?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Wie haben sie von der Website erfahren?'}</p>
              <p className="mt10 has-text-justified is-size-6 is-size-6-mobile has-text-black has-text-weight-semibold">{'• Finden sie sich auf der Website gut zurecht?'}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default UseCases;
