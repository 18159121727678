const getHubUrl = (hubUrl) => {
  if (process.env.REACT_APP_ENV === 'production') {
    // on production server, the hub url is on the subdomain
    return `https://${hubUrl}.${process.env.REACT_APP_HUB}`;
  }

  if (process.env.REACT_APP_ENV === 'development') {
    // dev mode has no https
    return `http://${process.env.REACT_APP_HUB}/${hubUrl}`;
  }

  // otherwise use the https version
  return `https://${process.env.REACT_APP_HUB}/${hubUrl}`;
};

export default getHubUrl;
