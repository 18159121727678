import React, { useLayoutEffect, useRef, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import WaveSurfer from 'wavesurfer.js';
// import VolumeSetting from './VolumeSetting';

const AudioPlayer = ({ src, color }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const [playing, setPlay] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [volume, setVolume] = useState(1);

  useLayoutEffect(() => {
    setPlay(false);

    if (!waveformRef) return null;

    const options = {
      container: waveformRef.current,
      waveColor: '#c1c1c1',
      progressColor: color || '#ff914d',
      barWidth: 4,
      barRadius: 5,
      cursorWidth: 0,
      responsive: true,
      height: 50,
      fillParent: true,
      maxCanvasWidth: 200,
      hideScrollbar: true,
      // min 10 pixels per second
      minPxPerSec: 10,
      // If true, normalize by the maximum peak instead of 1.0.
      normalize: true,
      // Use the PeakCache to improve rendering speed of large waveforms.
      partialRender: true,
    };

    setLoading(true);

    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(src);

    wavesurfer.current.on('ready', () => {
      // make sure object still available when file loaded
      if (!wavesurfer.current) {
        return;
      }

      console.log('Wavesurfer ready!', wavesurfer);

      setLoading(false);

      wavesurfer.current.setVolume(1);
    });

    // after finish, dont play again
    wavesurfer.current.on('finish', () => {
      setPlay(false);
    });

    wavesurfer.current.on('error', (error) => {
      console.log('Could not load wavesurfer', error);
    });

    return () => wavesurfer.current.destroy();
  }, [src]);

  const handlePlayPause = () => {
    if (!wavesurfer.current) return;

    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div>
      <div className="">
        <div className="has-min-width-200 has-fullwidth has-max-width-300 has-cursor-pointer has-margin-auto" ref={waveformRef} />
        <div className="mt-2">
          <button
            type="button"
            onClick={handlePlayPause}
            className="cleanButton is-block has-margin-auto"
            disabled={loading}
          >
            <i className={`is-size-5 fas fa-${!playing ? 'play' : 'pause'}`} />
          </button>
        </div>
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default AudioPlayer;
