import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

const AnalyticsInfo = (props) => {
  const { data } = props;
  const {
    browser, screen, device, os, lang, cpu, country, isDarkmode,
  } = data;

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const showDarkmode = () => (
    <div>
      <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
      <p className="ml10 has-text-weight-semibold is-inline has-text-midnightblue">Darkmode:</p>
      <p className="is-inline ml5 has-text-grey">
        {`${isDarkmode ? 'An' : 'Aus'}`}
      </p>
    </div>
  );

  const showCountry = () => {
    if (!country) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="ml10 has-text-weight-semibold is-inline has-text-midnightblue">Land:</p>
        <p className="is-inline ml5 has-text-grey">
          {`${country}`}
        </p>
      </div>
    );
  };

  const showCpu = () => {
    const { name } = cpu;

    if (!name) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="ml10 has-text-weight-semibold is-inline has-text-midnightblue">CPU:</p>
        <p className="is-inline ml5 has-text-grey">
          {`${name}`}
        </p>
      </div>
    );
  };

  const showBrowser = () => {
    const { name, version } = browser;
    if (!name || !version) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="has-text-weight-semibold ml10 is-inline has-text-midnightblue">
          Browser:
        </p>
        <p className="ml5 is-inline has-text-grey">
          {`${name} ${version}`}
        </p>
      </div>
    );
  };

  const showScreen = () => {
    const { width, height } = screen;

    if (!width || !height) return null;
    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="ml10 has-text-weight-semibold is-inline has-text-midnightblue">
          Bildschirm:
        </p>
        <p className="ml5 is-inline has-text-grey">
          {width}
          x
          {height}
        </p>
        <p className="ml5 is-inline has-text-grey">Pixel</p>
      </div>
    );
  };

  const showDevice = () => {
    const { type, model } = device;

    if (!type || !model) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="ml10 has-text-weight-semibold is-inline has-text-midnightblue">Gerät:</p>
        <p className="is-inline ml5 has-text-grey">
          {`${type} ${model}`}
        </p>
      </div>
    );
  };

  const showOS = () => {
    const { version, name } = os;

    if (!version || !name) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="has-text-weight-semibold is-inline ml10 has-text-midnightblue">Betriebssystem:</p>
        <p className="ml5 is-inline has-text-grey">
          {`${name} ${version}`}
        </p>
      </div>
    );
  };

  const showLang = () => {
    if (!lang) return null;

    return (
      <div>
        <i className="is-inline has-text-midnightblue fas fa-chevron-circle-right" />
        <p className="is-inline has-text-weight-semibold ml10 has-text-midnightblue">Browsersprache:</p>
        <p className="ml5 is-inline has-text-grey">
          {lang}
        </p>
      </div>
    );
  };

  const showAnalytics = () => (
    <div className="p5 br10 has-background-dash my-2 is-size-7">
      { showBrowser() }
      { showScreen() }
      { showDevice() }
      { showOS() }
      { showCpu() }
      { showLang() }
      { showCountry() }
    </div>
  );

  return (
    <>
      <button
        type="button"
        className="cleanButton has-text-weight-semibold is-paddingless is-size-7 is-block"
        onClick={toggleDetails}
      >
        { showDetails ? 'Weniger anzeigen' : 'Mehr anzeigen'}
      </button>
      { showDetails && showAnalytics() }
    </>
  );
};

AnalyticsInfo.propTypes = {
  data: PropTypes.shape({
    browser: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string,
    }),
    screen: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    device: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
    }),
    os: PropTypes.shape({
      version: PropTypes.string,
      name: PropTypes.string,
    }),
    cpu: PropTypes.shape({
      name: PropTypes.string,
    }),
    lang: PropTypes.string,
    country: PropTypes.string,
    isDarkmode: PropTypes.bool,
  }).isRequired,
};

export default AnalyticsInfo;
