import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import * as routes from 'constants/routes';

// Assets
import flowterImage from 'assets/images/flowterstart.png';
import flowterTemplateImage from 'assets/images/templatesstart.png';

// Reducer Actions
import { clearCreator } from 'reducer/hubs';

// Components
import CreatorButton from 'components/widgets/CreatorButton';

/*
 * Component to init the widget creation
 */
function HubEditorSelector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const clearAndGoToCreator = () => {
    dispatch(clearCreator());

    if (isAuthenticated) {
      // go to creator dialog
      navigate(routes.HUB_PROFILE);
      return;
    }

    // if user is not authenticated, go to the hub demo editor
    navigate(routes.HUB_DEMO_EDITOR);
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Hub erstellen</title>
      </Helmet>
      <div className="columns is-centered is-vcentered is-fullheight">
        <div className="column is-8-desktop is-10-tablet">
          <div className="mt20 mb40">
            <div className="columns mt0 mb0 ml10 mr10 is-mobile">
              <div className="column p0">
                <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile my-5 has-text-centered-mobile">
                  Erstelle einen Hub
                </h2>
              </div>
            </div>
            <div className="columns m0 is-mobile is-centered-mobile">
              <CreatorButton
                to={routes.HUB_TEMPLATES}
                img={flowterTemplateImage}
                topText="Aus Vorlage"
                bottomText="Wähle eine Vorlage zu verschiedenen Themen aus"
              />
              <CreatorButton
                action={clearAndGoToCreator}
                img={flowterImage}
                topText="Neuer Hub"
                bottomText="Beginne mit einem leeren Hub"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubEditorSelector;
