import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// HOCs
import withHubData from 'components/hoc/withHubData';

// Components
import HubNavbar from '../HubNavbar';
import UrlCreator from './UrlCreator';
import QRButton from './QRButton';
import NameCreator from './NameCreator';
import DescCreator from './DescCreator';
import SocialEditor from './SocialEditor';
import ColorSelector from './ColorSelector';
import SaveButton from './SaveButton';
import ProfilePicCreator from './ProfilePicCreator';
import LanguageSelector from './LanguageSelector';
import DeleteButton from './DeleteButton';
import PrivacyUrlEditor from './PrivacyUrlEditor';
import ToggleStatusButton from './ToggleStatusButton';

function HubProfileEditor() {
  return (
    <>
      <Helmet>
        <title>Flowdust - Hub Profil bearbeiten</title>
      </Helmet>

      <div>
        <HubNavbar />
        <div className="is-relative pb-6">
          <h1 className="has-text-centered is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold has-text-black mt-5">
            Dein Hub
          </h1>
          <h2 className="has-text-centered is-size-6-desktop is-size-7-tablet is-size-8-mobile has-text-weight-medium has-text-grey mt-3 px-4">
            Du kannst deinem Hub ein Profil hinzufügen, um dein Projekt vorzustellen. Dieses wird am Anfang und am Ende deines Hubs zu sehen sein.
          </h2>
          <div className="columns is-marginless is-centered is-mobile">
            <div className="column is-6-desktop is-10-touch">
              <ProfilePicCreator />
              <ColorSelector />
              <div className="columns is-multiline">
                <div className="column is-narrow is-flex has-content-centered">
                  <ToggleStatusButton />
                  <div className="ml-2">
                    <DeleteButton />
                  </div>
                </div>
                <div className="column">
                  <LanguageSelector />
                </div>
              </div>

              <div className="columns mb-0">
                <div className="column">
                  <UrlCreator />
                </div>
                <div className="column">
                  <NameCreator />
                </div>
              </div>
              <div className="columns mb-0">
                <div className="column">
                  <DescCreator />
                </div>
                <div className="column">
                  <PrivacyUrlEditor />
                </div>
              </div>

              <div className="is-flex">
                <SocialEditor />

              </div>
              <div className="has-text-centered mt-5">
                <SaveButton />
              </div>
              <div className="is-flex mt-6 pt-4 has-border-top">
                <div className="">
                  <QRButton />
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withHubData(HubProfileEditor);
