import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setSocial } from 'reducer/hubs';

const LinkedinEditor = () => {
  const dispatch = useDispatch();
  const { creator } = useSelector((state) => state.hubs);
  const { linkedin } = creator.socials;

  const [error, setError] = useState('');

  const setValue = (value) => {
    dispatch(setSocial({ type: 'linkedin', value }));
  };

  return (
    <div className="field">
      <label className="label">
        Deine LinkedIn URL
      </label>
      <div className="control">
        <input
          onChange={(event) => setValue(event.target.value)}
          type="text"
          value={linkedin}
          placeholder="https://linkedin.com/in/name"
          className="input br5 is-size-6 has-text-black has-background-white"
        />
        <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
          { error }
        </p>
      </div>
    </div>
  );
};

export default LinkedinEditor;
