import React from 'react';

// Components
import { Link } from 'react-router-dom';

// Constants
import { BILLING } from 'constants/routes';

const TooManyHubsInfo = () => (
  <div className="p-3">
    <div className="columns is-centered is-mobile is-vcentered is-fullheight">
      <div className="column is-8-desktop is-8-tablet is-11-mobile">
        <div className="box boxshadow has-text-centered">
          <h2
            className="has-text-black has-text-weight-bold is-size-5 mb-3"
          >
            Du möchtest noch mehr Hubs erstellen?
          </h2>
          <p>
            Mit Flowdust Premium bekommst du nicht nur mehr Hubs, sondern genießt auch noch weitere Vorteile!
          </p>
          <Link
            to={BILLING}
            className="button is-primary br10 has-text-weight-bold grow mt-3"
          >
            Zum Abonnement
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default TooManyHubsInfo;
