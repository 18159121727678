import React, { useMemo } from 'react';

// Hooks
import { Link, useParams, useLocation } from 'react-router-dom';

// Components
import HubLink from './HubLink';
import SaveButton from './SaveButton';

// Constants
import {
  HUB_CONTENT, HUB_PROFILE, HUB_STATISTIC, HUB_LIST,
} from 'constants/routes';

// Styles
import styles from './styles.module.scss';

function HubNavbar() {
  const { id } = useParams();
  const { pathname } = useLocation();

  const isContent = useMemo(() => pathname.includes(HUB_CONTENT), [pathname]);

  const isProfile = useMemo(() => pathname.includes(HUB_PROFILE), [pathname]);

  const isStats = useMemo(() => pathname.includes(HUB_STATISTIC), [pathname]);

  return (
    <div className="has-min-height-70 has-background-white br10 has-border-bottom">
      <div
        id={styles.navbarPadding}
        className="columns has-fullwidth m-0 px-3 has-min-height-70 is-mobile is-centered-mobile"
      >
        <div className="column is-narrow is-flex has-content-centered">
          <Link
            to={HUB_LIST}
            className="has-hover px-3 br10 is-flex has-content-centered has-text-black has-background-grey-lighter has-height-30 has-line-height-full is-size-7"
          >
            <i className="fas fa-caret-left mr-2" />
            <p>Zurück</p>
          </Link>
        </div>

        <div className="column is-narrow is-flex has-content-centered px-1">
          <Link
            className={`has-hover p-3 br10 is-flex has-width-100 has-content-centered has-text-black has-line-height-full ${isProfile ? 'has-text-weight-bold has-background-grey-lighter' : ''}`}
            to={`${HUB_PROFILE}/${id}`}
          >
            <i className="pr-1 is-inline fa-solid fa-user" />
            <p className="is-inline">Profil</p>
          </Link>
        </div>

        <div className="column is-narrow is-flex has-content-centered px-1">
          <Link
            className={`has-hover p-3 br10 is-flex has-width-130 has-content-centered has-text-black has-line-height-full ${isContent ? 'has-text-weight-bold has-background-grey-lighter' : ''}`}
            to={`${HUB_CONTENT}/${id}`}
          >
            <i className="pr-1 is-inline fa-solid fa-pen" />
            <p className="is-inline">Bearbeiten</p>
          </Link>
        </div>

        <div className="column is-narrow is-flex has-content-centered px-1">
          <Link
            className={`has-hover p-3 br10 is-flex has-width-130 has-content-centered has-text-black has-line-height-full ${isStats ? 'has-text-weight-bold has-background-grey-lighter' : ''}`}
            to={`${HUB_STATISTIC}/${id}`}
          >
            <i className="pr-1 is-inline fa-solid fa-square-poll-vertical" />
            <p className="is-inline">Ergebnisse</p>
          </Link>
        </div>

        <div className="column is-narrow is-flex has-content-centered">
          <HubLink />
        </div>

        {
          isContent
          && (
          <div className="column is-narrow has-margin-left-auto is-flex has-content-centered is-hidden-mobile">
            <SaveButton />
          </div>
          )
        }
      </div>
    </div>

  );
}

export default HubNavbar;
