import React, { useMemo } from 'react';

// Components
import HubTemplates from 'components/hub/HubTemplates';

// Data
import { templates } from 'components/hub/templates';

const GeneralHubTemplates = () => {
  const generalTemplates = useMemo(() => templates.filter((template) => template.tags.includes('general')), []);

  return (
    <div className="">
      <h2
        className="has-text-weight-bold has-text-black mb-6"
      >
        Hier sind ein paar Vorlagen, die für viele grundlegende Anwendungsfälle gut sind:
      </h2>
      <HubTemplates templates={generalTemplates} />
    </div>
  );
};

export default GeneralHubTemplates;
