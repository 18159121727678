import React, { useState } from 'react';

// Libraries
import { useSelector } from 'react-redux';
import stats from 'analytics/analytics';

// Components
import { CopyToClipboard } from 'react-copy-to-clipboard';

/*
 * Component to copy text.
 * The text (props.url) is given by the parent
 */

const ScriptTagCopy = () => {
  const [copied, setCopied] = useState(false);
  const { publicId } = useSelector((state) => state.sessionState.user);

  const url = `<script id="flowdustWidget">
!function(e,t,n){e.Flowdust=e.Flowdust||{},e.Flowdust.id="${publicId}";var s=function(){var e=t.createElement("script"),n=t.getElementsByTagName("script")[0];e.async=!0,e.type="text/javascript",e.src="${`${process.env.REACT_APP_CDN}/widget.js`}",n.parentNode.insertBefore(e,n)};e.addEventListener?e.addEventListener("load",s,!1):e.attachEvent("onload",s)}(window,document);
</script>`;

  const copyTag = () => {
    stats.push(['trackEvent', 'Script Installation', 'Script tag copied']);
    setCopied(true);
  };

  return (
    <div className="columns mb30 is-centered">
      <div className="column is-9-desktop has-text-centered">
        <p className="pr5 is-size-7 has-text-right">
          HTML
        </p>
        <div className="has-background-grey-dark br10 pb10 pl20 pr20 pt10">
          <pre className="has-text-left has-background-grey-dark has-text-white is-paddingless">
            {url}
          </pre>
        </div>
        <CopyToClipboard
          text={url}
          onCopy={copyTag}
        >
          <button
            className="button has-background-pastel boxshadow is-rounded has-text-white mt20 has-text-weight-bold has-hover-purple"
            type="button"
          >
            Kopieren
          </button>
        </CopyToClipboard>
        {
          copied && (
          <p className="has-text-centered has-text-weight-semibold mt30">
            Kopiert!
          </p>
          )
        }
      </div>
    </div>
  );
};

export default ScriptTagCopy;

/*
Full Script
<script id="flowdustWidget">
   (function (window, document, undefined) {
           window.Flowdust = window.Flowdust || {};
           window.Flowdust.id="${this.props.user.publicId}"
           var loader = function () {
            var script = document.createElement("script"), tag = document.getElementsByTagName("script")[0];
            script.async = !0;
            script.type = 'text/javascript'
            script.src = process.env.REACT_APP_CDN + "/widget.js";`
            `
            tag.parentNode.insertBefore(script, tag);
        };
        window.addEventListener ? window.addEventListener("load", loader, false) : window.attachEvent("onload", loader);
    })(window, document);
 </script>;
 */

/*
 Old minified script
 <script id="flowdustWidget">
!function(e,t,n){e.Flowdust={id:"${this.props.user.publicId}"};var a=function(){var e=t.createElement("script"),n=t.getElementsByTagName("script")[0];e.async=!0,e.type="text/javascript",e.src="${`${process.env.REACT_APP_CDN}/widget.js`}",n.parentNode.insertBefore(e,n)};e.addEventListener?e.addEventListener("load",a,!1):e.attachEvent("onload",a)}(window,document);
</script>
*/
