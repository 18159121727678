import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setLogicCondition, setLogicTrigger, deleteLogic } from 'reducer/hubs';

// Components
import Condition from './Condition';
import ActionEditor from './ActionEditor';

const Logic = (props) => {
  const { logic, questions } = useSelector((state) => state.hubs.creator.content);
  const dispatch = useDispatch();

  const { index } = props;

  const { trigger, action, conditions } = logic[index];

  const condition = conditions[0];

  const _deleteLogic = () => {
    dispatch(deleteLogic(index));
  };

  const setCondition = (_condition) => {
    const { question } = _condition;

    // In simple mode, trigger and condition question are the same
    dispatch(setLogicTrigger({
      id: index, value: question,
    }));

    dispatch(setLogicCondition({
      logicIndex: index,
      conditionIndex: 0,
      condition: _condition,
    }));
  };

  return (
    <div className="mt-4 has-text-black has-border-bottom pb-4">
      <div>
        <p className="has-text-black mb-3 has-text-weight-bold is-inline">
          {`Verzweigung Nr. ${index + 1}`}
        </p>
        <button
          type="button"
          onClick={_deleteLogic}
          className="cleanButton has-text-underlined has-text-danger is-inline mx-1"
        >
          Löschen
        </button>
      </div>
      <p className="is-size-7">
        Wenn bei
      </p>
      <Condition
        condition={condition}
        setCondition={(_condition) => setCondition(_condition)}
      />
      <ActionEditor logicIndex={index} />
    </div>
  );
};

Logic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default Logic;
