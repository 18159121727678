import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import LoadMoreButton from '../../utils/LoadMoreButton';
import DeleteButton from '../../utils/DeleteButton';

const FeedbackDisplay = (props) => {
  const { index, showValue, text } = props;
  const { statistic } = useSelector((state) => state.hubs);
  const { submissions } = statistic;

  const submissionWithText = useMemo(() => submissions.filter((data) => {
    const { responses } = data.submission;
    let hasText = false;

    if (responses[index].input) {
      hasText = true;
    }

    // filter if submission has no text
    return hasText;
  }), [submissions]);

  if (submissionWithText.length === 0) {
    return (
      <div className="has-max-height-500 has-overflow-y-auto mt-4">
        <p className="pt-5 has-border-bottom mb10 has-text-left has-text-weight-semibold has-text-midnightblue mb-3">
          Noch gibt es kein Feedback von deinen Nutzern!
        </p>
      </div>
    );
  }

  const submissionLayout = submissionWithText.map((item) => {
    const { values, input } = item.submission.responses[index];

    return (
      <div
        className="has-text-left p5 br10 mb15 has-text-weight-medium has-text-black has-background-dash mr-4"
        key={item._id}
      >
        <div className="columns is-marginless">
          <div className="column">
            <p className="break-word">
              {input}
            </p>
            { showValue
                 && (
                 <p
                   className="is-size-7"
                 >
                   {`Abstimmung: ${values[0]} %`}
                 </p>
                 )}
          </div>
          <div className="column is-narrow has-text-centered-mobile">
            <DeleteButton responseID={item._id} />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="has-max-height-500 has-overflow-y-auto mt-4 pb-3">
      <p className="has-border-bottom has-text-left has-text-weight-semibold has-text-midnightblue is-block mr-4 mb-5 pt-5">
        { text }
      </p>
      { submissionLayout }
      <div className="mt-4">
        <LoadMoreButton
          amountEntries={submissionWithText.length}
        />
      </div>
    </div>
  );
};

FeedbackDisplay.propTypes = {
  index: PropTypes.number.isRequired,
  showValue: PropTypes.bool,
  text: PropTypes.string,
};

FeedbackDisplay.defaultProps = {
  showValue: false,
  text: 'Das sagen deine Nutzer:',
};

export default FeedbackDisplay;
