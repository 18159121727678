import React from 'react';
import { useNavigate } from 'react-router-dom';
import stats from 'analytics/analytics';
import { DEMO } from 'constants/routes';
import shopifyIcon from 'assets/images/shopify.svg';
import joomlaIcon from 'assets/images/joomla.svg';
import googleIcon from 'assets/images/google.svg';

import './styles/callToAction.scss';

function CallToAction() {
  const navigate = useNavigate();

  const clickActionButton = () => {
    stats.push(['trackEvent', 'Landing - Call to Action', 'Button clicked']);
    navigate(DEMO);
  };
  return (
    <div className="has-text-centered pt50 pb50 mt-6">
      <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-black has-text-weight-bold px-3">
        Bereit loszulegen?
      </h2>
      <p className="m20 has-text-black has-text-weight-bold">
        Klicke auf den Button, um zu starten. Kein Account nötig.
      </p>
      <button
        type="button"
        className="button boxshadow has-text-white is-pastel br10 has-text-weight-bold grow mt-2"
        onClick={clickActionButton}
      >
        Zum Editor
      </button>
      <IntegrationIcons />
    </div>
  );
}

function IntegrationIcons() {
  return (
    <div className="columns is-centered is-mobile m0 mt60">
      <div className="column is-narrow has-text-centered">
        <a
          className="integrationIcon"
          href="https://wordpress.org/plugins/flowdust/"
          rel="noopener noreferrer"
          target="blank"
        >
          <i className="fab fa-wordpress has-text-info" />
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          href="https://marketingplatform.google.com/intl/de/about/tag-manager/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img className="integrationIcon" src={googleIcon} alt="Icon für Google Tag Manager" />
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          href="https://www.shopify.de/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img className="integrationIcon" src={shopifyIcon} alt="Icon für Shopify" />
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          className="integrationIcon"
          href="https://magento.com/de"
          rel="noopener noreferrer"
          target="blank"
        >
          <i className="fab fa-magento has-text-pastel"> </i>
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          className="integrationIcon"
          href="https://www.shopware.com/de/"
          rel="noopener noreferrer"
          target="blank"
        >
          <i className="fab fa-shopware has-text-info"> </i>
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          href="https://www.joomla.de/"
          rel="noopener noreferrer"
          target="blank"
        >
          <img className="integrationIcon" src={joomlaIcon} alt="Icon für Joomla" />
        </a>
      </div>
      <div className="column is-narrow has-text-centered">
        <a
          className="integrationIcon"
          href="https://de.wix.com/"
          rel="noopener noreferrer"
          target="blank"
        >
          <i className="fab fa-wix has-text-black" />
        </a>
      </div>
    </div>
  );
}

export default CallToAction;
