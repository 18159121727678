import React, { useMemo, useState } from 'react';
import EndingDisplay from './EndingDisplay';
import QuestionDisplay from './QuestionDisplay';

const ContentDisplay = (props) => {
  const { content } = props;

  const {
    info, questions, endings, logic,
  } = content;

  const renderedQuestions = useMemo(() => questions.map((question, index) => (
    <QuestionDisplay
      question={question}
      key={index}
      index={index}
    />
  )), []);

  const renderedEndings = useMemo(() => endings.map((ending, index) => (
    <EndingDisplay
      ending={ending}
      key={index}
      index={index}
    />
  )), []);

  return (
    <div className="my-3">
      <div>
        <p className="has-text-weight-bold has-text-black">
          Fragen
        </p>
        { renderedQuestions }
      </div>
      <div className="">
        <p className="has-text-weight-bold has-text-black">
          {endings.length > 1 ? 'Enden' : 'Ende'}
        </p>
        { renderedEndings }
      </div>

    </div>
  );
};

export default ContentDisplay;
