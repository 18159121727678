import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Select from 'react-select';
import EmailQuestion from './EmailQuestion';
import PollQuestion from './PollQuestion';
import SliderQuestion from './SliderQuestion';

const QuestionSelector = (props) => {
  const { filter, setFilter } = props;
  const { hubs, statistic } = useSelector((state) => state.hubs);
  const { hubIndex } = statistic;

  const { questions } = hubs[hubIndex].content;

  const { question } = filter;

  const _setQuestion = (_question) => {
    const newQuestion = _question.value;

    if (question === newQuestion) return;

    setFilter({
      question: newQuestion,
      type: 'duration',
      sign: 'eq',
      value: '3',
    });
  };

  // Prepare all question to be displayed
  const labels = useMemo(() => questions.map((_question, _index) => {
    const { text } = _question;

    return {
      value: _index,
      label: text,
    };
  }), []);

  // how each question should be rendered
  const renderLabel = ({ value, label }) => {
    const { type } = questions[value];

    let renderedType = '';

    switch (type) {
      case 'SLIDER':
        renderedType = 'Rating';
        break;
      case 'EMAIL':
        renderedType = 'Email sammeln';
        break;
      case 'POLL':
        renderedType = 'Umfrage';
        break;
      default:
        renderedType = '';
        break;
    }

    return (
      <div>
        <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-pastel" />
        <p className="is-inline has-text-weight-bold is-size-7">
          {label}
        </p>
        <div>
          <p
            className="is-size-7 has-text-grey"
          >
            {renderedType}
          </p>
        </div>
      </div>
    );
  };

  const showSelectors = () => {
    const { type } = questions[question];

    switch (type) {
      case 'POLL':
        return <PollQuestion filter={filter} setFilter={setFilter} />;
      case 'EMAIL':
        return <EmailQuestion filter={filter} setFilter={setFilter} />;
      case 'SLIDER':
        return <SliderQuestion filter={filter} setFilter={setFilter} />;

      default: return null;
    }
  };

  // custom react-select css height because it doesnt change automatically
  const targetHeight = 50;

  const styles = {
    control: (base) => ({
      ...base,
      cursor: 'pointer',
      minHeight: 'initial',
    }),
    menuList: (base) => ({
      ...base,
      padding: '0px',
      borderRadius: '10px',
      overflow: 'hidden',
    }),
    option: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      height: `${targetHeight - 1 - 1}px`,
      padding: '0 8px',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
  };

  return (
    <div className="has-min-width-200">
      <div className="has-max-width-400">
        <p
          className="has-text-weight-bold is-size-6"
        >
          Frage
        </p>
        <Select
          value={labels[question]}
          isSearchable={false}
          styles={styles}
          formatOptionLabel={renderLabel}
          options={labels}
          onChange={_setQuestion}
          maxMenuHeight={300}
          menuPlacement="auto"
          placeholder=""
          theme={(theme) => ({
            ...theme,
            borderRadius: '10px',
            colors: {
              ...theme.colors,
              text: '#ffffff',
              primary25: '#fcb992',
              primary50: '#fcb992',
              primary75: '#fcb992',
              primary: '#fcb992',
            },
          })}
        />
      </div>
      { showSelectors() }
    </div>
  );
};

QuestionSelector.propTypes = {
  /** Array of filters
    * @example: Filter: For Question at index 1, Duration should be less or equal to 3 seconds
    * question would be 1, type would be "duration", sign "lte" and value 3000
    */
  filter: PropTypes.shape({
    /* Index of the question the filter should be applied to */
    question: PropTypes.number,
    /* Type of the filter. Values: ['duration', 'answer', 'input'] */
    type: PropTypes.string,
    /*
     * Sign of the type for the filter. Values: ['gt', 'lt', 'eq', 'gte', 'lte']
     * Used to determine the range of the value
     */
    sign: PropTypes.string,
    /* Value of the filter */
    value: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default QuestionSelector;
