import React, { useEffect, useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';
import getHubUrl from 'utils/getHubUrl';

// Components
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyUrlButton = (props) => {
  const { url } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => setCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const sanitizedURL = useMemo(() => {
    const _url = sanitizeUrl(url);
    return getHubUrl(_url);
  }, [url]);

  return (
    <CopyToClipboard
      text={sanitizedURL}
      onCopy={() => setCopied(true)}
    >
      <button
        type="button"
        className="cleanButton has-hover is-flex align-items-center py-2 px-3 br10 m0 is-size-5 has-fullwidth"
      >
        <i className="fas fa-clone has-text-black mr-2" />
        <p
          className="is-size-7 is-inline-block has-text-black has-text-weight-semibold"
        >
          {
            copied
              ? 'Kopiert in die Zwischenablage!'
              : 'Link kopieren'
          }
        </p>
      </button>
    </CopyToClipboard>
  );
};

CopyUrlButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CopyUrlButton;
