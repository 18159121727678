import React, { useState, useEffect } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import isURL from 'validator/lib/isURL';
/* eslint import/no-webpack-loader-syntax: off */
import innerStyles from '!!raw-loader!./styles/innerStyles.scss';

/**
 * Footer Component for all Widgets
 * @prop {String} privacyURL - 0 = left, 1 = right
 * @return {Html}
 */
const Footer = (props) => {
  const isGerman = (navigator.language || navigator.userLanguage).includes('de');
  const pageUrl = window.location.hostname;

  const [url, setUrl] = useState('');

  const { privacyURL } = props;

  useEffect(() => {
    let sanitizedUrl = sanitizeUrl(privacyURL);

    if (sanitizedUrl.indexOf('http') !== 0) {
      sanitizedUrl = `https://${sanitizedUrl}`;
    }

    if (!isURL(sanitizedUrl)) {
      setUrl('about:blank');
      return;
    }

    setUrl(sanitizedUrl);
  }, [privacyURL]);

  return (
    <>
      <style>
        { innerStyles }
      </style>
      <div id="footer">
        <div id="privacyPolicy" className="privacy">
          {
							url
							&&							(isGerman
							  ?							<a href={url} rel="noopener noreferrer" target="_blank">Datenschutz</a>
							  :							<a href={url} rel="noopener noreferrer" target="_blank">Privacy Policy</a>)
						}
        </div>
        <div id="poweredBy">
          <a id="poweredByLink" href={`https://flowdust.com/ref/${pageUrl}`} rel="noopener noreferrer" target="_blank">
            <div />
          </a>
        </div>
        <div id="dataProcessor" className="privacy">
          {
						isGerman
						  ?						<a href="https://flowdust.com/privacy" rel="noopener noreferrer" target="_blank">Datenverarbeiter</a>
						  :						<a href="https://flowdust.com/privacy" rel="noopener noreferrer" target="_blank">Data Processor</a>
					}
        </div>
      </div>
    </>

  );
};

export default Footer;
