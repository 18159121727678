const initialUser = {
  publicId: '',
  status: 'trial',
  amount: 0,
  privacyURL: '',
  email: '',
  widgetCount: 0,
  websiteCount: 0,
  name: '',
  statCookies: false,
  // fileStorageUsedBytes: 0,
  planDetails: {
    perks: [],
    cost: 0,
    plan: 0,
    views: 1000,
    name: '',
    maxAmountHubs: 1,
    // fileStorageSizeBytes: 0,
    // maxfileSizeBytes: 2,
  },
};

const INITIAL_STATE = {
  hasData: false,
  user: initialUser,
};

const applySetUserLoading = (state, action) => ({
  ...state,
  isLoading: true,
});

const applySetUserLoaded = (state, action) => ({
  ...state,
  hasData: true,
  ...action.payload,
});

const applyClearState = () => INITIAL_STATE;

const applyLoginAgain = (state, action) => ({
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: false,
  loginAgain: true,
  hasData: false,
});

function applySetAuthSuccess(state, action) {
  let newState = {
    ...state,
    hasData: true,
    user: action.user,
  };

  const { custom } = action.user;

  if (custom) {
    // If there is a custom plan that has not yet been accepted, save the information about that plan in the custom variable of the user object
    newState = {
      ...newState,
      user: {
        ...newState.user,
        custom,
      },
    };
  }

  return newState;
}

const incrementWidgetCount = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    widgetCount: state.user.widgetCount + 1,
  },
});

const decrementWidgetCount = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    widgetCount: state.user.widgetCount - 1,
  },
});

const incrementWebsiteCount = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    websiteCount: state.user.websiteCount + 1,
  },
});

const decrementWebsiteCount = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    websiteCount: state.user.websiteCount - 1,
  },
});

function applySetName(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      name: action.name,
    },
  };
}

function applySetPrivacyURL(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      privacyURL: action.privacyURL,
    },
  };
}

function applySetPlanDetails(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      planDetails: action.planDetails,
    },
  };
}

function applySetStatus(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      status: action.status,
    },
  };
}

function applySetPaymentInfo(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      paymentMethods: action.paymentMethods,
    },
  };
}

function applyDeletePaymentMethod(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      paymentMethods: state.user.paymentMethods.filter((method, index) => index !== action.id),
    },
  };
}

function setOnboardingPage(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      page: action.page,
    },
  };
}

function applySetCustomerData(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      customer: action.data.customer,
      paymentMethods: action.data.paymentMethods,
      subscription: action.data.subscription,
    },
  };
}

const applySetCustomer = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    customer: action.customer,
  },
});

const setOnboardingWidgetType = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    onboardingWidgetType: action.widgetType,
  },
});

const applySetSubscription = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    subscription: action.subscription,
  },
});

const setNewPlan = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    newPlan: action.newPlan,
  },
});

const setUser = (state, action) => ({
  ...state,
  hasData: true,
  user: {
    ...action.user,
    // we dont want newPlan to rehydrate, so always set it with action.user, even if its undefined
    newPlan: action.user.newPlan,
  },
});

const setUserData = (state, action) => ({
  ...state,
  hasData: true,
  user: {
    // we NEED to have ...state.user first because we dont always set the whole data
    ...state.user,
    ...action.user,
    // we dont want newPlan to rehydrate, so always set it with action.user, even if its undefined
    newPlan: action.user.newPlan,
  },
});

function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USER_LOADING':
    {
      return applySetUserLoading(state, action);
    }
    case 'USER_LOADED':
    {
      return applySetUserLoaded(state, action);
    }
    // do the same thing for login succes and register success
    case 'LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
    case 'USER_SET':
    {
      return applySetAuthSuccess(state, action);
    }

    case 'CLEAR_STATE':
    case 'AUTH_ERROR':
    case 'LOGIN_FAIL':
    case 'LOGOUT_SUCCESS':
    case 'REGISTER_FAIL':
    {
      return applyClearState(state, action);
    }

    case 'LOGIN_AGAIN':
    {
      return applyLoginAgain(state, action);
    }

    case 'PRIVACY_URL_SET':
    {
      return applySetPrivacyURL(state, action);
    }

    case 'NAME_SET':
    {
      return applySetName(state, action);
    }

    case 'PLAN_DETAILS_SET':
    {
      return applySetPlanDetails(state, action);
    }

    case 'STATUS_SET':
    {
      return applySetStatus(state, action);
    }

    case 'SET_SUBSCRIPTION': {
      return applySetSubscription(state, action);
    }

    case 'PAYMENT_METHODS_SET':
    {
      return applySetPaymentInfo(state, action);
    }

    case 'PAYMENT_METHOD_DELETE':
    {
      return applyDeletePaymentMethod(state, action);
    }

    case 'SET_ONBOARDING_PAGE':
    {
      return setOnboardingPage(state, action);
    }
    case 'SET_ONBOARDING_WIDGET_TYPE':
    {
      return setOnboardingWidgetType(state, action);
    }
    case 'SET_CUSTOMER_DATA':
    {
      return applySetCustomerData(state, action);
    }
    case 'SET_CUSTOMER':
    {
      return applySetCustomer(state, action);
    }
    case 'INC_WIDGET_COUNT':
    {
      return incrementWidgetCount(state, action);
    }
    case 'DEC_WIDGET_COUNT':
    {
      return decrementWidgetCount(state, action);
    }
    case 'INC_WEBSITE_COUNT':
    {
      return incrementWebsiteCount(state, action);
    }
    case 'DEC_WEBSITE_COUNT':
    {
      return decrementWebsiteCount(state, action);
    }

    case 'NEW_PLAN_SET':
    {
      return setNewPlan(state, action);
    }

    case 'SET_USER_DATA': {
      return setUserData(state, action);
    }

    case 'SET_USER': {
      return setUser(state, action);
    }

    default:
      return state;
  }
}

export default sessionReducer;
