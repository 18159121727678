import React, { useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';

function MaxAmountChecksEditor(props) {
  const { amountOptions, maxAmountChecks, setMaxAmountChecks } = props;

  useEffect(() => {
    if (maxAmountChecks === undefined) {
      setMaxAmountChecks(1);
    } else if (maxAmountChecks > amountOptions && amountOptions > 0) {
      setMaxAmountChecks(amountOptions);
    }
  }, [amountOptions]);

  const decrementMaxAmountChecks = () => {
    if (maxAmountChecks !== undefined) {
      setMaxAmountChecks(Math.max(maxAmountChecks - 1, 1));
      return;
    }
    setMaxAmountChecks(1);
  };

  const incrementMaxAmountChecks = () => {
    if (maxAmountChecks !== undefined) {
      setMaxAmountChecks(Math.min(maxAmountChecks + 1, amountOptions));
      return;
    }
    setMaxAmountChecks(2);
  };

  return (
    <div className="columns is-gapless mt60 mb30">
      <div className="column customDiv has-info has-text-centered">
        <div className="has-text-grey">
          <p className="has-text-centered is-inline is-size-7">
            {'Auswählen bis zu '}
          </p>
          <div className="incrementBtnWrapper">
            <button
              aria-label="Anzahl erhöhen"
              className="incrementBtn"
              onClick={incrementMaxAmountChecks}
              type="button"
            >
              <i className="fas fa-chevron-up" />
            </button>
            <p className="incrementValue">
              {maxAmountChecks || 1}
            </p>
            <button
              aria-label="Anzahl verringern"
              className="incrementBtn"
              onClick={decrementMaxAmountChecks}
              type="button"
            >
              <i className="fas fa-chevron-down" />
            </button>
          </div>
          <p className="has-text-centered is-inline is-size-7">
            {' Möglichkeit'}
            { maxAmountChecks !== 1 && 'en'}
          </p>
          <div className="has-info checkInfoWrapper">
            <Popup
              trigger={(
                <div className="cleanButton ml10 pr20">
                  <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                </div>
                     )}
              position="top center"
              on={['hover']}
              keepTooltipInside="#root"
              repositionOnResize
            >
              {
                () => (
                  <div className="columns is-marginless">
                    <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey has-background-white">
                      <p className="is-size-7">
                        Gib an, wie viele Antworten die Nutzer bei deiner Frage auswählen können.
                      </p>
                    </div>
                  </div>
                )
                }
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
}

MaxAmountChecksEditor.propTypes = {
  amountOptions: PropTypes.number.isRequired,
  maxAmountChecks: PropTypes.number.isRequired,
  setMaxAmountChecks: PropTypes.func.isRequired,
};

export default MaxAmountChecksEditor;
