import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import './styles/pricingPlan.scss';

// Constants

import { FLOWTER_INFO, HUB_INFO } from 'constants/routes';

// Components
import { Link } from 'react-router-dom';

const PricingPlan = (props) => {
  const {
    plan, isSelected, selectPlan, showButton, buttonText, className,
  } = props;

  const formatViews = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const impressions = formatViews(plan.views);
  return (
    <div
      className={className}
      id="pricingPlan"
    >
      <div
        className={`box has-border-bottom-pastel-bold has-border-top-pastel-bold boxshadow is-relative has-text-centered has-text-black ${isSelected ? 'has-border-pastel' : 'has-border-white-semibold'}`}
      >
        <div className=" has-border-bottom has-text-black has-text-weight-bold">
          <p className="mb10 is-size-4">{plan.name}</p>
        </div>
        <p className="has-text-weight-bold is-size-2-desktop is-size-3-tablet is-size-4-mobile mt30">
          {`${plan.cost} €`}
        </p>
        <p className="has-text-weight-light is-size-6">pro Monat</p>
        <p className="has-text-black has-text-weight-bold is-size-7">
          {
             plan.costWithVAT
               ? `(${plan.costWithVAT} € inkl. USt.)`
               : '(exkl. USt.)'
           }
        </p>
        <div className="columns is-centered is-marginless mt-4">
          <div className="column has-text-weight-semibold is-paddingless">

            <div className="is-flex has-content-centered mb-2">
              <p className="is-size-6 mr-1">{plan.maxAmountHubs}</p>
              <Link to={HUB_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-1">
                {plan.maxAmountHubs === 1 ? 'Hub' : 'Hubs'}
              </Link>
            </div>

            <div>
              <p className="has-text-weight-bold is-size-6 is-inline">
                {`${impressions}`}
              </p>
              <p className="">
                Flowter Impressionen
              </p>
              <p className="has-text-weight-bold is-size-6 is-inline">
                pro Monat
              </p>
              <Popup
                trigger={(
                  <div className="cleanButton is-inline ml5">
                    <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                  </div>
                )}
                position="top center"
                on={['hover']}
                keepTooltipInside="#root"
                repositionOnResize
                className="mt10"
              >
                {
                      () => (
                        <div className="columns is-marginless">
                          <div className="has-text-weight-light column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-midnightblue">
                            <p className="is-size-7">Impressionen bezeichnen die Anzahl, wie oft deine Flowter auf deiner Website geladen und angezeigt werden.</p>
                          </div>
                        </div>
                      )
                }
              </Popup>
            </div>

            <i className="has-text-pastel fas fa-infinity mt-4" />
            <div className="mt-2">
              <p className="is-size-6">Unbegrenzte Anzahl an </p>
              <Link to={FLOWTER_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-1">
                Flowtern
              </Link>
            </div>
            <p className="is-size-6 mt-2">Unbegrenzte Anzahl an Abstimmungen</p>
            <p className="is-size-6 has-text-weight-light mt30 mb10">Jederzeit kündbar</p>
            <p className="is-size-6  has-text-weight-light">Keine Mindestlaufzeit</p>
            <div className="has-text-centered mt30 mb40">
              {
                showButton
                && (
                <button
                  className="button has-text-white has-background-pastel has-text-weight-bold is-rounded grow"
                  type="button"
                  onClick={() => selectPlan(plan.plan)}
                >
                  {buttonText}
                </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PricingPlan.propTypes = {
  plan: PropTypes.shape({
    views: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    costWithVAT: PropTypes.string,
    plan: PropTypes.number,
    maxAmountHubs: PropTypes.number,
  }).isRequired,
  isSelected: PropTypes.bool,
  selectPlan: PropTypes.func,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  className: PropTypes.string,
};

PricingPlan.defaultProps = {
  isSelected: false,
  showButton: true,
  selectPlan: () => {},
  buttonText: 'Auswählen',
  className: 'column has-min-width-300 is-3-desktop mt30 p10',
};

export default PricingPlan;
