import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import LogEntry from './LogEntry';
import LoadMoreButton from '../utils/LoadMoreButton';

// Styles
import styles from './styles.module.scss';

const FeedbackLog = (props) => {
  const { index } = props;

  const { flowters } = useSelector((state) => state.flowterState);

  const flowter = flowters[index];
  if (!flowter) return null;

  const submissions = flowter.submissions || [];

  const showSubmissions = () => submissions.map((feedback) => {
    const {
      _id,
    } = feedback;

    return <LogEntry data={feedback} key={_id} index={index} id={flowter._id} />;
  });

  return (
    <div
      id={styles.wrapper}
      className="box boxshadow mb-4 px-0 py-0"
    >
      <h2 className="has-border-bottom has-text-weight-bold has-text-pastel is-size-5 has-text-centered py-3">
        Gesamtübersicht
      </h2>
      <div
        id={styles.log}
        className="has-max-fullheight has-overflow-y-auto"
      >
        { showSubmissions() }
        <LoadMoreButton
          index={index}
          amountEntries={flowter.submissions.length}
        />
      </div>
    </div>
  );
};

FeedbackLog.propTypes = {
  index: PropTypes.number.isRequired,
};

export default FeedbackLog;
