import React from 'react';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import * as status from 'constants/status';
import { useSelector } from 'react-redux';

const StatusBar = () => {
  const user = useSelector((state) => state.sessionState.user);

  const showStatusPrivacyMissing = () => (
    <Link
      className="is-block mb-3 pt10 pb10 mb10 has-text-red is-size-5"
      to={routes.PRIVACY_SETTINGS}
    >
      <div className="">
        <i className="mr10 is-size-5 fas fa-exclamation-triangle" />
        <p className="is-size-6 is-inline has-text-black has-text-weight-bold">{'Bitte hinterlege noch den Link zu deiner Datenschutzerklärung! Klicke '}</p>
        <p className="is-size-6 is-inline has-text-weight-bold">hier</p>
        <p className="is-size-6 is-inline has-text-black has-text-weight-bold">{' um ihn hinzufügen!'}</p>
      </div>
    </Link>
  );

  const showStatusWillCancel = () => {
    let subscriptionEnd = user.subscriptionStart || Date.now(); // Get the start of the subscription
    subscriptionEnd = new Date(subscriptionEnd); // convert it to a date obj
    // calculate the subscription end (one month after scubscription cycle started)
    subscriptionEnd.setMonth(subscriptionEnd.getMonth() + 1);
    // format the date
    subscriptionEnd = subscriptionEnd.toLocaleString([], { day: '2-digit', month: '2-digit' });

    return (
      <Link
        className="has-background-yellow pt10 pb10 mb10 has-text-centered has-text-white br10 is-size-5 columns is-block"
        to={routes.BILLING}
      >
        <div className="column">
          <p className="has-text-black has-text-weight-semibold is-inline">Du hast dein Abo gekündigt. Klicke hier für weitere Informationen.</p>
        </div>
      </Link>
    );
  };

  const showStatusPastDue = () => (
    <Link
      className="has-background-danger pt10 pb10 mb10 has-text-centered has-text-white br10 is-size-5 columns is-block"
      to={routes.BILLING}
    >
      <div className="column">
        <p className="has-text-weight-semibold is-inline">Du bist mit deiner Zahlung im Rückstand. Klicke hier für weitere Informationen.</p>
      </div>
    </Link>
  );

  const showCustomSubscriptionOffer = () => (
    <Link
      className="has-background-success pt10 pb10 mb10 has-text-centered has-text-white br10 is-size-5 columns mb20 is-block"
      to={routes.CHECKOUT}
    >
      <div className="column">
        <p className="has-text-weight-semibold is-inline">Du hast nach einem auf dich zugeschnittenen größeren Plan gefragt. Klicke hier um dein Abonnement zu ändern!</p>
      </div>
    </Link>
  );

  const showStatus = () => {
    const userStatus = user.status;

    let statusMsg;

    switch (userStatus) {
      case status.WILL_CANCEL:
        statusMsg = showStatusWillCancel();
        break;

      case status.PAST_DUE:
        statusMsg = showStatusPastDue();
        break;

      default:
        statusMsg = null;
    }

    let privacyStatus = null;
    if (!user.privacyURL) {
      privacyStatus = showStatusPrivacyMissing();
    }

    let customSubscription = null;
    if (user.custom) {
      customSubscription = showCustomSubscriptionOffer();
    }

    return (
      <div>
        { customSubscription }
        { privacyStatus }
        { statusMsg }
      </div>
    );
  };

  return showStatus();
};

export default StatusBar;
