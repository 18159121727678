import React, { useMemo, useState } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Assets
import icon from 'assets/images/icon2.png';
import screenshot from 'assets/images/beispiele.png';

// Components
import Footer from 'components/layout/Footer';
import IconGuidelines from './IconGuidelines';
import ScreenshotPage from './ScreenshotPage';

const Press = () => {
  const [page, setPage] = useState('icons');

  const showPage = (_page) => {
    setPage(_page);
  };

  const renderedActivePage = useMemo(() => {
    switch (page) {
      case 'icons': return (<IconGuidelines />);
      case 'screenshots': return (<ScreenshotPage />);
      default: return null;
    }
  }, [page]);

  /*
<div className="column has-text-centered is-2-desktop is-3-tablet is-4-mobile has-min-width-200">
              <button
                type="button"
                onClick={() => showPage('screenshots')}
                className="has-background-white has-cursor-pointer has-fullwidth has-fullheight grow has-border br10 is-flex flex-direction-column p-4 mb-4"
              >
                <img className="" src={screenshot} alt="Flowdust Screenshot" />
                <p className="has-text-weight-semibold has-margin-top-auto is-size-6">
                  Screenshots
                </p>
              </button>
            </div>
  */

  return (
    <>
      <Helmet>
        <title>Flowdust - Presse / Media Kit</title>
      </Helmet>

      <div className="has-background-white">

        <div className="mb-6">
          <p className="pt-6 has-text-weight-bold is-size-1-desktop is-size-4-tablet is-size-5-mobile has-text-centered has-text-black">
            Media Kit
          </p>

          <div className="mt-3 columns is-centered">
            <div className="column is-7">
              <p className="px-4 has-text-weight-medium has-text-grey has-text-centered has-text-left-mobile">{'Flowdust ist ein Saas-Unternehmen, das Umfragen & Formulare auf eine ganz neue Stufe bringt. Mit dem leistungsstarken Editor schnell und einfach Umfragen, A/B Tests und Formulare erstellen oder mit dem Flowter wertvolles Feedback direkt auf der Website sammeln - Flowdust hilft dabei, Unternehmen voranzubringen und Kundenloyalität & -zufriedenheit zu erhöhen. '}</p>
            </div>
          </div>

          <div className="my-6 columns is-centered is-mobile">
            <div className="column has-text-centered is-2-desktop is-3-tablet is-4-mobile has-min-width-200">
              <button
                type="button"
                onClick={() => showPage('icons')}
                className="has-background-white has-cursor-pointer has-fullwidth has-fullheight grow has-border br10 is-flex flex-direction-column p-4 mb-4"
              >
                <img className="" src={icon} alt="Flowdust Logo" />
                <p className="has-text-weight-semibold has-margin-top-auto is-size-6">
                  Flowdust Branding
                </p>
              </button>
            </div>

          </div>

          <div className="columns is-centered is-mobile m-0">
            <div className="column is-8-desktop is-10-tablet is-12-mobile">
              { renderedActivePage }
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default (Press);
