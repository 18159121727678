import React from 'react';
import plans from 'constants/plans';
import FreePricingPlan from './FreePricingPlan';
import PrimaryPlan from './PrimaryPlan';
import SecondaryPlan from './SecondaryPlan';
import PlanContent from 'components/layout/PlanContent';

/*
<div className="has-text-centered mb-4 has-text-black">
        <p
          className="is-size-4 has-text-weight-bold"
        >
          🎉 Launch-Special zum Flowdust Hub! 🎉
        </p>
        <p
          className="is-size-5 has-text-weight-semibold mt-1"
        >
          Nur für kurze Zeit: Alle Pläne um 33% reduziert!
        </p>
      </div>
*/

const PricingPage = () => (
  <div className="columns has-fullheight is-centered is-vcentered is-relative mt-3 mx-0 is-mobile">
    <div className="column is-11">
      <h2 className="has-text-weight-bold has-text-centered has-text-black is-size-2-desktop is-size-3-tablet is-size-4-mobile mb-4">
        Preise
      </h2>
      <div className="columns mb-5 is-centered is-vcentered" id="planWrapper">
        <SecondaryPlan plan={plans[1]} />
        <PrimaryPlan plan={plans[2]} />
        <SecondaryPlan plan={plans[3]} />
      </div>
      <div className="mt-6">
        <PlanContent />
      </div>
      <div className="columns is-centered mt-3">
        <FreePricingPlan />
      </div>
    </div>
  </div>
);

export default PricingPage;
