import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import { useAuth0 } from '@auth0/auth0-react';
import loader from 'assets/images/loading.svg';

/**
 * Send user to login page if he's not logged in
 * otherwise, send him to /home
 * Show loading animation while component loads
 */
function SignUp(props) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    const sendToLoginPage = async () => {
      await loginWithRedirect();
    };

    if (!isAuthenticated) {
      sendToLoginPage();
    } else {
      navigate(routes.HOME);
    }
    // eslint-disable-next-line
	}, []);

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <img className="has-text-centered" src={loader} alt="" />
          <p className="is-size-7">Laden...</p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
