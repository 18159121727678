import React from 'react';

// Components
import Banner from 'components/onboarding/Banner';
import Footer from 'components/layout/Footer';

// Styles
import './css/personalizedRedirect.scss';

// Libraries
import stats from 'analytics/analytics';
import { Helmet } from 'react-helmet-async';

// HOCs
import withoutAuthentication from 'components/hoc/withoutAuthentication';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';

// Constants
import { FLOWTER_DEMO, LANDING } from 'constants/routes';

function PersonalizedRedirect() {
  const navigate = useNavigate();
  const { id } = useParams();

  const name = id.charAt(0).toUpperCase() + id.slice(1);

  const clickLandingButton = () => {
    stats.push(['trackEvent', 'Referral Page', 'Landing button clicked']);
    navigate(LANDING);
  };

  const clickDemoButton = () => {
    stats.push(['trackEvent', 'Referral Page', 'Live demo button clicked']);
    navigate(FLOWTER_DEMO);
  };

  return (
    <p>
      yoooo
    </p>
  );

  return (
    <>
      <Helmet>
        <title>{`Flowdust - ${name}`}</title>
      </Helmet>

      <div>
        <div className="is-fullheight is-mobile columns has-background-white is-marginless is-paddingless has-fullheight is-vcentered">
          <div className="column has-text-black">
            <div className="columns is-mobile is-centered is-vcentered has-fullheight">
              <div className="column is-9-desktop is-9-tablet is-10-mobile">
                <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold is-inline mr5">{'Gefällt dir unser Flowter auf '}</h2>
                <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold has-text-pastel is-inline">{name}</h2>
                <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold is-inline">?</h2>
                <h3 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt20 mb-4 has-text-weight-semibold">Füge einen auch deiner Website hinzu!</h3>
                <button
                  type="button"
                  className="button has-text-weight-bold is-primary br10 grow is-inline-block has-fullheight py-2 has-border-pastel-light mr-2"
                  onClick={clickDemoButton}
                >
                  <i className="fas fa-play-circle mr-2 mt-1" />
                  Zum Editor und loslegen (Kein Account nötig)
                </button>
                <button
                  type="button"
                  className="button has-no-background has-text-weight-bold br10 grow is-inline-block has-text-pastel has-fullheight py-2 mr-4"
                  onClick={clickLandingButton}
                >
                  Erfahre mehr über Flowdust
                </button>

              </div>
            </div>
          </div>
          <Banner />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default withoutAuthentication(PersonalizedRedirect);
