import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';

const TypeIndicator = (props) => {
  const { type } = props;

  const typeColor = useMemo(() => {
    switch (type) {
      case 'POLL': return 'primary';
      case 'EMAIL': return 'info';
      case 'SLIDER': return 'success';
      default: return '';
    }
  }, [type]);

  const typeName = useMemo(() => {
    switch (type) {
      case 'POLL': return 'Umfrage';
      case 'EMAIL': return 'E-Mail sammeln';
      case 'SLIDER': return 'Slider';
      default: return '';
    }
  }, [type]);

  return (
    <Popup
      trigger={(
        <div>
          <i className={`is-size-7 fas fa-circle has-text-${typeColor}`} />
        </div>
      )}
      position="top center"
      on={['hover']}
      keepTooltipInside="#root"
      repositionOnResize
      className="popup-width-auto"
    >
      <div className="has-text-grey-dark py-0 px-3 has-text-centered">
        <p className="is-size-7">
          {`Fragentyp: ${typeName}`}
        </p>
      </div>
    </Popup>

  );
};

TypeIndicator.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TypeIndicator;
