import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Components
import ScriptInsertContainer from 'components/onboarding/script/ScriptInsertContainer';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

function InstallationContainer() {
  return (
    <>
      <Helmet>
        <title>Flowdust - Installation</title>
      </Helmet>
      <div className="columns m0 p0 is-centered is-vcentered is-fullheight">
        <div className="column m0 p0 is-relative">
          <ScriptInsertContainer />
        </div>
      </div>
    </>
  );
}

export default withAuthentication(InstallationContainer);
