import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Libraries
import PropTypes from 'prop-types';
import notification from 'utils/notification';
import { removeWidgetFromWebsites } from 'actions/websites';

const RemoveFromWebsitesButton = (props) => {
  const { id } = props;

  const [disabled, setDisabled] = useState(false);
  const { websites } = useSelector((state) => state.websiteState);

  const { post } = useAPI();

  const dispatch = useDispatch();

  const removeFromWebsites = async () => {
    setDisabled(true);

    try {
      await post(`/flowters/unset/${id}`);

      console.log(websites);
      console.log('Flowter id', id);

      const websiteIds = [];
      for (let i = 0; i < websites.length; i++) {
        if (!websites[i].widgets) continue;

        for (let j = 0; j < websites[i].widgets.length; j++) {
          if (websites[i].widgets[j].id === id) {
            websiteIds.push(i);
          }
        }
      }

      console.log('website indices', websiteIds);

      dispatch(removeWidgetFromWebsites(websiteIds));

      setDisabled(false);

      notification.send('Erledigt!', 'Dein Flowter ist nun auf keiner Seite mehr zu sehen!', 'success');
    } catch (err) {
      setDisabled(false);

      console.log(err);

      notification.send('Etwas hat nicht funktioniert.', 'Bitte versuche es später noch einmal.', 'danger');
    }
  };

  return (
    <div>
      <button
        type="button"
        className="button cleanButton is-hover-primary br22 has-text-centered has-fullwidth px-4"
        onClick={removeFromWebsites}
        disabled={disabled}
      >
        <i className="is-size-6 fas fa-xmark" />
        <p className="ml10 has-text-weight-semibold is-size-7">
          Von allen Webseiten entfernen
        </p>
      </button>
    </div>
  );
};

RemoveFromWebsitesButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RemoveFromWebsitesButton;
