import React, { useState, useEffect } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Constants
import * as routes from 'constants/routes';

// Assets
import statusWidgetImage from 'assets/images/statusstart.png';
import flowterImage from 'assets/images/flowterstart.png';
import flowterTemplateImage from 'assets/images/templatesstart.png';

// Reducer Actions
import { clearFlowterCreator } from 'reducer/flowterCreator';
import { clearCreator } from 'actions/creator';

// Components
import CreatorButton from './CreatorButton';

// Styles
import './styles/creatorMainHub.scss';

/*
 * Component to init the widget creation
 */
function CreatorMainHub() {
  const [widgetType, setWidgetType] = useState(-1);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    if (Number.isNaN(id)) {
      return;
    }

    if (id === '0') {
      setWidgetType(0);
    }
    // eslint-disable-next-line
	}, [])

  const clearAndGoToCreator = () => {
    dispatch(clearFlowterCreator());
    dispatch(clearCreator());
    navigate(routes.FLOWTER_CREATOR);
  };

  const BackButton = (
    <button
      type="button"
      className="button has-text-weight-bold is-primary has-border-pastel br20"
      onClick={() => setWidgetType(-1)}
    >
      Zurück
    </button>
  );

  const widgetTypeSelection = (
    <>
      <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-3-tablet is-size-5-mobile mt30 ml10 mr10">
        Erstelle ein Widget
      </h2>
      <p className="mb10 ml10 has-text-grey">Ein Widget bezeichnet eine Minianwendung, die in deine Website eingebunden wird.</p>
      <div className="columns is-mobile m0 is-centered-mobile">
        <CreatorButton
          action={() => setWidgetType(0)}
          img={flowterImage}
          topText="Flowter"
          bottomText="Kurze Umfrage mit maximal vier Fragen und jeweils bis zu fünf Antwortmöglichkeiten."
        />
        <CreatorButton
          to={routes.STATUSWIDGET_CREATOR}
          img={statusWidgetImage}
          topText="Statusmeldung"
          bottomText="Kurzer Infotext bestehend aus Überschrift und Text, sowie (optional) Link und Icon."
        />
      </div>
    </>
  );

  const flowterCreation = (
    <>
      <div className="columns mt0 mb0 ml10 mr10 is-mobile">
        <div className="column p0">
          <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile mt10">Erstelle einen Flowter</h2>
        </div>
        <div className="column p0 is-narrow has-text-right">
          { BackButton }
        </div>
      </div>
      <div className="columns m0 is-mobile is-centered-mobile">
        <CreatorButton
          to={routes.FLOWTER_TEMPLATES}
          img={flowterTemplateImage}
          topText="Aus Vorlage"
          bottomText="Wähle eine Vorlage zu verschiedenen Themen aus"
        />
        <CreatorButton
          action={clearAndGoToCreator}
          img={flowterImage}
          topText="Neuer Flowter"
          bottomText="Beginne mit einem leeren Flowter"
        />
      </div>
    </>
  );

  const showButtons = () => {
    switch (widgetType) {
      case 0:
        return flowterCreation;
      default:
        return widgetTypeSelection;
    }
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Flowter erstellen</title>
      </Helmet>
      <div className="columns is-centered is-vcentered is-fullheight">
        <div className="column is-8-desktop is-10-tablet">
          <div className="mt20 mb40">
            <div className="columns mt0 mb0 ml10 mr10 is-mobile">
              <div className="column p0">
                <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile my-5 has-text-centered-mobile">Erstelle einen Flowter</h2>
              </div>
            </div>
            <div className="columns m0 is-mobile is-centered-mobile">
              <CreatorButton
                to={routes.FLOWTER_TEMPLATES}
                img={flowterTemplateImage}
                topText="Aus Vorlage"
                bottomText="Wähle eine Vorlage zu verschiedenen Themen aus"
              />
              <CreatorButton
                action={clearAndGoToCreator}
                img={flowterImage}
                topText="Neuer Flowter"
                bottomText="Beginne mit einem leeren Flowter"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatorMainHub;
