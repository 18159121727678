import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';

const ScriptMagento = () => (
  <>
    <div className="has-text-centered">
      <i className="fab fa-magento has-text-pastel is-size-1" />
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-weight-bold">Installation für Magento</p>
    <div className="mt40">
      <i className="is-inline is-size-6 fas fa-exclamation-triangle has-text-weight-bold has-text-danger mr10" />
      <p className="is-inline is-size-6 has-text-weight-bold has-text-black">Flowdust unterstützt nur Magento 2.3.1 oder Neuer!</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Im Admin Menü, wähle "Content" und dann "Configuration" aus</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Bearbeiten" in der "Action"-Zeile für die Seite, auf der Du Flowdust-Widgets anzeigen lassen möchtest</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke, um die "HTML Body" Sektion zu vergrößern</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere diesen HTML-Code:</p>
    </div>
    <ScriptTagCopy />
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'5. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Füge den kopierten Code in die "Scripts and Style Sheets"-Box ein</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'6. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Save Configuration"</p>
    </div>
    <div className="mt-2 mb-5">
      <i className="is-inline fas fa-exclamation-triangle has-text-weight-bold has-text-danger mr10" />
      <p className="is-inline has-text-weight-medium has-text-black">Bitte stelle sicher, dass Flowdust noch installiert ist, wenn deine Website eine größere Design-Änderung unternommen hat</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);
export default ScriptMagento;
