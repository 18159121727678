import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';

// Styles
import styles from './styles.module.scss';

function InfoPopup(props) {
  const { text } = props;

  return (
    <div className="is-inline-block has-info">
      <Popup
        trigger={(
          <button
            className="cleanButton is-relative"
            type="button"
          >
            <i
              id={styles.icon}
              className="fas fa-question-circle has-text-grey is-size-7 has-hover-icon"
            />

          </button>
        )}
        position="top center"
        on={['click', 'hover']}
        keepTooltipInside="#root"
        repositionOnResize
        className=""
      >
        {() => (
          <div className="columns is-marginless">
            <div className="column is-marginless is-paddingless p-3 has-text-grey has-background-white br22">
              <p className="is-size-7">
                {text}
              </p>
            </div>
          </div>
        )}
      </Popup>
    </div>

  );
}

InfoPopup.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoPopup;
