import React, { Fragment } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Components
import Popup from 'reactjs-popup';
import { SketchPicker } from 'react-color';
import InfoPopup from 'components/utils/InfoPopup';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setColors } from 'reducer/hubs';

const ColorSelector = () => {
  const { creator } = useSelector((state) => state.hubs);
  const dispatch = useDispatch();

  const { colors } = creator;

  const setPrimaryColor = (color) => {
    dispatch(setColors([color.hex, colors[1]]));
  };

  const setSecondaryColor = (color) => {
    dispatch(setColors([colors[0], color.hex]));
  };

  const sendAnalytics = (index) => {
    stats.push(['trackEvent', 'Hub Creator - Color', `Color ${index} selected`]);
  };

  /*
<div className="column is-narrow">
          <div className="field">
            <label className="label">
              Sekundärfarbe
            </label>
            <div className="control has-text-centered is-static">
              <Popup
                trigger={(
                  <button
                    type="button"
                    className="cleanButton"
                  >
                    <div
                      className="has-height-30 has-width-30 br100 has-border-white-bold boxshadow"
                      style={{ backgroundColor: colors[1] }}
                    />
                  </button>
               )}
                position="top center"
                on={['click']}
                keepTooltipInside="#root"
                closeOnDocumentClick
                repositionOnResize
                className=""
                contentStyle={{
                  width: '1px !important', overflow: 'visible !important', padding: '0 0 5px 0 !important',
                }}
              >
                {
                     () => (
                       <SketchPicker
                         color={colors[1]}
                         onChange={setSecondaryColor}
                         onChangeComplete={() => sendAnalytics(1)}
                       />
                     )
               }
              </Popup>
            </div>
          </div>
        </div>
  */

  return (
    <>
      <div className="columns is-centered m-0">
        <div className="column is-narrow">
          <div className="field has-text-centered-mobile">
            <label className="is-inline label">
              Farbe
            </label>
            <InfoPopup
              text="Hier kannst du die Akzentfarbe deines Hubs bestimmen."
            />
            <div className="control has-text-centered is-static">
              <Popup
                trigger={(
                  <button
                    type="button"
                    className="cleanButton"
                  >
                    <div
                      className="has-height-30 has-width-30 br100 has-border-white-bold boxshadow"
                      style={{ backgroundColor: colors[0] }}
                    />
                  </button>
               )}
                position="top center"
                on={['click']}
                keepTooltipInside="#root"
                closeOnDocumentClick
                repositionOnResize
                className="fixed-popup"
                contentStyle={{
                  width: '1px !important', overflow: 'visible !important', padding: '0 0 5px 0 !important',
                }}
              >
                {
                     () => (
                       <SketchPicker
                         color={colors[0]}
                         onChange={setPrimaryColor}
                         onChangeComplete={() => sendAnalytics(0)}
                         className="p-4"
                       />
                     )
               }
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorSelector;
