import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import PrivacyURLSettings from './PrivacyURLSettings';
import ParameterSettings from './ParameterSettings';

const Settings = (props) => {
  const {
    customPrivacyURL, setCustomPrivacyURL, rules, setRules,
  } = props;

  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    if (customPrivacyURL || rules.length > 0) {
      setShowSettings(true);
    }
  }, [customPrivacyURL, rules]);

  const toggleSettings = () => {
    stats.push(['trackEvent', 'Website Creator - Advanced Options', `Toggle settings ${showSettings ? 'ON' : 'OFF'}`]);
    setShowSettings(!showSettings);
  };

  return (
    <div className="br10 has-text-black my-3">
      <button
        className="cleanButton has-text-left has-text-grey has-cursor-pointer mb-2 mx-0 px-0"
        type="button"
        onClick={toggleSettings}
      >
        <i className="is-inline fas fa-cog mr5" />
        <p className="is-inline mr5">Erweiterte Einstellungen</p>
        <i className={`is-inline is-size-6 ${showSettings ? 'fas fa-angle-up' : 'fas fa-angle-down'}`} />
      </button>
      {
        showSettings
        && (
          <div className="px-2 py-2">
            <ParameterSettings
              rules={rules}
              setRules={setRules}
            />
            <PrivacyURLSettings
              url={customPrivacyURL}
              setURL={setCustomPrivacyURL}
            />
          </div>

        )
      }
    </div>
  );
};

Settings.propTypes = {
  customPrivacyURL: PropTypes.string.isRequired,
  setCustomPrivacyURL: PropTypes.func.isRequired,
  rules: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRules: PropTypes.func.isRequired,
};

export default Settings;
