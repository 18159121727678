import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Popup from 'reactjs-popup';
import ElementCategory from './ElementCategory';

const NestedSelector = (props) => {
  const {
    selectedItems,
    allItems,
    setSelection,
    name,
  } = props;

  /**
   * Calculates how many items have been selected in general
   */
  const amountSelected = useMemo(() => {
    let amount = 0;

    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].values.length !== 0) {
        amount += selectedItems[i].values.length;
      }
    }

    return amount;
  }, [selectedItems]);

  /*
   * Selects an element by adding or updating one element
   * to the currentValues array
   */
  const selectElement = (category, item) => {
    // deep copy array
    const newSelectedElements = JSON.parse(JSON.stringify(selectedItems));

    // get the category that has been clicked
    const categoryIndex = newSelectedElements.findIndex((element) => element.label === category);

    // add category if it does not yet exist
    if (categoryIndex === -1) {
      const index = allItems.findIndex((_item) => _item.label === category);

      if (index === -1) return;

      // if we have a item, add the category only with that item
      if (item) {
        newSelectedElements.push({
          label: category,
          values: [item],
        });
        // if no item is set, add the category with all its values
      } else {
        newSelectedElements.push({
          label: category,
          values: allItems[index].values,
        });
      }

      setSelection(newSelectedElements);
      return;
    }

    // if there is a category, and we dont want to select / deselect an item, deselect the whole category
    if (!item) {
      newSelectedElements.splice(categoryIndex, 1);
      setSelection(newSelectedElements);
      return;
    }

    // now, if we have a category and we have an item, select / deselect that item
    const clickedCategory = newSelectedElements[categoryIndex];
    // get the item
    const itemIndex = clickedCategory.values.findIndex((element) => element.value === item.value);

    // the item has no yet been selected, so we select it now
    if (itemIndex === -1) {
      clickedCategory.values.push(item);
      setSelection(newSelectedElements);
      return;
    }

    // the item has already been selected, so deselect it
    // if the item is the last one, deselect the whole category
    if (clickedCategory.values.length === 1) {
      newSelectedElements.splice(categoryIndex, 1);
    } else {
      // otherwise just deselect the item
      clickedCategory.values.splice(itemIndex, 1);
    }

    setSelection(newSelectedElements);
  };

  const renderedCategories = useMemo(() => {
    if (allItems.length === 0) {
      return (
        <div className="has-text-centered px-2">
          <p>Das waren alle!</p>
        </div>
      );
    }

    return allItems.map((category, index) => {
      const {
        label, values,
      } = category;

      const selectedCategory = selectedItems.find((item) => item.label === label);
      const selectedValues = selectedCategory ? selectedCategory.values : [];

      return (
        <ElementCategory
          values={values}
          name={label}
          selectElement={selectElement}
          selectedValues={selectedValues}
          key={index}
        />
      );
    });
  }, [selectedItems]);

  return (
    <Popup
      trigger={(
        <div
          className={`input br10 has-border-grey-light-thin dont-select-text is-flex ${amountSelected === 0 ? 'has-text-grey' : 'has-text-grey-dark'}`}
        >
          <p
            className="has-fullwidth"
            style={{ borderRight: '1px solid #cccccc' }}
          >
            {
            amountSelected === 0
              ? `Alle ${name}`
              : `${amountSelected} ausgewählt`
          }
          </p>
          <i className="fas fa-chevron-down has-margin-left-auto" style={{ color: '#cccccc', paddingLeft: '10px' }} />
        </div>
      )}
      position="bottom center"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
      className="popup-empty"
    >
      <div className="has-width-280 has-max-height-300 has-overflow-y-auto has-background-white p-1 has-border br5">
        { renderedCategories }
      </div>
    </Popup>
  );
};

NestedSelector.propTypes = {
  /*
  * The currently selected values. They get mapped to the options props
  * to see which ones are checked
  */
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  })).isRequired,
  /*
  * All the options available. Options can also have one nested array of options
  */
  allItems: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  })).isRequired,
  /*
    The name of the selection
  */
  name: PropTypes.string.isRequired,
  /*
    Function that gets called after an items gets selected
  */
  setSelection: PropTypes.func.isRequired,
};

export default NestedSelector;
