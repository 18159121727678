import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Slider from 'rc-slider';
import TextInput from '../../TextInput';

// Styles
import styles from './styles.module.scss';

function SliderQuestion(props) {
  const { index, saveAnswer } = props;

  const { t } = useTranslation(['hub']);

  const [text, setText] = useState('');
  const [value, setValue] = useState(25);

  const [hasSetSlider, setHasSetSlider] = useState(false);

  const { colors, content } = useSelector((state) => state.hubs.creator);
  const { questions } = content;
  const question = questions[index];

  if (!question) return null;

  const {
    allowText,
  } = question;

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const _saveAnswer = () => {
    saveAnswer([value], text);
  };

  const getColor = (_value) => {
    // value from 0 to 1
    const hue = (((_value / 100)) * 120).toString(10);
    return ['hsl(', hue, ',100%,70%)'].join('');
  };

  return (
    <div className="">
      <div className="has-max-width-300 is-in-center">
        <p
          className="has-text-grey-dark has-text-weight-bold is-size-6 has-text-centered mb-3"
        >
          {`${value}%`}
        </p>
        <Slider
          className="mb-5"
          value={value}
          onChange={setValue}
          onChangeComplete={() => setHasSetSlider(true)}
          trackStyle={[{ height: '13px', backgroundColor: getColor(value) }]}
          railStyle={{ height: '13px' }}
          handle={(handleProps) => (
            <div {...handleProps} dragging={undefined} className={styles.handle} />
          )}
        />
      </div>
      {
        allowText && hasSetSlider
          && (
          <div
            className="mt-6"
          >
            <p
              className="has-text-grey is-size-7 has-text-left nmb10 mx-1"
            >
              {t('hub:optional_answer')}
            </p>
            <TextInput
              text={text}
              setText={setText}
              showSubmitButton={false}
              color={colors[0]}
            />
          </div>
          )
      }
      <button
        type="button"
        className="button is-block is-in-center has-text-white has-text-weight-bold mt-4 mb-3 grow"
        onClick={_saveAnswer}
        disabled={!hasSetSlider}
        style={{ backgroundColor: colors[0] }}
      >
        {t('hub:continue')}
      </button>
    </div>
  );
}

SliderQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  saveAnswer: PropTypes.func.isRequired,
};

export default SliderQuestion;
