import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Constants
import { HUB_QUESTION_TYPES } from 'constants/hub';

// Actions
import { setQuestionType } from 'reducer/hubs';

// Components
import Dropdown from 'components/utils/Dropdown';

const TypeEditor = (props) => {
  const { id } = props;

  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.hubs.creator.content);
  const { type } = questions[id];

  const _setType = ({ value }) => {
    dispatch(setQuestionType({ id, type: value }));
  };

  // Prepare all question to be displayed
  const labels = useMemo(() => {
    const _labels = [];

    for (let i = 0; i < HUB_QUESTION_TYPES.length; i++) {
      const _type = HUB_QUESTION_TYPES[i];
      let label = '';

      switch (_type) {
        case 'POLL': label = 'Umfrage';
          break;
        case 'EMAIL': label = 'E-Mail sammeln';
          break;
        case 'SLIDER': label = 'Slider';
          break;
        default: continue;
      }

      _labels.push({
        value: _type,
        label,
      });
    }

    return _labels;
  }, []);

  const value = useMemo(() => labels.find((label) => label.value === type), [type]);

  return (
    <div className="has-min-width-200">
      <Dropdown
        value={value}
        labels={labels}
        onChange={_setType}
      />
    </div>
  );
};

TypeEditor.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TypeEditor;
