export const HUB_MAX_URL_LENGTH = 64;
export const HUB_MAX_NAME_LENGTH = 64;
export const HUB_MAX_DESC_LENGTH = 250;

export const HUB_LANGUAGES = [{ value: 'de', label: 'Deutsch' }, { value: 'en', label: 'Englisch' }];

// Endings
export const HUB_MAX_ENDINGS = 30;

export const HUB_MAX_ENDING_LINKS = 30;
export const HUB_MAX_ENDING_TEXT_LENGTH = 1400;
export const HUB_MAX_ENDING_LINK_URL_LENGTH = 2560;
export const HUB_ENDING_LINK_TYPES = ['LINK', 'GOOGLE', 'FB', 'TWITTER'];

// Info
export const HUB_MAX_INFO_LENGTH = 2560;

// Questions
export const HUB_MAX_QUESTIONS = 30;
export const HUB_MAX_QUESTION_LENGTH = 1400;
export const HUB_QUESTION_TYPES = ['POLL', 'EMAIL', 'SLIDER'];

// Options
export const HUB_MAX_OPTIONS = 30;
export const HUB_MAX_OPTION_LENGTH = 1400;

// LOGIC
export const HUB_MAX_LOGIC = 50;
export const HUB_MAX_CONDITIONS_PER_LOGIC = 50;

export const HUB_RESPONSE_TEXT_MAX_LENGTH = 1400;
