import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const PollResponse = ({ text, color }) => (
  <div>
    <i
      className="fas fa-square is-size-7 is-inline mr-1"
      style={{ color }}
    />
    <p className="is-inline break-word">
      {text}
    </p>
  </div>

);

PollResponse.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  media: PropTypes.shape({}).isRequired,
};

export default PollResponse;
