import React, { useState, useEffect } from 'react';

// Libraries
import isURL from 'validator/lib/isURL';
import stats from 'analytics/analytics';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Settings from './Settings';

// Styles
import './css/websiteForm.scss';

// Constants
import * as limits from 'constants/websites';

/*
 * Form to create or edit a website
 * Use it with a wrapper component
 */
function WebsiteForm(props) {
  const {
    data, disabled, saveButtonText, setError, saveForm,
  } = props;

  const {
    name, url, rules: websiteRules, privacyURL,
  } = data;

  const [websiteName, setWebsiteName] = useState(name);
  const [websiteUrl, setWebsiteUrl] = useState(url);
  const [rules, setRules] = useState(websiteRules);
  const [newRule, setNewRule] = useState('');
  const [customPrivacyURL, setCustomPrivacyURL] = useState(privacyURL);

  const { websites } = useSelector((state) => state.websiteState);

  useEffect(() => {
    setWebsiteName(name || '');
    setWebsiteUrl(url || '');
    setCustomPrivacyURL(privacyURL || '');

    // for some yet unknown reason, rules will stay in state even though the component unmounted
    // on the next mount, rules will still be there even though it should be the defaultProp
    // so check if other data is new, if it is, set the default prop manually
    if (name.length === 0) {
      setRules([]);
    } else {
      setRules(websiteRules || []);
    }
  }, [data]);

  useEffect(() => () => {
    setRules([]);
  }, []);

  const hasDuplicate = (name, url, rules) => {
    for (let i = 0; i < websites.length; i++) {
      const website = websites[i];

      if (i === data.id) {
        continue;
      }

      if (website.name === name) {
        setError('Der Name ist bereits vorhanden.');
        return true;
      }

      // only check rules if url is the same as new website
      if (website.url !== url) {
        continue;
      }

      if (!website.rules) {
        website.rules = [];
      }

      if (website.rules.length !== rules.length) {
        continue;
      }

      // count the amount how many rules are the same
      let amountSameRules = 0;
      website.rules.forEach((websiteRule) => {
        // if the given rule also exists in the new website
        if (rules.indexOf(websiteRule) !== -1) {
          amountSameRules++;
        }
      });

      // Url is the same and we dont have any params, so throw different error
      if (rules.length === 0) {
        setError('Diese URL ist bereits vorhanden.');
        return true;
      }

      // If all the rules are the same, throw error
      if (amountSameRules === rules.length) {
        setError('URL mit diesen Parametern ist bereits vorhanden.');
        return true;
      }
    }

    return false;
  };

  /**
   * Validates the input for the website name and url
   *
   * @params {String} websiteUrl: the website url WITHOUT protocols like www or https,
   * @returns {boolean} true if the form is valid, false if not
   */
  const isFormValid = (urlToCheck) => {
    setError('');

    if (!isURL(urlToCheck)) {
      setError('Vertippt? Bitte gib eine korrekte URL ein.');
      return false;
    }

    if (!urlToCheck.match(/^[\/:a-zA-Z0-9?.=_-]+$/g)) {
      setError('Bitte gib eine korrekte URL ein!');
      return false;
    }

    if (websiteName.length === 0) {
      setError('Jeder braucht einen Namen! Gib deiner Website bitte auch einen.');
      return false;
    }

    if (!customPrivacyURL) {
      return true;
    }

    if (!isURL(customPrivacyURL)) {
      setError('Vertippt? Bitte gib eine korrekte Datenschutz-URL ein.');
      return false;
    }

    if (!customPrivacyURL.match(/^[\/:a-zA-Z0-9?.=_-]+$/g)) {
      setError('Bitte gib eine korrekte Datenschutz-URL ein!');
      return false;
    }

    return true;
  };

  const setWebsiteNameToState = (nameToCheck) => {
    if (nameToCheck.length >= limits.NAME_MAX_LENGTH) {
      // Matomo
      stats.push(['trackEvent', 'Website Creator', 'Name max length reached']);

      setError('Wähle bitte einen kürzeren Namen.');
    } else {
      setWebsiteName(nameToCheck);
      setError('');
    }
  };

  const setWebsiteUrlToState = (urlToCheck) => {
    if (urlToCheck.length >= limits.URL_MAX_LENGTH) {
      // Matomo
      stats.push(['trackEvent', 'Website Creator', 'Url max length reached']);

      setError('Wähle bitte eine kürzere URL.');
      return;
    }

    // if user pasted a url
    // (+5 because you need to have at least http:// + the url)
    if (urlToCheck.length > websiteUrl.length + 7) {
      urlToCheck = urlToCheck.replace(/^https?\:\/\//i, '');
    }

    setWebsiteUrl(urlToCheck);
    setError('');
  };

  const saveWebsite = () => {
    // if there currently is something in the input field, add it as parameter if it does not exist yet
    if (newRule.length > 0 && !rules.includes(newRule)) {
      setNewRule('');
      rules.push(newRule);
    }

    // Remove all protocols
    const prefix = /(^\w+:|^)\/\/?(?:w+\.)?/;
    let noProtocolUrl = websiteUrl.replace(prefix, '');

    // only add a trailing slash if there are no parameters
    if (noProtocolUrl.charAt(noProtocolUrl.length - 1) !== '/' && noProtocolUrl.indexOf('?') === -1) {
      noProtocolUrl += '/';
    }

    if (!isFormValid(noProtocolUrl)) {
      return;
    }

    if (hasDuplicate(websiteName, noProtocolUrl, rules)) {
      return;
    }

    if (customPrivacyURL) {
      stats.push(['trackEvent', 'Website Creator', 'Added custom privacy url']);
    }

    const websiteData = {
      name: websiteName,
      url: noProtocolUrl,
      privacyURL: customPrivacyURL,
      rules,
    };

    saveForm(websiteData);
  };

  return (
    <div>
      <div className="columns is-centered" id="websiteForm">
        <div className="column">
          <div className="has-info">
            <div className="has-text-left">
              <p className="is-size-6 has-text-weight-bold has-text-grey has-background-white numberCircle">1</p>
              <p className=" is-inline is-size-6 has-text-weight-semibold has-no-hyphens has-text-black">Gib die URL deiner Website an, auf der dein Widget erscheinen soll</p>
              <div className="mt20">
                <div className="input p0 has-text-grey has-text-centered is-medium websiteInput" id="websiteUrl">
                  <div className="pl-1 pr-0 py-1">
                    <p className="has-text-midnightblue has-text-weight-semibold">https://</p>
                  </div>
                  <div>
                    <input
                      onChange={(event) => setWebsiteUrlToState(event.target.value)}
                      type="text"
                      value={websiteUrl}
                      placeholder="Website URL"
                      className="has-no-background has-no-border has-text-grey-dark"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-left mt50">
              <p className="is-size-6 has-text-weight-bold has-text-grey has-background-white numberCircle">2</p>
              <p className="is-inline is-size-6 has-text-weight-semibold has-text-black">Gib einen Namen ein</p>
              <Popup
                trigger={(
                  <button
                    className="cleanButton is-inline"
                    type="button"
                  >
                    <i className="fas fa-question-circle has-text-grey is-size-7 has-hover-icon" />
                  </button>
                )}
                position="top center"
                on={['click', 'hover']}
                keepTooltipInside="#root"
                repositionOnResize
                className="mt10"
                contentStyle={{ background: '#fff' }}
              >
                {() => (
                  <div className="columns is-marginless">
                    <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey">
                      <p className="is-size-7">Du kannst verschiedene Webseiten hinzufügen. Um diese voneinander zu unterscheiden, wird ein Name benötigt. Dieser ist nur für dich im Dashboard sichtbar.</p>
                    </div>
                  </div>
                )}
              </Popup>
              <div className="mt20">
                <input
                  onChange={(event) => setWebsiteNameToState(event.target.value)}
                  type="text"
                  value={websiteName}
                  placeholder="Name der Website"
                  className="input has-text-grey-dark websiteInput"
                  id="websiteName"
                />
              </div>
            </div>
            <Settings
              customPrivacyURL={customPrivacyURL}
              setCustomPrivacyURL={setCustomPrivacyURL}
              rules={rules}
              setRules={setRules}
            />
          </div>
        </div>
      </div>
      <div>
        <button
          className="button is-rounded has-background-pastel has-text-white has-text-weight-bold has-hover-pastel grow mb20 has-white-space"
          onClick={(e) => saveWebsite(e)}
          type="button"
          disabled={disabled}
        >
          { saveButtonText }
        </button>
      </div>
    </div>
  );
}

WebsiteForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    privacyURL: PropTypes.string,
  }),

  saveButtonText: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  saveForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

WebsiteForm.defaultProps = {
  data: {
    name: '',
    url: '',
    rules: [],
    id: -1,
    privacyURL: '',
  },
};

export default WebsiteForm;
