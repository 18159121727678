import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addLogicCondition, deleteLogicCondition, setLogicCondition } from 'reducer/hubs';

// Components
import Condition from './Condition';

// Constants
import { HUB_MAX_CONDITIONS_PER_LOGIC } from 'constants/hub';

const ConditionEditor = (props) => {
  const { logic } = useSelector((state) => state.hubs.creator.content);
  const dispatch = useDispatch();

  const { logicIndex } = props;

  const { conditions } = logic[logicIndex];

  const deleteCondition = (index) => {
    dispatch(deleteLogicCondition({
      logicIndex,
      conditionIndex: index,
    }));
  };

  const addCondition = () => {
    dispatch(addLogicCondition(logicIndex));
  };

  const setCondition = (_index, _condition) => {
    dispatch(setLogicCondition({
      logicIndex,
      conditionIndex: _index,
      condition: _condition,
    }));
  };

  const showConditions = () => conditions.map((condition, _index) => (
    <div key={_index}>
      <p className="is-size-7">
        {
          _index === 0 ? 'Wenn bei' : 'und wenn bei'
        }
      </p>
      <div>
        <p className="is-inline is-size-7 mr-2 has-text-weight-bold">
          {`${_index + 1}.`}
        </p>
        {
          _index > 0
          && (
            <button
              type="button"
              onClick={() => deleteCondition(_index)}
              className="cleanButton has-text-danger has-text-underlined"
            >
              Löschen
            </button>
          )
        }
      </div>
      <Condition
        condition={condition}
        setCondition={(_condition) => setCondition(_index, _condition)}
      />
    </div>
  ));

  return (
    <div className="">
      { showConditions() }
      {
        conditions.length < HUB_MAX_CONDITIONS_PER_LOGIC
        && (
        <div className="">
          <button
            type="button"
            onClick={addCondition}
            className="my-5 is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
          >
            <i className="fa fa-circle has-text-pastel is-size-7 mr-2" />
            <p className="is-size-7 has-text-weight-bold is-inline">
              Weitere Bedingung hinzufügen
            </p>
          </button>
        </div>
        )
      }

    </div>
  );
};

ConditionEditor.propTypes = {
  logicIndex: PropTypes.number.isRequired,
};

export default ConditionEditor;
