import React, { useEffect, useRef, useState } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { clearCreator } from 'reducer/hubs';

// HOCs
import withHubData from 'components/hoc/withHubData';

// Components
import HubNavbar from '../HubNavbar';
import NavigationBar from './NavigationBar';
import InfoEditor from './InfoEditor';
import QuestionEditor from './QuestionEditor';
import EndingEditor from './EndingEditor';
import LogicEditor from './LogicEditor';
import HubPreview from './HubPreview';

// Styles
import SaveButton from '../HubNavbar/SaveButton';

const NAVBAR_HEIGHT = 70;

function HubContentEditor() {
  const {
    creator,
  } = useSelector((state) => state.hubs);

  useEffect(() => {
    // const rootStyle = getComputedStyle(document.documentElement);
    // setMaxNavbarHeight(rootStyle.getPropertyValue('--max-navbar-height'));
  }, []);

  const dispatch = useDispatch();

  const contentRef = useRef();

  const { activeContent } = creator.content;

  const { type, index } = activeContent;

  useEffect(() => () => {
    // dispatch(clearCreator());
  }, []);

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [type, index]);

  const showActiveEditor = () => {
    switch (type) {
      case 'INFO':
        return <InfoEditor />;
      case 'QUESTION':
        return <QuestionEditor />;
      case 'ENDING':
        return <EndingEditor />;
      case 'LOGIC':
        return <LogicEditor />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Hub Inhalt bearbeiten</title>
      </Helmet>
      <div className="is-fullheight is-max-fullheight is-flex flex-direction-column">
        <HubNavbar />

        <div className="is-hidden-tablet is-hidden-desktop p-5 my-4 has-text-centered has-text-weight-bold">
          <p
            className="is-size-7"
          >
            Scrolle ganz nach unten, um die Vorschau zu sehen!
          </p>
        </div>

        <div
          className="columns is-marginless is-paddingless is-mobile"
          style={{ height: `calc(100% - ${70}px)` }}
        >
          <div
            className="column is-1-desktop is-1-tablet p-0 has-overflow-y-auto has-max-width-80-desktop has-min-width-100-tablet is-12-mobile flex-grow has-max-fullheight"
          >
            <div
              className="has-background-white has-border-right-grey-light px-2 has-min-fullheight"
            >
              <NavigationBar />
            </div>
          </div>
          <div
            className="column is-4-desktop is-4-tablet p-0 has-overflow-y-auto has-overflow-y-hidden-mobile has-min-width-300 has-max-width-400-desktop is-12-mobile flex-grow has-max-fullheight"
            ref={contentRef}
          >
            <div
              className="has-background-white px-4 py-5 has-min-fullheight"
            >
              {showActiveEditor()}
            </div>
          </div>
          <div className="column has-width-10 has-flex-1 p-0 flex-grow has-max-fullheight">
            <HubPreview />
          </div>
        </div>

        <div className="is-hidden-tablet is-hidden-desktop has-text-centered mb-6">
          <SaveButton />
        </div>
      </div>
    </>
  );
}

export default withHubData(HubContentEditor);
