import React from 'react';

// Components
import Popup from 'reactjs-popup';

const InactiveHubPopup = () => {
  const x = 5;
  return (
    <Popup
      trigger={(
        <i className="cleanButton fas fa-circle-exclamation has-text-danger has-cursor-pointer" />
      )}
      position="top center"
      on={['click', 'hover']}
      keepTooltipInside="#root"
      repositionOnResize
    >
      { () => (
        <div className="br10 is-size-7 has-text box pt-4">
          <p className="has-text-weight-bold mb-3">
            Dieser Hub ist momentan inaktiv.
          </p>
          <p>
            Du kannst ihn bearbeiten, aber er ist nicht für Nutzer verfügbar und niemand kann abstimmen.
          </p>
          <p className="mt-3">
            Setze ihn im Hub-Profil wieder aktiv, wenn du weiter Ergebnisse sammeln willst!
          </p>
        </div>
      )}
    </Popup>
  );
};

export default InactiveHubPopup;
