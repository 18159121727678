import React from 'react';

const PlanContent = () => (
  <div className="ml20 columns is-marginless is-vcentered has-text-black has-text-weight-bold mb-6 mt-3">
    <div className="column is-3-desktop is-10-tablet">
      <p>Alle Pläne beinhalten:</p>
    </div>
    <div className="column is-4-desktop is-10-tablet">
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Priorisierter Support</p>
      </div>
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Ergebnisse & Statistiken - sogar exportierbar</p>
      </div>
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Kein Flowdust Branding</p>
      </div>
    </div>
    <div className="column is-4-desktop is-10-tablet">
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Mehr Hubs</p>
      </div>
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Mehr Flowter Impressionen</p>
      </div>
      <div className="is-flex align-items-center">
        <i className="fas has-text-success fa-check-circle mr10" />
        <p className="">Unbegrenzte Anzahl an Abstimmungen (Hub und Flowter)</p>
      </div>
    </div>
  </div>
);

export default PlanContent;
