import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as routes from 'constants/routes';
import dropin from 'braintree-web-drop-in';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import loading from 'assets/images/loading.svg';
import plans from 'constants/plans';
import { TRIAL } from 'constants/status';
import stats from 'analytics/analytics';
import { setUser } from 'actions/user';
import notification from 'utils/notification';
import { addVAT } from 'utils/calculateVAT';
import PricingPlan from './PricingPlan';

function PaymentPage(props) {
  const [signal] = useState(axios.CancelToken.source());
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [dropInInstance, setDropInInstance] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { plan } = props;
  const planObject = plans[plan];

  const { user } = useSelector((state) => state.sessionState);

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = user.customer;
    let instance;

    const createDropIn = async () => {
      try {
        const authToken = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          cancelToken: signal.token,
        };

        let token;

        if (user.status !== TRIAL) {
          // we already have data for this customer, so only get the payment token
          token = await axios.get(`${process.env.REACT_APP_API}/payments/token`, config);
        } else {
          // for a new customer, create a customer object in braintree and get payment token
          token = await axios.post(`${process.env.REACT_APP_API}/payments/token`, data, config);
        }

        instance = await dropin.create({
          authorization: token.data,
          container: '#dropInContainer',
          locale: 'de_DE',
          paypal: {
            flow: 'vault',
            currency: 'EUR',
          },
          dataCollector: {
            paypal: true,
          },
          threeDSecure: true,
        });

        setDropInInstance(instance);
        setIsLoading(false);

        instance.on('paymentMethodRequestable', (e) => {
          setDisabled(false);
        });

        instance.on('noPaymentMethodRequestable', () => {
          setDisabled(true);
        });

        instance.on('paymentOptionSelected', () => {
          setError('');
        });

        instance.clearSelectedPaymentMethod();
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        console.log(err);

        if (err.response) {
          setError(err.response.data);
          console.log(err.response);
        }
      }
    };

    createDropIn();

    return () => {
      if (instance) {
        instance.teardown();
      }

      signal.cancel();
    };
    // eslint-disable-next-line
	}, [])

  const replaceUmlauts = (string) => {
    string = string.replace(/ä/g, 'ae');
    string = string.replace(/Ä/g, 'Ae');
    string = string.replace(/ö/g, 'oe');
    string = string.replace(/Ö/g, 'Oe');
    string = string.replace(/ü/g, 'ue');
    string = string.replace(/Ü/g, 'Ue');
    return string;
  };

  const startSubscription = async () => {
    if (!dropInInstance) {
      return;
    }

    setDisabled(true);
    setIsLoading(true);
    setError('');

    let amount = addVAT(planObject.cost);

    if (user.custom) {
      amount = addVAT(user.custom.cost);
    }

    amount += '';

    const { customer } = user;

    const threeDSecureParameters = {
      amount,
      email: user.email,
      billingAddress: {
        givenName: replaceUmlauts(customer.firstName),
        surname: replaceUmlauts(customer.lastName),
        streetAddress: replaceUmlauts(customer.addressData.streetAddress),
        locality: replaceUmlauts(customer.addressData.locality),
        postalCode: customer.addressData.postalCode,
        countryCodeAlpha2: 'DE',
      },
    };

    try {
      const payload = await dropInInstance.requestPaymentMethod(
        { threeDSecure: threeDSecureParameters },
      );

      /*
      if (payload.liabilityShifted === false) {
        setError('Zahlung mit dieser Karte nicht möglich. Bitte wähle eine andere Zahlungsart.');
        setIsLoading(false);
        return;
      }
*/

      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      const data = {
        nonce: payload.nonce,
        deviceData: payload.deviceData,
        email: user.email,
        plan,
      };

      // Start subscription
      const response = await axios.post(`${process.env.REACT_APP_API}/subscription`, data, config);

      stats.push(['trackEvent', 'Subscription', 'Started', 'subscriptionStarted']);

      const subscription = response.data;

      dispatch(setUser(subscription));

      notification.send('Erledigt!', 'Dein Abonnement wurde erfolgreich abgeschlossen.', 'success');

      navigate(routes.HOME);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      stats.push(['trackEvent', 'Subscription', 'Error']);

      console.log(err);

      dropInInstance.clearSelectedPaymentMethod();

      setDisabled(false);
      setIsLoading(false);

      if (err.response) {
        setError(err.response.data);
      } else {
        setError('Konnte Abonnement nicht starten.');
      }
    }
  };

  return (
    <div className="columns is-marginless is-centered mt-6 pb-6">
      <div className="column is-11">
        <h2 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-black has-text-weight-bold">Zahlung</h2>
        <p className="has-text-black">Wähle die Zahlmethode aus, mit der Du bezahlen möchtest.</p>
        <div className="columns is-marginless is-centered mt-6">
          <div className="column is-4-desktop is-8-tablet has-text-centered has-min-width p0">
            <h3
              className="is-size-4-desktop is-size-5-touch has-text-black has-text-weight-bold"
            >
              Ausgewählter Plan:
            </h3>
            <div className="is-in-center is-inline-block mt-3">
              <PricingPlan plan={plans[plan]} showButton={false} />
            </div>
          </div>
          <div className="column is-offset-1-desktop is-6-desktop has-min-width">
            <div className="box boxshadow p20">
              <p className="has-text-black has-text-weight-bold is-size-5">
                Wähle deine Bezahlmethode:
              </p>
              {
                isLoading
                && (
                <div className="has-text-centered">
                  <img src={loading} alt="" />
                </div>
                )
              }
              <div id="dropInContainer" />
            </div>
            <div className="has-text-centered">
              <button
                className="button is-pastel is-rounded has-text-weight-bold grow"
                type="button"
                disabled={disabled || isLoading}
                onClick={startSubscription}
              >
                Abonnement kostenpflichtig starten
              </button>
              <p className="has-text-danger has-text-centered has-text-weight-semibold mt-3">{error}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

PaymentPage.propTypes = {
  plan: PropTypes.number.isRequired,
};

export default PaymentPage;
