import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setName } from 'reducer/hubs';

// Constants
import { HUB_MAX_NAME_LENGTH } from 'constants/hub';

// Components
import InfoPopup from 'components/utils/InfoPopup';

/*
 * Component that handles the name input for the HubCreator
 */
const NameCreator = () => {
  const { creator } = useSelector((state) => state.hubs);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const { name } = creator;

  /*
   * Validates and sets the name to state
   */
  const _setName = (_name) => {
    setError('');

    // check max length
    if (_name.length >= HUB_MAX_NAME_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    dispatch(setName(_name));
  };

  return (
    <div className="field">
      <label className="label is-inline">
        Hub Name
      </label>
      <label className="mx-2 has-text-grey label is-inline has-text-weight-medium">
        (Optional)
      </label>
      <InfoPopup
        text="Du kannst deinem Hub einen Namen geben. Dieser wird im Header deines Hubs angezeigt."
      />
      <div className="control">
        <input
          onChange={(event) => _setName(event.target.value)}
          type="text"
          value={name}
          placeholder="Name"
          className="input br5 is-size-6 has-text-black has-background-white"
        />
        <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
          { error }
        </p>
      </div>
    </div>
  );
};

export default NameCreator;
