const PALETTE = ['#b2e061', '#bd7ebe', '#ffb55a', '#16A3A3', '#beb9db', '#fdcce5', '#8bd3c7', '#fd7f6f', '#7eb0d5'];

const getColor = (index) => {
  // if the value is negative, pick a color from the end of the palette minus the index value
  if (index < 0) {
    return PALETTE[(PALETTE.length + index) % PALETTE.length];
  }

  return PALETTE[index % PALETTE.length];
};

export {
  PALETTE,
  getColor,
};
