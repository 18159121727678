import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

const FacebookButton = (props) => {
  const { text, url, onClick } = props;

  return (
    <Button
      url={url}
      text={text}
      onClick={onClick}
      styles={{ backgroundColor: '#3b5998' }}
    >
      <i className="fab fa-facebook" />
    </Button>
  );
};

FacebookButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FacebookButton;
