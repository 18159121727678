import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';

const ScriptShopware = () => (
  <>
    <div className="has-text-centered">
      <i className="fab fa-shopware has-text-info is-size-1"> </i>
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-weight-bold">Installation für Shopware</p>
    <div className="mt40">
      <p className="mt30 is-size-6 has-text-weight-bold">So fügst Du Flowdust deiner Shopware-Seite hinzu:</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">{'Öffne den Ordner themes/frontend/bare/frontend/index '}</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Öffne die index.tpl Datei</p>
    </div>
    <div className="mt20 mb30">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere diesen HTML-Code:</p>
    </div>
    <ScriptTagCopy />
    <div className="mt-2 mb-5">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">{'Füge ihn vor dem schließenden </block> tag ein'}</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);

export default ScriptShopware;
