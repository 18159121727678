import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import stats from 'analytics/analytics';
import isURL from 'validator/lib/isURL';

function PrivacyURL(props) {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [signal] = useState(axios.CancelToken.source());
  const [url, setUrl] = useState('');

  const { user } = useSelector((state) => state.sessionState);

  const dispatch = useDispatch();
  const onSetPrivacyURL = (privacyURL) => dispatch({ type: 'PRIVACY_URL_SET', privacyURL });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
		, [])

  const saveNewPrivacyURL = async () => {
    setDisabled(true);
    setError('');

    if (!isURL(url)) {
      setError('Vertippt? Bitte gib eine korrekte URL ein.');
      setDisabled(false);
      return;
    }

    if (!url.match(/^[\/:a-zA-Z0-9._-]+$/g)) {
      setError('Bitte gib eine korrekte URL ein!');
      setDisabled(false);
      return;
    }

    try {
      const authToken = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const payload = {
        url,
      };

      await axios.patch(`${process.env.REACT_APP_API}/users/privacy`, payload, config);

      stats.push(['trackEvent', 'Privacy', 'Privacy URL set']);

      onSetPrivacyURL(url);

      setError('Fertig! Die URL zu deiner Datenschutzbestimmung wurde erfolgreich gespeichert.');
      setDisabled(false);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      console.log(e.response.data);

      setDisabled(false);
      setError('Hoppla! Die URL konnte leider nicht gespeichert werden.');
    }
  };

  const setPrivacyURL = (newUrl) => {
    setUrl(newUrl);
    setError('');

    if (newUrl === user.privacyURL) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <PrivacyURLLayout
      error={error}
      disabled={disabled}
      setPrivacyURL={setPrivacyURL}
      privacyURL={url}
      savedURL={user.privacyURL}
      saveNewPrivacyURL={saveNewPrivacyURL}
    />
  );
}

function PrivacyURLLayout(props) {
  return (
    <div className="columns is-centered is-mobile">
      <div className="column is-9-desktop is-10-tablet is-11-mobile mt10 pb30">
        <h2 className="mt20 has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile">Datenschutz-URL</h2>
        <div className="columns" />
        {props.savedURL ? (
          <div>
            <p className=" mb10 is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-semibold">Deine URL:</p>
            <a href={props.savedURL} target="_blank" rel="noopener noreferrer" className="mt10 has-text-weight-bold is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-blue">{props.savedURL}</a>
            <p className="mt30 is-size-6 has-text-grey">Wenn sich deine URL geändert hat, kannst Du sie hier aktualisieren:</p>
          </div>
        ) : <p className="mt10 has-text-weight-semibold">Bitte gib die URL zu deiner Datenschutzerklärung an. Diese wird dann im Flowter und im Hub unten links unter "Datenschutzerklärung" auffindbar sein.</p>}
        <div className="columns">
          <div className="column is-6">
            <input
              onChange={(event) => props.setPrivacyURL(event.target.value)}
              type="text"
              value={props.privacyURL}
              placeholder="Deine Datenschutz-URL"
              className="input has-text-grey has-text-centered mt30"
            />
          </div>
        </div>
        <button
          className="button has-background-blue is-rounded has-text-white has-text-weight-bold"
          onClick={() => props.saveNewPrivacyURL()}
          disabled={props.disabled}
        >
          Speichern
        </button>
        <p className="mt30 has-text-weight-bold">{props.error}</p>
      </div>
    </div>
  );
}

export default PrivacyURL;
