import React, { useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useDispatch } from 'react-redux';

// Libraries
import PropTypes from 'prop-types';
import { setHubStatus } from 'reducer/hubs';

// Components
import notification from 'utils/notification';

const ToggleStatusButton = (props) => {
  const { id, status } = props;

  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();
  const dispatch = useDispatch();

  const toggleStatus = async () => {
    setDisabled(true);

    try {
      const data = {
        status: (status === 'active' ? 'draft' : 'active'),
      };

      const newStatus = await post(`/hubs/status/${id}`, data);

      setDisabled(false);

      if (newStatus === 'active') {
        notification.send('Erledigt!', 'Dein Hub ist nun aktiv!', 'success');
      } else {
        notification.send('Erledigt!', 'Dein Hub ist nun inaktiv und kann nicht mehr aufgerufen werden.', 'success');
      }

      dispatch(setHubStatus({ id, status: newStatus }));
    } catch (err) {
      setDisabled(false);

      notification.send('Etwas hat nicht funktioniert.', 'Bitte versuche es später noch einmal.', 'danger');
    }
  };

  return (
    <div>
      <button
        type="button"
        className="cleanButton has-hover is-flex align-items-center py-2 px-3 br10 mx-0 is-size-5 mt-2 has-fullwidth"
        onClick={toggleStatus}
        disabled={disabled}
      >
        <i className={`has-text-black mr-2 fas fa-toggle-${status === 'active' ? 'on' : 'off'}`} />
        <p
          className="is-size-7 is-inline-block has-text-black has-text-weight-semibold"
        >
          {
          status === 'active' ? 'Hub deaktivieren' : 'Hub aktivieren'
        }
        </p>

      </button>
    </div>
  );
};

ToggleStatusButton.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ToggleStatusButton;
