import React from 'react';

// Libraries
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getColor } from '../../../palette';

const SubmissionInfo = (props) => {
  const { data: submissions, index } = props;

  const { flowters } = useSelector((state) => state.flowterState);
  const { questions } = flowters[index];

  return submissions.map((submission, i) => {
    const { checks, text, gif } = submission;
    const { question, options, allowText } = questions[i];

    const showChecks = () => {
      const checkLayout = [];

      // for each check, show the option / text and the corresponding statistic color
      checks.forEach((item, checkIndex) => {
        if (!item) return;

        let checkText = '';

        // if the last check is ticked and text is allowed
        // show the text
        if (checkIndex === checks.length - 1 && allowText) {
          checkText = text;
        } else {
          // otherwise, show the option
          checkText = options[checkIndex];
        }

        const check = (
          <div key={checkIndex}>
            <i
              className="fas fa-square is-size-7 is-inline mr-1"
              style={{ color: getColor(checkIndex) }}
            />
            <p className="is-inline">
              {checkText}
            </p>
          </div>
        );

        checkLayout.push(check);
      });

      return checkLayout;
    };

    return (
      <div key={i} className="mt-2">
        <p className="has-text-weight-bold has-text-black">
          {`${question}`}
        </p>
        { showChecks() }
      </div>

    );
  });
};

SubmissionInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    checks: PropTypes.arrayOf(PropTypes.number),
    text: PropTypes.string,
    gif: PropTypes.string,
  })).isRequired,
  index: PropTypes.number.isRequired,
};

export default SubmissionInfo;
