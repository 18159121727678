import React from 'react';
import rocketImage from 'assets/images/onboarding/rocket.png';
import './styles.scss';

const Banner = () => (
  <div className="column is-fullheight is-3-desktop is-2-tablet has-fullheight has-background-pastel p0 is-hidden-touch" id="banner">
    <div className="mt30 has-flex-3">
      <h2 className="has-text-white has-text-weight-bold has-text-centered is-size-3-desktop is-size-4-tablet pl10 pr10">Lass uns durchstarten!</h2>
    </div>
    <div className="has-flex-1">
      <img src={rocketImage} alt="Grafik einer Startup Rakete" className="has-fullwidth has-fullheight" />
    </div>
  </div>
);

export default Banner;
