import React from 'react';

// Libraries
import PropTypes from 'prop-types';

/**
 * Single Element that can be selected
 */
const SelectableElement = (props) => {
  const {
    label, value, category, clickElement, isSelected,
  } = props;

  /*
   * onChange function that gets called after an element has been clicked
   */
  const onChange = () => {
    clickElement({ label, value });
  };

  return (
    <div className="field">
      <input
        type="checkbox"
        id={`${category} ${value}`}
        checked={isSelected}
        className="is-checkradio"
        onChange={onChange}
      />
      <label
        className="has-text-centered"
        htmlFor={`${category} ${value}`}
      >
        {`${label} ${value}`}
      </label>
    </div>
  );
};

SelectableElement.propTypes = {
  /*
    The label combined with the value will be displayed and used as id for the checkbox
  */
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  /*
   * A string needed to have a unique id for the label
   */
  category: PropTypes.string.isRequired,
  /**
   * the function toggles the selection for that item
   */
  clickElement: PropTypes.func.isRequired,
  /**
   * Boolean to determine if that element has been selected
   */
  isSelected: PropTypes.bool.isRequired,
};

export default SelectableElement;
