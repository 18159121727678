export const setDisplay = (display) => ({
  type: 'SET_LAYOUT_DISPLAY',
  display,
});

export const setWidget = (widget) => ({
  type: 'SET_WIDGET',
  widget,
});

export const setExpire = (expire) => ({
  type: 'SET_EXPIRE',
  expire,
});

export const setScreens = (screens) => ({
  type: 'SET_SCREENS',
  screens,
});

export const setEditIndex = (editIndex) => ({
  type: 'SET_EDIT_INDEX',
  editIndex,
});

export const setShowWidget = (show) => ({
  type: 'SET_SHOW_WIDGET',
  show,
});

export const toggleShowWidget = () => ({
  type: 'TOGGLE_SHOW_WIDGET',
});

export const clearCreator = () => ({
  type: 'CLEAR_CREATOR',
});
