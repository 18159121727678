import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import ProgressBar from './ProgressBar';

// Libraries
import PropTypes from 'prop-types';

const ProgressIndicator = (props) => {
  const { activeQuestion } = props;

  const { content, colors } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const percentDone = (activeQuestion / questions.length) * 100;

  return (
    <div className="my-2">
      <ProgressBar color={colors[0]} completed={percentDone} />
    </div>
  );
};

ProgressIndicator.propTypes = {
  activeQuestion: PropTypes.number.isRequired,
};

export default ProgressIndicator;
