import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setEnding, setEndingMedia, deleteEnding } from 'reducer/hubs';

// Constants
import { HUB_MAX_ENDING_TEXT_LENGTH } from 'constants/hub';

// Components
import FileUploader from '../../FileUploader';
import LinkEditor from './LinkEditor';

const Ending = (props) => {
  const { id } = props;
  const { id: hubID, content } = useSelector((state) => state.hubs.creator);

  const { endings } = content;

  const isNotLastEnding = endings.length > 1;

  const { text, media, links } = endings[id];

  const dispatch = useDispatch();

  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState('');

  const setMedia = (fileUrl, fileType) => {
    dispatch(setEndingMedia({ id, source: fileUrl, type: fileType }));
  };

  const _setEnding = (_endText) => {
    if (_endText.length > HUB_MAX_ENDING_TEXT_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }
    dispatch(setEnding({ text: _endText, id }));
  };

  const _deleteEnding = (e) => {
    e.preventDefault();

    dispatch(deleteEnding(id));
  };

  return (
    <div>
      <h2 className="has-text-weight-bold is-size-6 pt-5 has-text-black">
        Füge den Text hinzu, der am Ende angezeigt werden soll
      </h2>
      {
          isNotLastEnding
          && (
          <div className="has-text-left">
            <button
              className="cleanButton is-inline has-text-weight-bold has-text-red is-size-7 mt10 is-underlined"
              onClick={(e) => _deleteEnding(e)}
              type="button"
            >
              Ende entfernen
            </button>
          </div>
          )
        }

      <div className="columns is-marginless mt-3 is-mobile">
        <div className="column is-paddingless has-overflow-hidden">

          <textarea
            onChange={(event) => _setEnding(event.target.value)}
            type="text"
            value={text}
            rows={4}
            placeholder="Danke für deine Hilfe!"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className="textarea br10 no-shadow has-border p-3 has-fixed-size has-text-black is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
          />

        </div>
      </div>

      <div className="has-text-centered mt-3">
        <p className="has-text-black has-text-weight-semibold">
          {error}
        </p>
      </div>

      <div className="mt-4">
        <FileUploader hub={hubID} file={media} setFile={setMedia} />
      </div>
      <LinkEditor endingIndex={id} />
    </div>
  );
};

export default Ending;
