import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Dropdown from 'components/utils/Dropdown';

const ActionSelector = (props) => {
  const { questions, endings } = useSelector((state) => state.hubs.creator.content);

  const { index, type, setAction } = props;

  const _setAction = (e) => {
    const { value: _index, type: _type } = e;

    // react-select gets confused when there are multiple values that are the same
    // e.g: index for ending is 0, and index for question is also 0
    // so we need to remove the corresponding prefix for the value
    const actualIndex = parseInt(_index.substring(2), 10);

    setAction(_type, actualIndex);
  };

  // Prepare all question to be displayed
  const labels = useMemo(() => {
    // const _questions = questions.map((_question, _index) => {
    //   const { text } = _question;
    //
    //   return {
    //     value: `Q_${_index}`,
    //     label: text,
    //     type: 'QUESTION',
    //   };
    // });

    const _endings = endings.map((_ending, _index) => {
      const { text } = _ending;

      return {
        value: `E_${_index}`,
        label: text,
        type: 'ENDING',
      };
    });

    return [
      // {
      //   label: 'Fragen',
      //   options: _questions,
      // },
      {
        label: 'Enden',
        options: _endings,
      },
    ];
  }, []);

  // how each question should be rendered
  const renderLabel = ({ label }) => (
    <div>
      <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-info" />
      <p className="is-inline has-text-weight-semibold has-text-black is-size-7 break-word">
        {label}
      </p>
    </div>
  );

  // const value = useMemo(() => (type === 'QUESTION' ? labels[0].options[index] : labels[1].options[index]), [type, index]);

  const value = useMemo(() => labels[0].options[index], [index]);

  return (
    <div className="has-min-width-200">
      <Dropdown
        value={value}
        renderLabel={renderLabel}
        labels={labels}
        onChange={_setAction}
      />
    </div>
  );
};

ActionSelector.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  setAction: PropTypes.func.isRequired,
};

export default ActionSelector;
