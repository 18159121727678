import React, { useEffect, useState } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';

// Reducer Actions
import { setPage } from 'actions/onboarding';
import { setStatus } from 'actions/user';
import { setComingFromDemo as setComingFromHubDemo, setHubs } from 'reducer/hubs';
import { setComingFromDemo as setComingFromFlowterDemo } from 'reducer/flowterCreator';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// HOCs
import { useNavigate } from 'react-router-dom';
import withAuthentication from 'components/hoc/withAuthentication';

// Constants
import { NEW_USER } from 'constants/status';
import * as routes from 'constants/routes';

// Components
import WelcomeInfo from './WelcomeInfo';
import Banner from './Banner';

// Styles
import './css/onboarding.scss';
import LoadingLayout from 'components/utils/LoadingLayout';

/*
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PreviousButton from 'components/onboarding/buttons/PreviousButton';
import SkipButton from 'components/onboarding/buttons/SkipButton';
import WebsiteOnboarding from './website/WebsiteOnboarding';
import OnboardingWidgetCreator from './OnboardingWidgetCreator';
import ScriptInsertContainer from './script/ScriptInsertContainer';
import WebsiteLinkOnboarding from './website/WebsiteLinkOnboarding';
import BusinessTypeQuestion from './questions/BusinessTypeQuestion';
import GoalQuestion from './questions/GoalQuestion';
import OnboardingFinished from './OnboardingFinished';
*/

/*
 * Container that holds all the components necessary for onboarding
 */
function Onboarding() {
  const { user } = useSelector((state) => state.sessionState);
  const { page, status } = user;

  const { hubs, creator } = useSelector((state) => state.hubs);
  const { content, isComingFromDemo: isComingFromHubDemo } = creator;

  const { isComingFromDemo: isComingFromFlowterDemo } = useSelector((state) => state.flowterCreator);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get, post } = useAPI();

  const [error, setError] = useState('');

  useEffect(() => {
    if (status !== NEW_USER) {
      navigate(routes.HOME);
    }

    if (page === undefined) {
      dispatch(setPage(0));
    }
    // eslint-disable-next-line
	}, [])

  // if a user created a hub in the demo, create a new hub and save the current content
  // then, go to the editor to show it to the user
  const finishUpHubDemo = async () => {
    try {
      // const hubIndex = hubs.length + 1
      // generate random id to create a unique url
      const id = uuidv4().replace(/-/gi, '').slice(16);
      const hub = await post('/hubs', { url: id });

      // change its contents to match the current creator state
      hub.content = {
        ...hub.content,
        ...content,
        // make sure every question has a text, even a generic one (will be shown in statistic)
        questions: content.questions.map((_question) => {
          const { text } = _question;

          if (!text) {
            return {
              ..._question,
              text: 'Wie gefällt dir unser Produkt?',
            };
          }

          return _question;
        }),
        // make sure every ending has a text, even a generic one (will be shown in statistic)
        endings: content.endings.map((_ending) => {
          const { text } = _ending;

          if (!text) {
            return {
              ..._ending,
              text: 'Danke für deine Hilfe!',
            };
          }

          return _ending;
        }),
      };

      // we dont ADD, but SET, as we need to have the hasData variable to be set to true (so the editor does not kick us out)
      // When we go back to HubList, we will load all the other hubs
      dispatch(setHubs([hub]));
      // clean up for good measure
      // clear both comingFromDemos
      dispatch(setComingFromFlowterDemo(false));
      dispatch(setComingFromHubDemo(false));

      // and finally, go to the hub editor
      navigate(`${routes.HUB_CONTENT}/${hubs.length}`);
    } catch (e) {
      // clear both comingFromDemos
      dispatch(setComingFromFlowterDemo(false));
      dispatch(setComingFromHubDemo(false));

      console.log(e);
      navigate(routes.HOME);
    }
  };

  // if a user created a hub in the demo, create a new hub and save the current content
  // then, go to the editor to show it to the user
  const finishUpFlowterDemo = () => {
    navigate(routes.FLOWTER_CREATOR);
    // clear both comingFromDemos
    dispatch(setComingFromFlowterDemo(false));
    dispatch(setComingFromHubDemo(false));
  };

  const finishOnboarding = async () => {
    try {
      const newStatus = await get('/users/onboarding');

      dispatch(setStatus(newStatus));

      // Onboarding is done. Check for hub demo and redirect.
      if (isComingFromHubDemo) {
        await finishUpHubDemo();
        return;
      }

      if (isComingFromFlowterDemo) {
        finishUpFlowterDemo();
        return;
      }

      navigate(routes.HOME);
    } catch (e) {
      // What to do if that fails?
      console.log(e);
      setError('Oops! Etwas hat leider nicht funktioniert. Bitte versuche die Seite neu zu laden. Sollte das Problem weiterhin bestehen, melde dich bitte bei support@flowdust.com bei uns!');
    }
  };

  useEffect(() => {
    if (page !== 1) return;

    finishOnboarding();
  }, [page]);

  /*
<SwitchTransition>
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={page}
        >
          {
             page === 5
               ? (
                 <>
                   <div id="onboardingButtonsWrapper" className="is-relative">
                     <PreviousButton />
                     <SkipButton />
                   </div>
                   { content }
                 </>
               )
               : (
                 <div className="columns has-fullheight has-fullwidth m0">
                   { user.page % 2 === 1 && <Banner />}
                   <div className="column has-fullheight p0 is-relative">
                     { user.page > 1 && <PreviousButton /> }
                     { user.page > 0 && <SkipButton /> }
                     <div className="columns has-fullheight has-fullwidth is-vcentered m0">
                       <div className="column p0">
                         { content }
                       </div>
                     </div>
                   </div>
                   { user.page % 2 === 0 && <Banner /> }
                 </div>
               )
        }
        </CSSTransition>
      </SwitchTransition>
  */
  return (
    <>
      <Helmet>
        <title>
          Willkommen bei Flowdust!
        </title>
      </Helmet>
      {
          page === 0
            ? (
              <div className="columns has-fullheight has-fullwidth m0">
                <div className="column has-fullheight p0 is-relative">
                  <div className="columns has-fullheight has-fullwidth is-vcentered m0">
                    <div className="column p0">
                      <WelcomeInfo />
                    </div>
                  </div>
                </div>
                <Banner />
              </div>
            )
            : <LoadingLayout error={error} />
        }
    </>
  );
}

export default withAuthentication(Onboarding);
