import React from 'react';
import Widget from 'components/widgets/Widget/Widget';
import { useDispatch, useSelector } from 'react-redux';
import { addFlowter, deleteFlowter } from 'actions/flowter';
import stats from 'analytics/analytics';
import { FLOWTER_CREATOR, FLOWTER_DATA } from 'constants/routes';
import { Link } from 'react-router-dom';
import './styles/flowter.scss';

const Flowter = (props) => {
  const { id } = props;

  const { flowters } = useSelector((state) => state.flowterState);

  const flowter = flowters[id];

  const dispatch = useDispatch();

  const addWidget = (widget) => {
    stats.push(['trackEvent', 'Flowter', 'Flowter duplicated']);
    dispatch(addFlowter(widget));
  };

  const deleteWidget = (widgetId) => {
    stats.push(['trackEvent', 'Flowter', 'Flowter deleted']);
    dispatch(deleteFlowter(widgetId));
  };

  if (!flowter) {
    return null;
  }

  const { submitted } = flowter.meta;

  return (
    <Widget
      api="flowters/"
      creatorEditUrl={`${FLOWTER_CREATOR}/${id}`}
      widget={flowter}
      allWidgets={flowters}
      addWidget={addWidget}
      deleteWidget={deleteWidget}
    >
      <div className="mt10 p5 has-text-centered has-background-pastel-light has-text-weight-semibold is-narrow br5 mt-1 px-2 py-1 has-fullwidth">
        <p className="is-size-7 has-text-white">Flowter</p>
      </div>
      <div className="columns is-marginless is-centered-bottom is-mobile mt-2">
        <div className="column has-text-weight-bold p0 mb5 is-12">
          <p className="is-inline has-text-black">Abstimmungen: </p>
          <p className="is-inline has-text-pastel mt5">
            {submitted}
          </p>
        </div>
        <Link
          to={`${FLOWTER_DATA}/${id}`}
          className="column is-narrow p0"
        >
          <p className="mr5 is-size-7 has-text-centered has-text-weight-semibold has-text-white pl10 pr10 has-background-pastel br22 grow">Ergebnisse</p>
        </Link>
      </div>
    </Widget>
  );
};

export default (Flowter);
