import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Components
import { Link } from 'react-router-dom';

// Assets
import support from 'assets/images/Chat_SVG.svg';

// Constants
import * as routes from 'constants/routes';

// Styles
import './css/support.scss';

const Support = () => (
  <>
    <Helmet>
      <title>Flowdust - Support</title>
    </Helmet>
    <div>
      <div className="columns is-centered is-marginless is-paddingless">
        <div className="column has-text-centered is-paddingless">
          <p className="mt30 mb20 has-text-centered has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile">
            Brauchst Du Hilfe?
          </p>
          <div className="columns is-centered is-hidden-mobile">
            <div className="mt10 mb20 column is-2">
              <img src={support} className="" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile is-centered is-marginless is-paddingless mb90">
        <div className="column is-11-mobile is-4-desktop is-6-tablet supportCard">
          <div className="box has-border-2 has-text-centered has-fullheight">
            <i className="mt20 has-text-pinkred is-size-1 fas fa-question" />
            <p className="mt20 has-text-pinkred has-text-weight-bold is-size-4">
              FAQ
            </p>
            <p className="mt10 has-text-black is-size-6">Sieh dir die häufig gestellten Fragen an, um Antworten auf geläufige Probleme zu erhalten.</p>
            <Link
              className="mt20 button is-rounded has-text-weight-bold has-background-pinkred has-text-white"
              to={routes.FAQ}
            >
              Auswählen
            </Link>
          </div>
        </div>
        <div className="column is-11-mobile is-4-desktop is-6-tablet supportCard">
          <div className="box has-border-2 has-text-centered has-fullheight">
            <i className="mt20 has-text-info is-size-1 fas fa-book" />
            <p className="mt20 has-text-info has-text-weight-bold is-size-4">
              Dokumentation
            </p>
            <p className="mt10 has-text-black is-size-6">
              Wie funktioniert Flowdust genau? All das wird in unserer Dokumentation beantwortet.
            </p>
            <button
              className="mt20 button has-hover-blue is-rounded has-background-info has-text-white"
              type="button"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.flowdust.com/"
                className="is-size-6"
              >
                <p className="has-text-white has-text-weight-bold is-inline mr5">Zur Dokumentation</p>
              </a>
            </button>
          </div>
        </div>
        <div className="column is-11-mobile is-4-desktop is-6-tablet supportCard">
          <div className="box has-border-2 has-text-centered has-fullheight">
            <i className="mt20 has-text-pastel is-size-1 fas fa-envelope" />
            <p className="mt20 has-text-pastel has-text-weight-bold is-size-4">Support</p>
            <p className="mt10 has-text-black is-size-6">
              Wurde deine Frage noch nicht beantwortet?
              Schicke uns eine E-Mail und wir tun unser Bestes, um dein Problem zu lösen.
            </p>
            <a
              href="mailto:support@flowdust.com"
              className="mt10 button is-inline-block is-rounded has-background-pastel is-size-6 has-text-white has-hover-pastel has-text-weight-bold is-block"
            >
              support@flowdust.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Support;
