import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import stats from 'analytics/analytics';
import axios from 'axios';
import { Link } from 'react-router-dom';

import * as routes from 'constants/routes';
import * as status from 'constants/status';

const SubscriptionInfoBox = () => {
  const user = useSelector((state) => state.sessionState.user);

  const nextBillingDate = user.subscription.nextBillingDate || Date.now();
  const [date] = useState(new Date(nextBillingDate).toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' }));

  const showSubscriptionInfo = () => {
    if (user.status === status.WILL_CANCEL) {
      return <WillCancelSubscriptionInfo date={date} />;
    }

    if (user.status === status.CANCELLED) {
      return <CancelledSubscriptionInfo />;
    }

    if (user.status === status.PAST_DUE) {
      return <PastDueSubscriptionInfo />;
    }

    if (user.newPlan) {
      return (
        <WillDowngradeSubscriptionInfo
          plan={user.newPlan}
          date={date}
        />
      );
    }

    if (user.status === status.ACTIVE) {
      return (
        <ActiveSubscriptionInfo
          plan={user.planDetails}
        />
      );
    }

    return null;
  };

  return showSubscriptionInfo();
};

const WillDowngradeSubscriptionInfo = (props) => {
  const { plan, date } = props;
  const { name, cost } = plan;
  return (
    <div className="box boxshadow br10 has-background-grey-lighter has-text-black">
      <div>
        <p className="is-inline has-text-weight-semibold">
          {`Dein Plan wird am ${date} heruntergestuft. Neuer Plan:`}
        </p>
      </div>
      <p className="has-text-weight-bold is-size-3">
        {`${cost} €`}
      </p>
      <p className="has-text-weight-light is-size-4">
        {name}
      </p>
      <Link
        className="button mt10 has-text-weight-bold br10 grow"
        to={routes.MANAGE_SUBSCRIPTION}
      >
        Abonnement ändern
      </Link>
    </div>
  );
};

WillDowngradeSubscriptionInfo.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
};

const PastDueSubscriptionInfo = () => (
  <div className="box boxshadow br10 has-text-black">
    <p className="has-text-weight-semibold">
      Du bist mit deiner Zahlung im Rückstand.
    </p>
    <p className="has-text-weight-semibold">
      Wenn das Abonnement nicht bezahlt wird, wird es gekündigt.
    </p>
  </div>
);

const CancelledSubscriptionInfo = () => (
  <div className="box boxshadow br10 has-text-black">
    <p className="has-text-weight-semibold">
      Du hast momentan kein aktives Abonnement.
    </p>
    <div className="has-text-centered mt-3">
      <Link
        to={routes.CHECKOUT}
        className="button has-fullheight is-pastel has-text-weight-bold grow br10"
        type="button"
      >
        Neues Abonnement starten
      </Link>
    </div>
  </div>
);

const WillCancelSubscriptionInfo = (props) => {
  const { date } = props;

  return (
    <div className="box boxshadow br10 has-text-black">
      <p className="has-text-weight-semibold">
        Dein Abonnement wurde gekündigt.
      </p>
      <p className="has-text-weight-semibold mt-3">
        {`Am ${date} befindest Du dich wieder in der kostenlosen Testphase.`}
      </p>
      <div className="has-text-centered mt-3">
        <Link
          to={routes.MANAGE_SUBSCRIPTION}
          className="button has-fullheight is-pastel has-text-weight-bold grow br10"
          type="button"
        >
          Abonnement doch fortsetzen
        </Link>
      </div>
    </div>
  );
};

WillCancelSubscriptionInfo.propTypes = {
  date: PropTypes.string.isRequired,
};

const ActiveSubscriptionInfo = (props) => {
  const { plan } = props;
  const impressions = plan.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const { cost, name } = plan;

  return (
    <div className="box boxshadow br10 has-background-grey-lighter has-text-black">
      <div>
        <p className="is-inline has-text-weight-semibold mr5">
          Status:
        </p>
        <p className="is-inline has-text-weight-bold">
          Aktives Abonnement
        </p>
        <div className="has-border-white" />
      </div>
      <p className="has-text-weight-bold is-size-3">
        {`${cost} €`}
      </p>
      <p className="has-text-weight-light is-size-4">
        {name}
      </p>
      <p className="has-text-weight-semibold is-size-6">
        {`${plan.maxAmountHubs} Hubs`}
      </p>
      <p className="has-text-weight-semibold is-size-6">
        {`${impressions} Impressionen pro Monat`}
      </p>
      <Link
        className="button mt10 has-text-weight-bold br10 grow"
        to={routes.MANAGE_SUBSCRIPTION}
      >
        Abonnement ändern
      </Link>
    </div>
  );
};

ActiveSubscriptionInfo.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    maxAmountHubs: PropTypes.number.isRequired,
  }).isRequired,
};

export default SubscriptionInfoBox;
