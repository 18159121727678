import React, { useLayoutEffect } from 'react';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setCreator, clearCreator } from 'reducer/hubs';

// Constants
import { HUB_LIST } from 'constants/routes';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

/**
 * This HOC checks if the user has hub data loaded.
 * If there is no data, the user will be redirected to the hub list page
 * where the hub data will be loaded
 */
const withHubData = (Component) => {
  function WithHubData(props) {
    const { hasData, hubs } = useSelector((state) => state.hubs);
    const dispatch = useDispatch();

    const { id: hubIndex } = useParams();
    const navigate = useNavigate();

    useLayoutEffect(() => {
      // if we dont have any hub data, load it first.
      if (!hasData) {
        navigate(HUB_LIST);
        return undefined;
      }

      const index = parseInt(hubIndex, 10);

      if (Number.isNaN(index)) return undefined;

      const hub = hubs[index];

      if (!hub) {
        navigate(HUB_LIST);
        return undefined;
      }

      dispatch(setCreator({ ...hub, editIndex: index }));

      return () => {
        dispatch(clearCreator());
      };
    }, []);

    return hasData ? <Component {...props} /> : null;
  }

  return withAuthentication(WithHubData);
};

export default withHubData;
