import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import ReactPlayer from 'react-player';

// Styles
import styles from './styles.module.scss';

const VideoPlayer = (props) => {
  const { src } = props;

  return (
    <div
      className="has-max-width-400 is-in-center has-border-grey-light br10 has-overflow-hidden"
    >
      <ReactPlayer
        url={src}
        width="100%"
        height="100%"
        controls
        id={styles.container}

      />
    </div>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default VideoPlayer;
