import React, { useState, useEffect } from 'react';

// Libraries

import { useParams, useNavigate } from 'react-router-dom';
import notification from 'utils/notification';
import axios from 'axios';
import stats from 'analytics/analytics';
import { Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';

// Components
import WebsiteForm from './WebsiteForm';

// HOCs
import withAuthentication from '../hoc/withAuthentication';
import { useDispatch, useSelector } from 'react-redux';

// Used by the routes.EDIT_WEBSITE route
function EditWebsite(props) {
  const [websiteObj, setWebsiteObj] = useState();
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [signal, setSignal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently } = useAuth0();

  const params = useParams();
  const navigate = useNavigate();

  const { websites } = useSelector((state) => state.websiteState);

  const dispatch = useDispatch();
  const onUpdateWebsite = (website) => dispatch({ type: 'UPDATE_WEBSITE', website });

  useEffect(() => {
    // If an existing website gets edited
    if (params.id) {
      // validate the ID in the url
      if (Number.isNaN(params.id)) {
        return navigate(routes.HOME);
      }

      const websiteToEdit = websites[params.id];

      if (websiteToEdit === undefined) {
        return navigate(routes.HOME);
      }

      setWebsiteObj({
        ...websiteToEdit,
        id: parseInt(params.id, 10),
      });
    }
  }, [websites, params.id]);

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
		, [])

  const saveEditedWebsite = async (websiteData) => {
    // TODO: enter should save
    setDisabled(true);
    setError('');

    // TODO: refactor the following part
    try {
      const token = await getAccessTokenSilently();

      const newSignal = axios.CancelToken.source();
      setSignal(newSignal);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: newSignal.token,
      };

      websiteData.id = websites[websiteObj.id]._id;

      const site = await axios.put(`${process.env.REACT_APP_API}/websites`, websiteData, config);

      // Save in redux
      onUpdateWebsite(site.data);

      // Matomo
      stats.push(['trackEvent', 'Website Creator', 'Website updated']);

      notification.send('Fertig!', 'Deine Website wurde bearbeitet.', 'success');
      navigate(routes.FLOWTER_LIST);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err);
      console.log(err.response);

      setDisabled(false);

      if (!err.response) {
        setError('Es ist etwas schief gelaufen. Bitte versuche es später noch einmal.');
        return;
      }

      if (err.response.status === 500) {
        setError('Server Fehler - Bitte versuche es später noch einmal.');
        return;
      }

      setError(err.response.data);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Website bearbeiten
        </title>
      </Helmet>
      <div className="columns is-centered is-vcentered has-fullheight is-marginless">
        <div className="column is-10-mobile is-offset-1-mobile is-7-desktop is-8-tablet mt20 mb20">
          <p className="mb20 has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-4-mobile mt10 is-block">Bearbeite deine Website</p>
          <WebsiteForm
            data={websiteObj}
            saveButtonText="Änderungen speichern"
            setError={setError}
            saveForm={saveEditedWebsite}
            disabled={disabled}
          />
          {error && <p className="mb20 has-text-weight-bold has-text-black">{error}</p>}
        </div>
      </div>
    </>
  );
}

export default withAuthentication(EditWebsite);
