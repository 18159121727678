import React, { useState, useMemo, useEffect } from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { v4 as uuidv4 } from 'uuid';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setUrl } from 'reducer/hubs';

// Components
import InfoPopup from 'components/utils/InfoPopup';

// Constants
import { HUB_MAX_URL_LENGTH } from 'constants/hub';
import getHubUrl from 'utils/getHubUrl';
import CopyToClipboard from 'react-copy-to-clipboard';

/*
 * Component that handles the name input for the HubCreator
 */
function UrlCreator(props) {
  const { creator, hubs } = useSelector((state) => state.hubs);
  const dispatch = useDispatch();

  const [dice, setDice] = useState('two');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const { url, editIndex } = creator;
  const { url: hubUrl } = editIndex !== -1 ? hubs[editIndex] : '';

  const { showRedirectLink } = props;

  useEffect(() => {
    if (copied === false) return undefined;

    const timeout = setTimeout(() => setCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  /*
   * Validates and sets the name to state
   */
  const _setUrl = (_url) => {
    setError('');

    // only allow letters (with umlauts)
    const regex = /^[a-zA-ZäöüßÄÖÜ\-0-9]+$/;

    // allow clearing the input field and check for letters
    if (_url.length !== 0 && !regex.test(_url)) {
      setError('Bitte benutze nur Buchstaben und Zahlen! Der Name für deinen Hub wird später in der URL erscheinen.');
      return;
    }

    // check max length
    if (_url.length >= HUB_MAX_URL_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    dispatch(setUrl(_url));
  };

  const urlToHub = useMemo(() => {
    const sanitizedURL = sanitizeUrl(hubUrl);
    return getHubUrl(sanitizedURL);
  }, [hubUrl]);

  const generateRandomID = () => {
    const id = uuidv4().replace(/-/gi, '').slice(16);
    dispatch(setUrl(id));

    const numbers = ['one', 'two', 'three', 'four', 'five', 'six'];
    const index = Math.floor(Math.random() * 6);

    setDice(numbers[index]);
  };

  return (
    <div className="field">
      <div className="is-flex">
        <label className="label">
          Hub URL
          {
          showRedirectLink
          && (
          <a
            href={urlToHub}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fas fa-external-link-alt has-text-black mx-2" />
          </a>
          )
        }

        </label>
        <InfoPopup
          text="Jeder Hub braucht eine einzigartige URL, damit Nutzer deinen Hub finden können."
        />
        { showRedirectLink
          && (
          <CopyToClipboard
            text={urlToHub}
            onCopy={() => setCopied(true)}
          >
            <button
              type="button"
              className="cleanButton has-hover p-1 ml-1 is-size-7 br10 has-text-black mb-2 has-margin-left-auto"
            >
              <i className="fas fa-copy has-text-grey-darker is-size-6 mr-1" />
              { copied ? 'Kopiert!' : 'Kopieren'}
            </button>
          </CopyToClipboard>
          )}
      </div>
      <div className="control">
        <div className="columns m-0 is-mobile">
          <div className="column p-0">
            <input
              onChange={(event) => _setUrl(event.target.value)}
              type="text"
              value={url}
              placeholder="Name der URL"
              className="input br5 is-size-6 has-text-black has-background-white"
            />
          </div>
          <div className="column p-0 is-narrow">
            <button
              type="button"
              onClick={generateRandomID}
              className="cleanButton has-hover br5 p-2"
            >
              <i className={`fas is-size-4 has-text-grey-darker fa-dice-${dice}`} />
            </button>
          </div>

        </div>

        <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
          { error }
        </p>
      </div>
    </div>
  );
}

UrlCreator.propTypes = {
  showRedirectLink: PropTypes.bool,
};

UrlCreator.defaultProps = {
  showRedirectLink: true,
};

export default UrlCreator;
