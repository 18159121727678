import React, { useState } from 'react';

// Hooks
// import { useSelector, useDispatch } from 'react-redux';

// Component
import TwitterEditor from './TwitterEditor';
import EmailEditor from './EmailEditor';
import PinterestEditor from './PinterestEditor';
import WebsiteEditor from './WebsiteEditor';
import FacebookEditor from './FacebookEditor';
import LinkedinEditor from './LinkedinEditor';
import InstagramEditor from './InstagramEditor';
import InfoPopup from 'components/utils/InfoPopup';

const SocialEditor = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="br10 has-text-black mb-3 mt-5">
      <div className="is-flex align-items-center mb-3">
        <button
          className="button boxshadow has-background-white br10 has-text-left has-cursor-pointer ml-0 mr-2"
          type="button"
          onClick={toggleOpen}
        >
          <p className="is-inline has-text-weight-bold mr5 is-size-6">
            Social Links
          </p>
          <i className={`is-inline is-size-6 ${isOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'}`} />
        </button>
        <InfoPopup text="Wichtig: Dein Profil und deine Social Links werden nur angezeigt, wenn entweder Name, Beschreibung oder ein Profilbild vorhanden ist." />
      </div>
      {
         isOpen
           && (
           <div>
             <WebsiteEditor />
             <EmailEditor />
             <TwitterEditor />
             <PinterestEditor />
             <FacebookEditor />
             <LinkedinEditor />
             <InstagramEditor />
           </div>
           )
       }
    </div>
  );
};

export default SocialEditor;
