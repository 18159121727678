import React from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addLogic } from 'reducer/hubs';

// Components
import Logic from './Logic';

// Constants
import { HUB_MAX_LOGIC } from 'constants/hub';

const SimpleLogicEditor = () => {
  const { logic } = useSelector((state) => state.hubs.creator.content);
  const dispatch = useDispatch();

  const showLogic = () => logic.map((_logic, index) => (
    <Logic
      index={index}
      key={index}
    />
  ));

  const _addLogic = () => {
    dispatch(addLogic());
  };

  return (
    <div className="py-5">
      { showLogic() }
      {
           logic.length < HUB_MAX_LOGIC
           && (
           <div className="has-text-centered">
             <button
               type="button"
               onClick={_addLogic}
               className="my-5 is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
             >

               <i className="fa fa-code-branch has-text-pastel is-size-7 mr-2" />
               <p className="is-size-7 has-text-weight-bold is-inline">
                 Verzweigung hinzufügen
               </p>
             </button>
           </div>
           )
      }
    </div>
  );
};

export default SimpleLogicEditor;
