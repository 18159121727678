import React from 'react';
import presentationGIF from 'assets/images/newvid.gif';
import BrowserWindow from 'components/layout/BrowserWindow';
import { useNavigate } from 'react-router-dom';
import stats from 'analytics/analytics';
import { SIGN_UP, DEMO } from 'constants/routes';
import './styles/waveAnimation.scss';

function Hero() {
  const navigate = useNavigate();

  const clickTryNowButton = () => {
    stats.push(['trackEvent', 'Landing - Hero', 'Try Now button clicked']);
    navigate(SIGN_UP);
  };

  const clickDemoButton = () => {
    stats.push(['trackEvent', 'Landing - Hero', 'Demo button clicked']);
    navigate(DEMO);
  };

  return (
    <div id="heroWrapper">
      <div className="columns is-centered is-vcentered pt100 pl10 pr10">
        <div className="column is-3-desktop is-4-tablet is-11-mobile has-text-black has-min-width-300">
          <p className="has-text-weight-bold is-size-5-desktop is-size-5-tablet is-size-6-mobile">Feedback, Interaktion und Growth</p>
          <div>
            <h1 className="has-text-weight-bold is-size-3-desktop is-size-4-touch has-line-height-40 is-inline">
              Deine Plattform für Online Umfragen, Formulare und Feedback Management
            </h1>
          </div>
          <p className="mt-3">
            Nutze unseren leistungsstarken Editor für Umfragen,
            A/B Tests und Formulare oder sammle mit dem Flowter wertvolles Feedback direkt auf Deiner Website.
          </p>
          <div className="mt-2">
            <button
              type="button"
              className="button has-text-weight-bold is-primary br10 grow is-inline-block has-fullheight py-2 has-border-pastel-light mt-2"
              onClick={clickDemoButton}
            >
              <i className="fas fa-play-circle mr-2 mt-1" />
              Zum Editor und loslegen (Kein Account nötig)
            </button>
          </div>
          <div className="mt-4 has-text-grey">
            <div>
              <i className="has-text-pastel is-inline fas fa-check-circle" />
              <p className="is-inline ml-2 is-size-6">Keine Kreditkarte erforderlich</p>
            </div>
            <div>
              <i className="has-text-pastel is-inline fas fa-check-circle" />
              <p className="is-inline ml-2 is-size-6">Kostenloser Plan verfügbar</p>
            </div>
          </div>
        </div>
        <div className="column is-6 is-offset-1">
          <BrowserWindow img={presentationGIF} alt="Video zur Erstellung eines Flowters" />
        </div>
      </div>
      <div className="oceanWrapper mt100">
        <div className="ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
