import React from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';

// Assets
import flowdust from 'assets/images/iconbig.png';

// Components
import FilterSelector from './FilterSelector';
import InfoBox from './InfoBox';
import FeedbackLog from './FeedbackLog';
import QuestionStatistic from './QuestionStatistic';
import EndingStatistic from './EndingStatistic';
import StatisticExporter from './StatisticExporter';

const Statistic = () => {
  const { statistic } = useSelector((state) => state.hubs);
  const { amountSubmissions } = statistic;

  return (
    <div className="py-5">
      <div className="columns is-marginless is-centered">
        <div className="column has-text-centered is-11-desktop is-12-tablet">
          <div className="">

            {
              amountSubmissions > 0
              && (
              <div className="has-text-right mb-5">
                <StatisticExporter />
              </div>
              )
            }

            <div className="columns is-centered has-content-centered">
              <div className="column">
                <FilterSelector />
              </div>
            </div>

            <div className="columns is-centered">
              <div className="column is-4">
                <div className="mb-5">
                  <InfoBox />
                </div>
                <FeedbackLog />
              </div>
              <div className="column is-7-desktop is-offset-1-desktop">
                <QuestionStatistic />
                <div className="mt-5">
                  <EndingStatistic />
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistic;
