import React, { useState, useEffect } from 'react';

// Libraries
import isURL from 'validator/lib/isURL';
import notification from 'utils/notification';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setHubContent, setCreatorContent } from 'reducer/hubs';
import axios from 'axios';
import Popup from 'reactjs-popup';

function SaveButton() {
  const { post } = useAPI();
  const dispatch = useDispatch();
  const { creator } = useSelector((state) => state.hubs);

  const { id, content, editIndex } = creator;
  const {
    questions, endings, info, logic,
  } = content;

  const [disabled, setDisabled] = useState(false);

  const isInputValid = () => {
    // should not happen but in case of db status
    if (questions.length === 0) {
      notification.send('Oops!', 'Bitte füge mindestens eine Frage hinzu.', 'danger');
      return false;
    }

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const {
        text, media, options, allowText, type,
      } = question;

      if (text.length === 0) {
        notification.send('Oops!', 'Bitte achte darauf, dass jede Frage ausgefüllt ist.', 'danger');
        return false;
      }

      if (type === 'POLL') {
        if (options.length === 0 && allowText === false) {
          notification.send('Oops!', `Bitte gib Frage Nr. ${i + 1} ein paar Antwortmöglichkeiten!`, 'danger');
          return false;
        }

        if (options.length < 2 && allowText === false) {
          notification.send('Oops!', `Bitte gib Frage Nr. ${i + 1} ein paar mehr Antwortmöglichkeiten!`, 'danger');
          return false;
        }

        for (let j = 0; j < options.length; j++) {
          const { text: optionText, media: optionMedia } = options[j];

          if (optionText.length === 0 && !optionMedia.source) {
            notification.send('Oops!', `Bitte achte darauf, dass bei Frage Nr. ${i + 1} jede Auswahlmöglichkeit ausgefüllt ist!`, 'danger');
            return false;
          }
        }
      }
    }

    // should not happen, but in case of a db status
    if (endings.length === 0) {
      notification.send('Oops!', 'Bitte füge mindestens ein Ende hinzu.', 'danger');
      return false;
    }

    for (let j = 0; j < endings.length; j++) {
      const ending = endings[j];
      const { text, links } = ending;

      if (text.length === 0) {
        notification.send('Oops!', `Bitte füge bei Ende Nr.${j + 1} einen Text hinzu!`, 'danger');
        return false;
      }

      const urlOptions = {
        require_protocol: false,
        allow_underscores: true,
        disallow_auth: true,
        allow_fragments: false,
      };

      for (let k = 0; k < links.length; k++) {
        const link = links[k];
        const { url, text: linkText } = link;

        if (!isURL(url, urlOptions)) {
          notification.send('Oops!', `Bitte gib bei deinem Link bei Ende Nr.${j + 1} eine korrekte URL an!`, 'danger');
          return false;
        }

        if (linkText.length === 0) {
          notification.send('Oops!', `Bitte gib bei Ende Nr.${j + 1} einen Text für deinen Link an!`, 'danger');
          return false;
        }
      }
    }

    for (let k = 0; k < logic.length; k++) {
      const { trigger, conditions, action } = logic[k];

      for (let l = 0; l < conditions.length; l++) {
        const {
          question, type, value, sign,
        } = conditions[l];

        if (value === null) {
          notification.send('Oops!', 'Bitte füge bei deiner Logik überall eine Bedingung hinzu!', 'danger');
          return false;
        }
      }
    }

    return true;
  };

  const loadFile = async (url) => {
    try {
      const { data } = await axios.get(url, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  /* loads the blobs from all questions and options */
  const prepareContent = async () => {
    let infoFile;
    const questionFiles = [];
    const optionFiles = [];
    const endingFiles = [];

    // prepare info file
    let preparedInfo = info;

    if (info.media.source.includes('blob:')) {
      const data = await loadFile(info.media.source);

      infoFile = data;

      preparedInfo = {
        ...info,
        media: {
          source: '',
          type: '',
        },
      };
    }

    // prepare questions
    const preparedQuestions = await Promise.all(
      questions.map(async (question, index) => {
        const { options: allOptions } = question;
        // cycle through all options and get the media
        const options = await Promise.all(allOptions.map(async (option, optionIndex) => {
          // only upload the new local blob files
          if (option.media.source.includes('blob:')) {
            const data = await loadFile(option.media.source);

            optionFiles.push({
              index,
              optionIndex,
              source: data,
            });

            // empty the media object so the old file gets deleted
            return {
              ...option,
              media: {
                source: '',
                type: '',
              },
            };
          }

          return option;
        }));

        // if media exists and it is something new, load it
        if (question.media.source.includes('blob:')) {
          const data = await loadFile(question.media.source);

          questionFiles.push({
            index,
            source: data,
          });

          return {
            ...question,
            options,
            media: {
              source: '',
              type: '',
            },
          };
        }

        // add the prepared options
        return {
          ...question,
          maxAmountChecks: question.maxAmountChecks || 1,
          options,
        };
      }),
    );

    // prepare the endings
    const preparedEndings = await Promise.all(endings.map(async (ending, endingIndex) => {
      // only upload the new local blob files
      if (ending.media.source.includes('blob:')) {
        const data = await loadFile(ending.media.source);

        endingFiles.push({
          index: endingIndex,
          source: data,
        });

        // empty the media object so the old file gets deleted
        return {
          ...ending,
          media: {
            source: '',
            type: '',
          },
        };
      }

      return ending;
    }));

    return {
      info: preparedInfo,
      infoFile,

      questions: preparedQuestions,
      questionFiles,
      optionFiles,

      endings: preparedEndings,
      endingFiles,
    };
  };

  const saveContent = async (close) => {
    try {
      if (!isInputValid()) {
        return;
      }

      setDisabled(true);

      const {
        info: _info,
        infoFile,

        questions: _questions,
        questionFiles,
        optionFiles,

        endings: _endings,
        endingFiles,
      } = await prepareContent();

      const data = {
        info: _info,
        questions: _questions,
        endings: _endings,
        logic: content.logic,
        settings: content.settings,
      };

      // save text for the content
      const updatedContent = await post(`/hubs/content/${id}`, data);

      let amountFiles = optionFiles.length + questionFiles.length + endingFiles.length;

      if (infoFile) {
        amountFiles++;
      }

      // if there are no files to upload, we're done
      if (amountFiles === 0) {
        // update hub
        dispatch(setHubContent({ id: editIndex, content: updatedContent }));
        // update the creator
        dispatch(setCreatorContent(updatedContent));
        setDisabled(false);

        close();
        notification.send('Fertig!', 'Dein Hub wurde gespeichert!', 'success');
        return;
      }

      const fileData = new FormData();

      if (infoFile) {
        fileData.append('infoFile', infoFile);
      }

      for (let i = 0; i < questionFiles.length; i++) {
        const file = questionFiles[i];

        const { index, source } = file;
        fileData.append('questionFiles[]', source);
        fileData.append('questionMetadata[]', JSON.stringify({ index }));
      }

      for (let i = 0; i < optionFiles.length; i++) {
        const file = optionFiles[i];

        const { index, optionIndex, source } = file;
        fileData.append('optionFiles[]', source);
        fileData.append('optionMetadata[]', JSON.stringify({ index, optionIndex }));
      }

      for (let i = 0; i < endingFiles.length; i++) {
        const file = endingFiles[i];

        const { index, source } = file;
        fileData.append('endingFiles[]', source);
        fileData.append('endingMetadata[]', JSON.stringify({ index }));
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };

      // then save the files
      const _content = await post(`/hubs/content/media/${id}`, fileData, config);

      // save the new hub
      dispatch(setHubContent({ id: editIndex, content: _content }));
      // update the creator
      dispatch(setCreatorContent(_content));

      close();

      notification.send('Fertig!', 'Dein Hub wurde gespeichert!', 'success');

      setDisabled(false);
    } catch (e) {
      setDisabled(false);

      // TODO: check error

      notification.send('Oops!', 'Ein Fehler ist aufgetreten. Dein Hub konnte nicht gespeichert werden.', 'danger');
    }
  };

  return (
    <div className="">

      <Popup
        trigger={(
          <button
            type="button"
            className={`button grow has-width-110 br10 is-primary has-text-weight-bold is-size-7 has-text-white has-hover-primary has-fullheight p-3 has-content-centered has-line-height-full ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
          >
            Speichern
          </button>
      )}
        position="top center"
        on={['click']}
        keepTooltipInside="#root"
        repositionOnResize
      >
        { (close) => (
          <div className="br22 has-text-centered box pt-2">
            <div className="has-text-right">
              <button
                className="cleanButton"
                onClick={close}
                type="button"
              >
                <i className="fas fa-times has-text-grey is-size-7" />
              </button>
            </div>
            <h2 className="has-text-centered has-text-black is-size-7 mb-3 mt-2">
              Wichtig: Wenn du speicherst werden alle deine bisherigen Ergebnisse und Statistiken (sofern vorhanden) gelöscht!
            </h2>
            <button
              type="button"
              className={`button grow has-width-110 br10 is-primary has-text-weight-bold is-size-7 has-text-white has-hover-primary has-fullheight p-3 has-content-centered has-line-height-full ${disabled ? 'is-loading' : ''}`}
              onClick={() => saveContent(close)}
              disabled={disabled}
            >
              Speichern
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default SaveButton;
