export const addFlowter = (flowter) => ({
  type: 'FLOWTER_ADD',
  flowter,
});

export const updateFlowter = (flowter) => ({
  type: 'FLOWTER_UPDATE',
  flowter,
});

export const setStatistic = (statistic, index) => ({
  type: 'FLOWTER_SET_STATISTIC',
  statistic,
  index,
});

export const addSubmissions = (submissions, index) => ({
  type: 'FLOWTER_ADD_SUBMISSIONS',
  submissions,
  index,
});

export const setSubmissions = (submissions, index) => ({
  type: 'FLOWTER_SET_SUBMISSIONS',
  submissions,
  index,
});

export const deleteSubmission = (data) => ({
  type: 'FLOWTER_DELETE_SUBMISSION',
  flowterID: data.flowterID,
  submissionID: data.submissionID,
});

export const setFlowters = (flowters) => ({
  type: 'SET_FLOWTERS',
  flowters,
});

export const deleteFlowter = (id) => ({
  type: 'FLOWTER_DELETE',
  id,
});
