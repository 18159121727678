import React, { useRef } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Frame from 'components/widgets/creator/widget/Frame';

// Styles
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!!raw-loader!./styles/styles.scss';

/**
 * Wrapper Component for all Widgets
 * @prop {String}
 * @return {Html}
 */
const Widget = (props) => {
  const {
    children, head, layout, isHidden, frameRef, heightPadding,
    styles: widgetStyle,
  } = props;

  let widgetRef = useRef();

  if (frameRef) {
    widgetRef = frameRef;
  }

  const { pos, display } = layout;

  let position = 'flowdustHiddenLeft';

  if (pos === 1 && isHidden) {
    position = 'flowdustHiddenRight';
  }

  if (pos === 1 && !isHidden) {
    position = 'flowdustRight';
  }

  if (pos === 0 && !isHidden) {
    position = 'flowdustLeft';
  }

  const classes = `widget ${position} ${(display === 0 ? ' flowdustPopup' : '')}`;

  return (
    <>
      <style>
        { styles }
      </style>
      <div
        id={`${widgetStyle}`}
        ref={widgetRef}
        className={classes}
      >
        <Frame head={head} wrapperRef={widgetRef} heightPadding={heightPadding}>
          { children }
        </Frame>
      </div>
    </>
  );
};

Widget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  head: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    pos: PropTypes.number.isRequired,
  }).isRequired,
  isHidden: PropTypes.bool.isRequired,
  frameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]).isRequired,
  styles: PropTypes.string.isRequired,
  heightPadding: PropTypes.number,
};

Widget.defaultProps = {
  heightPadding: 0,
};

export default Widget;
