import React from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Constants
import plans from 'constants/plans';

// Components
import PricingPlan from './PricingPlan';
import PlanContent from 'components/layout/PlanContent';

// Styles
import './styles/planSelector.scss';

const PlanSelector = (props) => {
  const { plan, setPlan, next } = props;

  const selectPlan = (planSelected) => {
    stats.push(['trackEvent', 'Subscription', `Plan selected: ${planSelected}`]);
    setPlan(planSelected);
    next();
  };

  return (
    <div id="planSelector" className="columns has-fullheight is-centered is-vcentered is-relative mt-3 mx-0">
      <div className="column is-11">
        <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-3-tablet is-size-4-mobile">
          Abonnement starten
        </h2>
        <p>
          Wähle deinen gewünschten Plan aus, um alle Vorteile von Flowdust nutzen zu können.
        </p>
        <div className="has-text-centered">
          <p className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch mt-5">
            Wähle einen Plan aus!
          </p>
        </div>
        <div className="columns mb-3 is-centered is-vcentered" id="planWrapper">
          <PricingPlan plan={plans[1]} isSelected={false} selectPlan={selectPlan} />
          <PricingPlan plan={plans[2]} isSelected={false} selectPlan={selectPlan} />
          <PricingPlan plan={plans[3]} isSelected={false} selectPlan={selectPlan} />
        </div>
        <PlanContent />
      </div>
    </div>
  );
};

PlanSelector.propTypes = {
  plan: PropTypes.number.isRequired,
  setPlan: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default PlanSelector;
