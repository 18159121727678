import React from 'react';

import PropTypes from 'prop-types';

const ProgressBar = (props) => {
  const { color, completed } = props;

  return (
    <div className="has-height-7 has-width-70 br10 has-background-grey-light">
      <div
        className="has-fullheight br10"
        style={{ width: `${completed}%`, backgroundColor: color }}
      >
        <span
          role="progressbar"
          aria-label="Progress"
          aria-valuenow={completed}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
};

export default ProgressBar;
