import React, { useMemo } from 'react';

// Libraries
import { sanitizeUrl } from '@braintree/sanitize-url';
import getHubUrl from 'utils/getHubUrl';

// Hooks
import { useSelector } from 'react-redux';

/*
 * Component that prepares and shows a link to the users hub
 */
const HubLink = () => {
  const { creator, hubs } = useSelector((state) => state.hubs);

  const { editIndex, colors } = creator;
  const { url } = editIndex !== -1 ? hubs[editIndex] : '';

  const hubUrl = useMemo(() => {
    const sanitizedURL = sanitizeUrl(url);
    return getHubUrl(sanitizedURL);
  }, [url]);

  return (
    <a
      href={hubUrl}
      rel="noopener noreferrer"
      target="_blank"
      className="is-flex has-content-centered has-text-white br10 p-3 growBig has-line-height-full is-size-7 has-text-weight-bold"
      style={{ backgroundColor: colors[0] }}
    >
      Zum Hub
      <i className="fas fa-external-link-alt has-text-white ml-2" />
    </a>
  );
};

export default HubLink;
