import React, { useRef, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
/* eslint import/no-webpack-loader-syntax: off */
import styles from '!!raw-loader!./styles/styles.scss';

/**
 * General component that handles all the iFrame stuff
 * NOT NEEDED (not working, we have 1 iframe wrapper instead)
 * @return {html}
 */
const Frame = ({ children, ...props }) => {
  const contentRef = useRef();

  const [mountNode, setMountNode] = useState();

  const { heightPadding } = props;
  const addedPadding = heightPadding || 30;

  useLayoutEffect(() => {
    resizeFrame();
    // eslint-disable-next-line
	}, [children, mountNode])

  // set the mound node and start the resize listener
  useLayoutEffect(() => {
    const frameDocument = contentRef.current.contentDocument;
    const frameWindow = contentRef.current.contentWindow;

    // After the component mounted and we have a ref to the frame, save the body in state
    setMountNode(frameDocument.body);

    frameDocument.head.innerHTML = props.head;
    frameWindow.addEventListener('resize', resizeFrame);

    return () => {
      frameWindow.removeEventListener('resize', resizeFrame);
    };
    // eslint-disable-next-line
	}, []);

  // For firefox, we need to set the mountnode via onload handler
  const onload = () => {
    if (!contentRef.current) {
      return;
    }
    const ref = contentRef.current;
    const frameDocument = ref.contentDocument;
    frameDocument.firstChild.lang = 'de';
    frameDocument.head.innerHTML = `<meta charset="UTF-8">${props.head}`;

    setMountNode(ref.contentWindow.document.body);
  };

  // Resize frame according to content
  const resizeFrame = () => {
    const frame = contentRef.current;

    if (!frame) {
      return;
    }

    const wrapper = frame.contentDocument.getElementById('wrapper');

    if (!wrapper) {
      return;
    }

    if (!props.wrapperRef) {
      return;
    }

    const frameContainer = props.wrapperRef.current;

    const height = wrapper.scrollHeight + addedPadding;

    frameContainer.setAttribute('style', `height: ${height}px !important;`);
  };

  return (
    <>
      <style>
        { styles }
      </style>
      <iframe onLoad={onload} title="flowdust" id="flowdustFrame" ref={contentRef}>
        {mountNode
          && createPortal((
            <div id="container">
              <div id="wrapper">
                {children}
              </div>
            </div>),
          mountNode)}
      </iframe>
    </>
  );
};

export default Frame;
