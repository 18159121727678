import React, { useState, useEffect } from 'react';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Actions
import { addSubmissions } from 'reducer/hubs';

// Constants
const AMOUNT_TO_LOAD = 20;

const LoadMoreButton = () => {
  const [disabled, setDisabled] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [status, setStatus] = useState('');

  const { statistic } = useSelector((state) => state.hubs);
  const { _id, submissions } = statistic;
  const { get } = useAPI();

  const dispatch = useDispatch();

  useEffect(() => {
    // if there are not enough submissions to load, dont show button
    if (submissions.length < AMOUNT_TO_LOAD) {
      setShowButton(false);
    }
  }, [submissions]);

  const loadMore = async () => {
    try {
      setStatus('');
      setDisabled(true);

      // get oldest entry
      const date = submissions[submissions.length - 1].createdAt;

      const newSubmissions = await get(`/hubs/responses?id=${_id}&date=${date}`);

      dispatch(addSubmissions(newSubmissions));

      // dont show button if there is nothing more to load
      if (newSubmissions.length < AMOUNT_TO_LOAD) {
        setShowButton(false);
      }

      setDisabled(false);

      setStatus('Neue Ergebnisse wurden geladen!');
    } catch (e) {
      setDisabled(false);
      setStatus('Konnte weitere Ergebnisse nicht laden!');
    }
  };

  return (
    showButton
      ? (
        <div className="has-text-centered">
          <button
            type="button"
            className={`button has-background-midnightblue has-hover-midnightblue has-text-white is-size-7 has-text-centered is-rounded ${disabled ? 'is-loading' : ''}`}
            onClick={loadMore}
            disabled={disabled}
          >
            <i className="mr10 fas fa-spinner" />
            Weitere laden
          </button>
          {
            status
            && (
            <p className="has-text-weight-bold has-text-black is-size-7 mt-4">
              {status}
            </p>
            )
          }
        </div>
      )
      : (
        <div className="has-text-centered">
          <p className="is-size-7 has-text-weight-semibold has-text-grey">
            Das waren alle!
          </p>
        </div>
      )

  );
};

export default LoadMoreButton;
