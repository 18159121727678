const INITIAL_STATE = {
  hasData: false,
  flowters: [],
};

// statistic: {},
// questions: [""],
// options: [["", ""]],
// maxNumberOfChecks: [1],
// checks:[[0,0]],

const applyClearFlowters = () => INITIAL_STATE;

function applyAddFlowter(state, action) {
  return {
    ...state,
    hasData: true,
    flowters: [...state.flowters, action.flowter],
  };
}

function applyUpdateFlowter(state, action) {
  return {
    ...state,
    flowters: state.flowters.map((flowter) => (flowter._id === action.flowter._id ? action.flowter : flowter)),
  };
}

function applyDeleteFlowter(state, action) {
  return {
    ...state,
    flowters: state.flowters.filter((flowter) => flowter._id !== action.id),
  };
}

function setStatistic(state, action) {
  const { statistic, index } = action;

  return {
    ...state,
    flowters: state.flowters.map((flowter, flowterIndex) => {
      if (flowterIndex !== index) return flowter;

      flowter.statistic = statistic;
      return flowter;
    }),
  };
}

const setSubmissions = (state, action) => {
  const { submissions, index } = action;
  return {
    ...state,
    flowters: state.flowters.map((flowter, flowterIndex) => {
      if (flowterIndex !== index) return flowter;

      flowter.submissions = submissions;
      return flowter;
    }),
  };
};

const addSubmissions = (state, action) => {
  const { submissions, index } = action;
  return {
    ...state,
    flowters: state.flowters.map((flowter, flowterIndex) => {
      if (flowterIndex !== index) return flowter;

      flowter.submissions = flowter.submissions.concat(submissions);
      return flowter;
    }),
  };
};

const deleteSubmission = (state, action) => {
  const { submissionID, flowterID } = action;
  return {
    ...state,
    flowters: state.flowters.map((currentFlowter) => {
      if (currentFlowter._id !== flowterID) return currentFlowter;

      let submission;

      currentFlowter.submissions = currentFlowter.submissions.filter((currentSubmission) => {
        if (currentSubmission._id !== submissionID) {
          return true;
        }

        // remove submission from array but keep the object to be able to update the global flowter statistic
        submission = currentSubmission.submission;
        return false;
      });

      currentFlowter.meta.submitted -= 1;

      // update global statistic
      currentFlowter.statistic = currentFlowter.statistic.map((qStatistic, index) => qStatistic.map((qCheck, qIndex) => {
        qCheck -= submission[index].checks[qIndex];
        return qCheck;
      }));

      return currentFlowter;
    }),
  };
};

const applySetFlowters = (state, action) => {
  // Sort by creation date to ensure flowters are always in the same order
  const sorted = action.sort((a, b) => {
    if (a.meta.createdAt > b.meta.createdAt) {
      return -1;
    }

    return 1;
  });

  return ({
    ...state,
    hasData: true,
    flowters: sorted,
  });
};

function flowterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_FLOWTERS':
    {
      return applySetFlowters(state, action.flowters);
    }

    case 'FLOWTER_ADD':
    {
      return applyAddFlowter(state, action);
    }

    case 'FLOWTER_DELETE':
    {
      return applyDeleteFlowter(state, action);
    }

    case 'FLOWTER_UPDATE':
    {
      return applyUpdateFlowter(state, action);
    }

    case 'FLOWTER_SET_STATISTIC':
    {
      return setStatistic(state, action);
    }

    case 'FLOWTER_SET_SUBMISSIONS':
    {
      return setSubmissions(state, action);
    }

    case 'FLOWTER_ADD_SUBMISSIONS':
    {
      return addSubmissions(state, action);
    }

    case 'FLOWTER_DELETE_SUBMISSION':
    {
      return deleteSubmission(state, action);
    }

    case 'CLEAR_STATE':
    case 'FLOWTERS_CLEAR':
    {
      return applyClearFlowters(state);
    }

    default:
      return state;
  }
}

export default flowterReducer;
