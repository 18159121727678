import axios from 'axios';

export default function setupAxiosInterceptor() {
  // axios.interceptors.request.use(async function(config) {
  // 		if(isAuthenticated) {
  // 			let token = await getAccessTokenSilently();
  // 			config.headers = {
  // 				Authorization: `Bearer ${token}`
  // 			}
  // 			console.log(config);
  // 			return config;
  // 		} else {
  // 			return config;
  // 		}
  // 	},
  // 	function(error) {
  // 		// Do something with request error
  // 		return Promise.reject(error);
  // 	});

  axios.interceptors.response.use((response) => response, (error) => {
    // If the server doesnt send any data
    if (!error.response) {
      error.response = { data: 'Server antwortet nicht.' };
    }

    // If it's a server error there's usually no data either
    if (error.response.status === 500) {
      error.response.data = 'Server Fehler. Bitte versuche es später noch einmal.';
    }

    // 401 is invalid token
    if (error.response.status === 401) {
      error.response.data = 'Fehler - Bitte logge dich erneut ein.';
    }

    return Promise.reject(error);
  });
}
