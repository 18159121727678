import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import stats from 'analytics/analytics';

// Components
import { Header, Section, InfoPage } from 'components/layout/infoPages/Templates';

// Assets
import img1 from 'assets/images/layout/flowterbobbles.png';
import img3 from 'assets/images/layout/colorwheel.png';
import img4 from 'assets/images/layout/flowterv2.gif';
import img5 from 'assets/images/layout/log2.png';
import img6 from 'assets/images/layout/newstats2.png';

// Constants
import { WIDGET_CREATOR_START } from 'constants/routes';

// Styles
import styles from './styles.module.scss';

function FlowterInfo() {
  const navigate = useNavigate();

  const clickDemoButton = () => {
    stats.push(['trackEvent', 'Landing - Hero', 'Demo button clicked']);
    navigate(`${WIDGET_CREATOR_START}/0`);
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Feedback Flowter</title>
      </Helmet>
      <InfoPage
        heading={(
          <Header
            heading="Feedback sammeln kann so einfach sein."
            img={img4}
            isGIF
            text="Erstelle mit dem Flowter eine Mini-Umfrage und erhalte schnell und einfach Feedback direkt auf deiner Website, das Dich wirklich weiter bringt."
            button={(
              <button
                type="button"
                className="button has-text-weight-bold is-primary br10 grow is-inline-block has-fullheight py-2 has-border-pastel-light"
                onClick={clickDemoButton}
              >
                <i className="fas fa-play-circle mr-2 mt-1" />
                Zum Editor und loslegen (Kein Account nötig)
              </button>
           )}
          />
         )}
      >
        <h2 className="has-text-weight-bold has-text-black is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-centered mt-3 mb-6 py-5">
          Umfragen, an denen man teilnehmen möchte.
        </h2>
        <Section
          imagePos={0}
          img={img1}
          headline="Mach es dir leicht, Feedback zu sammeln."
          topText="Eine kurze Frage nach der anderen, schnell beantwortbar und alles direkt auf Deiner Website: So machst Du es Deinen Kunden einfach, Feedback abzugeben und Dir bei Deinen Produktentscheidungen zu helfen."
          linkTo={`${WIDGET_CREATOR_START}/0`}
          linkText="Erstelle Deinen Flowter"
        />
        <Section
          imagePos={1}
          img={img6}
          headline="Finde heraus, was Deine Kunden wirklich wollen."
          topText="Lerne das Verhalten Deiner Nutzer kennen. Sieh Dir ihr Feedback an und analysiere ihre Entscheidungen."
          bottomText="Unsere Statistiken bieten Dir einen sofortigen Überlick über die Meinungen Deiner Kunden. Erfahre, was sie denken und wie Du dein Produkt verbessern kannst."
          linkTo={`${WIDGET_CREATOR_START}/0`}
          linkText="Beginne mit Deinem ersten Flowter"
        />
        <Section
          imagePos={0}
          img={img3}
          headline="Genauso individuell wie Deine Website."
          topText="Verändere Aussehen, Position und Zeitpunkt des Erscheinens, um Deinen Flowter genau auf Deine Website - und Deine Kunden - abzustimmen."
          bottomText="Sammle Feedback über eine Umfrage oder lass Deine Nutzer selbst sprechen. Emojis selbstverständlich inbegriffen."
          linkTo={`${WIDGET_CREATOR_START}/0`}
          linkText="Jetzt loslegen und Flowter erstellen"
        />
        <div className="mt100">
          <p className="has-text-right px-2 mb20 mt50 has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile">
            Aktivitätenfeed
          </p>
          <div className="has-background-pastel box boxshadow is-vcentered columns">
            <div className="column is-4 has-text-centered">
              <img
                id={styles.imgPos}
                src={img5}
                alt=""
              />
            </div>
            <div className="column is-5">
              <p
                className="has-text-weight-bold has-text-white mb20 has-line-height-30 is-size-3-desktop"
              >
                Strukturierte Übersicht
              </p>
              <p
                className="has-text-white mt20 has-line-height-30 has-text-weight-semibold is-size-5 is-size-6-mobile"
              >
                Erhalte die Insights Deiner Nutzer auf einem Blick und lerne Deine Zielgruppe noch besser kennen.
              </p>
              <p
                className="has-text-white mt20 has-line-height-30 has-text-weight-semibold is-size-5 is-size-6-mobile"
              >
                Durch unseren Aktivitätenfeed hast Du die Möglichkeit, genau zu sehen, wann und wie abgestimmt wurde und erhältst zusätzliche Informationen über Deine Kunden.
              </p>
            </div>
          </div>
        </div>
      </InfoPage>
    </>
  );
}

export default FlowterInfo;
