import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import * as status from 'constants/status';
import withAuthentication from './withAuthentication';

const withSubscription = (Component) => {
  function WithSubscription(props) {
    const user = useSelector((state) => state.sessionState.user);
    const navigate = useNavigate();

    useEffect(() => {
      if (!user) {
        navigate(routes.LOGIN);
        return;
      }

      const hasSubscription = (
        user.status === status.ACTIVE
        || user.status === status.PAST_DUE
        || user.status === status.WILL_CANCEL
      ) && user.customer;

      if (!hasSubscription) {
        navigate(routes.BILLING);
      }
    }, [user, navigate]);

    if (!user || !(
      user.status === status.ACTIVE
        || user.status === status.PAST_DUE
        || user.status === status.WILL_CANCEL
    ) || !user.customer) {
      return null;
    }

    return <Component {...props} />;
  }

  return withAuthentication(WithSubscription);
};

export default withSubscription;
