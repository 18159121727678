import React, { useState } from 'react';

// Components
import HubExampleTemplates from './HubExampleTemplates';

const TemplateExampleContainer = () => {
  const [templateType, setTemplateType] = useState('hub');

  const isTypeHub = templateType === 'hub';

  /*
<div className="mb-6">
            <button
              type="button"
              onClick={() => setTemplateType('hub')}
              className={`button br10 grow mx-2 has-text-weight-bold ${isTypeHub ? 'has-background-primary has-text-white' : 'has-border-grey-light has-hover'}`}
            >
              Hub Vorlagen
            </button>
            <button
              type="button"
              onClick={() => setTemplateType('flowter')}
              className={`button br10 grow mx-2 has-text-weight-bold ${!isTypeHub ? 'has-background-primary has-text-white' : 'has-border-grey-light has-hover'}`}
            >
              Flowter Vorlagen
            </button>
          </div>
  */

  return (
    <div className="my-6 has-text-centered py-6">
      <div className="columns is-centered is-mobile">
        <div className="column is-9-desktop is-10-tablet is-12-mobile">
          <h3
            className="has-text-weight-bold has-text-black is-size-3-desktop is-size-3-tablet is-size-4-mobile mb-6"
          >
            Starte gleich mit einer Vorlage!
          </h3>

          <div className="mx-3">
            {
                isTypeHub
                  ? <HubExampleTemplates />
                  : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateExampleContainer;
