import React, { useState, useRef } from 'react';

// Libraries
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Constants
import { RULE_MAX_LENGTH, RULE_MAX_AMOUNT } from 'constants/websites';

// Styles
import styles from './styles.module.scss';
import InfoPopup from 'components/utils/InfoPopup';

const ParameterSettings = (props) => {
  const { rules, setRules } = props;

  const inputRef = useRef();

  const [newRule, setNewRule] = useState('');

  const [error, setError] = useState('');

  const addNewRule = () => {
    // Only add new Rule if there is a rule to add
    // and if the rule does not yet exist
    if (newRule.length > 0 && !rules.includes(newRule)) {
      setRules([...rules, newRule]);
      setNewRule('');
    }
  };

  const isRuleValid = (rule) => {
    if (rule.length === 0) {
      return true;
    }

    if (!rule.match(/^[\w0-9_-]+=?[\w0-9_-]* ?$/g)) {
      // TODO: vllt besser erklären? man darf nur buchstaben, zahlen und = _ -
      setError('Es sind nur gültige Parameter erlaubt.');
      return false;
    }
    return true;
  };

  const saveRuleWithButton = (event) => {
    if (event.key === 'Enter') {
      addNewRule();
    }
  };

  const setRuleText = (event) => {
    const text = event.target.value;

    // dont set text if rule is invalid
    // or if there are too many rules already
    if (!isRuleValid(text) || rules.length >= RULE_MAX_AMOUNT) {
      return;
    }

    // if the user writes a space, add the text before the space as a rule
    if (text.charAt(text.length - 1) === ' ') {
      setNewRule(text.substring(0, text.length - 1));
      addNewRule();
      return;
    }

    if (text.length >= RULE_MAX_LENGTH) {
      setError('Wähle bitte einen kürzeren Parameter.');

      stats.push(['trackEvent', 'Website Creator', 'Website param max length reached']);

      return;
    }

    setNewRule(event.target.value);
    setError('');
  };

  // focus the input field
  const setRuleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeRule = (removeIndex) => {
    const newRuleArray = rules.filter((rule, index) => {
      if (index !== removeIndex) {
        return rule;
      }

      return null;
    });

    setRules(newRuleArray);

    // focus input field after rule has been removed
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const showRules = () => rules.map((rule, index) => (
    <CSSTransition
      classNames="push"
      key={rule}
      timeout={300}
    >
      <div className="has-border mt10 column pl10 pr10 pt3 pb3 mr5 br5 has-text-left has-background-dash has-max-width-100-percent break-word is-narrow push">
        <p className="dont-allow-selection is-inline is-size-7">
          {rule}
        </p>
        <button
          className="cleanButton is-inline ml10 p0"
          onClick={() => removeRule(index)}
          type="button"
        >
          <i className="fas fa-times has-text-red" />
        </button>
      </div>
    </CSSTransition>
  ));

  return (
    <>
      <div className="mb-4 has-text-left">
        <p className="is-inline is-size-6 has-no-hyphens has-text-black">
          Weitere Parameter angeben, die die URL enthalten soll (optional)
        </p>
        <InfoPopup
          text="Die Parameter, die hier eingegeben werden, müssen exakt so in der URL vorkommen, um den Flowter anzuzeigen. Wird der Name der Domain als Parameter angegeben, so wird der Flowter auf jeder Seite der Domain angezeigt."
        />
        <p className="has-text-right is-size-7 mr5">
          {`${rules.length} / ${RULE_MAX_AMOUNT}`}
        </p>
        <div
          className="br10 has-background-white pt0 pb10 pl10 pr10"
          id={styles.ruleWrapper}
          onClick={setRuleFocus}
          role="button"
          tabIndex="0"
        >
          <div>
            <TransitionGroup
              className="columns m0 is-mobile"
            >
              { showRules() }
            </TransitionGroup>
            {
              rules.length < RULE_MAX_AMOUNT
              && (
              <input
                type="text"
                onChange={setRuleText}
                onKeyDown={saveRuleWithButton}
                value={newRule}
                ref={inputRef}
                className="is-size-6"
              />
              )
            }
          </div>
        </div>
        <p className="has-text-weight-bold has-text-danger">
          { error }
        </p>
      </div>
    </>
  );
};

ParameterSettings.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRules: PropTypes.func.isRequired,
};

export default ParameterSettings;
