import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setEndingLink, deleteEndingLink } from 'reducer/hubs';

// Constants
import { HUB_MAX_ENDING_LINK_URL_LENGTH, HUB_MAX_ENDING_TEXT_LENGTH } from 'constants/hub';

// Components
import TypeSelector from './TypeSelector';

const LinkElement = (props) => {
  const { endingIndex, linkIndex } = props;

  const dispatch = useDispatch();
  const { endings } = useSelector((state) => state.hubs.creator.content);

  const link = endings[endingIndex].links[linkIndex];

  const { type, url, text } = link;

  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => () => {
    // reset error on unmount, so other links on same linkIndex but different endingIndex wont show the (old) error
    setError('');
  },
  [endingIndex]);

  const setLinkUrl = (_url) => {
    if (_url.length >= HUB_MAX_ENDING_LINK_URL_LENGTH) {
      setError('Maximale URL Länge erreicht!');
      return;
    }

    dispatch(setEndingLink({
      endingIndex, linkIndex, url: _url,
    }));
  };

  const setLinkText = (_text) => {
    if (_text.length >= HUB_MAX_ENDING_TEXT_LENGTH) {
      setError('Maximale Text Länge erreicht!');
      return;
    }

    dispatch(setEndingLink({
      endingIndex, linkIndex, text: _text,
    }));
  };

  const setLinkType = (_type) => {
    dispatch(setEndingLink({
      endingIndex, linkIndex, type: _type,
    }));
  };

  const deleteLink = () => {
    dispatch(deleteEndingLink({ endingIndex, linkIndex }));
  };

  return (
    <div className="mt-4 mb-6 has-text-black">
      <div className="mb-3">
        <p
          className="has-text-weight-bold has-text-black is-inline is-size-7"
        >
          {`Link Nr. ${linkIndex + 1}`}
        </p>
        <button
          className="cleanButton has-text-underlined has-text-danger is-inline mx-1"
          onClick={deleteLink}
          type="button"
        >
          Löschen
        </button>
      </div>
      <TypeSelector type={type} setType={setLinkType} />
      <p className="mt-2 is-size-7 has-text-weight-bold">
        URL
      </p>
      <div className="my-1 ml-0 mr-1 columns is-mobile creatorInput px-3">
        <div className="column is-paddingless mx-1">
          <input
            onChange={(event) => setLinkUrl(event.target.value)}
            type="text"
            placeholder="https://..."
            value={url}
            className="input has-text-black is-size-6 is-rounded is-paddingless is-inline has-no-border-bottom has-fullheight has-fullwidth py-3"
          />
        </div>
      </div>
      <p className="is-size-7 has-text-weight-bold mt-3">
        Text der im Link angezeigt werden soll:
      </p>

      <div className="columns is-marginless mt-3 is-mobile">
        <div className="column is-paddingless has-overflow-hidden">

          <textarea
            onChange={(event) => setLinkText(event.target.value)}
            type="text"
            value={text}
            rows={3}
            placeholder="Folge uns auf Social Media!"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className="textarea br10 no-shadow has-border p-3 has-fixed-size has-text-black is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
          />

        </div>
      </div>

      <div className="has-text-centered mt-3">
        <p className="has-text-black has-text-weight-semibold">
          {error}
        </p>
      </div>

    </div>
  );
};

LinkElement.propTypes = {
  endingIndex: PropTypes.number.isRequired,
  linkIndex: PropTypes.number.isRequired,
};

export default LinkElement;
