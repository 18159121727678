import React, { useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import QuestionDisplay from './QuestionDisplay';
import PollQuestion from './PollQuestion';
import EmailQuestion from './EmailQuestion';
import SliderQuestion from './SliderQuestion';
import ProgressIndicator from '../ProgressIndicator';

/**
  * Component that represents one question
  * Can have text and / or some type of media (audio, video, img)
  * as well as multiple options that can be selected
  * After submitting the question, the answer will be pushed to the server
  */
function Question(props) {
  // TODO: translate
  const { index, saveAnswer } = props;

  const { t } = useTranslation(['hub']);

  const { colors, content } = useSelector((state) => state.hubs.creator);
  const { questions } = content;
  const question = questions[index];

  if (!question) return null;

  const {
    type, isOptional,
  } = question;

  const skipQuestion = () => {
    saveAnswer(-2, ['']);
  };

  const showCorrectQuestionType = () => {
    switch (type) {
      case 'POLL': return <PollQuestion index={index} saveAnswer={saveAnswer} />;
      case 'EMAIL': return <EmailQuestion index={index} saveAnswer={saveAnswer} />;
      case 'SLIDER': return <SliderQuestion index={index} saveAnswer={saveAnswer} />;
      default: return null;
    }
  };

  const amountOptions = question.options.length + (question.isOptional ? 1 : 0);
  const layoutVertical = type === 'POLL' && amountOptions >= 3;

  return (
    <div className="box boxshadow br8 p-5 has-min-height-400 is-flex flex-direction-column">
      <ProgressIndicator activeQuestion={index} />
      <div className="columns m-0 is-centered has-flex-1 is-mobile is-multiline">

        <div className={`column has-min-width-280 has-border-right is-flex has-content-centered flex-direction-column is-12-mobile ${layoutVertical ? 'is-10-desktop is-10-tablet' : 'is-6-desktop is-6-tablet'}`}>

          <p className="has-text-grey has-text-medium has-text-left">
            {`${t('hub:question')} ${index + 1}`}
          </p>
          <div className="nmt-2 has-max-width-100-percent break-word">
            <QuestionDisplay index={index} />
            { isOptional
                  && (
                  <button
                    type="button"
                    onClick={skipQuestion}
                    className="button has-height-auto py-2 has-text-white has-text-weight-bold mt-4"
                    style={{ backgroundColor: colors[0] }}
                  >
                    <i className="mr-2 fas fa-forward" />
                    <p className="is-size-7">
                      {t('hub:skip')}
                    </p>
                  </button>
                  )}
          </div>
        </div>
        <div className="column has-min-width-280 is-flex-desktop is-flex-tablet has-content-centered is-12-mobile is-block-mobile">
          <div className="has-fullwidth">
            { showCorrectQuestionType() }
          </div>
        </div>
      </div>
    </div>
  );
}

/*
<p className="mt-4 is-size-7 has-text-weight-light has-text-grey">Drücke Enter zur Eingabe</p>
*/

Question.propTypes = {
  index: PropTypes.number.isRequired,
  saveAnswer: PropTypes.func.isRequired,
};

export default Question;
