import React from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setQuestionMedia, setAllowText,
} from 'reducer/hubs';

// Components
import FileUploader from 'components/hub/HubContentEditor/FileUploader';
import QuestionEditor from '../QuestionEditor';
import AllowTextEditor from '../AllowTextEditor';

const SliderQuestion = (props) => {
  const dispatch = useDispatch();
  const { id: hubID, content } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const { id } = props;

  const question = questions[id];

  const {
    media, allowText,
  } = question;

  const setMedia = (fileUrl, fileType) => {
    stats.push(['trackEvent', 'Hub Slider Question Editor', 'Question Media set']);
    dispatch(setQuestionMedia({ id, source: fileUrl, type: fileType }));
  };

  const _setAllowText = (_allowText) => {
    stats.push(['trackEvent', 'Hub Slider Question Editor', 'Allow text set']);
    dispatch(setAllowText({ id, allowText: _allowText }));
  };

  return (
    <div>
      <p className="mb-5 is-size-7">Nutzer können ihre Aussage mithilfe einer Skala darstellen. </p>
      <QuestionEditor id={id} />
      <div className="pb-4">
        <FileUploader hub={hubID} file={media} setFile={setMedia} />
      </div>
      <div className="mt-4">
        <AllowTextEditor isAllowed={allowText} setAllowed={_setAllowText} id={id} />
      </div>

    </div>
  );
};

SliderQuestion.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SliderQuestion;
