import React, { useState, useEffect } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Components
import ExpireOptions from './ExpireOptions';
import ScreenOptions from './ScreenOptions';

const Settings = (props) => {
  const { showExpire } = props;

  const [showSettings, setShowSettings] = useState(false);
  const { settings } = useSelector((state) => state.creatorState);
  const { expire, screens } = settings;

  useEffect(() => {
    const hasExpireSetting = showExpire && expire !== 604800;

    if (hasExpireSetting || screens.includes(0)) {
      setShowSettings(true);
    }
  }, [settings]);

  const toggleSettings = () => {
    stats.push(['trackEvent', 'Website Creator - Advanced Options', `Toggle settings ${showSettings ? 'ON' : 'OFF'}`]);
    setShowSettings(!showSettings);
  };

  return (
    <div className="br10 has-text-black my-3">
      <button
        className="cleanButton has-fullwidth has-text-left has-text-grey has-cursor-pointer mb-2 mx-0 px-0"
        type="button"
        onClick={toggleSettings}
      >
        <i className="is-inline fas fa-cog mr5" />
        <p className="is-inline mr5">Erweiterte Einstellungen</p>
        <i className={`is-inline is-size-6 ${showSettings ? 'fas fa-angle-up' : 'fas fa-angle-down'}`} />
      </button>
      {
        showSettings
        && (
          <div className="has-background-white br10 px-3 py-3">
            {
              showExpire && <ExpireOptions />
            }
            <ScreenOptions />
          </div>

        )
      }
    </div>
  );
};

Settings.propTypes = {
  showExpire: PropTypes.bool,
};

Settings.defaultProps = {
  showExpire: true,
};

export default Settings;
