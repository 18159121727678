import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import dropin from 'braintree-web-drop-in';
import stats from 'analytics/analytics';

import withSubscription from 'components/hoc/withSubscription';

import { addVAT } from 'utils/calculateVAT';
import * as routes from 'constants/routes';
import plans from 'constants/plans';
import { setPlanDetails, setNewPlan } from 'actions/user';
import { setSubscription } from 'actions/subscription';

import PricingPlan from 'components/checkout/PricingPlan';

import loader from 'assets/images/loading.svg';

function ChangeSubscriptionPlan() {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [dropInInstance, setDropInInstance] = useState();

  const [signal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently } = useAuth0();

  const { plan } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.sessionState.user);
  const dispatch = useDispatch();

  const checkPlanEqual = (first, second) => {
    if (first.cost === second.cost && first.name === second.name && first.views === second.views) {
      return true;
    }

    return false;
  };

  useLayoutEffect(() => {
    if (plan === undefined) {
      navigate(routes.BILLING);
    }

    const index = parseInt(plan, 10);

    // if index is not a number,
    // if it is the test plan
    // if there is no plan with this index
    // or it is the current plan
    // or if it's the current downgrade plan (if there is one)
    // go to billing page

    if (
      Number.isNaN(index)
      || index === 0
      || plans[index] === undefined
      || checkPlanEqual(plans[index], user.planDetails)
      || (user.newPlan && checkPlanEqual(plans[index], user.newPlan))

    ) {
      navigate(routes.BILLING);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let instance;

    const initBraintree = async () => {
      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const paymentToken = await axios.get(`${process.env.REACT_APP_API}/payments/token`, config);

        instance = await dropin.create({
          authorization: paymentToken.data,
          container: '#dropInContainer',
          locale: 'de_DE',
          paypal: {
            flow: 'vault',
          },
          dataCollector: {
            paypal: true,
          },
          threeDSecure: true,
        });

        setDropInInstance(instance);

        instance.on('paymentMethodRequestable', () => {
          setDisabled(false);
        });

        instance.on('noPaymentMethodRequestable', () => {
          setDisabled(true);
        });

        instance.on('paymentOptionSelected', () => {
          setError('');
        });

        instance.clearSelectedPaymentMethod();
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setError('Etwas ist schief gelaufen. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');

        console.log(err);
      }
    };

    initBraintree();

    return () => {
      signal.cancel();
      if (instance) {
        instance.teardown();
      }
    };
    // eslint-disable-next-line
  }, [])

  // dont show anything if we dont have a valid plan
  if (!plans[plan]) {
    return null;
  }

  const newPlan = plans[plan];
  const isDowngrade = newPlan.cost < user.planDetails.cost;
  const date = new Date(user.subscription.nextBillingDate).toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' });

  const amount = addVAT(plans[plan].cost);

  const requestNonce = async () => {
    // Request payment method with braintree dropin
    try {
      const threeDSecureParameters = {
        amount,
        email: user.email,
        billingAddress: {
          givenName: user.customer.firstName,
          surName: user.customer.lastName,
          streetAddress: user.customer.addressData.streetAddress,
          locality: user.customer.addressData.locality,
          postalCode: user.customer.addressData.postalCode,
          countryCodeAlpha2: 'DE',
        },
      };

      const instance = await dropInInstance.requestPaymentMethod({
        threeDSecure: threeDSecureParameters,
      });

      if (instance.liabilityShifted === false) {
        setError('Zahlung mit dieser Karte nicht möglich. Bitte wähle eine andere Zahlungsart.');
        dropInInstance.clearSelectedPaymentMethod();
        return false;
      }

      return instance;
    } catch (e) {
      console.log(e);

      setDisabled(false);
      setLoading(false);

      if (e.name === 'DropinError') {
        setError('Bitte gib eine Zahlungsmethode an.');
        return false;
      }

      setError('Initialisierung fehlgeschlagen. Bitte lade die Seite noch einmal neu.');
      return false;
    }
  };

  const updateSubscription = async () => {
    setError('');
    setDisabled(true);

    if (!dropInInstance) {
      setError('Moment bitte, ich bin noch nicht ganz bereit...');
      setDisabled(false);
      return;
    }

    // Then, send payment method nonce to server
    try {
      setLoading(true);

      const instance = await requestNonce();

      // if we dont have a braintree instance, stop
      if (!instance) {
        return;
      }

      const data = {
        nonce: instance.nonce,
        deviceData: instance.deviceData,
        plan: parseInt(plan, 10),
      };

      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      // update subscription
      const result = await axios.patch(`${process.env.REACT_APP_API}/subscription`, data, config);

      dispatch(setPlanDetails(result.data.planDetails));
      dispatch(setSubscription(result.data.subscription));
      dispatch(setNewPlan(result.data.newPlan));

      dropInInstance.clearSelectedPaymentMethod();

      setLoading(false);
      // after successful change, dont allow changing again
      setDisabled(true);
      setError('Dein Abonnement wurde erfolgreich geändert! Du kannst diese Seite nun verlassen.');

      stats.push(['trackEvent', 'Subscription', `Switch to plan ${data.plan}`]);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      dropInInstance.clearSelectedPaymentMethod();
      setError(e.response.data);
      setLoading(false);
    }
  };

  return (
    <div className="p20">
      <h2
        className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile"
      >
        Plan wechseln
      </h2>
      {
          isDowngrade
            ? (
              <div className="has-text-black">
                <p className="is-inline">Dein Plan wird</p>
                <p className="has-text-weight-bold is-inline"> nach Ablauf deines Abos </p>
                <p className="is-inline">
                  {`heruntergestuft. Du kannst deinen jetzigen Plan noch bis zum ${date}
                  nutzen. Danach wird dein neuer Plan beginnen.`}
                </p>
              </div>
            )
            : <p>Dein Plan wird sofort hochgestuft und die Anzahl deiner verfügbaren Impressionen und Hubs wird erhöht.</p>
        }
      <div className="columns is-marginless is-centered mt-6">
        <div className="column is-narrow">
          <p className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch has-text-centered">
            Dein neuer Plan:
          </p>
          <div className="is-flex has-content-centered">
            <PricingPlan plan={newPlan} showButton={false} />
          </div>
        </div>
        <div className="column is-6-desktop is-7-tablet is-offset-1-desktop is-offset-1-tablet">
          <div className="box boxshadow">
            <p className="has-text-weight-bold has-text-black mb-1">
              Wie möchtest Du bezahlen?
            </p>
            <div id="dropInContainer" />
            <div className="has-text-centered mt-6">
              <button
                disabled={disabled || isLoading}
                onClick={updateSubscription}
                type="button"
                className="button has-white-space has-fullheight has-text-weight-bold has-background-pastel has-no-border has-text-white br10 grow"
              >
                Plan wechseln und mit ausgewählter Zahlungsart bezahlen
              </button>
              <div className="mt-3">
                {
                  isLoading
                    ? <img src={loader} alt="" />
                    : (
                      <p className="mb-3 mt-5 has-text-black has-text-weight-bold is-size-6">
                        {error}
                      </p>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withSubscription(ChangeSubscriptionPlan);
