import React, { useMemo, useState } from 'react';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';

// Components
import { Helmet } from 'react-helmet-async';
import HubExampleTemplates from 'components/layout/landing/TemplateExampleContainer/HubExampleTemplates';
import GeneralHubTemplates from './categories/GeneralHubTemplates';
import HubTemplates from 'components/hub/HubTemplates';

// Templates
import { templates } from 'components/hub/templates';

const CATEGORIES = [
  {
    label: 'Alle',
    value: '',
  },
  {
    label: 'Allgemein',
    value: 'general',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
  {
    label: 'Feedback',
    value: 'feedback',
  },
];

function HubTemplateGallery() {
  const [category, setCategory] = useState('');

  const { isAuthenticated } = useAuth0();

  const renderedNavbar = useMemo(() => CATEGORIES.map((_category, index) => {
    const { label, value } = _category;

    return (
      <button
        type="button"
        className={`has-fullwidth has-cursor-pointer has-hover has-text-left br10 px-3 py-1 has-no-border is-size-6 is-block my-2 ${category === value ? 'has-background-dash has-text-black has-text-weight-semibold' : 'has-no-background has-text-grey'}`}
        onClick={() => setCategory(value)}
        key={index}
      >
        { label }
      </button>
    );
  }), [category]);

  const renderedCategory = useMemo(() => {
    switch (category) {
      case '': return (
        <>
          <GeneralHubTemplates />
          <HubExampleTemplates />

        </>
      );
      case 'general': return <GeneralHubTemplates />;
      default: return null;
    }
  }, [category]);

  /*
  <div className="column is-3-desktop has-max-width-300 is-4-tablet is-12-mobile has-fullheight has-border-right-grey-light">

            <div className="has-background-white px-2 has-min-fullheight has-fullheight pt-6 pb-3 is-flex flex-direction-column">
              <h4
                className="has-text-grey-dark has-text-weight-semibold"
              >
                Kategorien
              </h4>

              <div>
                { renderedNavbar }
              </div>

              <Link
                to={HUB_PROFILE}
                className="has-fullwidth has-cursor-pointer has-hover has-text-left br10 px-3 py-1 has-no-border is-size-6 is-block has-text-grey-darker has-margin-top-auto"
              >
                <i className="fas fa-file-circle-plus mr-2" />
                Neuer leerer Hub
              </Link>

            </div>

          </div>
  */

  return (
    <>
      <Helmet>
        <title>Flowdust | Hub Vorlagen</title>
      </Helmet>
      <div className="has-background-white is-fullheight">

        <div className="columns is-multiline is-mobile has-fullheight m-0">

          <div className="column has-fullheight has-background-white">
            <div className="pb-6 pt-6 pt-4 px-4">

              <div className="columns is-centered">
                <div className="column is-10">
                  <h3
                    className="has-text-weight-bold has-text-black is-size-4-desktop is-size-4-tablet is-size-4-mobile"
                  >
                    Such dir eine Vorlage aus uns leg&apos; los!
                  </h3>
                  <p
                    className="mb-6"
                  >
                    Entdecke verschiedene Vorlagen für viele unterschiedliche Anwendungsfälle.
                  </p>

                  <HubTemplates templates={templates} />

                  {
                    isAuthenticated && (
                      <div className="has-text-centered mt-6 mb-6">
                        <p
                          className="is-size-5-desktop has-text-black has-text-weight-bold is-size-4-tablet is-size-5-mobile"
                        >
                          Du hast eine gute Vorlage, die Du der Community zur Verfügung stellen möchtest?
                        </p>
                        <a
                          href="mailto:hello@flowdust.com"
                          className="button has-text-weight-bold is-primary br10 grow py-2 has-border-pastel-light mt-3"
                        >
                          Schreib uns!
                          <i className="fas fa-envelope ml-2" />
                        </a>

                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubTemplateGallery;
