import React from 'react';

// Hooks
import { useSelector } from 'react-redux';
import LoadMoreButton from '../utils/LoadMoreButton';

// Components
import LogEntry from './LogEntry';

// Styles
import styles from './styles.module.scss';

const FeedbackLog = () => {
  const { statistic } = useSelector((state) => state.hubs);
  const { submissions } = statistic;

  const showSubmissions = () => submissions.map((feedback, _index) => {
    const {
      _id,
    } = feedback;

    return <LogEntry key={_id} index={_index} />;
  });

  /*
  <LoadMoreButton
          index={index}
          amountEntries={submissions.length}
        />
  */

  return (
    <div
      id={styles.wrapper}
      className="box mb-4 px-0 py-0"
    >
      <h2 className="has-border-bottom has-text-weight-bold has-text-pastel is-size-5 has-text-centered py-3">
        Aktivitäten-Protokoll
      </h2>
      <div
        id={styles.log}
        className="has-max-fullheight has-overflow-y-auto"
      >
        { showSubmissions() }
        <div className="my-5">
          <LoadMoreButton />
        </div>
      </div>
    </div>
  );
};

export default FeedbackLog;
