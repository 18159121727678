import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import * as status from 'constants/status';
import stats from 'analytics/analytics';
import { useDispatch, useSelector } from 'react-redux';

function NextButton(props) {
  const { user } = useSelector((state) => state.sessionState);
  const { disabled, text } = props;

  const dispatch = useDispatch();
  const setPage = (page) => dispatch({ type: 'SET_ONBOARDING_PAGE', page });

  const goToNextPage = () => {
    window.scrollTo(0, 0);

    stats.push(['trackEvent', 'Onboarding', 'Next page']);

    setPage(1);
  };

  useEffect(() => {
    window.addEventListener('keyup', handleEnterPressed);

    return () => {
      window.removeEventListener('keyup', handleEnterPressed);
    };
    // eslint-disable-next-line
	}, [disabled])

  const handleEnterPressed = (e) => {
    if (e.keyCode === 13 && disabled !== true) {
      stats.push(['trackEvent', 'Onboarding', 'ENTER pressed - next page']);

      goToNextPage();
    }
  };

  if (user.status !== status.NEW_USER) {
    return null;
  }

  return (
    <div className="has-text-centered">
      <button
        className="mt40 button has-background-pastel has-text-white has-text-weight-bold has-hover-primary is-rounded"
        onClick={() => goToNextPage()}
        disabled={disabled}
        type="button"
      >
        <p>{text}</p>
        <i className="fas fa-caret-right ml10" />
      </button>
      <div className="mt10 min-height-50">
        {
          !disabled
          && (
          <>
            <p className="has-text-grey is-inline">{'oder drücke '}</p>
            <p className="has-text-grey is-inline has-text-weight-bold">ENTER</p>
          </>
          )
        }
      </div>
    </div>
  );
}

NextButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

NextButton.defaultProps = {
  text: 'Weiter',
  disabled: false,
};

export default NextButton;
