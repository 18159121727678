import React from 'react';
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';
import './styles/styles.scss';

const PreviousButton = (props) => {
  const { goBack } = props;

  const goToPreviousPage = () => {
    window.scrollTo(0, 0);
    stats.push(['trackEvent', 'Checkout', 'Previous page']);
    goBack();
  };

  return (
    <button
      className="button grow has-background-grey-light has-text-light has-text-weight-bold br12"
      id="checkoutPreviousButton"
      type="button"
      onClick={() => goToPreviousPage()}
    >
      <i className="fas fa-angle-left" />
    </button>
  );
};

PreviousButton.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default PreviousButton;
