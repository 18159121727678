import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setFilter } from 'reducer/hubs';

// Components
import Dropdown from 'components/utils/Dropdown';

const CPUFilter = () => {
  // const { filter, setFilter } = props;

  const { analytics, filter } = useSelector((state) => state.hubs.statistic);
  const { cpu } = analytics;

  const dispatch = useDispatch();

  const allCPUs = useMemo(() => cpu.map((_cpu) => ({
    label: _cpu.name,
    value: _cpu.name,
  })), []);

  const NoOptionsMessage = () => <p className="has-text-centered">Das waren alle!</p>;

  const _setFilter = (newFilter) => {
    dispatch(setFilter({ cpus: newFilter }));
  };

  return (
    <div className="field has-width-280 has-margin-auto">
      <p className="is-inline has-text-weight-semibold">CPUs</p>
      <i className="mx-2 is-inline fas fa-tachometer-alt" />
      <Dropdown
        value={filter.cpus}
        isMulti
        labels={allCPUs}
        onChange={_setFilter}
        placeholder="Alle CPUs"
        noOptionsMessage={NoOptionsMessage}
      />
    </div>

  );
};

export default CPUFilter;
