import React from 'react';
import Popup from 'reactjs-popup';
import stats from 'analytics/analytics';
import './styles/nameCreator.scss';
import { connect } from 'react-redux';
import * as limits from 'constants/widgets';

/**
 * Component to collect the name of the widget
 */
function NameCreator(props) {
  const {
    name, setName, error, setError,
  } = props;

  const setNameText = (name) => {
    if (name.length > limits.WIDGET_MAX_NAME_LENGTH) {
      stats.push(['trackEvent', 'Widget Creator', 'Widget name max length reached']);

      setError('Kurz und knapp ist unser Motto! Wähle doch bitte einen kürzeren Namen.');

      return;
    }

    setError('');
    setName(name);
  };

  return (
    <div className="mb30 mt30" id="nameCreatorWrapper">
      <div className="columns is-marginless">
        <div className="column has-text-black has-info is-paddingless">
          <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">1</p>
          <h2 className="has-text-weight-bold is-size-6 has-text-centered pt10 is-inline">Gib deinem Flowter einen Namen</h2>
          <Popup
            trigger={(
              <div className="cleanButton is-inline ml5">
                <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
              </div>
        )}
            position="top center"
            on={['hover']}
            keepTooltipInside="#root"
            repositionOnResize
            className="mt10"
            contentStyle={{ background: '#fff' }}
          >
            {
               () => (
                 <div className="columns is-marginless">
                   <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-grey">
                     <p className="is-size-7">Du kannst verschiedene Flowter hinzufügen. Um diese voneinander zu unterscheiden, wird ein Name benötigt. Dieser ist nur für dich im Dashboard sichtbar.</p>
                   </div>
                 </div>
               )
          }
          </Popup>
        </div>
      </div>
      <div className="columns is-marginless is-centered">
        <div className="column is-10">
          <input
            onChange={(event) => setNameText(event.target.value)}
            type="text"
            placeholder="Name"
            value={name}
            className="creatorInput input is-size-6 has-text-black has-text-centered has-background-white is-medium has-text-weight-bold"
          />
        </div>
      </div>
      <p className="has-text-centered has-text-danger has-text-weight-semibold">{error}</p>
    </div>
  );
}

const mapStateToProps = (state) => ({
  name: state.creatorState.name,
  error: state.creatorState.nameError,
});

const mapDispatchToProps = (dispatch) => ({
  setName: (name) => dispatch({ type: 'SET_NAME', name }),
  setError: (error) => dispatch({ type: 'SET_NAME_ERROR', error }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameCreator);
