import { combineReducers } from 'redux';
import sessionReducer from './session';
import flowterReducer from './flowters';
import statusWidgetReducer from './statusWidgets';
import websiteReducer from './websites';
import cookieReducer from './cookie';
import creatorReducer from './creator';
import flowterCreatorReducer from './flowterCreator';
import statusCreatorReducer from './statusCreator';
import hubReducer from './hubs';

// import { withReduxStateSync } from 'redux-state-sync';
// formState are the surveys
// widgetState is the metaData for the surveys
const rootReducer = combineReducers({
  sessionState: sessionReducer,
  flowterState: flowterReducer,
  statusWidgetState: statusWidgetReducer,
  websiteState: websiteReducer,
  cookieState: cookieReducer,
  creatorState: creatorReducer,
  flowterCreator: flowterCreatorReducer,
  statusCreatorState: statusCreatorReducer,
  hubs: hubReducer,
});

export default rootReducer;
// export default withReduxStateSync(rootReducer);
