import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import loader from 'assets/images/loading.svg';

// Actions
import { setUser } from 'actions/user';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// Libraries
import axios from 'axios';

function PaymentHistory() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const user = useSelector((state) => state.sessionState.user);
  const dispatch = useDispatch();

  const [signal] = useState(axios.CancelToken.source());
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const loadInvoices = async () => {
      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        // Get customer data
        const response = await axios.get(`${process.env.REACT_APP_API}/payments/invoices`, config);

        dispatch(setUser({ invoices: response.data }));
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setError('Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');
        console.log(err);
      }
    };

    if (user.invoices === undefined) {
      loadInvoices();
    }
  }, []);

  const showInfo = () => {
    if (!user.invoices) {
      return (
        <div className="has-text-centered">
          <img src={loader} alt="" />
        </div>
      );
    }

    if (user.invoices.length === 0) {
      return (
        <p
          className="has-text-weight-bold has-text-black has-text-centered"
        >
          Noch keine Rechnungen vorhanden.
        </p>
      );
    }

    return null;
  };

  const downloadInvoice = async (invoiceID, date) => {
    setDisabled(true);
    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
        responseType: 'blob',
      };

      // Get customer data
      const response = await axios.get(`${process.env.REACT_APP_API}/payments/invoice/${invoiceID}`, config);

      const blob = response.data;

      // download pdf
      // const blob = new Blob([response.data], { type: response.data.type });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;

      link.setAttribute('download', `Flowdust Rechnung - ${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);

      setDisabled(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err);
      setDisabled(false);
    }
  };

  const displayPrice = (price) => `${price.replace('.', ',')} €`;

  const displayDate = (date) => new Date(date).toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' });

  const showInvoices = () => {
    if (!user.invoices) {
      return null;
    }

    return user.invoices.map((invoice, index) => (
      <tr
        className="has-text-weight-semibold has-border-bottom"
        key={index}
      >
        <td>{displayDate(invoice.date)}</td>
        <td className="py-4">{displayPrice(invoice.total)}</td>
        <td>
          <button
            className="cleanButton is-underlined"
            type="button"
            disabled={disabled}
            onClick={() => downloadInvoice(invoice.invoice_id, invoice.date)}
          >
            Download
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="has-fullwidth">
      <p className="has-text-weight-bold has-text-black">
        Zahlungsverlauf
      </p>
      <div className="table-container py-3 px-3">
        <table className="table is-striped has-fullwidth">
          <thead>
            <tr className="has-background-light">
              <th className="has-text-centered">Datum</th>
              <th className="has-text-centered">Betrag</th>
              <th className="has-text-centered">Rechnung</th>
            </tr>
          </thead>
          <tbody className="has-text-centered">
            { showInvoices() }
          </tbody>
        </table>
      </div>
      { showInfo() }
    </div>
  );
}

export default withAuthentication(PaymentHistory);
