import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Components
import { Link } from 'react-router-dom';

// Constants
import * as routes from 'constants/routes.js';

const TermsOfService = () => (
  <>
    <Helmet>
      <title>Flowdust - Allgemeine Geschäftsbedingungen</title>
    </Helmet>

    <div className="column">
      <div className="columns is-centered">
        <div className="pb60 mt30 mb30 pl20 pr20 column is-10 has-text-justified box has-background-white is-size-7">
          <h2 className="mb30 has-text-centered has-text-weight-bold has-text-pastel is-size-6 mt30 break-word">Allgemeine Geschäftsbedingungen</h2>
          <p>Flowdust Online Services UG (haftungsbeschränkt) stellt über seine Website einen internetbasierten Onlinedienst zur Verfügung, auf dessen Basis die Kunden Widgets erstellen und diese auf ihre eigene Website einbinden können. Die nachfolgenden Bedingen regeln die Inanspruchnahme des Dienstes durch den Endkunden (im Folgenden „Kunde“ genannt).</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§1 Geltungsbereich</p>
          <p>(1) Die Flowdust Online Services UG (haftungsbeschränkt) (im Folgenden Flowdust genannt) ist Betreiber des Online-Dienstes Flowdust und erbringt ihre Leistungen im Zusammenhang mit dem Dienst Flowdust - gleichgültig ob unentgeltlich oder entgeltlich – ausschließlich auf der Grundlage dieser Geschäftsbedingungen. Die Geschäftsbedingungen sind an alle Kunden der Leistungsangebote von Flowdust gerichtet.</p>
          <p className="is-inline">(2) Es finden die Allgemeinen Geschäftsbedingungen in ihrer jeweils gültigen Fassung Anwendung. Sie gelten in ihrer jeweils gültigen Fassung auch für alle künftigen Geschäftsbeziehungen, auch wenn sie nicht noch einmal ausdrücklich vereinbart werden. Der Kunde ist dazu angehalten, sich vor jeder Bestellung bzw. Vertragsverlängerung über die aktuellen AGB zu informieren. Die AGB sind jederzeit einzusehen unter:</p>
          <Link to={routes.TERMS_OF_SERVICE} className="is-inline">{' https://www.flowdust.com/tos'}</Link>
          <p>(3) Die Allgemeinen Geschäftsbedingungen gelten ausschließlich. Abweichende Bedingungen des Kunden erkennen wir nicht an, außer wir haben ausdrücklich schriftlich ihrer Geltung zugestimmt.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 2 Leistungsbeschreibung, Zustandekommen des Vertrags</p>
          <p>(1) Der Leistungsumfang ist jederzeit auf der Landing Page des Services unter 'Preise' einsehbar.</p>
          <p>(2) Flowdust ist berechtigt, den Umfang der angebotenen Funktionalität des Dienstes jederzeit nach eigenem Ermessen zu ändern, insbesondere zu erweitern und umzugestalten. Diese Änderungen stellen keinen Kündigungsgrund dar. Über wesentliche Änderungen wird der Kunde per E-Mail informiert.</p>
          <p>(3) Die Angebote von Flowdust sind grundsätzlich freibleibend und unverbindlich, es sei denn Flowdust bezeichnet diese ausdrücklich als verbindlich.</p>
          <p>(4) Indem der Kunde auf der Website https://www.flowdust.com einen Account erstellt und einen der Tarife auswählt, gibt er sein Angebot ab. Der Kunde erhält dann eine elektronische Bestätigung von Flowdust. Mit dieser Bestätigung nimmt Flowdust das Angebot des Kunden an und der Vertrag über die Nutzung des Dienstes kommt gemäß diesen Geschäftsbedingungen zustande.</p>
          <p>Der Kunde kann den Dienst sofort nutzen. Mit dem Zahlungseingang bekommt der Kunde den vollständigen Zugriff auf alle Leistungen des Dienstes.</p>
          <p>(6) Ist der Vertrag auf bestimmte Zeit geschlossen, dann endet der Vertrag automatisch am Ende der Laufzeit, sobald er auf „Abonnement kündigen“ der Zahlungsseite geklickt hat. Ansonsten wird das Abonnement jeden Monat für einen weiteren Monat verlängert.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 3 Preise, Preisänderungen, Zahlungsbedingungen</p>
          <p>(1) Für die Nutzung des Dienstes hat der Kunde eine Nutzungsgebühr zu zahlen. Bei allen vereinbarten Preisen handelt es sich um Festpreise.</p>
          <p>(2) Nach der Registrierung bzw. Anmeldung des Kunden bei dem Dienst wird Flowdust dem Kunden eine Rechnung ausstellen und auf elektronisch zukommen lassen. Dieser Betrag wird monatlich vom angegebenen Konto des Kunden abgebucht. Der Kunde wählt seine Zahlungsmethode vor dem Start des Abonnements und kann diese jederzeit ändern.</p>
          <p>(3) Der Preis, zu dem der Vertrag geschlossen wird, bleibt für die Dauer einer Nutzungsperiode gültig und ändert sich für den Kunden während dieser Periode nicht. Flowdust behält sich vor, die Preise für die Tarife jederzeit nach eigenem Ermessen zu ändern. Bei einer Vertragsverlängerung verliert der vorhin vereinbarte Preis seine Gültigkeit. Der Vertrag wird dann zu dem Preis verlängert, der zu dem Zeitpunkt der Verlängerung für den jeweiligen Tarif gültig ist.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 4 Pflichten des Kunden</p>
          <p>(1) Der Kunde darf sein Benutzerkonto Dritten nicht zugänglich machen und hat die dafür entsprechenden Zugangsdaten und das persönliche Passwort vor dem Zugriff Dritter zu schützen.</p>
          <p>(2) Der Kunde hat sein Passwort in regelmäßigen Abständen aus Gründen der Sicherheit zu ändern. Die Änderung des Passwortes kann er online vornehmen. Sofern der Kunde den Verdacht hat, dass Dritte Kenntnis vom Passwort haben, hat er unverzüglich dieses zu ändern.</p>
          <p>(3) Liegt ein hinreichender Verdacht darauf vor, dass der Zugang des Kunden durch einen Dritten unberechtigt benutzt wird, so kann Flowdust den Zugang des Kunden zum Dienst sperren bis der Verdacht entkräftet ist. Der Kunde wird in diesem Falle unverzüglich über die Sperrung informiert und erhält neue Zugangsdaten von Flowdust sobald der Vorfall geklärt ist.</p>
          <p>(4) Der Kunde darf den Dienst ausschließlich für den vorhergesehen Zweck nutzen. Er darf insbesondere den Dienst nicht missbräuchlich nutzen, z.B. Versenden von Werbe-E-Mails (Spamming), Junk-Mails, Viren, Malware oder sonstige Arten von schadhafter Software, Code oder Links.</p>
          <p>(5) Der Kunde darf den Dienst nicht in einem Maß nutzen, welches weit über die durchschnittliche Nutzung hinaus geht, insbesondere wodurch die Servergeschwindigkeit und -stabilität andere Benutzer in Ihrer Nutzung beeinträchtigt.</p>
          <p>(6) Dem Kunden ist es untersagt, ohne ausdrückliche Genehmigung von Flowdust, das System auf Schwachstellen zu testen oder zu scannen.</p>
          <p>(7) Der Kunde ist allein für die Inhalte der von ihm im Internet veröffentlichten oder Flowdust zur Veröffentlichung übergebenen Inhalte oder Teilen davon verantwortlich. Der Kunde verpflichtet sich, bei der Veröffentlichung von Inhalten und/oder bei der Übergabe von Inhalten an Flowdust zur Veröffentlichung die gesetzlichen Bestimmungen zu beachten, insbesondere die in Deutschland und weltweit geltenden Gesetze zum Schutze von Kindern und Jugendlichen sowie Urhebern und zum Schutz vor Terrorismus und Gewalt zu beachten. Der Kunde ist für die Einhaltung der in Deutschland geltenden Bestimmungen für den elektronischen Geschäftsverkehr verantwortlich.</p>
          <p>(8) Der Kunde stellt bei der berechtigten Benutzung des Dienstes durch Dritte sicher, dass diese den Dienst entsprechend diesen Allgemeinen Geschäftsbedingungen verantwortungsbewusst nutzen. Flowdust haftet nicht für Schäden, die dem Kunden im Rahmen der ihm von Flowdust zur Verfügung gestellten Zugriffs- und Nutzungsmöglichkeiten durch befugte oder unbefugte Nutzung durch Dritte entstanden sind.</p>
          <p>(9) Der Kunde hat seine im Nutzerkonto angelegten vertragsrelevanten Informationen (z.B. Name, E-Mail) ständig aktuell zu halten. Hierfür steht dem Kunden ein entsprechendes Konfigurationsmenü in seinem Nutzerkonto zur Verfügung.</p>
          <p>(10) Dem Kunde ist es untersagt seine Zugangsdaten, für die Nutzung von Flowdust, anderen Personen kenntlich zu machen und diesen damit Zugang zum System zu gewähren. Insbesondere ist es nicht gestattet über die Anzahl der möglichen Benutzerkonten, aus den jeweilig bestellten Leistungen des Tarifes, hinaus weiteren Personen Zugang zu gewähren.</p>
          <p>(11) Der Kunde stellt Flowdust und alle Mitarbeiter von Flowdust von allen Ansprüchen Dritter wegen der Verletzung von Rechten aufgrund des vom Kunden im Internet veröffentlichten oder an Flowdust zur Veröffentlichung übergebenen Inhalt oder Teilen davon frei. Dies umfasst insbesondere die Pflicht, Flowdust und/oder Mitarbeiter von Flowdust von Schadens- oder Aufwendungsersatzansprüchen Dritter, sowie von allen Kosten der Rechtsverfolgung (z.B. Gerichts- und Anwaltskosten) freizuhalten oder freizustellen.</p>
          <p>(12) Verstößt der Kunde gegen eine seiner Pflichten, so hat Flowdust das Recht, das Nutzerkonto des Kunden auf dessen Kosten zu sperren. Bei schwerwiegenden oder wiederholten Verstößen des Kunden gegen seine Pflichten steht Flowdust ein fristloses Kündigungsrecht zu. Im Falle von Falschangaben bei der Anmeldung oder bei missbräuchlicher Nutzung des Dienstes behält sich Flowdust vor, das Kundenkonto mitsamt aller Daten umgehend zu löschen.</p>
          <p>(13) Der Kunde ist verpflichtet, Flowdust nur gemäß diesen Geschäftsbedingungen zu nutzen.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 5 Nutzungsrechte</p>
          <p>Alle Leistungen, die der Kunde innerhalb des Leistungsumfanges über Flowdust bezieht, sind ausschließlich für die eigene Nutzung und die eigenen Zwecke gedacht. Eine gewerbliche Nutzung, insbesondere ein Weiterverkauf als Dienstleistung (Software as a Service), ist nicht gestattet. Die eigene Vermarktung als Anbieter für Online Tools mit Flowdust als Software-Lösung ist ebenfalls, mit den angebotenen Leistungen (Tarifen) auf https://www.flowdust.com, nicht gestattet. Hierfür bedarf es zusätzlicher AGB und einer separaten Lizenzbestimmung.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 6 Geheimhaltung, Datenschutz, Speicherung</p>
          <p>(1) Gemäß § 33 Bundesdatenschutzgesetz weist Flowdust darauf hin, dass personenbezogene Daten gespeichert werden. Diese Daten können, falls erforderlich und soweit der Kunde nicht ausdrücklich widerspricht, ausschließlich zum Zwecke der Vertragsabwicklung an Dritte weitergegeben werden. Der Kunde hat das Recht, seine Einwilligung zu dieser Speicherung und Verwendung seiner personenbezogenen Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.</p>
          <p>(2) Flowdust verpflichtet sich, die während der Widgets und Formulare gesammelten Daten sowie die Umfrageergebnisse und Statistiken des Kunden in keiner Weise zu nutzen oder Dritten zur Verfügung zu stellen.</p>
          <p>(3) Die obigen Absätze (1) und (2) gelten nur insoweit nicht, als Flowdust gesetzlich verpflichtet ist, Dritten, insbesondere staatlichen Stellen beispielsweise zur Strafverfolgung, solche Daten zu offenbaren.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§7 Datenhaltung, Datenschutz</p>
          <p>(1) Der Kunde kann jederzeit über Flowdust seine eingestellten Widgets, Formulare, Webseiten, Ergebnisse und Statistiken selbst löschen.</p>
          <p className="is-inline">(2) Genauere Informationen zum Thema Datenschutz sind einsehbar unter:</p>
          <Link to={routes.PRIVACY_POLICY} className="is-inline">{' https://www.flowdust.com/privacy'}</Link>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 8 Verfügbarkeit und Gewährleistung des Dienstes</p>
          <p>(1) Flowdust strebt eine Verfügbarkeit des Dienstes von 100% an. Hiervon ausgenommen sind die Zeiten, in denen der Webserver aufgrund von technischen oder sonstigen Problemen nicht verfügbar ist, die nicht im Einflussbereich von Flowdust liegen, wie z.B. im Falle von höherer Gewalt, bei Verschulden Dritter etc. Bei Attacken darf Flowdust die Leistung für den Zeitraum der Attacke einstellen. Im Falle von Störungen oder Unterbrechungen wird Flowdust unverzüglich sämtliche zumutbaren Maßnahmen ergreifen, um die Verfügbarkeit so kurzfristig wie möglich wieder herzustellen.</p>
          <p>(2) Flowdust steht dafür ein, dass der Dienst seine Hauptfunktionen im Wesentlichen erfüllt. Die Gewährleistung erstreckt sich nicht auf solche Schäden und/oder Störungen die verursacht werden, weil der Kunde gegen Bestimmungen dieses Vertrages verstößt. Soweit ein Mangel des Dienstes vorliegt, ist Flowdust zunächst zur Nacherfüllung berechtigt. Im Falle mindestens zweimaligen Fehlschlagens der Nacherfüllung ist der Kunde berechtigt, zu mindern oder nach seiner Wahl vom Vertrag zurückzutreten. Die Verpflichtung zur Gewährleistung ist zeitlich auf die Laufzeit des jeweiligen Vertrages beschränkt.</p>
          <p>(3) Der Kunde und Flowdust stimmen überein, dass es nach dem Stand der Technik nicht möglich ist, Software so zu erstellen, dass sie für alle Anwendungsbedingungen fehlerfrei ist. Der Kunde wird Flowdust Fehler mitteilen und die Mängel genau benennen. Der Kunde wird zudem auf Verlangen von Flowdust die von ihm eingesetzten Daten, die zum Fehler geführt haben, sowie die zur Fehleranalyse und -behebung benötigten Mitwirkungsleistungen in geeigneter Weise und unentgeltlich für die Vertragsabwicklung zur Verfügung stellen.</p>
          <p>(4) Falls Aufwendungen durch Mängelrügen entstehen, die nicht auf Mängeln der von Flowdust erbrachten Leistung beruhen, so wird der Kunde Flowdust die entstandenen Kosten vergüten.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§9 Haftung und Haftungsbeschränkung</p>
          <p>(1) Die Haftung von Flowdust und seiner Vertreter und Erfüllungsgehilfen richtet sich ausschließlich nach den folgenden Bestimmungen.</p>
          <p>(2) Flowdust haftet nach den gesetzlichen Bestimmungen für Schäden, die auf einem vorsätzlichen oder grob fahrlässigen Verhalten seitens Flowdust beruhen oder darauf, dass Flowdust schuldhaft eine wesentliche Vertragspflicht verletzt hat. Die Schadensersatzhaftung ist in diesen Fällen auf vorhersehbare, typischerweise eintretende Schäden begrenzt, soweit Flowdust kein vorsätzliches oder grob fahrlässiges Verhalten angelastet wird.</p>
          <p>(3) Im Übrigen ist die Haftung von Flowdust auf Schadensersatz ausgeschlossen. Dies gilt insbesondere für entgangenen Gewinn oder sonstige direkte oder indirekte Folgeschäden beim Kunden oder bei Dritten. Hierzu zählen auch Schäden an der Hardware oder Software des Kunden oder der Verlust von Daten beim Kunden, etwa durch Viren; der Abruf der Untersuchungsergebnisse von der Webseite geschieht insoweit auf eigene Gefahr.</p>
          <p>(4) Flowdust haftet nach Maßgabe von §8 nicht dafür, dass Flowdust jederzeit verfügbar und frei von technischen Mängeln, Störungen oder Beschränkungen sind.</p>
          <p>(5) Bei Ausfällen von Flowdust wegen einer außerhalb des Verantwortungsbereiches von Flowdust liegenden Störung erfolgt keine Rückvergütung von Gebühren oder sonstiger Schadensersatz.</p>
          <p>(6) Flowdust haftet nicht dafür, dass der Kunde den von ihm mit der Nutzung von Flowdust verfolgten Zweck erreichen kann, insbesondere nicht dafür, dass ein Fragebogen zu wenige oder für den Kunden unbrauchbare Ergebnisse liefert, weil er von den Befragten nicht in der vorgestellten Weise beantwortet wird. Des Weiteren haftet Flowdust nicht für die Verwendung der Untersuchungsergebnisse durch den Kunden.</p>
          <p>(7) Flowdust haftet nicht, welche Links, Buttons, Bilder, Videos oder Audiodateien vom Kunden in die Formulare oder die Widgets eingefügt werden.</p>
          <p className="has-text-weight-bold mt20 mb20 is-size-5">§ 10 Widerrufsrecht</p>
          <p>(1) Verbraucher im Sinne des § 13 BGB haben das Recht, ihren bestellten Tarif innerhalb von 14 Tagen nach Vertragsabschluss ohne weiteres zu widerrufen. Die Frist zur Einhaltung beträgt 14 Tage und beginnt mit Zustandekommen des Vertrages (Timestamp der Bestellung des Tarifes auf dem Flowdust Server). Zur Wahrung der Widerrufsfrist genügt die Rechtzeitige Absendung des Widerrufs in Form einer E-Mail an hello@flowdust.com</p>
          <p>(2) Hat der Kunde (Verbraucher) Flowdust bereits genutzt, indem er ein Widget oder ein Formular veröffentlicht und/oder Teilnehmerergebnisse erfasst hat, erlischt das Widerrufsrecht. Im Falle eines wirksamen Widerrufs erstattet Flowdust dem Kunden (Verbraucher) seine bereits geleistete Zahlungen zurück.</p>
          <p>{'(3) Jeder Widerruf bedarf der Bestätigung durch einer E-Mail zur Einhaltung seiner Wirksamkeit. '}</p>
        </div>
      </div>
    </div>
  </>
);

export default (TermsOfService);
