import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setFilter } from 'reducer/hubs';

// Components
import Dropdown from 'components/utils/Dropdown';

const LanguageFilter = () => {
  const { analytics, filter } = useSelector((state) => state.hubs.statistic);
  const { lang } = analytics;

  const dispatch = useDispatch();

  const allLanguages = useMemo(() => lang.map((_lang) => ({
    label: _lang.name,
    value: _lang.name,
  })), []);

  const NoOptionsMessage = () => <p className="has-text-centered">Das waren alle!</p>;

  const _setFilter = (newFilter) => {
    dispatch(setFilter({ languages: newFilter }));
  };

  return (
    <div className="field has-width-280 has-text-black has-margin-auto">
      <p className="is-inline has-text-weight-semibold">Sprachen</p>
      <i className="mx-2 is-inline fas fa-language" />
      <Dropdown
        value={filter.languages}
        isMulti
        labels={allLanguages}
        onChange={_setFilter}
        placeholder="Alle Sprachen"
        noOptionsMessage={NoOptionsMessage}
      />
    </div>

  );
};

export default LanguageFilter;
