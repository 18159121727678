import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import stats from 'analytics/analytics';
import { connect } from 'react-redux';
import axios from 'axios';
import notification from 'utils/notification';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import loader from 'assets/images/loading.svg';

function DeleteButton(props) {
  const { websites } = props;

  const { id } = props;

  const [disabled, setDisabled] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const signal = axios.CancelToken.source();

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  const deleteWebsite = async (close) => {
    setDisabled(true);

    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      await axios.delete(`${process.env.REACT_APP_API}/websites/${websites[id]._id}`, config);

      stats.push(['trackEvent', 'Website', 'Website deleted']);

      setDisabled(false);
      close();

      const { deleteWebsite, decrementWebsiteCount } = props;
      deleteWebsite(websites[id]._id);
      decrementWebsiteCount();

      notification.send('Erledigt!', 'Deine gespeicherte Website wurde gelöscht.', 'success');
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      close();

      setDisabled(false);

      console.log(err.response);

      if (!err.response) {
        notification.send('Fehler!', 'Die Website konnte leider nicht gelöscht werden.', 'danger');
        return;
      }

      if (err.response.status === 500) {
        notification.send('Server Fehler!', 'Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      notification.send('Fehler!', err.response.data, 'danger');
    }
  };

  return (
    <Popup
      trigger={(
        <button
          className="button is-inline-block p0 icon-circle has-background-grey-light has-hover has-hover-grey"
          type="button"
        >
          <i className="is-size-7 fas fa-trash has-text-grey" />
        </button>
  )}
      position="top center"
      on="click"
      keepTooltipInside="#root"
      repositionOnResize
    >
      { (close) => (
        <div className="columns is-marginless">
          <div className="column is-marginless is-paddingless pb10 pl5 pr5 has-background-white br22">
            <div className="br22 has-text-centered box">
              <div className="has-text-right">
                <button
                  className="cleanButton"
                  onClick={close}
                  type="button"
                >
                  <i className="fas fa-times has-text-grey-lighter is-size-7" />
                </button>
              </div>
              <h2 className="has-text-centered has-text-black is-size-7">
                Bist Du sicher, dass Du diese Website löschen willst?
              </h2>
              {
               disabled
                 ? <img className="buttonLoader" src={loader} alt="" />
                 : (
                   <button
                     className="pt5 pb5 pl10 pr10 cleanButton mt5"
                     onClick={() => deleteWebsite(close)}
                     disabled={disabled}
                     type="button"
                   >
                     <i className="fas fa-trash-alt has-text-danger is-size-7 is-inline mr5" />
                     <p className="is-inline has-text-danger has-text-weight-semibold is-size-7">Löschen</p>
                   </button>
                 )
               }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

DeleteButton.propTypes = {
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.sessionState.user,
  websites: state.websiteState.websites,
});

const mapDispatchToProps = (dispatch) => ({
  deleteWebsite: (id) => dispatch({ type: 'WEBSITE_DELETE', id }),
  decrementWebsiteCount: () => dispatch({ type: 'DEC_WEBSITE_COUNT' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton);
