import React, { useState, useEffect } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setStatistic } from 'reducer/hubs';

// HOCs
import withHubData from 'components/hoc/withHubData';

// Components
import HubNavbar from '../HubNavbar';
import Statistic from './Statistic';
import Loader from 'components/layout/Loader';

// Constants
import { HUB_LIST } from 'constants/routes';

function HubStatistic() {
  const [hasStatisticData, setHasStatisticData] = useState(false);

  const { hasData, hubs } = useSelector((state) => state.hubs);

  const dispatch = useDispatch();
  const { post } = useAPI();

  const { id: hubIndex } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState();

  useEffect(() => {
    const loadStatistic = async (hubID, index) => {
      try {
        const data = {
          browser: [],
          countries: [],
          cpus: [],
          devices: [],
          languages: [],
          os: [],
          screens: [],
          questions: [],
        };

        const _statistic = await post(`/hubs/statistic/${hubID}`, data);
        dispatch(setStatistic({ statistic: _statistic, hubIndex: index }));
        setHasStatisticData(true);
      } catch (e) {
        setError('Ergebnisse konnten nicht geladen werden.');
        setHasStatisticData(false);
      }
    };

    // if we dont have any hub data, load it first.
    if (!hasData) {
      navigate(HUB_LIST);
      return;
    }

    const index = parseInt(hubIndex, 10);

    if (Number.isNaN(index)) return;

    const hub = hubs[index];

    if (!hub) {
      navigate(HUB_LIST);
      return;
    }

    loadStatistic(hub._id, index);
  }, []);

  return (
    <>
      <Helmet>
        <title>Flowdust - Hub Ergebnisse</title>
      </Helmet>
      <HubNavbar />
      {
        hasStatisticData === false
          ? <Loader error={error} />
          : <Statistic />
      }
    </>
  );
}

export default withHubData(HubStatistic);
