const templates = [

	{
		questions: [{
				question: "Wie findest Du die Übersicht unserer Website?",
				options: ["Gut", "In Ordnung", "Unübersichtlich"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du unsere Website zuverlässig?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du das Design ansprechend?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Konntest Du sofort finden, nach was Du gesuchst hast?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Übersicht",
	},

	{
		questions: [{
				question: "Wie bist Du mit dem Service zufrieden?",
				options: ["Sehr zufrieden", "Zufrieden", "In Ordnung", "Eher weniger", "Überhaupt nicht"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wirst Du uns erneut besuchen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Würde dir etwas fehlen, wenn unsere Website nicht existieren würde?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Meinung!",
		name: "Zufriedenheit",
	},

	{
		questions: [{
				question: "Hast Du das Gefühl, dass wir deinen Wünschen gerecht werden?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du unseren Service besser als gewöhnliche?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Hast Du dir alle Texte der Website durchgelesen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Meinung!",
		name: "Nutzerverständnis",
	},

] // Ende Template Array

export default templates;