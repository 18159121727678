import React, { useState, useEffect } from 'react';
import PrivacyAgreement from 'components/layout/PrivacyAgreement';
import TermsOfService from 'components/layout/TermsOfService';
import NextButton from 'components/onboarding/buttons/NextButton';
import { useSelector } from 'react-redux';
import stats from 'analytics/analytics';

import './styles.scss';

const WelcomeInfo = () => {
  const [hasCheckedGDPR, setHasCheckedGDPR] = useState(false);
  const [hasCheckedTOS, setHasCheckedTOS] = useState(false);
  const [showPrivacyAgreement, setShowPrivacyAgreement] = useState(false);
  const [showTOS, setShowTOS] = useState(false);
  const disabled = !hasCheckedTOS || !hasCheckedGDPR;

  const user = useSelector((state) => state.sessionState.user);
  const { name } = user;

  useEffect(() => {
    stats.push(['trackEvent', 'Onboarding', 'Page WelcomeInfo']);
  }, []);

  const toggleShowTOS = () => {
    setShowTOS(!showTOS);
  };

  const toggleShowPrivacyAgreement = () => {
    setShowPrivacyAgreement(!showPrivacyAgreement);
  };

  const toggleGDPRChecked = () => {
    setHasCheckedGDPR(!hasCheckedGDPR);
  };

  const toggleTOSChecked = () => {
    setHasCheckedTOS(!hasCheckedTOS);
  };

  return (
    <div className="columns is-centered is-mobile m0">
      <div className="column is-8-desktop is-10-tablet is-11-mobile">
        <h2 className="has-text-black has-text-weight-bold is-size-2-desktop is-size-3-tablet is-size-4-mobile">{`Willkommen, ${name}!`}</h2>
        <p className="mt10 has-text-grey is-size-5-desktop">Schön, dass Du dich für Flowdust entschieden hast.</p>
        <div className="columns is-centered m0">
          <div className="column mb20 mt30 p0">
            <div className="">
              <input
                type="checkbox"
                id="tos"
                checked={hasCheckedTOS}
                onChange={toggleTOSChecked}
                className="is-checkradio"
              />
              <label className="has-text-left is-inline-block" htmlFor="tos">
                <p className="is-inline">{'Ich stimme den '}</p>
                <button
                  onClick={toggleShowTOS}
                  className="cleanButton is-inline has-text-pastel has-text-weight-bold is-size-6 m0 p0"
                  type="button"
                >
                  {' Geschäftsbedingungen '}
                </button>
                <p className="is-inline">{' zu'}</p>
              </label>
              {
                showTOS
                && (
                <div className="privacyAgreementWindow">
                  <TermsOfService />
                </div>
                )
              }
            </div>
            <div>
              <input
                type="checkbox"
                id="gdpr"
                checked={hasCheckedGDPR}
                onChange={toggleGDPRChecked}
                className="is-checkradio"
              />
              <label className="is-inline-block" htmlFor="gdpr">
                <p className="is-inline">{'Ich stimme dem '}</p>
                <button
                  onClick={toggleShowPrivacyAgreement}
                  className="cleanButton is-inline has-text-pastel has-text-weight-bold is-size-6 p0"
                  type="button"
                >
                  {' Auftragsverarbeitungsvertrag '}
                </button>
                <p className="is-inline">{' zu'}</p>
              </label>
              {
                showPrivacyAgreement
                && (
                <div className="privacyAgreementWindow">
                  <PrivacyAgreement />
                </div>
                )
              }
            </div>
          </div>
        </div>
        <NextButton disabled={disabled} />
      </div>
    </div>
  );
};

export default WelcomeInfo;
