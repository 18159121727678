const templates = [{
		questions: [{
				question: "Wie hast Du von uns erfahren?",
				options: ["Internetwerbung", "Freunde/Familie", "Zeitung", "Andere"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Warum hast Du dich für unsere Website entschieden?",
				options: ["Vertrauenswürdig", "Empfehlung", "Beste Bewertung", "Andere"],
				maxNumberOfChecks: 3,
			},
			{
				question: "Folgst Du uns auf Social Media?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Ist es dir leicht gefallen, unsere Website zu finden?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Analytics",
	},


	{
		questions: [{
				question: "Wie wahrscheinlich ist es, dass Du uns weiterempfehlen wirst?",
				options: ["Ich werde euch weiterempfehlen!", "Eher unwahrscheinlich", "Ich bin mir noch unsicher"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Wie würdest Du die gesamte Erfahrung unserer Website bewerten?",
				options: ["Sehr gut", "Gut", "In Ordnung", "Schlecht", "Sehr schlecht"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Hilfe!",
		name: "Bewertung",
	},




] // Ende Template Array

export default templates;