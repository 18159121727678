/* ESSENTIALS */
export const LOGIN = '/login';
export const LANDING = '/';
export const HOME = '/home';
export const SIGN_UP = '/signup';
export const BILLING = '/billing';
export const DEMO = '/demo';

/* FLOWTER */
export const FLOWTER_CREATOR = '/flowter/creator';
export const FLOWTER_CREATOR_EDIT = '/flowter/creator/:id';
export const FLOWTER_DATA = '/flowter';
export const FLOWTER_DATA_ID = '/flowter/:id';
export const FLOWTER_TEMPLATES = '/flowter/templates';
export const FLOWTER_LIST = '/flowter/list';
export const FLOWTER_DEMO = '/demo/flowter';

/* STATUS MESSAGES */
export const STATUSWIDGET_CREATOR = '/statusmessage/creator';
export const STATUSWIDGET_CREATOR_EDIT = '/statusmessage/creator/:id';
export const STATUSWIDGET_DATA = '/statusmessage';
export const STATUSWIDGET_DATA_ID = '/statusmessage/:id';
export const STATUSWIDGET_TEMPLATES = '/statusmessage/templates';

/* WIDGETS */
export const WIDGET_CREATOR_START = '/creator';
export const WIDGET_CREATOR_START_WITH_TYPE = '/creator/:id';

/* HUB */
// IMPORTANT: always use hubs, not hub as /hub is the Hub React App (e.g /hub/myhub)
export const HUB_LIST = '/hubs';
export const HUB_DATA_ID = '/hubs/:id';

export const HUB_PROFILE = '/hubs/profile';
export const HUB_PROFILE_EDIT = '/hubs/profile/:id';

export const HUB_CONTENT = '/hubs/content';
export const HUB_CONTENT_EDIT = '/hubs/content/:id';

export const HUB_STATISTIC = '/hubs/statistic';
export const HUB_STATISTIC_ID = '/hubs/statistic/:id';

export const HUB_EDITOR_SELECTOR = '/hubs/create';

export const HUB_DEMO = '/demo/hub';
export const HUB_DEMO_EDITOR = '/demo/hub/editor';

export const HUB_TEMPLATES = '/hubs/templates';
export const HUB_TEMPLATE = '/hubs/template';
export const HUB_TEMPLATE_ID = '/hubs/template/:id';

/* SETTINGS */
export const SETTINGS = '/settings';
export const PRIVACY_SETTINGS = '/settings/privacy';
export const CUSTOMER_INFO_SETTINGS = '/settings/customer';

/* SUBSCRIPTION */
export const CHECKOUT = '/checkout';
export const NO_SUBSCRIPTION = '/subscription/error';
export const CHANGE_PAYMENT_METHOD = '/payment';
export const MANAGE_SUBSCRIPTION = '/subscription';
export const CANCEL_SUBSCRIPTION = '/subscription/cancel';
export const CHANGE_SUBSCRIPTION_PLAN = '/subscription/:plan';

/* WEBSITE */
export const ADD_WEBSITE = '/website';
export const EDIT_WEBSITE = '/website/:id';
export const REDIRECT = '/ref/:id';

/* TERMS */
export const PRIVACY_POLICY = '/privacy';
export const GDPR_CONTRACT = '/gdpr';
export const IMPRESSUM = '/imprint';
export const ABOUT_US = '/aboutus';
export const TERMS_OF_SERVICE = '/tos';
export const PARTNER = '/partner';
export const PRESS = '/press';

/* LAYOUT / LANDING */
export const SCRIPT_INSERT_CONTAINER = '/integration';
export const FEATURES = '/features';
export const FAQ = '/faq';
export const HUB = '/hub';
export const AFFILIATE = '/affiliate';
export const USE_CASES = '/use-cases';
export const SUPPORT = '/support';
export const NEW_USER = '/get-started';

/* INFO PAGES */
export const FLOWTER_INFO = '/flowter/info';
export const STATUSWIDGET_INFO = '/widget/statusmessage';
export const HUB_INFO = '/hub';

/* CUSTOMER INFO PAGES */
export const ECOMMERCE_INFO = '/ecommerce';
export const SERVICES_INFO = '/service';
export const OTHER_INFO = '/other';
