import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const CircleButton = (props) => {
  const { children, onClick, isActive } = props;

  return (
    <button
      type="button"
      className={`is-in-center is-flex has-content-centered button has-text-black has-text-weight-bold has-background-grey-light br100 has-width-40 has-height-40 is-size-7 p-0 ${isActive ? 'has-border-primary-bold' : 'has-border-white-semibold'}`}
      onClick={onClick}
    >
      { children }
    </button>
  );
};

CircleButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CircleButton;
