import React from 'react';
import { useSelector } from 'react-redux';

const WelcomeMessage = () => {
  const { name } = useSelector((state) => state.sessionState.user);

  const hour = new Date().getHours();
  let greeting;

  if (hour >= 6 && hour <= 10) {
    greeting = 'Guten Morgen';
  }
  if (hour > 10 && hour <= 17) {
    greeting = 'Hallo';
  }
  if (hour > 17 && hour <= 22) {
    greeting = 'Guten Abend';
  }
  if (hour > 22 || hour < 6) {
    greeting = 'Gute Nacht';
  }

  return (
    <p className="has-text-weight-bold is-size-4-desktop is-size-5-touch has-text-black mt10">
      {`${greeting}, ${name}.`}
    </p>
  );
};

export default WelcomeMessage;
