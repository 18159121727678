import React from 'react';

// Libraries
import stats from 'analytics/analytics';

// Constants
import { HUB_EDITOR_SELECTOR, HUB_TEMPLATES } from 'constants/routes';

// Components
import { Helmet } from 'react-helmet-async';
import Footer from 'components/layout/Footer';
import BrowserWindow from 'components/layout/BrowserWindow';
import { Link, useNavigate } from 'react-router-dom';

// Assets
import hub from 'assets/images/newvid.gif';
import cafe from 'assets/images/cafe5.png';
import ab from 'assets/images/ab3.png';
import giveaway from 'assets/images/flowhub6.png';
import car from 'assets/images/car3.png';
import cafe2 from 'assets/images/cafe3.png';

function FeedbackHub() {
  const navigate = useNavigate();

  const clickDemoButton = () => {
    stats.push(['trackEvent', 'Hub Landing Page', 'Hub Editor button clicked']);
    navigate(HUB_EDITOR_SELECTOR);
  };

  /*
          <div className="has-text-centered px-4">
            <h1 className="has-text-weight-bold is-size-2-desktop is-size-4-touch has-text-black">
              Flowdust Hub - mehr als nur Feedback
            </h1>
            <h2 className="is-inline has-text-weight-bold is-size-3-desktop is-size-4-touch has-text-black">
              Deine zentrale Anlaufstelle für
            </h2>
            <h2 className="ml10 is-inline has-text-weight-bold is-size-3-desktop is-size-4-touch has-text-pastel">
              Feedback, Growth & Kundeninteraktion
            </h2>
          </div>
  */

  return (
    <div>
      <Helmet>
        <title>Flowdust - Feedback Hub</title>
      </Helmet>

      <div className="has-background-white">
        <div className="py-6">

          <div className="columns is-centered is-mobile mb-6">
            <div className="column is-10">

              <div className="columns is-centered is-vcentered mt-6">
                <div className="column is-4-desktop is-6-tablet is-11-mobile">
                  <h3 className="has-text-weight-bold is-size-3-desktop is-size-4-touch has-line-height-40 is-inline has-text-black">
                    Der einzige Form Builder, den Du brauchst.
                  </h3>
                  <p className="mt-3">
                    Nutze unseren leistungsstarken Editor für Umfragen, A/B Tests, Formulare, Bewertungen und noch vieles mehr. Der Flowdust Hub ist Deine Seite für mehr Kundeninteraktion.
                  </p>
                  <button
                    type="button"
                    className="button has-text-weight-bold is-primary br10 grow is-inline-block has-fullheight py-2 has-border-pastel-light mt-4"
                    onClick={clickDemoButton}
                  >
                    <i className="fas fa-play-circle mr-2 mt-1" />
                    Zum Editor und loslegen (Kein Account nötig)
                  </button>
                </div>
                <div className="column is-offset-1 has-text-centered">
                  <BrowserWindow img={hub} alt="Video zur Erstellung eines Hubs" />
                </div>
              </div>

            </div>
          </div>

        </div>

        <div className="nmb60 is-hidden-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 115 1440 205">
            <path
              fill="#ff914d"
              fillOpacity="1"
              className="has-no-pointer-events"
              d="M0,128L40,122.7C80,117,160,107,240,128C320,149,400,203,480,202.7C560,203,640,149,720,154.7C800,160,880,224,960,224C1040,224,1120,160,1200,138.7C1280,117,1360,139,1400,149.3L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            />
          </svg>
        </div>

        <div className="columns is-marginless is-centered is-mobile pb40">
          <div className="column is-10-desktop is-11-touch is-relative p-0">
            <div className="box boxshadow py-6">

              <div className="has-text-centered-mobile">
                <div
                  className="has-background-dash is-inline-block br10 py-2 has-text-black"
                >
                  <p
                    className="mx-6 has-text-weight-bold"
                  >
                    Anwendungsfälle
                  </p>
                </div>
              </div>

              <div className="mt-5 mb-6 columns is-centered is-vcentered is-mobile">
                <div className="column is-4-desktop is-6-tablet is-11-mobile">
                  <p className="has-text-primary has-text-weight-bold is-size-5 my-3">
                    Logik & Verzweigungen
                  </p>
                  <p className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-touch my-2">
                    Erhalte positive Bewertungen
                  </p>
                  <p>
                    Erhalte mehr positive Bewertungen durch ausgeprägte Logik & Verzweigungen. Leite Deine Teilnehmer in unterschiedliche Richtungen, je nachdem wie sie abgestimmt haben. So stellst Du sicher, dass Kritik nur bei Dir landet und positives Feedback in Bewertungen übergeht.
                  </p>
                  <Link
                    to={HUB_TEMPLATES}
                    className="mt-5 button has-text-white has-background-primary has-text-weight-semibold br10 py-2 has-fullheight grow"
                  >
                    <i className="mr-2 fa-solid fa-code-branch" />
                    <p className="has-text-white is-size-6-desktop is-size-6-touch has-text-weight-semibold">
                      Zu den Vorlagen
                    </p>
                  </Link>
                </div>
                <div className="has-background-white has-max-width-500 column grow is-5-desktop is-5-tablet is-12-mobile is-offset-1-desktop">
                  <BrowserWindow img={cafe} alt="" />
                </div>
              </div>
              <div className="my-5 has-border-grey-light-thin-top" />

              <div className="columns is-centered is-vcentered is-mobile py-6">
                <div className="has-background-white has-max-width-500 column grow is-5-desktop is-5-tablet is-12-mobile">
                  <BrowserWindow img={car} alt="" />
                </div>
                <div className="column is-4-desktop is-6-tablet is-11-mobile is-offset-1-desktop">
                  <p className="has-text-primary has-text-weight-bold is-size-5 my-3">
                    Abstimmungen
                  </p>
                  <p className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-touch my-2">
                    Erhöhe die Kundenzufriedenheit
                  </p>
                  <p>
                    Finde heraus, was Deine Kunden wirklich wollen und erhöhe die Kundenzufriedenheit. Mit dem Flowdust Hub sammelst Du ganz einfach wertvolle Informationen und kommst so schneller voran.
                  </p>
                  <Link
                    to={HUB_TEMPLATES}
                    className="mt-5 button has-text-white has-background-primary has-text-weight-semibold br10 py-2 has-fullheight grow"
                  >
                    <i className="mr-2 fa-solid fa-square-poll-horizontal" />
                    <p className="has-text-white is-size-6-desktop is-size-6-touch has-text-weight-semibold">
                      Zu den Vorlagen
                    </p>
                  </Link>
                </div>
              </div>
              <div className="my-5 has-border-grey-light-thin-top" />
              <div className="my-6 columns is-centered is-vcentered is-mobile">
                <div className="column pl10 pr10 is-4-desktop is-6-tablet is-11-mobile mainContentText">
                  <p className="has-text-pastel has-text-weight-bold is-size-5 my-3">
                    Bewertungen
                  </p>
                  <p className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-touch my-2">
                    Verbessere Qualität & Leistung
                  </p>
                  <p>
                    Lass deine Kunden Dein Produkt oder Deine Dienstleistung bewerten. Stelle Deine neuen Produkte vor, präsentiere Deine neuen Ideen oder lass Deine Nutzer bewerten, wie sehr sie mit Deinem Unternehmen zufrieden sind.
                  </p>
                  <Link
                    to={HUB_TEMPLATES}
                    className="mt-5 button has-text-white has-background-pastel has-text-weight-semibold br10 py-2 has-fullheight grow"
                  >
                    <i className="mr-2 fa-solid fa-star" />
                    <p className="has-text-white is-size-6-desktop is-size-6-touch has-text-weight-semibold">
                      Zu den Vorlagen
                    </p>
                  </Link>
                </div>
                <div className="has-background-white has-max-width-500 column grow is-5-desktop is-5-tablet is-12-mobile is-offset-1-desktop">
                  <BrowserWindow img={cafe2} alt="" />
                </div>
              </div>
              <div className="my-5 has-border-grey-light-thin-top" />
              <div className="my-6 columns is-centered is-vcentered is-mobile">
                <div className="has-background-white has-max-width-500 column grow is-5-desktop is-5-tablet is-12-mobile">
                  <BrowserWindow img={ab} alt="" />
                </div>
                <div className="column pl10 pr10 is-4-desktop is-6-tablet is-11-mobile is-offset-1-desktop">
                  <p className="has-text-primary has-text-weight-bold is-size-5 my-3">
                    A / B Tests
                  </p>
                  <p className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-touch my-2">
                    Triff Entscheidungen
                  </p>
                  <p>
                    Führe kurze A/B Tests durch, um wichtige Entscheidungen mit Hilfe Deiner Nutzer zu treffen. Vergleiche Bilder, Audio- oder Videodateien und überlass den Teilnehmern die Wahl.
                  </p>
                  <Link
                    to={HUB_TEMPLATES}
                    className="mt-5 button has-text-white has-background-primary has-text-weight-semibold br10 py-2 has-fullheight grow"
                  >
                    <i className="mr-2 fa-solid fa-circle-half-stroke" />
                    <p className="has-text-white is-size-6-desktop is-size-6-touch has-text-weight-semibold">
                      Zu den Vorlagen
                    </p>
                  </Link>
                </div>
              </div>
              <div className="my-5 has-border-grey-light-thin-top" />
              <div className="my-6  columns is-centered is-vcentered is-mobile">
                <div className="column pl10 pr10 is-4-desktop is-6-tablet is-11-mobile mainContentText">
                  <p className="has-text-primary has-text-weight-bold is-size-5 my-3">
                    E-Mails sammeln
                  </p>
                  <p className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-touch my-2">
                    Gehe auf Anfragen ein
                  </p>
                  <p>
                    Sammle E-Mails, um potentielle und bereits bestehende Kunden kontaktieren zu können und noch besser auf ihr Anliegen und ihre Fragen eingehen zu können.
                  </p>
                  <Link
                    to={HUB_TEMPLATES}
                    className="mt-5 button has-text-white has-background-primary has-text-weight-semibold br10 py-2 has-fullheight grow"
                  >
                    <i className="mr-2 fa-solid fa-envelope" />
                    <p className="has-text-white is-size-6-desktop is-size-6-touch has-text-weight-semibold">
                      Zu den Vorlagen
                    </p>
                  </Link>
                </div>
                <div className="has-background-white has-max-width-500 column grow is-5-desktop is-5-tablet is-12-mobile is-offset-1-desktop">
                  <BrowserWindow img={giveaway} alt="" />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="pb-3 pt-6 nmb60 is-hidden-mobile mt-6 has-fullwidth">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 115 1440 205">
            <path
              fill="#ff914d"
              fillOpacity="1"
              className="has-no-pointer-events"
              d="M0,128L40,122.7C80,117,160,107,240,128C320,149,400,203,480,202.7C560,203,640,149,720,154.7C800,160,880,224,960,224C1040,224,1120,160,1200,138.7C1280,117,1360,139,1400,149.3L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            />
          </svg>
        </div>

        <div className="has-background-pastel pb-6">
          <h1 className="py-6 is-size-2 has-text-weight-bold has-text-white has-text-centered">
            Features
          </h1>
          <div className="columns is-centered is-mobile pb-6">
            <div className="column box is-6-desktop is-7-tablet is-11-mobile boxshadow br22 p-5 has-text-weight-semibold">

              <div className="my-5 columns is-centered">
                <div className="mx-3 column has-min-width-200 br22 is-4 is-flex align-items-center">
                  <i className="is-inline is-size-4 has-text-midnightblue fa-solid fa-qrcode mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Einfach zu teilen durch einen einzigartigen Link oder QR-Code
                  </p>
                </div>
                <div className="mx-3 column has-min-width-200 br22 is-4 is-flex align-items-center">
                  <i className="is-inline is-size-4  has-text-midnightblue fa-solid fa-palette mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Individuelles Profil für Dein Unternehmen mit allen wichtigen Links
                  </p>
                </div>
              </div>
              <div className="my-5 columns is-centered">
                <div className="mx-3 column br22 is-4 has-min-width-200 is-flex align-items-center">
                  <i className="is-inline is-size-4  has-text-midnightblue fa-solid fa-network-wired mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Smarte Verzweigungslogik
                  </p>
                </div>
                <div className="mx-3 column br22 is-4 has-min-width-200 is-flex align-items-center">
                  <i className="is-inline is-size-4  has-text-midnightblue fa-solid fa-images mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Videos, Bilder und Audiodateien hinzufügbar
                  </p>
                </div>
              </div>
              <div className="my-5 columns is-centered">
                <div className="mx-3 column br22 is-4 has-min-width-200 is-flex align-items-center">
                  <i className="is-inline is-size-4  has-text-midnightblue fa-solid fa-chart-simple mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Aussagekräftige Statistiken mit vielen Filtermöglichkeiten
                  </p>
                </div>
                <div className="mx-3 column br22 is-4 has-min-width-200 is-flex align-items-center">
                  <i className="is-inline is-size-4  has-text-midnightblue fa-solid fa-file mr-2" />
                  <p className="ml-2 is-inline has-text-black">
                    Viele Vorlagen, um sofort loslegen zu können
                  </p>
                </div>
              </div>

            </div>

          </div>

          <div
            className="has-text-white has-text-centered"
          >
            <p
              className="has-text-white is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold"
            >
              Erstelle jetzt Deinen ersten Flowdust Hub!
            </p>
            <Link
              to={HUB_EDITOR_SELECTOR}
              className="button boxshadow grow has-text-black has-background-white br10 has-text-weight-bold has-fullheight mt-5 mb-6"
            >
              <i className="fas fa-play-circle mr-2" />
              Zum Editor (Kein Account nötig)
            </Link>
          </div>

        </div>

        <Footer />
      </div>
    </div>
  );
}

export default FeedbackHub;
