import React, { useState, useEffect } from 'react';
import './styles/scriptInsertContainer.scss';
import shopifyIcon from 'assets/images/shopify.svg';
import joomlaIcon from 'assets/images/joomla.svg';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import stats from 'analytics/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'actions/onboarding';
import { NEW_USER } from 'constants/status';
import ScriptManually from './scriptManuals/ScriptManually';
import ScriptWordpress from './scriptManuals/ScriptWordpress';
import ScriptGoogleTagManager from './scriptManuals/ScriptGoogleTagManager';
import ScriptShopify from './scriptManuals/ScriptShopify';
import ScriptMagento from './scriptManuals/ScriptMagento';
import ScriptShopware from './scriptManuals/ScriptShopware';
import ScriptJoomla from './scriptManuals/ScriptJoomla';
import PreviousButton from './PreviousButton';

function ScriptInsertContainer() {
  const [category, setCategory] = useState(0);

  const { page, status } = useSelector((state) => state.sessionState.user);
  const dispatch = useDispatch();

  useEffect(() => {
    stats.push(['trackEvent', 'Onboarding', 'Page ScriptInsertContainer']);
  }, []);

  const selectCategory = (newCategory) => {
    let categorySelected;
    switch (newCategory) {
      case 1:
        categorySelected = 'Manual';
        break;
      case 2:
        categorySelected = 'Wordpress';
        break;
      case 3:
        categorySelected = 'Google Tag Manager';
        break;
      case 4:
        categorySelected = 'Shopify';
        break;
      case 5:
        categorySelected = 'Magento';
        break;
      case 6:
        categorySelected = 'Shopware';
        break;
      case 7:
        categorySelected = 'Joomla';
        break;
      default: break;
    }
    stats.push(['trackEvent', 'Onboarding - Script Installation', `${categorySelected} installation selected`]);
    setCategory(newCategory);
  };

  const goToMainCategory = () => {
    stats.push(['trackEvent', 'Onboarding - Script Installation', 'Went to main menu']);
    setCategory(0);
  };

  /*
  case 8:
    categorySelected = 'Wix';
    break;

  <CategoryButton
    selectCategory={() => selectCategory(8)}
    icon="fab fa-wix is-size-1"
    text="Ich benutze Wix.com"
  />

  case 8:
    return <ScriptWix />;
  */

  const goToNextPage = () => {
    window.scrollTo(0, 0);

    stats.push(['trackEvent', 'Onboarding', 'Skip Script Installation - Next Page']);

    dispatch(setPage(page + 1));
  };

  const showCategory = () => {
    switch (category) {
      case 1:
        return <ScriptManually />;
      case 2:
        return <ScriptWordpress />;
      case 3:
        return <ScriptGoogleTagManager />;
      case 4:
        return <ScriptShopify />;
      case 5:
        return <ScriptMagento />;
      case 6:
        return <ScriptShopware />;
      case 7:
        return <ScriptJoomla />;
      default:
        return (
          <div className="columns is-relative is-centered m0">
            <div className="column is-11 has-text-black p0 mb40">
              <p className="has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile">Installiere Flowdust auf deiner Website!</p>
              <p className="mt10">Um Flowter nutzen zu können ist es vorher nötig Flowdust deiner Website hinzuzufügen.</p>
              <p className="mb30">
                Nutze unsere Anleitungen und Plugins oder füge das Code-Snippet in die HTML-Datei deiner Website selbst ein.
              </p>
              <div className="columns is-mobile is-centered-mobile">
                <CategoryButton
                  selectCategory={() => selectCategory(2)}
                  icon="fab fa-wordpress has-text-info is-size-1"
                  text="Ich benutze WordPress"
                />
                <CategoryButton
                  selectCategory={() => selectCategory(3)}
                  icon="fas fa-tags has-text-danger is-size-3"
                  text="Ich benutze den Google Tag Manager"
                />
                <CategoryButton
                  selectCategory={() => selectCategory(4)}
                  text="Ich benutze Shopify"
                >
                  <img
                    className="shopSystemIcons"
                    src={shopifyIcon}
                    alt=""
                  />
                </CategoryButton>
                <CategoryButton
                  selectCategory={() => selectCategory(5)}
                  icon="fab fa-magento has-text-pastel is-size-1"
                  text="Ich benutze Magento"
                />
                <CategoryButton
                  selectCategory={() => selectCategory(6)}
                  icon="fab fa-shopware has-text-info is-size-1"
                  text="Ich benutze Shopware"
                />
                <CategoryButton
                  selectCategory={() => selectCategory(7)}
                  text="Ich benutze Joomla"
                >
                  <img
                    className="shopSystemIcons"
                    src={joomlaIcon}
                    alt=""
                  />
                </CategoryButton>
                <CategoryButton
                  selectCategory={() => selectCategory(1)}
                  icon="has-text-primary is-size-2 fas fa-file-code"
                  text="Ich kann das Skript selbst installieren"
                />
                {
                   status === NEW_USER
                   && (
                   <CategoryButton
                     selectCategory={goToNextPage}
                     icon="has-text-grey is-size-2 fas fa-clock"
                     text="Ich installiere Flowdust später"
                   />
                   )
              }
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <SwitchTransition>
      <CSSTransition
        classNames="fade"
        timeout={300}
        key={category}
      >
        <div className="columns is-centered is-mobile m0 p0 has-overflow-y-auto" id="scriptInsertContainer">
          <div className="column m0 pl0 pr0 pt30 pb30 is-11 has-text-black">
            { category > 0 && <PreviousButton goToPreviousPage={goToMainCategory} />}
            { showCategory() }
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

function CategoryButton(props) {
  return (
    <div className="column is-3 categoryButtonContainer">
      <button
        className="columns has-background-white is-paddingless categoryButton has-cursor-pointer grow is-vcentered is-marginless has-border br20 has-fullwidth pl10 pr10"
        onClick={props.selectCategory}
        type="button"
      >
        <div className="column has-text-centered has-maxWidth is-paddingless">
          {
          props.children
            ? props.children
            : <i className={props.icon} />
          }
          <p className="has-text-black is-size-6 pt20 has-text-weight-semibold">{props.text}</p>
        </div>
      </button>
    </div>
  );
}

export default ScriptInsertContainer;
