import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import {
  VictoryPie, VictoryChart, VictoryBar, VictoryAxis,
} from 'victory';

// Components
import SubmissionDisplay from './SubmissionDisplay';

// Hooks
import { useSelector } from 'react-redux';

// Styles
import styles from './styles.module.scss';

import { PALETTE, getColor } from '../palette';

const QuestionStatistic = (props) => {
  const { id, index } = props;

  const { flowters } = useSelector((state) => state.flowterState);

  const questionData = flowters[id].questions[index];
  const statisticData = flowters[id].statistic[index];

  const [statistic, setStatistic] = useState([]);
  const { question, options, maxNumberOfChecks } = questionData;

  const [chartType, setChartType] = useState(0);
  const [activeValue, setActiveValue] = useState(-1);

  useEffect(() => {
    const data = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const value = statisticData[i];

      data.push({
        y: value, x: option, index: i,
      });
    }

    if (questionData.allowText) {
      const lastIndex = statisticData.length - 1;
      data.push({
        y: statisticData[lastIndex],
        x: 'Feedback deiner Nutzer',
        index: lastIndex,
      });
    }

    setStatistic(data);
  }, [chartType, statisticData]);

  const setChartRadiant = () => {
    stats.push(['trackEvent', 'Flowter Statistic', 'Chart Radiant selected']);

    setChartType(0);
  };

  const setChartBar = () => {
    stats.push(['trackEvent', 'Flowter Statistic', 'Chart Bar selected']);

    setChartType(1);
  };

  const onMouseOver = () => ([{
    target: 'data',
    mutation: ({ datum }) => {
      const statIndex = datum.index;
      setActiveValue(statIndex);
      return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex) } });
    },
  }]);

  const onMouseOut = () => ([{
    target: 'data',
    mutation: () => {
      setActiveValue(-1);
      return {};
    },
  }]);

  const renderedActiveValue = useMemo(() => {
    if (activeValue === -1) return null;

    const value = statisticData[activeValue];

    const totalAmount = statisticData.reduce((pv, cv) => pv + cv, 0);
    const percent = ((value / totalAmount) * 100).toFixed(2);

    return (
      <div
        id={styles.tooltip}
        className={chartType === 0 ? 'has-fullheight' : ''}
      >
        {`${value} (${percent}%)`}
      </div>
    );
  }, [activeValue]);

  const showGraph = () => {
    if (statisticData.length === 1) {
      return null;
    }

    if (chartType === 0) {
      return (
        <div className="has-max-width-300 is-center is-relative">
          { renderedActiveValue }
          <VictoryPie
            innerRadius={90}
            padAngle={2}
            data={statistic}
            colorScale={PALETTE}
            labels={() => null}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver,
                onMouseOut,
              },
            }]}
          />
        </div>
      );
    }

    return (
      <div className="has-max-width-600 is-center is-relative">
        { renderedActiveValue }
        <VictoryChart
          domainPadding={30}
        >
          <VictoryBar
            data={statistic}
            barWidth={45}
            style={{ data: { fill: (item) => getColor(item.index) } }}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver,
                onMouseOut,
              },
            }]}
          />
          <VictoryAxis tickFormat={() => ''} />
          <VictoryAxis
            dependentAxis
          />
        </VictoryChart>
      </div>
    );
  };

  const showStatistic = () => statistic.map((stat, statIndex) => {
    const color = getColor(statIndex);
    const name = stat.x;
    const amount = stat.y;

    return (
      <div key={statIndex}>
        <div className="column">
          <div className="columns is-mobile is-tablet is-centered">
            <div className="column is-1 has-text-right">
              <i className="fas fa-square is-size-4" style={{ color }} />
            </div>
            <div className="column is-11-desktop has-text-left customParagraph">
              <p
                className={`is-inline has-text-black ${activeValue === statIndex ? 'has-text-weight-bold' : ''}`}
              >
                {`${name}: `}
              </p>
              <p
                className="is-inline has-text-weight-bold"
                style={{ color }}
              >
                {amount}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  // if this question only has text input as an option
  if (statisticData.length === 1) {
    return (
      <div className="box">
        <h2 className="title br10 pt12 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey">
          {question}
        </h2>
        <SubmissionDisplay flowterIndex={id} questionIndex={index} />
      </div>
    );
  }

  const { allowText } = questionData;

  return (
    <div className="box statisticBox">
      <h2 className="title br10 pt15 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey mb-4 px-3">
        {question}
      </h2>
      <div className="columns is-marginless mt-2">
        <div className="column has-text-right">
          <button
            className="button mx-1"
            onClick={setChartRadiant}
            type="button"
          >
            { chartType === 0
              ? (
                <div className="has-text-pastel has-border-pastel-light br10 p10">
                  <i className="fas fa-chart-pie" />
                </div>
              )
              : (
                <div className="has-text-grey br10 p10 has-border">
                  <i className="fas fa-chart-pie" />
                </div>
              )}
          </button>
          <button
            className="button mx-1"
            onClick={setChartBar}
            type="button"
          >
            { chartType === 0
              ? (
                <div className="has-text-grey br10 p10 has-border">
                  <i className="fas fa-chart-bar" />
                </div>
              )
              : (
                <div className="has-text-pastel has-border-pastel-light br10 p10">
                  <i className="fas fa-chart-bar" />
                </div>
              )}
          </button>
        </div>

      </div>
      {
        maxNumberOfChecks > 1
        && (
        <div className="columns is-paddingless is-marginless">
          <div className="column is-paddingless is-marginless">
            <p className="has-text-weight-medium mb20">
              {`Nutzer konnten bis zu ${maxNumberOfChecks} Möglichkeiten auswählen.`}
            </p>
          </div>
        </div>
        )
      }
      <SwitchTransition>
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={chartType}
        >
          { showGraph() }
        </CSSTransition>
      </SwitchTransition>
      { showStatistic() }
      {
           allowText
           && <SubmissionDisplay flowterIndex={id} questionIndex={index} />
      }
    </div>
  );
};

QuestionStatistic.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default QuestionStatistic;
