import React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import stats from 'analytics/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import { clearCompleteState } from 'actions/user';

function SignOutButton(props) {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  // also dispatch all actions in the axios interceptor if jwt is not valid anymore!
  const signOut = async () => {
    props.hideNavbarMobile();

    stats.push(['resetUserId']);

    Sentry.configureScope((scope) => scope.setUser(null));

    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    dispatch(clearCompleteState());
  };

  return (
    <button
      className="button navbarButton br10 has-hover"
      onClick={() => signOut()}
      type="button"
    >
      <p className="has-text-weight-bold has-text-midnightblue is-size-6 mr10">Abmelden</p>
      <i className="fas fa-sign-out-alt is-size-5 has-text-midnightblue" />

    </button>
  );
}

export default SignOutButton;
