import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

const EndingDisplay = (props) => {
  const { ending, index } = props;
  const {
    text, links,
  } = ending;

  const [showDetails, setShowDetails] = useState(false);

  const renderedLinks = useMemo(() => {
    const content = links.length === 1 ? '1 weiterführender Link' : (`${links.length} weiterführende Links`);

    return (<p className="is-size-7 has-text-grey">{content}</p>);
  }, [links]);

  return (
    <div className="mb-3">
      <button
        type="button"
        onClick={() => setShowDetails(!showDetails)}
        className="has-no-border has-cursor-pointer has-text-left box p-3 boxshadow br10 has-fullwidth"
      >
        <div className="columns m0 is-mobile">
          <div className="column">
            <p className="is-size-7 has-text-weight-bold">
              {text}
            </p>
          </div>
          <div className="column is-narrow has-text-right">
            <div
              className="cleanButton p-0 has-text-right"
            >
              <i className={`is-size-7 has-text-grey fas fa-chevron-${showDetails ? 'up' : 'down'}`} />
            </div>
          </div>
        </div>
        {
        links.length !== 0 && showDetails && (
          <div className="px-3">
            { renderedLinks }
          </div>
        )
      }
      </button>

    </div>
  );
};

EndingDisplay.propTypes = {
  ending: PropTypes.shape({
    text: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default EndingDisplay;
