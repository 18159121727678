const INITIAL_STATE = {
  hasData: false,
  websites: [],

};

const applyClearWebsiteState = () => INITIAL_STATE;

function setWebsites(state, action) {
  return {
    ...state,
    hasData: true,
    websites: action.websites,
  };
}

function addWebsite(state, action) {
  return {
    ...state,
    hasData: true,
    websites: [action.website, ...state.websites],
  };
}

function updateWebsite(state, action) {
  return {
    ...state,
    websites: state.websites.map((website) => (website._id === action.website._id ? action.website : website)),
  };
}

function deleteWebsite(state, action) {
  return {
    ...state,
    websites: state.websites.filter((item) => item._id !== action.id),
  };
}

function setWidgetToWebsite(state, action) {
  return {
    ...state,
    websites: state.websites.map((item) => {
      if (item._id === action.websiteID) {
        return {
          ...item,
          widgets: [action.widgetData],
        };
      }
      return item;
    }),
  };
}

function unsetWidgetToWebsite(state, action) {
  return {
    ...state,
    websites: state.websites.map((item) => {
      if (item._id === action.websiteID) {
        return {
          ...item,
          widgets: [],
        };
      }
      return item;
    }),
  };
}

// Set widgets to false after widget got deleted
function removeWidgetFromWebsites(state, action) {
  const { websites } = action;

  const newWebsites = state.websites.map((item, index) => {
    if (websites.includes(index)) {
      return {
        ...item,
        widgets: [],
      };
    }
    return item;
  });

  return {
    ...state,
    websites: newWebsites,
  };
}

const updateWebsitesWithWidget = (state, action) => {
  const { widget } = action;
  const data = {
    id: widget._id,
    type: widget.type,
    ...widget.settings,
  };

  return {
    ...state,
    websites: state.websites.map((item) => {
      if (!item.widgets === undefined || item.widgets.length === 0) {
        return item;
      }

      if (item.widgets[0].id === widget._id) {
        return {
          ...item,
          widgets: [data],
        };
      }
      return item;
    }),
  };
};

function websiteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /* Websites */
    case 'SET_WEBSITES':
    {
      return setWebsites(state, action);
    }

    case 'ADD_WEBSITE':
    {
      return addWebsite(state, action);
    }

    case 'UPDATE_WEBSITE':
    {
      return updateWebsite(state, action);
    }

    case 'WEBSITE_WIDGET_SET':
    {
      return setWidgetToWebsite(state, action);
    }

    case 'WEBSITE_WIDGET_UNSET':
    {
      return unsetWidgetToWebsite(state, action);
    }

    case 'WEBSITE_DELETE':
    {
      return deleteWebsite(state, action);
    }
    case 'DELETE_WIDGET_WEBSITES':
    {
      return removeWidgetFromWebsites(state, action);
    }

    case 'CLEAR_STATE':
    case 'WEBSITES_CLEAR':
    {
      return applyClearWebsiteState(state);
    }

    case 'WEBSITE_WIDGET_UPDATE':
    {
      return updateWebsitesWithWidget(state, action);
    }

    default:
      return state;
  }
}

export default websiteReducer;
