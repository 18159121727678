import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import modul from 'assets/images/joomla1.png';
import neu from 'assets/images/joomla2.png';
import custom from 'assets/images/joomla3.png';
import code from 'assets/images/joomla4.png';
import joomlaIcon from 'assets/images/joomla.svg';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';
import Image from './Image';

const ScriptJoomla = () => (
  <>
    <div className="has-text-centered">
      <img className="shopSystemIcons" src={joomlaIcon} alt="" />
    </div>
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-weight-bold">Installation für Joomla</p>
    <div className="mt30">
      <p className="is-size-6 has-text-weight-bold">So fügst Du dein Flowdust-Skript Joomla hinzu:</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Logge dich in Joomla ein</p>
    </div>
    <div className="mt20">
      <div className="columns">
        <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
        <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Gehe links auf Module</p>
        <Image src={modul} />
      </div>
    </div>
    <div className="mt20">
      <div className="columns">
        <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
        <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Neu"</p>
        <Image src={neu} />
      </div>
    </div>
    <div className="mt20">
      <div className="columns">
        <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
        <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Wähle "Benutzerdefiniertes Modul"</p>
        <Image src={custom} />
      </div>
    </div>
    <div className="mt20 mb30">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'5. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Kopiere diesen HTML-Code:</p>
    </div>
    <ScriptTagCopy />
    <div className="mt20">
      <div className="columns">
        <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'6. '}</p>
        <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf den Reiter "Code" und füge ihn in das HTML-Eingabefeld ein</p>
        <Image src={code} />
      </div>
    </div>
    <div className="mt-2 mb-5">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'7. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Speichern"</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);

export default ScriptJoomla;
