import React, { useState, useEffect } from 'react';

// Libraries
import stats from 'analytics/analytics';
import axios from 'axios';
import notification from 'utils/notification';
import { Helmet } from 'react-helmet-async';

// Reducer Actions
import { setStatus } from 'actions/user';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Constants
import {
  HOME, SUPPORT, BILLING, MANAGE_SUBSCRIPTION,
} from 'constants/routes';
import { ACTIVE } from 'constants/status';

// HOCs
import withSubscription from 'components/hoc/withSubscription';

function CancelSubscription() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [signal] = useState(axios.CancelToken.source());

  const user = useSelector((state) => state.sessionState.user);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.status !== ACTIVE || !user.subscription) {
      navigate(BILLING);
    }
    // eslint-disable-next-line
  }, []);

  if (!user.subscription) {
    return null;
  }

  const goToDashboard = () => {
    stats.push(['trackEvent', 'Subscription', 'Subscription not cancelled - go to dashboard']);

    navigate(HOME);
  };

  const nextBillingDate = new Date(user.subscription.nextBillingDate).toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' });

  const cancelSubscription = async () => {
    setDisabled(true);
    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      // Get customer data
      const data = await axios.delete(`${process.env.REACT_APP_API}/subscription`, config);

      setDisabled(false);

      stats.push(['trackEvent', 'Subscription', 'Subscription cancelled']);

      const status = data.data;
      dispatch(setStatus(status));

      notification.send('Fertig!', `Deine Abonnement wurde gekündigt. Am ${nextBillingDate} befindest Du dich wieder in der Testphase.`, 'success');

      navigate(HOME);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      const { response } = err;

      if (response.data) {
        setError(err.response.data);
        return;
      }

      setError('Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');
      console.log(err);
    }
  };

  const name = user.customer.firstName;

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Abonnement kündigen
        </title>
      </Helmet>
      <div className="columns p20">
        <div className="column">
          <h2 className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile">
            {
            `${name}, bist Du sicher, dass Du dein Abonnement wirklich kündigen willst?`
          }
          </h2>
          <div className="mt-2">
            <p className="is-inline">
              {'Dein Abonnement wurde bis zum '}
            </p>
            <p className="is-inline has-text-weight-bold">
              {nextBillingDate}
            </p>
            <p className="is-inline">
              {' bezahlt. Wenn Du dein Abonnement wirklich kündigen willst, klicke unten auf "Ja, Abonnement kündigen".'}
            </p>
          </div>
          <p
            className="has-text-black has-text-weight-bold is-size-5 my-5"
          >
            Bevor Du gehst...
          </p>
          <div className="columns mb-4 is-centered is-vcentered has-border-bottom pb-5">
            <div className="column is-5-desktop">
              <div className="box boxshadow br10">
                <h3 className="has-text-black has-text-weight-bold is-size-5">
                  Können wir dir vielleicht weiterhelfen?
                </h3>
                <p className="mt-2">
                  Falls Du Fragen zu Flowdust hast, kannst Du uns gerne kontaktieren. Wir stehen dir jederzeit zu Verfügung und lösen jedes Problem.
                </p>
                <div className="has-text-right">
                  <Link
                    className="button is-pastel has-text-weight-bold grow my-3"
                    to={SUPPORT}
                  >
                    Zum Support
                  </Link>
                </div>
              </div>
            </div>
            <div className="column is-5-desktop is-offset-1-desktop">
              <div className="box boxshadow br10">
                <h3 className="has-text-black has-text-weight-bold is-size-5">
                  Möchtest Du deinen Plan ändern?
                </h3>
                <p className="mt-2">
                  Brauchst Du mehr oder weniger Impressionen? Bearbeite deinen Abo-Plan mit nur wenigen Klicks.
                </p>
                <div className="has-text-right">
                  <Link
                    className="button has-background-midnightblue has-text-white has-text-weight-bold grow my-3"
                    to={MANAGE_SUBSCRIPTION}
                  >
                    Plan verwalten
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-centered mt-5">
            <div className="column has-text-centered is-narrow">
              <div className="box boxshadow">
                <div className="columns is-marginless">
                  <div className="column is-narrow has-fullheight px-1">
                    <i className="fas fa-exclamation-circle has-text-pastel" />
                  </div>
                  <div className="column px-1">
                    <p className="mb-3">
                      Bist Du sicher? Nach dem
                      {' '}
                      {nextBillingDate}
                      {' '}
                      werden deine Impressionen eingeschränkt sein.
                    </p>
                  </div>
                </div>
                <button
                  className={`button has-border-grey br10 has-text-grey has-text-weight-bold is-block mb-2 has-fullwidth ${disabled && 'is-loading'}`}
                  type="button"
                  onClick={cancelSubscription}
                  disabled={disabled}
                >
                  Ja, Abonnement kündigen
                </button>
                <button
                  className={`button is-pastel br10 grow has-text-weight-bold is-block has-fullwidth ${disabled && 'is-loading'}`}
                  onClick={goToDashboard}
                  type="button"
                  disabled={disabled}
                >
                  Nein, nicht kündigen
                </button>
              </div>
            </div>
          </div>
          <p className="has-text-weight-bold my-2 has-text-danger">
            {error}
          </p>
        </div>
      </div>
    </>
  );
}

export default withSubscription(CancelSubscription);
