import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addEndingLink } from 'reducer/hubs';

// Constants
import { HUB_MAX_ENDING_LINKS } from 'constants/hub';

// Components
import LinkElement from './LinkElement';

const LinkEditor = (props) => {
  const { endingIndex } = props;

  const dispatch = useDispatch();
  const { endings } = useSelector((state) => state.hubs.creator.content);
  const { links } = endings[endingIndex];

  const addLink = () => {
    dispatch(addEndingLink(endingIndex, 'GGL'));
  };

  const renderedLinks = links.map((link, index) => (
    <LinkElement
      key={index}
      linkIndex={index}
      endingIndex={endingIndex}
    />
  ));

  return (
    <div className="pb-5">
      <h2 className="has-text-weight-bold is-size-6 pt-5 mb-4 has-text-black">
        Füge weiterführende Links hinzu (optional)
      </h2>
      { renderedLinks }
      {
        links.length < HUB_MAX_ENDING_LINKS
        && (
        <div className="">
          <button
            type="button"
            onClick={addLink}
            className="mb-2 is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
          >

            <i className="fa fa-link has-text-pastel is-size-7 mr-2" />
            <p className="is-size-7 has-text-weight-bold is-inline">
              Link hinzufügen
            </p>
          </button>
        </div>
        )
      }
    </div>
  );
};

LinkEditor.propTypes = {
  endingIndex: PropTypes.number.isRequired,
};

export default LinkEditor;
