import React, { useEffect, useRef } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// HOCs
import withoutAuthentication from 'components/hoc/withoutAuthentication';

// Components
import NavigationBar from '../HubContentEditor/NavigationBar';
import InfoEditor from '../HubContentEditor/InfoEditor';
import QuestionEditor from '../HubContentEditor/QuestionEditor';
import EndingEditor from '../HubContentEditor/EndingEditor';
import LogicEditor from '../HubContentEditor/LogicEditor';
import HubPreview from '../HubContentEditor/HubPreview';
import SaveButtonDemo from './SaveButtonDemo';

// Actions
import { setComingFromDemo } from 'reducer/hubs';

function HubDemoEditor() {
  const {
    creator,
  } = useSelector((state) => state.hubs);

  const contentRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setComingFromDemo(true));

    return () => {
      // on unmount, reset setComingFromDemo
      // IMPORTANT: works only because auth0 is on a different domain,
      // so this WONT run if we route to auth0 via signInWithRedirect
      // TODO: when auth0 is on one domain, we need to find a different solution
      dispatch(setComingFromDemo(false));
    };
  }, []);

  const { activeContent } = creator.content;

  const { type, index } = activeContent;

  const showActiveEditor = () => {
    switch (type) {
      case 'INFO':
        return <InfoEditor />;
      case 'QUESTION':
        return <QuestionEditor />;
      case 'ENDING':
        return <EndingEditor />;
      case 'LOGIC':
        return <LogicEditor />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [type, index]);

  return (
    <>
      <Helmet>
        <title>Flowdust - Hub Demo</title>
      </Helmet>
      <div className="has-fullheight is-max-fullheight">
        <div className="is-hidden-tablet is-hidden-desktop p-5 my-4 has-text-centered has-text-weight-bold">
          <p
            className="is-size-7"
          >
            Scrolle ganz nach unten, um die Vorschau zu sehen!
          </p>
        </div>

        <div className="columns is-marginless is-paddingless has-fullheight is-mobile">
          <div
            className="column is-1-desktop is-1-tablet p-0 has-overflow-y-auto has-max-width-80-desktop has-min-width-100-tablet is-12-mobile has-fullheight"
          >
            <div
              className="has-background-white has-border-right-grey-light px-2 has-min-fullheight has-fullheight"
            >
              <NavigationBar />
              <SaveButtonDemo />
            </div>
          </div>
          <div
            className="column is-4-desktop is-4-tablet p-0 has-overflow-y-auto has-overflow-y-hidden-mobile has-min-width-300 has-max-width-400-desktop is-12-mobile has-fullheight"
            ref={contentRef}
          >
            <div
              className="has-background-white px-4 py-5 has-min-fullheight"
            >
              {showActiveEditor()}
            </div>
          </div>
          <div className="column has-max-width-100-percent has-flex-1 p-0 has-fullheight">
            <HubPreview />
          </div>
        </div>
      </div>
    </>
  );
}

export default withoutAuthentication()(HubDemoEditor);
