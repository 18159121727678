import avatar_1 from 'assets/images/statistics/avatars/avatar_1.svg';
import avatar_2 from 'assets/images/statistics/avatars/avatar_2.svg';
import avatar_3 from 'assets/images/statistics/avatars/avatar_3.svg';
import avatar_4 from 'assets/images/statistics/avatars/avatar_4.svg';
import avatar_5 from 'assets/images/statistics/avatars/avatar_5.svg';
import avatar_6 from 'assets/images/statistics/avatars/avatar_6.svg';
import avatar_7 from 'assets/images/statistics/avatars/avatar_7.svg';
import avatar_8 from 'assets/images/statistics/avatars/avatar_8.svg';
import avatar_9 from 'assets/images/statistics/avatars/avatar_9.svg';
import avatar_10 from 'assets/images/statistics/avatars/avatar_10.svg';
import avatar_11 from 'assets/images/statistics/avatars/avatar_11.svg';

const pictures = [
  avatar_1,
  avatar_2,
  avatar_3,
  avatar_4,
  avatar_5,
  avatar_6,
  avatar_7,
  avatar_8,
  avatar_9,
  avatar_10,
  avatar_11,
];

export default pictures;
