import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import stats from 'analytics/analytics';
import { SIGN_UP, FLOWTER_INFO, HUB_INFO } from 'constants/routes';
import plans from 'constants/plans';
import Popup from 'reactjs-popup';

function FreePricingPlan() {
  const plan = plans[0];

  const formatViews = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const impressions = formatViews(plan.views);

  const navigate = useNavigate();

  const clickButton = () => {
    stats.push(['trackEvent', 'Landing - Pricing Page', 'Free Plan - Start Now clicked']);
    navigate(SIGN_UP);
  };

  return (
    <div
      className="column is-9-desktop mt30 is-paddingless"
      id="pricingPlan"
    >
      <div
        className="box has-border-bold-pastel boxshadow is-relative has-text-centered has-text-black has-border-white-semibold"
      >
        <div className="columns">
          <div className="column">
            <div className="has-border-bottom has-text-black has-text-weight-bold">
              <p className="mb10 is-size-4">{plan.name}</p>
            </div>
            <p className="has-text-weight-bold is-size-2-desktop is-size-3-tablet is-size-4-mobile mt-2">
              {`${plan.cost} €`}
            </p>
            <p className="has-text-weight-light is-size-6">pro Monat</p>
          </div>
          <div className="column">
            <div className="columns is-centered is-marginless">
              <div className="column is-10 has-text-weight-semibold is-paddingless">
                <div className="is-flex has-content-centered mt-2">
                  <p className="is-size-6">{plan.maxAmountHubs}</p>
                  <Link to={HUB_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-2">
                    {`${plan.maxAmountHubs === 1 ? 'Hub' : 'Hubs'}`}
                  </Link>
                </div>
                <p className="is-size-6">Max. 10 Hub-Abstimmungen</p>
                <div className="is-flex has-content-centered mt-4">
                  <p className="is-size-6">Unbegrenzte Anzahl an </p>
                  <Link to={FLOWTER_INFO} className="has-text-underlined has-text-weight-bold has-text-primary mx-1">
                    Flowtern
                  </Link>
                </div>
                <p className="is-size-6">Unbegrenzte Anzahl an Flowter-Abstimmungen</p>
                <div className="">
                  <p className="has-text-weight is-size-6 is-inline">
                    {`${impressions} Flowter-Impressionen pro Monat`}
                  </p>
                  <Popup
                    trigger={(
                      <div className="cleanButton is-inline ml5">
                        <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
                      </div>
                    )}
                    position="top center"
                    on={['hover']}
                    keepTooltipInside="#root"
                    repositionOnResize
                    className="mt10"
                  >
                    {
                          () => (
                            <div className="columns is-marginless">
                              <div className="has-text-weight-light column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-midnightblue">
                                <p className="is-size-7">Impressionen bezeichnen die Anzahl, wie oft deine Flowter auf deiner Website geladen und angezeigt werden. </p>
                              </div>
                            </div>
                          )
                    }
                  </Popup>
                </div>

                <div className="has-text-centered mt-4">
                  <button
                    className="button has-border-pastel has-text-weight-bold br10 grow"
                    type="button"
                    onClick={clickButton}
                  >
                    Jetzt starten!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FreePricingPlan;
