import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import LoadMoreButton from '../../utils/LoadMoreButton';
import DeleteButton from 'components/flowter/statistics/Statistic/utils/DeleteButton';

const SubmissionDisplay = (props) => {
  const { flowterIndex, questionIndex } = props;
  const { flowters } = useSelector((state) => state.flowterState);

  const { submissions, _id } = flowters[flowterIndex];

  const submissionWithText = useMemo(() => submissions.filter((data) => {
    const { submission } = data;
    let hasText = false;

    if (submission[questionIndex].text) {
      hasText = true;
    }

    // filter if submission has no text
    return hasText;
  }), [submissions]);

  if (submissionWithText.length === 0) return null;

  const submissionLayout = submissionWithText.map((item) => {
    const data = item.submission[questionIndex];
    return (
      <div
        className="has-text-left p5 br3 mb15 has-text-weight-medium has-text-black boxshadow has-background-dash"
        key={item._id}
      >
        <div className="columns is-marginless">
          <div className="column">
            <p className="">
              {data.text}
            </p>
          </div>
          <div className="column is-narrow has-text-centered-mobile">
            <DeleteButton flowterID={_id} submissionID={item._id} />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="has-max-height-500 has-overflow-y-auto mt-4 submissionDisplay">
      <p className="pt-5 has-border-bottom mb10 has-text-left has-text-weight-semibold has-text-midnightblue mb-3">
        Das sagen deine Nutzer:
      </p>
      { submissionLayout }
      <LoadMoreButton
        index={flowterIndex}
        showText={false}
        amountEntries={submissionWithText.length}
      />
    </div>
  );
};

SubmissionDisplay.propTypes = {
  flowterIndex: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default SubmissionDisplay;
