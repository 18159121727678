import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import InfoPopup from 'components/utils/InfoPopup';

const AllowTextEditor = (props) => {
  const { isAllowed, setAllowed, id } = props;

  return (
    <div className="has-border-top">
      <div className="mt-5">
        <i className="is-inline fas fa-cog mr5" />
        <p className="is-inline">Erweiterte Einstellungen</p>
      </div>
      <div className="mt-5 field dont-select-text">
        <input
          type="checkbox"
          checked={isAllowed}
          id={`allowText_${id}`}
          className="is-checkradio"
          onChange={() => setAllowed(!isAllowed)}
        />
        <label className="has-text-centered" htmlFor={`allowText_${id}`}>
          Textfeld hinzufügen
        </label>
        <InfoPopup
          text="Hier können Nutzer per Texteingabe ihre Meinung ausdrücken."
        />
      </div>
    </div>
  );
};

AllowTextEditor.propTypes = {
  isAllowed: PropTypes.bool.isRequired,
  setAllowed: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default AllowTextEditor;
