import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import GoogleButton from './buttons/GoogleButton';
import FacebookButton from './buttons/FacebookButton';
import TrustpilotButton from './buttons/TrustpilotButton';
import TwitterButton from './buttons/TwitterButton';
import LinkButton from './buttons/LinkButton';
import TripadvisorButton from './buttons/TripadvisorButton';

const LinkDisplay = (props) => {
  const { endingIndex, linkIndex } = props;

  const {
    content, colors,
  } = useSelector((state) => state.hubs.creator);

  const { endings } = content;
  const link = endings[endingIndex].links[linkIndex];

  const { type, url, text } = link;

  const clickLink = () => {
  };

  // see client hub constants for available types
  return useMemo(() => {
    switch (type) {
      case 'GOOGLE': return (
        <GoogleButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
        />
      );
      case 'FB': return (
        <FacebookButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
        />
      );
      case 'TRIPADVISOR': return (
        <TripadvisorButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
        />
      );
      case 'TRUSTPILOT': return (
        <TrustpilotButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
        />
      );
      case 'TWITTER': return (
        <TwitterButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
        />
      );
      default: return (
        <LinkButton
          url={url}
          text={text}
          onClick={() => clickLink(type)}
          color={colors[0]}
        />
      );
    }
  }, [type, text, url]);
};

LinkDisplay.propTypes = {
  endingIndex: PropTypes.number.isRequired,
  linkIndex: PropTypes.number.isRequired,
};

export default LinkDisplay;
