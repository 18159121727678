import { useSelector } from 'react-redux';
import * as status from 'constants/status';

// same as withSubscription, but hasSubscription is true, even if user.customer is undefined
const useSubscription = () => {
  const user = useSelector((state) => state.sessionState.user);

  if (!user) {
    return {
      hasSubscription: false,
      isNewCustomer: true,
      canCreateSubscription: false,
    };
  }

  const hasSubscription = (
    user.status === status.ACTIVE
    || user.status === status.PAST_DUE
    || user.status === status.WILL_CANCEL
  );

  const isNewCustomer = user.status === status.TRIAL;

  const isOnboarding = user.status === status.NEW_USER;

  const canCreateSubscription = user.status === status.TRIAL || user.status === status.CANCELLED;

  return {
    hasSubscription,
    isNewCustomer,
    canCreateSubscription,
    customer: user.customer,
    isOnboarding,
  };
};

export default useSubscription;
