import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// styles
/* eslint import/no-webpack-loader-syntax: off */
import innerStyles from '!!raw-loader!./innerStyles.scss';

const PrivacyNotice = (props) => {
  const { onClick, color, textData } = props;

  // Checks if the questions are formal or not - only needed for the german language atm
  const isFormal = useMemo(() => {
    const regex = new RegExp(/\b(Sie|Ihnen|Ihr)\b/g);

    let isTypeFormal = false;

    textData.forEach((text) => {
      if (regex.test(text)) {
        isTypeFormal = true;
        // eslint-disable-next-line
        return;
      }
    });

    return isTypeFormal;
  }, [textData]);

  return (
    <>
      <style>
        { innerStyles }
      </style>
      <div>
        <div id="privacyNotice" style={{ backgroundColor: color }}>
          <p>
            {
             isFormal
               ? 'Sie wollen uns unterstützen?'
               : 'Du willst uns unterstützen?'
            }
          </p>
          <p>
            {
              isFormal
                ? 'Bitte geben Sie uns Ihr Einverständnis, Ihre personenbezogenen Daten verarbeiten zu dürfen.'
                : 'Gib uns bitte dein Einverständnis, deine personenbezogenen Daten verarbeiten zu dürfen.'
            }
          </p>
          <a
            href="https://flowdust.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Mehr Infos
          </a>
        </div>
        <button
          type="button"
          onClick={onClick}
          style={{ backgroundColor: color }}
          id="privacyNotice_button"
        >
          Ja, senden
        </button>
      </div>
    </>
  );
};

PrivacyNotice.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  textData: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivacyNotice;
