import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

const TwitterButton = (props) => {
  const { text, url, onClick } = props;

  return (
    <Button
      url={url}
      text={text}
      onClick={onClick}
      styles={{ backgroundColor: '#1DA1F2' }}
    >
      <i className="fab fa-twitter" />
    </Button>
  );
};

TwitterButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TwitterButton;
