import React, { useMemo } from 'react';

// Components
import HubTemplates from 'components/hub/HubTemplates';

// JSON
import { templates } from 'components/hub/templates';

const HubExampleTemplates = () => {
  // show first 5 templates as examples on landing
  const landingTemplates = useMemo(() => {
    if (templates.length < 5) return templates.slice(0, templates.length);

    return templates.slice(0, 5);
  }, []);

  return (
    (
      <div>
        <HubTemplates templates={landingTemplates} />
      </div>
    )
  );
};

export default HubExampleTemplates;
