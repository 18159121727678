import React, { useState, useEffect, Fragment } from 'react';

// Libraries
import stats from 'analytics/analytics';
import notification from 'utils/notification';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Constants
import * as routes from 'constants/routes';

import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';

import DeleteButton from './DeleteButton';

import WidgetSelector from './WidgetSelector';

import './css/website.scss';
import useAPI from 'components/hooks/useAPI';

import { unsetWidget } from 'actions/websites';

/*
 * Website component that shows all the information about one website
 * The user can set widgets to the website
 */
function Website(props) {
  const [signal] = useState(axios.CancelToken.source());

  const [disabled, setDisabled] = useState(false);

  const { del } = useAPI();

  const websites = useSelector((state) => state.websiteState.websites);
  const dispatch = useDispatch();
  const { id } = props;

  const website = websites[id];
  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  if (!website) {
    return null;
  }

  const websiteName = website.name;
  const url = sanitizeUrl(website.url);
  const { isOnboarding } = props;

  const unsetWidgetFromWebsite = async () => {
    try {
      setDisabled(true);

      const websiteId = website._id;

      await del(`/websites/set/${websiteId}`);

      stats.push(['trackEvent', 'Website', 'Website Flowter unset']);

      dispatch(unsetWidget(websiteId));

      setDisabled(false);

      notification.send('Fertig!', 'Diese Website hat nun keinen Flowter mehr.', 'success');
    } catch (err) {
      setDisabled(false);

      if (!err.response) {
        notification.send('Hoppla!', 'Flowter konnte leider nicht hinzugefügt werden.', 'danger');
        return;
      }

      if (err.response.status === 500) {
        notification.send('Server Fehler.', 'Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      notification.send('Fehler!', err.response.data, 'danger');
    }
  };

  return (
    <div className="box has-background-white column boxshadow is-2 mb20 website p20">
      <div className="columns is-mobile is-marginless has-fullheight">
        <div className="column p0 websiteContent has-fullheight has-maxWidth">
          <div className="columns is-centered is-mobile is-marginless">
            {
            !isOnboarding
              && (
              <>
                <div className="column is-paddingless has-text-left">
                  <Link
                    to={`${routes.ADD_WEBSITE}/${id}`}
                    className="icon-circle has-background-grey-light br20"
                  >
                    <i className="is-size-7 ml-1 fas fa-edit has-text-grey" />
                  </Link>
                </div>
                {
                  website.widgets.length !== 0 && (
                    <div className="column is-paddingless has-text-centered">
                      <button
                        className="button has-background-grey-light is-size-7 has-text-weight-bold br10 has-hover-grey-lighter has-text-grey"
                        type="button"
                        onClick={() => unsetWidgetFromWebsite()}
                        disabled={disabled}
                      >
                        <i className="fas fa-unlink" />
                      </button>
                    </div>
                  )
                }
                <div className="column is-paddingless has-text-right">
                  <DeleteButton id={id} />
                </div>
              </>
              )
            }
          </div>
          <div className="has-text-centered websiteContentData mt-2">
            <p className="has-text-black is-size-6 has-text-weight-bold">{websiteName}</p>
            <a
              href={`https://${url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="is-size-7 has-text-link is-underlined is-block has-max-height-20 is-clipped my-1"
            >
              {url}
            </a>
            <div className="mt-2 has-text-centered">
              <p className="has-text-weight-bold  has-text-yellow is-size-7 mb-1 ml-1">
                Flowter
              </p>
              <WidgetSelector website={website} />
            </div>

          </div>
          {
            website.rules
            && (
            <div className="websiteIconWrapper mt-2">
              <div className="columns is-mobile m0 has-fullwidth">
                <ParameterDisplay parameter={website.rules} />
              </div>
            </div>
            )
          }
          <div />
        </div>
      </div>
    </div>
  );
}

Website.propTypes = {
  id: PropTypes.number.isRequired,
  isOnboarding: PropTypes.bool.isRequired,
};

function ParameterDisplay(props) {
  const { parameter } = props;
  const amountParams = parameter.length;

  if (amountParams === 0) {
    return null;
  }

  const paramsToDisplay = parameter.map((param) => (
    <p
      key={param}
      className="is-size-7 has-text-black"
    >
      {param}
    </p>
  ));

  return (
    <div className="has-cursor-pointer column px-2 pt-2 pb-0">
      <Popup
        trigger={(
          <div className="websiteIcon has-background-grey-light">
            <p>
              {`P${amountParams}`}
            </p>
          </div>
        )}
        position="top center"
        on={['hover', 'click']}
        keepTooltipInside="#root"
        repositionOnResize
      >
        {
          () => (
            <div className="px-4 py-2 has-text-centered has-background-white">
              <p className="is-size-7 has-text-black has-text-weight-bold has-text-left">Enthaltene Parameter:</p>
              { paramsToDisplay }
            </div>
          )
        }
      </Popup>
    </div>
  );
}

ParameterDisplay.propTypes = {
  parameter: PropTypes.arrayOf(PropTypes.string),
};

ParameterDisplay.defaultProps = {
  parameter: [],
};

export default Website;
