import React from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';

// Components
import Popup from 'reactjs-popup';

function SaveButtonDemo() {
  const { loginWithRedirect } = useAuth0();

  const login = async (close) => {
    try {
      close();
      stats.push(['trackEvent', 'Flowter Editor Demo Save Button', 'Go to login']);
      await loginWithRedirect();
    } catch (err) {
      // TODO: show error message
      console.log(err);
    }
  };

  return (
    <div className="has-text-centered pt-5">
      <Popup
        trigger={(
          <button
            type="button"
            className="button grow has-text-white has-background-pastel has-no-border is-outlined is-rounded mt20 has-text-weight-bold has-hover-primary has-fullheight"
          >
            Speichern
          </button>
      )}
        position="top center"
        className="fixed"
        on={['click']}
        keepTooltipInside="#root"
        repositionOnResize
        modal
      >
        { (close) => (
          <div className="br22 has-text-centered box pt-2 p-5">
            <div className="has-text-right">
              <button
                className="cleanButton"
                onClick={close}
                type="button"
              >
                <i className="fas fa-times has-text-grey is-size-6" />
              </button>
            </div>
            <h2 className="has-text-centered has-text-weight-semibold has-text-black is-size-6mt-2">
              Um deinen Flowter zu speichern benötigst du einen (kostenlosen) Flowdust-Account.
            </h2>
            <p className="has-text-centered has-text-weight-semibold has-text-black is-size-6 mb-4">
              Möchtest du dich jetzt anmelden?
            </p>
            <button
              type="button"
              className="button grow br10 is-primary has-text-weight-bold is-size-6 has-text-white has-hover-primary has-fullheight p-3 has-content-centered has-line-height-full"
              onClick={() => login(close)}
            >
              Ja, zum Login
              <i className="fas fa-arrow-up-right-from-square ml-3" />
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default SaveButtonDemo;
