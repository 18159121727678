import React, { useState, useEffect } from 'react';
import stats from 'analytics/analytics';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TriggerOptions = (props) => {
  const { step } = props;
  const { trigger } = props;
  const { setType, setAmount } = props;

  const [error, setError] = useState('');

  const clearError = () => {
    setError('');
  };

  useEffect(() => {
    const { type } = trigger;

    let triggerType = 'Immediately';
    if (type === 1) {
      triggerType = 'Time';
    }
    if (type === 2) {
      triggerType = 'Scroll';
    }

    stats.push(['trackEvent', 'Flowter Creator - Trigger', `Type ${triggerType} selected`]);
  }, [trigger.type]);

  const setTriggerType = (type) => {
    if (trigger.type === type) {
      return;
    }

    setType(type);

    if (type === 0) {
      setAmount(0);
    } else {
      setAmount('');
    }
    setError('');
  };

  const setTypeAndAmount = (value, type) => {
    setType(type);
    // Value is a string, so convert it to int
    if (value.length > 0) {
      value = parseInt(value, 10);
    } else {
      // Allow clearing the input field
      setAmount('');
      return;
    }

    if (Number.isNaN(value)) {
      setError('Bitte gib eine gültige Nummer ein!');
      return;
    }

    if (trigger.type === 1 && (value > 1800 || value < 1)) {
      setError('Bitte gib eine Zeit zwischen 1 und 1800 Sekunden an.');
      stats.push(['trackEvent', 'Flowter Creator', 'Trigger invalid time amount']);
      return;
    }

    if (trigger.type === 2 && (value > 100 || value < 1)) {
      setError('Bitte gib eine Prozentzahl zwischen 1 und 100 an.');
      stats.push(['trackEvent', 'Flowter Creator', 'Trigger invalid percent amount']);
      return;
    }

    setAmount(value);
    setError('');
  };

  const triggerType = trigger.type;
  const triggerAmount = trigger.amount;

  return (
    <div className="columns is-marginless is-centered" id="triggerOptionWrapper">
      <div className="column has-text-black p0">
        <p className="is-size-6 has-text-weight-bold has-background-dash numberCircle">{step}</p>
        <h2 className="has-text-weight-bold is-size-6 has-text-centered is-inline">Bestimme wann dein Flowter angezeigt werden soll</h2>
        <div className="mt30">
          <input
            type="radio"
            id="showImmediately"
            name="displayTime"
            checked={triggerType === 0}
            onChange={() => setTriggerType(0)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered"
            htmlFor="showImmediately"
          >
            sofort, wenn die Website geladen hat
          </label>
        </div>
        <div className="mt20">
          <input
            type="radio"
            id="showAfterSeconds"
            name="displayTime"
            checked={triggerType === 1}
            onChange={() => setTriggerType(1)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered"
            htmlFor="showAfterSeconds"
          >
            <p className="is-inline">{'nach '}</p>
            <input
              type="text"
              onChange={(event) => setTypeAndAmount(event.target.value, 1)}
              onBlur={() => clearError()}
              value={triggerType === 1 ? triggerAmount : ''}
              className="input is-size-6 is-inline has-text-black has-text-centered has-text-weight-bold layoutInputField"
            />
            <p className="is-inline">{' Sekunden'}</p>
          </label>
        </div>
        <div className="mt20">
          <input
            type="radio"
            id="showAfterScroll"
            name="displayTime"
            checked={triggerType === 2}
            onChange={() => setTriggerType(2)}
            className="is-checkradio is-pastel"
          />
          <label className="has-text-centered" htmlFor="showAfterScroll">
            <p className="is-inline">{'nachdem die Seite um '}</p>
            <input
              type="text"
              onChange={(event) => setTypeAndAmount(event.target.value, 2)}
              onBlur={() => clearError()}
              value={triggerType === 2 ? triggerAmount : ''}
              className="input is-size-6 is-inline has-text-black has-text-centered has-text-weight-bold layoutInputField"
            />
            <p className="is-inline">{' % nach unten gescrollt wurde'}</p>
          </label>
        </div>
        <p className="has-text-weight-bold has-text-danger has-text-centered my-2">{error}</p>
      </div>
    </div>
  );
};

TriggerOptions.propTypes = {
  step: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  trigger: state.creatorState.trigger,
});

const mapDispatchToProps = (dispatch) => ({
  setType: (triggerType) => dispatch({ type: 'SET_TRIGGER_TYPE', triggerType }),
  setAmount: (amount) => dispatch({ type: 'SET_TRIGGER_AMOUNT', amount }),
  showWidget: () => dispatch({ type: 'SHOW_WIDGET' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TriggerOptions);
