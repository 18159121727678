export const setStatus = (status) => ({
  type: 'STATUS_SET',
  status,
});

export const setPlanDetails = (planDetails) => ({
  type: 'PLAN_DETAILS_SET',
  planDetails,
});

export const setNewPlan = (newPlan) => ({
  type: 'NEW_PLAN_SET',
  newPlan,
});

// set user, keep data thats already in the state and just replace
export const setUser = (user) => ({
  type: 'SET_USER_DATA',
  user,
});

// set user, delete everything the user obj currently has and set it new
export const setUserObj = (user) => ({
  type: 'SET_USER',
  user,
});

export const incrementWidgetCount = () => ({
  type: 'INC_WIDGET_COUNT',
});

export const setOnboardingPage = (page) => ({
  type: 'SET_ONBOARDING_PAGE',
  page,
});

export const clearCompleteState = () => ({
  type: 'CLEAR_STATE',
});
