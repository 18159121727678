import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setFilter } from 'reducer/hubs';

// Components
import NestedSelector from '../../NestedSelector';

const OSFilter = () => {
  const { analytics, filter } = useSelector((state) => state.hubs.statistic);
  const { os } = analytics;

  const dispatch = useDispatch();

  const allOs = useMemo(() => os.map((_os) => ({
    label: _os.name,
    values: _os.versions.map(({ version }) => ({
      label: _os.name,
      value: version,
    })),
  })), []);

  const _setFilter = (newFilter) => {
    dispatch(setFilter({ os: newFilter }));
  };

  return (
    <div className="field has-width-280 has-margin-auto">
      <p className="is-inline has-text-weight-semibold">Betriebssysteme</p>
      <i className="mx-2 is-inline fab fa-windows" />
      <NestedSelector
        selectedItems={filter.os}
        setSelection={_setFilter}
        allItems={allOs}
        name="Betriebssysteme"
      />
    </div>

  );
};

export default OSFilter;
