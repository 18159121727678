import React from 'react';

// Constants
import * as routes from 'constants/routes.js';

// Libraries
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import stats from 'analytics/analytics';

// Components
import Switch from 'react-switch';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const onSetStatisticCookies = (setting) => dispatch({ type: 'SET_STATISTIC_COOKIES', setting });

  const { statCookies } = useSelector((state) => state.cookieState);

  const toggleStatisticCookie = () => {
    if (!statCookies === false) {
      stats.push(['forgetConsentGiven']);
    } else {
      stats.push(['rememberConsentGiven']);
    }
    onSetStatisticCookies(!statCookies);
  };

  return (
    <>
      <Helmet>
        <title>Flowdust - Datenschutzerklärung</title>
      </Helmet>

      <div className="column">
        <div className="columns is-centered">
          <div className="pb60 mt30 mb30 pl20 pr20 column is-10 has-text-justified box has-background-white has-hyphens">
            <h2 className="has-text-centered has-text-weight-bold has-text-pastel is-size-3 mt30">Datenschutzerklärung</h2>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">{'Allgemeine Begriffsbestimmungen '}</p>
            <p>Die nachfolgende Datenschutzerklärung der Flowdust Online Services UG (Haftungsbeschränkt) beruht auf Begrifflichkeiten, die in der Datenschutz-Grundverordnung (EU DS-GVO) sowie im Bundesdatenschutz neu (BDSG neu) Verwendung finden.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">{'1. Name und Anschrift des für die Verarbeitung Verantwortlichen '}</p>
            <p>Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO):</p>
            <p>Flowdust Online Services UG (haftungsbeschränkt)</p>
            <p>Am Bahnhof 1</p>
            <p>95632 Wunsiedel</p>
            <p>Telefon: 0160 4340857</p>
            <p>E-Mail: hello [@] flowdust.com</p>
            <p>Website: https://www.flowdust.com</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">2. Zuständige Aufsichtsbehörde</p>
            <p>Bayerisches Landesamt für Datenschutzaufsicht</p>
            <p>Promenade 27</p>
            <p>91522 Ansbach</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">3. Allgemeine Information über die Verarbeitung von personenbezogenen Daten</p>
            <p>Im Folgenden informieren wir Sie über die Verarbeitung personenbezogener Daten bei der Nutzung unserer Internetseiten und unserer Angebote.</p>
            <p className="has-text-weight-bold mt10">Definition „personenbezogene Daten“:</p>
            <p>Personenbezogene Daten sind Daten, die auf Sie persönliche Rückschlüsse zulassen könnten, z. B.: Vor- und Nachname, Adresse, E-Mail-Adressen oder Ihr Nutzerverhalten.</p>
            <p>„Verarbeitung“ meint jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder alle Vorgänge im Zusammenhang mit personenbezogenen Daten wie das Erfassen, das Erheben, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung; vgl. Art. 4 Nr. 2 DS-GVO.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">4. Verarbeitung von personenbezogenen Daten</p>
            <p>Die Rechtsgrundlage der Verarbeitung von personenbezogenen Daten für uns ergibt sich grundsätzlich aus Art. 6 DS-GVO. Die Daten, die bei der Verarbeitung erhoben werden, kategorisieren und verarbeiten wir wie folgt:</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-6">4.1 Generelle Nutzung unserer Webseiten und unseres Angebotes</p>
            <p className="mb10">(4.1.1) Speicherung von Logfiles</p>
            <p>Beim Besuch unserer Webseite verarbeiten wir folgenden Daten, die für uns technisch erforderlich sind, um unsere Webseiten anzuzeigen und um die Stabilität und Sicherheit unserer IT-Systeme zu gewährleisten.</p>
            <p className="mt10">- IP-Adresse</p>
            <p>- Datum und Uhrzeit der Anfrage</p>
            <p>- Inhalt der Anforderung (konkrete Seite)</p>
            <p>- Zugriffsstatus/HTTP-Statuscode</p>
            <p>- jeweils übertragene Datenmenge</p>
            <p>- Website, von der die Anforderung kommt (Referer)</p>
            <p>- Browser (Browsertypen und Versionen)</p>
            <p>- Betriebssystem</p>
            <p>- Zur Absicherung des technischen Betriebs werden die erhobenen Daten und Informationen in sog. Server-Logfiles gespeichert. Die Daten der Server-Logfiles werden getrennt von allen anderen Daten für eine Dauer von bis zu 90 Tagen gespeichert. Danach werden die Daten gelöscht.</p>
            <p className="mt20 mb10">(4.1.2) Einsatz von Cookies</p>
            <p>Auf unseren Internetseiten verwenden wir sog. „Cookies“. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.</p>
            <p>Durch den Einsatz von Cookies können wir unseren Besuchern und Kunden unserer Webseiten nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären. Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, die Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu erleichtern.</p>
            <p>Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar. Alternativ können Sie bei den Cookie Einstellungen die Option 'Ablehnen' wählen.</p>

            <p className="mt20 mb10">(4.1.3) Verwendung von Tokens in der Local Storage</p>
            <p>{'Beim Login bekommt der Nutzer einen eindeutigen JSON-Web-Token. Dieser Token ist für eine bestimmte Zeit gültig und wird in der sog. Local Storage gespeichert. Ist der Token abgelaufen, wird der Nutzer aus dem Benutzermenü ausgeloggt und die Local Storage automatisch gelöscht. '}</p>
            <p>In der Local Storage werden folgende Daten temporär gespeichert:</p>
            <p className="mt10">- Accountdaten</p>
            <p>- Umfragedaten</p>
            <p>- Formulardaten</p>
            <p>- Websitedaten</p>
            <p>- Daten über Flowter und Hub</p>
            <p className="mt20 mb10">(4.1.4) SSL-bzw. TLS-Verschlüsselung</p>
            <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen die Sie an uns senden, verwenden wir eine SSL-bzw. TLS-Verschlüsselung. Die verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und zusätzlich am Schloss-Symbol in der Adresszeile des Browsers.</p>
            <p className="mt20 mb10">(4.1.5) Zweck der Verarbeitung</p>
            <p className="mt10">- Zurverfügungstellung unseres Onlineangebotes, seiner Funktionen und Inhalte</p>
            <p>- Beantwortung von Kontaktanfragen / Kommunikation mit Anfragenden und Nutzern</p>
            <p>- Sicherheitsmaßnahmen</p>
            <p>- Marketing / Reichweitenmessung</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-6">4.2 Registrierung und Kundenkonto</p>
            <p>Neben der rein informatorischen Nutzung unserer Webseiten, bieten wir verschiedene Leistungen an, die Sie allein bei Interesse Ihrerseits nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten.</p>
            <p>Bei der Anmeldung zu unserem Angebot erheben wir zusätzlich zu den in Pkt. 5 genannten, folgende personenbezogenen Daten:</p>
            <p className="mt10">- Vorname</p>
            <p>- Emailadresse</p>
            <p className="mt10">{'Diese Daten werden zum Zweck der Durchführung unseres Vertrages Ihnen gegenüber erhoben und verarbeitet, insbesondere auch, um Ihnen abrechnungsrelevante Informationen zukommen lassen zu können. Ferner zur Rechnungslegung und ggf. auch daraus resultierender Folgeprozesse. Falls wir z.B. wichtige technische Informationen für Sie bereitstellen müssen, nutzen wir diese Daten u.a. auch, um Sie zu kontaktieren. '}</p>
            <p>Zusätzlich speichern wir:</p>
            <p className="mt10">- die Umfrage- und Formular-Daten (inkl. Metadaten, wie zum Beispiel Erstellungsdatum, Uhrzeit und gewählter Name)</p>
            <p>- die URLs der Webseiten, auf denen die Umfragen und Formulare angezeigt werden sollen</p>
            <p>- die ausgewerteten Ergebnisse der Umfragen und Formulare</p>
            <p>- Statistiken (wie zum Beispiel Anzahl der Klicks und Impressionen)</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-6">4.3 Auftragsverarbeitung für unsere Kunden (als Dienstleistung)</p>
            <p>(4.3.1) Wir stellen webbasierte Systeme zur Verfügung, mit denen unsere Kunden eigenverantwortlich Online-Formulare und Online-Umfragen unter Einbindung unserer Umfrage-Widgets durchführen können.</p>
            <p className="mt10">(4.3.2) Zur Durchführung von Online-Formularen und Online-Umfragen sowie Nutzung der weiteren Dienste und Produkte stellen wir unseren Kunden ausschließlich die technische Infrastruktur als datenschutzrechtlich sogenannter „Auftrags-Verarbeiter“ zur Verfügung. Kunden, die unsere Systeme zur Durchführung von Online-Umfragen oder Online-Formularen nutzen oder Benutzung weiterer Dienste, haben mit uns einen Auftragsverarbeitungsvertrag (AVV) geschlossen, der mit der DS-GVO im Einklang steht.</p>
            <p className="mt10">(4.3.3) Für die Rechtmäßigkeit der Verarbeitung von personenbezogenen Daten, insbesondere auch für die ordnungsgemäße und vollständige datenschutzrechtliche Information der betroffenen Person bei Online-Umfragen, Online-Formularen und zusätlichen Diensten ist allein derjenige verantwortlich, der die Online-Umfrage oder das Online-Formular durchführt bzw. Widgets in seine Website einbindet (im Regelfall unser Kunde, für den wir im Auftrag tätig sind). Gemäß Art. 4 Abs. 7 DS-GVO ist allein der Datenschutzrechtlich verantwortlich, der Umfragen oder Formulare erstellt und durchführt (Verantwortliche Person).</p>
            <p className="mt10">(4.3.4) Widgets werden ausschließlich auf externen Kundenwebseiten eingebunden, sodass für sämtliche Widgets, die mittels unserer technischen Infrastruktur bereitgestellt werden, die hier vorliegende Datenschutzerklärung keine Geltung entfaltet. Dies ergibt sich unmittelbar aus Punkt 5 in Verbindung mit Punkt 1 dieser Datenschutzerklärung.</p>
            <p className="mt10">Zur Wahrnehmung etwaiger datenschutzrechtlich bestehender Rechte gegen eine Verantwortliche Person, die Widgets unter Zuhilfenahme unserer technischen Systeme benutzt, wenden Sie sich bitte generell und direkt an denjenigen, der die Widgets erstellt hat und betreibt.</p>
            <p>Jegliche Informationen, die bei von Kunden erstellten Widgets an uns übermittelt werden sind Eigentum des Erstellers der Widgets. Daten aus Widgets werden von uns niemals weitergegeben.</p>
            <p>Flowdust erfasst bei Widgets und Formularen aus Sicherheitsgründen die IP Adresse, die jedoch nicht für die Auswertung des Nutzerverhaltens verwendet und nicht an den Kunden weitergegeben wird.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-6">{'4.4 Dauer der Speicherung / Löschfristen '}</p>
            <p>Daten, die im Rahmen eines Vertragsverhältnisses erhoben wurden, werden gemäß unseren Fristenregelungen wie folgt gelöscht:</p>
            <p>Nach Ablauf der Frist von längstens 180 Tagen nach dem letzten Login, werden sämtliche Bestandsdaten zu diesem Benutzerkonto gelöscht.	Daten, die zur Erfüllung bestehender gesetzlicher oder vertraglicher Aufbewahrungs-fristen benötigt werden, sperren wir oder halten sie gemäß den gesetzlichen Haltefristen vor. Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern die betroffene Person der weitergehenden Verarbeitung und Nutzung nicht zugestimmt hat.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">5. Kundenmeinungen / Testimonials</p>
            <p className="is-inline">Auf unserer Webseite veröffentlichen wir persönliche Empfehlungen von zufriedenen Kunden. Dabei verwenden wir ausschließlich Ihren Vornamen. Wenn Sie Ihre Empfehlung aktualisieren oder löschen möchten, wenden Sie sich bitte an uns:</p>
            <Link to={routes.IMPRESSUM} className="is-inline">{' https://www.flowdust.com/impressum'}</Link>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">{'6. Rechte der betroffenen Person '}</p>
            <p>{'Jede betroffene Person hat hinsichtlich der Sie betreffenden personenbezogenen Daten das: '}</p>
            <p className="mt10">- Recht auf Bestätigung,</p>
            <p>- Recht auf Auskunft,</p>
            <p>- Recht auf Berichtigung oder Löschung,</p>
            <p>- Recht auf Vergessen werden,</p>
            <p>- Recht auf Einschränkung der Verarbeitung,</p>
            <p>- Recht auf Widerspruch gegen die Verarbeitung,</p>
            <p>- Recht auf Datenübertragbarkeit.</p>
            <p>Jede betroffene Person hat zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">7. Widerspruch gegen die Verarbeitung Ihrer Daten</p>
            <p className="mt10">(7.1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.</p>
            <p className="mt10">(7.2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">8. Kontaktaufnahme per E-Mail</p>
            <p>(8.1) Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Mitteilung) von uns gespeichert, um Ihr Anliegen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
            <p className="mt10">(8.2) Die Datenverarbeitung erfolgt aufgrund einer vertraglichen Grundlage, soweit es um Fragestellungen rund um das Kundenkonto geht und um Ihnen den gewünschten bzw. angefragten Service anbieten zu können.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">9. Newsletter</p>
            <p className="mb10">{'Wir bieten einen Newsletter an, der Sie über unser Angebot und über Angebote unserer Partner informiert. '}</p>
            <p>{'Für den Newsletterversand können Sie sich im Kundenportal oder auf der Startseite registrieren. Zum Versand verwenden wir die bei der Registrierung angegebene E-Mailadresse. Die Versendung des Newsletters erfolgt nur mit ihrer ausdrücklichen Einwilligung. '}</p>
            <p>Nach Eingabe Ihrer E-Mail erhalten sie eine Bestätigungs-E-Mail an die angegebene E-Mail-Adresse. Die Versendung des Newsletters erfolgt nur nach einer ausdrücklichen Bestätigung durch Klick auf einen in der Bestätigungs-E-Mail hinterlegten Link (sog. Double-Opt-In).</p>
            <p>Sie haben das Recht, jederzeit die von Ihnen erteilte Einwilligung für die Zukunft zu widerrufen und sich von unserem Newsletter abzumelden. Hierzu klicken Sie bitte entweder in einer Newsletter E-Mail auf den Link „Vom Newsletter abmelden“ oder gehen ins Kundenkonto unter Accounteinstellungen und klicken Sie auf den Button „Vom Newsletter abmelden“. Ihre E-Mail-Adresse wird nach erfolgtem Widerruf von der Newsletter-Liste gelöscht.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">10. Nutzung der Blogfunktionen auf unserer Internetseite</p>
            <p>Flowdust bietet Besuchern im firmeneigenen Blog die Möglichkeit, individuelle Kommentare zu einzelnen Blogbeiträgen zu hinterlassen. Ein Blog ist ein auf einer Internetseite geführtes, in der Regel öffentlich einsehbares Portal, in welchem eine oder mehrere Personen, die Blogger oder Web-Blogger genannt werden, Artikel posten oder Gedanken in sogenannten Blogposts niederschreiben können. Die Blogposts können in der Regel von Dritten kommentiert werden.</p>
            <p>Hinterlässt eine betroffene Person einen Kommentar in dem auf dieser Internetseite veröffentlichten Blog, werden neben den von der betroffenen Person hinterlassenen Kommentaren auch Angaben zum Zeitpunkt der Kommentareingabe sowie zu dem von der betroffenen Person gewählten Nutzernamen (Pseudonym) gespeichert und veröffentlicht.</p>
            <p>Ferner wird die vom Internet-Service-Provider (ISP) der betroffenen Person vergebene IP-Adresse mitprotokolliert. Diese Speicherung der IP-Adresse erfolgt aus Sicherheitsgründen und für den Fall, dass die betroffene Person durch einen abgegebenen Kommentar die Rechte Dritter verletzt oder rechtswidrige Inhalte postet. Die Speicherung dieser personenbezogenen Daten erfolgt daher im eigenen Interesse des für die Verarbeitung Verantwortlichen, damit sich dieser im Falle einer Rechtsverletzung gegebenenfalls exkulpieren könnte. Es erfolgt keine Weitergabe dieser erhobenen personenbezogenen Daten an Dritte, sofern eine solche Weitergabe nicht gesetzlich vorgeschrieben ist oder der Rechtsverteidigung des für die Verarbeitung Verantwortlichen dient.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">{'11. Datenschutz bei Bewerbungen und im Bewerbungsverfahren '}</p>
            <p>Sofern Sie sich bei uns bewerben, erheben und verarbeiten wir die personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise per E-Mail, an den für die Verarbeitung Verantwortlichen übermittelt.</p>
            <p>Schließt Flowdust einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Wird kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">12. Zahlungsabwicklung</p>
            <p className="is-inline">(12.1) PayPal (Braintree). Sie können u.a. über das Payment Gateway Braintree von PayPal bezahlen. PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (“PayPal”) erhält Ihre E-Mail-Adresse und Einzelheiten zu den von Ihnen bei uns bestellten Dienstleistungen. Informationen, die Braintree zur Verfügung gestellt werden, stehen nicht unter unserer Kontrolle und unterliegen der Datenschutzerklärung von PayPal, die hier zu finden ist:</p>
            <a href="https://www.braintreepayments.com/en-de/legal/braintree-privacy-policy" className="is-inline">{' https://www.braintreepayments.com/en-de/legal/braintree-privacy-policy. '}</a>
            <p className="mt10">(12.2) Zahlungen mit Kreditkarte über Braintree. Zur Abwicklung Ihres Abonnements speichern wir keinerlei Kreditkarteninformationen. Für die Dauer des Vertragsverhältnisses speichern wir die Braintree Customer-ID und die Braintree Abonnement-ID.</p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">13. Datenschutzbestimmungen zu Einsatz und Verwendung von Matomo (ehem. Piwik)</p>
            <p>Flowdust hat auf seinen Internetseiten die Komponente Matomo integriert. Matomo ist ein Open-Source-Softwaretool zur Web-Analyse. Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten von Besuchern von Internetseiten. Ein Web-Analyse-Tool erfasst unter anderem Daten darüber, von welcher Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannter Referrer), auf welche Unterseiten der Internetseite zugegriffen oder wie oft und für welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung einer Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.</p>
            <p>Die Software wird auf den Servern von Flowdust betrieben, die datenschutzrechtlich sensiblen Logdateien werden ausschließlich auf unseren Servern gespeichert.</p>
            <p>Der Zweck der Matomo-Komponente ist die Analyse der Besucherströme auf unserer Internetseite. Flowdust nutzt die gewonnenen Daten und Informationen unter anderem dazu, die Nutzung dieser Internetseite auszuwerten, um Online-Reports, welche die Aktivitäten auf unseren Internetseiten aufzeigen, zusammenzustellen.</p>
            <p>Matomo setzt ein Cookie auf dem informationstechnologischen System der betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Mit der Setzung des Cookies wird uns eine Analyse der Benutzung unserer Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite wird der Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch durch die Matomo-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an unseren Server zu übermitteln. Im Rahmen dieses technischen Verfahrens erhalten wir Kenntnis über personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die uns unter anderem dazu dient, die Herkunft der Besucher und Klicks nachzuvollziehen.</p>
            <p className="mt10">Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die Häufigkeit der Besuche auf unserer Internetseite gespeichert. Bei jedem Besuch unserer Internetseiten werden diese personenbezogenen Daten, einschließlich der anonymisierten IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an unseren Server übertragen. Diese personenbezogenen Daten werden durch uns gespeichert. Wir geben diese personenbezogenen Daten nicht an Dritte weiter und verknüpfen diese Daten nicht mit anderen personenbezogenen Daten, die auf unseren Servern gespeichert werden.</p>
            <p>Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Matomo ein Cookie auf dem informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Matomo bereits gesetzter Cookie jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden.</p>
            <p>Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch den Matomo erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten zu widersprechen und eine solche zu verhindern. Hierzu muss die betroffene Person ein Opt-Out-Cookie setzen. Wird das informationstechnologische System der betroffenen Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu installiert, muss die betroffene Person erneut einen Opt-Out-Cookie setzen.</p>
            <p>Mit der Setzung des Opt-Out-Cookies besteht jedoch die Möglichkeit, dass die Internetseiten des für die Verarbeitung Verantwortlichen für die betroffene Person nicht mehr vollumfänglich nutzbar sind.</p>
            <p className="mt10 has-text-weight-bold is-size-6">Widerspruch</p>
            <div className="columns is-mobile cookieSetting is-marginless">
              <div className="column pb20">
                <p className=" has-text-black">Diese Cookies erlauben uns die Nutzer zu identifizieren, Website-Traffic zu messen und analysieren und ermöglichen andere erweiternde Features. Automatisch abgelehnt.</p>
                <div className="columns is-mobile mt5">
                  <div className="column has-text-right pr0 pb0 has-text-weight-bold">
                    <span className="is-size-6 has-text-pastel">Abgelehnt</span>
                  </div>
                  <div className="column pl0 pr0 pb0 ml5 mr5 is-3 has-text-centered">
                    <Switch
                      onChange={toggleStatisticCookie}
                      checked={statCookies}
                      onColor="#209CEE"
                      onHandleColor="#ffffff"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      offColor="#ff914d"
                      handleDiameter={25}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      className="switch"
                    />
                  </div>
                  <div className="column pl0 pb0 pl5 has-text-weight-bold">
                    <span className="is-size-6 has-text-info">Akzeptiert</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="is-inline">
              {' '}
              Weitere Informationen und die geltenden Datenschutzbestimmungen von Matomo können abgerufen werden unter:
            </p>
            <a
              href="https://matomo.org/privacy/"
              className="is-inline"
            >
              {' '}
              { ' https://matomo.org/privacy/' }
            </a>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">
              {' '}
              14. Links zu externen Webseiten
            </p>
            <p>
              {' '}
              Unsere Website kann Links zu externen Webseiten Dritter oder zu Webseiten von uns selbst enthalten. Wenn Sie einem Link zu einer dieser Webseiten folgen, weisen wir Sie darauf hin, dass diese Webseiten eigene Datenschutzrichtlinien haben und dass wir für diese keine Verantwortung oder Haftung übernehmen. Bitte überprüfen Sie deren Datenschutzrichtlinien, bevor Sie personenbezogenen Daten an diese Webseiten weitergeben.
            </p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">
              {' '}
              15. Weitere Informationen und Kontakte
            </p>
            <p>
              {' '}
              Wenn Sie weitere Fragen zum Thema “Datenschutz bei der verantwortlichen Stelle” haben, können Sie sich jederzeit an uns wenden. Unsere Kontaktdaten finden Sie in unserem Impressum
            </p>
            <p>
              {' '}
              { 'Wir behalten uns das Recht vor, diese Datenschutzerklärung zu ändern oder anzupassen, wenn sich z.B. die Rechtslage oder dieses Online-Angebot oder die Art der Datenerfassung ändert. Diese gelten jedoch ausdrücklich nur in Bezug auf die Erklärungen zur Datenverarbeitung. Sofern eine Einwilligung des Nutzers notwendig ist oder Bestandteile der Datenschutzerklärung eine Regelung des Vertragsverhältnisses mit Nutzern enthalten, erfolgt die Änderung der Datenschutzerklärung nur nach Zustimmung des Nutzers. ' }
            </p>
            <p className="mt10">
              {' '}
              Bitte informieren Sie sich daher bei Bedarf über unsere Datenschutzerklärung, insbesondere, wenn Sie uns personenbezogene Daten mitteilen.
            </p>
            <p className="has-text-weight-bold mt20 mb20 is-size-5">
              {' '}
              { '16. Bestehen einer automatisierten Entscheidungsfindung ' }
            </p>
            <p>
              {' '}
              Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder ein Profiling.
              {' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
