import React, { useState, useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setFilter } from 'reducer/hubs';

// Components
import QuestionSelector from './QuestionSelector';

const MAX_AMOUNT_OF_FILTERS = 7;
/**
  * Component to create, delete and change filters about questions
  *
  * @example For Question at index 1, Duration should be less or equal to 3 seconds
  * question would be 1, type would be "duration", sign "lte" and value 3000
  *
  * */
const QuestionFilter = () => {
  const { filter } = useSelector((state) => state.hubs.statistic);
  const dispatch = useDispatch();

  const addFilter = () => {
    const newFilter = {
      question: 0,
      /*
       * duration, value, input, answer
       */
      type: 'duration',
      sign: 'eq',
      value: '',
    };

    dispatch(setFilter({
      questions: [
        ...filter.questions,
        newFilter,
      ],
    }));
  };

  const deleteFilter = (index) => {
    const newFilter = filter.questions.filter((_filter, _index) => _index !== index);

    dispatch(setFilter({
      questions: newFilter,
    }));
  };

  const _setFilter = (index, _filter) => {
    const newFilter = filter.questions.slice();

    newFilter[index] = _filter;

    dispatch(setFilter({
      questions: newFilter,
    }));
  };

  const renderedFilters = useMemo(() => filter.questions.map((_filter, index) => (
    <div key={index} className="my-2">
      <QuestionSelector
        index={index}
        filter={_filter}
        setFilter={(newFilter) => _setFilter(index, newFilter)}
      />

      <button
        type="button"
        onClick={() => deleteFilter(index)}
        className="cleanButton has-text-danger has-text-underlined"
      >
        Löschen
      </button>
    </div>
  )), [filter.questions]);

  return (
    <div className="field">
      <p className="has-border-top pt-4 is-size-6 has-text-weight-bold has-text-black">Fragenfilter</p>
      { renderedFilters }
      {
        filter.questions.length < MAX_AMOUNT_OF_FILTERS
        && (
        <button
          type="button"
          className="mt-3 mb-5 is-inline button pl10 pr10 is-size-7 is-outlined has-background-grey-lighter has-no-border has-text-black has-hover br10 has-fullheight"
          onClick={addFilter}
        >
          <i className="fas fa-plus has-text-pastel is-size-7 mr-2" />
          <p className="is-size-7 has-text-weight-bold is-inline">
            Filter für eine Frage hinzufügen
          </p>
        </button>
        )
      }
    </div>

  );
};

export default QuestionFilter;
