import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import ResponseDisplay from './ResponseDisplay';

/*
 * Shows the whole submission for one user with the path
 * the user took through the questions
 */
const SubmissionInfo = (props) => {
  const { index } = props;

  const { hubs, statistic } = useSelector((state) => state.hubs);
  const { hubIndex, submissions } = statistic;

  const { path, responses } = submissions[index].submission;
  const { questions } = hubs[hubIndex].content;

  // show the submission for one user, but for all questions the user has answered
  const renderedSubmission = useMemo(() => {
    const renderedResponse = [];

    // walk through the path the user took

    for (let i = 0; i < responses.length; i++) {
      // path shows the index of the questions the user answered, without the first question
      // e.g [1,3] means user answered question at index 0, 1 and then 3
      const response = responses[i];
      const { duration } = response;

      // duration of 0 means question has not yet been answered
      if (duration === 0) continue;

      renderedResponse.push(
        <ResponseDisplay
          questionData={questions[i]}
          responseData={response}
          key={i}
          index={i}
        />,
      );
    }

    return renderedResponse;
  }, []);

  return renderedSubmission;
};

SubmissionInfo.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SubmissionInfo;
