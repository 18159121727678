import React, { useState, useEffect } from 'react';

// Hooks
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Actions
import { updateHub } from 'reducer/hubs';

// Assets
import defaultAvatar from 'assets/images/hub/icons/avatar.png';
import formatBytes from 'utils/formatBytes';

/**
 * Drag & Drop Zone to upload images
 */
const ProfilePicCreator = () => {
  const [imgData, setImgData] = useState();
  const [imgUrl, setImgUrl] = useState(defaultAvatar);

  const [status, setStatus] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();

  const { fileStorageUsedBytes, planDetails } = useSelector((state) => state.sessionState.user);
  const { hubs, creator } = useSelector((state) => state.hubs);

  const { maxfileSizeBytes, fileStorageSizeBytes } = planDetails;

  const dispatch = useDispatch();

  const { editIndex, profilePic } = creator;

  useEffect(() => {
    if (profilePic) {
      setImgUrl(`https://cdn.flowdust.com/hub/images/profile/${profilePic}.png`);
    }

    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [profilePic]);

  const saveProfilePic = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const formData = new FormData();
      formData.append('profilePic', imgData);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };

      const { _id } = hubs[editIndex];

      const hub = await post(`/hubs/profile/picture/${_id}`, formData, config);
      dispatch(updateHub({ id: editIndex, hub }));

      setDisabled(false);
      setImgData();

      setStatus('Erfolgreich gespeichert.');
    } catch (e) {
      setDisabled(false);

      console.log(e);

      if (!e.response.data) {
        setStatus('Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.');
        return;
      }

      setStatus(e.response.data);
    }
  };

  const _setProfilePic = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setStatus('');
    setImgData(file[0]);

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const fileStorageValidator = (_file) => {
    if (_file.size + fileStorageUsedBytes > fileStorageSizeBytes) {
      return {
        code: 'not-enough-storage',
        message: 'Dein Speicher ist voll! Lösche ein paar andere Dateien oder ändere dein Abonnement!',
      };
    }

    return null;
  };

  const onDropRejected = (data) => {
    setStatus('Bitte lade ein gültiges Bild hoch! (jpg oder png und maximal 2 MB groß)');
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    noDrag: true,
    onDrop: _setProfilePic,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
    // validator: fileStorageValidator,
  });

  return (
    <div
      className="control is-flex flex-direction-column has-content-centered mt-4"
    >
      <div {...getRootProps({
        className: 'box boxshadow has-cursor-pointer has-width-250 has-text-centered',
      })}
      >
        <input {...getInputProps()} />
        <div className="has-border has-width-128 has-height-128 br100 has-overflow-hidden p-0 br100 has-background-white mb-3 has-margin-auto is-flex has-content-centered">
          <img
            src={imgUrl}
            className="has-max-fullheight has-max-fullwidth"
            alt=""
          />
        </div>
        <button
          type="button"
          className="button has-no-background has-text-black has-text-weight-bold mt-2"
          disabled={disabled}
        >
          {
            imgData
              ? 'Anderes Profilbild auswählen'
              : 'Profilbild auswählen'
           }
        </button>
      </div>
      {
             imgData
             && (
             <button
               type="button"
               className="button br10 is-primary has-text-weight-bold mb-4"
               disabled={disabled}
               onClick={saveProfilePic}
             >
               Neues Profilbild speichern
             </button>
             )
        }
      <p className="has-text-black has-text-weight-bold is-size-7 as-text-centered mb-4">
        { status }
      </p>
    </div>
  );
};

export default ProfilePicCreator;
