import React, { useState, useCallback } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

// Styles
import styles from './styles.module.scss';

function ImageDisplay(props) {
  const { url } = props;

  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback((shouldZoom) => {
    // dont zoom on mobile
    if (window.innerWidth < 768 && shouldZoom) return;

    setIsZoomed(shouldZoom);
  }, [setIsZoomed]);

  return (
    <div
      type="button"
      className="cleanButton p-0 has-fullwidth has-fullheight"
    >
      <div className="is-relative is-flex br-5 has-width-150 has-height-150 has-background-lightblue has-content-centered is-in-center br6 has-border-grey-light">
        <ControlledZoom
          isZoomed={isZoomed}
          onZoomChange={handleZoomChange}
        >
          <img
            src={url}
            className="is-block has-max-fullwidth has-max-fullheight is-in-center has-height-150"
            alt=""
          />
          <i
            id={styles.icon}
            aria-label="Bild zoomen"
            onClick={() => setIsZoomed(true)}
            className="has-text-right fas fa-search-plus has-text-black has-background-white p-2 br5 is-size-6 mt-2"
          />
        </ControlledZoom>

      </div>
    </div>
  );
}

ImageDisplay.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImageDisplay;
