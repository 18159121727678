import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import FeedbackDisplay from '../FeedbackDisplay';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Chart from 'components/hub/HubStatistic/Statistic/Chart';
import SliderDisplay from './SliderDisplay';
import MediaDisplay from 'components/hub/MediaDisplay';
import BasicQuestionStatistic from '../BasicQuestionStatistic';

const SliderStatistic = (props) => {
  const { index } = props;

  const { hubs, statistic: statisticObj } = useSelector((state) => state.hubs);
  const {
    _id, hubIndex, amountSubmissions, resultsPerQuestion,
  } = statisticObj;

  const questionData = hubs[hubIndex].content.questions[index];

  // prepare statistic data and return defaults if we dont have data
  const statisticData = useMemo(() => {
    const results = resultsPerQuestion.find((_results) => _results.question === index);

    // if we dont find any data for this question, return defaults
    if (!results) {
      return {
        values: [],
        avgDuration: 0,
        submissions: 0,
        question: index,
      };
    }

    return results;
  }, [resultsPerQuestion]);

  const [statistic, setStatistic] = useState([]);
  const [average, setAverage] = useState(-1);
  const [median, setMedian] = useState(-1);

  const { values, avgDuration, submissions } = statisticData;
  const {
    text, media, allowText, isOptional,
  } = questionData;

  useEffect(() => {
    // if we dont have data, set defaults
    if (values.length === 0) {
      setAverage(-1);
      setStatistic([]);
      return;
    }

    let sumValues = 0;
    let amountOfValues = 0;

    // fill all the data into buckets
    const buckets = [
      {
        x: '0 - 25%', y: 0, index: 0, value: 0,
      },
      {
        x: '26 - 50%', y: 0, index: 1, value: 1,
      },
      {
        x: '51 - 75%', y: 0, index: 2, value: 2,
      },
      {
        x: '76 - 100%', y: 0, index: 3, value: 3,
      },
    ];

    for (let i = 0; i < values.length; i++) {
      // value is the type of the slection (skipped or the slider amount)
      // amount is how many people chose that selection
      const { value, amount } = values[i];

      // let renderedValue = `${value}`;

      if (value === -2) {
        // add skipped statistic if question has been skipped
        buckets.push({
          y: amount, x: 'Übersprungen', index: 4, value: -2,
        });
        continue;
        // add the selected slider amount to the buckets
      } else if (value >= 76) {
        buckets[3].y += amount;
      } else if (value >= 51) {
        buckets[2].y += amount;
      } else if (value >= 26) {
        buckets[1].y += amount;
      } else {
        buckets[0].y += amount;
      }

      // add up all the values to be able to show the average
      amountOfValues += amount;
      sumValues += amount * value;
    }

    // calculate average, make sure to not devide by 0
    setAverage((sumValues / Math.max(1, amountOfValues)).toFixed(2));

    setStatistic(buckets);
  }, [statisticData]);

  const actualSubmissions = useMemo(() => {
    if (statistic.length === 0) return 0;

    return submissions;
    // return submissions - skipData.y;
  }, [statistic]);

  return (
    <div className="box">
      <p className="mb-5 has-text-weight-semibold has-text-pastel has-text-left">
        Fragentyp: Rating
      </p>
      <h2 className="title br10 pt15 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey mb-4 px-3">
        {text}
      </h2>
      <MediaDisplay file={media} hubID={_id} />
      {
        isOptional
        && (
        <p className="mt-2 has-text-grey my-5">
          Diese Frage ist optional und konnte übersprungen werden.
        </p>
        )
      }
      <BasicQuestionStatistic
        totalSubmissions={amountSubmissions}
        submissions={actualSubmissions}
        avgDuration={avgDuration}
      />
      { average !== -1
        && (
        <div className="my-4 has-text-black has-text-center">
          <p className="is-inline mr-1">
            Durchschnitt:
          </p>
          <p className="is-inline has-text-weight-bold">
            {`${average}%`}
          </p>
          <SliderDisplay value={average} />
        </div>
        )}
      {
        statistic.length !== 0
          && (
            <>
              <Chart statistic={statistic} />
            </>
          )
      }
      {
        allowText
        && <FeedbackDisplay index={index} showValue />
      }
    </div>
  );
};

SliderStatistic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SliderStatistic;
