import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics.js';
import notification from 'utils/notification';
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setStatistic } from 'reducer/hubs';

// Components
import Popup from 'reactjs-popup';

// Assets
import loader from 'assets/images/loading.svg';

function DeleteButton(props) {
  const { responseID } = props;

  const [disabled, setDisabled] = useState(false);

  const { _id: hubID, hubIndex } = useSelector((state) => state.hubs.statistic);

  const dispatch = useDispatch();
  const { post } = useAPI();

  const deleteEntry = async (close) => {
    try {
      setDisabled(true);
      const data = { hubID };
      const statistic = await post(`/hubs/responses/delete/${responseID}`, data);

      close();
      setDisabled(false);

      dispatch(setStatistic({ statistic, hubIndex }));

      stats.push(['trackEvent', 'Hub Statistic', 'Submission deleted']);
    } catch (err) {
      console.log(err);
      setDisabled(false);

      notification.send('Fehler!', 'Eintrag konnte nicht gelöscht werden.', 'danger');
    }
  };

  return (
    <Popup
      trigger={(
        <button
          type="button"
          className="cleanButton has-text-weight-semibold has-text-danger is-block has-fullheight has-fullwidth"
          disabled={disabled}
        >
          <i className="is-inline has-text-danger fas fa-trash mr-1" />
          <p className="is-inline is-size-7">
            Eintrag löschen
          </p>
        </button>
      )}
      position="left top"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
    >
      { (close) => (
        <div className="columns is-marginless">
          <div className="column is-marginless is-paddingless pb10 pl5 pr5 br22 has-background-white">
            <div className="br22 has-text-centered">
              <div className="has-text-right">
                <button
                  className="cleanButton"
                  onClick={close}
                  type="button"
                >
                  <i className="fas fa-times has-text-grey-lighter is-size-7" />
                </button>
              </div>
              <h2 className="has-text-centered has-text-black is-size-7">
                Bist Du sicher, dass Du den Eintrag löschen möchtest?
              </h2>
              {
              disabled
                ? <img className="buttonLoader" src={loader} alt="" />
                : (
                  <button
                    className="pt5 pb5 pl10 pr10 cleanButton mt5"
                    onClick={() => deleteEntry(close)}
                    disabled={disabled}
                    type="button"
                  >
                    <i className="fas fa-trash has-text-danger is-size-7 is-inline mr-1" />
                    <p className="is-inline has-text-danger has-text-weight-semibold is-size-7">Löschen</p>
                  </button>
                )
              }
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

DeleteButton.propTypes = {
  responseID: PropTypes.string.isRequired,
};

export default DeleteButton;
