import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as routes from 'constants/routes';

const withoutAuthentication = () => (Component) => {
  function WithoutAuthentication(props) {
    const { isAuthenticated } = useAuth0();
    const { navigate } = useNavigate();

    useEffect(() => {
      if (isAuthenticated) {
        navigate(routes.HOME);
      }
    }, [isAuthenticated]);

    return isAuthenticated ? null : <Component {...props} />;
  }

  return WithoutAuthentication;
};

export default withoutAuthentication;
