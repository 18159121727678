// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setUser } from 'actions/user';
import { setFlowters } from 'actions/flowter';
import { setStatusWidgets } from 'actions/statusWidgets';
import { setWebsites } from 'actions/websites';

const useDataLoading = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  // const { hasData } = useSelector((state) => state.sessionState);

  const dispatch = useDispatch();

  const { get, post } = useAPI();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) return;

    const path = window.location.pathname;

    if (path.includes('/login')) return;

    const loadData = async () => {
      try {
        setLoading(true);

        const { user } = await post('/users', {});
        const websites = await get('/websites');
        const widgets = await get('/users/widgets');

        const { flowters, statusWidgets } = widgets;

        dispatch(setUser(user));
        dispatch(setWebsites(websites));
        dispatch(setFlowters(flowters || []));
        dispatch(setStatusWidgets(statusWidgets || []));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    loadData();
  }, [isAuthenticated]);

  return {
    isLoading: isLoading || loading,
  };
};

export default useDataLoading;
