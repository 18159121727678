import React, { useState } from 'react';
import SignOutButton from 'components/auth/SignOut';
import { useSelector } from 'react-redux';
import './css/navigationBar.scss';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as STATUS from 'constants/status';
import Popup from 'reactjs-popup';
import { HashLink } from 'react-router-hash-link';
import flowtericon from 'assets/images/flowtericon.png';
import hubicon from 'assets/images/hubicon2.png';
/*
<div className="navbar-item has-dropdown has-text-weight-bold has-text-grey p0 has-no-border">
 <Popup
   trigger={(
     <p className="is-flex has-content-centered has-cursor-pointer dont-allow-selection navbar-dropdown-trigger">
        Anwendungsgebiete
        <i className="fas fa-caret-down is-size-7 has-text-grey is-inline ml5" />
     </p>
   )}
   position="bottom center"
   on={['click']}
   className="navbar-popup"
 >
   {
     (close) => (
        <div className="pt5 pb5">
          <Link
            className="navbar-item has-text-weight-bold has-text-grey"
            to={routes.ECOMMERCE_INFO}
            onClick={() => props.hide(close)}
          >
            E-Commerce
          </Link>
          <Link
            className="navbar-item has-text-weight-bold has-text-grey"
            to={routes.SERVICES_INFO}
            onClick={() => props.hide(close)}
          >
            Onlinedienste
          </Link>
          <Link
            className="navbar-item has-text-weight-bold has-text-grey"
            to={routes.OTHER_INFO}
            onClick={() => props.hide(close)}
          >
            Weitere
          </Link>
        </div>
     )
   }
 </Popup>
</div>

*/

/*
<button
  className="button has-background-pastel grow has-text-white has-text-weight-semibold is-rounded"
  onClick={props.signIn}
  type="button"
>
  Kostenlos registrieren
</button>
*/

function NavigationBar(props) {
  const [showNavbarMobile, setShowNavbarMobile] = useState('');
  const [disabled, setDisabled] = useState(false);
  const toggleNavbarMobile = () => {
    if (showNavbarMobile === 'is-active') {
      setShowNavbarMobile('');
    } else {
      setShowNavbarMobile('is-active');
    }
  };
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const signIn = async () => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    setShowNavbarMobile('');
    await loginWithRedirect();
  };
  const hideNavbarMobile = () => {
    setShowNavbarMobile('');
  };
  const hide = (close) => {
    close();
    hideNavbarMobile();
  };

  if (!isAuthenticated) {
    return (
      <div>
        <Navigation
          toggleNavbarMobile={toggleNavbarMobile}
          showNavbarMobile={showNavbarMobile}
          hide={hide}
          hideNavbarMobile={hideNavbarMobile}
          signIn={signIn}
        />
        <div className="navigationBarMargin" />
      </div>
    );
  }
  const { user } = useSelector((state) => state.sessionState);
  let isOnboarding = false;
  if (user) {
    isOnboarding = user.status === STATUS.NEW_USER;
  }
  return (
    <NavigationBarAuth
      toggleNavbarMobile={toggleNavbarMobile}
      showNavbarMobile={showNavbarMobile}
      hideNavbarMobile={hideNavbarMobile}
      isOnboarding={isOnboarding}
    />
  );
}

/*
  <Link
    className="navbar-item has-text-weight-bold has-text-grey"
    to={routes.STATUSWIDGET_INFO}
    onClick={() => props.hide(close)}
  >
    Statusmeldungen
  </Link>
      <div
      className="has-fullwidth has-text-centered has-background-pastel has-text-white px-4 py-2 has-text-weight-bold"
    >
      <HashLink
        className="has-text-white"
        to={`${routes.LANDING}#pricing`}
        onClick={props.hideNavbarMobile}
      >
        + + + + 🎉 Launch-Special zum Flowdust Hub! Alle Pläne um 33% reduziert! 🎉 + + + +
      </HashLink>

    </div>
*/

function Navigation(props) {
  return (
    <div className="is-fixed-top has-height-auto has-fullwidth has-max-z-index">

      <nav className="navbar boxshadow has-background-white navigationBar has-no-border navbarNonAuth" role="navigation" aria-label="main navigation">

        <div className="navbar-brand">
          <Link
            to={routes.LANDING}
            className="has-text-pastel is-size-3 flowdustNavbar pt5 pb10 pl30 pr15"
            onClick={props.hideNavbarMobile}
          >
            Flowdust
          </Link>
          <button
            className="navbar-burger has-text-black button"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => props.toggleNavbarMobile()}
            type="button"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>
        <div className={`navbar-menu mt5 mb5 ${props.showNavbarMobile}`}>
          <div className="navbar-start has-content-centered">
            <div className="navbar-item has-text-weight-bold has-text-grey has-no-border p0">
              <Popup
                trigger={(
                  <p className="is-flex has-content-centered has-cursor-pointer dont-allow-selection navbar-dropdown-trigger">
                    Produkte
                    <i className="fas fa-caret-down is-size-7 has-text-grey is-inline ml5" />
                  </p>
            )}
                position="bottom center"
                on={['click']}
                className="navbar-popup"
                keepTooltipInside
              >
                {
              (close) => (
                <div className="py-4 px-2">
                  <Link
                    className="navbar-item has-text-weight-bold has-text-grey is-block mb-3"
                    to={routes.FLOWTER_INFO}
                    onClick={() => props.hide(close)}
                  >
                    <div>
                      <div className="is-flex align-items-center mb-1">
                        <img className="mr-2" src={flowtericon} alt="" />
                        <p className="is-inline has-text-weight-bold has-text-black">
                          Flowter
                        </p>
                      </div>
                      <p
                        className="is-size-7 has-text-grey has-text-weight-semibold"
                      >
                        Mini-Umfragen direkt auf der Website
                      </p>
                    </div>
                  </Link>
                  <Link
                    className="navbar-item has-text-weight-bold has-text-grey is-flex align-items-center"
                    to={routes.HUB}
                    onClick={() => props.hide(close)}
                  >
                    <div>
                      <div className="is-flex align-items-center mb-1">
                        <img className="mr-2" src={hubicon} alt="" />
                        <p className="is-inline has-text-weight-bold has-text-black">
                          Hub
                        </p>
                      </div>
                      <p
                        className="is-size-7 has-text-grey has-text-weight-semibold"
                      >
                        Vorkonfigurierte Seite für Formulare und Umfragen
                      </p>
                      <p className="has-background-pastel boxshadow is-absolute br10 px-2 py-1 has-text-white has-text-weight-bold is-block top-0 ml110 is-size-7">
                        Neu!
                      </p>
                    </div>
                  </Link>
                </div>
              )
            }
              </Popup>
            </div>

            <Link
              className="navbar-item has-text-weight-bold has-text-grey"
              to={routes.DEMO}
              onClick={props.hideNavbarMobile}
            >
              Editor
            </Link>

            <Link
              className="navbar-item has-text-weight-bold has-text-grey"
              to={routes.FAQ}
              onClick={props.hideNavbarMobile}
            >
              FAQ
            </Link>
            <a
              className="navbar-item has-text-weight-bold has-text-grey"
              href="https://blog.flowdust.com"
              rel="noreferrer"
              target="_blank"
              onClick={props.hideNavbarMobile}
            >
              Blog
            </a>

            <HashLink
              className="navbar-item has-text-weight-bold has-text-grey"
              to={`${routes.LANDING}#pricing`}
              onClick={props.hideNavbarMobile}
            >
              Preise
            </HashLink>

          </div>
          <div className="navbar-end">
            <div className="navbar-item pl-0">
              <div className="buttons has-fullheight">
                <button
                  className="button has-background-pastel grow has-text-white has-text-weight-semibold br10"
                  onClick={props.signIn}
                  type="button"
                >
                  Anmelden
                  <i className="fas fa-user-circle ml-2 mt-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
function NavigationBarAuth(props) {
  return (
    <nav className="navbar has-background-white has-border-bottom navigationBarAuth" role="navigation" aria-label="main navigation">

      <div className="navbar-brand">
        <button
          className="navbar-burger has-text-black button"
          aria-label="menu"
          aria-expanded="false"
          onClick={() => props.toggleNavbarMobile()}
          type="button"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>
      <Link
        to={routes.HOME}
        className="has-text-centered has-text-pastel is-size-3 flowdustNavbar"
      >
        Flowdust
      </Link>
      <div className={`navbar-menu nml50 mt5 mb5 ${props.showNavbarMobile}`}>
        <div className="navbar-start">
          <Link
            to={props.isOnboarding ? routes.NEW_USER : routes.HOME}
            className="button has-hover br10 navbarButton"
            onClick={props.hideNavbarMobile}
          >
            <i className="fas fa-home is-size-5 has-text-midnightblue" />
            <p className="ml10 has-text-weight-bold is-size-6 has-text-midnightblue">Dashboard</p>
          </Link>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <SignOutButton hideNavbarMobile={props.hideNavbarMobile} />
          </div>
        </div>
      </div>
    </nav>
  );
}

/**
 <div className="navbar-item has-text-weight-bold has-text-grey has-no-border p0">
          <Popup
            trigger={(
              <p className="is-flex has-content-centered has-cursor-pointer dont-allow-selection navbar-dropdown-trigger">
                Lösungen
                <i className="fas fa-caret-down is-size-7 has-text-grey is-inline ml5" />
              </p>
            )}
            position="bottom center"
            on={['click']}
            className="popup-width-400"
            keepTooltipInside
          >
            {
              (close) => (
                <div className="py-4 px-2 has-fullwidth">
                  <p className="">Anwendungsmöglichkeiten</p>
                  <Link
                    className="navbar-item has-text-weight-bold has-text-grey is-block mt-3 mb-2"
                    to={routes.FLOWTER_INFO}
                    onClick={() => props.hide(close)}
                  >
                    <div className="is-flex align-items-center m">
                      <i className="fas fa-square-poll-vertical mr-3 is-size-6" />
                      <p className="is-inline is-size-7 has-text- has-text-weight-semibold has-text-black">
                        Umfragen durchführen
                      </p>
                    </div>
                  </Link>
                  <Link
                    className="navbar-item has-text-weight-bold has-text-grey is-flex align-items-center"
                    to={routes.HUB}
                    onClick={() => props.hide(close)}
                  >
                    <div className="is-flex align-items-center">
                      <i className="fas fa-address-book mr-3 is-size-6" />
                      <p className="is-inline is-size-7 has-text- has-text-weight-semibold has-text-black">
                        Kontaktformular erstellen
                      </p>
                    </div>
                  </Link>
                </div>
              )
            }
          </Popup>
        </div>
 */
export default NavigationBar;
