import { useSelector } from 'react-redux';
import React from 'react';
import './css/privacyAgreement.scss';

function PrivacyAgreement(props) {
  // acceptPrivacyAgreement() {
  // 	stats.push(['trackEvent', 'Account', 'Privacy Agreement Accept', 'userAcceptedPrivacyAgreement']);
  // 	this.props.goToNextPage();
  // }

  const { user } = useSelector((state) => state.sessionState);
  return (
    <PrivacyAgreementLayout name={user.name} email={user.email} />
  );
}

function PrivacyAgreementLayout(props) {
  return (
    <div className="columns is-centered is-marginless">
      <div className="column mt20 mb50 has-text-justified has-background-white br10 pl20 pr20 pt20 privacyAgreementWrapper">
        <h2 className="has-text-weight-bold mt20 mb20 has-text-black is-size-4 is-size-6-mobile">Rev 1 Auftragsverarbeitungs-Vertrag (AV-Vertrag)</h2>
        <p>gemäß Art. 28 Abs. 3 der EU-Datenschutz-Grundverordnung (EU-DSGVO)</p>
        <p className="mt20">Zwischen</p>
        <p className="has-text-centered has-text-weight-bold">{props.name}</p>
        <p className="has-text-centered has-text-weight-bold">{`Kundenkonto (E-Mail): ${props.email}`}</p>
        <p className="is-inline">{'- nachstehend '}</p>
        <p className="is-inline has-text-weight-bold">"Auftraggeber"</p>
        <p className="is-inline">{' genannt'}</p>
        <p className="mt20">und</p>
        <p className="has-text-centered has-text-weight-bold">Flowdust Online Services UG (haftungsbeschränkt)</p>
        <p className="has-text-centered has-text-weight-bold">Am Bahnhof 1</p>
        <p className="has-text-centered has-text-weight-bold">95632 Wunsiedel</p>
        <p className="is-inline">{'- nachstehend '}</p>
        <p className="is-inline has-text-weight-bold">"Auftragnehmer"</p>
        <p className="is-inline">{' genannt'}</p>
        <p className="mt20 mb20">zu</p>
        <p>allen aktuellen und künftigen Verträgen zwischen dem Auftraggeber und dem Auftragnehmer unter den o.g. Angaben.</p>
        <p className="is-inline">{'- nachfolgend '}</p>
        <p className="is-inline has-text-weight-bold">"Dienstleistungsvertrag"</p>
        <p className="is-inline">{' genannt'}</p>
        <h2 className="has-text-weight-bold mt20 mb20">Einleitung, Geltungsbereich, Definitionen</h2>
        <p>(1)	Dieser Vertrag regelt die Rechte und Pflichten von Auftraggeber und -nehmer (im Folgenden „Vertragsparteien“, „Parteien“ oder „Partei“ genannt) im Rahmen einer Verarbeitung von personenbezogenen Daten im Auftrag und ist ab der Erstellung eines Accounts gültig.</p>
        <p>(2)	Dieser Vertrag findet auf alle Tätigkeiten Anwendung, bei denen der Auftragnehmer, Mitarbeiter des Auftragnehmers oder durch ihn beauftragte Unterauftragnehmer (Subunternehmer) personenbezogene Daten des Auftraggebers verarbeiten.</p>
        <p>(3)	In diesem Vertrag verwendete Begriffe sind entsprechend ihrer Definition in der EU Datenschutz-Grundverordnung zu verstehen. Soweit Erklärungen im Folgenden „schriftlich“ zu erfolgen haben, ist die Schriftform nach § 126 BGB gemeint. Im Übrigen können Erklärungen auch in anderer Form erfolgen, soweit eine angemessene Nachweisbarkeit gewährleistet ist.</p>
        <p>(4)	Vertragssprache ist „deutsch“. Sollten deutsche und anderssprachige Fassungen dieses Vertrages abweichen, so gilt stets der deutsche Text als rechtsverbindlich vereinbart.</p>
        <h2 className="has-text-weight-bold mt20 mb20">{'1.	Dauer des Auftrags '}</h2>
        <p>Die Verarbeitung erfolgt auf unbestimmte Zeit bis zur Kündigung dieses Vertrags oder des Dienstleistungsvertrags durch eine Partei.</p>
        <p>Die Laufzeit dieses Vertrages richtet sich nach der Laufzeit des Dienstleistungsvertrages und endet automatisch, wenn der Dienstleistungsvertrag endet, sofern sich aus gesetzlichen Bestimmungen nicht darüberhinausgehende Verpflichtungen ergeben. Darüber hinaus kann dieser Vertrag von jeder Vertragspartei mit einer Frist von 1 Monat ordentlich gekündigt werden. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt. Eine Kündigung dieses Vertrages beinhaltet keine Kündigung des Dienstleistungsvertrages und bedingt diese auch nicht.</p>
        <h2 className="has-text-weight-bold mt20 mb20">2.	Art und Zweck der Datenerhebung, -verarbeitung oder -nutzung:</h2>
        <p>2.1. Aus dem Dienstleistungsvertrag (basierend auf den AGB, den Leistungsbeschreibungen, der Webseite sowie den Angeboten und Auftragsbestätigungen des Auftragnehmers) ergeben sich Gegenstand und Dauer des Auftrags sowie Art und Zweck der Verarbeitung. Konkret umfasst dies die Bereitstellung von Feedbacktools auf webbasierten Systemen.</p>
        <p>Im Rahmen dieser Dienstleistungen hat der Auftraggeber die Möglichkeit, selbständig mit Daten auf Systemen des Auftragnehmers umzugehen (speichern, verändern, löschen, übertragen, verarbeiten, berichtigen etc.).</p>
        <p>2.2. Explizit nicht Gegenstand dieses Vertrages und explizit nicht des Dienstleistungsvertrages ist eine originäre Nutzung oder Verarbeitung von personenbezogenen Daten durch den Auftragnehmer. Die Regelungen dieses Vertrages sind nötig, da nicht ausgeschlossen werden kann, dass der Auftragnehmer im Zuge der Leistungserbringung des Dienstleistungsvertrages mit Daten des Auftraggebers in Berührung kommen kann.</p>
        <p>2.3. Weiterhin werden die Daten zum Zweck der Leistungserbringung, in Angeboten, Rechnungen, Gutschriften, Lieferscheinen, Auftragsbestätigungen, Kundeninformationen und zur Beantwortung von Kundenanfragen z.B. per E-Mail verwendet.</p>
        <h2 className="has-text-weight-bold mt20 mb20">3.	Pflichten des Auftraggebers</h2>
        <p>3.1. Der Auftraggeber ist alleine für die Einhaltung der datenschutzrechtlichen Bestimmungen und Gesetze, insbesondere für die Rechtmäßigkeit der Datenweitergabe an den Auftragnehmer, für die Beurteilung der Zulässigkeit der Datenverarbeitungsvorgänge sowie für die Rechtmäßigkeit der Datenverarbeitung verantwortlich (Verantwortlicher im Sinne des Art. 4 Nr. 7 EU-DSGVO).</p>
        <p>3.2. Der Auftraggeber hat den Auftragnehmer unverzüglich und vollumfänglich zu informieren, wenn er Verstöße des Auftragnehmers gegen datenschutzrechtliche Bestimmungen oder Gesetze feststellt.</p>
        <p>3.3. Der Auftraggeber ist alleine gegenüber betroffenen Personen verantwortlich, mit dessen Daten er auf Systemen des Auftraggebers umgeht, insbesondere was das Recht auf Auskunft, Berichtigung, Löschung oder Sperrung angeht. Er ist alleine für die Wahrung der Rechte der betroffenen Personen verantwortlich.</p>
        <p>3.4. Der Auftraggeber ist alleine für alle Informationspflichten, insbesondere gegenüber betroffenen Personen, verantwortlich.</p>
        <p>3.5. Der Auftraggeber ist verpflichtet, alle im Rahmen dieses Vertrages erlangten Kenntnisse von Geschäftsgeheimnissen und Datensicherheitsmaßnahmen des Auftragnehmers u.ä. - auch über die Beendigung des Vertrages hinaus - vertraulich zu behandeln und in keinem Fall an Dritte weiterzugeben. Bestehen Zweifel, ob eine Information der Geheimhaltungspflicht unterliegt, ist sie bis zur schriftlichen Freigabe durch den Auftragnehmer als vertraulich zu behandeln.  Der Auftraggeber ist berechtigt, Dokumentationen für den Nachweis der Erfüllung seiner Sorgfaltspflichten und seiner gesetzlichen Verpflichtungen zu erstellen, soweit Geschäftsinteressen, Geheimhaltungsinteressen oder Sicherheitsinteressen des Auftragnehmers dem nicht entgegenstehen. Für etwaige Schäden sowie Folgeschäden aus der Verletzung der vorgenannten Geheimhaltungspflicht des Auftraggebers haftet dieser gegenüber dem Auftragnehmer zeitlich und finanziell unbegrenzt.</p>
        <h2 className="has-text-weight-bold mt20 mb20">4.	Pflichten des Auftragnehmers</h2>
        <p>4.1. Der Auftragnehmer darf die ihm vom Auftraggeber zur Verfügung gestellten personenbezogenen Daten von betroffenen Personen nur im Rahmen des Dienstleistungsvertrages und zur Erfüllung der darin beinhalteten Leistungen verwenden. Eine Verwendung für Zwecke außerhalb des Dienstleistungsvertrages findet nicht statt. Kopien oder Duplikate werden nur erstellt, wenn dies dem Charakter des Dienstleistungsvertrages und den darin beinhalteten Leistungen entspricht (z.B. Datensicherungen, temporäre Dateien, Log-Dateien etc.).</p>
        <p>4.2. Sofern eine gesetzliche Verpflichtung besteht, ist der Auftragnehmer zur Herstellung von Kopien oder zur Weitergabe von Daten des Auftraggebers ermächtigt.</p>
        <p>4.3. Die Datenverarbeitung durch den Auftragnehmer erfolgt ausschließlich im Gebiet der Bundesrepublik Deutschland, in einem Mitgliedsstaat der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum, sofern nichts Anderes vom Auftraggeber beauftragt wurde, und darf nur erfolgen, wenn die besonderen Voraussetzungen der Art. 44 ff. EU-DSGVO erfüllt sind.</p>
        <p>4.4. Der Auftragnehmer wird in seinem Verantwortungsbereich die innerbetriebliche Organisation so gestalten, dass sie den besonderen Anforderungen des Datenschutzes gerecht wird. Er wird dazu angemessene technische und organisatorische Maßnahmen (TOM) zum angemessenen Schutz der Daten des Auftraggebers treffen und aufrechterhalten, die den Anforderungen der Datenschutz-Grundverordnung (Art. 32 EU-DSGVO) genügen, insbesondere in Bezug auf die Vertraulichkeit und Integrität.</p>
        <p>Diese technischen und organisatorischen Maßnahmen (TOM) sind diesem Vertrag als Anlage beigefügt. Beide Vertragsparteien verständigen sich darauf, diese technischen und organisatorischen Maßnahmen als ausreichenden Nachweis für die Verpflichtungen, die sich aus diesem Vertrag ergeben, zu sehen. Die technischen und organisatorischen Maßnahmen sind dem Auftraggeber dadurch bekannt und er trägt die alleinige Verantwortung dafür, dass diese für die Risiken der zu verarbeitenden Daten ein angemessenes Schutzniveau bieten. Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der Weiterentwicklung. Eine Änderung der getroffenen Sicherheitsmaßnahmen bleibt dem Auftragnehmer vorbehalten, wobei jedoch sichergestellt sein muss, dass das vertraglich vereinbarte Schutzniveau nicht unterschritten wird.</p>
        <p>4.5. Der Auftragnehmer sorgt dafür, dass es den mit der Verarbeitung der Daten des Auftraggebers befassten Mitarbeitern und anderen für den Auftragnehmer tätigen Personen untersagt ist, die Daten außerhalb dieses Vertrages und des Dienstleistungsvertrages zu verarbeiten. Ferner sorgt der Auftragnehmer dafür, dass sich die zur Verarbeitung der personenbezogenen Daten befugten Personen zur Vertraulichkeit verpflichtet haben oder einer angemessenen gesetzlichen Verschwiegenheitspflicht unterliegen. Diese Verpflichtung besteht auch nach Beendigung dieses Vertrages und des Dienstleistungsvertrages fort, sofern dies aus dem Charakter des Dienstleistungsvertrages hervorgeht.</p>
        <p>4.6. Der Auftragnehmer unterrichtet den Auftraggeber, wenn ihm Verletzungen des Schutzes personenbezogener Daten des Auftraggebers, welche durch den Auftragnehmer verursacht wurden, bekannt werden.</p>
        <p>4.7. Sollten die Daten des Auftraggebers beim Auftragnehmer durch Pfändung oder Beschlagnahmung oder durch ein Insolvenz- oder Vergleichsverfahren gefährdet werden, so hat der Auftragnehmer den Auftraggeber darüber zu informieren. Der Auftragnehmer wird alle in diesem Zusammenhang Verantwortlichen darüber informieren, dass die Hoheit und das Eigentum an den Daten ausschließlich beim Auftraggeber als Verantwortlicher im Sinne der EU-DSGVO liegen.</p>
        <p>4.8. Der Auftragnehmer ist gesetzlich nicht zur Bestellung eines externen Datenschutzbeauftragten verpflichtet. Als Ansprechpartner beim Auftragnehmer dient der jeweils auf der Webseite des Auftragnehmers angegebene, freiwillig bestellte, interne Datenschutzbeauftragte.</p>
        <h2 className="has-text-weight-bold mt20 mb20">5.	Anfragen betroffener Personen</h2>
        <p>5.1. Wendet sich eine betroffene Person an den Auftragnehmer, wird der Auftragnehmer die betroffene Person an den Auftraggeber verweisen, sofern dies nach Angaben der betroffenen Person möglich ist. Der Auftragnehmer wird derartige Anfragen nicht bearbeiten, nicht beantworten und insbesondere keine Änderungen etc. an Daten vornehmen oder Auskünfte erteilen. Der Auftragnehmer haftet nicht, wenn das Ersuchen der betroffenen Person vom Auftraggeber nicht, nicht richtig oder nicht fristgerecht beantwortet wird.</p>
        <p>5.2. Der Auftragnehmer unterstützt, soweit vereinbart und zumutbar und soweit dies dem Charakter des Dienstleistungsvertrages und den darin beinhalteten Leistungen entspricht, den Auftraggeber im Rahmen seiner technischen und betrieblichen Möglichkeiten bei der Erfüllung der Anfragen und Ansprüche betroffenen Personen gemäß Kapitel III der EU-DSGVO sowie bei der Einhaltung der in Art. 33 bis 36 EU-DSGVO genannten Pflichten.</p>
        <h2 className="has-text-weight-bold mt20 mb20">6.	Umgang mit Daten</h2>
        <p>6.1. Der Auftraggeber hat im Rahmen der Leistungen des Dienstleistungsvertrages jederzeit selbst und vollumfänglich Zugriff auf seine Daten, so dass eine Mitwirkung des Auftragnehmers zum Umgang mit Daten nicht nötig ist und daher nicht stattfindet.</p>
        <p>6.2. Erteilt der Auftraggeber dem Auftragnehmer Einzelweisungen, die über den vertraglich vereinbarten Leistungsumfang des Dienstleistungsvertrages hinausgehen, müssen diese schriftlich erfolgen. Die die dadurch begründeten Kosten/Aufwendungen sind vom Auftraggeber zu tragen. Die Durchführung solcher Einzelanweisungen erfolgt nur nach vorheriger Bestätigung durch den Auftragnehmer. Der Auftragnehmer kann und darf solche Einzelanweisungen – auch generell – ablehnen oder aussetzen, insbesondere, wenn er der Auffassung ist, dass eine Weisung gegen anwendbare Gesetze verstößt.</p>
        <p>6.3. Da es im Rahmen des Dienstleistungsvertrages nicht zu einem Datenträgeraustausch kommt, entfällt die Notwendigkeit zur Regelung einer Rückgabe oder Löschung. Für die Löschung von Daten nach Ende des Dienstleistungsvertrages ist der Auftraggeber gemäß Absatz 1 und gemäß dem Charakter des Dienstleistungsvertrages und der darin beinhalteten Leistungen in der Lage und alleine verantwortlich. Evtl. entstandene Datensicherungen etc. gemäß dem Charakter der im Dienstleistungsvertrag beinhalteten Leistungen werden gemäß den technischen und organisatorischen Maßnahmen innerhalb angemessener Frist durch den Auftragnehmer gelöscht.</p>
        <h2 className="has-text-weight-bold mt20 mb20">7.	Art der personenbezogenen Daten, betroffene Personen</h2>
        <p>7.1.  Die Daten, mit denen der Auftraggeber umgeht, können nach Art. 4 Nr. 1, 13, 14 und 15 DSGVO mehrere oder alle Arten von Daten wie nachfolgend beschrieben oder darüber hinaus enthalten: Personenstammdaten, IP-Adresse(n), Kommunikationsdaten, Vertragsstammdaten, Kundenhistorien, Vertrags- und Zahlungsdaten, Auskunftsangaben, Bestell- und Angebotsdaten, Bild- und Videodaten, Personaldaten, Transaktionsdaten, Bank- oder Kreditkartendaten, sonstige Daten etc.</p>
        <p>7.2. Der Kreis der betroffenen Personen der Daten, mit denen der Auftraggeber umgeht, kann nach Art. 4 Nr. 1 EU-DSGVO mehrere oder alle oder darüber hinaus gehende Kreise wie nachfolgend beschrieben, umfassen: Kunden, Interessenten, Abonnenten, Mitglieder, Beschäftigte/Mitarbeiter, Lieferanten, Dienstleister, Vermittler, Geschäftspartner, sonstige betroffene Personen etc.</p>
        <h2 className="has-text-weight-bold mt20 mb20">8.	Kontrollen</h2>
        <p>8.1. Sollten im Einzelfall Inspektionen durch den Auftraggeber oder einen von diesem beauftragten Prüfer erforderlich sein, werden diese zu den üblichen Geschäftszeiten ohne Störung des Betriebsablaufs nach Anmeldung unter Berücksichtigung einer angemessenen Vorlaufzeit (mindestens jedoch 6 Wochen) durchgeführt. Der Auftragnehmer darf diese von der vorherigen Anmeldung mit der angegebenen Vorlaufzeit und von der Unterzeichnung einer Verschwiegenheitserklärung, von betrieblichen Bedürfnissen und den technischen und organisatorischen Maßnahmen abhängig machen. Sollte der durch den Auftraggeber beauftragte Prüfer in einem Wettbewerbsverhältnis zum Auftragnehmer stehen, hat der Auftragnehmer gegen diesen ein Einspruchsrecht. Während der Prüfung wird für jede anwesende Person im Auftrag des Auftraggebers ein Mitarbeiter des Auftragnehmers anwesend sein. Als Vergütungsbasis für den Aufwand, der dem Auftragnehmer durch eine Inspektion entsteht, verständigen sich beide Vertragsparteien auf eine Abrechnung in ganzen Arbeitstagen zu jeweils 8 Arbeitsstunden je anwesendem Mitarbeiter des Auftragnehmers.</p>
        <p>8.2. Sollte eine Datenschutzaufsichtsbehörde oder eine sonstige hoheitliche Aufsichtsbehörde des Auftraggebers eine Inspektion vornehmen, gilt grundsätzlich Absatz 1, auch in Bezug auf die Regelungen zum Aufwand, entsprechend. Eine Unterzeichnung einer Verschwiegenheitsverpflichtung ist nicht erforderlich, wenn diese Aufsichtsbehörde einer berufsrechtlichen oder gesetzlichen Verschwiegenheit unterliegt, bei der ein Verstoß nach dem Strafgesetzbuch strafbewehrt ist.</p>
        <h2 className="has-text-weight-bold mt20 mb20">9.	Einsatz von Subunternehmen (Untervertragsverhältnisse)</h2>
        <p>9.1. Der Einsatz von Subunternehmern als weiteren Auftrags Verarbeiter zur Erbringung der Leistungen des Dienstleistungsvertrages durch den Auftragnehmer ist grundsätzlich und ohne zusätzliches Einverständnis des Auftraggebers zulässig. Eine Information gegenüber dem Auftraggeber erfolgt nicht. Der Auftraggeber hat kein Widerspruchs- oder Einspruchsrecht.  Der Auftraggeber ist mit dem Einsatz von Subunternehmern einverstanden.</p>
        <p>9.2. Erteilt der Auftragnehmer Aufträge an Subunternehmer, so obliegt es dem Auftragnehmer, seine datenschutzrechtlichen Pflichten aus diesem Vertrag dem Subunternehmer zu übertragen. Der Auftragnehmer wird dazu mit diesen Subunternehmern in erforderlichen Umfang Vereinbarungen treffen, um angemessene Datenschutz- und Informationssicherheitsmaßnahmen zu gewährleisten.</p>
        <p>9.3. Die vom Auftragnehmer beauftragten Subunternehmer werden in der Regel aus wettbewerbsrechtlichen und betrieblichen Gründen nicht mitgeteilt.</p>
        <h2 className="has-text-weight-bold mt20 mb20">10.	 Haftung, Schadensersatz, Vertragsstrafe</h2>
        <p>10.1. Eine Haftung des Auftragnehmers gegenüber dem Auftraggeber oder gegenüber betroffenen Personen für Schäden, die sich aus Verletzungen seiner Pflichten aus diesem Vertrag ergeben, wird vollumfänglich ausgeschlossen.</p>
        <p>10.2. Der Auftraggeber stellt den Auftragnehmer dauerhaft, auch über das Ende dieses Vertrages hinaus, von allen Ansprüchen, die sich u.a. aus Art. 82 EU-DSGVO ergeben frei. Auch in Fällen, in denen der Schaden durch eine Verletzung der Pflichten durch den Auftragnehmer entstanden ist.</p>
        <p>10.3. Eine Vertragsstrafe wird nicht vereinbart und ausgeschlossen.</p>
        <p>10.4. Für den Ersatz von Schäden, die eine betroffene Person wegen einer nach der EU-DSGVO oder anderen Vorschriften für den Datenschutz unzulässigen oder unrichtigen Datenverarbeitung im Rahmen des Auftragsverhältnisses erleidet, ist der Auftraggeber gegenüber der betroffenen Person alleine verantwortlich.</p>
        <h2 className="has-text-weight-bold mt20 mb20">11. Kostenregelung</h2>
        <p>11.1. Der Auftraggeber ersetzt dem Auftragnehmer alle mit diesem Vertrag direkt oder indirekt in Zusammenhang stehenden Aufwände und Kosten, auch solchen, die sich aus gesetzlichen Verpflichtungen oder aus diesem Vertrag ergeben, abgerechnet in ganzen Stunden. Ausgenommen hiervon sind Inspektionen, welche gemäß Pkt. 8 abgerechnet werden.</p>
        <p>11.2. Es gelten die regulären Stundensätze des Auftragnehmers.</p>
        <h2 className="has-text-weight-bold mt20 mb20">12. Schlussbestimmungen</h2>
        <p>12.1. Änderungen und Ergänzungen dieses Vertrages und aller seiner Bestandteile bedürfen der Schriftform. Dies gilt auch für den Verzicht auf diese Formerfordernisse.</p>
        <p>12.2. Soweit rechtlich zulässig, wird als Gerichtsstand 95632 Wunsiedel vereinbart. Es gilt ausschließlich deutsches Recht.</p>
        <p>12.3. Bei etwaigen Widersprüchen gehen Regelungen dieses Vertrages den Regelungen des Dienstleistungsvertrages vor.</p>
        <p>12.4. Es gelten die Regelungen der Allgemeinen Geschäftsbedingungen (AGB) sowie die Leistungsbeschreibungen des Auftragnehmers.</p>
        <h2 className="has-text-weight-bold mt20 mb20">13. Salvatorische Klausel</h2>
        <p>13.1. Sollte eine Bestimmung des Vertrages ungültig oder unwirksam sein oder werden oder sollte der Vertrag unvollständig oder undurchsetzbar sein, so wird der Vertrag in seinem übrigen Inhalt davon nicht berührt. Die unwirksame Bestimmung gilt durch eine solche Bestimmung ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für etwaige Vertragslücken.</p>
        <h2 className="has-text-weight-bold is-size-5 mt20 mb20">{'Technische und organisatorische Maßnahmen (TOM) '}</h2>
        <h2 className="has-text-weight-bold mt20 mb20">1. Vertraulichkeit</h2>
        <h2 className="has-text-weight-bold">1.1 Zutrittskontrolle</h2>
        <p>Maßnahmen, die geeignet sind, Unbefugten den Zutritt zu Datenverarbeitungsanlagen, mit denen personenbezogene Daten verarbeitet oder genutzt werden, zu verwehren.</p>
        <p className="mt20">- Videoüberwachung der Zugänge</p>
        <p />
        <p>- Restriktive Besucherkontrolle</p>
        <h2 className="mt20 has-text-weight-bold">1.2 Zugangskontrolle</h2>
        <p>Maßnahmen, die geeignet sind zu verhindern, dass Datenverarbeitungssysteme von Unbefugten genutzt werden können.</p>
        <p className="mt20">- Authentifikation für Mitarbeiter nur mit Benutzername + Passwort</p>
        <p>- Strenge Passwortregeln für Mitarbeiter</p>
        <p>- Umfassende Protokollierung</p>
        <p>- Einsatz von Anti-Viren-Software</p>
        <p>- Einsatz von Firewalls und IDS</p>
        <p>- Einsatz von Verschlüsselung und VPN-Technologien</p>
        <p>- Getrennte Netzbereiche für Administrations- und Wartungszwecke</p>
        <h2 className="has-text-weight-bold mt20">1.3 Zugriffskontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass die zur Benutzung eines Datenverarbeitungssystems Berechtigten ausschließlich auf die ihrer Zugriffsberechtigung unterliegenden Daten zugreifen können, und dass personenbezogene Daten bei der Verarbeitung, Nutzung und nach der Speicherung nicht unbefugt gelesen, kopiert, verändert oder entfernt werden können.</p>
        <p className="mt20">- Restriktive Berechtigungsregelungen</p>
        <p>- Umfassende Protokollierung</p>
        <p>- Einsatz von Aktenvernichtern</p>
        <p>- Sichere Aufbewahrung von Datenträgern</p>
        <p>- Ordnungsgemäße Vernichtung von Datenträgern</p>
        <p>- Sichere Löschung von Datenträgern vor Wiederverwendung</p>
        <p>- Zugriffskontrolle auf Kundenkonten über eine verschlüsselte Verbindung</p>
        <p>- Login mittels Benutzername und Passwort. Letzteres ist dem Auftragnehmer nicht bekannt. Das Login wird bei Unregelmäßigkeiten durch das System gesperrt</p>
        <h2 className="has-text-weight-bold mt20">1.4 Trennungskontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass zu unterschiedlichen Zwecken erhobene Daten getrennt verarbeitet werden.</p>
        <p className="mt20">- Firmen-Daten von Kunden-Daten strikt getrennt</p>
        <p>- Trennung von Produktiv- und Testsystemen</p>
        <h2 className="has-text-weight-bold mt20">1.5 Pseudonymisierung</h2>
        <p>Maßnahmen, die sicherstellen, dass Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr oder schwer einer spezifischen betroffenen Person zugeordnet werden können.</p>
        <p className="mt20">- Kurze Vorhaltezeiten von Log-Dateien</p>
        <p>- Reduktion von Log-Dateien auf das Nötigste</p>
        <p>- Pseudonmysierung von IP-Adressen</p>
        <h2 className="has-text-weight-bold mt20">1.6 Löschung von Daten</h2>
        <p className="mt20">- Löschung von Daten nach Beendigung des Vertragsverhältnisses</p>
        <p>- Turnusmäßige Löschung von Daten, die gemäß AGB als zu löschen definiert sind</p>
        <h2 className="has-text-weight-bold mt20 mb20">2. Integrität</h2>
        <h2 className="has-text-weight-bold mt20">2.1 Eingabekontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass nachträglich überprüft und festgestellt werden kann, ob und von wem personenbezogene Daten in Datenverarbeitungssysteme eingegeben, verändert oder entfernt worden sind.</p>
        <p className="mt20">- Berechtigungssystem zur sowie Protokollierung der Eingabe, Änderung und Löschung von Daten</p>
        <h2 className="has-text-weight-bold mt20">2.2 Weitergabekontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass personenbezogene Daten bei der elektronischen Übertragung oder während ihres Transports oder ihrer Speicherung auf Datenträger nicht unbefugt gelesen, kopiert, verändert oder entfernt werden können, und dass überprüft und festgestellt werden kann, an welche Stellen eine Übermittlung personenbezogener Daten durch Einrichtungen zur Datenübertragung vorgesehen ist.</p>
        <p className="mt20">- Einsatz von Verschlüsselung und VPN-Technologien</p>
        <p>- Authentifizierung</p>
        <h2 className="has-text-weight-bold mt20 mb20">3. Verfügbarkeit und Belastbarkeit</h2>
        <h2 className="has-text-weight-bold mt20">3.1 Verfügbarkeitskontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass personenbezogene Daten gegen zufällige Zerstörung oder Verlust geschützt sind.</p>
        <p className="mt20">- Unterbrechungsfreie Stromversorgung (USV)</p>
        <p>- Feuer- und Rauchmeldeanlage</p>
        <p>- Geräte zur Überwachung von Temperatur in Serverräumen</p>
        <p>- Alarmmeldung bei unberechtigten Zutritten zu Serverräumen</p>
        <p>- Backup- und Recovery-Strategie</p>
        <p>- Notfallpläne</p>
        <p>- Serverräume über der Wassergrenze</p>
        <p>- Automatische Überwachungssysteme (Monitoring) kritischer Systeme</p>
        <h2 className="has-text-weight-bold mt20">4. Verfahren zur regelmäßigen Überprüfung, Bewertung und Evaluierung</h2>
        <h2 className="has-text-weight-bold mt20">4.1 Auftragskontrolle</h2>
        <p>Maßnahmen, die gewährleisten, dass personenbezogene Daten, die im Auftrag verarbeitet werden, nur entsprechend den Weisungen des Auftraggebers verarbeitet werden können.</p>
        <p className="mt20">- Verpflichtung der Mitarbeiter und Subunternehmern auf die EU-DSGVO</p>
        <p>- Maßnahmen gemäß § 1, 2 und 3 der TOM sowie des AV-Vertrages</p>
        <p>- Verarbeitung ausschließlich in der Bundesrepublik Deutschland, einem Mitgliedsstaat der Europäischen Union oder einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum</p>
        <h2 className="has-text-weight-bold mt20">4.2 Datenschutz-Management</h2>
        <p>Maßnahmen, die gewährleisten, dass der Datenschutz fortlaufend sichergestellt ist.</p>
        <p className="mt20">- Implementierung eines Datenschutz-Managements</p>
        <h2 className="has-text-weight-bold mt20">4.3 Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen</h2>
        <p>Maßnahmen, die gewährleisten, dass der Datenschutz oberste Priorität in allen Belangen hat.</p>
        <p className="mt20">- Reduktion der Pflichtfelder in Eingabeformularen auf das Nötigste</p>
      </div>
    </div>
  );
}

export default PrivacyAgreement;
