import React from 'react';

const BrowserWindow = (props) => (
  <div className="browser-window boxshadow">
    <div className="top-bar">
      <div className="columns has-fullheight is-marginless is-paddingless is-mobile">
        <div className="column is-4 is-paddingless has-text-left">
          <div className="circles">
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
          </div>
        </div>
        <div className="column is-4 is-paddingless">
          <div className="searchBar" />
        </div>
        <div className="column is-4 is-paddingless" />
      </div>
    </div>
    <img
      src={props.img}
      alt=""
      className="has-border has-border-radius-bottom-light is-block has-fullwidth has-fullheight"
    />
  </div>
);

export default BrowserWindow;
