import React from 'react';
import PropTypes from 'prop-types';
import loader from 'assets/images/loading.svg';

const LoadingLayout = (props) => {
  const { error, screenHeight } = props;
  return (
    <div className={`hero ${screenHeight ? 'is-fullheight' : 'has-fullheight'}`}>
      <div className="hero-body">
        <div className="container has-text-centered">
          {
              error ? (
                <p className="has-text-centered has-text-danger has-text-weight-bold is-size-4-desktop is-size-5-touch">
                  {error}
                </p>
              )
                : (
                  <>
                    <img className="has-text-centered" src={loader} alt="" />
                    <p className="is-size-7">Laden...</p>
                  </>
                )
          }
        </div>
      </div>
    </div>
  );
};

LoadingLayout.propTypes = {
  error: PropTypes.string,
  screenHeight: PropTypes.bool,
};

LoadingLayout.defaultProps = {
  screenHeight: true,
  error: '',
};

export default LoadingLayout;
