import React, { useState } from 'react';

// Libraries
import { isEmail } from 'validator';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import TextInput from '../../TextInput';

function EmailQuestion(props) {
  const { saveAnswer } = props;

  const { t } = useTranslation(['errors']);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const { colors } = useSelector((state) => state.hubs.creator);

  const _setEmail = (_email) => {
    setError('');

    setEmail(_email);
  };

  const isEmailValid = () => {
    const isValid = isEmail(email, {
      domain_specific_validation: true,
      ignore_max_length: false,
    });

    if (!isValid) {
      setError(`${t('errors:enter_valid_email_error')}!`);
      return false;
    }

    if (!hasAcceptedTerms) {
      setError(`${t('errors:accept_email_notifications_error')}!`);
      return false;
    }

    return true;
  };

  const saveEmail = () => {
    if (!isEmailValid()) return;

    saveAnswer([-1], email);
  };

  const toggleTerms = () => {
    // reset error if terms get accepted
    if (hasAcceptedTerms === false) {
      setError('');
    } else {
      setError(`${t('errors:accept_email_notifications_error')}!`);
    }

    setHasAcceptedTerms(!hasAcceptedTerms);
  };

  return (
    <div className="">
      <TextInput
        text={email}
        setText={_setEmail}
        goNext={saveEmail}
        color={colors[0]}
        placeholder="E-Mail"
        icon="envelope"
      />

      <div className="field mt-4">
        <input
          type="checkbox"
          id="terms"
          checked={hasAcceptedTerms}
          className="is-checkradio is-black"
          onChange={toggleTerms}
        />

        <label
          className="has-text-grey-dark is-size-7"
          htmlFor="terms"
        >
          Ich bestätige, dass ich unter der angegebenen E-Mail benachrichtigt werden darf
        </label>
      </div>

      <p className="has-text-weight-bold is-size-7-mobile mt-3">
        { error }
      </p>
    </div>
  );
}

EmailQuestion.propTypes = {
  saveAnswer: PropTypes.func.isRequired,
};

export default EmailQuestion;
