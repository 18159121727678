import React, { useMemo } from 'react';

// Libraries
import QRCodeStyling from 'qr-code-styling';
import { sanitizeUrl } from '@braintree/sanitize-url';
import getHubUrl from 'utils/getHubUrl';

import { useSelector } from 'react-redux';

const QRButton = () => {
  const { creator } = useSelector((state) => state.hubs);
  const { url, profilePic } = creator;

  const imgUrl = profilePic ? `https://cdn.flowdust.com/hub/images/profile/${profilePic}.png` : '';

  const sanitizedURL = useMemo(() => {
    const _url = sanitizeUrl(url);
    return getHubUrl(_url);
  }, [url]);

  const generateQRCode = () => {
    const options = {
      width: 300,
      height: 300,
      type: 'png',
      data: sanitizedURL,
      image: imgUrl,
      margin: 10,
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 10,
      },
      dotsOptions: {
        color: '#222222',
        type: 'dots',
      },
      backgroundOptions: {
        color: '#ffffff',
      },
      cornersSquareOptions: {
        color: '#222222',
        type: 'extra-rounded',
      },
      cornersDotOptions: {
        color: '#222222',
        type: 'square',
      },
    };

    const qrCode = new QRCodeStyling(options);
    qrCode.download({ name: 'QR', extension: 'png' });
  };

  return (
    <button
      type="button"
      onClick={generateQRCode}
      className="cleanButton has-hover is-flex has-content-centered py-1 px-3 br10 m0 is-size-5 has-fullheight"
    >
      <i className="fas fa-qrcode has-text-black mr-2" />
      <p
        className="is-size-7 is-inline-block has-text-black has-text-weight-semibold"
      >
        QR-Code herunterladen
      </p>

    </button>
  );
};

export default QRButton;
