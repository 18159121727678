import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import QuestionSelector from 'components/hub/HubContentEditor/LogicEditor/SimpleLogicEditor/Logic/QuestionSelector';
import PollConditionSelector from './PollConditionSelector';
import EmailConditionSelector from './EmailConditionSelector';
import SliderConditionSelector from './SliderConditionSelector';

/**
 * One condition for one logic object
 * The conditions change for each type of the question the condition should be applied to
 * e.g: POLL has different conditions as SLIDER
 */
const Condition = (props) => {
  const { condition, setCondition } = props;

  const {
    question,
  } = condition;

  const { questions } = useSelector((state) => state.hubs.creator.content);

  const setConditionQuestion = (newQuestion) => {
    if (question === newQuestion) return;

    const { isOptional, allowText } = questions[newQuestion];

    if (allowText) {
      setCondition({
        question: newQuestion,
        type: 'ANSWER',
        sign: 'EQ',
        value: '-1',
      });

      return;
    }

    if (isOptional) {
      setCondition({
        question: newQuestion,
        type: 'ANSWER',
        sign: 'EQ',
        value: '-2',
      });

      return;
    }

    setCondition({
      question: newQuestion,
      type: 'ANSWER',
      sign: 'EQ',
      value: '0',
    });
  };

  const showConditionSelectors = () => {
    const questionObj = questions[question];
    const type = questionObj.type || '';

    switch (type) {
      case 'POLL':
        return <PollConditionSelector condition={condition} setCondition={setCondition} />;
      case 'EMAIL':
        return <EmailConditionSelector condition={condition} setCondition={setCondition} />;
      case 'SLIDER':
        return <SliderConditionSelector condition={condition} setCondition={setCondition} />;

      default: return null;
    }
  };

  return (
    <div className="is-size-7">
      <QuestionSelector
        index={question}
        setQuestion={setConditionQuestion}
      />
      {showConditionSelectors()}
    </div>
  );
};

Condition.propTypes = {
  /** One condition object
    * @example: Condition: For Question at index 1, answer should be User Input
    * question would be 1, type would be "answer", sign "eq" and value -1
    */
  condition: PropTypes.shape({
    /* Index of the question the condition should be applied to */
    question: PropTypes.number,
    /* Type of the condition. Values: ['duration', 'answer', 'value'] */
    type: PropTypes.string,
    /*
     * Sign of the type for the condition. Values: ['gt', 'lt', 'eq', 'gte', 'lte']
     * Used to determine the range of the value
     */
    sign: PropTypes.string,
    /* Value of the condition */
    value: PropTypes.string,
  }).isRequired,
  setCondition: PropTypes.func.isRequired,
};

export default Condition;
