import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import stats from 'analytics/analytics';

function PrivacyAgreementDownload() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [signal, setSignal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently, user: auth0User } = useAuth0();

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  const downloadPDF = async () => {
    setDisabled(true);
    setError('');

    try {
      const signal = axios.CancelToken.source();
      setSignal(signal);

      const authToken = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
        responseType: 'blob',
      };

      const data = {
        email: auth0User.email,
        name: auth0User.nickname,
      };

      const doc = await axios.post(`${process.env.REACT_APP_API}/users/privacy/agreement`, data, config);

      stats.push(['trackEvent', 'Account', 'Privacy Agreement Download']);

      // download pdf
      const blob = new Blob([doc.data], { type: doc.data.type });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;

      link.setAttribute('download', 'Flowdust Auftragsverarbeitungsvertrag.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);

      setDisabled(false);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      console.log(e);

      setDisabled(false);
      setError('Leider Es gab ein Problem. Der Server konnte die Anfrage nicht bearbeiten. Bitte versuche es später erneut.');
    }
  };

  return (
    <PrivacyLayout
      disabled={disabled}
      downloadPDF={downloadPDF}
      error={error}
    />
  );
}

function PrivacyLayout(props) {
  return (
    <div className="columns is-centered is-mobile">
      <div className="column pb50 is-9-desktop is-10-tablet is-11-mobile has-fullheight mt30">
        <h2 className="mt20 has-text-weight-bold has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile">
          Auftragsverarbeitungs-Vertrag
        </h2>
        <p className="mb30 mt10 is-size-6 has-text-grey">
          Hier ist dein bestätigter Datenverarbeitungsvertrag. Klicke auf den Download Button, um die Datei herunterzuladen.
        </p>
        <button
          className="button is-rounded has-background-blue has-text-weight-bold has-text-white piwik_download"
          onClick={(e) => props.downloadPDF(e)}
          disabled={props.disabled}
        >
          Download
        </button>
        <p className="mt20 has-text-danger has-text-weight-bold">{props.error}</p>
      </div>
    </div>
  );
}

export default PrivacyAgreementDownload;
