import React from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  deleteQuestion, setMaxAmountChecks, setOptional,
} from 'reducer/hubs';

// Components
import TypeEditor from './TypeEditor';
import EmailQuestion from './EmailQuestion';
import SliderQuestion from './SliderQuestion';
import PollQuestion from './PollQuestion';
import IsOptionalEditor from './IsOptionalEditor';

// Constants
import {
  HUB_MAX_QUESTIONS,
} from 'constants/hub';
import MaxAmountChecksEditor from './MaxAmountChecksEditor';

function Question(props) {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const { id } = props;

  const question = questions[id];

  const {
    isOptional, type, maxAmountChecks, options,
  } = question;

  const _setOptional = (_isOptional) => {
    dispatch(setOptional({ id, isOptional: _isOptional }));
  };

  const _setMaxAmountChecks = (_maxAmountChecks) => {
    dispatch(setMaxAmountChecks({ id, maxAmountChecks: _maxAmountChecks }));
  };

  const _deleteQuestion = (e) => {
    e.preventDefault();

    stats.push(['trackEvent', 'Hub Content Editor', 'Question removed']);

    dispatch(deleteQuestion(id));
  };

  const showQuestion = () => {
    const renderQuestion = () => {
      switch (type) {
        case 'EMAIL': return <EmailQuestion id={id} />;
        case 'SLIDER': return <SliderQuestion id={id} />;
        case 'POLL': return <PollQuestion id={id} />;
        // TODO: change this to return null (update db first)
        default: return <PollQuestion id={id} />;
      }
    };

    return (
      <div>
        <div className="mb-4">
          <p
            className="is-size-7 has-text-grey"
          >
            Typ
          </p>
          <TypeEditor id={id} />
        </div>
        { renderQuestion() }
        <div className="mt-3">
          <IsOptionalEditor isOptional={isOptional} setOptional={_setOptional} id={id} />
          {
            type === 'POLL' && (
              <MaxAmountChecksEditor
                amountOptions={options.length}
                maxAmountChecks={maxAmountChecks}
                setMaxAmountChecks={_setMaxAmountChecks}
              />
            )
          }
        </div>

      </div>
    );
  };

  const isNotLastQuestion = questions.length > 1;

  return (
    <div className="questionWrapper pb30">
      <div className="columns mb-0">
        <div className="column is-narrow">
          <p className="has-text-grey pl5 has-text-weight-semibold has-text-centered-mobile is-size-7">
            {`Frage ${id + 1} / ${HUB_MAX_QUESTIONS}`}
          </p>
          {
            isNotLastQuestion
            && (
            <div className="has-text-left">
              <button
                className="cleanButton is-inline has-text-weight-bold has-text-red is-size-7 mt10 is-underlined"
                onClick={(e) => _deleteQuestion(e)}
                type="button"
              >
                Frage entfernen
              </button>
            </div>
            )
          }
        </div>
      </div>
      {showQuestion()}
    </div>
  );
}

Question.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Question;
