import React, { useState, useEffect } from 'react';

// Libraries

import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import stats from 'analytics/analytics';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import CustomerInfoCollector from 'components/checkout/CustomerInfoCollector';

function CustomerInformation() {
  const [signal] = useState(axios.CancelToken.source());
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const onSetCustomer = (customer) => dispatch({ type: 'SET_CUSTOMER', customer });

  const { getAccessTokenSilently } = useAuth0();

  const { user } = useSelector((state) => state.sessionState);

  useEffect(
    () => () => {
      signal.cancel();
    }
    // eslint-disable-next-line
	, [])

  const saveChanges = async (customer) => {
    setDisabled(true);

    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      await axios.patch(`${process.env.REACT_APP_API}/users/address`, customer, config);

      onSetCustomer(customer);

      setDisabled(false);
      setError('Die neuen Kontaktinformationen wurden erfolgreich gespeichert.');

      stats.push(['trackEvent', 'Customer Information', 'Information updated']);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err);

      setDisabled(false);

      console.log(err.response);

      if (!err.response) {
        setError('Es ist etwas schief gelaufen. Die neuen Kontaktinformationen konnten nicht gespeichert werden.');
        return;
      }

      if (err.response.status === 500) {
        setError('Server Fehler. Bitte versuche es später noch einmal.');
      } else {
        setError(err.response.data);
      }
    }
  };

  if (!user || !user.customer) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Kontaktinformationen
        </title>
      </Helmet>
      <div className="columns is-centered mb50">
        <div className="column is-9 p0">
          <p className="mt30 mb30 has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-centered" id="contactInfo">
            Kontaktinformationen
          </p>
          <div className="box boxshadow pl40 pr40">
            <CustomerInfoCollector next={saveChanges} disabled={disabled} />
            <p className="has-text-centered has-text-weight-bold mb30 has-text-black">
              {error}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerInformation;
