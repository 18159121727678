import React, { useState } from 'react';

// Library
import stats from 'analytics/analytics';
import notification from 'utils/notification';
import { isURL } from 'validator';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Constants
import { HUB_LIST } from 'constants/routes';

// Actions
import { addHub, updateHub } from 'reducer/hubs';

const URL_OPTIONS = {
  require_protocol: false,
  allow_underscores: true,
  disallow_auth: true,
  allow_fragments: false,
};

function SaveButton() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { post, patch } = useAPI();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { hubs, creator } = useSelector((state) => state.hubs);

  const {
    name, description, colors, url, socials, language, privacyUrl, editIndex,
  } = creator;

  const isInputValid = () => {
    if (privacyUrl && !isURL(privacyUrl, URL_OPTIONS)) {
      setError('Bitte gib eine gültige Datenschutz URL an.');
      return false;
    }

    return true;
  };

  const saveHub = async () => {
    try {
      if (!isInputValid()) return;

      setError('');
      setDisabled(true);

      const data = {
        name,
        description,
        language,
        colors,
        url,
        socials,
        privacyUrl,
      };

      if (editIndex !== -1) {
        const id = hubs[editIndex]._id;
        const hub = await patch(`/hubs/${id}`, data);

        dispatch(updateHub({ hub, id: editIndex }));

        stats.push(['trackEvent', 'Hub Creator', 'Hub updated']);

        notification.send('Erledigt!', 'Dein Hub wurde bearbeitet.', 'success');

        window.scrollTo(0, 0);

        // navigate(HUB_LIST);
        setDisabled(false);
        return;
      }

      const hub = await post('/hubs/', data);
      dispatch(addHub(hub));
      stats.push(['trackEvent', 'Hub Creator', 'Hub created']);

      notification.send('Erledigt!', 'Dein neuer Hub wurde erstellt.', 'success');

      navigate(HUB_LIST);
    } catch (e) {
      console.log(e);
      setDisabled(false);

      if (!e.response.data) {
        setError('Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.');
        return;
      }

      setError(e.response.data);
    }
  };

  return (
    <div
      className=""
    >
      <button
        type="button"
        disabled={disabled}
        onClick={saveHub}
        className="button glow grow br10 is-primary has-text-weight-bold"
        style={{ backgroundColor: colors[0] }}
      >
        Speichern
      </button>
      <p className="has-text-black has-text-weight-bold has-text-centered mt-3">
        { error }
      </p>
    </div>
  );
}

export default SaveButton;
