import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Constants
import { HUB_LANGUAGES } from 'constants/hub';

// Actions
import { setLanguage } from 'reducer/hubs';

// Components
import Select from 'react-select';
import InfoPopup from 'components/utils/InfoPopup';

const LanguageSelector = () => {
  const { creator } = useSelector((state) => state.hubs);
  const dispatch = useDispatch();

  const { language } = creator;

  const _setLanguage = (lang) => {
    dispatch(setLanguage(lang.value));
  };

  // how each question should be rendered
  const renderLabel = ({ label }) => (
    <div>
      <p className="has-text-black is-size-6">
        {label}
      </p>
    </div>
  );

  const selectedValue = useMemo(() => {
    const item = HUB_LANGUAGES.find((label) => label.value === language);

    return item;
  }, [language]);

  // custom styles that match the styles from the profile
  const styles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: 'None',
      borderBottom: '1px solid #a5a5a5',
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '8px',
      border: 'None',
      padding: '0px 10px',
      boxshadow: '0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02)',
    }),
    option: (base) => ({
      ...base,
      borderRadius: '6px',
      marginTop: '5px',
      marginBottom: '5px',
      cursor: 'pointer',
    }),
  };

  return (
    <div className="is-flex has-content-end mb-5">
      <div className="has-width-200">
        <div className="mb-1">
          <p
            className="label is-inline mr-1"
          >
            Hub Sprache
          </p>
          <InfoPopup text="Diese Einstellung bestimmt, in welcher Sprache der Hub für den Benutzer angezeigt wird. Sie ist unabhängig von vom dir erstellten Inhalt. Es werden z.B. Datenschutz-Hinweise oder Buttons in der ausgewählten Sprache angezeigt." />
        </div>
        <Select
          value={selectedValue}
          styles={styles}
          isSearchable={false}
          formatOptionLabel={renderLabel}
          options={HUB_LANGUAGES}
          onChange={_setLanguage}
          maxMenuHeight={300}
          menuPlacement="auto"
          className=""
          placeholder=""
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              text: '#ffffff',
              primary25: '#fcb992',
              primary50: '#fcb992',
              primary75: '#fcb992',
              primary: '#fcb992',
            },
          })}
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
