import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

// Components
import Footer from './Footer';
import { HashLink } from 'react-router-hash-link';

function FAQ() {
  return (
    <>
      <Helmet>
        <title>Flowdust - FAQ</title>
      </Helmet>

      <div>
        <div className="columns is-centered pb70 pt50 has-background-white m0" id="faq">
          <div className="column is-10-desktop">
            <h1 className="mb30 has-text-weight-bold has-text-black has-text-centered is-size-3 is-size-5-mobile">
              Häufig gestellte Fragen
            </h1>

            <h2 className="has-text-weight-bold has-text-black is-size-5">
              Allgemeines
            </h2>

            <div className="columns mt-4 is-mobile is-centered">

              <div className="column is-10 has-fullheight">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Was ist Flowdust?
                </p>
                <p className="mt-2 is-size-6 has-text-grey">A: Flowdust ist eine Plattform, die es dir einfach macht Feedback zu sammeln, Formulare zu erstellen, positive Bewertungen auf gängigen Plattformen zu erhalten und Mini-Umfragen durchzuführen.</p>
                <p className="mb-2 mt-2 is-size-6 has-text-grey">
                  Das funktioniert entweder auf einer zentralen Seite bei uns oder direkt auf deiner Eigenen – und zwar alles ohne Programmieren.
                </p>
                <Link className="has-text-white has-background-pastel pl-2 pr-2 br10 py-1 has-text-weight-bold has-hover-pastel" to={routes.DEMO}>Probiere es aus!</Link>
              </div>

              <div className="column is-10 has-fullheight">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Für wen ist Flowdust gedacht?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Flowdust ist für Unternehmen jeder Art, die sich im Internet bewegen. Wir wollen dabei helfen, durch Feedback und Nutzerinput schneller zu wachsen, besser mit Kunden zu interagieren und zufriedenere Nutzer zu bekommen.</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Funktioniert Flowdust auf allen Geräten?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Ja! Flowdust funktioniert auf Computern, Tablets und Smartphones.</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Was sind Flowter?
                </p>
                <p className="mb-2 is-size-6 has-text-grey mt10 ">
                  A:
                  Flowter sind Mini-Umfragen, die direkt auf deiner Website platziert werden. Du kannst sie ganz nach deinen Wünschen gestalten, um deine Nutzer zu befragen und wertvolles Feedback von deinen Kunden zu sammeln. Weitere Informationen findest du hier.
                </p>
                <Link className="has-text-white has-background-pastel pl-2 pr-2 br10 py-1 has-text-weight-bold has-hover-pastel" to={routes.FLOWTER_INFO}>Erfahre mehr über Flowter!</Link>
              </div>
              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Was ist ein Hub?
                </p>
                <p className="mb-2 is-size-6 has-text-grey mt10 ">
                  A:
                  Ein Hub ist eine vorkonfigurierte Seite, auf der du Formulare erstellen oder Umfragen durchführen kannst. Füge Verzweigungs-Logik hinzu, um z.B. A/B Tests durchzuführen oder positives Feedback in gute Bewertungen zu verwandeln.
                </p>
                <Link className="has-text-white has-background-pastel pl-2 pr-2 br10 py-1 has-text-weight-bold has-hover-pastel" to={routes.HUB_INFO}>Erfahre mehr über Hubs!</Link>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Benötige ich Programmierkenntnisse, um Flowdust nutzen zu können?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Nein, Flowdust ist eine No-Code Plattform, d.h. du kannst es ganz ohne jegliche Programmierkenntnisse nutzen. Wenn du einen Flowter auf deiner eigenen Internetseite platzieren möchtest, musst du nur einmalig ein kleines Code-Snippet kopieren und einfügen - das wars.</p>
              </div>
            </div>

            <h2 className="has-text-weight-bold has-text-black is-size-5 mt-4 mb-5">
              Fragen zu Flowtern
            </h2>

            <div className="columns is-centered is-mobile">

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5">
                  Q: Wie platziere ich einen Flowter auf meiner Seite?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Wir haben verschiedene Anleitungen und Plugins, um die die Integration in deine Website so einfach wie möglich zu machen.Logge dich einfach ein und gehe zu &quot;Flowter Installation&quot;.</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Wie verknüpfe ich meinen Flowter mit meiner Website?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Gehe in der Navigation auf "Flowter" und scrolle zu deinen Webseiten. Klicke nun auf "Wähle einen Flowter". Fertig! Um die Verknüpfung aufzuheben, wähle einen anderen Flowter aus oder klicke auf "Flowter entfernen".</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Was sind Impressionen?
                </p>
                <p className="mt-2 is-size-6  has-text-grey">A: Impressionen bezeichnen die Anzahl, wie oft deine Flowter auf deiner Website geladen und angezeigt werden.</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Machen Flowter meine Website langsamer?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Unsere Flowter legen großen Wert auf Höflichkeit. Sie werden erst geladen, wenn deine Website bereit ist. Deine Website wird dadurch also nicht langsamer.</p>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Wieso wird mein Flowter nicht auf meiner Website angezeigt?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">
                  A: Dafür kann es verschiedene Gründe geben. Bitte stelle zunächst sicher, dass du Flowdust korrekt auf deiner Seite hinzugefügt hast und dass dein Flowter deiner Website auch zugewiesen wurde.
                </p>
                <p className="mb-2 mt-2 is-size-6 has-text-grey mt10 ">
                  Wenn Du selbst bei deinem Flowter bereits abgestimmt hast, ist er ebenfalls nicht mehr sichtbar.
                  Sollte es dennoch nicht funktionieren, wende dich bitte an uns!
                </p>
                <a
                  href="mailto:hello@flowdust.com"
                  className="has-text-weight-bold has-text-white has-background-pastel pl-2 pr-2 br10 py-1"
                >
                  Kontaktiere uns
                </a>
              </div>

            </div>

            <h2 className="has-text-weight-bold has-text-black is-size-5 mt-4 mb-5">
              Fragen zum Abonnement
            </h2>

            <div className="columns is-centered is-mobile">

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Gibt es Rabatte für Studenten, Lehrer oder Non-Profit Organisationen?
                </p>
                <p className="mb-2 is-size-6 has-text-grey mt10 ">A: Selbstverständlich! Wir bieten einen Rabatt von 50% auf alle Pläne an. Kontaktiere uns bitte hier:</p>
                <a
                  href="mailto:hello@flowdust.com"
                  className="has-text-weight-bold has-text-white has-background-pastel pl-2 pr-2 br10 py-1"
                >
                  E-Mail senden
                </a>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Welche Zahlungsmöglichkeiten gibt es?
                </p>
                <p className="mb-2 is-size-6 has-text-grey mt10 ">A: Wir akzeptieren die gängigsten Kreditkarten (Visa/Mastercard) und PayPal. Du kannst Flowdust aber auch kostenlos nutzen. Hier geht’s zu den Preisen.</p>
                <HashLink className="has-text-white has-background-pastel pl-2 pr-2 br10 py-1 has-text-weight-bold has-hover-pastel" to={`${routes.LANDING}#pricing`}>Zu den Preisen</HashLink>
              </div>

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Wie kann ich mein Abonnement ändern oder kündigen?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Logge dich in deinen Account ein und gehe links in der Navigationsleiste auf "Abonnement". Klicke nun auf "Abonnement ändern", um deinen Plan anzupassen.</p>
              </div>
            </div>

            <h2 className="has-text-weight-bold has-text-black is-size-5 mt-4 mb-5">
              Fragen zum Account
            </h2>

            <div className="columns is-centered is-mobile">

              <div className="column is-10">
                <p className="has-text-pastel has-text-weight-bold is-size-5 ">
                  Q: Wo kann ich mein Passwort ändern?
                </p>
                <p className="is-size-6 has-text-grey mt10 ">A: Du musst dein Passwort zurücksetzen, um es ändern zu können. Klicke hierfür beim "Einloggen" auf "Du hast dein Passwort vergessen?" und gib deine E-Mail an. Du erhältst anschließend per Mail einen Link. Dort kannst Du dein neues Passwort eingeben.</p>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

/*
<div className="mt20 column is-10-mobile is-offset-1-mobile is-offset-2 is-4">
  <p className="has-text-pastel has-text-weight-bold is-size-5 ">
    Q: Ist die Multifaktor Authentifizierung notwendig?
  </p>
  <p className="is-size-6 has-text-grey mt10 ">A: Durch die MFA wird die Sicherheit deines Accounts verstärkt. Diese kannst Du in deinem Account unter Einstellungen aktivieren. Um die MFA nutzen zu können, wird eine Authentifizierungs-App, wie z.B. Google Authenticator benötigt.</p>
</div>

*/

export default FAQ;
