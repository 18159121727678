import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';

// Components
import AnalyticsChart from '../AnalyticsChart';

const LangStatistic = () => {
  const { analytics } = useSelector((state) => state.hubs.statistic);
  const { lang } = analytics;

  const [generalStatistic, setGeneralStatistic] = useState([]);

  useEffect(() => {
    if (lang.length === 0) return;

    const generalData = [];

    for (let i = 0; i < Math.min(lang.length, 5); i++) {
      const { name, totalAmount } = lang[i];

      generalData.push({
        x: name, y: totalAmount, label: name, index: i,
      });
    }

    setGeneralStatistic(generalData);
  }, [lang]);

  const showGeneralStatistic = () => {
    if (generalStatistic.length === 0) return null;

    return (
      <AnalyticsChart
        statistic={generalStatistic}
      />
    );
  };

  return (
    <div className="my-4">
      <h2 className="has-text-centered has-text-black has-text-weight-semibold">
        Aufrufe pro Browsersprache
      </h2>
      <div>
        { showGeneralStatistic() }
      </div>
    </div>
  );
};

export default LangStatistic;
