import React, { useState, useEffect } from 'react';

// Libraries
import { setPaymentMethods } from 'actions/payment/paymentMethods';
import axios from 'axios';
import dropin from 'braintree-web-drop-in';
import stats from 'analytics/analytics';
import { addVAT } from 'utils/calculateVAT';
import { Helmet } from 'react-helmet-async';

// Constants
import { BILLING } from 'constants/routes';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Assets
import loader from 'assets/images/loading.svg';

/*
  Class to add a new payment method or to set a saved one as active
*/
function PaymentMethodSelector() {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [dropInInstance, setDropInInstance] = useState();
  const [signal] = useState(axios.CancelToken.source());

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const user = useSelector((state) => state.sessionState.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.customer) {
      navigate(BILLING);
      return null;
    }

    let instance;

    const initBraintree = async () => {
      try {
        const token = await getAccessTokenSilently();

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const paymentToken = await axios.get(`${process.env.REACT_APP_API}/payments/token`, config);

        instance = await dropin.create({
          authorization: paymentToken.data,
          container: '#dropInContainer',
          locale: 'de_DE',
          paypal: {
            flow: 'vault',
          },
          dataCollector: {
            paypal: true,
          },
          threeDSecure: true,
        });

        setDropInInstance(instance);

        instance.on('paymentMethodRequestable', () => {
          setDisabled(false);
        });

        instance.on('noPaymentMethodRequestable', () => {
          setDisabled(true);
        });

        instance.on('paymentOptionSelected', () => {
          setError('');
        });

        instance.clearSelectedPaymentMethod();
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        setError('Etwas ist schief gelaufen. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');

        console.log(err);
      }
    };

    initBraintree();

    return () => {
      signal.cancel();
      if (instance) {
        instance.teardown();
      }
    };
    // eslint-disable-next-line
 }, [])

  const setPaymentMethod = async () => {
    setError('');
    setDisabled(true);

    if (!dropInInstance) {
      setError('Moment bitte, ich bin noch nicht ganz bereit...');
      setDisabled(false);
      return;
    }

    let instance;

    // add vat
    const amount = addVAT(user.planDetails.cost);

    try {
      // TODO: check country code for austria and switzerland
      const threeDSecureParameters = {
        amount,
        email: user.email,
        billingAddress: {
          givenName: user.customer.firstName,
          surName: user.customer.lastName,
          streetAddress: user.customer.addressData.streetAddress,
          locality: user.customer.addressData.locality,
          postalCode: user.customer.addressData.postalCode,
          countryCodeAlpha2: 'DE',
        },
      };

      instance = await dropInInstance.requestPaymentMethod({
        threeDSecure: threeDSecureParameters,
      });

      if (instance.liabilityShifted === false) {
        setError('Zahlung mit dieser Karte nicht möglich. Bitte wähle eine andere Zahlungsart.');
        dropInInstance.clearSelectedPaymentMethod();
        return;
      }
    } catch (e) {
      console.log(e);

      setDisabled(false);

      if (e.name === 'DropinError') {
        setError('Bitte gib eine Zahlungsmethode an.');
        return;
      }

      setError('Initialisierung fehlgeschlagen. Bitte lade die Seite noch einmal neu.');
    }

    // Then, send payment method nonce to server
    try {
      setLoading(true);

      const data = {
        nonce: instance.nonce,
        deviceData: instance.deviceData,
      };

      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      const result = await axios.post(`${process.env.REACT_APP_API}/payments`, data, config);

      dispatch(setPaymentMethods(result.data));

      dropInInstance.clearSelectedPaymentMethod();

      setLoading(false);
      setError('Deine ausgewählte Bezahlmethode ist nun aktiv.');

      stats.push(['trackEvent', 'Billing', 'Payment Method set']);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      dropInInstance.clearSelectedPaymentMethod();
      setError(e.response.data);
      setLoading(false);
    }
  };

  /**
  {
    isLoading && (
    <div className="columns is-paddingless mb0">
      <div className="column has-text-centered mt20 mb0 is-paddingless">
        <img src={loading} alt="" />
      </div>
    </div>
    )
  }
  */

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Bezahlmethode wählen
        </title>
      </Helmet>
      <div className="columns is-marginless is-centered">
        <div className="column is-9 py-6">
          <h2 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-black has-text-weight-bold">
            Bezahlmethode ändern
          </h2>
          <p>Wähle eine bereits gespeicherte Bezahlmethode aus oder füge eine neue hinzu.</p>
          <div className="columns is-marginless mt-5">
            <div className="column is-8-desktop is-9-tablet box pl20 pr20 boxshadow py-5">
              <p className="has-text-weight-bold has-text-black mb-1">
                Wie möchtest Du bezahlen?
              </p>
              <div id="dropInContainer" />
              <div className="has-text-centered mt-6">
                <button
                  disabled={disabled || isLoading}
                  onClick={setPaymentMethod}
                  type="button"
                  className="button has-white-space has-fullheight has-text-weight-bold has-background-pastel has-no-border has-text-white br10 grow"
                >
                  Mit ausgewählter Zahlungsart bezahlen
                </button>
                <div className="mt-3">
                  {
                    isLoading
                      ? <img src={loader} alt="" />
                      : (
                        <p className="mb-3 mt-5 has-text-black has-text-weight-bold is-size-6">
                          {error}
                        </p>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthentication(PaymentMethodSelector);
