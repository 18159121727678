import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import notification from 'utils/notification';
import * as limits from 'constants/widgets';
import { useAuth0 } from '@auth0/auth0-react';
import './styles/editButton.scss';
import RemoveFromWebsitesButton from './RemoveFromWebsitesButton';

function EditButtons(props) {
  const {
    widget, allWidgets, api, creatorEditUrl,
  } = props;

  const { saveDuplicate } = props;

  const [duplicateName, setDuplicateName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const signal = axios.CancelToken.source();

  const { getAccessTokenSilently } = useAuth0();

  const resetError = () => {
    setError('');
  };

  const setDuplicateWidgetName = (text) => {
    setDuplicateName(text);

    if (duplicateName.length > limits.WIDGET_MAX_NAME_LENGTH) {
      setError('Bitte wähle einen kürzeren Namen.');
    } else {
      setError('');
    }
  };

  const duplicateWidget = async (closeSmallPopup, closeBigPopup) => {
    if (duplicateName.length === 0) {
      setError('Bitte wähle einen Namen.');
      return;
    }

    if (duplicateName.length > limits.WIDGET_MAX_NAME_LENGTH) {
      setError('Bitte wähle einen kürzeren Namen.');
      return;
    }

    for (let i = 0; i < allWidgets.length; i++) {
      if (allWidgets[i].meta.name === duplicateName) {
        setError('Der Name ist bereits vorhanden.');
        return;
      }
    }

    setDisabled(true);

    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      const data = widget;
      data.name = duplicateName;

      const duplicate = await axios.post(`${process.env.REACT_APP_API}/${api}`, data, config);

      setDisabled(false);
      setError('');
      setDuplicateName('');

      closeBigPopup();
      closeSmallPopup();

      saveDuplicate(duplicate.data);

      notification.send('Geschafft!', 'Dein Flowter wurde dupliziert.', 'success');
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      console.log(err);
      console.log(err.response);

      if (!err.response) {
        setError('Es ist etwas schief gelaufen. Bitte versuche es später noch einmal.');
        return;
      }

      if (err.response.status === 500) {
        setError('Server Fehler! Bitte versuche es später noch einmal.');
        return;
      }

      setError(err.response.data);
    }
  };

  return (
    <Popup
      trigger={(
        <button
          className="button is-inline-block p0 icon-circle has-background-grey-light"
          type="button"
        >
          <i className="is-size-7 fas fa-cog has-text-grey" />
        </button>
   )}
      position="top center"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
      className="mt5"
    >
      {(close) => (
        <div className="columns is-marginless">
          <div className="column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-background-white br22">
            <div className="is-hover-primary br22 has-text-centered mb5">
              <Link
                to={creatorEditUrl}
                className="button cleanButton p-0 is-hover-primary br22 has-text-centered has-fullwidth"
              >
                <i className="is-size-6 fas fa-edit" />
                <p className="ml10 has-text-weight-semibold is-size-7">Bearbeiten</p>
              </Link>
            </div>
            <div className="is-hover-primary br22 has-text-centered">
              <Popup
                trigger={(
                  <button
                    className="button cleanButton p-0 is-hover-primary br22 has-text-centered has-fullwidth"
                    type="button"
                  >
                    <i className="is-size-6 fas fa-copy" />
                    <p className="ml10 has-text-weight-semibold is-size-7">Duplizieren</p>
                  </button>
             )}
                modal
                onClose={resetError}
                className="duplicate"
              >
                {(closeBigPopup) => (
                  <div className="columns">
                    <div className="column box is-12">
                      <div className="columns">
                        <div className="column has-text-centered">
                          <div className="has-text-right">
                            <button
                              className="button cleanButton"
                              onClick={close}
                              type="button"
                            >
                              <i className="fas fa-times has-text-grey-light is-size-2 hoverLink" />
                            </button>
                          </div>
                          <h2 className="mt20 has-text-centered is-size-5 is-size-6-mobile has-text-black has-text-weight-semibold">
                            Wie soll der duplizierte Flowter heißen?
                          </h2>
                        </div>
                      </div>
                      <div className="columns is-centered mt0">
                        <div className="column is-6 ml20 mr20">
                          <input
                            onChange={(event) => setDuplicateWidgetName(event.target.value)}
                            type="text"
                            placeholder="Flowter Name"
                            className="is-size-5 is-size-6-mobile input has-text-black has-text-centered has-background-white mt10 has-text-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="columns is-centered is-mobile mt10 mb5">
                        <div className="column has-text-centered is-paddingless pl10 pr10">
                          <button
                            className="button grow is-pastel is-rounded has-text-white has-text-weight-bold"
                            onClick={() => duplicateWidget(close, closeBigPopup)}
                            disabled={disabled}
                            type="button"
                          >
                            Duplizieren
                          </button>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column has-text-centered mb20 mt20">
                          <p className="has-text-danger has-text-weight-bold">{error}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
            <div className="is-hover-primary br22 has-text-centered mb5">
              <RemoveFromWebsitesButton id={widget._id} />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

EditButtons.propTypes = {
  allWidgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  widget: PropTypes.shape({}).isRequired,
  api: PropTypes.string.isRequired,
  creatorEditUrl: PropTypes.string.isRequired,
  saveDuplicate: PropTypes.func.isRequired,
};

export default EditButtons;
