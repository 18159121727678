import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { HUB_ENDING_LINK_TYPES } from 'constants/hub';

// Components
import Dropdown from 'components/utils/Dropdown';
import InfoPopup from 'components/utils/InfoPopup';

const TypeSelector = (props) => {
  const { type, setType } = props;

  const _setType = (_type) => {
    setType(_type.value);
  };

  // Prepare all question to be displayed
  const labels = useMemo(() => HUB_ENDING_LINK_TYPES.map((_type) => {
    let label;
    switch (_type) {
      case 'GOOGLE': label = 'Google';
        break;
      case 'TWITTER': label = 'Twitter';
        break;
      case 'FB': label = 'Facebook';
        break;
      default: label = 'Link';
    }

    return {
      value: _type,
      label,
    };
  }), []);

  const value = useMemo(() => labels.find((label) => label.value === type), [type]);

  return (
    <div className="has-min-width-200">
      <p className="is-inline has-text-weight-bold is-size-7">
        Icon
      </p>
      <InfoPopup
        class="is-inline"
        text="Du kannst vorbestimmte Icons auswählen, damit deine User sofort sehen, um welche Website es sich handelt."
      />
      <Dropdown
        value={value}
        labels={labels}
        onChange={_setType}
      />
    </div>
  );
};

TypeSelector.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

export default TypeSelector;
