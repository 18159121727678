import React, { useEffect } from 'react';

// Libarries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Constants
import { SIGN_UP } from 'constants/routes';
// Actions
import { clearCreator } from 'actions/creator';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import Preview from 'components/widgets/creator/Preview';
import NameCreator from './NameCreator';
import LayoutOptions from './LayoutOptions';
import TriggerOptions from './TriggerOptions';
import Settings from './Settings';
import ColorSelector from './ColorSelector';
import SaveButtonDemo from './SaveButtonDemo';

// Styles
import './styles/creator.scss';

function Creator(props) {
  // Props
  const {
    showResetButton, resetWidget, error, widgetPreview, disabled, showLayoutTypes, allowColor, children, showExpireSettings,
  } = props;

  const { isAuthenticated } = useAuth0();

  const { editIndex } = useSelector((state) => state.creatorState);

  const isEdit = editIndex !== -1;

  const dispatch = useDispatch();

  const saveWidget = async () => {
    props.saveWidget();
  };
  // Amount of children in parent creator (plus nameCreator) is the amount of steps
  const steps = children.props.children.length + 1;

  useEffect(
    () => () => {
      dispatch(clearCreator());
    }
    // eslint-disable-next-line
  , [])

  return (
    <div className="has-background-white" id="widgetCreator">
      <div className="columns is-marginless is-paddingless" id="widgetCreatorWrapper">
        <div className="column is-4-desktop is-12-tablet pl30 pr30 has-overflow-y-auto" id="widgetCreatorSettings">
          <div id="widgetCreatorSettingsWrapper">
            {
            isEdit
              ? (
                <>
                  <h2 className="title has-text-weight-bold pb10 mt70 is-size-4 is-size-4-mobile has-text-black">
                    Bearbeite deinen Flowter
                  </h2>
                  <p className="has-text-weight-bold has-text-danger has-text-centered mb30">
                    Bitte beachte, dass nach dem Bearbeiten die Statistiken zurückgesetzt werden!
                  </p>
                </>
              )
              : (
                <h2 className="title has-text-weight-bold pb10 mt70 is-size-4 is-size-5-mobile has-text-black">
                  Erstelle deinen Flowter
                </h2>
              )
            }
            <p className="is-hidden-desktop is-hidden-tablet is-size-7">
              Scrolle nach unten, um die Vorschau für deinen Flowter zu sehen!
            </p>
            <NameCreator />
            { children }
            <LayoutOptions
              step={steps + 1}
              showLayoutTypes={showLayoutTypes}
            />
            <TriggerOptions step={steps + 2} />
            <ColorSelector step={steps + 3} isVisible={allowColor} />
            <Settings showExpire={showExpireSettings} />
            <div className="has-text-centered mb50">
              {
                isAuthenticated
                  ? (
                    <button
                      className="button grow has-text-white has-background-pastel has-no-border is-outlined is-rounded mt20 has-text-weight-bold has-hover-primary"
                      onClick={(e) => saveWidget(e)}
                      disabled={disabled}
                      type="button"
                    >
                      Speichern
                    </button>
                  )
                  : (
                    <SaveButtonDemo />
                  )
              }
              { error && <p className="mb40 mt20 has-text-danger has-text-centered has-text-weight-bold">{error}</p>}
            </div>
          </div>
        </div>
        <Preview
          showResetButton={showResetButton}
          resetWidget={resetWidget}
        >
          { widgetPreview }
        </Preview>
      </div>
    </div>
  );
}

Creator.propTypes = {
  children: PropTypes.node.isRequired,
  showResetButton: PropTypes.bool,
  saveWidget: PropTypes.func.isRequired,
  widgetPreview: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,

  resetWidget: PropTypes.func,
  error: PropTypes.string,
  showLayoutTypes: PropTypes.bool,
  showExpireSettings: PropTypes.bool,
  allowColor: PropTypes.bool,
};

Creator.defaultProps = {
  resetWidget: () => {},
  error: '',
  showLayoutTypes: true,
  showExpireSettings: true,
  showResetButton: true,
  allowColor: true,
};

export default Creator;
