import React from 'react';
import './styles/previousButton.scss';

/**
 * Go-Back Button used for the ScriptInsertContainer to go back to the main menu
 * Works and looks like the PreviousButton in Onboarding, but has a higher z-index so not the onboarding button gets clicked, but this one
 */
const PreviousButton = (props) => {
  const goToPreviousPage = (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    props.goToPreviousPage();
  };

  return (
    <button
      className="button grow has-background-grey-light has-text-light
		 has-text-weight-bold br12"
      id="scriptInsertPreviousButton"
      onClick={(e) => goToPreviousPage(e)}
    >
      <i className="fas fa-angle-left" />
    </button>
  );
};

export default PreviousButton;
