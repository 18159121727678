import { createSlice } from '@reduxjs/toolkit';

const ONE_QUESTION = {
  checks: [],
  question: '',
  options: [],
  maxNumberOfChecks: 1,
  allowText: false,
  text: '',
  // reaction: null,
  // gif: null,
};

const initialState = {
  questions: [ONE_QUESTION],
  endTxt: "Danke für's Mitmachen!",
  // controls
  activeQuestion: 0,
  showResetButton: false,
  isComingFromDemo: false,
};

export const flowterCreatorSlice = createSlice({
  name: 'flowterCreator',
  initialState,
  reducers: {
    // Basic controls
    setFlowter: (state, action) => {
      const flowter = action.payload;

      // add check array
      state.questions = flowter.questions.map((item) => {
        const checkArray = new Array(item.options.length).fill(0);

        if (item.allowText) {
          checkArray.push(0);
        }

        return ({
          ...ONE_QUESTION,
          ...item,
          checks: checkArray,
        });
      });

      state.endTxt = flowter.endTxt;

      state.activeQuestion = 0;
      state.showResetButton = false;
    },
    setEndTxt: (state, action) => {
      state.endTxt = action.payload;
      // show end text
      state.activeQuestion = -2;
      state.showResetButton = true;
    },
    setMaxNumberOfChecks: (state, action) => {
      const { id, amount } = action.payload;
      state.questions[id].maxNumberOfChecks = amount;

      const checkLength = state.questions[id].checks.length;
      state.questions[id].checks = new Array(checkLength).fill(0);

      state.activeQuestion = id;
      state.showResetButton = id > 0;
    },
    setAllowText: (state, action) => {
      const { id, type } = action.payload;
      // add check to checkArray
      if (type === true) {
        state.questions[id].checks.push(0);
      } else {
        // remove check from checks and reset input
        state.questions[id].checks.splice(-1, 1);
        state.questions[id].text = '';
        state.questions[id].reaction = null;
        state.questions[id].gif = null;

        state.questions[id].maxNumberOfChecks = 1;
      }

      state.questions[id].allowText = type;

      state.activeQuestion = id;
      state.showResetButton = id > 0;
    },
    setCheck: (state, action) => {
      const { id, option, type } = action.payload;
      state.questions[id].checks[option] = type;
    },
    // Questions
    setIndexQuestion: (state, action) => {
      const index = action.payload;

      state.activeQuestion = index;

      if (index === 0) {
        state.showResetButton = false;
      } else {
        state.showResetButton = true;
      }
    },
    setTextQuestion: (state, action) => {
      const { id, text } = action.payload;
      state.questions[id].text = text;
    },
    addQuestion: (state) => {
      state.questions.push(ONE_QUESTION);

      state.activeQuestion = state.questions.length - 1;
      state.showResetButton = true;
    },
    setQuestion: (state, action) => {
      const { id, text } = action.payload;
      state.questions[id].question = text;

      state.activeQuestion = id;
      state.showResetButton = id > 0;
    },
    deleteQuestion: (state, action) => {
      // if the active question is the last one, go one step back
      if (state.activeQuestion === state.questions.length - 1) {
        const previousQuestion = state.questions.length - 2;
        state.activeQuestion = previousQuestion;
        state.showResetButton = previousQuestion > 0;
      }

      state.questions.splice(action.payload, 1);
    },
    // Options
    addOption: (state, action) => {
      const id = action.payload;
      state.questions[id].options.push('');
      state.questions[id].checks.push(0);
      // reset input
      state.questions[id].text = '';
      state.questions[id].reaction = null;
      state.questions[id].gif = null;

      state.activeQuestion = id;
      state.showResetButton = id > 0;
    },
    setOption: (state, action) => {
      const { id, option, text } = action.payload;
      state.questions[id].options[option] = text;

      state.activeQuestion = id;
      state.showResetButton = id > 0;
    },
    deleteOption: (state, action) => {
      const { id, option } = action.payload;
      state.questions[id].options.splice(option, 1);
      state.questions[id].checks.splice(option, 1);
    },
    // Clears
    resetPreview: (state) => {
      // reset checks
      state.questions = state.questions.map((item) => {
        let checkLength = item.options.length;
        // if text is allowed, we need one more check
        if (item.allowText) checkLength++;

        return ({
          ...item,
          checks: new Array(checkLength).fill(0),
          text: '',
        });
      });

      state.activeQuestion = 0;
      state.showFlowter = true;
      state.showResetButton = false;
    },
    setShowResetButton: (state, action) => {
      state.showResetButton = action.payload;
    },
    clearChecks: (state) => {
      state.questions = state.questions.map((item) => ({
        ...item,
        checks: new Array(item.options.length).fill(0),
        text: '',
        gif: null,
        reaction: null,
      }));
    },
    setComingFromDemo: (state, action) => {
      state.isComingFromDemo = action.payload;
    },
    clearFlowterCreator: (state) => {
      const _state = { ...initialState, isComingFromDemo: state.isComingFromDemo };
      return _state;
    },
  },
});

export const {
  // Basic controls
  setFlowter,
  setEndTxt,
  setMaxNumberOfChecks,
  setCheck,
  setShowResetButton,
  setAllowText,
  // Questions
  addQuestion,
  setQuestion,
  deleteQuestion,
  setIndexQuestion,
  setTextQuestion,
  // Options
  addOption,
  setOption,
  deleteOption,
  // Clears
  resetPreview,
  clearChecks,
  clearFlowterCreator,

  setComingFromDemo,

} = flowterCreatorSlice.actions;

export default flowterCreatorSlice.reducer;
