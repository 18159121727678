import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setInfoActive,
  addQuestion, setQuestionActive,
  addEnding, setEndingActive,
  setLogicActive, swapQuestions,
} from 'reducer/hubs';

// Components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfoPopup from 'components/utils/InfoPopup';
import CircleButton from './CircleButton';

// Constants
import { HUB_MAX_QUESTIONS, HUB_MAX_ENDINGS } from 'constants/hub';

// Styles
import styles from './styles.module.scss';

const NavigationBar = () => {
  const {
    questions, endings, logic, activeContent,
  } = useSelector((state) => state.hubs.creator.content);

  // flag to deetermine if the mobile navbar is shown or not
  // only used well, on smartphones (width <= 768px)
  const [mobileOpen, setMobileOpen] = useState(false);

  const { type, index } = activeContent;

  const dispatch = useDispatch();

  const toggleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };

  const _setInfoActive = () => {
    dispatch(setInfoActive());
  };

  const _setQuestionActive = (_index) => {
    dispatch(setQuestionActive(_index));
  };

  const _setEndingActive = (_index) => {
    dispatch(setEndingActive(_index));
  };

  const _setLogicActive = () => {
    dispatch(setLogicActive());
  };

  const _addQuestion = () => {
    dispatch(addQuestion());
  };

  const _addEnding = () => {
    dispatch(addEnding());
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const { index: start } = source;
    const { index: end } = destination;

    dispatch(swapQuestions({ start, end }));
  };

  const showInfoButton = () => {
    const isActive = type === 'INFO';

    return (
      <div className="my-3 pb-3 has-text-centered">
        <p className="has-text-black has-text-weight-bold is-size-7">
          Start
        </p>
        <CircleButton
          onClick={_setInfoActive}
          isActive={isActive}
        >
          <i className="fas fa-paper-plane" />
        </CircleButton>
      </div>
    );
  };

  const showQuestionButtons = () => {
    const renderedButtons = questions.map((question, _index) => {
      const { type: questionType } = question;

      const isActive = type === 'QUESTION' && index === _index;
      // { _index + 1}

      let icon = '';

      switch (questionType) {
        case 'POLL':
          icon = 'fa-square-poll-vertical is-size-6';
          break;
        case 'SLIDER':
          icon = 'fa-sliders is-size-6';
          break;
        case 'EMAIL':
          icon = 'fa-envelope is-size-6';
          break;
        default:
          icon = '';
      }

      const id = `${questionType}_${_index}`;

      return (
        <Draggable key={id} draggableId={id} index={_index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="py-2 px-1"
            >
              <div className="is-flex has-content-centered has-max-width-100 is-in-center">
                <i
                  {...provided.dragHandleProps}
                  className="fas fa-bars has-text-grey-dark has-height-16 has-width-16"
                />
                <CircleButton
                  onClick={() => _setQuestionActive(_index)}
                  isActive={isActive}
                  key={_index}
                >
                  <i className={`fas ${icon} has-text-grey-dark has-height-16 has-width-16`} />
                </CircleButton>
              </div>
            </div>
          )}
        </Draggable>

      );
    });

    return (
      <div className="my-3 py-3 has-border-top has-border-bottom has-text-centered">
        <p className="has-text-black has-text-weight-bold is-size-7">
          Fragen
        </p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className=""
                >
                  { renderedButtons }
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </DragDropContext>

        {
          questions.length < HUB_MAX_QUESTIONS
          && (
          <div className="mt-3">
            <CircleButton
              onClick={() => _addQuestion()}
              isActive={false}
            >
              <i className="fas fa-plus" />
            </CircleButton>
          </div>
          )
        }
      </div>
    );
  };

  const showEndingButtons = () => {
    const renderedButtons = [];

    // start at second ending, beccause first ending is special, als most questions will end with the first ending
    // As for display, show it as the second/third... ending
    for (let i = 1; i < endings.length; i++) {
      const isActive = type === 'ENDING' && index === i;
      renderedButtons.push(
        <div className="my-2" key={i}>
          <CircleButton
            onClick={() => _setEndingActive(i)}
            isActive={isActive}
            key={i}
          >
            { i + 1 }
          </CircleButton>
        </div>,
      );
    }

    return (
      <div className="my-3 pb-3 has-border-bottom has-text-centered">
        <p className="has-text-black has-text-weight-bold is-size-7">
          Ende
        </p>
        <InfoPopup text="Wenn Fragen beantwortet werden, werden deine Nutzer immer zu diesem Ende geleitet. Dort kannst du ihnen zum Beispiel für ihre Hilfe danken oder sie auf weitere Social-Media-Kanäle verweisen." />
        <div className="pb-3 mb-3 mt-2 has-border-bottom">
          <CircleButton
            onClick={() => _setEndingActive(0)}
            isActive={type === 'ENDING' && index === 0}
            key={0}
          >
            1
          </CircleButton>
        </div>

        <div>
          <p className="has-text-black has-text-weight-bold is-size-7">
            Weitere Enden
          </p>
          <InfoPopup text="Weitere Enden sind nur in Verbindung mit Logik möglich. Lenke deine Nutzer je nach Abstimmung zu verschiedenen Enden." />
          { renderedButtons }
          {
            endings.length < HUB_MAX_ENDINGS
            && (
            <CircleButton
              onClick={() => _addEnding()}
              isActive={false}
            >
              <i className="fas fa-plus" />
            </CircleButton>
            )
          }
        </div>

      </div>
    );
  };

  const showLogicButton = () => {
    const isActive = type === 'LOGIC';

    return (
      <div className="has-text-centered">
        <p className="has-text-black has-text-weight-bold is-size-7 mb-2">
          {`Logik ${logic.length > 0 ? `(${logic.length})` : ''}`}
        </p>
        <CircleButton
          onClick={_setLogicActive}
          isActive={isActive}
        >
          <i className="fas fa-sitemap" />
        </CircleButton>
      </div>
    );
  };

  return (
    <div>

      <div
        id={styles.overlay}
        className={mobileOpen ? styles.mobileOpen : ''}
        onClick={() => setMobileOpen(false)}
        aria-hidden="true"
      />

      <button
        type="button"
        onClick={toggleMobileOpen}
        className={`button is-hidden-tablet boxshadow is-hidden-desktop ${mobileOpen ? styles.mobileOpen : ''}`}
        id={styles.navbarButton}
      >
        <i className={`fas fa-angle-${mobileOpen ? 'left' : 'right'}`} />
      </button>

      <div
        id={styles.sidebar}
        className={`py-3 ${mobileOpen ? styles.mobileOpen : ''}`}
      >
        { showInfoButton() }

        { showQuestionButtons() }

        { showEndingButtons() }
        { showLogicButton() }

      </div>
    </div>
  );
};

export default NavigationBar;
