/* VAT is 19% */
const plans = [
  {
    cost: 0,
    costWithVAT: '0',
    views: 1000,
    name: 'Free',
    plan: 0,
    maxAmountHubs: 1,
    perks: ['whitelabel'],
  },

  {
    cost: 9,
    costWithVAT: '10,71',
    views: 10000,
    name: 'Basic',
    plan: 1,
    maxAmountHubs: 3,
    perks: ['whitelabel'],
  },

  {
    cost: 29,
    costWithVAT: '34,51',
    views: 100000,
    name: 'Premium',
    plan: 2,
    maxAmountHubs: 10,
    perks: ['whitelabel'],
  },

  {
    cost: 89,
    costWithVAT: '105,91',
    views: 600000,
    name: 'Business',
    plan: 3,
    maxAmountHubs: 50,
    perks: ['whitelabel'],
  },
];

/*
Default Plans:
const plans = [
  {
    cost: 0,
    costWithVAT: '0',
    views: 1000,
    name: 'Free',
    plan: 0,
    maxAmountHubs: 1,
    perks: ['whitelabel'],
  },

  {
    cost: 9,
    costWithVAT: '10,71',
    views: 10000,
    name: 'Basic',
    plan: 1,
    maxAmountHubs: 3,
    perks: ['whitelabel'],
  },

  {
    cost: 29,
    costWithVAT: '34,51',
    views: 100000,
    name: 'Premium',
    plan: 2,
    maxAmountHubs: 10,
    perks: ['whitelabel'],
  },

  {
    cost: 89,
    costWithVAT: '105,91',
    views: 600000,
    name: 'Business',
    plan: 3,
    maxAmountHubs: 50,
    perks: ['whitelabel'],
  },
];
*/

export default plans;
