import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import useSubscription from 'components/hooks/useSubscription';
import stats from 'analytics/analytics';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { setCustomer } from 'actions/customer';
import PlanSelector from './PlanSelector';
import CustomerInfoCollector from './CustomerInfoCollector';
import PreviousButton from './buttons/PreviousButton';
import PaymentPage from './PaymentPage';
import { Helmet } from 'react-helmet-async';
import './styles/checkout.scss';

const Checkout = () => {
  const [step, setStep] = useState(0);
  const [plan, setPlan] = useState(-1);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { canCreateSubscription } = useSubscription();

  useLayoutEffect(() => {
    if (!canCreateSubscription) {
      stats.push(['trackEvent', 'Checkout', 'Invalid User on checkout']);
      navigate(routes.BILLING);
    }
    // eslint-disable-next-line
  }, []);

  const goToNextStep = () => {
    const nextStep = step + 1;

    let page;

    switch (nextStep) {
      case 1:
        page = 'Customer Info Collector';
        break;
      case 2:
        page = 'Payment Page';
        break;
      default:
        page = 'Unknown Page';
    }

    stats.push(['trackEvent', 'Checkout', `Next step: ${page}`]);

    setStep(nextStep);
  };

  const goToPreviousStep = () => {
    const previousStep = step - 1;

    let page;

    switch (previousStep) {
      case 0:
        page = 'Plan Selector';
        break;
      case 1:
        page = 'Customer Info Collector';
        break;
      case 2:
        page = 'Payment Page';
        break;
      default:
        page = 'Unknown Page';
    }

    stats.push(['trackEvent', 'Checkout', `Previous step: ${page}`]);

    setStep(previousStep);
  };

  const saveCustomer = (customer) => {
    stats.push(['trackEvent', 'Checkout', 'Customer address saved']);
    dispatch(setCustomer(customer));
    goToNextStep();
  };

  const showCorrectPage = () => {
    let content;

    switch (step) {
      case 0:
        content = (<PlanSelector plan={plan} setPlan={setPlan} next={goToNextStep} />);
        break;
      case 1:
        content = (
          <div className="pb-6">
            <PreviousButton goBack={goToPreviousStep} />
            <div className="columns is-marginless mt-6 is-centered">
              <div className="column is-10">
                <h2 className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile">Kundeninformationen</h2>
                <p className="mb20 has-text-grey">
                  Bitte fülle folgendes Formular aus, um Dein Abonnement starten zu können.
                  Im Anschluss kannst Du Deine Zahlungsmethode auswählen.
                </p>
              </div>
            </div>
            <CustomerInfoCollector next={saveCustomer} />
          </div>
        );
        break;
      case 2:
        content = (
          <div>
            <PreviousButton goBack={goToPreviousStep} />
            <PaymentPage plan={plan} />
          </div>
        );
        break;
      default:
        return null;
    }

    return (
      <>
        <Helmet>
          <title>Flowdust - Abonnement</title>
        </Helmet>

        <SwitchTransition>
          <CSSTransition
            classNames="fade"
            timeout={300}
            key={step}
          >
            { content }
          </CSSTransition>
        </SwitchTransition>
      </>
    );
  };

  return showCorrectPage();
};

export default Checkout;
