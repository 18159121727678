import React, { useEffect, useState } from 'react';

// Libraries
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// Hooks
import { useNavigate } from 'react-router-dom';

// Reducer Actions
import {
  setFlowter,
  clearFlowterCreator,
  resetPreview,
} from 'reducer/flowterCreator';

// Constants
import { FLOWTER_CREATOR } from 'constants/routes';

// Templates
import Preview from 'components/widgets/creator/Preview';
import ecommerce from './templates/Ecommerce';
import startups from './templates/Startups';
import specific from './templates/Specific';
import marketing from './templates/Marketing';
import basics from './templates/Basics';

// Components
import Template from './Template';
import Flowter from './widget/Flowter';

// Styles
import './css/flowterTemplates.scss';

const CATEGORIES = ['E-Commerce', 'Start-Ups', 'Marketing', 'Basics', 'Spezifisches'];
// Template index matches category index
const TEMPLATES = [ecommerce, startups, marketing, basics, specific];

function FlowterTemplates() {
  const { activeQuestion } = useSelector((state) => state.flowterCreator);

  const [activeTemplate, setActiveTemplate] = useState(-1);
  const [activeCategory, setActiveCategory] = useState(-1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setWidget = (widget) => dispatch({ type: 'SET_WIDGET', widget });
  const clearCreator = () => dispatch({ type: 'CLEAR_CREATOR' });

  useEffect(() => {
    dispatch(clearFlowterCreator());
    clearCreator();
  }, []);

  useEffect(() => {
    if (activeTemplate === -1) {
      return;
    }

    const template = TEMPLATES[activeCategory][activeTemplate];

    dispatch(setFlowter(template));
    setWidget(template);
  }, [activeTemplate]);

  const checkAndSetActiveTemplate = (category, templateId) => {
    if (category === activeCategory && templateId === activeTemplate) {
      // close template
      setActiveCategory(-1);
      setActiveTemplate(-1);
    } else {
      setActiveCategory(category);
      setActiveTemplate(templateId);
    }
  };

  const loadTemplates = (categoryIndex) => TEMPLATES[categoryIndex].map((flowter, index) => (
    <div className="mt10 pt10 pb10 column is-10" key={index}>
      <Template
        template={flowter}
        id={index}
        category={categoryIndex}
        setActiveTemplate={checkAndSetActiveTemplate}
        isActive={categoryIndex === activeCategory && index === activeTemplate}
      />
    </div>
  ));

  const _resetPreview = () => {
    dispatch(resetPreview());
  };

  const resetAndGoToCreator = () => {
    clearCreator();
    dispatch(clearFlowterCreator());
    navigate(FLOWTER_CREATOR);
  };

  // Show all available template categories
  const loadCategories = () => CATEGORIES.map((category, categoryIndex) => (
    <div className="column" key={categoryIndex}>
      <p className="mt30 mb30 has-text-centered has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch">
        {category}
      </p>
      <div className="columns is-centered is-mobile">
        { loadTemplates(categoryIndex) }
      </div>
      <div className="mt40 has-background-grey-light lines" />
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>Flowdust - Vorlagen</title>
      </Helmet>
      <div className="has-background-white" id="flowterTemplates">
        <div className="columns is-marginless is-paddingless has-fullheight">
          <div className="column is-4-desktop is-12-tablet p0 has-overflow-y-auto" id="flowterTemplatesContainer">
            <p className="title has-text-pastel has-text-weight-light is-size-3 is-size-4-mobile has-text-centered has-text-weight-bold pt30">
              Vorlagen
            </p>
            <div className="columns is-centered">
              <div className="column has-text-centered is-10">
                <p className="has-text-grey has-text-weight-light has-text-centered">Hier findest Du Vorlagen zu verschiedenen Themengebieten. Klicke, um sie in der Vorschau anzeigen zu lassen.</p>
              </div>
            </div>
            { loadCategories() }
            <div className="mt10 mb20 has-text-centered">
              <button
                onClick={resetAndGoToCreator}
                className="p5 pl10 pr10 cleanButton br22 is-size-6 has-text-weight-bold has-hover"
                type="button"
              >
                <p className="is-inline mr5">
                  Mit leerem Flowter beginnen
                </p>
                <i className="is-inline fas fa-chevron-right has-text-pastel" />
              </button>
            </div>
          </div>
          <Preview
            showResetButton={activeQuestion !== 0}
            resetWidget={_resetPreview}
          >
            <Flowter />
          </Preview>
        </div>
      </div>
    </>
  );
}

export default FlowterTemplates;
