import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const Template = (props) => {
  const {
    templateData, onSelect,
  } = props;

  const {
    thumbnail, name, descriptionShort,
  } = templateData;

  return (
    <div className="is-inline-block my-2">
      <button
        type="button"
        className="cleanButton boxshadow br10 has-min-width-300 has-max-width-350 has-height-300 has-cursor-pointer grow is-flex flex-direction-column has-text-left p-0 is-size-6"
        onClick={onSelect}
      >
        <img
          src={thumbnail}
          alt=""
          className="has-fullwidth has-height-200"
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
        />

        <div className="has-border-top-grey-light has-background-white has-fullheight has-fullwidth p-3 br10">
          <p className="has-text-weight-bold has-text-black">
            { name }
          </p>
          <p className="is-size-7 mt-1 has-text-grey-dark">
            { descriptionShort }
          </p>

        </div>

      </button>
    </div>
  );
};

Template.propTypes = {
  templateData: PropTypes.shape({
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    descriptionShort: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Template.defaultProps = {
  disabled: false,
};

export default Template;
