import React, { useLayoutEffect, useRef } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
// import EmojiPicker from './EmojiPicker';

// Styles
/* eslint import/no-webpack-loader-syntax: off */
import innerStyles from '!!raw-loader!./innerStyles.scss';

const MAX_LETTERS = 140;

const TextArea = (props) => {
  const {
    setText, text, placeholder, type, showEmojis, setShowEmojis, color,
  } = props;

  const isTypeInput = type === 'input';

  const ref = useRef();

  const surrogatePairs = text.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
  const textLength = text.length - surrogatePairs.length;
  const lettersLeft = Math.max(0, MAX_LETTERS - textLength);

  const resize = () => {
    const area = ref.current;

    if (!area) {
      return;
    }

    // set height to 1px to be able to resize to content
    area.style.height = 'auto';
    area.style.height = `${area.scrollHeight + 2}px`;
  };

  useLayoutEffect(() => {
    resize();
  }, [text, showEmojis, type]);

  const setTextValue = (event) => {
    let val = event.target.value;

    if (isTypeInput) {
      // replace any new lines
      val = val.replace(/[\r\n\v]+/g, '');
    }

    // dont allow multiple spaces
    val = val.replace(/ +(?= )/g, '');

    // get exact length of current input value
    const pairs = val.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
    const length = val.length - pairs.length;

    if (length > MAX_LETTERS) {
      // reset the text in the textarea
      if (ref.current) {
        ref.current.value = text;
      }
      return;
    }

    setText(val);
  };

  const addEmoji = (emoji) => {
    if (textLength >= MAX_LETTERS) {
      // reset the text in the textarea
      if (ref.current) {
        ref.current.value = text;
      }
      return;
    }

    // focus textarea after picking an emoji
    if (ref.current) {
      ref.current.focus();
    }

    setText(text + emoji);
  };

  /*
  <EmojiPicker
          addEmoji={addEmoji}
          showEmojis={showEmojis}
          setShowEmojis={setShowEmojis}
          color={color}
        />
  */
  return (
    <>
      <style>
        { innerStyles }
      </style>
      <div className="TextArea_textAreaWrapper">
        <div className={isTypeInput ? 'TextArea_wrapper_input' : 'TextArea_wrapper_textArea'}>
          <textarea
            rows={1}
            onChange={setTextValue}
            type="text"
            value={text}
            placeholder={placeholder}
            className="TextArea_textArea"
            ref={ref}
          />
          {
            !isTypeInput
            && (
            <p className="TextArea_letterDisplay">
              {lettersLeft}
            </p>
            )
          }
        </div>
      </div>
    </>
  );
};

TextArea.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  setText: PropTypes.func.isRequired,
  type: PropTypes.string,
  showEmojis: PropTypes.bool,
  setShowEmojis: PropTypes.func,
  color: PropTypes.string.isRequired,
};

TextArea.defaultProps = {
  placeholder: 'Deine Antwort',
  type: 'area',
  showEmojis: false,
  setShowEmojis: () => {},
};

export default TextArea;
