import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as routes from 'constants/routes.js';
import { Link, useLocation } from 'react-router-dom';
import stats from 'analytics/analytics';

import { useAuth0 } from '@auth0/auth0-react';

import CookieSettings from './CookieSettings';

import './css/cookieButton.scss';

function CookieButton(props) {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  const [showSettings, setShowSettings] = useState(false);

  if (isAuthenticated || location.pathname === routes.PRIVACY_POLICY || window.navigator.userAgent === 'ReactSnap') {
    return null;
  }

  const { showCookieBox } = useSelector((state) => state.cookieState);

  const dispatch = useDispatch();
  const setShowCookieBox = (setting) => dispatch({ type: 'SET_SHOW_COOKIE_BOX', setting });
  const setStatCookies = (setting) => dispatch({ type: 'SET_STATISTIC_COOKIES', setting });

  const toggleCookieSettings = () => {
    stats.push(['trackEvent', 'Cookies', `Toggle settings to ${!showSettings}`]);
    setShowSettings(!showSettings);
  };

  const rejctAllCookies = () => {
    stats.push(['forgetConsentGiven']);
    stats.push(['trackEvent', 'Cookies', 'Reject all']);

    // Reject all Cookies
    setStatCookies(false);
    // Hide Cookie Box
    setShowCookieBox(false);
  };

  const allowAllCookies = () => {
    stats.push(['rememberConsentGiven']);
    stats.push(['trackEvent', 'Cookies', 'Allow all']);

    // Activate all Cookies
    setStatCookies(true);
    // Hide Cookie Box
    setShowCookieBox(false);
  };

  const hideCookieBox = () => {
    stats.push(['trackEvent', 'Cookies', 'Hide Settings']);
    // Hide Cookie Box
    setShowCookieBox(false);
  };

  return (
    showCookieBox
      && (
        <div id="cookieOverlay">
          <div id="cookieBackground" />
          <div id="cookieButtonWrapper" className="columns is-marginless is-vcentered is-centered is-mobile">
            <div id="cookieButton" className="column is-6-desktop is-6-tablet is-10-mobile py-6 px-5">
              {
                showSettings
                && (
                <div className="has-text-left mb-3">
                  <button
                    className="button is-size-7"
                    type="button"
                    onClick={hideCookieBox}
                  >
                    Schließen
                  </button>
                </div>
                )
              }
              <div>
                <p className="is-size-6 is-inline">
                  {'Wir verwenden Cookies auf unserer Website. Manche von ihnen sind essenziell, während andere uns helfen Flowdust und dein Nutzererlebnis zu verbessern. '}
                </p>
                <Link
                  to={routes.PRIVACY_POLICY}
                  className="is-inline has-text-primary"
                >
                  Mehr anzeigen
                </Link>
              </div>
              <div className="columns is-marginless has-fullwidth is-mobile is-centered mt-5">
                <div className="column has-text-right has-text-centered-desktop">
                  <button
                    className="button is-primary has-fullwidth br10 has-text-weight-bold has-text-white has-fullheight"
                    type="button"
                    onClick={() => allowAllCookies()}
                  >
                    Cookies erlauben
                  </button>
                </div>
                <div className="column has-text-right has-text-centered-desktop">
                  <button
                    className="button is-primary has-fullwidth br10 has-text-weight-bold has-text-white has-fullheight"
                    type="button"
                    onClick={() => rejctAllCookies()}
                  >
                    Cookies ablehnen
                  </button>
                </div>
                <div className="column has-text-centered align-self-center">
                  <button
                    className="button"
                    type="button"
                    onClick={() => toggleCookieSettings()}
                  >
                    <i className="fas fa-cog has-text-grey" />
                    <p className="is-inline ml5 has-text-grey">Einstellungen</p>
                  </button>
                </div>
              </div>
              {
                showSettings
                && <CookieSettings />
              }
            </div>
          </div>
        </div>
      )
  );
}

export default CookieButton;
