import React, { useEffect } from 'react';

// Libraries
import { Auth0Provider } from '@auth0/auth0-react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
// eslint-disable-next-line
//import { createBrowserHistory } from 'history';
import stats from 'analytics/analytics';
import setupAxiosInterceptor from './middleware/axios';
import * as routes from './constants/routes';
import { store, persistor } from './store';
import useDataLoading from './components/hooks/useDataLoading';

// Components
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import Billing from 'components/billing/Billing';
import NotFound from 'components/layout/NotFound';
import PaymentMethodSelector from 'components/billing/PaymentMethodSelector';
import Faq from 'components/layout/Faq';
import FeedbackHub from 'components/layout/FeedbackHub';
import PrivacyPolicy from 'components/layout/PrivacyPolicy';
import UseCases from 'components/layout/UseCases';
import ScrollToTop from 'components/utils/ScrollToTop';
import Login from 'components/auth/Login';
import LoadingLayout from 'components/utils/LoadingLayout';
import CreatorMainHub from 'components/widgets/CreatorMainHub';
import FlowterInfo from 'components/layout/infoPages/widgets/Flowter';
import ManageSubscription from 'components/billing/subscription/ManageSubscription';
import CancelSubscription from 'components/billing/subscription/CancelSubscription';
import CustomerInformation from 'components/settings/CustomerInformation';
import ChangeSubscriptionPlan from 'components/billing/subscription/ChangeSubscriptionPlan';
import Navigation from './components/layout/Navigation';
import NavigationBar from './components/layout/NavigationBar';
import SignUp from './components/auth/SignUp';
import Home from './components/home/Home';
import Checkout from './components/checkout/Checkout';
import Support from './components/support/Support';
import Landing from './components/layout/landing/Landing';
import Partner from './components/layout/Partner';
import Press from './components/layout/Press';
import Affiliate from './components/layout/Affiliate';
import Impressum from './components/layout/Impressum';
import AboutUs from './components/layout/AboutUs';
import PersonalizedRedirect from './components/layout/PersonalizedRedirect';
import AddWebsite from './components/websites/AddWebsite';
import EditWebsite from './components/websites/EditWebsite';
import CookieButton from './components/cookies/CookieButton';
import Privacy from './components/settings/Privacy/Privacy';
import TermsOfService from './components/layout/TermsOfService';
import Onboarding from './components/onboarding/Onboarding';
import FlowterTemplates from './components/flowter/creator/FlowterTemplates';
import InstallationContainer from './components/layout/InstallationContainer';
import FlowterDemo from './components/flowter/FlowterDemo';
import Demo from './components/layout/Demo';
import { Helmet, HelmetProvider } from 'react-helmet-async';

/* Flowter */
import FlowterCreatorContainer from './components/flowter/creator/FlowterCreatorContainer';
import FlowterStatistics from './components/flowter/statistics/StatisticWrapper';
import FlowterDashboard from 'components/flowter/FlowterDashboard';

/* Hub Components */
import HubTemplateGallery from 'components/hub/HubTemplateGallery';
import HubContentEditor from './components/hub/HubContentEditor';
import HubProfileEditor from './components/hub/HubProfileEditor';
import HubCreator from './components/hub/HubCreator';
import HubList from './components/hub/HubList';
import HubStatistic from 'components/hub/HubStatistic';
import HubEditorSelector from 'components/hub/HubEditorSelector';
import HubDemoEditor from 'components/hub/HubDemoEditor';
import HubTemplatePage from 'components/hub/HubTemplatePage';

import { LOGIN } from './constants/routes';

// Styles
import 'css/style.scss';
import 'css/animations.scss';

// Import icons here because on building, react does not like fontawesome files
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';

// Initialize languages
import './locales/i18n';

// const history = stats.connectToHistory(createBrowserHistory());

const onRedirectCallback = (appState) => {
  navigate(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

// stats.push(['requireConsent']);
stats.push(['enableHeartBeatTimer']);
stats.push(['trackPageView']);

function App() {
  useEffect(() => {
    setupAxiosInterceptor();
  }, []);

  /*
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      useCookiesForTransactions
    >
  */

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + LOGIN,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <Helmet>
              <title>Flowdust - Deine Plattform für Online Umfragen, Formulare und Feedback Management</title>
            </Helmet>
            <AppComponent />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>

  );
}

function AppComponent() {
  const { isLoading } = useDataLoading();
  return (
    isLoading ? <LoadingLayout /> : <AllRoutes />
  );
}

/*
<Route
  exact
  path={routes.HUB_DATA_ID}
  element={<HubEditor />}
/>
<Route
            exact
            path={routes.STATUSWIDGET_CREATOR}
            element={<StatusWidgetCreatorContainer />}
          />

          <Route
            exact
            path={routes.STATUSWIDGET_CREATOR_EDIT}
            element={<StatusWidgetCreatorContainer />}
          />
          <Route
            exact
            path={routes.STATUSWIDGET_INFO}
            element={<StatusWidgetInfo />}
          />
*/

function AllRoutes() {
  return (
    <Router>
      <ReactNotifications />
      <ScrollToTop />
      <NavigationBar />
      <div className="columns is-marginless is-fullheight has-overflow-hidden">
        <Navigation />
        <div className="column is-paddingless has-fullwidth">
          <Routes>
            <Route
              exact
              path={routes.LANDING}
              element={<Landing />}
            />
            <Route
              exact
              path={routes.FLOWTER_INFO}
              element={<FlowterInfo />}
            />
            <Route
              exact
              path={routes.LOGIN}
              element={<Login />}
            />
            <Route
              exact
              path={routes.SIGN_UP}
              element={<SignUp />}
            />
            <Route
              exact
              path={routes.CUSTOMER_INFO_SETTINGS}
              element={<CustomerInformation />}
            />
            <Route
              exact
              path={routes.HOME}
              element={<Home />}
            />
            <Route
              exact
              path={routes.DEMO}
              element={<Demo />}
            />
            <Route
              exact
              path={routes.BILLING}
              element={<Billing />}
            />
            <Route
              exact
              path={routes.CHANGE_PAYMENT_METHOD}
              element={<PaymentMethodSelector />}
            />
            <Route
              exact
              path={routes.MANAGE_SUBSCRIPTION}
              element={<ManageSubscription />}
            />
            <Route
              exact
              path={routes.CANCEL_SUBSCRIPTION}
              element={<CancelSubscription />}
            />
            <Route
              exact
              path={routes.CHANGE_SUBSCRIPTION_PLAN}
              element={<ChangeSubscriptionPlan />}
            />
            <Route
              exact
              path={routes.CHECKOUT}
              element={<Checkout />}
            />
            <Route
              exact
              path={routes.ADD_WEBSITE}
              element={<AddWebsite />}
            />
            <Route
              exact
              path={routes.EDIT_WEBSITE}
              element={<EditWebsite />}
            />

            <Route
              exact
              path={routes.HUB_TEMPLATES}
              element={<HubTemplateGallery />}
            />

            <Route
              exact
              path={routes.HUB_TEMPLATE_ID}
              element={<HubTemplatePage />}
            />
            <Route
              exact
              path={routes.HUB_PROFILE_EDIT}
              element={<HubProfileEditor />}
            />
            <Route
              exact
              path={routes.HUB_CONTENT_EDIT}
              element={<HubContentEditor />}
            />
            <Route
              exact
              path={routes.HUB_EDITOR_SELECTOR}
              element={<HubEditorSelector />}
            />
            <Route
              exact
              path={routes.HUB_DEMO_EDITOR}
              element={<HubDemoEditor />}
            />
            <Route
              exact
              path={routes.HUB_PROFILE}
              element={<HubCreator />}
            />
            <Route
              exact
              path={routes.HUB_LIST}
              element={<HubList />}
            />
            <Route
              exact
              path={routes.HUB_STATISTIC_ID}
              element={<HubStatistic />}
            />
            <Route
              exact
              path={routes.FLOWTER_CREATOR_EDIT}
              element={<FlowterCreatorContainer />}
            />
            <Route
              exact
              path={routes.FLOWTER_CREATOR}
              element={<FlowterCreatorContainer />}
            />

            <Route
              exact
              path={routes.FLOWTER_LIST}
              element={<FlowterDashboard />}
            />

            <Route
              exact
              path={routes.WIDGET_CREATOR_START}
              element={<CreatorMainHub />}
            />
            <Route
              exact
              path={routes.WIDGET_CREATOR_START_WITH_TYPE}
              element={<CreatorMainHub />}
            />
            <Route
              exact
              path={routes.FLOWTER_DEMO}
              element={<FlowterDemo />}
            />
            <Route
              exact
              path={routes.FLOWTER_TEMPLATES}
              element={<FlowterTemplates />}
            />
            <Route
              exact
              path={routes.FLOWTER_DATA_ID}
              element={<FlowterStatistics />}
            />

            <Route
              exact
              path={routes.SUPPORT}
              element={<Support />}
            />
            <Route
              exact
              path={routes.NEW_USER}
              element={<Onboarding />}
            />
            <Route
              exact
              path={routes.REDIRECT}
              element={<PersonalizedRedirect />}
            />
            <Route
              exact
              path={routes.IMPRESSUM}
              element={<Impressum />}
            />
            <Route
              exact
              path={routes.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path={routes.PRIVACY_SETTINGS}
              element={<Privacy />}
            />
            <Route
              exact
              path={routes.TERMS_OF_SERVICE}
              element={<TermsOfService />}
            />
            <Route
              exact
              path={routes.SCRIPT_INSERT_CONTAINER}
              element={<InstallationContainer />}
            />
            <Route
              exact
              path={routes.FAQ}
              element={<Faq />}
            />
            <Route
              exact
              path={routes.HUB}
              element={<FeedbackHub />}
            />
            <Route
              exact
              path={routes.USE_CASES}
              element={<UseCases />}
            />
            <Route
              exact
              path={routes.PARTNER}
              element={<Partner />}
            />
            <Route
              exact
              path={routes.PRESS}
              element={<Press />}
            />
            <Route
              exact
              path={routes.ABOUT_US}
              element={<AboutUs />}
            />
            <Route
              exact
              path={routes.AFFILIATE}
              element={<Affiliate />}
            />
            <Route
              path="*"
              render={() => <NotFound />}
            />
          </Routes>
        </div>
        <CookieButton />
      </div>
    </Router>
  );
}

export default App;
