import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Components
import NestedSelector from '../../NestedSelector';

// Actions
import { setFilter } from 'reducer/hubs';

const BrowserFilter = () => {
  const { analytics, filter } = useSelector((state) => state.hubs.statistic);
  const dispatch = useDispatch();

  const { browser } = analytics;

  const allBrowser = useMemo(() => browser.map((_browser) => ({
    label: _browser.name,
    values: _browser.versions.map(({ version }) => ({
      label: 'Version',
      value: version,
    })),
  })), []);

  const _setFilter = (newFilter) => {
    dispatch(setFilter({ browser: newFilter }));
  };

  return (
    <div className="field has-width-280 has-margin-auto">
      <p className="is-inline has-text-weight-semibold">Browser</p>
      <i className="mx-2 is-inline fas fa-globe" />
      <NestedSelector
        selectedItems={filter.browser}
        setSelection={_setFilter}
        allItems={allBrowser}
        name="Browser"

      />
    </div>

  );
};

export default BrowserFilter;
