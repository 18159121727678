import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Select from 'react-select';

const styles = {
  menu: (base) => ({
    ...base,
    borderRadius: '10px',
  }),
  control: (base) => ({
    ...base,
    cursor: 'pointer',
    borderRadius: '10px',
  }),
  menuList: (base) => ({
    ...base,
    padding: '0px',
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
};

const Dropdown = (props) => {
  const {
    labels, value, onChange, isSearchable, renderLabel, noOptionsMessage, isMulti, placeholder, isDisabled, hasIndicator, className,
  } = props;

  return (
    <div className={className}>
      <Select
        value={value}
        isMulti={isMulti}
        isDisabled={isDisabled}
        formatOptionLabel={renderLabel}
        components={hasIndicator ? { } : { IndicatorSeparator: () => null }}
        options={labels}
        onChange={onChange}
        isSearchable={isSearchable}
        maxMenuHeight={300}
        menuPlacement="auto"
        placeholder={placeholder}
        styles={styles}
        noOptionsMessage={noOptionsMessage}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: '#ffffff',
            primary25: '#fcb992',
            primary50: '#fcb992',
            primary75: '#fcb992',
            primary: '#fcb992',
            cursor: 'pointer',
          },
        })}
      />
    </div>
  );
};

const defaultLabelRender = ({ label }) => (
  <div>
    <p className="is-inline has-text-weight-semibold is-size-7 has-text-black">
      {label}
    </p>
  </div>
);

Dropdown.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
    ),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,

  isSearchable: PropTypes.bool,
  renderLabel: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  hasIndicator: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  isSearchable: false,
  renderLabel: defaultLabelRender,
  noOptionsMessage: () => {},
  isMulti: false,
  hasIndicator: true,
  isDisabled: false,
  placeholder: '',
  className: 'has-min-width-200',
};

export default Dropdown;
