import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import TypeIndicator from './TypeIndicator';

const QuestionDisplay = (props) => {
  const { question, index } = props;
  const {
    text, media, options, isOptional, allowText, type,
  } = question;

  const [showDetails, setShowDetails] = useState(false);

  const renderedOptions = useMemo(() => {
    if (options.length === 0) return null;

    const _options = options.map((option, optionIndex) => {
      const content = option.text ? `${optionIndex + 1}. ${option.text}` : `${optionIndex + 1}. Option`;

      return (
        <p
          key={optionIndex}
          className="is-size-7 my-1 has-text-weight-bold"
        >
          { content }
        </p>
      );
    });

    return (
      <div className="mb-2">
        <p className="has-text-grey-darker is-size-7 mb-2">Auswahlmöglichkeiten:</p>
        { _options }
      </div>
    );
  }, [options]);

  return (
    <div className="mb-3">
      <button
        type="button"
        onClick={() => setShowDetails(!showDetails)}
        className="has-no-border has-cursor-pointer has-text-left box p-3 boxshadow br10 has-fullwidth"
      >
        <div className="columns m0 is-mobile is-multiline">
          <div className="column is-flex has-content-centered-vertically flex-direction-column">
            <p className="is-size-7 has-text-weight-bold has-text-black">
              {`${index + 1}. ${text}`}
            </p>
            <div className="has-text-left has-fullwidth mt-1">
              { isOptional
                && (
                  <p
                    className="is-size-7 has-text-grey"
                  >
                    (Frage ist optional)
                  </p>
                )}
            </div>
          </div>
          <div className="column is-narrow is-flex flex-direction-column has-text-right">
            <TypeIndicator type={type} />
            <div
              className="cleanButton has-margin-top-auto p-0 has-text-right mt-3"
            >
              <i className={`is-size-7 has-text-grey fas fa-chevron-${showDetails ? 'up' : 'down'}`} />
            </div>
          </div>
        </div>
        {
        showDetails && (
          <div className="">

            <div className="px-4">
              { renderedOptions }

              <div className="is-size-7">
                { allowText
                && <p>Nutzer haben die Möglichkeit einen Text einzugeben</p>}
                { isOptional
                && <p>Nutzer können die Frage überspringen</p>}
              </div>
            </div>

          </div>
        )
      }
      </button>

    </div>
  );
};

QuestionDisplay.propTypes = {
  question: PropTypes.shape({
    isOptional: PropTypes.bool,
    allowText: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default QuestionDisplay;
