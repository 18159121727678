import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

const LinkButton = (props) => {
  const {
    text, url, onClick, color,
  } = props;

  return (
    <Button
      url={url}
      text={text}
      onClick={onClick}
      styles={{ backgroundColor: color }}
    >
      <i className="fas fa-external-link-alt" />
    </Button>
  );
};

LinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

LinkButton.defaultProps = {
  color: '#ff914d',
};

export default LinkButton;
