import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';

const BasicStatistic = (props) => {
  const { setDetails } = props;

  const { analytics } = useSelector((state) => state.hubs.statistic);
  const {
    browser, screen, device, os, cpu, lang,
  } = analytics;

  const showBasicBrowserAnalytics = () => {
    if (browser.length === 0) return null;

    const renderedBrowser = [];

    for (let i = 0; i < Math.min(5, browser.length); i++) {
      const { name, totalAmount } = browser[i];

      renderedBrowser.push(
        <div key={name} className="is-flex my-1">
          <p className="has-text-grey">
            { `${name}`}
          </p>
          <p className="has-text-weight-bold has-margin-left-auto">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            Aufrufe pro Browser
          </p>
          <button
            type="button"
            onClick={() => setDetails('browser')}
            className="cleanButton has-margin-left-auto has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>
        </div>

        <div className="mt-2">
          { renderedBrowser }
        </div>

      </div>
    );
  };

  const showScreenAnalytics = () => {
    if (screen.length === 0) return null;

    const renderedScreenSizes = [];

    for (let i = 0; i < Math.min(5, screen.length); i++) {
      const { width, height, totalAmount } = screen[i];

      renderedScreenSizes.push(
        <div key={i} className="is-flex">
          <p className="has-text-grey mr-1">
            { `${width}x${height}`}
          </p>
          <p className="has-margin-left-auto has-text-weight-bold">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            Aufrufe pro Bildschirmgröße
          </p>
          <button
            type="button"
            onClick={() => setDetails('screen')}
            className="has-margin-left-auto cleanButton has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>
        </div>

        <div className="mt-2">
          { renderedScreenSizes }
        </div>

      </div>
    );
  };

  const showDeviceAnalytics = () => {
    if (device.length === 0) return null;

    const renderedDevices = [];

    for (let i = 0; i < Math.min(5, device.length); i++) {
      const { amount, model } = device[i];

      renderedDevices.push(
        <div key={model} className="is-flex">
          <p className="has-text-grey">
            { `${model}`}
          </p>
          <p className="has-margin-left-auto has-text-weight-bold">
            { amount }
          </p>

        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            Aufrufe pro Gerät
          </p>

          <button
            type="button"
            onClick={() => setDetails('device')}
            className="has-margin-left-auto cleanButton has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>

        </div>

        <div className="mt-2">
          { renderedDevices }
        </div>

      </div>
    );
  };

  const showOsAnalytics = () => {
    if (os.length === 0) return null;

    const renderedOs = [];

    for (let i = 0; i < Math.min(5, os.length); i++) {
      const { name, totalAmount } = os[i];

      renderedOs.push(
        <div key={name} className="is-flex">
          <p className="has-text-grey mr-1">
            { `${name}`}
          </p>
          <p className="has-margin-left-auto has-text-weight-bold">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            Aufrufe pro Betriebssystem
          </p>
          <button
            type="button"
            onClick={() => setDetails('os')}
            className="has-margin-left-auto cleanButton has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>
        </div>

        <div className="mt-2">
          { renderedOs }
        </div>

      </div>
    );
  };

  const showCPUAnalytics = () => {
    if (cpu.length === 0) return null;

    const renderedCpus = [];

    for (let i = 0; i < Math.min(5, cpu.length); i++) {
      const { name, totalAmount } = cpu[i];

      renderedCpus.push(
        <div key={name} className="is-flex">
          <p className="has-text-grey">
            { `${name}`}
          </p>
          <p className="has-margin-left-auto has-text-weight-bold">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            CPU
          </p>
          <button
            type="button"
            onClick={() => setDetails('cpu')}
            className="has-margin-left-auto cleanButton has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>
        </div>

        <div className="mt-2">
          { renderedCpus }
        </div>

      </div>
    );
  };

  const showLangAnalytics = () => {
    if (lang.length === 0) return null;

    const renderedLangs = [];

    for (let i = 0; i < Math.min(5, lang.length); i++) {
      const { name, totalAmount } = lang[i];

      renderedLangs.push(
        <div key={name} className="is-flex">
          <p className="has-text-grey">
            { `${name}`}
          </p>
          <p className="has-margin-left-auto has-text-weight-bold">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="mt-4 mr-3">

        <div className="is-flex">
          <p className="has-text-black has-text-weight-bold">
            Sprachen
          </p>
          <button
            type="button"
            onClick={() => setDetails('lang')}
            className="has-margin-left-auto cleanButton has-text-weight-bold p-0 has-text-pastel"
          >
            Details
          </button>
        </div>

        <div className="mt-2">
          { renderedLangs }
        </div>

      </div>
    );
  };

  /* const showDarkmodeAnalytics = () => {
    if (isDarkmode.length === 0) return null;

    const renderedTypes = [];

    for (let i = 0; i < Math.min(5, isDarkmode.length); i++) {
      const { type, totalAmount } = isDarkmode[i];

      renderedTypes.push(
        <div key={type}>
          <p className="has-text-grey is-inline mr-1">
            { `${type ? 'Angeschaltet' : 'Ausgeschaltet'}:`}
          </p>
          <p className="is-inline has-text-weight-bold">
            { totalAmount }
          </p>
        </div>,
      );
    }

    return (
      <div className="has-border-bottom column is-8 mt-2">
        <p className="has-background-pastel-light pl-1 br5 has-text-white has-text-weight-semibold">
          Darkmode
        </p>
        <button
          type="button"
          onClick={() => setDetails('darkmode')}
          className="mt-2 mb-2 cleanButton has-text-underlined p-0 is-info"
        >
          Details
        </button>
        { renderedTypes }
      </div>
    );
}; */
  // {showDarkmodeAnalytics()}

  return (
    <div>
      {showBasicBrowserAnalytics()}
      {showScreenAnalytics()}
      {showDeviceAnalytics()}
      {showOsAnalytics()}
      {showCPUAnalytics()}
      {showLangAnalytics()}

    </div>
  );
};

BasicStatistic.propTypes = {
  setDetails: PropTypes.func.isRequired,
};

export default BasicStatistic;
