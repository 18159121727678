import React from 'react';

// Assets
import icon from 'assets/images/icon2.png';

const IconGuidelines = () => {
  const downloadIcon = () => {
    const link = document.createElement('a');
    link.href = icon;
    link.download = 'Flowdust_Logo.png';
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <div>
        <p
          className="has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold"
        >
          Unser Logo
        </p>
        <p
          className="mt-4"
        >
          Das Flowdust-Logo darf nicht bearbeitet, geändert, verzerrt, neu koloriert oder neu konfiguriert werden.
        </p>
        <div className="mt-5 has-text-centered-mobile">
          <div className="is-inline-block has-text-centered">
            <img className="" src={icon} alt="Flowdust Logo" />
            <button
              type="button"
              onClick={downloadIcon}
              className="button is-primary mt-3 is-block br10 has-margin-auto"
            >
              <i className="fas fa-download" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <p
          className="has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold mb-3"
        >
          Farben
        </p>
        <p
          className="mt-4 mb-5"
        >
          Die Farben auf unserer Website.
        </p>
        <div className="columns is-mobile">

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-pastel is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Orange
              </p>
              <p className="has-margin-top-auto has-text-black">
                #FF914D
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-danger is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Rot
              </p>
              <p className="has-margin-top-auto has-text-black">
                #F14668
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-yellow is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Gelb
              </p>
              <p className="has-margin-top-auto has-text-black">
                #FFCF89
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-info is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Blau
              </p>
              <p className="has-margin-top-auto has-text-black">
                #3E8ED0
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-dash is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Grau
              </p>
              <p className="has-margin-top-auto has-text-black">
                #F2F2F6
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-white is-flex flex-direction-column has-border has-margin-auto">
              <p
                className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Weiß
              </p>
              <p className="has-margin-top-auto has-text-black">
                #FFFFFF
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-midnightblue is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-white has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Dunkelblau
              </p>
              <p className="has-margin-top-auto has-text-white">
                #273443
              </p>
            </div>
          </div>

          <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile">
            <div className="box br10 has-height-200 has-width-200 has-background-black is-flex flex-direction-column has-margin-auto">
              <p
                className="has-text-white has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                Schwarz
              </p>
              <p className="has-margin-top-auto has-text-white">
                #000000
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default IconGuidelines;
