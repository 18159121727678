import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import MediaDisplay from 'components/hub/MediaDisplay';

const QuestionDisplay = (props) => {
  const { index } = props;

  const { t } = useTranslation(['hub']);

  const { content, id } = useSelector((state) => state.hubs.creator);
  const { questions } = content;
  const question = questions[index];

  if (!question) return null;

  const {
    text, media, isOptional,
  } = question;

  return (
    <div className="has-text-left my-2 has-max-fullwidth">
      <div className="has-text-black has-text-centered">
        <div className="mb-4">
          <p className="has-text-black show-linebreaks br5 is-size-5 has-text-weight-medium is-size-6-mobile">
            {text}
          </p>
          {
            isOptional
            && (
            <p className="has-text-grey">
              {`(${t('hub:optional')})`}
            </p>
            )
          }
        </div>
        { media.source && <MediaDisplay file={media} hubID={id} isCreator />}
      </div>
    </div>
  );
};

QuestionDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export default QuestionDisplay;
