import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

const Impressum = () => (
  <>
    <Helmet>
      <title>Flowdust - Impressum</title>
    </Helmet>

    <div className="column mt50">
      <div className="columns is-centered">
        <div className="column has-text-centered">
          <h2 className="title has-text-black has-text-weight-bold mt20">Impressum</h2>
        </div>
      </div>
      <div className="mt30 columns is-centered">
        <div className="pt30 pb30 box is-5 column  has-text-centered">
          <p className="has-text-weight-bold mb10">Flowdust Online Services UG (haftungsbeschränkt)</p>
          <p className="has-text-weight-light">Am Bahnhof 1</p>
          <p className="has-text-weight-light">95632 Wunsiedel</p>
          <p className="has-text-weight-light">Deutschland</p>
          <p className="has-text-weight-bold mt30 mb10">Geschäftsführer:</p>
          <p className="has-text-weight-light">Kevin Pollak</p>
          <p className="has-text-weight-light">Ariane Pollak</p>
          <p className="has-text-weight-light">(Anschrift wie oben)</p>
          <p className="has-text-weight-bold mt30 mb10">Kontakt:</p>
          <p className="has-text-weight-light">E-Mail: hello@flowdust.com</p>
          <p className="has-text-weight-bold mt30 mb10">Handelsregister:</p>
          <p className="has-text-weight-light">Registergericht: Amtsgericht Hof</p>
          <p className="has-text-weight-light">Registernummer: HRB 6045</p>
          <p className="has-text-weight-bold mt30 mb10">Umsatzsteuer:</p>
          <p className="has-text-weight-light">Umsatzsteuer-ID: DE325502448</p>
          <p className="has-text-weight-light">Steuernummer: 223/126/50596</p>
        </div>
      </div>
    </div>
  </>
);

export default (Impressum);
