import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector } from 'react-redux';

// HOCs
import withSubscription from 'components/hoc/withSubscription';

// Constants
import * as status from 'constants/status';

// Components
import ResumeSubscription from './ResumeSubscription';
import ManageSubscriptionPlan from './ManageSubscriptionPlan';

function ManageSubscription() {
  const user = useSelector((state) => state.sessionState.user);

  const showSubscriptionInfo = () => {
    switch (user.status) {
      case status.ACTIVE:
        return <ManageSubscriptionPlan />;
      case status.WILL_CANCEL:
        return <ResumeSubscription />;
      default: return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Flowdust - Abonnement verwalten
        </title>
      </Helmet>
      <div className="columns is-marginless">
        <div className="column pl20 pr20 py-6">
          <h2
            className="has-text-black has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile"
          >
            Abonnement verwalten
          </h2>
          <p className="mb-5">Hier findest Du alles, um dein Abonnement zu verwalten.</p>
          { showSubscriptionInfo() }
        </div>
      </div>
    </>
  );
}

export default withSubscription(ManageSubscription);
