import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getColor } from 'components/hub/HubStatistic/Statistic/palette';

// Components
import EmailResponse from './EmailResponse';
import SliderResponse from './SliderResponse';
import PollResponse from './PollResponse';
import SkippedDisplay from './SkippedDisplay';

/*
 * Response entry for one question, prepares the data for display and shows
 */
const ResponseDisplay = (props) => {
  const { responseData, questionData, index } = props;

  const { values, input, duration } = responseData;
  const {
    text: question, options, type,
  } = questionData;

  const renderedValues = useMemo(() => {
    const _renderedValues = [];

    // for each value, show the option / text
    // and the corresponding statistic color
    // usually its only one value, except POLL has maxNumberOfChecks > 1
    // so values looks something like [-1]
    values.forEach((value, valueIndex) => {
      const color = getColor(value);

      // show skipped message if user skipped the question
      if (value === -2) {
        _renderedValues.push(
          <SkippedDisplay
            color={color}
            key={valueIndex}
          />,
        );
        return;
      }

      if (type === 'EMAIL') {
        _renderedValues.push(
          <EmailResponse
            email={input}
            color={color}
            key={valueIndex}
          />,
        );
        return;
      }

      if (type === 'SLIDER') {
        // the slider stats are 4 buckets, so check in which bucket the selected value falls to be able to get the correct color for the log
        let bucketValue = -2;

        if (value >= 76) {
          bucketValue = 3;
        } else if (value >= 51) {
          bucketValue = 2;
        } else if (value >= 26) {
          bucketValue = 1;
        } else {
          bucketValue = 0;
        }

        const sliderColor = getColor(bucketValue);

        _renderedValues.push(
          <SliderResponse
            value={value}
            text={input}
            color={sliderColor}
            key={valueIndex}
          />,
        );
        return;
      }

      if (type === 'POLL') {
        let mediaData = {};
        let renderedText = input;

        // if an option has been chosen, get the data for it
        if (value >= 0) {
          const { text, media } = options[value];

          mediaData = media;
          renderedText = text;
        }

        _renderedValues.push(
          <PollResponse
            text={renderedText}
            media={mediaData}
            color={color}
            key={valueIndex}
          />,
        );
      }
    });

    return _renderedValues;
  }, [props]);

  return (
    <div className="mt-2">
      <p className="has-text-weight-bold has-text-black break-word">
        {`${index + 1}. ${question}`}
      </p>
      { renderedValues }
      <div>
        <p>{`Dauer: ${(duration / 1000).toFixed(2)}s`}</p>
      </div>
    </div>
  );
};

ResponseDisplay.propTypes = {
  responseData: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    input: PropTypes.string,
    duration: PropTypes.number,
  }).isRequired,
  questionData: PropTypes.shape({
    text: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      media: PropTypes.shape({}),
    })),
    type: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ResponseDisplay;
