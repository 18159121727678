import React from 'react';

// Libraries
import PropTypes from 'prop-types';

const LinkStatistic = ({ data }) => {
  const { links, totalAmount } = data;

  const showLinkData = () => links.map((linkData) => {
    const { link, amount } = linkData;

    return (
      <div>
        <p>
          {`${link}:`}
        </p>
        <p>
          {amount}
        </p>
      </div>
    );
  });
  return (
    <div>
      <p>{`Insgesamte Link Clicks: ${totalAmount}`}</p>
      { showLinkData() }
    </div>
  );
};

LinkStatistic.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      amount: PropTypes.number,
    })),
    totalAmount: PropTypes.number,
  }).isRequired,
};

export default LinkStatistic;
