import React, { useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { WIDGET_CREATOR_START } from 'constants/routes';

import stats from 'analytics/analytics';
import { setWidget, unsetWidget } from 'actions/websites';
import notification from 'utils/notification';

// Hooks
import useAPI from 'components/hooks/useAPI';

import Popup from 'reactjs-popup';

import PropTypes from 'prop-types';

function WidgetSelector(props) {
  const { website } = props;

  const [disabled, setDisabled] = useState(false);

  const { del, post } = useAPI();
  const dispatch = useDispatch();
  const popup = useRef();

  // All widgets
  const { hasData: hasFlowters, flowters } = useSelector((state) => state.flowterState);
  const { hasData: hasStatusWidgets, statusWidgets } = useSelector((state) => state.statusWidgetState);

  const closePopup = () => {
    if (!popup.current) {
      return;
    }

    popup.current.close();
  };

  /**
     * Set a Widget to a website
     * @param {String} widgetId id of the widget that gets set as active
     * @param {Number} type type of the widget (0 = flowter, 1 = status)
     */
  const setWidgetToWebsite = async (widget) => {
    try {
      // If the website has a widget and its the same we want to set
      // don't change anything
      const widgetNow = website.widgets[0];
      // only send a request if it's a different widget
      if (widgetNow && widgetNow.id === widget._id) {
        return;
      }

      const data = {
        widgetId: widget._id,
        websiteId: website._id,
        widgetType: widget.type,
      };

      const widgetID = await post('/websites/set', data);

      dispatch(setWidget(website._id, widgetID));

      stats.push(['trackEvent', 'Website', 'Flowter set']);

      closePopup();

      notification.send('Fertig!', 'Dein Flowter wurde mit deiner Website verlinkt.', 'success');
    } catch (err) {
      console.log(err);
      notification.send('Das hat nicht funktioniert', 'Der Flowter konnte leider nicht hinzugefügt werden.', 'danger');
    }
  };

  function WidgetLayout({ widget, children }) {
    return (
      <div
        className="columns is-marginless is-paddingless is-mobile"
      >
        <button
          className="column cleanButton p5 pl10 pr10 br10 has-text-black has-text-weight-semibold has-hover-grey-lighter abbreviate has-text-left"
          onClick={() => setWidgetToWebsite(widget)}
          type="button"
        >
          <p className="is-size-7">{widget.meta.name}</p>
          { children }
        </button>
      </div>
    );
  }

  WidgetLayout.propTypes = {
    children: PropTypes.node.isRequired,
    widget: PropTypes.shape({ meta: PropTypes.shape({ name: PropTypes.string }) }).isRequired,
  };

  const renderedFlowters = useMemo(() => {
    if (!hasFlowters) return null;
    const data = flowters.sort((a, b) => {
      if (a.meta.createdAt > b.meta.createdAt) {
        return -1;
      }

      return 1;
    });

    return data.map((flowter) => (
      <WidgetLayout widget={flowter} key={flowter._id}>
        <p className="has-text-grey is-size-7">
          {`${flowter.questions.length} Frage${flowter.questions.length === 1 ? '' : 'n'}`}
        </p>
      </WidgetLayout>
    ));
  }, [flowters, website.widgets]);

  const renderedStatusWidgets = useMemo(() => {
    if (!hasStatusWidgets) return null;

    const data = statusWidgets.sort((a, b) => {
      if (a.meta.createdAt > b.meta.createdAt) {
        return -1;
      }

      return 1;
    });

    return data.map((statusWidget) => (
      <WidgetLayout widget={statusWidget} key={statusWidget._id}>
        <p className="has-text-grey is-size-7">
          {`${statusWidget.meta.shown}x angezeigt`}
        </p>
      </WidgetLayout>
    ));
  }, [statusWidgets, website.widgets]);

  // const hasWidgetsToSelect = hasFlowters || hasStatusWidgets;
  const hasWidgetsToSelect = hasFlowters || hasStatusWidgets;

  const name = useMemo(() => {
    // When the website has no widget active
    let widgetName = 'Wähle einen Flowter';

    if (website.widgets.length === 0) {
      return widgetName;
    }

    const widgetId = website.widgets[0].id;
    const { type } = website.widgets[0];

    let widgetArray;
    switch (type) {
      case 0:
        widgetArray = flowters;
        break;
      case 1:
        widgetArray = statusWidgets;
        break;
      default:
        widgetArray = [];
    }

    widgetArray = widgetArray || [];
    const correctWidget = widgetArray.find((widget) => widget._id === widgetId);

    if (correctWidget) {
      widgetName = correctWidget.meta.name;
    }

    return widgetName;
  }, [website.widgets]);

  const unsetWidgetFromWebsite = async () => {
    try {
      setDisabled(true);

      const id = website._id;

      await del(`/websites/set/${id}`);

      stats.push(['trackEvent', 'Website', 'Website Flowter unset']);

      dispatch(unsetWidget(id));

      setDisabled(false);

      closePopup();

      notification.send('Fertig!', 'Diese Website hat nun keinen Flowter mehr.', 'success');
    } catch (err) {
      setDisabled(false);

      if (!err.response) {
        notification.send('Hoppla!', 'Flowter konnte leider nicht hinzugefügt werden.', 'danger');
        return;
      }

      if (err.response.status === 500) {
        notification.send('Server Fehler.', 'Bitte versuche es später noch einmal.', 'danger');
        return;
      }

      notification.send('Fehler!', err.response.data, 'danger');
    }
  };

  const showFlowter = () => {
    if (!hasFlowters) {
      return null;
    }

    return (
      <DropdownItem name="Flowter" items={renderedFlowters} />
    );
  };

  const showStatusWidgets = () => {
    if (!hasStatusWidgets) {
      return null;
    }

    return (
      <DropdownItem name="Statusmeldungen" items={renderedStatusWidgets} />
    );
  };

  if (!hasWidgetsToSelect) {
    return (
      <div className="has-text-centered">
        <p className="is-size-7">Du hast bisher noch keinen Flowter erstellt!</p>
        <Link
          to={WIDGET_CREATOR_START}
          className="button grow is-pastel is-rounded has-text-weight-bold mt10 mb10 is-size-7 has-fullheight"
        >
          Neuen Flowter erstellen
        </Link>
      </div>
    );
  }

  const hasActiveWidget = !!website.widgets.length;

  return (
    <Popup
      trigger={(
        <button
          className="has-hover-grey-light has-background-grey-lighter has-fullwidth has-no-border py-3 px-3 br10 is-block has-cursor-pointer has-text-left"
          type="button"
        >
          <div className="columns is-marginless">
            <div className="column is-paddingless is-10">
              <p className="is-size-7 break-word">
                { name }
              </p>
            </div>
            <div className="column is-narrow has-text-right is-paddingless px-1">
              <i className="fas fa-chevron-down is-size-7" />
            </div>
          </div>
        </button>
      )}
      position="bottom center"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
      className="widgetSelectionWrapper"
      nested
      ref={popup}
    >
      {
           () => (
             <div className="columns is-marginless">
               <div className="column is-marginless has-background-white br20 widgetSelectionWrapper">
                 <p className="has-text-centered is-size-7 mb-2">Wähle deinen Flowter aus!</p>
                 <div className="my-2">
                   {renderedFlowters}
                 </div>

                 {
                       hasActiveWidget
                       && (
                       <button
                         className="button has-fullwidth has-background-grey-lighter is-size-7 has-text-weight-bold br10 has-hover-grey-light has-text-danger"
                         type="button"
                         onClick={() => unsetWidgetFromWebsite()}
                         disabled={disabled}
                       >
                         Flowter entfernen
                       </button>
                       )
                     }
               </div>
             </div>
           )
      }
    </Popup>
  );
}

WidgetSelector.propTypes = {
  website: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    widgets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.number,
    })).isRequired,
  }).isRequired,
};

function DropdownItem(props) {
  const { items, name } = props;

  const [showItems, setShowItems] = useState(false);

  const toggleItems = () => {
    setShowItems(!showItems);
  };

  return (
    <div className="mb-2">
      <button
        className="has-hover-grey-light has-background-grey-lighter has-fullwidth has-no-border py-3 px-3 mb-2 br10 is-block has-cursor-pointer has-text-left"
        type="button"
        onClick={toggleItems}
      >
        <div className="columns is-marginless">
          <div className="column is-paddingless is-10">
            <p className="is-size-7 break-word">
              {name}
            </p>
          </div>
          <div className="column is-narrow has-text-right is-paddingless px-1">
            <i className={`fas is-size-7 ${showItems ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
          </div>
        </div>
      </button>
      <div>
        { showItems && items }
      </div>
    </div>
  );
}

DropdownItem.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
};

export default WidgetSelector;
