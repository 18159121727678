import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import Chart from 'components/hub/HubStatistic/Statistic/Chart';
import MediaDisplay from 'components/hub/MediaDisplay';
import FeedbackDisplay from '../FeedbackDisplay';
import BasicQuestionStatistic from '../BasicQuestionStatistic';

// Hooks
import { useSelector } from 'react-redux';

const EmailStatistic = (props) => {
  const { index } = props;

  const { hubs, statistic: statisticObj } = useSelector((state) => state.hubs);
  const {
    _id, hubIndex, amountSubmissions, resultsPerQuestion,
  } = statisticObj;

  const questionData = hubs[hubIndex].content.questions[index];

  // prepare statistic data and return defaults if we dont have data
  const statisticData = useMemo(() => {
    // find results for this question
    const results = resultsPerQuestion.find((_results) => _results.question === index);
    // if we dont find any data for this question, return defaults
    if (!results) {
      return {
        values: [],
        avgDuration: 0,
        submissions: 0,
      };
    }

    return results;
  }, [resultsPerQuestion]);

  const [statistic, setStatistic] = useState([]);

  const { values, avgDuration, submissions } = statisticData;
  const {
    text, media, isOptional,
  } = questionData;

  useEffect(() => {
    if (values.length === 0) {
      setStatistic([]);
      return;
    }

    const data = [
      {
        x: 'Emails gesammelt', y: 0, index: 0, value: -1,
      },
      {
        x: 'Übersprungen', y: 0, index: 1, value: -2,
      },
    ];

    for (let i = 0; i < values.length; i++) {
      const { value, amount } = values[i];
      if (value === -1) {
        data[0].y = amount;
      } else if (value === -2) {
        data[1].y = amount;
      } else {
        continue;
      }
    }

    setStatistic(data);
  }, [statisticData]);

  // const hasEmails = statistic.length !== 0 && statistic[0].y > 0;
  // Only show the chart if the q is optional, otherwise there is no useful info on it
  const showChart = isOptional;

  // dont count skips as submissions
  const actualSubmissions = useMemo(() => {
    if (statistic.length === 0) return 0;

    if (!isOptional) return submissions;

    return submissions;
  }, [statistic]);

  const showCollectedEmails = () => {
    if (statistic.length === 0) return null;

    const amountMails = statistic[0].y;

    if (amountMails > 0) {
      return (
        <div>
          <p className="pb-2 has-text-weight-bold mt-5">
            {
              amountMails === 1
                ? `Bisher wurde ${amountMails} E-Mail gesammelt!`
                : `Bisher wurden ${amountMails} E-Mails gesammelt!`
            }
          </p>
          <FeedbackDisplay text="Gesammelte Mails:" index={index} />
        </div>
      );
    }

    return (
      <p className="pb-2 has-text-weight-bold">
        Bisher wurden noch keine E-Mails gesammelt!
      </p>
    );
  };

  return (
    <div className="box">
      <p className="mb-4 has-text-weight-semibold has-text-pastel has-text-left">Fragentyp: E-Mail</p>
      <h2 className="title br10 pt15 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey mb-4 px-3">
        {text}
      </h2>
      <MediaDisplay file={media} hubID={_id} />
      {
        isOptional
        && (
        <p className="mt-2 has-text-grey">
          Diese Frage ist optional und konnte übersprungen werden.
        </p>
        )
      }
      <BasicQuestionStatistic
        totalSubmissions={amountSubmissions}
        submissions={actualSubmissions}
        avgDuration={avgDuration}
      />
      {
        showChart && <Chart statistic={statistic} />
      }
      {
        showCollectedEmails()
      }
    </div>
  );
};

EmailStatistic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default EmailStatistic;
