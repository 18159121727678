const templates = [{
		questions: [{
				question: "Was können wir noch verbessern?",
				options: ["Kundensupport", "Produktqualität", "Versand", "Etwas anderes", "Nichts, ich bin zufrieden"],
				maxNumberOfChecks: 4,
			},
			{
				question: "Würde dir etwas fehlen, wenn es unseren Shop nicht mehr geben würde?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Kundenzufriedenheit",
	},

	{
		questions: [{
				question: "Hast Du vor dem Kauf genug Informationen über das Produkt erhalten?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Hatten wir die Auswahl nach der Du gesucht hast?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Was ist dir bei Produkten am Wichtigsten?",
				options: ["Preis", "Qualität", "Verfügbarkeit", "Auswahl", "Etwas anderes"],
				maxNumberOfChecks: 5,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Produktinformationen",
	},

	{
		questions: [{
				question: "Würdest Du bei uns erneut bestellen?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Würdest Du unseren Shop weiterempfehlen?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wurden deine Erwartungen erfüllt?",
				options: ["Übertroffen", "Erfüllt", "Nicht erfüllt"],
				maxNumberOfChecks: 1,
			},

		],
		endTxt: "Danke für's Mitmachen!",
		name: "Shop-Bewertung",
	},

	{
		questions: [{
				question: "Wie würdest Du das Preis-Leistungs-Verhältnis bewerten?",
				options: ["Sehr gut", "Angemessen", "Zu teuer"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Welche Zahlungsmethode bevorzugst du?",
				options: ["Kreditkarte", "Paypal", "Lastschrift", "Andere"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Wie empfindest Du den Zahlungsablauf?",
				options: ["Sehr einfach", "In Ordnung", "Verwirrend", "Sehr kompliziert"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Zahlung",
	},

	{
		questions: [{
				question: "Wie lange hat der Versand gedauert?",
				options: ["einige Tage", "eine Woche", "mehr als eine Woche"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Warum hast Du die Ware zurückgeschickt?",
				options: ["Artikel beschädigt", "Artikel unbrauchbar", "schlechte Qualität", "anderer Grund"],
				maxNumberOfChecks: 4,
			},
			{
				question: "War das Rücksenden der Ware ein Problem?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wartest Du auf Sale bevor Du bei uns bestellst?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Rückversand",
	},

	{
		questions: [{
				question: "Bist Du mit der Auswahl an Versandmöglichkeiten zufrieden?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Ist die Bestellung unversehrt und vollständig bei dir angekommen?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Mitmachen!",
		name: "Versand",
	},

	{
		questions: [{
				question: "Wie würdest Du die Qualität unserer Produkte bewerten?",
				options: ["Gut", "Schlecht", "Durchschnitt"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Nutzt Du bereits ähnliche Produkte?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},

			{
				question: "Wie häufig benutzt Du unsere Produkte?",
				options: ["Täglich", "Gelegentlich", "Selten", "Nie"],
				maxNumberOfChecks: 1,
			},

			{
				question: "Ersetzt Du ein altes Produkt mit einem von unseren?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

		],
		endTxt: "Danke für's Mitmachen!",
		name: "Produkte + Nutzung",
	},



	{
		questions: [{
				question: "Was ist dir bei unseren Produkten am Wichtigsten?",
				options: ["Preis", "Auswahl", "Qualität", "Verfügbarkeit", "Zahlungsarten"],
				maxNumberOfChecks: 6,
			},

			{
				question: "Ist die Auswahl der Produkte ausreichend?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},


			{
				question: "Nutzt Du bereits ähnliche Produkte?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},

			{
				question: "Hast Du vorher schon mal bei uns eingekauft?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

		],
		endTxt: "Danke für's Mitmachen!",
		name: "Produktangebot",
	},

	{
		questions: [{
				question: "Wie war dein Ersteindruck des Produkts?",
				options: ["Sehr negativ", "Negativ", "In Ordnung", "Positiv", "Sehr positiv"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wie würdest Du unser Produkt bewerten? (1 = Am Schlechtesten, 5 = Am Besten)",
				options: ["1", "2", "3", "4", "5"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Wie lange benutzt Du unsere Produkte schon?",
				options: ["Zum ersten Mal", "Weniger als 6 Monate", "6 Monate bis 1 Jahr", "Mehr als ein Jahr", "Ich weiß es nicht"],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für's Abstimmen!",
		name: "Produktbewertung",
	},


	{
		questions: [{
				question: "Wie findest Du die Übersicht unserer Artikel?",
				options: ["Gut", "In Ordnung", "Unübersichtlich"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Ist die Beschreibung der Produkte verständlich?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du die Produktvorschau ansprechend?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Konntest Du sofort finden, nach was Du gesuchst hast?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},


		],
		endTxt: "Danke für's Mitmachen!",
		name: "Übersicht Produkt",
	},

	{
		questions: [{
				question: "Wie empfindest Du die Navigation auf der Website?",
				options: ["Einfach", "Normal", "Schwierig"],
				maxNumberOfChecks: 1,
			},
			{
				question: "Konntest Du das Produkt, das Du kaufen wolltest schnell finden?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
			{
				question: "Findest Du die Website ansprechend?",
				options: ["Ja", "Nein"],
				maxNumberOfChecks: 1,
			},

			{
				question: "War es einfach, Artikel in den Warenkorb zu legen?",
				options: ["Ja", "Nein", ],
				maxNumberOfChecks: 1,
			},
		],
		endTxt: "Danke für deine Meinung!",
		name: "Übersicht Website",
	},









] // Ende Template Array

export default templates;
