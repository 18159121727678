import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import './css/template.scss';
import stats from 'analytics/analytics';

function Template(props) {
  const {
    template: initialTemplate, category, id: initialId, isActive, setActiveTemplate,
  } = props;
  const [showTemplate, setShowTemplate] = useState(false);
  const [template, setTemplate] = useState(initialTemplate);
  const [id, setId] = useState(initialId);

  const dispatch = useDispatch();

  const setWidget = (widget) => dispatch({ type: 'SET_WIDGET', widget });
  const setFlowter = (flowter) => dispatch({ type: 'FLOWTER_CREATOR_SET_FLOWTER', flowter });

  const selectTemplate = () => {
    setFlowter(template);
    setWidget(template);

    setActiveTemplate(category, initialId);
  };

  const templateGotSelected = () => {
    stats.push(['trackEvent', 'Template', `Selected template ${template.name}`]);
  };

  const showTemplateData = () => {
    const { questions } = template;
    return (
      <div className="columns is-centered is-paddingless is-marginless">
        <div className="column has-text-grey p0 mt5">
          <p className="is-hidden-desktop is-size-7 has-text-black mb10 mt5">
            Zur Vorschau bitte nach unten scrollen!
          </p>
          {
            questions.map((question, index) => (
              <div key={index} className="has-text-left mb5">
                <p className="is-inline has-text-weight-bold mr10 is-size-7 has-text-black">{`${index + 1}`}</p>
                <p className="is-inline mb10 is-size-7">{question.question}</p>
              </div>
            ))
          }
          <Link
            className="mt10 button br22 is-pastel is-size-7 has-text-white has-text-weight-semibold grow has-height-auto has-white-space"
            onClick={templateGotSelected}
            to={routes.FLOWTER_CREATOR}
          >
            Vorlage auswählen und zum Flowter Editor
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="columns is-centered is-mobile">
      <button
        type="button"
        className={`boxshadow template mb20 column has-background-white has-no-border has-text-black br10 pb10 ${showTemplate ? '' : 'template-hover'}`}
        onClick={() => selectTemplate()}
      >
        <p className="is-inline has-text-weight-bold is-size-6">{template.name}</p>
        <i className="is-inline has-text-pastel is-pulled-right fas fa-chevron-down mr10 mt5 is-size-6" />
        {
          showTemplate
          && showTemplateData()
        }
      </button>
    </div>
  );
}
export default Template;
