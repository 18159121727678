import React, { useState, useEffect } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from 'components/hooks/useAPI';

// Actions
import { updateFlowter, setSubmissions } from 'actions/flowter';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// Components
import Statistic from '../Statistic';
import Loader from 'components/layout/Loader';

// Constants
import { HOME } from 'constants/routes';

function StatisticWrapper() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { get } = useAPI();

  const { flowters } = useSelector((state) => state.flowterState);
  const dispatch = useDispatch();

  const [index, setIndex] = useState(-1);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadStatistic = async (flowterID, flowterIndex) => {
      try {
        const flowter = await get(`/flowters/${flowterID}`);
        dispatch(updateFlowter(flowter, flowterIndex));

        const submissions = await get(`/flowters/submissions?id=${flowterID}`);

        dispatch(setSubmissions(submissions, flowterIndex));

        setIndex(flowterIndex);
      } catch (e) {
        setError('Ergebnisse konnten nicht geladen werden.');
      }
    };

    if (id === undefined) {
      navigate(HOME);
      return;
    }

    const widgetIndex = parseInt(id, 10);

    if (Number.isNaN(widgetIndex)) {
      navigate(HOME);
      return;
    }

    const widgetData = flowters[widgetIndex];

    if (!widgetData) {
      navigate(HOME);
    }

    const { _id } = widgetData;

    loadStatistic(_id, widgetIndex);
  }, []);

  return (
    <>
      <Helmet>
        <title>Flowdust - Flowter Ergebnisse</title>
      </Helmet>
      {
        index === -1
          ? <Loader error={error} />
          : <Statistic index={index} />
      }
    </>
  );
}

export default withAuthentication(StatisticWrapper);
