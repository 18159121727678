import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import gdprCompliance from 'assets/images/gdprCompliance.svg';
/*
<div className="column is-2-desktop is-8-mobile is-offset-2-mobile">
	<p className="mb20 is-size-5 mt90 has-text-weight-bold">{"Flowdust"}</p>
	<Link className="is-block has-text-weight-light" to="#pricing">{"Preise"}</Link>
	<Link className="is-block mt10 has-text-weight-light" to="#features">{"Features"}</Link>
	<Link className="is-block mt10 has-text-weight-light" to="#faq">{"FAQ"}</Link>
	<Link className="is-block mt10 has-text-weight-light" to={routes.LIVEDEMO}>{"Live Demo"}</Link>
	<Link to={routes.PARTNER} className="is-block mt10 has-text-weight-light">{"Partner"}</Link>
</div>

*/

/*
	<svg className="landing-shape-wave" viewBox="0 0 500 95"><path d="M462.8,18.3C455.6,22.9 449.90000000000003,26.6 437.5,26.6C425.09999999999997,26.6 419.4,23 412.2,18.3C404,13 393.8,6.5 375,6.5C356.2,6.5 346,13 337.8,18.2C330.6,22.799999999999997 324.90000000000003,26.5 312.5,26.5C300.09999999999997,26.5 294.4,22.9 287.2,18.2C279,13 268.8,6.5 250,6.5C231.2,6.5 221,13 212.8,18.2C205.60000000000002,22.799999999999997 199.9,26.5 187.5,26.5C175.1,26.5 169.4,22.9 162.2,18.2C154,13 143.8,6.5 125,6.5C106.19999999999999,6.5 96,13 87.8,18.2C80.6,22.799999999999997 74.89999999999999,26.5 62.5,26.5C50.10000000000001,26.5 44.4,22.9 37.2,18.2C29,13 18.8,6.5 0,6.5V95.79200000000003H500V6.5C481.2,6.5 471,13 462.8,18.3Z" fill="#273443"></path></svg>
*/
const Footer = () => (
  <div className="has-background-dash has-text-midnightblue pt90 pb70">
    <div className="columns is-centered has-text-centered ml20 mr20">
      <div className="column is-narrow">
        <Link
          to={routes.LANDING}
          className="has-text-pastel is-size-2-desktop is-size-3-tablet is-size-2-mobile flowdustFont"
        >
          Flowdust
        </Link>
      </div>
      <div className="column is-2-desktop is-4-tablet is-8-mobile is-offset-2-mobile">
        <p className="is-size-6 has-text-weight-bold mb10">Rechtliches</p>
        <Link
          to={routes.PRIVACY_POLICY}
          className="has-text-midnightblue is-block has-text-weight-light"
        >
          Datenschutz
        </Link>
        <Link to={routes.IMPRESSUM} className="has-text-midnightblue is-block mt10 has-text-weight-light">Impressum</Link>
        <Link to={routes.TERMS_OF_SERVICE} className="has-text-midnightblue is-block mt10 has-text-weight-light">AGB</Link>
      </div>
      <div className="column is-2-desktop is-4-tablet is-8-mobile is-offset-2-mobile">
        <p className="is-size-6 mb10 has-text-weight-bold ">Fragen oder Feedback?</p>
        <p className="has-hyphens">hello@flowdust.com</p>
        <p
          className="has-text-weight-bold mt-4 mb-1"
        >
          Folge uns unauffällig!
        </p>
        <div className="mt-2">
          <a
            href="https://www.facebook.com/flowdust"
            target="_blank"
            rel="noopener noreferrer"
            className="mt10 is-inline mx-1"
          >
            <i className="fab fa-facebook has-text-info is-size-3" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC6w3ZmeLZxCTR7hMunBErdQ"
            target="_blank"
            rel="noopener noreferrer"
            className="mt10 is-inline mx-1"
          >
            <i className="fab fa-youtube has-text-danger is-size-3" />
          </a>
          <a
            href="https://www.pinterest.de/Flowdust_/"
            target="_blank"
            rel="noopener noreferrer"
            className="mt10 is-inline mx-1"
          >
            <i className="fab fa-pinterest has-text-danger is-size-3" />
          </a>
          <a
            href="https://www.instagram.com/flowdustcom/"
            target="_blank"
            rel="noopener noreferrer"
            className="mt10 is-inline mx-1"
          >
            <i className="fab fa-instagram has-text-black is-size-3" />
          </a>
        </div>
      </div>
      <div className="column is-2-desktop is-4-tablet is-8-mobile is-offset-2-mobile">
        <p className="is-size-6 has-text-weight-bold mb10">Ressourcen</p>
        <a
          href="https://blog.flowdust.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="mt10 has-text-midnightblue is-block has-text-weight-light"
        >
          Blog
        </a>
        <Link to={routes.ABOUT_US} className="has-text-midnightblue is-block mt10 has-text-weight-light">Über Uns</Link>
        <Link to={routes.PRESS} className="has-text-midnightblue is-block mt10 has-text-weight-light">Presse</Link>
        <Link
          className="has-text-midnightblue is-block mt10 has-text-weight-light"
          to={routes.AFFILIATE}
        >
          Affiliate
        </Link>
        <a href="https://docs.flowdust.com" target="_blank" rel="noopener noreferrer" className="mt10 has-text-midnightblue is-block has-text-weight-light">Dokumentation</a>
        <Link to={routes.PARTNER} className="has-text-midnightblue is-block mt10 has-text-weight-light">Unsere Partner</Link>
      </div>
      <div className="column is-narrow">
        <img src={gdprCompliance} alt="Grafik für DSGVO/GDPR Compliance" className="has-max-height-70" />
      </div>
    </div>
    <div className="columns is-marginless">
      <div className="column pl20 pr20 has-text-midnightblue">
        <p className="has-text-centered mt20 has-text-weight-bold">Mit ❤ gemacht im Fichtelgebirge.</p>
        <p className="has-text-centered mt5">Copyright © 2022 - Alle Rechte vorbehalten.</p>
      </div>
    </div>
  </div>
);
export default (Footer);
