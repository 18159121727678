import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import BasicStatistic from './BasicStatistic';
import BrowserStatistic from './BrowserStatistic';
import ScreenStatistic from './ScreenStatistic';
import OsStatistic from './OsStatistic';
import CpuStatistic from './CpuStatistic';
import LangStatistic from './LangStatistic';
import CountryStatistic from './CountryStatistic';
import InfoPopup from 'components/utils/InfoPopup';

// Styles
import styles from './styles.module.scss';
import DeviceStatistic from './DeviceStatistic';

const AnalyticsStatistic = () => {
  const [detailsPage, setDetailsPage] = useState('');

  const setDetails = (page) => {
    // window.scrollTo(0, 0);
    setDetailsPage(page);
  };

  const showContent = () => {
    switch (detailsPage) {
      case 'browser': return <BrowserStatistic />;
      case 'screen': return <ScreenStatistic />;
      case 'os': return <OsStatistic />;
      case 'cpu': return <CpuStatistic />;
      case 'lang': return <LangStatistic />;
      case 'country': return <CountryStatistic />;
      case 'device': return <DeviceStatistic />;
      // case 'darkmode': return <DarkmodeStatistic />;
      default: return <BasicStatistic setDetails={setDetails} />;
    }
  };

  return (
    <div className="">
      <div
        id={styles.wrapper}
        className={`has-text-black has-text-left ${detailsPage ? '' : styles.scrollWrapper}`}
      >
        <div className="columns is-mobile m-0">
          <div className="column p-0 is-narrow">
            <h2 className="mr-1 is-inline has-text-weight-bold has-text-black is-size-4">
              Analytics
            </h2>
            <InfoPopup
              text="Analytics erscheinen, sobald beim Hub die Verarbeitung der personenbezogenen Daten akzeptiert wurde. Daher kann es vorkommen, dass mehr Aufrufe, als Abstimmungen angezeigt werden."
            />
          </div>
          {
          detailsPage
          && (
          <div className="column p-0 is-flex justify-content-end">
            <button
              type="button"
              onClick={() => setDetails('')}
              className="cleanButton has-text-underlined is-info is-size-7"
            >
              Zurück
            </button>
          </div>
          )
        }
        </div>
        <div
          className={detailsPage ? '' : styles.scrollContent}
        >
          {showContent()}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsStatistic;
