import React from 'react';
import stats from 'analytics/analytics';
import { useNavigate } from 'react-router-dom';
import { SIGN_UP, WIDGET_CREATOR_START } from 'constants/routes';
import PropTypes from 'prop-types';
import Footer from 'components/layout/Footer';
import BrowserWindow from 'components/layout/BrowserWindow';
import './styles/templates.scss';

function Header(props) {
  const {
    heading, isGIF, img, text, button,
  } = props;

  const navigate = useNavigate();

  const clickActionButton = () => {
    stats.push(['trackEvent', `Info Page Heading - ${heading}`, 'Register button clicked']);
    navigate(SIGN_UP);
  };

  return (
    <div className="columns is-vcentered">
      <div className="column is-4-desktop is-6-tablet is-11-mobile">
        <h1 className="has-text-weight-bold is-size-3-desktop is-size-4-touch has-line-height-40 is-inline has-text-black">
          {heading}
        </h1>
        <p className="mt20 mb20">{text}</p>
        <div>
          { button }
        </div>
      </div>
      <div className="column is-offset-1 has-text-centered">
        {
      isGIF
        ? <BrowserWindow img={img} />
        : (
          <img
            className="boxshadow br20 has-max-height-400"
            alt="Grafik"
            src={img}
          />
        )
      }
      </div>
    </div>
  );
}

Header.propTypes = {
  img: PropTypes.string.isRequired,
  isGIF: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.shape({}),
};

Header.defaultProps = {
  button: null,
};

function Section(props) {
  const {
    imagePos, topText, bottomText, headline, linkText, linkTo, img,
  } = props;

  const navigate = useNavigate();

  const clickButton = () => {
    stats.push(['trackEvent', 'Info Page - Section', 'Action button clicked']);
    navigate(linkTo);
  };

  return (
    <div className="columns is-centered is-vcentered is-centered-mobile my-6">
      {
        imagePos === 0
        && (
        <div className="column is-5 has-text-centered is-hidden-mobile">
          <img className="has-max-height-350" alt="Illustration" src={img} />
        </div>
        )
      }
      <div className={`column is-5 ${imagePos === 0 ? 'is-offset-1' : ''}`}>
        <h2 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold has-text-black">
          {headline}
        </h2>
        <p className="mt20 has-line-height-30">{topText}</p>
        <p className="mt20 has-line-height-30">{bottomText}</p>
        {
          linkTo
          && (
          <div className="has-text-centered mt-5">
            <button
              type="button"
              className="button grow is-pastel has-text-weight-bold br10 has-fullheight"
              onClick={clickButton}
            >
              {linkText}
            </button>
          </div>
          )
        }
      </div>
      {
        imagePos === 1
        && (
        <div className="column is-5 is-offset-1 has-text-centered">
          <img className="has-max-height-350" alt="Illustration" src={img} />
        </div>
        )
      }
    </div>
  );
}

Section.propTypes = {
  imagePos: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
};

Section.defaultProps = {
  linkText: '',
  linkTo: '',
  topText: '',
  bottomText: '',
};

function CallToAction() {
  const navigate = useNavigate();

  const clickActionButton = () => {
    stats.push(['trackEvent', 'Info Page - Bottom Call To Action', 'Register button clicked']);
    navigate(`${WIDGET_CREATOR_START}/0`);
  };

  return (
    <div className="has-text-centered has-background-white py-5 px-3">
      <h2 className="has-text-black is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-weight-bold">
        Erstelle jetzt Deinen ersten Flowter!
      </h2>
      <button
        type="button"
        className="button boxshadow grow is-pastel br10 has-text-weight-bold has-fullheight mt-5 mb-6"
        onClick={clickActionButton}
      >
        <i className="fas fa-play-circle mr-2" />
        Zum Editor (Kein Account nötig)
      </button>
    </div>
  );
}
function InfoPage(props) {
  return (
    <div id="infoPageWrapper" className="has-background-white">
      <div className="columns is-centered is-mobile mb-6">
        <div className="column is-10 is-relative pt100">
          { props.heading }
        </div>
      </div>
      <div className="nmb60 is-hidden-mobile">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 115 1440 205">
          <path
            fill="#ff914d"
            fillOpacity="1"
            className="has-no-pointer-events"
            d="M0,128L40,122.7C80,117,160,107,240,128C320,149,400,203,480,202.7C560,203,640,149,720,154.7C800,160,880,224,960,224C1040,224,1120,160,1200,138.7C1280,117,1360,139,1400,149.3L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          />
        </svg>
      </div>
      <div className="columns is-marginless is-centered is-mobile mt-4 pb40">
        <div className="pt-6 boxshadow box column is-10-desktop is-10-tablet is-11-mobile is-relative">
          { props.children }
        </div>
      </div>
      <CallToAction />
      <Footer />
    </div>
  );
}

function Steps(props) {
  return (
    <div>
      <div className=" mt80 has-border-black-slim" />
      <p className="has-text-centered mt30 has-text-black has-text-weight-bold is-size-2">Kurzanleitung</p>
      <div className="mb30 mt30 columns has-text-centered is-centered">
        <div className="pt20 column m20 is-2 boxshadow box">
          <p className="has-text-weight-bold has-border-bottom-pastel-bold">Installieren</p>
          <i className="mt20 is-size-5 fas fa-code has-text-midnightblue" />
          <p className="mt10 has-text-weight-semibold has-text-grey">Kopiere dein Flowdust Code-Snippet und füge es in deine HTML Datei ein.</p>
        </div>
        <div className="pt20 column m20 is-2 boxshadow box">
          <p className="has-text-weight-bold has-border-bottom-pastel-bold">Hinzufügen</p>
          <i className="mt20 is-size-5 fas fa-plus-square has-text-midnightblue" />
          <p className="mt10 has-text-weight-semibold has-text-grey">Gib die URL und den Namen der Website an, auf der dein Widget erscheinen soll.</p>
        </div>
        <div className="pt20 column m20 is-2 boxshadow box">
          <p className="has-text-weight-bold has-border-bottom-pastel-bold">Erstellen</p>
          <i className="mt20 is-size-5 fas fa-tools has-text-midnightblue" />
          <p className="mt10 has-text-weight-semibold has-text-grey">Gestalte dein Widget ganz nach deinen Wünschen und Vorstellungen.</p>
        </div>
        <div className="pt20 column m20 is-2 boxshadow box">
          <p className="has-text-weight-bold has-border-bottom-pastel-bold">Verknüpfen</p>
          <i className="mt20 is-size-5 fas fa-object-group has-text-midnightblue" />
          <p className="mt10 has-text-weight-semibold has-text-grey">Weise deiner Website dem Widget zu, das du dort platzieren möchtest.</p>
        </div>
      </div>
    </div>
  );
}

InfoPage.propTypes = {
  heading: PropTypes.element.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export {
  Header,
  Section,
  InfoPage,
  CallToAction,
  Steps,
};
