import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';
import { PALETTE, getColor } from 'components/hub/HubStatistic/Statistic/palette';

// Components
import {
  VictoryChart, VictoryBar, VictoryAxis,
} from 'victory';

// Styles
import styles from './styles.module.scss';

/*
 * AnalyticsChart shows groups of analytics data in one chart,
 * so props has to be a 2 dimensional array
*/
const AnalyticsChart = (props) => {
  const {
    prefix, name, statistic,
  } = props;

  const [fixedActiveValue, setFixedActiveValue] = useState(-1);
  const [activeValue, setActiveValue] = useState(-1);

  const onMouseOver = () => ([{
    target: 'data',
    mutation: ({ datum }) => {
      const statIndex = datum.index;

      if (fixedActiveValue !== -1) {
        if (statIndex === fixedActiveValue) {
          return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex), cursor: 'pointer' } });
        }
        return {};
      }

      if (statIndex !== activeValue) {
        setActiveValue(statIndex);
      }

      return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex), cursor: 'pointer' } });
    },
  }]);

  const onMouseOut = () => ([{
    target: 'data',
    mutation: ({ datum }) => {
      const statIndex = datum.index;
      // check if we have a fixed value
      if (fixedActiveValue !== -1) {
        if (statIndex === fixedActiveValue) {
          return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex), cursor: 'pointer' } });
        }
        return {};
      }

      // normally, onMouseOut should just reset the active Value
      setActiveValue(-1);
      return {};
    },
  }]);

  const onClick = () => ([{
    target: 'data',
    mutation: ({ datum }) => {
      const statIndex = datum.index;

      if (fixedActiveValue !== -1) {
        if (statIndex === fixedActiveValue) {
          // reset fixed value
          setFixedActiveValue(-1);

          return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex), cursor: 'pointer' } });
        }

        return {};
      }

      setActiveValue(statIndex);

      if (statIndex === fixedActiveValue) {
        setFixedActiveValue(-1);
      } else {
        setFixedActiveValue(statIndex);
      }

      return ({ style: { fillOpacity: 0.6, fill: getColor(statIndex) } });
    },
  }]);

  const amount = useMemo(() => {
    let totalAmount = 0;

    statistic.forEach((stat) => {
      totalAmount += stat.y;
    });

    return totalAmount;
  }, [statistic]);

  const renderedActiveValue = useMemo(() => {
    if (activeValue === -1) return null;

    const value = statistic[activeValue].y;
    const statName = statistic[activeValue].x;

    const percent = ((value / amount) * 100).toFixed(2);

    return (
      <div
        id={styles.tooltip}
        className="has-fullheight"
      >
        <p className="is-block">
          {`${prefix}${statName}:`}
        </p>
        <p>
          {`${value} (${percent}%)`}
        </p>
      </div>
    );
  }, [activeValue]);

  const showGraph = () => (
    <div className="has-max-width-600 is-center is-relative">
      { renderedActiveValue }
      <VictoryChart
        domainPadding={30}
        horizontal
      >
        <VictoryBar
          data={statistic}
          barWidth={45}
          style={{ data: { fill: (item) => getColor(item.index) } }}
          events={[{
            target: 'data',
            eventHandlers: {
              onMouseOver,
              onMouseOut,
              onClick,
            },
          }]}
        />
        <VictoryAxis tickFormat={() => ''} />
        <VictoryAxis
          dependentAxis
        />
      </VictoryChart>

    </div>
  );
  const showStatistic = () => statistic.map((stat, statIndex) => {
    const color = getColor(statIndex);
    const _name = stat.x;
    const _amount = stat.y;

    return (
      <div key={statIndex}>
        <div className="column">
          <div className="columns is-mobile is-tablet is-centered">
            <div className="column is-2 has-text-right">
              <i className="fas fa-square is-size-4" style={{ color }} />
            </div>
            <div className="column is-10-desktop has-text-left">
              <p
                className={`is-inline has-text-black ${activeValue === statIndex ? 'has-text-weight-bold' : ''}`}
              >
                {`${prefix}${_name}: `}
              </p>
              <p
                className="is-inline has-text-weight-bold"
                style={{ color }}
              >
                {_amount}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="my-3">
      <p
        className="mt-5 br5 has-text-centered mb-2 has-background-grey-light has-text-grey  pl-1 has-text-weight-semibold"
      >
        {name}
      </p>
      { showGraph() }
      {
           amount !== -1
           && (
           <p className="pb-2 has-text-weight-bold">
             {`Insgesamt: ${amount}`}
           </p>
           )
      }
      { showStatistic() }
    </div>
  );
};

AnalyticsChart.propTypes = {
  statistic: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
      index: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string,
  prefix: PropTypes.string,
};

AnalyticsChart.defaultProps = {
  name: '',
  prefix: '',
};

export default AnalyticsChart;
