import React from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setLogicActive, setHasAdvancedLogic } from 'reducer/hubs';

// Components
import Switch from 'react-switch';
import SimpleLogicEditor from './SimpleLogicEditor';
import AdvancedLogicEditor from './AdvancedLogicEditor';

const LogicEditor = () => {
  const { hasAdvancedLogic } = useSelector((state) => state.hubs.creator.content.settings);

  const dispatch = useDispatch();

  const toggleMode = () => {
    if (hasAdvancedLogic) {
      dispatch(setHasAdvancedLogic(false));
    } else {
      dispatch(setHasAdvancedLogic(true));
    }
  };

  return (
    <div className="pb-5">

      <div className="is-flex align-items-center justify-content-end mb-4">
        <p
          className="is-size-7 mx-3 has-text-black"
        >
          {
            hasAdvancedLogic ? 'Erweiterter Modus' : 'Einfacher Modus'
          }
        </p>
        <Switch
          onChange={toggleMode}
          checked={hasAdvancedLogic}
          onColor="#209CEE"
          onHandleColor="#ffffff"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          offColor="#ff914d"
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="switch"
        />
      </div>

      <h2 className="has-text-weight-bold is-size-6 has-text-black">
        Füge Verzweigungen hinzu (optional)
      </h2>
      <p className="mt-1 has-text-grey is-size-7">
        Verzweigungen helfen dir dabei, deine Nutzer basierend auf ihren Abstimmungen in unterschiedliche Richtungen zu leiten.
      </p>

      {
           hasAdvancedLogic
             ? <AdvancedLogicEditor />
             : <SimpleLogicEditor />
      }
    </div>
  );
};

export default LogicEditor;
