import React, { useEffect, useMemo, useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// Components
import PricingPlan from 'components/checkout/PricingPlan';
import PlanContent from 'components/layout/PlanContent';
import LoadingLayout from 'components/utils/LoadingLayout';

// Constants
import { CANCEL_SUBSCRIPTION, HUB_PROFILE, MANAGE_SUBSCRIPTION } from 'constants/routes';
import plans from 'constants/plans';

// Actions
import { setHubs } from 'reducer/hubs';
import Popup from 'reactjs-popup';

function ChangeSubscriptionPlan() {
  const { user } = useSelector((state) => state.sessionState);
  const { hasData, hubs } = useSelector((state) => state.hubs);
  const plan = user.planDetails;
  const { nextBillingDate } = user.subscription;

  const [planToDowngradeTo, setPlanToDowngradeTo] = useState();
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { get } = useAPI();

  const navigate = useNavigate();

  useEffect(() => {
    const loadHubs = async () => {
      try {
        setLoading(true);
        // load hubs so we have the newest data
        const loadedHubs = await get('/hubs');
        dispatch(setHubs(loadedHubs));
        // then check if on a downgrade the user could lose hubs
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    loadHubs();
  }, []);

  const selectPlan = (index) => {
    navigate(`${MANAGE_SUBSCRIPTION}/${index}`);
  };

  const showAvailablePlans = () => plans.map((planToShow, index) => {
    // dont show current plan, or test plan
    // .plan is the id of the plan
    if (index === 0) {
      return null;
    }

    if (planToShow.cost === plan.cost && planToShow.name === plan.name && planToShow.views === plan.views) {
      return null;
    }

    // if the user will downgrade, we have a newPlan object, so don't show the plan with the downgrade plan
    if (user.newPlan) {
      if (planToShow.cost === user.newPlan.cost && planToShow.name === user.newPlan.name && planToShow.views === user.newPlan.views) {
        return null;
      }
    }

    const isDowngrade = planToShow.cost < plan.cost;

    let buttonText = 'Auswählen';

    if (isDowngrade) {
      buttonText = 'Downgrade';
    }

    if (planToShow.cost > plan.cost) {
      buttonText = 'Upgrade';
    }

    const shouldShowPopup = isDowngrade && hubs.length > planToShow.maxAmountHubs;

    // eslint-disable-next-line
    return <PricingPlan 
      key={index}
      plan={planToShow}
      buttonText={buttonText}
      selectPlan={() => (shouldShowPopup ? setPlanToDowngradeTo(planToShow) : selectPlan(index))}
    />;
  });

  const renderedPopup = useMemo(() => {
    if (!planToDowngradeTo || !hasData) return null;

    const { maxAmountHubs } = planToDowngradeTo;
    const amountHubs = hubs.length;

    const amountThatGetDeleted = amountHubs - maxAmountHubs;
    const hubsThatGetDeleted = hubs.slice(amountThatGetDeleted * -1);

    const renderedHubs = hubsThatGetDeleted.map((hub, index) => {
      const { url, name, createdAt } = hub;

      return (
        <div
          key={index}
          className="box boxshadow br10"
        >
          <div className="columns m-0">

            <div className="column">
              {
                name && (
                  <div>
                    <p
                      className="is-size-7"
                    >
                      Name
                    </p>
                    <p
                      className="has-text-black break-word"
                    >
                      { name }
                    </p>
                  </div>
                )
              }

              <div>
                <p
                  className="is-size-7"
                >
                  Erstellt am
                </p>
                <p
                  className="has-text-black"
                >
                  {`${new Date(createdAt).toLocaleDateString('de')}`}
                </p>
              </div>

              <div>
                <p
                  className="is-size-7"
                >
                  Hub URL
                </p>
                <p
                  className="has-text-black break-word"
                >
                  {url}
                </p>
              </div>

            </div>

            <div className="column has-text-centered is-narrow">
              <Link
                className="button is-size-7 has-text-weight-bold has-hover is-primary br10"
                to={`${HUB_PROFILE}/${hubs.length - index - 1}`}
              >
                Zum Hub Profil
              </Link>
            </div>

          </div>

        </div>
      );
    });

    const goToDowngradePage = () => {
      selectPlan(planToDowngradeTo.plan);
    };

    const cancelDowngrade = () => {
      setPlanToDowngradeTo();
    };

    return (
      <div className="columns is-centered is-mobile m-0">
        <div className="column p-0 is-8-desktop is-10-tablet is-11-mobile">
          <div className="has-text box boxshadow br10 p-5 has-text-black has-overflow-y-auto is-max-fullheight">
            <p className="has-text-weight-bold mb-3 is-size-3-desktop is-size-4-touch">
              Achtung!
            </p>
            <p>
              {`Wenn du diesen Plan auswählst werden einige deiner Hubs gelöscht, da der neue Plan nur noch ${maxAmountHubs} Hubs beinhaltet, du aber momentan ${amountHubs} verschiedene Hubs hast!`}
            </p>
            <p
              className="has-text-weight-bold my-4"
            >
              {`Folgende Hubs würden am ${new Date(nextBillingDate).toLocaleDateString('de')} verloren gehen:` }
            </p>
            { renderedHubs }
            <div className="mt-6 has-text-centered pb-5">
              <p className="has-text-weight-bold has-text-black is-size-5">
                Möchtest du dennoch das Downgrade durchführen?
              </p>
              <div
                className="is-flex has-content-centered mt-4"
              >
                <button
                  className="button has-border-grey br10 has-text-grey has-text-weight-bold is-block has-fullwidth mx-2 has-fullheight"
                  type="button"
                  onClick={goToDowngradePage}
                >
                  Ja, zum Downgrade
                </button>
                <button
                  className="button is-pastel br10 grow has-text-weight-bold is-block has-fullwidth mx-2 has-fullheight"
                  type="button"
                  onClick={cancelDowngrade}
                >
                  Nein, nicht downgraden
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [planToDowngradeTo, hubs]);

  if (isLoading) return <LoadingLayout />;

  return (
    <div className="">
      <div className="columns is-marginless is-centered">
        <div className="column is-4-desktop is-6-tablet has-text-centered">
          <p className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch mb-3">
            Dein momentaner Plan:
          </p>
          <div className="columns is-centered is-marginless">
            <PricingPlan plan={plan} showButton={false} />
          </div>
        </div>
        <Popup
          position="top center"
          keepTooltipInside="#root"
          repositionOnResize
          modal
          open={planToDowngradeTo !== undefined}
          onClose={() => setPlanToDowngradeTo()}
          className="no-styling"
          contentStyle={{ borderRadius: '10px' }}
        >
          { renderedPopup }
        </Popup>
        {
          user.newPlan
          && (
          <div className="column is-5-desktop is-6-tablet has-text-centered">
            <p className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch mb-3">
              Dein zukünftiger Plan:
            </p>
            <div className="columns is-centered is-marginless">
              <PricingPlan plan={user.newPlan} showButton={false} />
            </div>
          </div>
          )
        }
        <div className="column is-12 has-text-centered">
          <p className="has-text-black has-text-weight-bold is-size-4-desktop is-size-5-touch mb-3">
            Anderen Plan auswählen:
          </p>
          <div className="columns is-marginless is-centered">
            { showAvailablePlans() }
          </div>
        </div>
      </div>
      <PlanContent />
      <div className="has-text-black has-text-weight-bold has-text-centered my-4">
        <p className="is-inline mr5">
          Du brauchst noch mehr? Kontaktiere uns unter
        </p>
        <a
          href="mailto:hello@flowdust.com"
          className="has-text-pastel"
        >
          hello@flowdust.com
        </a>
        <p
          className="is-inline ml5"
        >
          Wir finden auch für Dich das Passende!
        </p>
      </div>
      <div>
        <Link
          className="button grow has-background-red is-size-7 has-text-weight-semibold has-text-white"
          to={CANCEL_SUBSCRIPTION}
        >
          Abo kündigen
        </Link>
      </div>
    </div>
  );
}

export default ChangeSubscriptionPlan;
