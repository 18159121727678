import React from 'react';

const SkippedDisplay = (props) => {
  const { color } = props;
  return (
    <div>
      <i
        className="fas fa-square is-size-7 is-inline mr-1"
        style={{ color }}
      />
      <p className="is-inline">
        Frage übersprungen
      </p>
    </div>
  );
};

export default SkippedDisplay;
