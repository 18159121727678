import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import * as limits from 'constants/websites';

const PrivacyURLSettings = (props) => {
  const { url, setURL } = props;

  const [error, setError] = useState('');

  const setUrlToState = (urlToCheck) => {
    if (urlToCheck.length >= limits.URL_MAX_LENGTH) {
      setError('Wähle bitte eine kürzere URL.');
    } else {
      setURL(urlToCheck);
      setError('');
    }
  };

  return (
    <div className="columns">
      <div className="column is-9">
        <div>
          <p className="is-inline mr5">Datenschutz-URL für diese Website</p>
          <Popup
            trigger={(
              <div className="cleanButton is-inline">
                <i className="fas fa-question-circle has-text-midnightblue is-size-7 has-hover-icon" />
              </div>
            )}
            position="top center"
            on={['hover']}
            keepTooltipInside="#root"
            repositionOnResize
            className="mt10"
          >
            {
              () => (
                <div className="columns is-marginless">
                  <div className="has-text-weight-light column is-marginless is-paddingless pt10 pb10 pl5 pr5 has-text-midnightblue">
                    <p className="is-size-7">Möchtest du speziell für diese Website eine andere Datenschutz-URL anzeigen lassen, kannst du sie hier angeben.</p>
                  </div>
                </div>
              )
            }
          </Popup>
        </div>
        <input
          onChange={(event) => setUrlToState(event.target.value)}
          type="text"
          value={url}
          placeholder="Die Datenschutz-URL für diese Website"
          className="input has-text-grey-dark has-no-background px-0"
        />
        <p className="mt-2 has-text-weight-bold has-text-black">
          {error}
        </p>
      </div>
    </div>
  );
};

export default PrivacyURLSettings;
