import React from 'react';

// Components
import WebsiteContainer from 'components/websites/WebsiteContainer';
import WidgetContainer from 'components/widgets/WidgetContainer';
import UserStatisticBox from 'components/billing/UserStatisticBox';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

function FlowterDashboard() {
  return (
    <div className="columns is-centered is-mobile mx-0 my-6">
      <div className="column is-8-desktop is-10-tablet is-11-mobile">
        <div className="box p-5">
          <p className="has-text-centered has-text-weight-bold mb-6 has-text-pastel is-size-3-desktop is-size-4-tablet is-size-4-mobile">Flowter Kurzanleitung</p>
          <div className="columns is-centered is-multiline">
            <div className="column is-5 has-text-black">
              <p
                className="mr-2 is-inline has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                1. Flowter erstellen
              </p>

              <p className="mt-2">
                Erstelle eine Mini-Umfrage für Deine Website und passe sie nach Deinen Wünschen an.
              </p>
              <Link
                to={routes.FLOWTER_CREATOR}
                className="mt10 button br22 has-background-pastel is-size-7 has-text-black has-text-weight-semibold grow has-height-auto has-white-spacey"
              >
                Neuer Flowter
              </Link>
            </div>
            <div className="column is-5 is-offset-1 has-text-black">
              <p
                className="s-inline has-text-weight-bold is-size-4-desktop is-size-5-touch"
              >
                2. Website hinzufügen
              </p>

              <p className="mt-2">
                Damit Dein Flowter auch auf der richtigen Website erscheint, musst du sie zunächst hinzufügen.
              </p>
              <Link
                to={routes.ADD_WEBSITE}
                className="has-text-black mt10 button br22 has-background-yellow is-size-7  has-text-weight-semibold grow has-height-auto has-white-spacey"
              >
                Website erstellen
              </Link>
            </div>
          </div>

          <div className="columns is-centered mt-4 mb-4">
            <div className="column is-5 has-text-black">
              <p
                className="mr-2 has-text-weight-bold is-size-4-desktop is-size-5-touch is-inline"
              >
                3. Website & Flowter verknüpfen
              </p>

              <p className="mt-2">
                Wähle in der Webseiten-Übersicht den Flowter aus, der angezeigt werden soll.
              </p>
            </div>
            <div className="column is-5 is-offset-1 has-text-black">
              <p
                className="mr-2 is-block has-text-weight-bold is-size-4-desktop is-size-5-touch nmt5"
              >
                4. Skript installieren
              </p>

              <p className="mt-2">
                Nutze unsere Plugins & Anleitungen, um Flowdust auf Deiner Website ganz einfach zu installieren.
              </p>
              <Link
                to={routes.ADD_WEBSITE}
                className="has-text-black mt10 button br22 has-background-blue is-size-7  has-text-weight-semibold grow has-height-auto has-white-spacey"
              >
                Installieren
              </Link>
            </div>
          </div>

        </div>
        <WidgetContainer />
        <WebsiteContainer />
      </div>
    </div>
  );
}

export default withAuthentication(FlowterDashboard);
