export const addStatusWidget = (widget) => ({
  type: 'ADD_STATUS_WIDGET',
  widget,
});

export const updateStatusWidget = (widget) => ({
  type: 'UPDATE_STATUS_WIDGET',
  widget,
});

export const setStatusWidgets = (widgets) => ({
  type: 'SET_STATUS_WIDGETS',
  widgets,
});

export const deleteStatusWidget = (id) => ({
  type: 'DELETE_STATUS_WIDGET',
  id,
});

export const clearStatusWidgets = (widgets) => ({
  type: 'STATUSWIDGETS_CLEAR',
  widgets,
});
