import React, {
  useEffect, useState, useLayoutEffect, useMemo, useRef,
} from 'react';

// Hooks
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import {
  setCreatorContent, setHasTemplateData, setInfoActive, setQuestionActive,
} from 'reducer/hubs';

// Constants
import { HUB_PROFILE, HUB_DEMO_EDITOR, HUB_TEMPLATE } from 'constants/routes';

// Components
import LoadingLayout from 'components/utils/LoadingLayout';
import HubPreview from '../HubContentEditor/HubPreview';
import ContentDisplay from './ContentDisplay';

// Templates
import { templates } from 'components/hub/templates';
import CopyToClipboardButton from 'components/utils/CopyToClipboardButton';
import Template from 'components/templates/Template';

function HubTemplatePage() {
  const [template, setTemplate] = useState();
  const { id: templateId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  const { isAuthenticated } = useAuth0();

  const goToTemplatePage = (_template) => {
    navigate({
      pathname: `${HUB_TEMPLATE}/${_template.id}`,
    });
  };

  const renderedOtherTemplates = useMemo(() => {
    if (!template) return [];

    // filter out current one
    const templatesWithoutCurrent = templates.filter((_template) => _template.id !== templateId);

    // shuffle templates
    const shuffled = templatesWithoutCurrent.sort(() => 0.5 - Math.random());

    // Get 2 random items
    const selected = shuffled.slice(0, 2);

    return selected.map((_template) => (
      <div className="column is-narrow">
        <Template
          templateData={_template}
          onSelect={() => goToTemplatePage(_template)}
        />
      </div>
    ));
  }, [template]);

  const resetTemplate = () => {
    if (template.content.info.text || template.content.info.media.source) {
      dispatch(setInfoActive());
    } else {
      // othwise, just show the first question
      dispatch(setQuestionActive(0));
    }

    if (!ref || !ref.current) return;

    const div = ref.current;
    div.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!templateId) return;

    const getTemplate = async () => {
      const _template = templates.find((entry) => entry.id === templateId);

      if (!_template) return;

      setTemplate(_template);
    };

    getTemplate();
  }, [templateId]);

  useLayoutEffect(() => {
    if (!template) return;

    resetTemplate();

    // set the actual template
    dispatch(setCreatorContent(template.content));
  }, [template]);

  const selectTemplate = () => {
    resetTemplate();

    // if user is not logged in, go to hub demo editor
    if (!isAuthenticated) {
      navigate(HUB_DEMO_EDITOR);
      return;
    }

    // if user is logged in, go to hub creator and mark hasTemplateData as true
    dispatch(setHasTemplateData(true));
    navigate(HUB_PROFILE);
  };

  if (!template) return <LoadingLayout />;

  const {
    name, descriptionLong, tags, thumbnail, content,
  } = template;

  return (
    <div className="is-min-fullheight has-background-white py-6">

      <div className="columns is-centered is-mobile mt-5">
        <div className="column is-7-desktop is-10-tablet is-11-mobile">

          <div className="columns px-1 ">

            <div className="column is-flex">
              <p
                className="has-text-black has-text-weight-bold is-size-4 has-margin-top-auto"
              >
                { name }
              </p>
            </div>
            <div className="column has-text-right">
              <button
                type="button"
                className="button has-text-weight-bold is-primary br10 grow py-2 has-border-pastel-light"
                onClick={selectTemplate}
              >
                Vorlage auswählen
                <i className="fas fa-circle-check ml-2" />
              </button>
            </div>
          </div>

          <div
            ref={ref}
            className="has-border-grey-light-thin br10 has-overflow-y-auto has-overflow-x-hidden has-height-580"
          >
            <HubPreview wrapper={ref} />
          </div>

          <div className="mt-6 mb-6">

            <div className="columns">

              <div className="column">

                <div className="is-flex">

                  <button
                    type="button"
                    className="button has-text-weight-semibold br10 has-hover has-border-grey-thin mb-4"
                    onClick={resetTemplate}
                  >
                    <i className="fas fa-undo mr-2" />
                    Vorschau zurücksetzen

                  </button>
                </div>

                <p
                  className="has-text-black mt-2"
                >
                  { descriptionLong }
                </p>

                <div className="is-flex mt-5 has-border-top pt-5">

                  <div className="">
                    <p
                      className="has-text-weight-bold has-text-black mb-2"
                    >
                      Teilen
                    </p>
                    <CopyToClipboardButton
                      text={window.location.href}
                    >
                      <button
                        type="button"
                        className="button br5 has-text-black has-border-grey-light-thin p-3 has-hover"
                      >
                        <i className="fas fa-link" />
                      </button>
                    </CopyToClipboardButton>
                  </div>

                  <button
                    type="button"
                    className="button has-text-weight-bold is-primary br10 grow py-2 has-border-pastel-light has-margin-left-auto"
                    onClick={selectTemplate}
                  >
                    Vorlage auswählen
                    <i className="fas fa-circle-check ml-2" />
                  </button>
                </div>

              </div>

              <div className="column has-min-width-300 is-4-desktop is-5-tablet is-12-mobile">
                <ContentDisplay content={template.content} />
              </div>

            </div>

            <div>

              <div className="mt-5">
                <p
                  className="has-text-weight-bold has-text-black mb-2"
                >
                  Weitere Vorlagen
                </p>
              </div>

              <div className="has-text-centered-mobile">
                <div className="columns is-multiline is-centered-mobile">
                  { renderedOtherTemplates }
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
}

export default HubTemplatePage;
