import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks

// Components
import AnalyticsInfo from './AnalyticsInfo';
import ProfilePic from './ProfilePic';
import SubmissionInfo from './SubmissionInfo';
import DeleteButton from '../../utils/DeleteButton';

const LogEntry = (props) => {
  const { data, index, id } = props;
  const {
    _id, hash, submission, analytics, createdAt,
  } = data;

  const date = useMemo(() => `${new Date(createdAt).toLocaleString([],
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })} Uhr`, [createdAt]);

  return (
    <div className="is-size-7 my-2 mx-4 pb-2 has-border-bottom">
      <div className="columns is-marginless is-mobile py-2">
        <div className="column p-0">
          <p className="has-text-grey">
            { date }
          </p>
        </div>
        <div className="column is-narrow p-0">
          <DeleteButton flowterID={id} submissionID={_id} />
        </div>
      </div>
      <div className="columns is-marginless is-mobile">
        <div className="column is-narrow p-0">
          <ProfilePic />
        </div>
        <div className="column pt-0">
          <SubmissionInfo data={submission} index={index} />
        </div>
      </div>
      <AnalyticsInfo data={analytics} />
    </div>
  );
};

LogEntry.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    submission: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    analytics: PropTypes.shape({}).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

export default LogEntry;
