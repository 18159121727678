const isBasicWidgetValid = (setError, trigger, layout, settings) => {
  if (Number.isNaN(trigger.amount)) {
    setError('Bitte achte darauf, dass Du bei deinem Trigger eine gültige Zahl eingegeben hast.');
    return false;
  }

  if (trigger.type === 1 && (trigger.amount < 1 || trigger.amount > 1800)) {
    setError('Bitte gib bei deinem Trigger eine Zahl zwischen 1 und 1800 Sekunden ein');
    return false;
  }

  if (trigger.type === 2 && (trigger.amount < 1 || trigger.amount > 100)) {
    setError('Bitte gib bei deinem Trigger eine Zahl zwischen 1 und 100 Prozent ein');
    return false;
  }

  if (!settings.screens.includes(1)) {
    setError('Bitte gib an, auf welchen Geräten dein Flowter angezeigt werden soll.');
    return false;
  }

  return true;
};

export default isBasicWidgetValid;
