import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';

// Libraries
import stats from 'analytics/analytics';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSelector } from 'react-redux';

// Assets
import flowdust from 'assets/images/iconbig.png';

// Components
import QuestionStatistic from './QuestionStatistic';
import FeedbackLog from './FeedbackLog';
import Popup from 'reactjs-popup';

function Statistic(props) {
  const { index } = props;
  const headers = [
    { label: 'Frage', key: 'question' },
    { label: 'Option', key: 'option' },
    { label: 'Anzahl', key: 'amount' },
    { label: 'Feedback', key: 'input' },
  ];

  const refCSVLink = useRef();
  const refWrapper = useRef();

  const [disabled, setDisabled] = useState(false);
  const [statisticData, setStatisticData] = useState([]);

  const [responseCSVData, setResponseCSVData] = useState([]);

  const { get } = useAPI();

  const { flowters } = useSelector((state) => state.flowterState);
  const flowter = flowters[index];

  const {
    questions, statistic, meta, submissions, _id,
  } = flowter;
  const { shown, submitted } = meta;

  useEffect(() => {
    const data = [];

    for (let i = 0; i < questions.length; i++) {
      const {
        question, options, allowText,
      } = questions[i];

      for (let j = 0; j < options.length; j++) {
        const option = options[j];
        const value = statistic[i][j];

        data.push({
          amount: value, option, question,
        });
      }

      if (allowText) {
        data.push({
          amount: statistic[i][statistic[i].length - 1],
          option: 'Feedback deiner Nutzer',
          question,
        });
      }

      setStatisticData(data);
    }
  }, [statistic]);

  const showStatistics = () => {
    if (meta.submitted === 0) {
      return (
        <p className="pt10 pb10 is-size-5 has-text-weight-semibold is-6 has-text-black  has-text-centered">
          Es sind noch keine Statistiken vorhanden
        </p>
      );
    }

    return questions.map((item, questionIndex) => (
      <QuestionStatistic
        key={questionIndex}
        id={index}
        index={questionIndex}
      />
    ));
  };

  const exportResponses = async () => {
    try {
      setDisabled(true);
      const response = await get(`/flowters/submissions/csv/${_id}`);

      setResponseCSVData(response);
      setDisabled(false);

      setTimeout(() => {
        refCSVLink.current.link.click();
      });
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  };

  // CSV Link will take care for the download, here we just send the event to matomo
  const exportCSV = () => {
    stats.push(['trackEvent', 'Flowter Statistic', 'Export CSV']);
  };

  const downloadPDF = async () => {
    if (!refWrapper || !refWrapper.current) return;

    setDisabled(true);

    const boxes = refWrapper.current.querySelectorAll('.statisticBox');

    if (boxes.length === 0) {
      return;
    }

    const promises = [];
    const pdf = new jsPDF();

    pdf.setFontSize(18);
    pdf.text('Flowter Statistik', 10, 280);
    pdf.text(`Erstellt am: ${new Date().toLocaleDateString()}`, 10, 290);

    const submissionDivs = refWrapper.current.querySelectorAll('.submissionDisplay');
    submissionDivs.forEach((div) => {
      div.style.display = 'none'; // Hide the divs during PDF generation
    });

    Array.from(boxes).forEach((box, index) => {
      const promise = html2canvas(box, { scale: 2, width: box.offsetWidth, height: box.offsetHeight });

      promises.push(promise);
    });

    const canvases = await Promise.all(promises);

    canvases.forEach((canvas, index) => {
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (index !== 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      submissionDivs.forEach((div) => {
        div.style.display = 'block'; // Restore the display property after generating the PDF
      });
    });

    pdf.save('report.pdf');
    setDisabled(false);
  };

  return (
    <div className="mt-5" ref={refWrapper}>
      <div className="columns is-marginless is-centered">
        <div className="column has-text-centered is-8-desktop is-12-tablet">
          <div className="statisticWrapper">
            <div className="columns is-centered has-fullwidth m0">
              <div className="box column pb30 is-7-desktop is-12-tablet is-12-mobile">
                {
                   submitted > 0
                   && (
                   <div className="has-text-right m-2">
                     <Popup
                       trigger={(
                         <div className="is-inline-flex has-background-grey-light has-text-grey-darker has-content-centered p-3 br10 has-cursor-pointer growBig">
                           <i className="fas fa-download" />
                         </div>
                      )}
                       position="top center"
                       on={['click']}
                       keepTooltipInside="#root"
                       repositionOnResize
                       closeOnDocumentClick={!disabled}
                       closeOnEscape={!disabled}
                     >
                       { () => (
                         <div className="br10 is-size-7 has-text-centered box pt-4">
                           <p className="is-size-7 has-text-weight-bold mb-4">
                             Daten exportieren
                           </p>
                           <CSVLink
                             data={responseCSVData.data || []}
                             headers={responseCSVData.headers || []}
                             filename="Antworten.csv"
                             className="button has-fullheight has-fullwidth has-text-black has-background-pastel-light is-size-7 br10 mb20 has-hover "
                             style={{ display: 'none' }}
                             disabled={disabled}
                             ref={refCSVLink}
                           />
                           <CSVLink
                             data={statisticData}
                             headers={headers}
                             filename="Statistik.csv"
                             className="button has-fullheight has-fullwidth has-text-black has-background-pastel-light is-size-7 br10 mb20 has-hover"
                             onClick={() => exportCSV()}
                             disabled={disabled}
                           >
                             <i className="fas fa-file-csv mr-2 is-size-5" />
                             Statistik als .csv
                           </CSVLink>
                           <button
                             type="button"
                             className="button has-fullheight has-fullwidth has-text-black has-background-pastel-light is-size-7 br10 mb20 has-hover"
                             onClick={() => downloadPDF()}
                             disabled={disabled}
                           >
                             <i className="fas fa-file-pdf mr-2 is-size-5" />
                             Statistik als .pdf
                           </button>
                           <button
                             type="button"
                             className="button has-fullheight has-fullwidth has-text-black has-background-pastel-light is-size-7 br10 mb20 has-hover"
                             onClick={exportResponses}
                             disabled={disabled}
                           >
                             <i className="fas fa-file-csv mr-2 is-size-5" />
                             Antworten als .csv
                           </button>

                         </div>
                       )}
                     </Popup>

                   </div>
                   )
                 }
                <img className="ml10 mb10" src={flowdust} alt="" />
                <h2 className="pb5 has-border-bottom-grey has-text-pastel has-text-weight-semibold is-size-4">
                  ERGEBNISSE
                </h2>
                <div className="mt20 mb20">
                  <p className="has-text-weight-semibold  has-text-midnightblue is-size-5 has-hyphens">
                    {`Anzeigen: ${shown}`}
                  </p>
                  <p className="has-text-weight-semibold has-text-midnightblue is-size-5 has-hyphens">
                    {`Abstimmungen: ${submitted}`}
                  </p>
                </div>

              </div>
            </div>
            <div className="mt20 pb40">
              { showStatistics() }
            </div>
          </div>
        </div>
        {
           submissions.length > 0
           && (
             <div className="column has-min-width-300 mx-2">
               <FeedbackLog index={index} />
             </div>
           )
        }
      </div>
    </div>
  );
}

Statistic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default Statistic;
