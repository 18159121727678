import React, { useState } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useDispatch, useSelector } from 'react-redux';

// Components
import notification from 'utils/notification';
import { setStatus } from 'reducer/hubs';
import InfoPopup from 'components/utils/InfoPopup';

const ToggleStatusButton = () => {
  const { status, id } = useSelector((state) => state.hubs.creator);

  const [disabled, setDisabled] = useState(false);

  const { post } = useAPI();
  const dispatch = useDispatch();

  const toggleStatus = async () => {
    setDisabled(true);

    try {
      const data = {
        status: (status === 'active' ? 'draft' : 'active'),
      };

      const newStatus = await post(`/hubs/status/${id}`, data);

      setDisabled(false);

      if (newStatus === 'active') {
        notification.send('Erledigt!', 'Dein Hub ist nun aktiv!', 'success');
      } else {
        notification.send('Erledigt!', 'Dein Hub ist nun inaktiv und kann nicht mehr aufgerufen werden.', 'success');
      }

      dispatch(setStatus(newStatus));
    } catch (err) {
      setDisabled(false);

      notification.send('Etwas hat nicht funktioniert.', 'Bitte versuche es später noch einmal.', 'danger');
    }
  };

  return (
    <div className="is-flex has-content-centered">

      <div className="has-text-centered mr-2">
        <InfoPopup text="Du kannst deinen Hub deaktivieren. Dadurch kann er nicht mehr von anderen Nutzern aufgerufen werden, aber alle deine Einstellungen und Ergebnisse bleiben erhalten." />
      </div>

      <button
        type="button"
        className="button has-background-grey-light has-text-black has-hover br10"
        onClick={toggleStatus}
        disabled={disabled}
      >
        <p
          className="has-text-weight-semibold is-size-7"
        >
          {
            status === 'active' ? 'Hub deaktivieren' : 'Hub aktivieren'
          }
        </p>

      </button>

    </div>
  );
};

export default ToggleStatusButton;
