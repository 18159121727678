import React, { Fragment } from 'react';
import ScriptTagCopy from 'components/utils/ScriptTagCopy';
import shopifyIcon from 'assets/images/shopify.svg';
import actions from 'assets/images/shopify1.png';
import theme from 'assets/images/shopify2.png';
import code from 'assets/images/shopify3.png';
import NextButton from 'components/onboarding/buttons/NextButton';
import ScriptIntegrationChecker from 'components/onboarding/script/ScriptIntegrationChecker';
import Image from './Image';

const ScriptShopify = () => (
  <>
    <div className="has-text-centered">
      <img src={shopifyIcon} className="shopSystemIcons" alt="" />
      <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile mt30 has-text-left has-text-weight-bold">Installation für Shopify</p>
    </div>
    <div className="mt20 has-background-pastel br10 has-text-white p20">
      <div className="mb10">
        <i className="fas fa-question-circle mr10" />
        <p className="is-inline has-text-weight-bold">Shopify erlaubt keine Third-Party-Skripte im Checkout</p>
      </div>
      <p className="has-text-weight-semibold mt10">Wenn Du Shopify Plus hast, ist es möglich, auch auf deiner Checkout Seite Widgets anzeigen zu lassen. Sprich dafür am Besten mit deinem Merchant Success Manager bei Shopify.</p>
    </div>
    <div className="mt30">
      <p className=" is-inline is-size-6 has-text-weight-bold has-text-pastel">{'1. '}</p>
      <p className=" is-inline is-size-6 has-text-weight-medium has-text-black">Klicke in deinem Shopify Dashboard auf "Online Store"</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'2. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf Themes</p>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'3. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf den "Actions" Button oben rechts und wähle "Code bearbeiten"</p>
      <div className="columns">
        <Image src={actions} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'4. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Unter Layout, klicke auf "theme.liquid"</p>
      <div className="columns">
        <Image src={theme} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'5. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">{'Scrolle, bis Du den </body>-Tag findest'}</p>
      <div className="columns">
        <Image src={code} />
      </div>
    </div>
    <div className="mt20">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'6. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">{'Kopiere diesen HTML-Code und füge ihn vor dem schließenden </body>-Tag ein'}</p>
    </div>
    <ScriptTagCopy />
    <div className="mt-2 mb-5">
      <p className="is-inline is-size-6 has-text-weight-bold has-text-pastel">{'7. '}</p>
      <p className="is-inline is-size-6 has-text-weight-medium has-text-black">Klicke auf "Speichern"</p>
    </div>
    <ScriptIntegrationChecker />
    <NextButton />
  </>
);
export default ScriptShopify;
