import React from 'react';
import { useSelector } from 'react-redux';
import withSubscription from 'components/hoc/withSubscription';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import { ACTIVE, PAST_DUE } from 'constants/status';
import loader from 'assets/images/loading.svg';
import americanExpressImg from 'assets/images/payment/americanexpress.png';
import blankCardImg from 'assets/images/payment/blankcard.png';
import mastercardImg from 'assets/images/payment/mastercard.png';
import paypalImg from 'assets/images/payment/paypal.png';
import visaImg from 'assets/images/payment/visa.png';

function PaymentInfo() {
  const user = useSelector((state) => state.sessionState.user);

  const { paymentMethods } = user;

  if (!paymentMethods || paymentMethods.length === 0) {
    return <LoadingLayout />;
  }

  if (user.status !== ACTIVE && user.status !== PAST_DUE) {
    return null;
  }

  const showPaymentIcon = () => {
    const paymentType = paymentMethods[0].cardType;

    let src;

    switch (paymentType) {
      case undefined:
        src = paypalImg;
        break;
      case 'Visa':
        src = visaImg;
        break;
      case 'AmericanExpress':
        src = americanExpressImg;
        break;
      case 'Mastercard':
        src = mastercardImg;
        break;
      default: src = blankCardImg;
    }

    return (
      <img
        alt=""
        src={src}
        className="br10"
      />
    );
  };

  // TODO: only show default payment method
  const paymentMethod = paymentMethods[0];

  return (
    <div className="box br10 has-background-light boxshadow">
      <div className="columns is-marginless is-mobile">
        <div className="column p0">
          <p className="has-text-black has-text-weight-bold has-hyphens">
            Zahlungsinformationen
          </p>
        </div>
        <div className="column is-narrow p0 ml5 mr5">
          <Link
            className="button px-3"
            type="button"
            to={routes.CHANGE_PAYMENT_METHOD}
          >
            <i className="fas fa-pencil-alt is-size-6" />
          </Link>
        </div>
      </div>
      <div className="columns is-marginless is-mobile">
        <div className="column is-narrow align-self-center">
          { showPaymentIcon() }
        </div>
        <div className="column has-text-black px-0">
          <div>
            <p className="is-inline mr5">Typ: </p>
            <p className="is-inline has-text-weight-bold">
              {
                paymentMethod.type === 'card'
                  ? 'Karte'
                  : 'PayPal'
              }
            </p>
          </div>
          <div>
            <p className="is-inline mr5">
              {
                   paymentMethod.cardType
                     ? `${paymentMethod.cardType}: `
                     : 'Account: '
                 }
            </p>
            <p className="is-inline has-text-weight-bold">{paymentMethod.last4}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingLayout() {
  return (
    <div className="box br10 has-background-light">
      <p className="has-text-black has-text-weight-bold has-hyphens">
        Zahlungsinformationen
      </p>
      <div className="has-text-centered">
        <img className="has-text-centered mt30 mb30" src={loader} alt="" />
      </div>
    </div>
  );
}

export default withSubscription(PaymentInfo);
