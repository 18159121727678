import React from 'react';

// images
import wave_bottom from 'assets/images/shapes/wavegrey2.svg';
import wave_top from 'assets/images/shapes/wavegrey1.svg';
import flag from 'assets/images/german_flag.png';

const Features = () => {
  const x = 5;

  return (
    <div className="has-background-dash">
      <div className="has-background-white">
        <img src={wave_top} alt="graue Welle für Übergang" className="is-block" />
      </div>
      <div className="columns is-mobile">
        <div className="column br5 pt20 is-2 has-background-pastel" />
      </div>
      <div className="columns is-mobile">
        <div className="column pt20 br5 is-3 has-background-pastel-light" />
      </div>
      <div className="columns is-marginless is-centered my-6 is-mobile">

        <div className="column boxshadow br10 has-background-white px-5 py-6 is-7-desktop is-10-tablet is-11-mobile">
          <h2 className="is-size-3-desktop is-size-4-tablet is-size-4-mobile has-text-weight-bold has-text-centered has-text-black mb-6">
            Übrigens, Flowdust ist...
          </h2>
          <div className="mt-2">
            <div className="columns is-centered is-multiline">
              <div className="column is-5 has-text-black">
                <div className="">
                  <i className="is-inline has-text-primary is-size-3 fa-solid fa-mobile-screen-button" />
                </div>
                <p
                  className="mr-2 is-inline has-text-weight-bold is-size-4-desktop is-size-5-touch"
                >
                  für alle Geräte optimiert
                </p>

                <p className="mt-2">
                  Egal ob Desktop, Tablet oder am Smartphone. Deine Umfragen und Formulare sind mit jedem Gerät kompatibel und sehen überall ansprechend aus.
                </p>
              </div>
              <div className="column is-5 is-offset-1 has-text-black">

                <div>
                  <i className="is-inline has-text-primary is-size-3 fa-solid fa-palette" />
                </div>
                <p
                  className="s-inline has-text-weight-bold is-size-4-desktop is-size-5-touch"
                >
                  individualisierbar
                </p>

                <p className="mt-2">
                  Passe alle Deine Umfragen und Formulare genau nach Deinem Geschmack und Unternehmen an. Du bestimmst die Farben, den Inhalt und das Layout.
                </p>
              </div>
            </div>

            <div className="columns is-centered mt-4">
              <div className="column is-5 has-text-black">
                <div>
                  <i className="is-inline has-text-primary is-size-3 fa-solid fa-shield is-block" />
                </div>
                <p
                  className="mr-2 has-text-weight-bold is-size-4-desktop is-size-5-touch is-inline"
                >
                  DSGVO konform
                </p>

                <p className="mt-2">
                  Wir nehmen den Schutz Deiner Daten und den Daten Deiner Kunden sehr ernst. Alle unsere Server stehen ausschließlich in Deutschland und alles ist nach der deutschen DSGVO geregelt.
                </p>
              </div>
              <div className="column is-5 is-offset-1 has-text-black">
                <div className="is-inline-block nmt7">
                  <img src={flag} alt="Deutsche Flagge" className="has-width-40 has-height-40" />
                </div>
                <p
                  className="mr-2 is-block has-text-weight-bold is-size-4-desktop is-size-5-touch nmt5"
                >
                  aus Deutschland
                </p>

                <p className="mt-2">
                  Flowdust wird entwickelt und gehostet in Deutschland.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="columns level-right is-mobile">
        <div className="column br5 pt20 is-2 has-background-pastel" />
      </div>
      <div className="columns level-right is-mobile">
        <div className="column br5 pt20 is-3 has-background-pastel-light" />
      </div>
      <div className="has-background-white">
        <img src={wave_bottom} alt="graue Welle für Übergang" className="is-block" />
      </div>
    </div>
  );
};

export default Features;
