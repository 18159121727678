import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setExpire } from 'actions/creator';
import stats from 'analytics/analytics';

// 30 days
const MAX_AMOUNT = 3600 * 24 * 30;
const ONE_DAY = 3600 * 24;

const ExpireOptions = () => {
  const { expire } = useSelector((state) => state.creatorState.settings);

  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const setAmount = (amount) => {
    if (amount === 0) {
      stats.push(['trackEvent', 'Widget Creator - Advanced Options - Expire', 'No expire']);
    } else {
      stats.push(['trackEvent', 'Widget Creator - Advanced Options - Expire', 'Amount set']);
    }

    setError('');
    dispatch(setExpire(amount));
  };

  const validateAndSetAmount = (value, isHour = false) => {
    // Value is a string, so convert it to int
    if (value.length > 0) {
      value = parseInt(value, 10);
    } else {
      // Allow clearing the input field
      dispatch(setExpire(0));
      return;
    }

    if (Number.isNaN(value)) {
      setError('Bitte gib eine Zahl ein!');
      stats.push(['trackEvent', 'Widget Creator - Advanced Options - Expire', 'Invalid Value']);
      return;
    }

    // if its an hour, multiply with 3600
    // if its a day, multiply with 24 * 3600
    value = isHour ? value * 3600 : value * 24 * 3600;

    if (isHour && value >= ONE_DAY) {
      setError('Bitte gib eine Zahl zwischen 1 und 23 an.');

      stats.push(['trackEvent', 'Widget Creator - Advanced Options - Expire', 'Invalid Hours']);

      return;
    }

    if (value > MAX_AMOUNT || value < 1) {
      setError('Bitte gib eine Zahl zwischen 1 und 30 an.');

      stats.push(['trackEvent', 'Widget Creator - Advanced Options - Expire', 'Invalid Days']);

      return;
    }

    setError('');
    dispatch(setExpire(value));
  };

  const clearError = () => {
    setError('');
  };

  const isHour = expire > 0 && expire < ONE_DAY;
  const isDay = expire >= ONE_DAY;

  const amountInHours = expire / 3600;
  const amountInDays = amountInHours / 24;

  return (
    <div className="has-text-grey">
      <p>
        Wie lange soll der Flowter nach Abstimmung dem Nutzer verborgen bleiben?
      </p>
      <div className="mt-3">
        <div className="mb-2">
          <input
            type="radio"
            id="neverExpire"
            checked={expire === 0}
            onChange={() => setAmount(0)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered"
            htmlFor="neverExpire"
          >
            Immer anzeigen
          </label>
        </div>
        <div className="mb-2">
          <input
            type="radio"
            id="expireHours"
            checked={isHour}
            onChange={() => setAmount(3600 * 12)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered"
            htmlFor="expireHours"
          >
            <input
              type="text"
              onChange={(event) => validateAndSetAmount(event.target.value, true)}
              value={isHour ? amountInHours : ''}
              onBlur={() => clearError()}
              className="input is-size-6 is-inline has-text-centered has-text-weight-bold has-max-height-30 has-max-width-40"
            />
            <p className="is-inline">
              {` ${expire === 3600 ? 'Stunde' : 'Stunden'}`}
            </p>
          </label>
        </div>
        <div className="mb-2">
          <input
            type="radio"
            id="expireDays"
            checked={isDay}
            onChange={() => setAmount(ONE_DAY * 7)}
            className="is-checkradio is-pastel"
          />
          <label
            className="has-text-centered"
            htmlFor="expireDays"
          >
            <input
              type="text"
              onChange={(event) => validateAndSetAmount(event.target.value)}
              value={isDay ? amountInDays : ''}
              onBlur={() => clearError()}
              className="input is-size-6 is-inline has-text-centered has-text-weight-bold has-max-height-30 has-max-width-40"
            />
            <p className="is-inline">
              {` ${expire === ONE_DAY ? 'Tag' : 'Tage'}`}
            </p>
          </label>
        </div>
      </div>
      <p className="has-text-weight-bold has-text-danger">
        {error}
      </p>
    </div>
  );
};

export default ExpireOptions;
