import React, { useMemo } from 'react';

// Assets
import pictures from './pictures';

/*
 * Component to get a random profile pic to display
 */
const ProfilePic = () => {
  const pic = useMemo(() => {
    const index = Math.round(Math.random() * (pictures.length - 1));
    return pictures[index];
  }, []);

  return <img alt="" className="has-width-64" src={pic} />;
};
export default ProfilePic;
