import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

const ValueSelector = (props) => {
  const {
    isTextfield, onlyNumbers, values, value, setValue,
  } = props;

  const _setValue = (e) => {
    const { value: _value } = e;

    // dont set value if only numbers is allowed and input is not a value currently
    // (but skip empty inputs, they are allowed)
    if (_value.length !== 0 && onlyNumbers) {
      if (!/^\d+$/.test(_value)) return;
    }

    setValue(_value);
  };

  // how each question should be rendered
  const renderLabel = ({ _value, label }) => (
    <div>
      <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-pastel" />
      <p className="is-inline has-text-weight-bold is-size-7">
        {label}
      </p>
    </div>
  );

  const selectedValue = useMemo(() => {
    if (isTextfield) return value;

    const item = values.find((label) => label.value === value);

    return item;
  }, [value, isTextfield]);

  //  console.log('Value', selectedValue);
  return (
    <div className="">
      <p
        className="has-text-weight-bold is-size-6"
      >
        Auswahl
      </p>
      {
           isTextfield
             ? (
               <input
                 className="input"
                 type="text"
                 value={selectedValue}
                 placeholder="Auswahl"
                 onChange={(e) => _setValue(e.target)}
               />
             )
             : (
               <Dropdown
                 value={selectedValue}
                 labels={values}
                 renderLabel={renderLabel}
                 onChange={_setValue}
                 isDisabled={values.length === 1}
               />
             )
      }
    </div>
  );
};

ValueSelector.propTypes = {
  isTextfield: PropTypes.bool.isRequired,
  onlyNumbers: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ValueSelector;
