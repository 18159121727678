import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import FileUploader from 'components/hub/HubContentEditor/FileUploader';

// Constants
import { HUB_MAX_OPTION_LENGTH } from 'constants/hub';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setOption, deleteOption, setOptionMedia } from 'reducer/hubs';

const Option = (props) => {
  const { question, id } = props;

  const [isFocused, setFocused] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const { id: hubID, content } = useSelector((state) => state.hubs.creator);
  const { questions } = content;
  const { options } = questions[question];
  const option = options[id];

  const { text, media } = option;

  const setMedia = (fileUrl, fileType) => {
    dispatch(setOptionMedia({
      question, option: id, source: fileUrl, type: fileType,
    }));

    stats.push(['trackEvent', 'Hub Content Editor', `Set media type ${fileType} for question ${question + 1}`]);
  };

  const _setOption = (_option) => {
    if (_option.length > HUB_MAX_OPTION_LENGTH) {
      setError('Dein Text ist leider etwas zu lang.');
      stats.push(['trackEvent', 'Hub Content Editor', 'Option text max length reached']);
      return;
    }

    setError('');
    dispatch(setOption({ question, option: id, text: _option }));
  };

  const _deleteOption = () => {
    dispatch(deleteOption({ question, option: id }));

    stats.push(['trackEvent', 'Hub Content Editor', `Option removed for question ${question + 1}`]);
  };

  return (
    <div className="my-3 py-4">
      <div className="is-flex has-content-centered">
        <p
          className="has-text-weight-semibold has-text-grey is-size-7"
        >
          {`Option ${id + 1}` }
        </p>

        <button
          className="button is-size-7 has-text-danger has-text-weight-bold has-text-underlined p-0 has-margin-left-auto"
          onClick={_deleteOption}
          type="button"
        >
          Option entfernen
        </button>
      </div>

      <div className="columns is-marginless mt-1 is-mobile">
        <div className="column is-paddingless has-overflow-hidden">
          <textarea
            onChange={(e) => _setOption(e.target.value)}
            type="text"
            value={text}
            rows={2}
            name="option"
            placeholder="Option"
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className="textarea br10 no-shadow has-border p-3 has-fixed-size has-text-black is-size-6 is-paddingless is-inline has-fullheight has-fullwidth"
          />
        </div>
      </div>

      <div className="has-text-centered mt-3">
        <p className="has-text-black has-text-weight-semibold">
          { error }
        </p>
      </div>

      <div className="has-border-bottom pb-4">
        <FileUploader hub={hubID} file={media} setFile={setMedia} />
      </div>
    </div>
  );
};

Option.propTypes = {
  question: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default Option;
