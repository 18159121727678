import React, { useState, useEffect, useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Actions
import { setStatistic, resetFilter, setFilter } from 'reducer/hubs';

// Components
import AnimateHeight from 'react-animate-height';

import BrowserFilter from './BrowserFilter';
import LanguageFilter from './LanguageFilter';
// import CountryFilter from './CountryFilter';
import ScreenFilter from './ScreenFilter';
import CPUFilter from './CPUFilter';
import DeviceFilter from './DeviceFilter';
import OSFilter from './OSFilter';
// import DarkmodeFilter from './DarkmodeFilter';
import QuestionFilter from './QuestionFilter';

const FilterSelector = () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  /**
   * filter always look like this for nested selections like browser:
   * [
   * {
   * label: "Firefox",
   * values: [{ label: "Version", value: 91}]
   * }
   * ]
   * and they look like this for one dimensional selections like country
   * ['en', 'de']
   *
   */
  const [showFilters, setShowFilters] = useState(false);

  const dispatch = useDispatch();
  const { _id: hubID, hubIndex, filter } = useSelector((state) => state.hubs.statistic);

  const {
    browser,
    countries,
    cpus,
    devices,
    languages,
    os,
    screens,
    // darkmode,
    questions,
  } = filter;

  const { post } = useAPI();

  // extract the vaue from the filter
  const getFilterValue = (filterArray) => filterArray.map((_filter) => _filter.value);

  // clear all filters on unmount
  useEffect(() => () => {
    dispatch(resetFilter());
  }, []);

  // count filters to display amount
  const amountOfActiveFilters = useMemo(() => {
    let amount = 0;

    // count all filter objects
    Object.keys(filter).forEach((item) => {
      // browser and os are nested filters with multiple values
      // (e.g each browser has a version number you can filter)
      // so instead, count all the inner values
      if (item === 'browser' || item === 'os') {
        filter[item].forEach((innerFilter) => {
          amount += innerFilter.values.length;
        });
      } else {
        amount += filter[item].length;
      }
    });

    return amount;
  }, [filter]);

  /**
   * Prepares the query for the browser filter
   * label is just the name of the browser, values is an array of versions
   */
  const getBrowserValue = () => browser.map((_browser) => ({
    name: _browser.label,
    values: _browser.values.map(({ value }) => value),
  }));

  /**
   * Prepares the query for the OS filter
   * label is just the name of the os, values is an array of versions
   */
  const getOsValue = () => os.map((_os) => ({
    name: _os.label,
    values: _os.values.map(({ value }) => value),
  }));

  // load new statistic from the server, isReset will be true if the reset button has been clicked
  /**
   * @param ignoreFilter whether or not to not use any filter
   */
  const applyFilter = async (ignoreFilter = false) => {
    try {
      setError('');
      setDisabled(true);

      let data = {
        browser: [],
        countries: [],
        cpus: [],
        devices: [],
        languages: [],
        os: [],
        screens: [],
        // darkmode: [],
        questions: [],
      };

      // apply filters if we did not reset
      if (ignoreFilter === false) {
        // check if all question filters have values
        for (let i = 0; i < filter.questions.length; i++) {
          const { value } = questions[i];
          if (value.length === 0) {
            setError('Bitte gib einen Wert bei jeder Frage ein, die du filtern möchtest.');
            setDisabled(false);
            return;
          }
        }

        data = {
          browser: getBrowserValue(),
          os: getOsValue(),
          countries: getFilterValue(countries),
          cpus: getFilterValue(cpus),
          devices: getFilterValue(devices),
          languages: getFilterValue(languages),
          screens: getFilterValue(screens),
          // darkmode: getFilterValue(darkmode),
          questions: filter.questions,
        };
      }

      // load new statistic with current filter
      const filteredStatistic = await post(`/hubs/statistic/${hubID}`, data);

      // save new statistic in state
      dispatch(setStatistic({ statistic: filteredStatistic, hubIndex }));

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      setError('Filter konnte nicht angewendet werden');
      console.log(e);
    }
  };

  const resetFilters = async () => {
    dispatch(resetFilter());
    await applyFilter(true);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const removeFilter = (filterToRemove) => {
    const obj = {};

    obj[filterToRemove] = [];

    dispatch(setFilter(obj));
  };

  const filterSummary = useMemo(() => {
    const filters = [];
    Object.keys(filter).forEach((filterName) => {
      let amountSelected = 0;

      if (filterName === 'browser' || filterName === 'os') {
        filter[filterName].forEach((innerFilter) => {
          amountSelected += innerFilter.values.length;
        });
      } else {
        amountSelected = filter[filterName].length;
      }

      if (amountSelected === 0) return;

      let renderedFilterName = '';

      switch (filterName) {
        case 'os':
          renderedFilterName = 'Betriebssysteme';
          break;
        case 'browser':
          renderedFilterName = 'Browser';
          break;
        case 'device':
          renderedFilterName = 'Geräte';
          break;
        case 'languages':
          renderedFilterName = 'Sprachen';
          break;
        case 'cpus':
          renderedFilterName = 'CPUs';
          break;
        case 'screens':
          renderedFilterName = 'Bildschirmgrößen';
          break;
        case 'questions':
          renderedFilterName = 'Fragen';
          break;
        default: renderedFilterName = 'Weitere Filter';
          break;
      }

      filters.push(
        <div className="column is-narrow has-text-centered" key={filterName}>
          <div
            className="p-2 has-no-border has-height-auto has-background-grey-light br10 is-flex"
          >
            <p className="is-size-7 has-text-weight-bold">
              { `${renderedFilterName} (${amountSelected})` }
            </p>
            <button
              type="button"
              className="cleanButton ml-2 has-hover"
              onClick={() => removeFilter(filterName)}
            >
              <i className="fas fa-times has-text-danger" />
            </button>
          </div>
        </div>,
      );
    });

    return filters;
  }, [filter]);

  /*
  <div className="column is-narrow">
    <DarkmodeFilter />
  </div>
    <div className="column is-narrow">
    <CountryFilter />
  </div>
  */

  return (
    <div className="box has-text-left">
      <div className="columns is-mobile m-0 has-min-height-60 is-multiline">
        <div className="column is-flex is-narrow-desktop is-narrow-tablet is-12-mobile">
          <button
            type="button"
            className="cleanButton is-size-6 has-text-weight-bold"
            onClick={toggleFilters}
          >
            <i className="is-inline has-text-midnightblue fas fa-filter" />
            <h3 className=" is-inline is-size-7-mobile px-2 has-text-weight-bold has-text-black">
              {`Filter (${amountOfActiveFilters})`}
            </h3>
          </button>
        </div>
        <div className="column">
          <div className="columns is-mobile is-centered-mobile is-centered-tablet is-multiline">
            { filterSummary }
          </div>
        </div>
        <div className="column is-flex has-content-centered is-narrow-desktop is-narrow-tablet is-12-mobile">
          <div className="has-text-right">
            <button
              type="button"
              className="cleanButton is-size-7 has-text-primary has-text-weight-bold"
              onClick={toggleFilters}
            >
              {
                showFilters
                  ? (
                    <>
                      <p className="is-inline mr-1">Einklappen</p>
                      <i className="fas fa-caret-up" />
                    </>
                  )
                  : (
                    <>
                      <p className="is-inline mr-1">Ausklappen</p>
                      <i className="fas fa-caret-down" />
                    </>
                  )
              }
            </button>
          </div>
        </div>
      </div>
      <AnimateHeight
        duration={500}
        height={showFilters ? 'auto' : 0}
      >
        <div className="columns is-multiline is-centered-mobile">
          <div className="column is-narrow">
            <BrowserFilter />
          </div>
          <div className="column is-narrow">
            <DeviceFilter />
          </div>
          <div className="column is-narrow">
            <LanguageFilter />
          </div>
          <div className="column is-narrow">
            <OSFilter />
          </div>
          <div className="column is-narrow">
            <CPUFilter />
          </div>
          <div className="column is-narrow">
            <ScreenFilter />
          </div>
          <div className="column is-12">
            <QuestionFilter />
          </div>
        </div>
        <div className="pb-3">
          <button
            onClick={() => applyFilter()}
            type="button"
            className={`is-size-7 br10 has-hover-primary has-text-weight-bold button mt-4 is-primary ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
          >
            Filter anwenden
          </button>
          <button
            onClick={resetFilters}
            type="button"
            className="is-size-7 is-pulled-right button mt-4 has-text-danger has-text-underlined"
            disabled={disabled}
          >
            Filter zurücksetzen
          </button>
          {
            error
            && (
              <p className="mt-3 has-text-weight-bold is-size-7 has-text-black">
                {error}
              </p>
            )
          }
        </div>
      </AnimateHeight>
    </div>
  );
};

export default FilterSelector;
