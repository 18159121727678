import React, { useState } from 'react';
import PricingPlan from 'components/checkout/PricingPlan';
import { useSelector, useDispatch } from 'react-redux';
import { BILLING } from 'constants/routes';
import { setStatus } from 'actions/user';
import stats from 'analytics/analytics';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import notification from 'utils/notification';
import { useAuth0 } from '@auth0/auth0-react';

function ResumeSubscription() {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [signal] = useState(axios.CancelToken.source());

  const user = useSelector((state) => state.sessionState.user);
  const plan = user.planDetails;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const resumeSubscription = async () => {
    setDisabled(true);
    setError('');
    try {
      const token = await getAccessTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: signal.token,
      };

      // resume subscription
      const data = await axios.put(`${process.env.REACT_APP_API}/subscription`, {}, config);

      setDisabled(false);

      stats.push(['trackEvent', 'Subscription', 'Subscription resumed']);

      const status = data.data;
      dispatch(setStatus(status));

      notification.send('Fertig!', 'Deine Abonnement wurde fortgesetzt.', 'success');

      navigate(BILLING);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setDisabled(false);

      const { response } = err;

      if (response.data) {
        setError(err.response.data);
        return;
      }

      setError('Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal. Wenn das Problem weiterhin besteht, kontaktiere uns unter support@flowdust.com');
      console.log(err);
    }
  };
  return (
    (
      <div>
        <p className="has-text-weight-bold has-text-black is-size-4-desktop is-size-5-touch">
          Du hast dein Abonnement gekündigt.
        </p>
        <p>
          Möchtest Du es fortsetzen?
        </p>
        <div className="columns is-marginless is-centered">
          <PricingPlan plan={plan} showButton={false} />
        </div>
        <div className="has-text-centered mt-4">
          <button
            className={`button is-pastel br10 grow boxshadow has-text-weight-bold ${disabled && 'is-loading'}`}
            type="button"
            onClick={resumeSubscription}
            disabled={disabled}
          >
            Abonnement fortsetzen
          </button>
          <p className="has-text-weight-bold has-text-danger mt-3">
            {error}
          </p>
        </div>
      </div>
    )
  );
}

export default ResumeSubscription;
