import React, { useMemo, useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import TextInput from '../../TextInput';
import Option from './Option';

function PollQuestion(props) {
  const { index, saveAnswer } = props;

  const { t } = useTranslation(['hub']);

  const [currentAnswer, setCurrentAnswer] = useState([]); // -1 means no answer
  const [text, setText] = useState('');

  const { colors, content } = useSelector((state) => state.hubs.creator);
  const { questions } = content;
  const question = questions[index];

  if (!question) return null;

  const {
    options, allowText, maxAmountChecks,
  } = question;

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const amountMediaInOptions = useMemo(() => {
    let amount = 0;

    for (let i = 0; i < options.length; i++) {
      if (options[i].media.source) {
        amount++;
      }
    }

    return amount;
  }, [options]);

  const setOption = (selectedOption) => {
    if (maxAmountChecks === 1) {
      saveAnswer([selectedOption]);
      return;
    }

    // check if already selected
    const alreadySelected = currentAnswer.find((answer) => answer === selectedOption);

    if (alreadySelected !== undefined) {
      const newAnswer = currentAnswer.filter((answer) => answer !== selectedOption);
      setCurrentAnswer(newAnswer);
      return;
    }

    const newAnswer = [...currentAnswer, selectedOption];
    setCurrentAnswer(newAnswer);
  };

  const showHorizontal = () => {
    const renderedOptions = options.map((option, optionIndex) => (
      <div className="column is-narrow has-min-width-128 has-max-width-100-percent" key={optionIndex}>
        <Option
          isChecked={currentAnswer.find((answer) => answer === optionIndex) !== undefined}
          questionIndex={index}
          optionIndex={optionIndex}
          selectOption={setOption}
          key={optionIndex}
        />
      </div>
    ));

    return (
      <div className="columns is-centered is-multiline is-mobile m-0">
        {renderedOptions}
      </div>
    );
  };

  const showVertical = () => {
    const renderedOptions = options.map((option, optionIndex) => (
      <div className="is-in-center has-max-width-400">
        <Option
          isChecked={currentAnswer.find((answer) => answer === optionIndex) !== undefined}
          questionIndex={index}
          optionIndex={optionIndex}
          selectOption={setOption}
          key={optionIndex}
        />
      </div>
    ));

    return (
      <div className="my-5">
        {renderedOptions}
      </div>
    );
  };

  const _setText = (_text) => {
    // -1 means user input
    setText(_text);
  };

  const _saveAnswer = () => {
    // dont go to next question after enter
    if (text.length === 0) return;

    saveAnswer([-1], text);
  };

  const saveAnswerWithMaxChecks = () => {
    if (currentAnswer.length === 0) return;

    saveAnswer(currentAnswer);
  };

  let renderedOptions;

  if (options.length === 2 || amountMediaInOptions >= 2) {
    renderedOptions = showHorizontal();
  } else {
    renderedOptions = showVertical();
  }

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {
        options.length >= 2
        && (
          <div className="has-text-left is-size-7 has-border-bottom pb-1">
            <p>{`${t('hub:answer_options')}`}</p>
          </div>
        )
      }

      { renderedOptions }

      { maxAmountChecks > 1 && (
      <button
        type="button"
        className="button growBig is-primary is-fullwidth has-text-weight-bold is-size-7 mt-4"
        style={{ backgroundColor: colors[0] }}
        onClick={saveAnswerWithMaxChecks}
      >
        <p>Weiter</p>
      </button>
      )}

      {
        allowText
       && (
       <>
         <TextInput
           text={text}
           setText={_setText}
           goNext={_saveAnswer}
           color={colors[0]}
         />
         {
           !isMobile
              && <p className="mt-4 is-size-7 has-text-weight-light has-text-grey">Eingabetaste drücken um fortzufahren</p>
         }
       </>
       )
      }
    </>
  );
}

PollQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  saveAnswer: PropTypes.func.isRequired,
};

export default PollQuestion;
