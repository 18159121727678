import React from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addEnding } from 'reducer/hubs';

// Constants
import { HUB_MAX_ENDINGS } from 'constants/hub';

// Components
import Ending from './Ending';

const EndingEditor = () => {
  const { endings, activeContent } = useSelector((state) => state.hubs.creator.content);

  const { index } = activeContent;

  if (!endings || index === -1) return null;

  return (
    <div>
      <Ending
        id={index}
      />
    </div>
  );
};

export default EndingEditor;
