import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function WidgetCreatorButton(props) {
  const {
    to, img, topText, bottomText, action,
  } = props;

  const navigate = useNavigate();

  const isLink = !!to;

  const clickButton = () => {
    if (isLink) {
      navigate(to);
    } else {
      action();
    }
  };

  return (
    <div className="columns is-marginless is-mobile">
      <div className="column m10 p0">
        <div
          className="has-fullheight has-fullwidth"
        >
          <div className="columns px-3 pb-3 pt-0 br22 has-text-left is-centered is-mobile">
            <div className="column is-6-desktop is-6-tablet is-9-mobile is-flex has-content-centered has-max-width-500">
              <img className="boxshadow br20" src={img} alt="" />
            </div>
            <div className="column is-offset-1">
              <p className="is-uppercase mt20 has-text-black is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-bold has-border-grey-bottom pb-2">
                {topText}
              </p>
              <div className="has-border-grey" />
              <p className="mt20 is-size-5-desktop is-size-6-touch mt10 has-text-grey">
                {bottomText}
              </p>
              <button
                type="button"
                onClick={clickButton}
                className="mt30 grow has-background-pastel boxshadow has-text-weight-bold has-text-white button br10"
              >
                Mehr Infos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WidgetCreatorButton.propTypes = {
  action: PropTypes.func,
  to: PropTypes.string,
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

WidgetCreatorButton.defaultProps = {
  action: () => {},
  to: '',
};

export default WidgetCreatorButton;
