import React, { useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Libraries
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';

// Components
import ImageDisplay from './ImageDisplay';
import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';

const MediaDisplay = (props) => {
  const { file, hubID, isCreator } = props;

  const { hubs, creator } = useSelector((state) => state.hubs);

  const color = useMemo(() => {
    // find the color via the hubID

    if (isCreator) {
      return creator.colors[0];
    }

    const hub = hubs.find((_hub) => _hub._id === hubID);

    if (!hub) return '';

    return hub.colors[0];
  }, [hubID]);

  const url = useMemo(() => {
    if (!file.source) return null;

    // if it's a local blob file, just return the blob url
    if (file.source.includes('blob:')) {
      return sanitizeUrl(file.source);
    }

    // if its already saved on the cdn, load it from there
    return sanitizeUrl(`${process.env.REACT_APP_HUB_FILES}/hubs/${hubID}/${file.source}`);
  }, [file.source]);

  if (!url) return null;

  if (file.type.includes('image')) {
    return (<ImageDisplay url={url} />);
  } if (file.type.includes('audio')) {
    return <AudioPlayer src={url} color={color} />;
  }

  return (<VideoPlayer src={url} />
  );
};

MediaDisplay.propTypes = {
  hubID: PropTypes.string.isRequired,
  file: PropTypes.shape({
    source: PropTypes.string,
    type: PropTypes.string,
  }),
  isCreator: PropTypes.bool,
};

MediaDisplay.defaultProps = {
  file: {
    source: '',
    type: '',
  },

  isCreator: false,
};

export default MediaDisplay;
