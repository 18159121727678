import React, { useState } from 'react';

// Libraries
import stats from 'analytics/analytics';
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setQuestionMedia,
} from 'reducer/hubs';

// Constants
import { } from 'constants/hub';

// Components
import QuestionEditor from '../QuestionEditor';
import FileUploader from 'components/hub/HubContentEditor/FileUploader';

const EmailQuestion = (props) => {
  const dispatch = useDispatch();
  const { id: hubID, content } = useSelector((state) => state.hubs.creator);

  const { questions } = content;

  const { id } = props;

  const question = questions[id];

  const {
    media,
  } = question;

  const setMedia = (fileUrl, fileType) => {
    stats.push(['trackEvent', 'Hub Email Question Editor', 'Question Media set']);
    dispatch(setQuestionMedia({ id, source: fileUrl, type: fileType }));
  };

  return (
    <div>
      <p className="mb-5 is-size-7">Nutzer können im Textfeld ihre E-Mail hinterlassen. </p>
      <QuestionEditor id={id} />
      <div className="has-border-bottom pb-4">
        <FileUploader hub={hubID} file={media} setFile={setMedia} />
      </div>
    </div>
  );
};

EmailQuestion.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EmailQuestion;
