import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

const TypeSelector = (props) => {
  const { types, type, setType } = props;

  const _setType = (e) => {
    const { value } = e;

    setType(value);
  };

  // how each type should be rendered
  const renderLabel = ({ value, label }) => (
    <div>
      <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-pastel" />
      <p className="is-inline has-text-weight-bold is-size-7">
        {label}
      </p>
    </div>
  );

  const value = useMemo(() => types.find((label) => label.value === type), [type]);

  return (
    <div className="has-min-width-200">
      <p
        className="has-text-weight-bold is-size-6"
      >
        Typ
      </p>
      <Dropdown
        value={value}
        labels={types}
        renderLabel={renderLabel}
        onChange={_setType}
      />
    </div>
  );
};

TypeSelector.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

export default TypeSelector;
