import React from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setPrivacyUrl } from 'reducer/hubs';

// Components
import InfoPopup from 'components/utils/InfoPopup';

const PrivacyUrlEditor = () => {
  const { creator } = useSelector((state) => state.hubs);
  const { privacyUrl } = creator;

  const dispatch = useDispatch();

  const _setPrivacyPolicy = (_description) => {
    dispatch(setPrivacyUrl(_description));
  };

  return (
    <>
      <div className="field">
        <label className="label is-inline">
          Datenschutz-URL
        </label>
        <InfoPopup
          text="Gib hier die URL zu deiner Datenschutzerklärung an."
        />
        <div className="control">
          <input
            onChange={(event) => _setPrivacyPolicy(event.target.value)}
            type="text"
            value={privacyUrl}
            placeholder="Datenschutzerklärung"
            className="input br5 is-size-6 has-text-black has-background-white"
          />
        </div>
      </div>
    </>
  );
};

export default PrivacyUrlEditor;
