import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import TypeSelector from '../TypeSelector';
import SignSelector from '../SignSelector';
import ValueSelector from '../ValueSelector';
/**
  * Component to set the filter for a given poll question
  *
* */
const PollQuestion = (props) => {
  const { filter, setFilter } = props;

  const { hubs, statistic } = useSelector((state) => state.hubs);
  const { hubIndex } = statistic;
  const {
    question, type, sign, value,
  } = filter;

  const { questions } = hubs[hubIndex].content;
  const { allowText, isOptional, options } = questions[question];

  const availableTypes = useMemo(() => {
    // Prepare all types to be displayed
    const types = [
      {
        value: 'duration',
        label: 'Antwortdauer (in Sekunden)',
      },
      {
        value: 'answer',
        label: 'Gewählte Antwort',
      },
    ];

    if (allowText) {
      types.push({
        value: 'input',
        label: 'User Feedback',
      });
    }

    return types;
  }, []);

  const availableValues = useMemo(() => {
    const values = options.map((option, index) => {
      if (option.text.length === 0) {
        return {
          value: `${index}`,
          label: `Option ${index}`,
        };
      }

      return {
        value: `${index}`,
        label: option.text,
      };
    });

    if (allowText) {
      values.push({
        value: '-1',
        label: 'Feedback von User',
      });
    }

    if (isOptional) {
      values.push({
        value: '-2',
        label: 'Übersprungen',
      });
    }

    return values;
  }, [type]);

  const setFilterType = (_type) => {
    // set an empty string if the value is a text field
    let newValue = '';

    // but if you can select options, select an index
    if (_type === 'answer') {
      newValue = availableValues[0].value;
    }

    if (_type === 'duration') {
      newValue = '3';
    }

    setFilter({
      ...filter,
      type: _type,
      sign: 'eq',
      value: newValue,
    });
  };

  const setFilterSign = (_sign) => {
    setFilter({
      ...filter,
      sign: _sign,
    });
  };

  const setFilterValue = (_value) => {
    if (type === 'duration' && _value.length > 5) return;
    if (_value.length > 128) return;

    setFilter({
      ...filter,
      value: _value,
    });
  };

  return (
    <div className="columns mt-3">
      <div className="column is-6">
        <TypeSelector
          type={filter.type}
          types={availableTypes}
          setType={setFilterType}
        />
      </div>
      <div className="column is-2">
        <SignSelector
          onlyEqualSign={filter.type !== 'duration'}
          sign={sign}
          setSign={setFilterSign}
        />
      </div>
      <div className="column is-4">
        <ValueSelector
          onlyNumbers={type === 'duration'}
          isTextfield={type !== 'answer'}
          values={availableValues}
          value={value}
          setValue={setFilterValue}
        />
      </div>
    </div>
  );
};

PollQuestion.propTypes = {
  /** Array of filters
    * @example: Filter: For Question at index 1, Duration should be less or equal to 3 seconds
    * question would be 1, type would be "duration", sign "lte" and value 3000
    */
  filter: PropTypes.shape({
    /* Index of the question the filter should be applied to */
    question: PropTypes.number,
    /* Type of the filter. Values: ['duration', 'answer', 'input'] */
    type: PropTypes.string,
    /*
     * Sign of the type for the filter. Values: ['gt', 'lt', 'eq', 'gte', 'lte']
     * Used to determine the range of the value
     */
    sign: PropTypes.string,
    /* Value of the filter */
    value: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default PollQuestion;
