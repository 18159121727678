import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import PollStatistic from './PollStatistic';
import EmailStatistic from './EmailStatistic';
import SliderStatistic from './SliderStatistic';

const QuestionStatistic = () => {
  const { hubs, statistic } = useSelector((state) => state.hubs);
  const { hubIndex, amountSubmissions, resultsPerQuestion } = statistic;

  const { questions } = hubs[hubIndex].content;
  /* if (amountSubmissions === 0) {
      return (
        <p className="pt10 pb10 is-size-5 has-text-weight-semibold is-6 has-text-black  has-text-centered">
          Es sind noch keine Statistiken vorhanden
        </p>
      );
 } */
  const showStatistics = () => questions.map((item, _index) => {
    const { type } = item;
    switch (type) {
      case 'POLL':
        return <PollStatistic key={_index} index={_index} />;
      case 'EMAIL':
        return <EmailStatistic key={_index} index={_index} />;
      case 'SLIDER':
        return <SliderStatistic key={_index} index={_index} />;
      default: return null;
    }
  });
  return (
    <div>
      { showStatistics() }
    </div>
  );
};

export default QuestionStatistic;
