import React from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';
import { useNavigate } from 'react-router-dom';
import {
  CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import WidgetCreatorButton from 'components/utils/WidgetCreatorButton';

// Constants
import {
  FLOWTER_INFO, HUB_INFO, SIGN_UP, STATUSWIDGET_INFO,
} from 'constants/routes';

// Components
import TemplateExampleContainer from './TemplateExampleContainer';

// Assets
import flowter from 'assets/images/layout/flowterborder.png';
import cafe from 'assets/images/cafe5.png';

/*
import status from 'assets/images/landing/features2.png';
import img1 from 'assets/images/landing/features3.png';
import img2 from 'assets/images/landing/features4.png';
import flowter2 from 'assets/images/layout/flowterv2.png';
import status2 from 'assets/images/layout/statuslanding2.png';
import ab from 'assets/images/ab3.png';
import giveaway from 'assets/images/flowhub6.png';
import car from 'assets/images/car3.png';
import cafe2 from 'assets/images/cafe3.png';
*/

// Styles
import './styles/main.scss';
import 'css/browserWindow.scss';

function Headline(props) {
  return (
    <h2 className="has-text-weight-bold has-text-black is-size-3-desktop is-size-3-tablet is-size-4-mobile">
      { props.children }
    </h2>
  );
}

function Content(props) {
  return (
    <p className="mt-4 mb-4 has-text-black has-line-height-30">
      { props.children }
    </p>
  );
}

function ActionButton(props) {
  const { text, to } = props;
  const navigate = useNavigate();

  const clickActionButton = () => {
    stats.push(['trackEvent', 'Landing - Main', 'Action Button Info clicked']);
    navigate(to);
  };

  return (
    <div className="mt-5">
      <button
        className="button boxshadow grow has-background-pastel has-text-white br10 has-text-weight-bold"
        type="button"
        onClick={clickActionButton}
      >
        { text }
        <i className="fas fa-arrow-right ml-2" />
      </button>
    </div>
  );
}

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

function Main() {
  return (
    <div className="mt50">
      <div className="has-background-dash py-6 mt-6">
        <div
          className="has-background-pastel is-inline-block py-2 has-text-white"
          style={{ borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}
        >
          <p
            className="mx-6 has-text-weight-bold"
          >
            Produkte
          </p>
        </div>

        <div className="mt-5 mb-6 columns is-centered is-vcentered is-mobile">
          <div className="column pl10 pr10 is-4-desktop is-6-tablet is-11-mobile mainContentText">
            <Headline>
              Flowdust Hub: Die zentrale Anlaufstelle
            </Headline>
            <Content>
              Mit dem Flowdust Hub ist es ganz einfach Umfragen, Formulare, A/B Tests und vieles mehr zu erstellen - und das alles von einer einzigen Seite aus. Leite Deine Nutzer basierend auf ihren Abstimmungen zu verschiedenen Inhalten, um Kritik abzufangen und positives Feedback in Bewertungen umzuwandeln.
            </Content>
            <ActionButton text="Zum Flowdust Hub" to={HUB_INFO} />
          </div>
          <div className="column p-4 boxshadow br22 is-3-desktop is-4-tablet is-6-mobile grow is-offset-1-desktop has-background-white is-offset-1-tablet is-offset-0-mobile">
            <img src={cafe} alt="" className="br10" />
          </div>
        </div>

        <div className="columns is-centered is-vcentered is-mobile">
          <div className="is-hidden-mobile column mt20 br22 is-4-desktop is-4-tablet is-6-mobile">
            <img src={flowter} alt="" className="grow" />
          </div>
          <div className="column pl10 pr10 is-4-desktop is-6-tablet is-11-mobile mainContentText">
            <Headline>
              Flowdust Flowter: Direkt auf Deiner Website
            </Headline>
            <Content>
              Erstelle Mini-Umfragen, die Deine Nutzer mit nur wenigen Klicks ganz einfach beantworten können. Sammle Feedback genau dort, wo Du es brauchst. Egal ob Landing Page oder Support-Seite - Deine Flowter erscheinen zur richtigen Zeit am richtigen Ort.
            </Content>
            <ActionButton text="Zum Flowter" to={FLOWTER_INFO} />
          </div>
        </div>
      </div>
      <TemplateExampleContainer />
    </div>
  );
}

/*
<div className="has-text-centered mt-6 pt-6 has-text-black">
      <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold my-6">
        Anwendungsbeispiele
      </h2>
      <div className="columns is-centered is-marginless">
        <div className="column is-8">
          <CarouselProvider
            totalSlides={5}
            naturalSlideWidth={10}
            naturalSlideHeight={3}
            isIntrinsicHeight
            infinite
          >
            <Slider>
              <Slide index={0}>
                <div className=" has-fullheight">
                  <WidgetCreatorButton
                    to={FLOWTER_INFO}
                    img={ab}
                    topText="A/B Tests"
                    bottomText="Du musst wichtige Entscheidungen nicht mehr alleine treffen. Lass dich von deinen Nutzern dabei unterstützen. Vergleiche Bilder, Audio- oder Videodateien und überlass den Teilnehmern die Wahl."
                  />
                </div>
              </Slide>
              <Slide index={1}>
                <div className="has-fullheight is-flex has-content-centered">
                  <WidgetCreatorButton
                    to={STATUSWIDGET_INFO}
                    img={cafe}
                    topText="Positive Bewertungen erhalten"
                    bottomText="Leite deine Teilnehmer in unterschiedliche Richtungen, je nachdem wie sie abgestimmt haben. So stellst du sicher, dass Kritik bei dir landet und nur positives Feedback in Bewertungen übergeht."
                  />
                </div>
              </Slide>
              <Slide index={2}>
                <div className=" has-fullheight">
                  <WidgetCreatorButton
                    to={FLOWTER_INFO}
                    img={car}
                    topText="Kundenzufriedenheit erhöhen"
                    bottomText="Frage deine Kunden vor und nach dem Kauf nach ihrer Meinung, um sicherzustellen, dass du ihnen den bestmöglichsten Service bietest."
                  />
                </div>
              </Slide>
              <Slide index={3}>
                <div className=" has-fullheight">
                  <WidgetCreatorButton
                    to={FLOWTER_INFO}
                    img={giveaway}
                    topText="Gewinnspiele"
                    bottomText="Führe Gewinnspiele durch, um potentielle Kunden auf dein Unternehmen aufmerksam zu machen oder nutze sie als Anreiz für Feedback."
                  />
                </div>
              </Slide>
              <Slide index={4}>
                <div className=" has-fullheight">
                  <WidgetCreatorButton
                    to={FLOWTER_INFO}
                    img={cafe2}
                    topText="Bewertungen erhalten"
                    bottomText="Stell deine neuen Produkte vor, präsentiere deine neuen Ideen oder lass deine Nutzer bewerten, wie sehr sie mit dir und deinem Unternehmen zufrieden sind."
                  />
                </div>
              </Slide>
            </Slider>
            <div className="mt-4">
              <ButtonBack className="button has-hover has-border">
                <i className="fas fa-chevron-left is-size-6 has-text-grey" />
              </ButtonBack>
              <ButtonNext className="button has-hover has-border">
                <i className="fas fa-chevron-right is-size-6 has-text-grey" />
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      </div>
    </div>
*/
export default Main;
