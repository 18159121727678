import React from 'react';
import PropTypes from 'prop-types';
import loader from 'assets/images/loading.svg';

const Loader = (props) => {
  const { text, error } = props;

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {
            error
              ? (
                <p className="has-text-centered ml3 mr30 has-text-danger has-text-weight-bold is-size-4">
                  {error}
                </p>
              )
              : (
                <>
                  <img className="has-text-centered" src={loader} alt="" />
                  <p className="is-size-7">
                    {text}
                  </p>
                </>
              )
            }
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
  error: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Laden...',
  error: '',
};

export default Loader;
