import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import Select from 'react-select';

const QuestionSelector = (props) => {
  const { questions } = useSelector((state) => state.hubs.creator.content);

  const { index, setQuestion } = props;

  const _setQuestion = (e) => {
    const { value } = e;

    setQuestion(value);
  };

  const renderType = (type) => {
    if (type === 'POLL') return 'Umfrage';
    if (type === 'SLIDER') return 'Slider';
    if (type === 'EMAIL') return 'E-Mail sammeln';

    return '';
  };

  // Prepare all question to be displayed
  const labels = useMemo(() => questions.map((_question, _index) => {
    const {
      text, type, options, allowText,
    } = _question;

    return {
      value: _index,
      label: text,
      // isDisabled: false,
      questionDisplay: _index + 1,
      type: renderType(type),
    };
  }), [questions]);

  // how each question should be rendered
  const renderLabel = ({
    value, label, questionDisplay, type,
  }) => (
    <div>
      <i className="fas fa-circle is-inline mr-2 is-size-7 has-text-pastel" />
      <p className="is-inline has-text-weight-semibold is-size-7 break-word">
        {`Nr. ${questionDisplay} ${label}`}
      </p>
      <p
        className="is-size-7"
      >
        { `Typ: ${type}` }
      </p>
    </div>
  );

  // custom react-select css height because it doesnt change automatically
  const targetHeight = 50;

  const styles = {
    control: (base) => ({
      ...base,
      cursor: 'pointer',
      borderRadius: '10px',
      minHeight: 'initial',
    }),
    menuList: (base) => ({
      ...base,
      padding: '0px',
      borderRadius: '10px',
      overflow: 'hidden',
    }),
    option: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      height: `${targetHeight - 1 - 1}px`,
      padding: '0 8px',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
  };

  return (
    <div className="has-min-width-200">
      <Select
        value={labels[index]}
        isSearchable={false}
        styles={styles}
        formatOptionLabel={renderLabel}
        options={labels}
        onChange={_setQuestion}
        maxMenuHeight={300}
        menuPlacement="auto"
        placeholder=""
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: '#ffffff',
            primary25: '#fcb992',
            primary50: '#fcb992',
            primary75: '#fcb992',
            primary: '#fcb992',
          },
          borderRadius: '10px',
        })}
      />
    </div>
  );
};

QuestionSelector.propTypes = {
  index: PropTypes.number.isRequired,
  setQuestion: PropTypes.func.isRequired,
};

export default QuestionSelector;
