import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

const DurationDisplay = () => {
  const { t } = useTranslation(['hub']);

  return (
    <p className="has-text-left has-text-grey is-inline-block is-size-7-mobile">
      {t('hub:durationMinutes', { count: 1 })}
    </p>
  );
};

export default DurationDisplay;
