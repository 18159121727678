import React, {
  useState, useEffect, useRef, useLayoutEffect, useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// components
import PrivacyNotice from 'components/widgets/creator/widget/utils/PrivacyNotice';
import TextArea from 'components/widgets/creator/widget/utils/TextArea';
import Button from 'components/widgets/creator/widget/Button';
import Widget from 'components/widgets/creator/widget/Widget';
import Footer from 'components/widgets/creator/widget/Footer';
import IndexDisplay from './IndexDisplay';

// Styles
/* eslint import/no-webpack-loader-syntax: off */
import innerStyles from '!!raw-loader!./styles/innerStyles.scss';
import styles from '!!raw-loader!./styles/styles.scss';

// React Client Libraries (not used in the widget)
import { useDispatch, useSelector } from 'react-redux';
import { setShowWidget } from 'actions/creator';
import { setIndexQuestion, setTextQuestion, setCheck } from 'reducer/flowterCreator';

/**
 * the data for the flowter has already been sanitized
 */
const Flowter = () => {
  /**
  * @typedef {Object} flowterData all the data for one flowter
  * @property {String} endTxt text that will be displayed after user completed the flowter
  * @property {Object} layout object that contains info about the layout (e.g {pos: 1, display: 1})
  * @property {String} privacyURL the url to the customers privacy policy
  * @property {Array} questions array containing all the question objects
  * @property {Object} trigger object that contians info about the trigger (e.g {type: 0, amount: 0})
  * @property {String} color what color the widget has
  * @property {Number} type used to specify what kind of widget it is
  * @property {Boolean} isTrial shows if user is on trial or not
  */

  const {
    questions, endTxt, activeQuestion,
  } = useSelector((state) => state.flowterCreator);

  const {
    color, layout, showWidget,
  } = useSelector((state) => state.creatorState);

  // const { privacyURL, status } = useSelector((state) => state.sessionState.user);

  // Needs stay that way because the user could not be signed in
  const privacyURL = '';
  const isTrial = true;

  const dispatch = useDispatch();

  // showType will determine if widget is hidden or not
  const isHidden = !showWidget;
  const setHidden = (type) => {
    dispatch(setShowWidget(!type));
  };

  const flowterData = {
    layout,
    privacyURL,
    questions,
    showWidget,
  };

  const flowterRef = useRef();

  const amountQuestions = questions.length;

  const index = activeQuestion;
  const setIndex = (newIndex) => {
    dispatch(setIndexQuestion(newIndex));
  };

  const submits = questions;

  const [showEmojis, setShowEmojis] = useState(false);

  const head = `<style type="text/css">${innerStyles}</style>`;

  // TextData needed for PrivacyNotice
  // eslint-disable-next-line
  const textData = useMemo(() => {

    if (index !== -1) return [''];

    const texts = [];
    questions.forEach((questionData) => {
      const { question } = questionData;

      texts.push(question);
    });

    texts.push(endTxt);

    return texts;
    // eslint-disable-next-line
  }, [questions, endTxt]);

  const setTextForQuestion = (text) => {
    dispatch(setTextQuestion({
      id: activeQuestion,
      text,
    }));
  };

  useEffect(() => {
    // After Initialization, show the Flowter if it's a popup
    if (layout.display === 0) {
      setHidden(false);
    }

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    // hide emojis after content changes
    setShowEmojis(false);
  }, [index]);

  /**
   * Hide / Show the Flowter
   */
  const toggleFlowter = () => {
    setHidden(!isHidden);
  };

  /**
   * Checks if there is a next question
   * If there is, load the next index
   * If there is not, show the index to -1 to show the privacy notice
   */
  const loadNextQuestion = () => {
    if (index + 1 >= questions.length) {
      setIndex(-1);
    } else {
      setIndex(index + 1);
    }
  };

  /**
   * Select a checkbox or set button vote
   * @param {Number} index The index of the option that got voted
   */
  const selectBox = (boxIndex) => {
    const { maxNumberOfChecks } = questions[index];
    // get the array that contains all the votes for the current question
    const votesForCurrentQuestion = submits[index].checks;
    const currentVote = votesForCurrentQuestion[boxIndex];

    // If there are checkboxes and if the checkbox has not yet been checked
    if (maxNumberOfChecks > 1 && currentVote === 0) {
      let amountVotes = 0;
      // calculate how many boxes have been checked
      votesForCurrentQuestion.forEach((vote) => {
        // if box has been checked
        if (vote === 1) {
          amountVotes++;
        }
      });

      // if the next vote wouldn't be allowed, don't check the box
      if ((amountVotes + 1) > maxNumberOfChecks) {
        return;
      }
    }

    dispatch(setCheck({
      id: activeQuestion,
      option: boxIndex,
      type: currentVote ? 0 : 1,
    }));

    // If it's a button, go to next question after vote
    if (maxNumberOfChecks === 1) {
      loadNextQuestion();
    }
  };

  const setTextAndCheckForQuestion = (input) => {
    const { checks } = submits[index];

    // The textArea will always be the last element in the checks array
    const indexLastElement = checks.length - 1;

    const surrogatePairs = input.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
    const length = input.length - surrogatePairs.length;

    if (length >= 5) {
      // check box if input is long enough
      if (checks[indexLastElement] === 0) {
        selectBox(indexLastElement);
      }
    }

    // remove check if there is no input
    if (input.length === 0 && checks[indexLastElement] === 1) {
      selectBox(indexLastElement);
    }

    setTextForQuestion(input);
  };

  /**
    * Layout if there are only 2 buttons to decide between
   */
  const showABLayout = () => {
    const { options } = questions[index];
    const buttons = options.map((option, optionIndex) => (
      <button
        className="compare_button"
        style={{ borderColor: color }}
        onClick={() => selectBox(optionIndex)}
        type="button"
        key={optionIndex}
      >
        {option}
      </button>
    ));

    return (
      <div id="optionWrapper" className="compare_layout">
        { buttons }
      </div>
    );
  };

  /**
    * Render function to show checkboxes with multiple checks allowed
   */
  const showMultipleChecksLayout = () => {
    const { maxNumberOfChecks, options, allowText } = questions[index];
    const { text, checks } = submits[index];

    let allZeroes = true;
    checks.forEach((option) => {
      if (option === 1) {
        allZeroes = false;
      }
    });

    // disable mext button if there is no checks
    let disabled = allZeroes;

    const surrogatePairs = text.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
    const length = text.length - surrogatePairs.length;

    // if text is allowed and checked, disable button if text is to short
    if (allowText && checks[checks.length - 1] === 1 && length < 5) {
      disabled = true;
    }

    return (
      <div id="optionWrapper">
        <p id="maxChecksInfo">
          {`Es können bis zu ${maxNumberOfChecks} Möglichkeiten ausgewählt werden.`}
        </p>
        {!showEmojis
          && options.map((item, optionIndex) => {
            const isChecked = !!checks[optionIndex];

            return (
              <div className="inputWrapper" key={optionIndex}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  id={optionIndex}
                  onChange={() => selectBox(optionIndex)}
                />
                <label htmlFor={optionIndex}>{item}</label>
              </div>
            );
          })}
        {
          allowText
          && (
            <div className="inputWrapper">
              <input
                type="checkbox"
                checked={checks[checks.length - 1]}
                id="text_input"
                onChange={() => selectBox(checks.length - 1, 0)}
              />
              <label htmlFor="text_input">
                <TextArea
                  text={text}
                  setText={setTextAndCheckForQuestion}
                  showEmojis={showEmojis}
                  setShowEmojis={setShowEmojis}
                  type="input"
                  color={color}
                />
              </label>
            </div>
          )
        }
        <NextButton
          onClick={loadNextQuestion}
          color={color}
          disabled={disabled}
        />
      </div>
    );
  };

  /**
   * Render function to show all the checkboxes / button for one question
   */
  const showOptions = () => {
    const { maxNumberOfChecks, options, allowText } = questions[index];
    const { text, checks } = submits[index];

    // show the A/B Layout
    if (options.length === 2 && maxNumberOfChecks === 1 && !allowText) {
      return showABLayout();
    }

    if (maxNumberOfChecks > 1) {
      return showMultipleChecksLayout();
    }

    let optionButtons = null;

    // dont show any options when emoji picker is open
    if (!showEmojis) {
      optionButtons = options.map((item, optionIndex) => (
        <button
          className="button"
          key={optionIndex}
          style={{ borderColor: color }}
          onClick={() => selectBox(optionIndex)}
          type="button"
        >
          {item}
        </button>
      ));
    }

    let showNextButton = false;

    if (allowText) {
      const surrogatePairs = text.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
      const length = text.length - surrogatePairs.length;

      showNextButton = length >= 5;
    }

    const checkTextAndContinue = () => {
      // select last box, which is the textbox
      selectBox(checks.length - 1);
      // laod next question
      loadNextQuestion();
    };

    // if there are just buttons
    return (
      <div id="optionWrapper">
        { optionButtons }
        <>
          {
            allowText
            && (
            <TextArea
              text={text}
              setText={setTextForQuestion}
              type={options.length === 0 ? 'area' : 'input'}
              showEmojis={showEmojis}
              setShowEmojis={setShowEmojis}
              color={color}
            />
            )
          }
          {
            showNextButton
            && (
              <NextButton
                onClick={checkTextAndContinue}
                color={color}
              />
            )
          }
        </>
      </div>
    );
  };

  const acceptPrivacyPolicy = () => {
    setIndex(-2);
  };

  /**
   * Show the end text if the flowter has been done, otherwise show the current question
   */
  const showContent = () => {
    if (index === -2) {
      return (
        <div id="endTxt" style={{ backgroundColor: color }}>
          <p>{endTxt}</p>
        </div>
      );
    }

    if (index === -1) {
      return (
        <PrivacyNotice
          textData={textData}
          color={color}
          onClick={acceptPrivacyPolicy}
        />
      );
    }

    const { question } = questions[index];

    return (
      <>
        <div id="question" style={{ backgroundColor: color }}>
          <h2>{question}</h2>
        </div>
        { showOptions() }
      </>
    );
  };

  return (
    <>
      <style>
        { styles }
      </style>
      <Widget
        styles="flowter"
        head={head}
        layout={flowterData.layout}
        isHidden={isHidden}
        frameRef={flowterRef}
        type={0}
      >
        <div id="header">
          <IndexDisplay
            index={index}
            max={amountQuestions}
          />
          <button
            id="exitBtn"
            onClick={toggleFlowter}
            type="button"
            aria-label="Close Button"
          />
        </div>
        <div id="content">
          { showContent() }
        </div>
        <Footer
          privacyURL={privacyURL}
          isTrial={isTrial}
        />
      </Widget>

      {
        flowterData.layout.display === 1
        && (
        <Button
          pos={layout.pos}
          click={toggleFlowter}
          isActive={!isHidden}
          color={color}
        />
        )
      }
    </>
  );
};

const NextButton = (props) => {
  const { color, onClick, disabled } = props;
  return (
    <button
      onClick={onClick}
      className="nextBtn"
      type="button"
      style={{ backgroundColor: color }}
      disabled={disabled}
    >
      Weiter
    </button>
  );
};

NextButton.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NextButton.defaultProps = {
  disabled: false,
};

export default Flowter;
