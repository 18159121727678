import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

import mapImg from 'assets/images/payment/map.png';
import loader from 'assets/images/loading.svg';

const CustomerInfoBox = () => {
  const { user } = useSelector((state) => state.sessionState);
  const [showInfo, setShowInfo] = useState(false);

  const { customer } = user;

  const { firstName, lastName, addressData } = customer;
  const { streetAddress, postalCode, locality } = addressData;

  useEffect(() => {
    if (streetAddress && postalCode && locality) {
      setShowInfo(true);
    }
    // eslint-disable-next-line
  }, [])

  if (!showInfo) {
    return (
      <div className="box boxshadow has-border br10 p10">
        <div className="columns is-marginless">
          <div className="column has-text-centered">
            <img src={loader} alt="" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="box boxshadow has-border br10 p10">
      <p className="has-text-black has-text-weight-bold mt-3 ml-2">Kontaktinformationen</p>
      <div className="columns is-marginless is-mobile is-vcentered">
        <div className="column is-narrow">
          <img src={mapImg} alt="" />
        </div>
        <div className="column has-text-black">
          <p className="has-text-weight-bold">
            {`${firstName} ${lastName}`}
          </p>
          <p>{streetAddress}</p>
          <div>
            <p className="is-inline mr5">
              {postalCode}
            </p>
            <p className="is-inline">
              {locality}
            </p>
          </div>
        </div>
      </div>
      <div className="columns is-marginless is-vcentered is-mobile">
        <div className="column is-narrow p0">
          <Link
            to={routes.CUSTOMER_INFO_SETTINGS}
            className="button px-3 py-1 has-background-light has-border"
            type="button"
          >
            <i className="fas fa-pencil-alt is-size-6 has-text-grey" />
          </Link>
        </div>
        <div className="column">
          <p className="is-inline is-size-7 has-text-weight-bold">Informationen bearbeiten</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoBox;
