import React, { useEffect, useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { sanitizeUrl } from '@braintree/sanitize-url';

// Components
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyToClipboardButton = (props) => {
  const { text, children } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => setCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const sanitizedURL = useMemo(() => sanitizeUrl(text), [text]);

  return (
    <CopyToClipboard
      text={sanitizedURL}
      onCopy={() => setCopied(true)}
    >
      <div className="is-relative">
        { copied && (
        <div
          className="is-absolute has-max-z-index has-background-white boxshadow has-text-weight-bold is-size-7 px-2 py-1 br7 has-text-black has-border-grey-light-thin"
          style={{ top: '-35px', left: '-8px' }}
        >
          <p>Kopiert!</p>
        </div>
        )}
        { children }
      </div>
    </CopyToClipboard>
  );
};

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CopyToClipboardButton.defaultProps = {
  children: null,
};

export default CopyToClipboardButton;
