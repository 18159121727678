import React, { useEffect } from 'react';

// Hooks
import { useDispatch } from 'react-redux';

// Actions
import { setComingFromDemo } from 'reducer/flowterCreator';

// Components
import CreatorMainHub from 'components/widgets/CreatorMainHub';

const FlowterDemo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setComingFromDemo(true));
  }, []);

  return (
    <CreatorMainHub />
  );
};

export default FlowterDemo;
