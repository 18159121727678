import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const BasicQuestionStatistic = (props) => {
  const { submissions, totalSubmissions, avgDuration } = props;

  const formattedDuration = useMemo(() => (avgDuration / 1000).toFixed(2), [avgDuration]);

  if (submissions === 0) {
    return (
      <div>
        <p className="is-size-6 has-text-weight-semibold has-text-black has-text-centered mt-4">
          Für diese Frage sind noch keine Statistiken vorhanden
        </p>
      </div>
    );
  }

  const percentage = ((submissions / totalSubmissions) * 100).toFixed(2);

  return (
    <div className="box has-background-dash has-text-left my-3">
      <div>
        <p className="is-inline has-text-weight-bold">
          {`${submissions} von ${totalSubmissions} (${percentage} %) `}
        </p>
        <p className="is-inline">
          haben die Frage beantwortet
        </p>
      </div>
      <div className="">
        <p className="is-inline">
          Deine Nutzer haben durchschnittlich
        </p>
        <p className="is-inline has-text-weight-bold">
          {` ${formattedDuration} Sekunden `}
        </p>
        <p className="is-inline">
          für die Beantwortung der Frage gebraucht.
        </p>
      </div>

    </div>
  );
};

BasicQuestionStatistic.propTypes = {
  totalSubmissions: PropTypes.number.isRequired,
  submissions: PropTypes.number.isRequired,
  avgDuration: PropTypes.number.isRequired,
};

export default BasicQuestionStatistic;
