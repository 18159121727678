import React, { useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setFilter } from 'reducer/hubs';

// Components
import Dropdown from 'components/utils/Dropdown';

const ScreenFilter = () => {
  const { analytics, filter } = useSelector((state) => state.hubs.statistic);
  const { screen } = analytics;

  const dispatch = useDispatch();

  const allScreens = useMemo(() => screen.map((_screen) => ({
    label: `${_screen.width}x${_screen.height}`,
    value: [_screen.width, _screen.height],
  })), []);

  const NoOptionsMessage = () => <p className="has-text-centered">Das waren alle!</p>;

  const _setFilter = (newFilter) => {
    dispatch(setFilter({ screens: newFilter }));
  };

  console.log(filter.screens);

  return (
    <div className="field has-width-280 has-margin-auto">
      <p className="is-inline has-text-weight-semibold">Bildschirmgrößen</p>
      <i className="mx-2 is-inline fas fa-desktop" />
      <Dropdown
        value={filter.screens}
        isMulti
        labels={allScreens}
        onChange={_setFilter}
        placeholder="Alle Bildschirmgrößen"
        noOptionsMessage={NoOptionsMessage}
      />
    </div>

  );
};

export default ScreenFilter;
