import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';
import './styles/creatorMainHub.scss';

/*
 * Component to init the widget creation
 */
function WidgetButton(props) {
  const {
    to, img, topText, bottomText, action,
  } = props;

  const navigate = useNavigate();

  const isLink = !!to;

  const clickButton = () => {
    stats.push(['trackEvent', 'Widget Creator Button', `${topText} selected`]);

    if (isLink) {
      navigate(to);
    } else {
      action();
    }
  };

  const content = (
    <div className="column has-text-centered has-fullheight">
      <div className="has-height-two-third creatorButtonImageWrapper">
        <img src={img} alt="" />
      </div>
      <div className="has-height-one-third">
        <p className="has-text-black is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-bold">{topText}</p>
        <p className="is-size-6 mt10 has-text-grey mb20">
          {bottomText}
        </p>
      </div>
    </div>
  );

  return (
    <div className="column is-3-desktop is-4-tablet is-11-mobile creatorButton m10 p0">
      <button
        className="button columns has-no-border br22 has-fullheight has-fullwidth is-marginless is-paddingless has-hover has-background-white"
        onClick={clickButton}
        type="button"
      >
        { content }
      </button>
    </div>
  );
}

WidgetButton.propTypes = {
  action: PropTypes.func,
  to: PropTypes.string,
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

WidgetButton.defaultProps = {
  action: () => {},
  to: '',
};

export default WidgetButton;
