import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import stats from 'analytics/analytics';

// Hooks
import { useSelector } from 'react-redux';

// Components
import AnalyticsChart from '../AnalyticsChart';

const ScreenStatistic = () => {
  const { analytics } = useSelector((state) => state.hubs.statistic);
  const { screen } = analytics;

  const [statistic, setStatistic] = useState([]);

  useEffect(() => {
    if (screen.length === 0) return;

    const data = [];

    for (let i = 0; i < Math.min(screen.length, 5); i++) {
      const { height, width, totalAmount } = screen[i];

      const name = (`${width}x${height}`);

      data.push({
        x: name, y: totalAmount, index: i, label: name,
      });
    }

    setStatistic(data);
  }, [screen]);

  const amount = useMemo(() => {
    if (screen.length === 0) return 0;
    let _amount = 0;

    for (let i = 0; i < screen.length; i++) {
      const { totalAmount } = screen[i];

      _amount += totalAmount;
    }

    return _amount;
  }, [screen]);

  const showStatistic = () => {
    const hasData = statistic.length !== 0;
    if (!hasData) return null;

    return (
      <AnalyticsChart
        statistic={statistic}
        amount={amount}
      />
    );
  };

  return (
    <div className="my-4">
      <h2 className="has-text-centered has-text-black has-text-weight-semibold">
        Aufrufe pro Bildschirmgröße
      </h2>
      { showStatistic() }
    </div>
  );
};

export default ScreenStatistic;
