import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Components
import AnalyticsInfo from './AnalyticsInfo';
import ProfilePic from './ProfilePic';
import SubmissionInfo from './SubmissionInfo';
import DeleteButton from '../../utils/DeleteButton';

const LogEntry = (props) => {
  const { index } = props;

  const { statistic } = useSelector((state) => state.hubs);
  const { submissions } = statistic;

  const {
    _id, analytics, createdAt,
  } = submissions[index];

  const date = useMemo(() => {
    if (!createdAt) {
      return null;
    }

    return `${new Date(createdAt).toLocaleString([],
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })} Uhr`;
  }, [createdAt]);

  return (
    <div className="is-size-7 my-2 mx-4 pb-2 has-border-bottom  has-text-left">
      <div className="columns is-marginless is-mobile py-2">
        <div className="column p-0">
          <p className="has-text-grey">
            { date }
          </p>
        </div>
        <div className="column is-narrow p-0">
          <DeleteButton responseID={_id} />
        </div>
      </div>
      <div className="columns is-marginless is-mobile">
        <div className="column is-narrow p-0">
          <ProfilePic index={index} />
        </div>
        <div className="column pt-0 has-fullwidth">
          <SubmissionInfo index={index} />
        </div>
      </div>
      <AnalyticsInfo data={analytics} />
    </div>
  );
};

LogEntry.propTypes = {
  index: PropTypes.number.isRequired,
};

export default LogEntry;
