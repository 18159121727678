import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import stats from 'analytics/analytics';
import { NEW_USER } from 'constants/status';
import { useAuth0 } from '@auth0/auth0-react';

function ScriptIntegrationChecker() {
  const [signal] = useState(axios.CancelToken.source());
  const [lastDataReceived, setLastDataReceived] = useState();
  const [error, setError] = useState();
  const { status } = useSelector((state) => state.sessionState.user);

  const { getAccessTokenSilently } = useAuth0();

  const checkScriptIntegration = async () => {
    setError('');

    const token = await getAccessTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: signal.token,
    };

    try {
      // If no error gets thrown, the server returns 200
      const result = await axios.get(`${process.env.REACT_APP_API}/users/integration`, config);

      const { data } = result;

      if (!data) {
        return;
      }

      const date = new Date(data);
      const now = new Date();

      const diffTime = Math.abs(date - now);
      const minutes = Math.floor(diffTime / 60000);

      setLastDataReceived(minutes);

      stats.push(['trackEvent', 'Script Integration', 'Check - Success']);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      console.log(err.response.data);

      if (err.response.status === 500) {
        setError('Hoppla! Die Installation konnte gerade nicht überprüft werden.');
      }
    }
  };

  useEffect(() => {
    if (status !== NEW_USER) {
      return undefined;
    }

    checkScriptIntegration();
    const interval = setInterval(checkScriptIntegration, 30000);
    return () => {
      signal.cancel();
      clearInterval(interval);
    };
  }, []);

  if (status !== NEW_USER) {
    return null;
  }

  return (
    <div>
      {
      lastDataReceived !== undefined
        ? (
          <div className="has-background-pastel-light has-text-centered px-3 py-5 br10">
            <p className="has-text-black has-text-weight-bold">
              Sehr gut! Dein Skript wurde erfolgreich eingebunden!
            </p>
            <p className="has-text-grey has-text-weight-semibold mt-1">
              {
                lastDataReceived === 0
                  ? 'Wir haben die letzten Daten vor weniger als einer Minute erhalten.'
                  : `Wir haben die letzten Daten vor ${lastDataReceived} Minute${lastDataReceived === 1 ? '' : 'n'} erhalten.`
              }
            </p>
          </div>
        )
        : (
          <div className="has-background-grey-light has-text-centered has-text-grey px-3 py-5 br10">
            <p className="pb-2 has-text-weight-bold">
              Bisher wurden noch keine Daten empfangen.
            </p>
            <p className="mt-2">
              Bitte folge der Anleitung und besuche deine Website.
            </p>
            <p>
              Wir testen alle 30 Sekunden, ob das Skript auf deiner Seite zu finden ist.
            </p>
          </div>
        )
    }
      <p className="has-text-weight-bold has-text-danger mt-2">{error}</p>
    </div>
  );
}

export default ScriptIntegrationChecker;
