import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from './DeleteButton';
import EditButtons from './EditButtons';
import './styles/widget.scss';

const Widget = (props) => {
  const {
    children, api, widget, allWidgets, creatorEditUrl, deleteWidget, addWidget,
  } = props;

  const { name } = widget.meta;

  return (
    <div className="box has-background-white column boxshadow is-3 p20 ml10 mr10 mb20 smallWidget">
      <div className="is-flex flex-direction-column has-fullheight">
        <div className="is-flex flex-direction-column flex-grow">
          <div className="columns is-mobile is-marginless">
            <div className="column has-text-left p0">
              <EditButtons
                widget={widget}
                allWidgets={allWidgets}
                creatorEditUrl={creatorEditUrl}
                saveDuplicate={addWidget}
                api={api}
              />
            </div>
            <div className="column has-text-right p0">
              <DeleteButton
                api={api}
                id={widget._id}
                deleteWidget={deleteWidget}
              />
            </div>
          </div>
          <div className="is-flex flex-grow mt-2 flex-direction-column has-content-centered">
            <p className="has-text-black has-text-weight-bold has-text-centered break-word has-fullwidth has-hyphens">{name}</p>
          </div>
        </div>
        { children }
      </div>
    </div>
  );
};

Widget.propTypes = {
  children: PropTypes.node,
  api: PropTypes.string.isRequired,
  creatorEditUrl: PropTypes.string.isRequired,
  widget: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  allWidgets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteWidget: PropTypes.func.isRequired,
  addWidget: PropTypes.func.isRequired,
};

Widget.defaultProps = {
  children: null,
};

export default Widget;
