import React, {
  useState,
  useRef,
  useLayoutEffect,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import './styles/preview.scss';

import PropTypes from 'prop-types';

const Preview = (props) => {
  const contentRef = useRef();
  const [mountNode, setMountNode] = useState();

  const [previewStyle, setPreviewStyle] = useState('desktop');

  const { layout, showWidget } = props;
  const { toggleWidget } = props;

  const { showResetButton, resetWidget, children } = props;
  const isPopup = layout.display === 0;

  useLayoutEffect(() => {
    const frameDocument = contentRef.current.contentDocument;
    setMountNode(frameDocument.body);
    // eslint-disable-next-line
	}, []);

  // Only needed for firefox - the iframe will disappear without onload handler
  const loadFrame = () => {
    const frameDocument = contentRef.current.contentDocument;
    setMountNode(frameDocument.body);
  };

  /*
  <div className="winter-is-coming">

   <div className="snow snow--near" />
   <div className="snow snow--near snow--alt" />

   <div className="snow snow--mid" />
   <div className="snow snow--mid snow--alt" />

   <div className="snow snow--far" />
   <div className="snow snow--far snow--alt" />
 </div>
  */

  return (
    <div className="column is-8-desktop previewWrapper has-background-dash">

      <div className="columns is-marginless is-centered is-vcentered has-fullheight is-flex">
        <div className={`column has-fullheight content browser-window-wrapper ${previewStyle}`}>
          <p id="previewInfo" className="mt10 is-size-6-desktop is-size-6-tablet is-size-7-mobile has-text-weight-bold">
            Interaktive Vorschau - Probiere deinen Flowter aus!
          </p>
          <div className="columns is-marginless resetWrapper mt-6">
            <div className="column has-text-left">
              {
               showResetButton
               && (
               <button
                 className="button has-text-black br10 has-hover-grey is-size-7 has-text-weight-bold ml10 mr10"
                 onClick={() => resetWidget()}
                 type="button"
               >
                 <i className="fas fa-undo is-inline mr5" />
                 <p className="has-no-hyphens is-inline">
                   Zurücksetzen
                 </p>
               </button>
               )
            }
            </div>
            {
            isPopup
            && (
            <div className="column has-text-right">
              <button
                className="button has-text-black mr5 br10 has-hover-grey is-size-7 has-text-weight-bold has-fullheight has-width-160"
                onClick={() => toggleWidget()}
                type="button"
              >
                {
              	showWidget
              	  ? (
                <>
                  <p className="has-no-hyphens is-inline m-0 mr5">
                    Widget verstecken
                  </p>
                  <i className="fas fa-eye-slash" />
                </>
              	  )
              	  : (
                <>
                  <p className="has-no-hyphens is-inline m-0 mr5">
                    Widget anzeigen
                  </p>
                  <i className="fas fa-eye" />
                </>
              	  )
                  }
              </button>
            </div>
		   )
            }
          </div>
          <div className="has-fullheight">
            <iframe
              title="widgetContainer"
              ref={contentRef}
              className="has-fullwidth has-fullheight is-relative"
              onLoad={loadFrame}
            >
              {
                  mountNode
                  && createPortal(<div id="flowdustContainer">{ children }</div>, mountNode)
                }
            </iframe>
          </div>

          <div className="columns has-text-centered is-centered is-marginless is-hidden-mobile pt-0 pb-5">
            <div className="column is-narrow pr0">
              <button
                className={`button previewButtonDesktop ${previewStyle}`}
                onClick={() => setPreviewStyle('desktop')}
                type="button"
              >
                <i className="fas fa-desktop is-inline mr5" />
                <p className="is-inline has-text-weight-bold">Desktop</p>
              </button>
            </div>
            <div className="column is-narrow pl0">
              <button
                className={`button previewButtonMobile ${previewStyle}`}
                onClick={() => setPreviewStyle('mobile')}
                type="button"
              >
                <i className="fas fa-mobile-alt is-inline mr5" />
                <p className="is-inline has-text-weight-bold">Mobile</p>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

// If showResetButton always stays false, we dont need to have the resetWidget function
Preview.propTypes = {
  showResetButton: PropTypes.bool.isRequired,
  resetWidget: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Preview.defaultProps = {
  resetWidget: () => {},
};

const mapStateToProps = (state) => ({
  layout: state.creatorState.layout,
  showWidget: state.creatorState.showWidget,
});

const mapDispatchToProps = (dispatch) => ({
  toggleWidget: () => dispatch({ type: 'TOGGLE_SHOW_WIDGET' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
