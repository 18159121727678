import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Flowter from 'components/flowter/Flowter';
import StatusWidget from 'components/statusWidget/StatusWidget';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import './styles/widgetContainer.scss';

// const WIDGETS_TO_SHOW = 3;

/*
 * Container that holds all the widgets
 */
const WidgetContainer = () => {
  const [sortBy, setSortBy] = useState(0);
  const [type, setType] = useState(-1);

  const [widgets, setWidgets] = useState([]);

  const { user } = useSelector((state) => state.sessionState);
  const { flowters } = useSelector((state) => state.flowterState);
  const { statusWidgets } = useSelector((state) => state.statusWidgetState);

  // const [amountToShow, setAmountToShow] = useState(WIDGETS_TO_SHOW);

  /**
 * Whenever the sortBy variable changes, sort the widgets to show
 */
  useEffect(() => {
    sortAndSetWidgets();
    // eslint-disable-next-line
	}, [sortBy]);

  /**
 * Show widgets depending on type
 */
  useEffect(() => {
    return sortAndSetWidgets(flowters, 0);

    if (type === -1) {
      // , ...statusWidgets
      sortAndSetWidgets([...flowters]);
    }

    if (type === 0) {
      sortAndSetWidgets(flowters, 0);
    }

    if (type === 1) {
      sortAndSetWidgets(statusWidgets, 0);
    }

    // Reset how many widgets to show after type change
    // setAmountToShow(WIDGETS_TO_SHOW);
    // eslint-disable-next-line
	}, [type]);

  /**
 * When any widgetarray has changed, sort again with the new data
 */
  useEffect(() => {
    return sortAndSetWidgets(flowters, sortBy);

    if (type === -1) {
      sortAndSetWidgets([...flowters, ...statusWidgets], sortBy);
    }

    if (type === 0) {
      sortAndSetWidgets(flowters, sortBy);
    }

    if (type === 1) {
      sortAndSetWidgets(statusWidgets, sortBy);
    }

    // eslint-disable-next-line
}, [flowters, statusWidgets]);

  /**
  * Sort the given widgets depending on the sort variable and set it to the state
  */
  const sortAndSetWidgets = (allWidgets = widgets, sort = sortBy) => {
    if (sort !== sortBy) {
      setSortBy(sort);
    }

    let sortedWidgets = [...allWidgets];

    // Sort by date
    if (sort === 0) {
      sortedWidgets = sortedWidgets.sort((a, b) => a.meta.createdAt.localeCompare(b.meta.createdAt)).reverse();
    }

    // sort by type
    if (sort === 1) {
      // type is number, so convert to string before sorting
      sortedWidgets = sortedWidgets.sort((a, b) => {
        const testA = `${a.type}`;
        const testB = `${b.type}`;
        return testA.localeCompare(testB);
      });
    }

    // sort by alphabet
    if (sort === 2) {
      sortedWidgets = sortedWidgets.sort((a, b) => a.meta.name.localeCompare(b.meta.name));
    }

    setWidgets(sortedWidgets);
  };

  /**
 * Show the widgets given by the widgets array
 */
  const renderedWidgets = useMemo(() => widgets.map((widget) => {
    const { type: widgetType, _id } = widget;

    if (widgetType === 0) {
      const index = flowters.findIndex((flowter) => flowter._id === _id);

      return (
        <Flowter
          key={_id}
          id={index}
        />
      );
    }

    /* const index = statusWidgets.findIndex((flowter) => flowter._id === _id);
    return (
      <StatusWidget
        key={_id}
        id={index}
      />
    ); */
  }), [widgets]);

  const widgetAmountNow = user.widgetCount || 0;

  const showAddWidgetButton = () => {
    let url;
    let widget;
    let text;

    url = `${routes.WIDGET_CREATOR_START}/0`;
    widget = 'Flowter';
    text = 'einen Flowter';

    /* switch (type) {
      case 0:
        url = `${routes.WIDGET_CREATOR_START}/0`;
        widget = 'Flowter';
        text = 'einen Flowter';
        break;
      case 1:
        url = routes.STATUSWIDGET_CREATOR;
        widget = 'Statusmeldung';
        text = 'eine Statusmeldung';
        break;
      default:
        url = routes.WIDGET_CREATOR_START;
        widget = 'Widget';
        text = 'ein Widget';
    } */

    const Button = (props) => (
      <div className="column has-text-centered has-fullheight has-fullwidth is-flex flex-direction-column has-content-centered">
        <i className="fas fa-plus-circle has-text-pastel is-size-2" />
        <p className="has-text-black is-size-5 mt10 has-text-weight-bold has-hyphens">
          {`${props.widget} hinzufügen`}
        </p>
      </div>
    );
    return (
      <div className="box has-background-white column boxshadow is-3 ml10 mr10 mb20 smallWidget has-hover">
        <Link
          className="columns cleanButton is-vcentered has-fullheight has-fullwidth is-marginless is-paddingless has-hover br22"
          to={url}
        >
          <Button widget={widget} text={text} />
        </Link>
      </div>

    );
  };
  /*
<div className="column is-narrow m0 mt10 p0">
                  <button
                    className={`button br10 boxshadow ml5 mr5 has-text-weight-bold ${type === -1 && 'is-primary'}`}
                    onClick={() => setType(-1)}
                    type="button"
                  >
                    Alle
                  </button>
                </div>
                <div className="column is-narrow m0 mt10 p0">
                  <button
                    className={`button br10 boxshadow ml5 mr5 has-text-weight-bold ${type === 0 && 'is-primary'}`}
                    onClick={() => setType(0)}
                    type="button"
                  >
                    Flowter
                  </button>
                </div>
                <div className="column is-narrow m0 mt10 p0">
                  <button
                    className={`button br10 boxshadow ml5 mr5 has-text-weight-bold ${type === 1 && 'is-primary'}`}
                    onClick={() => setType(1)}
                    type="button"
                  >
                    Statusmeldungen
                  </button>
                </div>
*/
  return (
    <div className="columns m0 p0" id="widgetContainer">
      <div className="column m0 p0">
        <h2 className="has-text-weight-bold has-text-pastel is-size-3-desktop is-size-4-tablet is-size-5-mobile">
          Deine Flowter
        </h2>
        <div className="column m0 p0 pb50">
          <div className="columns m0">
            <div className="column is-narrow p0 has-maxWidth">
              <div className="columns m0 p0 is-mobile">
                <div className="column is-narrow is-12-mobile m0 mt10 p0">
                  <p className="has-text-weight-bold has-text-black is-size-5 is-inline ml5 mr10 mt5">{`Anzahl: ${widgetAmountNow}`}</p>
                </div>
              </div>
            </div>

          </div>
          <div
            className="columns m-0 is-multiline is-centered is-mobile has-max-height-500 has-overflow-y-auto box p-5 mt-1"
            id=""
          >
            { showAddWidgetButton() }
            { renderedWidgets }
          </div>
        </div>
      </div>
    </div>

  );
};

export default WidgetContainer;
