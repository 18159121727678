import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/analytics';

// Components
import FeedbackDisplay from '../FeedbackDisplay';
import Chart from 'components/hub/HubStatistic/Statistic/Chart';
import MediaDisplay from 'components/hub/MediaDisplay';
import BasicQuestionStatistic from '../BasicQuestionStatistic';

// Hooks
import { useSelector } from 'react-redux';

const PollStatistic = (props) => {
  const { index } = props;

  const { hubs, statistic: statisticObj } = useSelector((state) => state.hubs);
  const {
    _id, hubIndex, amountSubmissions, resultsPerQuestion,
  } = statisticObj;

  const [statistic, setStatistic] = useState([]);
  const [showChart, setShowChart] = useState(false);

  const questionData = hubs[hubIndex].content.questions[index];

  // prepare statistic data and return defaults if we dont have data
  /**
   * statisticData looks like this:
   * {
   *  values: [{ value: 0, amount: 144 }, { value: 2, amount: 4 }]
   * }
   * Any missing options that can be picked are 0
   */
  const statisticData = useMemo(() => {
    // get the statistic for this question, search via the index
    const results = resultsPerQuestion.find((_results) => _results.question === index);

    // if we dont find any data for this question, return defaults and hide the chart
    if (!results) {
      setShowChart(false);
      return {
        values: [],
        avgDuration: 0,
        submissions: 0,
        question: index,
      };
    }

    // only show the chart if we have data
    setShowChart(true);

    return results;
  }, [resultsPerQuestion]);

  const { values, avgDuration, submissions } = statisticData;
  const {
    text, media, options, allowText, isOptional,
  } = questionData;

  useEffect(() => {
    const data = [];

    // go through all the options and check if we have submissions for it
    for (let i = 0; i < options.length; i++) {
      const { text, media } = options[i];

      let renderedText = text;

      if (text === '') {
        renderedText = `Option ${i + 1}`;
      }

      // try to find the option index inside the statistic values
      const optionIndex = values.findIndex(({ value }) => value === i);

      // if we dont find the index for this option, it means no one has picked this option yet
      if (optionIndex === -1) {
        data.push({
          y: 0, x: renderedText, index: data.length, value: i,
        });
      } else {
        // if we have data, fill the data array with it
        data.push({
          y: values[optionIndex].amount,
          x: renderedText,
          index: data.length,
          value: i,
        });
      }
    }

    if (allowText) {
      // check if we have data about user inputs
      // if a value is -1 it means we have user input
      const textIndex = values.findIndex(({ value }) => value === -1);

      let amount = 0;

      if (textIndex !== -1) {
        // get amount from the stats
        amount = values[textIndex].amount;
      }

      data.push({
        y: amount, x: 'User Feedback', index: data.length, value: -1,
      });
    }

    if (isOptional) {
      // check if we have data about user inputs
      // a value of -2 means skipped
      const skipIndex = values.findIndex(({ value }) => value === -2);

      let amount = 0;

      if (skipIndex !== -1) {
        // get amount from the stats
        amount = values[skipIndex].amount;
      }

      data.push({
        y: amount, x: 'Übersprungen', index: data.length, value: -2,
      });
    }

    setStatistic(data);
  }, [statisticData]);

  // dont count skips as submissions
  const actualSubmissions = useMemo(() => {
    if (statistic.length === 0) return 0;

    if (!isOptional) return submissions;

    const skipData = statistic.find(((stat) => stat.x === 'Übersprungen'));

    if (!skipData) return 0;

    return submissions - skipData.y;
  }, [statistic]);

  return (
    <div className="box">
      <p className="mb-4 has-text-weight-semibold has-text-pastel has-text-left">
        Fragentyp: Umfrage
      </p>
      <h2 className="title br10 pt15 pb15 has-text-weight-semibold is-6 has-text-white has-background-midnightblue has-text-centered is-clipped is-grey mb-4 px-3">
        {text}
      </h2>
      <MediaDisplay file={media} hubID={_id} />
      {
        isOptional
        && <p className="mt-2 has-text-grey">Diese Frage ist optional und konnte übersprungen werden.</p>
      }
      <BasicQuestionStatistic
        totalSubmissions={amountSubmissions}
        submissions={actualSubmissions}
        avgDuration={avgDuration}
      />
      {
        showChart
        && (
        <>
          <Chart statistic={statistic} />
          <FeedbackDisplay index={index} />
        </>
        )
      }
    </div>
  );
};

PollStatistic.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PollStatistic;
