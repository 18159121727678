import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// Actions
import { addSubmissions } from 'actions/flowter';

// Constants
const AMOUNT_TO_LOAD = 20;

const LoadMoreButton = (props) => {
  const { index, showText, amountEntries } = props;

  const [disabled, setDisabled] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const { flowters } = useSelector((state) => state.flowterState);
  const { get } = useAPI();

  const flowter = flowters[index];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!flowter || !flowter.submissions) return;
    // if there are not enough submissions to load, dont show button
    if (amountEntries < AMOUNT_TO_LOAD) {
      setShowButton(false);
    }
  }, []);

  if (!flowter) return null;

  const loadMore = async () => {
    try {
      setDisabled(true);

      const { _id } = flowter;

      // get oldest entry
      const date = flowter.submissions[flowter.submissions.length - 1].createdAt;

      const newSubmissions = await get(`/flowters/submissions?id=${_id}&length=${AMOUNT_TO_LOAD}&date=${date}`);

      dispatch(addSubmissions(newSubmissions, index));

      // dont show button if there is nothing more to load
      if (newSubmissions.length < AMOUNT_TO_LOAD) {
        setShowButton(false);
      }

      setDisabled(false);
    } catch (e) {
      setDisabled(false);
    }
  };

  return (
    showButton
      ? (
        <div className="has-text-centered mt-2 pb-4">
          <button
            type="button"
            className={`button has-background-midnightblue has-hover-midnightblue has-text-white is-size-7 has-text-centered is-rounded ${disabled ? 'is-loading' : ''}`}
            onClick={loadMore}
            disabled={disabled}
          >
            <i className="mr10 fas fa-spinner" />
            Weitere laden
          </button>
        </div>
      )
      : showText
        && (
          <div className="has-text-centered pb-3">
            <p className="pt-3 is-size-7 has-text-weight-semibold has-text-grey">
              Das waren alle!
            </p>
          </div>
        )

  );
};

LoadMoreButton.propTypes = {
  index: PropTypes.number.isRequired,
  amountEntries: PropTypes.number.isRequired,
  showText: PropTypes.bool,
};

LoadMoreButton.defaultProps = {
  showText: true,
};

export default LoadMoreButton;
