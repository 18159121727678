import React, { useState, useEffect } from 'react';

import stats from 'analytics/analytics';

import Footer from 'components/layout/Footer';
import Hero from './Hero';
import InfoText from './InfoText';
import IntegrationSteps from './IntegrationSteps';
import Main from './Main';
import CallToAction from './CallToAction';
import PricingPage from './PricingPage';
import Features from './Features';

import './styles/landing.scss';

const Landing = () => {
  const [userScrolled, setUserScrolled] = useState(0);

  useEffect(() => {
    stats.push(['setDocumentTitle', '/']);

    const trackScrolling = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

      const scrolled = ((winScroll / height) * 100).toFixed(0);
      // only send every 10% (10%, 20%, 30%...)
      if ((scrolled / 10) % 1 === 0) {
        if (scrolled > userScrolled) {
          // stats.push(['trackEvent', 'Landing', `Scroll depth ${scrolled}%`]);

          switch (scrolled) {
            case '20':
              stats.push(['trackEvent', 'Landing', 'Scrolled to "Why Flowdust"']);
              break;
            case '40':
              stats.push(['trackEvent', 'Landing', 'Scrolled to Features']);
              break;
            case '70':
              stats.push(['trackEvent', 'Landing', 'Scrolled to IntegrationSteps']);
              break;
            case '80':
              stats.push(['trackEvent', 'Landing', 'Scrolled to Pricing']);
              break;
            case '90':
              stats.push(['trackEvent', 'Landing', 'Scrolled to to CallToAction']);
              break;
            default:
          }

          setUserScrolled(scrolled);
        }
      }
    };

    trackScrolling();
    window.addEventListener('scroll', trackScrolling, { passive: true });

    return () => {
      window.removeEventListener('scroll', trackScrolling);
    };
    // eslint-disable-next-line
	}, [userScrolled])

  return (
    <div className="has-background-white">
      <Hero />
      <InfoText />
      <Main />
      <Features />
      <div id="pricing">
        <PricingPage />
      </div>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Landing;
