export const addWebsite = (website) => ({
  type: 'ADD_WEBSITE',
  website,
});

export const setWebsites = (websites) => ({
  type: 'SET_WEBSITES',
  websites,
});

export const updateWebsite = (website) => ({
  type: 'UPDATE_WEBSITE',
  website,
});

export const setWidget = (websiteID, widgetData) => ({
  type: 'WEBSITE_WIDGET_SET',
  websiteID,
  widgetData,
});

export const unsetWidget = (websiteID) => ({
  type: 'WEBSITE_WIDGET_UNSET',
  websiteID,
});

export const removeWidgetFromWebsites = (websites) => ({
  type: 'DELETE_WIDGET_WEBSITES',
  websites,
});

export const updateWebsitesWithWidget = (widget) => ({
  type: 'WEBSITE_WIDGET_UPDATE',
  widget,
});
