import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import MediaDisplay from 'components/hub/MediaDisplay';

/**
  * Component that represents one selectable option for one question
  * Can have text and / or some type of media (audio, video, img)
  */
function Option(props) {
  const {
    questionIndex, optionIndex, selectOption, isChecked,
  } = props;

  const { t } = useTranslation('hub');
  const { colors, content, id } = useSelector((state) => state.hubs.creator);
  const { questions } = content;

  const { maxAmountChecks } = questions[questionIndex];
  const options = questions[questionIndex].options[optionIndex];

  const { text, media } = options;

  return (
    <div className="has-text-centered has-background-grey-lighter br10 p-4 my-2 has-fullheight is-flex flex-direction-column">

      {
        media.source
        && (
          <div className="has-min-height-140 is-flex has-content-centered">
            <MediaDisplay file={media} hubID={id} isCreator />
          </div>
        )
      }

      {
        text
        && (
        <div className="">
          <p className="br-5 mt-4 has-text-weight-medium is-size-6 is-size-6-mobile has-text-black break-word">
            {text}
          </p>
        </div>
        )
      }
      <div className="has-margin-top-auto has-text-centered">
        {
          maxAmountChecks === 1 ? (
            <button
              type="button"
              onClick={() => selectOption(optionIndex)}
              className="button growBig has-text-white has-text-weight-bold is-size-7 mt-4 has-width-100 "
              style={{ backgroundColor: colors[0] }}
            >

              <p>{optionIndex + 1}</p>
            </button>
          ) : (

            <div className="field has-text-left mt-3 is-flex has-content-centered">
              <input
                type="checkbox"
                id={`option_${optionIndex}`}
                checked={isChecked}
                className="is-checkradio is-black"
                onChange={() => selectOption(optionIndex)}
              />
              <label
                className="has-text-grey-dark is-size-6 pr-0 mr-0"
                htmlFor={`option_${optionIndex}`}
                style={{ width: '24px', height: '24px' }}
              />
              <button
                type="button"
                onClick={() => selectOption(optionIndex)}
                className="button growBig has-text-white has-text-weight-bold is-size-7 has-width-100"
                style={{ backgroundColor: colors[0] }}
              >
                Auswählen
              </button>
            </div>
          )
        }
      </div>

    </div>
  );
}

Option.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  optionIndex: PropTypes.number.isRequired,
  selectOption: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default Option;
