import React, { useEffect, useState, useMemo } from 'react';

// Libraries
import { sanitizeUrl } from '@braintree/sanitize-url';
import getHubUrl from 'utils/getHubUrl';

// Components
import { Link } from 'react-router-dom';
import Loader from 'components/layout/Loader';
import ShareOptions from './ShareOptions';
import InactiveHubPopup from './InactiveHubPopup';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';

// HOCs
import withAuthentication from 'components/hoc/withAuthentication';

// Actions
import { setHubs } from 'reducer/hubs';

// Constants
import * as routes from 'constants/routes';

function HubList() {
  const dispatch = useDispatch();
  const { hubs } = useSelector((state) => state.hubs);
  const { maxAmountHubs } = useSelector((state) => state.sessionState.user.planDetails);

  const [loading, setLoading] = useState(true);

  const { get } = useAPI();

  useEffect(() => {
    const loadHubs = async () => {
      try {
        setLoading(true);
        const loadedHubs = await get('/hubs');

        dispatch(setHubs(loadedHubs));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    loadHubs();
  }, []);

  const renderedHubs = useMemo(() => hubs.map((hub, index) => {
    const {
      _id, url, profilePic, name, description, statistic, status,
    } = hub;

    const { impressions } = statistic || { impressions: 0 };

    const sanitizedURL = sanitizeUrl(url);
    const hubUrl = getHubUrl(sanitizedURL);

    const nameToDisplay = name || url;

    return (
      <div
        className="column is-4-desktop is-12-touch has-min-width-300 has-max-width-400"
        key={_id}
      >
        <div className="box has-background-white boxshadow">
          <div className="columns is-marginless is-mobile">
            <div className="column is-size-6 p-0">
              <div className="is-flex align-items-center">
                <p className="has-text-black has-text-weight-bold is-inline mr-2 break-all">
                  { nameToDisplay }
                </p>
                {
                  status !== 'active' && (
                    <InactiveHubPopup />
                  )
                }
              </div>
              <p className="is-size-7 has-text-grey break-all">
                {`Aufrufe: ${impressions.toLocaleString()}`}
              </p>
            </div>
            <div className="column is-narrow p-0">
              <a
                href={hubUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p0 growBig button icon-circle grow has-text-black is-inline-block mr-1"
              >
                <i className="is-size-6 fas fa-external-link-alt" />
              </a>
              <ShareOptions id={_id} index={index} url={url} status={status} />
            </div>
          </div>
          <div className="columns mt-3 mb-4 is-vcentered is-mobile">
            {
              profilePic
              && (
                <div className="column is-narrow is-flex has-content-centered py-0 pr-0">
                  <img
                    src={`https://cdn.flowdust.com/hub/images/profile/${profilePic}.png`}
                    alt=""
                    className="has-width-64 has-height-64 br100 has-border-grey-light"
                  />
                </div>
              )
            }

            {
              (name || description)
              && (
              <div className="column">
                {
                  name
                  && (
                    <div className="">
                      <p className="is-size-7 has-text-black has-text-weight-bold break-word">
                        {name}
                      </p>
                    </div>
                  )
                }

                {
                  description
                  && (
                    <div className="">
                      <p className="is-size-7 has-text-grey has-text-weight-light break-word">
                        {description}
                      </p>
                    </div>
                  )
                }
              </div>
              )
            }
          </div>
          <div className="columns my-0 is-mobile">
            <div className="column has-text-right py-0">
              <Link
                to={`${routes.HUB_STATISTIC}/${index}`}
                className="button is-size-7 has-text-weight-semibold has-background-info has-text-white grow has-hover py-0 has-fullwidth br10"
                type="button"
              >
                Öffnen
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }), [hubs]);

  if (loading) return <Loader />;

  return (
    <div className="px-3 py-3">
      <h1 className="has-text-weight-bold has-text-info is-size-3-desktop is-size-4-tablet is-size-5-mobile">
        Deine Hubs
      </h1>
      <p className="has-text-weight-bold has-text-black is-size-5">
        {
          `Anzahl: ${hubs.length} / ${maxAmountHubs}`
        }
      </p>

      <div className="has-width-220 mt-4">
        <div className="box boxshadow has-hover is-block has-fullheight">
          <div className="is-flex has-content-centered">
            <Link
              className="cleanButton p-2 has-fullwidth"
              to={routes.HUB_EDITOR_SELECTOR}
            >
              <div className="has-text-centered">
                <i className="mt20 fas fa-plus-circle has-text-info is-size-2" />
                <p className="has-text-black is-size-5-desktop is-size-6-touch mt10 has-text-weight-bold">
                  Hub hinzufügen
                </p>
              </div>
            </Link>
          </div>

        </div>

      </div>

      <div
        className="columns is-vcentered is-centered-mobile is-multiline m-0 mt-3"
        id=""
      >
        { renderedHubs }
      </div>
    </div>
  );
}

export default withAuthentication(HubList);
