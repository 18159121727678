const INITIAL_STATE = {
  showCookieBox: true,
  statCookies: false,
};

const applyClearCookieSettings = () => ({
  showCookieBox: true,
  statCookies: false,
});

const setStatisticCookies = (state, action) => ({
  ...state,
  statCookies: action.setting,
  showCookieBox: false,
});

const setShowCookieBox = (state, action) => ({
  ...state,
  showCookieBox: action.setting,
});

function cookieReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_SHOW_COOKIE_BOX':
    {
      return setShowCookieBox(state, action);
    }

    case 'SET_STATISTIC_COOKIES':
    {
      return setStatisticCookies(state, action);
    }

    case 'COOKIES_CLEAR':
    {
      return applyClearCookieSettings(state);
    }

    default:
      return state;
  }
}

export default cookieReducer;
