import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setLogicAction } from 'reducer/hubs';

// Components
import ActionSelector from './ActionSelector';

const ActionEditor = (props) => {
  const { logic, questions, endings } = useSelector((state) => state.hubs.creator.content);
  const dispatch = useDispatch();

  const { logicIndex } = props;

  const { action } = logic[logicIndex];
  const { value, type: actionType } = action;

  const { index, type } = value;

  const _setAction = (_type, _index) => {
    dispatch(setLogicAction({
      id: logicIndex,
      index: _index,
      type: _type,
    }));
  };

  return (
    <div className="mt-3">
      <p className="is-size-7 has-text-black mb-2">
        dann gehe zu
      </p>
      <ActionSelector index={index} type={type} setAction={_setAction} />
    </div>
  );
};

ActionEditor.propTypes = {
  logicIndex: PropTypes.number.isRequired,
};

export default ActionEditor;
