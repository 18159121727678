import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Assets
import vulkan from 'assets/images/layout/vulkan.png';
import ari from 'assets/images/testari2.png';
import kevin from 'assets/images/kevin2.png';

// Components
import Footer from 'components/layout/Footer';

// Styles
import './css/aboutus.scss';

/*
<div className="column p20 box boxshadow has-fullheight is-4-desktop is-7-tablet is-11-mobile">
          <p className="has-text-midnightblue has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-5-mobile">
            Firmengründung
          </p>
          <p className="mt20 is-size-5-desktop is-size-6-touch has-text-weight-medium">
            Die Idee von Flowdust enstand bereits im September 2018. Offiziell gegründet wurde Flowdust Online Services UG im Juni 2019 im schönen Fichtelgebirge. Wir haben es uns als Ziel gesetzt, die Bedeutung von Feedback hervorzuheben und durch Flowdust eine Feedback Plattform zu erschaffen, die sowohl Webseiten-Betreiber als auch -Besucher begeistert.
          </p>
        </div>
*/

const AboutUs = () => (
  <>
    <Helmet>
      <title>Flowdust - Über uns</title>
    </Helmet>

    <div className="has-background-dash pt-6">
      <div className="mb40 columns p20 is-centered is-marginless">
        <div className="column is-6-desktop is-9-tablet is-12-mobile box boxshadow p-6">
          <p className="is-size-3-desktop is-size-4-touch has-text-black has-text-weight-bold">
            Feedback - Umfragen - Formulare
          </p>
          <p className="is-size-5-desktop is-size-4-touch has-text-black has-text-weight-bold">
            Alles Begriffe, die sich alles andere als aufregend anhören.
          </p>
          <p className="mt-5 has-text-black">
            Bei Umfragen hat man sofort seitenlange Fragebögen mit hunderten Antwortmöglichkeiten im Kopf, an denen man selbst noch nie teilgenommen hat und das in Zukunft auch ganz sicher nicht wird, vielen Dank.
          </p>
          <p className="mt-2 has-text-black">
            Das Gleiche gilt für Formulare: Jahrhunderte alt, genauso grau wie am Tag der Erfindung und ebenfalls etwas, dem man mit eher gemischten Gefühlen begegnet - vor allem wenn man das vermeintliche Glück hat Eines komplett &quot;durcharbeiten&quot; zu müssen.
          </p>
          <div className="mt-2 has-text-black">
            <p
              className="is-inline has-text-weight-bold mr-1"
            >
              Eigentlich schade,
            </p>
            <p className="is-inline">
              denn Umfragen und Formulare helfen die Nutzerzufriedenheit zu verbessern, wichtige Informationen zu sammeln, Abläufe zu vereinfachen und einfacher mit dem Kunden zu interagieren - gerade in einer Zeit, wo immer mehr nur noch online stattfindet.
            </p>
          </div>

          <p className="is-size-5-desktop is-size-6-touch has-text-black has-text-weight-bold mt-6 has-text-centered">
            Daher unser Ziel: Umfragen und Formulare, an denen man gerne teilnimmt.
          </p>

          <div className="mt-6 has-text-centered">
            <div className="mb-4 is-flex has-content-centered">
              <img
                src={ari}
                className="has-border-grey has-width-128 has-height-128 br100 has-border-white boxshadow mx-3"
                alt=""
              />
              <img
                src={kevin}
                className="boxshadow has-border-grey has-width-128 has-height-128 br100 has-border-white boxshadow mx-3"
                alt=""
              />
            </div>
            <p className="has-text-weight-bold has-text-black">
              Ariane und Kevin Pollak
            </p>
            <p>Gründer von Flowdust</p>
          </div>

          <p className="p-4 is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-black has-text-weight-bold has-text-centered mt-6 has-text-primary">
            {'"We all need people who will give us feedback. That\'s how we improve." - Bill Gates'}
          </p>

        </div>
      </div>
      <Footer />
    </div>
  </>
);

export default (AboutUs);
