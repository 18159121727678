import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from 'components/utils/Dropdown';

const SignSelector = (props) => {
  const { onlyEqualSign, sign, setSign } = props;

  const _setType = (e) => {
    const { value } = e;

    setSign(value);
  };

  const labels = useMemo(() => {
    if (onlyEqualSign) {
      return [{
        value: 'eq',
        label: '=',
      }];
    }

    return [
      {
        value: 'gt',
        label: '>',
      },
      {
        value: 'lt',
        label: '<',
      },
      {
        value: 'eq',
        label: '=',
      },
      {
        value: 'gte',
        label: '>=',
      },
      {
        value: 'lte',
        label: '<=',
      },
    ];
  }, [onlyEqualSign]);

  // how each question should be rendered
  const renderLabel = ({ value, label }) => (
    <div>
      <p className="is-inline has-text-weight-bold is-size-6">
        {label}
      </p>
    </div>
  );

  const value = useMemo(() => labels.find((label) => label.value === sign), [sign]);

  return (
    <div className="">
      <p
        className="has-text-weight-bold is-size-6"
      >
        Art
      </p>
      <Dropdown
        value={value}
        labels={labels}
        renderLabel={renderLabel}
        onChange={_setType}
        className=""
        isDisabled={labels.length === 1}
      />
    </div>
  );
};

SignSelector.propTypes = {
  onlyEqualSign: PropTypes.bool.isRequired,
  sign: PropTypes.string.isRequired,
  setSign: PropTypes.func.isRequired,
};

export default SignSelector;
